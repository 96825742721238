import React, { createRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import DropZone from '../drag-and-drop/DropZone';
import Draggable from '../drag-and-drop/Draggable';
import {
  Facility,
  AllFacilitiesDocument,
  AllGroupsDocument,
  useAddFacilityToGroupMutation,
  useUpdateFacilityGroupByIdMutation,
  useDeleteFacilityGroupByIdMutation,
  useRemoveFacilityFromGroupMutation,
  RemoveFacilityFromGroupMutationOptions,
  UpdateFacilityGroupByIdMutationResult,
  UpdateFacilityGroupByIdMutationOptions
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';

import GroupingItem from './GroupingItem';
import { FacilityGroupingTree } from './GroupingsContainer';
import FacilityItemInHierarchy from './FacilityItemInHierarchy';
import { FacilityGroupingDropTypes } from '../drag-and-drop/constants/dropTypes';
import {
  groupContainsFacilityRecursive,
  testGroupHasGroupAsChild
} from '../../utils/groupingUtilities';
import { toast } from 'react-toastify';
import { GroupMovedToastContent } from './GroupMovedToastContent';
import { expandToFacilityIdAtom } from '../../Atoms';
import { useRecoilValue, useResetRecoilState } from 'recoil';

export type FacilityRefDict = {
  [facilityId: number]: React.RefObject<HTMLDivElement>;
};

export interface ExpandableSection {
  isExpanded: boolean;
}

const SubItems = styled.div<ExpandableSection>`
  ${({ isExpanded }) => (isExpanded ? `display: block` : `display: none`)}
`;

const GroupingItemRoot = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

interface GroupingItemContainerProps {
  group: FacilityGroupingTree;
  isRoot?: boolean;
  level?: number;
  className?: string;
}

const GroupingItemContainer = React.memo(function GroupingContainerItem({
  group,
  isRoot = false,
  level = 0,
  className
}: GroupingItemContainerProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [facilityData, setFacilityData] = useState<Facility[] | null>(null);
  const expandToFacilityId = useRecoilValue(expandToFacilityIdAtom);
  const resetExpandToFacilityId = useResetRecoilState(expandToFacilityIdAtom);
  const [facilityRefs, setFacilityRefs] = useState<FacilityRefDict>([]);

  useEffect(() => {
    if (group?.facilities?.nodes) {
      setFacilityData(group.facilities.nodes as Facility[]);

      const tempFacilityRefs: FacilityRefDict = {};
      group.facilities?.nodes.forEach((facility) => {
        tempFacilityRefs[facility.id] = createRef();
      });
      setFacilityRefs(tempFacilityRefs);
    }
  }, [group]);

  useEffect(() => {
    if (expandToFacilityId !== -1) {
      const [isDirectChild, containsAnywhere] = groupContainsFacilityRecursive(
        group,
        expandToFacilityId
      );
      if (!isExpanded && containsAnywhere) {
        setIsExpanded(containsAnywhere);
      }
      if (isDirectChild) {
        // i'm not really sure why this needs to be in a timeout but it doesn't work otherwise
        setTimeout(() => {
          facilityRefs[expandToFacilityId].current?.scrollIntoView({
            behavior: 'smooth'
          });
          resetExpandToFacilityId();
        }, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandToFacilityId, facilityData, group]);

  const [deleteFacilityGroupsLinker] = useRemoveFacilityFromGroupMutation();
  const onDeleteFacilityFromGroup = useCallback(
    (
      grouping: Pick<FacilityGroupingTree, 'id'>,
      facility: Facility,
      refresh?: boolean // flag to tell the hook if it should run the default refetch operations
    ) => {
      const options: RemoveFacilityFromGroupMutationOptions = {
        variables: {
          input: {
            facilityGroupingId: grouping.id,
            facilityId: facility.id
          }
        }
      };

      if (refresh)
        options.refetchQueries = [AllGroupsDocument, AllFacilitiesDocument];

      return deleteFacilityGroupsLinker(options);
    },
    [deleteFacilityGroupsLinker]
  );

  const [addFacilityToGroup] = useAddFacilityToGroupMutation();
  const onAddFacilityToGroup = useCallback(
    async (
      facility: Facility,
      groupId: string,
      type: FacilityGroupingDropTypes
    ) => {
      if (
        type === 'FACILITY_FROM_LIST' &&
        facility.facilityGroups?.nodes.length > 0
      ) {
        toast(
          <GroupMovedToastContent
            group={facility.facilityGroups?.nodes[0]}
            onUndoClick={(originalGroupId) => {
              onDeleteFacilityFromGroup({ id: groupId }, facility, false);
              addFacilityToGroup({
                variables: {
                  input: {
                    facilityGroupsLinker: {
                      facilityId: facility.id,
                      facilityGroupingId: originalGroupId
                    }
                  }
                },
                refetchQueries: [AllFacilitiesDocument, AllGroupsDocument]
              });
            }}
          />,
          {
            style: {
              width: 400,
              marginLeft: -100
            }
          }
        );
      }
      await Promise.all(
        facility.facilityGroups?.nodes?.map(
          ({ id }: Partial<FacilityGroupingTree>) => {
            onDeleteFacilityFromGroup({ id }, facility, false);
          }
        )
      );

      addFacilityToGroup({
        variables: {
          input: {
            facilityGroupsLinker: {
              facilityId: facility.id,
              facilityGroupingId: groupId
            }
          }
        },
        refetchQueries: [AllFacilitiesDocument, AllGroupsDocument]
      });
    },
    [addFacilityToGroup, onDeleteFacilityFromGroup]
  );

  const [updateGroup] = useUpdateFacilityGroupByIdMutation();
  const onUpdateParentGroup = useCallback(
    (
      grouping: FacilityGroupingTree,
      update: Partial<FacilityGroupingTree>,
      refresh = true
    ): Promise<UpdateFacilityGroupByIdMutationResult | null | undefined> => {
      // Prevent making a group its own parent
      if (grouping.id === update.parentId) return Promise.reject();

      const options: UpdateFacilityGroupByIdMutationOptions = {
        variables: {
          input: {
            id: grouping.id,
            patch: {
              ...update
            }
          }
        }
      };

      if (refresh) {
        options.refetchQueries = [AllGroupsDocument];
      }

      return updateGroup(
        options
      ) as Promise<UpdateFacilityGroupByIdMutationResult>;
    },
    [updateGroup]
  );

  const [deleteGroup] = useDeleteFacilityGroupByIdMutation();
  const onDeleteGroup = useCallback(
    async (group: FacilityGroupingTree) => {
      // TODO, may want to use Promise.allSettled to check for failures, then roll back changes if there are any
      await Promise.all(
        group.groupings?.map((childGroup) => {
          onUpdateParentGroup(childGroup.id, { parentId: null });
        })
      );

      await Promise.all(
        group.facilities?.nodes?.map((facility) => {
          onDeleteFacilityFromGroup(group, facility);
        })
      );

      await deleteGroup({
        variables: {
          input: {
            id: group.id
          }
        },
        refetchQueries: [AllGroupsDocument]
      });
    },
    [deleteGroup, onDeleteFacilityFromGroup, onUpdateParentGroup]
  );

  const onDrop = useCallback(
    (item, type: FacilityGroupingDropTypes) => {
      if (type === 'FACILITY_GROUP') {
        // Prevent making a group its own parent
        if (item.id === group.id) return;

        onUpdateParentGroup(item, { parentId: group.id });
      } else {
        if (
          !facilityData ||
          facilityData.find((groupFacility) => {
            groupFacility.id === item.id;
          })
        ) {
          return;
        }

        onAddFacilityToGroup(item as Facility, group.id, type);
      }
    },
    [facilityData, group.id, onAddFacilityToGroup, onUpdateParentGroup]
  );

  const onConfirmCanDrop = (
    item: Facility | FacilityGroupingTree,
    type: FacilityGroupingDropTypes
  ) => {
    if (type === 'FACILITY_FROM_HIERARCHY' || type === 'FACILITY_FROM_LIST')
      return true;
    return !testGroupHasGroupAsChild(item as FacilityGroupingTree, group.id);
  };

  return (
    <DropZone
      onDrop={onDrop}
      className={className}
      onConfirmCanDrop={onConfirmCanDrop}
    >
      <GroupingItemRoot>
        <Draggable
          type={'FACILITY_GROUP'}
          handleLeftOffset={level}
          item={group}
        >
          <GroupingItem
            isRoot={isRoot}
            group={group}
            onUpdateGroup={onUpdateParentGroup}
            onDelete={onDeleteGroup}
            isExpanded={isExpanded}
            onExpanded={setIsExpanded}
            isExpandable={!!facilityData?.length || !!group.groupings?.length}
          />
        </Draggable>
        <SubItems isExpanded={isExpanded}>
          {group.groupings?.map((childGroup: FacilityGroupingTree) => (
            <GroupingItemContainer
              key={childGroup.id}
              group={childGroup}
              level={level + 1}
            />
          ))}
          {facilityData?.map((facility) => (
            <Draggable
              key={`${facility.id}${group.id}`}
              type={'FACILITY_FROM_HIERARCHY'}
              handleLeftOffset={level + 1}
              item={facility}
            >
              <FacilityItemInHierarchy
                key={facility.id + group.id}
                facility={facility as Facility}
                onRemove={() =>
                  onDeleteFacilityFromGroup(group, facility, true)
                }
                removeText={'remove facility from group'}
                ref={facilityRefs[facility.id]}
              />
            </Draggable>
          ))}
        </SubItems>
      </GroupingItemRoot>
    </DropZone>
  );
});

export default GroupingItemContainer;
