import { Loader } from '@ndustrial/contxt-common';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import applicationListTypes from '../constants/applicationListTypes';
import {
  $grayVeryDark,
  $white,
  ndColorLink,
  ndColorSuccess
} from '@ndustrial/contxt-common';
import ApplicationImage from './ApplicationImage';
import { Heart } from '@ndustrial/nd-icons-svg';

const StyledApplicationCard = styled.div`
  cursor: pointer;
  margin: 1em 0;
  display: block;
  position: relative;
`;

const StyledCardImage = styled.div`
  position: relative;
  width: 100%;

  img {
    display: block;
    width: 100%;
  }

  .nd-application-image {
    background-color: var(--nd-color-primary);
  }

  &::after {
    text-transform: uppercase;
    color: ${$white};
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    padding: 0.5em 1em;
    font-weight: 400;

    .application-card--new & {
      content: 'new';
      background-color: ${ndColorLink};
    }

    .application-card--updated & {
      content: 'updated';
      background-color: ${ndColorSuccess};
    }
  }
`;

const StyledCardInfo = styled.div`
  padding: 15px 0 0;
  width: 100%;
  display: block;
  color: ${$grayVeryDark};

  .application-card--featured & {
    color: ${$white};
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    padding: 15px;
  }
`;

const StyledCardButton = styled.div`
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
  font-size: 0.7em;
  padding: 10px;
  letter-spacing: 1px;
  margin-top: 20px;

  display: flex;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const StyledCardTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 5px;
`;

const StyledCardDescription = styled.div`
  font-size: 0.6875rem;
  font-weight: 500;
`;

const StyledHeartIcon = styled(Heart)`
  z-index: 2;
  position: absolute;
  top: 0.4em;
  right: 0.5em;
  height: 22px;
  width: 22px;
  stroke: ${$white};
  fill: ${(props) => (props.$isFavorite ? $white : 'none')};

  :hover {
    fill: rgba(255, 255, 255, 0.2);
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const propTypes = {
  className: PropTypes.string,
  application: PropTypes.shape({
    description: PropTypes.string,
    favorite: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      isLoading: PropTypes.bool.isRequired
    }).isRequired,
    iconUrl: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
    isUpdated: PropTypes.bool,
    launchUrl: PropTypes.string.isRequired
  }).isRequired,
  onAddFavorite: PropTypes.func.isRequired,
  onRemoveFavorite: PropTypes.func.isRequired
};

function ApplicationCard({ application, onAddFavorite, onRemoveFavorite }) {
  const applicationCardClasses = classNames({
    'application-card': true,
    'application-card--featured':
      application.type === applicationListTypes.FEATURED,
    'application-card--new': application.isNew,
    'application-card--updated': application.isUpdated && !application.isNew
  });

  const favoriteIconClasses = classNames({
    'application-card__favorite': true,
    'icon-heart-stroke': !application.favorite.enabled,
    'icon-heart-fill': application.favorite.enabled
  });

  const toggleFavorite = () => {
    return application.favorite.enabled
      ? onRemoveFavorite(application.id)
      : onAddFavorite(application.id);
  };

  return (
    <StyledApplicationCard className={applicationCardClasses}>
      {application.favorite.isLoading ? (
        <Loader />
      ) : (
        <StyledHeartIcon
          className={favoriteIconClasses}
          onClick={toggleFavorite}
          $isFavorite={application.favorite.enabled}
        />
      )}
      <StyledLink
        href={application.launchUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledCardImage className="application-card__image">
          <ApplicationImage src={application.iconUrl} alt={application.name} />
        </StyledCardImage>
        <StyledCardInfo className="application-card__info">
          <StyledCardTitle className="application-card__title">
            {application.name}
          </StyledCardTitle>
          <StyledCardDescription className="application-card__description">
            {application.description}
          </StyledCardDescription>
          {application.type === applicationListTypes.FEATURED && (
            <StyledCardButton className="application-card__button">
              View application
            </StyledCardButton>
          )}
        </StyledCardInfo>
      </StyledLink>
    </StyledApplicationCard>
  );
}

ApplicationCard.propTypes = propTypes;

export default ApplicationCard;
