import { FacilityGroup } from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { FacilityGroupingTree } from '../features/groupings/GroupingsContainer';

const addToTree = (
  groupTree: FacilityGroupingTree,
  group: FacilityGroupingTree
) => {
  if (!group) return false;

  if (groupTree.id === group.parentId) {
    groupTree.groupings.push(group);
    return true;
  } else if (groupTree.groupings.length) {
    for (let i = 0; i < groupTree.groupings.length; i++) {
      if (addToTree(groupTree.groupings[i], group)) return true;
    }
  }

  return false;
};

const countFacilitiesAndGroups = (groupTree: FacilityGroupingTree) => {
  let localFacilityCount = groupTree.facilities?.nodes?.length ?? 0;
  let localGroupCount = groupTree.groupings?.length ?? 0;

  if (groupTree.groupings?.length) {
    groupTree.groupings.forEach((grouping: FacilityGroupingTree) => {
      const { facilityCount, groupCount } = countFacilitiesAndGroups(grouping);
      localFacilityCount += facilityCount;
      localGroupCount += groupCount;
    });
  }

  groupTree.facilityCount = localFacilityCount;
  groupTree.groupCount = localGroupCount;

  return { facilityCount: localFacilityCount, groupCount: localGroupCount };
};

function createGroupingTree(groups: FacilityGroup[]) {
  if (!groups?.length) return;

  const parentArray: FacilityGroupingTree[] = [];
  const groupsArray: FacilityGroupingTree[] = [];

  groups.forEach((group) => {
    const groupAsTree = { ...group } as FacilityGroupingTree;

    groupAsTree.groupings = [];
    groupAsTree.facilityCount = 0;
    groupAsTree.groupCount = 0;

    if (group.parentId === null) {
      parentArray.push(groupAsTree);
    } else {
      groupsArray.push(groupAsTree);
    }
  });

  let currentGroupIndex = 0;
  while (groupsArray.length) {
    let found = false;

    for (let i = 0; i < parentArray.length; i++) {
      if (addToTree(parentArray[i], groupsArray[currentGroupIndex])) {
        groupsArray.splice(currentGroupIndex, 1);
        found = true;
        break;
      }
    }

    if (!found)
      // Prevents skipping an item due to slicing it in place above
      currentGroupIndex++;
    if (currentGroupIndex >= groupsArray.length) currentGroupIndex = 0;
  }

  parentArray.forEach((parentGroupTree) =>
    countFacilitiesAndGroups(parentGroupTree)
  );
  return parentArray;
}

function testGroupHasGroupAsChild(
  testGroup: FacilityGroupingTree,
  currentGroupId: string
): boolean {
  if (testGroup.id === currentGroupId) return true;
  if (!testGroup.groupings || testGroup.groupings.length === 0) return false;

  if (
    testGroup.groupings?.find((childGroup) => childGroup.id === currentGroupId)
  ) {
    return true;
  } else {
    for (let i = 0; i < testGroup.groupings.length; i++) {
      if (testGroupHasGroupAsChild(testGroup.groupings[i], currentGroupId)) {
        return true;
      }
    }
  }
  return false;
}

function groupContainsFacilityRecursive(
  group: FacilityGroupingTree,
  facilityId: number
): [isDirectChild: boolean, containsAnywhere: boolean] {
  if (
    group.facilities &&
    group.facilities.nodes &&
    group.facilities.nodes.length > 0
  ) {
    if (group.facilities.nodes.some((facility) => facility.id === facilityId)) {
      return [true, true];
    }
  }
  if (group.groupings && group.groupings.length > 0) {
    let groupContainsId = false;
    group.groupings.forEach((grouping) => {
      if (!groupContainsId) {
        const [, groupingContainsFacility] = groupContainsFacilityRecursive(
          grouping,
          facilityId
        );
        if (groupingContainsFacility) {
          groupContainsId = true;
        }
      }
    });
    return [false, groupContainsId];
  }

  return [false, false];
}

export {
  createGroupingTree,
  countFacilitiesAndGroups,
  addToTree,
  testGroupHasGroupAsChild,
  groupContainsFacilityRecursive
};
