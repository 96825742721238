import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function loadAllUsersPermissions(organizationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_USERS_PERMISSIONS_START
    });

    return contxtSdk.coordinator.permissions
      .getAllByOrganizationId(organizationId)
      .then((usersPermissions) => {
        dispatch({
          type: actionTypes.LOAD_USERS_PERMISSIONS_SUCCESS,
          payload: usersPermissions
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_USERS_PERMISSIONS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function loadUserPermissions(organizationId, userId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_USER_PERMISSIONS_START
    });

    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.permissions
      .getOneByOrganizationId(organizationId, userId)
      .then((permissions) => {
        dispatch({
          type: actionTypes.LOAD_USER_PERMISSIONS_SUCCESS,
          payload: permissions
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_USER_PERMISSIONS_FAILURE,
          error: true,
          payload: err
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

export { loadAllUsersPermissions, loadUserPermissions };
