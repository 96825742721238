import styled from 'styled-components';

import { Chip, ChipLabel } from '@ndustrial/nd-chip-react';

const ProjectEnvironmentChip = styled(Chip)`
  width: 325px;
  justify-content: space-between;
`;

const ProjectEnvironmentChipLabel = styled(ChipLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;

export { ProjectEnvironmentChip, ProjectEnvironmentChipLabel };
