const composeValidators =
  (...validators) =>
  (value, model) =>
    validators.reduce(
      (error, validator) => error || validator(value, model),
      undefined
    );

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

const passwordMatch = (keyToMatch) => (value, model) =>
  model[keyToMatch] && value !== model[keyToMatch]
    ? 'Passwords must match'
    : undefined;

const required = (value) => (value ? undefined : 'Required');

const minimumLength = (minLength) => (value) =>
  value && value.length < minLength
    ? `Minimum of ${minLength} characters required`
    : undefined;

const slugValidator = (value) =>
  value && !/^[a-z0-9]+$/.test(value)
    ? 'Only lowercase letters and numbers allowed'
    : undefined;

export {
  composeValidators,
  email,
  passwordMatch,
  required,
  minimumLength,
  slugValidator
};
