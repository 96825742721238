import { PrimaryButton } from '@ndustrial/nd-button-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@ndustrial/contxt-common';

import Form from './Form';
import { useTheme } from 'styled-components';
const propTypes = {
  className: PropTypes.string,
  newOrganizationForm: PropTypes.shape({
    error: PropTypes.object,
    isLoading: PropTypes.bool.isRequired
  }).isRequired,
  onCreateOrganization: PropTypes.func.isRequired,
  loadOrganizations: PropTypes.func.isRequired
};

function NewOrganizationModal(props) {
  const [isModalShown, setIsModalShown] = useState(false);
  const theme = useTheme();

  const openModal = () => {
    setIsModalShown(true);
  };

  const closeModal = () => {
    setIsModalShown(false);
  };

  const handleSubmit = (newOrganization) => {
    const { onCreateOrganization, loadOrganizations } = props;

    onCreateOrganization(newOrganization)
      .then(() => {
        return closeModal();
      })
      .then(() => {
        return loadOrganizations();
      });
  };

  return (
    <div className={props.className}>
      <Modal
        title="Add Organization"
        isOpen={isModalShown}
        onRequestClose={closeModal}
        customStyles={{
          height: 'fit-content',
          width: 800,
          padding: 0,
          paddingBottom: 10,
          borderRadius: 0,
          border: 'none'
        }}
        customHeaderStyles={{
          backgroundColor: theme.primary,
          padding: '10px',
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: 'none'
        }}
      >
        <Form onSubmit={handleSubmit} formState={props.newOrganizationForm} />
      </Modal>
      <PrimaryButton onClick={openModal}>Add Organization</PrimaryButton>
    </div>
  );
}

NewOrganizationModal.propTypes = propTypes;

export default NewOrganizationModal;
