import { NotificationManager } from 'react-notifications';
import { get } from 'lodash';

import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function filterOrganizationUsersByCategory(categoryFilter) {
  return {
    type: actionTypes.FILTER_ORGANIZATION_USERS_BY_CATEGORY,
    payload: categoryFilter
  };
}

function filterOrganizationUsersByValue(filterValue) {
  return {
    type: actionTypes.FILTER_ORGANIZATION_USERS_BY_VALUE,
    payload: filterValue
  };
}

function inviteNewOrganizationUser(organizationId, user) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.INVITE_ORGANIZATION_USER_START
    });

    return contxtSdk.coordinator.users
      .invite(organizationId, user)
      .then((users) => {
        NotificationManager.success('User invite sent.');

        dispatch({
          type: actionTypes.INVITE_ORGANIZATION_USER_SUCCESS,
          payload: users
        });
      })
      .catch((err) => {
        const errorMessage = get(err, 'response.data.message', err.message);
        NotificationManager.error(errorMessage);

        dispatch({
          type: actionTypes.INVITE_ORGANIZATION_USER_FAILURE,
          error: true,
          payload: errorMessage
        });

        throw err;
      });
  };
}

function loadOrganizationUsers(organizationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_ORGANIZATION_USERS_START
    });

    return contxtSdk.coordinator.users
      .getByOrganizationId(organizationId)
      .then((users) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_USERS_SUCCESS,
          payload: users
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_USERS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function removeOrganizationUser(organizationId, userId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .remove(organizationId, userId)
      .then(() => {
        NotificationManager.success('Successfully deleted user.');

        dispatch({
          type: actionTypes.REMOVE_ORGANIZATION_USER_SUCCESS,
          payload: userId
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        const errorMessage = get(err, 'response.data.message', err.message);
        NotificationManager.error(errorMessage);

        dispatch({
          type: actionTypes.REMOVE_ORGANIZATION_USER_FAILURE,
          error: true,
          payload: err
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function addRole(userId, roleId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .addRole(userId, roleId)
      .then(() => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function removeRole(userId, roleId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .removeRole(userId, roleId)
      .then(() => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function addApplication(userId, applicationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .addApplication(userId, applicationId)
      .then(() => {
        dispatch({
          type: actionTypes.USER_PERMISSIONS_APPLICATION_ADD,
          payload: {
            userId,
            applicationId
          }
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function removeApplication(userId, applicationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .removeApplication(userId, applicationId)
      .then(() => {
        dispatch({
          type: actionTypes.USER_PERMISSIONS_APPLICATION_REMOVE,
          payload: {
            userId,
            applicationId
          }
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function addProjectEnvironment(userId, projectEnvironment, accessType) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .addProjectEnvironment(userId, projectEnvironment.id, accessType)
      .then(() => {
        dispatch({
          type: actionTypes.USER_PERMISSIONS_PROJECT_ENVIRONMENT_ADD,
          payload: {
            userId,
            projectEnvironment,
            accessType
          }
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

function removeProjectEnvironment(userId, projectEnvironmentId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ORGANIZATION_USER_LOADING_START,
      payload: userId
    });

    return contxtSdk.coordinator.users
      .removeProjectEnvironment(userId, projectEnvironmentId)
      .then(() => {
        dispatch({
          type: actionTypes.USER_PERMISSIONS_PROJECT_ENVIRONMENT_REMOVE,
          payload: {
            userId,
            projectEnvironmentId
          }
        });

        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ORGANIZATION_USER_LOADING_STOP,
          payload: userId
        });

        throw err;
      });
  };
}

export {
  addApplication,
  addRole,
  addProjectEnvironment,
  filterOrganizationUsersByCategory,
  filterOrganizationUsersByValue,
  inviteNewOrganizationUser,
  loadOrganizationUsers,
  removeApplication,
  removeOrganizationUser,
  removeProjectEnvironment,
  removeRole
};
