import React from 'react';
import HierarchyEditorContainer from './HierarchyEditorContainer';
import { ApolloProviderWrapper } from '@ndustrial/contxt-common';
import { ModuleComponentProps } from 'app/ts/components/PackageEntryPoint';
import FacilityGroupingHeader from './FacilityGroupingHeader';

export { FacilityGroupingHeader };

function HierarchyEditorEntry({ orgSlug }: ModuleComponentProps) {
  return (
    <ApolloProviderWrapper orgSlug={orgSlug}>
      <HierarchyEditorContainer />
    </ApolloProviderWrapper>
  );
}

export default HierarchyEditorEntry;
