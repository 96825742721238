import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function filterApplications(filterValue) {
  return {
    type: actionTypes.FILTER_APPLICATIONS,
    payload: filterValue
  };
}

function loadApplications() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_APPLICATIONS_START
    });

    return contxtSdk.coordinator.applications
      .getAll()
      .then((applications) => {
        dispatch({
          type: actionTypes.LOAD_APPLICATIONS_SUCCESS,
          payload: applications
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_APPLICATIONS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

export { filterApplications, loadApplications };
