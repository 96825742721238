import { Loader } from '@ndustrial/contxt-common';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import * as applicationsActionCreators from '../actions/applications';
import * as favoriteApplicationsActionCreators from '../actions/favoriteApplications';
import * as featuredApplicationsActionCreators from '../actions/featuredApplications';
import ApplicationGroup from '../components/ApplicationGroup';
import ApplicationsFilter from '../components/ApplicationsFilter';
import applicationListTypes from '../constants/applicationListTypes';
import {
  getApplicationFilterValue,
  getApplicationGroups,
  getApplicationsLoading,
  getFavoriteApplications
} from '../selectors/applications';

const StyledWrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

const propTypes = {
  actions: PropTypes.shape({
    applications: PropTypes.shape({
      filterApplications: PropTypes.func.isRequired,
      loadApplications: PropTypes.func.isRequired
    }),
    favoriteApplications: PropTypes.shape({
      addFavoriteApplication: PropTypes.func.isRequired,
      loadFavoriteApplications: PropTypes.func.isRequired,
      removeFavoriteApplication: PropTypes.func.isRequired
    }),
    featuredApplications: PropTypes.shape({
      loadFeaturedApplications: PropTypes.func.isRequired
    })
  }),
  applicationFilterValue: PropTypes.string,
  applicationGroups: PropTypes.array.isRequired,
  favoriteApplicationsGroup: PropTypes.shape({
    applications: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired
  }),
  selectedOrganization: PropTypes.shape({
    id: PropTypes.string
  }),
  isLoading: PropTypes.bool.isRequired
};

class ConnectedApplications extends Component {
  componentDidMount() {
    if (this.props.selectedOrganization.id) {
      this.props.actions.applications.loadApplications();
      this.props.actions.favoriteApplications.loadFavoriteApplications();
      this.props.actions.featuredApplications.loadFeaturedApplications(
        this.props.selectedOrganization.id
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedOrganization.id &&
      this.props.selectedOrganization.id !== prevProps.selectedOrganization.id
    ) {
      this.props.actions.applications.loadApplications();
      this.props.actions.favoriteApplications.loadFavoriteApplications();

      this.props.actions.featuredApplications.loadFeaturedApplications(
        this.props.selectedOrganization.id
      );
    }
  }

  clearApplicationFilter = (e) => {
    e.preventDefault();
    this.props.actions.applications.filterApplications('');
  };

  onAddFavorite = (applicationId) => {
    this.props.actions.favoriteApplications.addFavoriteApplication(
      applicationId
    );
  };

  onRemoveFavorite = (applicationId) => {
    this.props.actions.favoriteApplications.removeFavoriteApplication(
      applicationId
    );
  };

  render() {
    if (this.props.isLoading) {
      return <Loader label="LOADING APPS" />;
    }

    if (!this.props.applicationGroups.length) {
      return (
        <React.Fragment>
          <ApplicationsFilter
            value={this.props.applicationFilterValue}
            onFilter={this.props.actions.applications.filterApplications}
          />
          <div className="centered">
            <h2>No Applications Found!</h2>
            {this.props.applicationFilterValue ? (
              <a href="#" onClick={this.clearApplicationFilter}>
                Try Clearing Your Search
              </a>
            ) : null}
          </div>
        </React.Fragment>
      );
    }

    const hasFavorites =
      this.props.favoriteApplicationsGroup.applications.length > 0;

    return (
      <StyledWrapper className={'nd-content--scrollable'}>
        <ApplicationsFilter
          value={this.props.applicationFilterValue}
          onFilter={this.props.actions.applications.filterApplications}
        />
        {hasFavorites && (
          <ApplicationGroup
            type={applicationListTypes.FAVORITE}
            group={this.props.favoriteApplicationsGroup}
            onAddFavorite={this.onAddFavorite}
            onRemoveFavorite={this.onRemoveFavorite}
          />
        )}
        {this.props.applicationGroups.map((group) => (
          <ApplicationGroup
            key={group.name}
            type={applicationListTypes.DEFAULT}
            group={group}
            onAddFavorite={this.onAddFavorite}
            onRemoveFavorite={this.onRemoveFavorite}
          />
        ))}
      </StyledWrapper>
    );
  }
}

ConnectedApplications.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    applicationGroups: getApplicationGroups(state),
    favoriteApplicationsGroup: {
      name: 'favorited',
      applications: getFavoriteApplications(state)
    },
    applicationFilterValue: getApplicationFilterValue(state),
    selectedOrganization: state.organizations.selectedOrganization,
    isLoading: getApplicationsLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      applications: bindActionCreators(applicationsActionCreators, dispatch),
      favoriteApplications: bindActionCreators(
        favoriteApplicationsActionCreators,
        dispatch
      ),
      featuredApplications: bindActionCreators(
        featuredApplicationsActionCreators,
        dispatch
      )
    }
  };
}

export { ConnectedApplications as Applications };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedApplications);
