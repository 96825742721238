import PropTypes from 'prop-types';
import React from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';

import { SecondaryButton } from '@ndustrial/nd-button-react';
import { Loader } from '@ndustrial/nd-react-common';
import {
  required,
  minimumLength,
  passwordMatch,
  composeValidators
} from '../utils/formValidators';
import ValidatedInput from './ValidatedInput';

import { $white, ndColorFailure } from '@ndustrial/contxt-common';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--nd-color-primary);
  transition: background-color 0.5s ease;
  color: ${$white};
`;

const StyledForm = styled.form`
  width: 400px;
`;

const StyledTitle = styled.h2`
  line-height: 2rem;
  font-weight: 400;
`;

const StyledField = styled(Field)`
  .nd-input {
    font-size: 0.825rem;
    height: 30px;
    flex: 1;
    margin-bottom: 10px;
    display: block;
    outline: none;
    border: 1px solid ${$white};
    padding: 8px 10px;
    width: 100%;
    color: ${$white};
    background: transparent;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: 0;
    -webkit-appearance: none;
  }

  .nd-input-error {
    border: 1px solid ${ndColorFailure};
  }

  .nd-input__label {
    font-size: 0.725rem;
    font-style: italic;
    font-weight: 700;
    line-height: 2rem;
  }

  .nd-input-label-error {
    background-color: ${$white};
    color: ${ndColorFailure};
    padding: 4px;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  :disabled {
    opacity: 0.5;
  }
`;

const propTypes = {
  className: PropTypes.string,
  formState: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

function ActivateUserForm({ className, formState, onSubmit }) {
  return (
    <Container className={className}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid }) => {
          return (
            <>
              <StyledTitle>
                Set a password to activate your account:
              </StyledTitle>
              {formState.error && (
                <span>
                  Something went wrong while activating your account. Please
                  contact your administrator.
                </span>
              )}
              <StyledForm onSubmit={handleSubmit}>
                <StyledField
                  name="password"
                  label="Password"
                  component={ValidatedInput}
                  validate={composeValidators(required, minimumLength(8))}
                  type="password"
                />
                <StyledField
                  name="passwordConfirm"
                  label="Repeat Password"
                  component={ValidatedInput}
                  validate={composeValidators(
                    required,
                    passwordMatch('password')
                  )}
                  type="password"
                />
                <StyledSecondaryButton
                  type="submit"
                  disabled={invalid || formState.isLoading}
                >
                  {formState.isLoading ? <Loader /> : 'Activate Account'}
                </StyledSecondaryButton>
              </StyledForm>
            </>
          );
        }}
      />
    </Container>
  );
}

ActivateUserForm.propTypes = propTypes;

export default ActivateUserForm;
