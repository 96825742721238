import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '@ndustrial/nd-button-react';
import { InputText } from '@ndustrial/nd-inputs-react';
import { OnAddGroupProps } from '../../common/hooks/useOnAddGroup';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${PrimaryButton} {
    padding: 15px;
    align-self: flex-end;
    border-radius: 5px;
  }
`;

const InputTextWrapper = styled.span`
  input {
    margin: 0;
  }
`;

interface OptionsWidgetProps {
  className?: string;
  onAddGroup: ({ name }: OnAddGroupProps) => void;
  isActive: boolean;
}

const AddGroupInput = React.forwardRef<HTMLDivElement, OptionsWidgetProps>(
  function AddGroupInputComponent(props, addGroupRef) {
    const { className, onAddGroup, isActive } = props;
    const nameInputRef = useRef<HTMLInputElement>(null);

    const addGroup = () => {
      if (nameInputRef.current?.value) {
        onAddGroup({ name: nameInputRef.current.value });
        nameInputRef.current.value = '';
      }
    };

    useEffect(() => {
      // For some reason, without the timeout, the focus function fires too quickly, and the input doesn't receive focus
      isActive && setTimeout(() => nameInputRef.current?.focus(), 100);
    }, [isActive]);

    return (
      <Wrapper ref={addGroupRef} className={className}>
        <InputTextWrapper>
          <InputText
            ref={nameInputRef}
            text={'Add group'}
            placeholder={'Enter a group name'}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                addGroup();
              }
            }}
          />
        </InputTextWrapper>
        <PrimaryButton onClick={() => addGroup()}>Save</PrimaryButton>
      </Wrapper>
    );
  }
);

export default AddGroupInput;
