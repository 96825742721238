import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import moment from 'moment';

import applicationListTypes from '../constants/applicationListTypes';

const getApplicationFilterValue = (state) => state.applications.filterValue;

const getRawApplications = (state) => state.applications.items;
const getRawApplicationsLoading = (state) => state.applications.isLoading;

const getFavoriteApps = (state) => state.favoriteApplications.items;
const getPendingFavoriteApps = (state) =>
  state.favoriteApplications.loadingItems;
const getFavoriteAppsLoading = (state) => state.favoriteApplications.isLoading;

const getFeaturedApps = (state) => state.featuredApplications.items;
const getFeaturedAppsLoading = (state) => state.featuredApplications.isLoading;

const getDerivedApplications = createSelector(
  [
    getRawApplications,
    getFavoriteApps,
    getPendingFavoriteApps,
    getFeaturedApps
  ],
  (apps, favoriteApps, pendingFavorites, featuredApps) => {
    return apps.map((app) => {
      const isFavorite = !!favoriteApps.find((f) => f.applicationId === app.id);
      const isLoading = pendingFavorites.indexOf(app.id) > -1;

      app.favorite = {
        enabled: isFavorite,
        isLoading
      };

      const featuredApp = featuredApps.find((f) => f.applicationId === app.id);
      if (featuredApp) {
        app.iconUrl = featuredApp.featuredIconUrl;
        app.type = applicationListTypes.FEATURED;
      }

      const { vhost, forceSsl, domain } = app.serviceInstance;
      const launchUrl = domain
        ? `${forceSsl ? 'https' : 'http'}://${vhost}.${domain.name}`
        : '';

      const fifteenDaysAgo = moment().subtract(15, 'days');
      const isNew = moment(app.createdAt).isAfter(fifteenDaysAgo);
      const isUpdated = moment(app.updatedAt).isAfter(fifteenDaysAgo);

      return {
        ...app,
        favorite: {
          enabled: isFavorite,
          isLoading
        },
        launchUrl,
        isNew,
        isUpdated
      };
    });
  }
);

const getApplications = createSelector(
  [getDerivedApplications, getApplicationFilterValue],
  (apps, filterValue) => {
    if (!filterValue.trim()) return apps;

    return apps.filter((app) => {
      return (
        app.name.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
      );
    });
  }
);

const getApplicationGroups = createSelector([getApplications], (apps) => {
  const appTypes = groupBy(apps, 'type');
  const appGroups = [];

  Object.keys(appTypes).forEach((type) => {
    appGroups.push({
      name: type,
      applications: appTypes[type]
    });
  });

  appGroups.sort((a, b) => {
    if (a.name === applicationListTypes.FEATURED) return -1;
    if (b.name === applicationListTypes.FEATURED) return 1;
  });

  return appGroups;
});

const getApplicationGroupings = (state) => state.applicationGroupings.groupings;

const getApplicationModules = createSelector(
  getApplicationGroupings,
  (applicationGroupings) => {
    if (!applicationGroupings) return [];

    return applicationGroupings.reduce((modules, grouping) => {
      return [...modules, ...grouping.applicationModules];
    }, []);
  }
);

const getApplicationsLoading = createSelector(
  [getRawApplicationsLoading, getFavoriteAppsLoading, getFeaturedAppsLoading],
  (appsLoading, favoritesLoading, featuredLoading) => {
    return appsLoading || favoritesLoading || featuredLoading;
  }
);

const getFavoriteApplications = createSelector([getApplications], (apps) => {
  return apps.filter((a) => a.favorite.enabled);
});

export {
  getApplications,
  getApplicationFilterValue,
  getApplicationGroups,
  getApplicationModules,
  getApplicationsLoading,
  getDerivedApplications,
  getFavoriteApplications
};
