import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { Header as NdHeader } from '@ndustrial/nd-header-react';
import {
  UserDropdown,
  UserDropdownItem
} from '@ndustrial/nd-user-dropdown-react';
import OrganizationSelector from '../components/OrganizationSelector';

import * as organizationsActionCreators from '../actions/organizations';
import * as userActionCreators from '../actions/user';
import { getProfile } from '../selectors/session';
import { headerHeight } from '../constants/styles';

import { contxtSdk } from '@ndustrial/contxt-common';

const Header = styled(NdHeader)`
  height: ${headerHeight}px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  img {
    max-width: 100%;
    max-height: 100%;
    padding: 15px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;

  img {
    padding: 0px;
  }
`;

class ConnectedHeader extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      organizations: PropTypes.shape({
        setSelectedOrganization: PropTypes.func.isRequired
      }),
      user: PropTypes.shape({
        loadUser: PropTypes.func.isRequired
      }).isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        orgSlug: PropTypes.string.isRequired
      })
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    profile: PropTypes.object,
    organizations: PropTypes.array,
    selectedOrganization: PropTypes.object,
    theme: PropTypes.object
  };

  onOrganizationSelect = ({ id, label }) => {
    this.props.actions.organizations.setSelectedOrganization({
      id,
      name: label
    });
  };

  handleMenuSelection = (value) => {
    switch (value) {
      case 'consent':
        return this.props.history.push('/settings/consent');
      case 'logOut':
        return contxtSdk.auth.logOut();
    }
  };

  render() {
    return (
      <Header appName="contxt" logoSrc={this.props.theme.logo}>
        <HeaderContent>
          <OrganizationSelector
            organizations={this.props.organizations}
            selectedOrganization={this.props.selectedOrganization}
            onOrganizationSelect={this.onOrganizationSelect}
          />
          <UserDropdown
            avatarSrc={this.props.profile.profileImage}
            onSelection={this.handleMenuSelection}
            userName={this.props.profile.userName}
          >
            <UserDropdownItem value="consent">User Consent</UserDropdownItem>
            <UserDropdownItem value="logOut">Log Out</UserDropdownItem>
          </UserDropdown>
        </HeaderContent>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: getProfile(state),
    organizations: state.organizations.items,
    selectedOrganization: state.organizations.selectedOrganization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(userActionCreators, dispatch),
      organizations: bindActionCreators(organizationsActionCreators, dispatch)
    }
  };
}

export { ConnectedHeader as Header };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ConnectedHeader));
