import React from 'react';
import styled from 'styled-components';
import { User } from './types/types';
import { Trash } from '@ndustrial/nd-icons-svg';

interface Props {
  user: User;
  onDeleteUser: (userId: string) => void;
}

const COLUMN_GAP = '1rem';
const INPUT_WIDTH = 250;
const INPUT_HEIGHT = 42;
const ENTITY_BORDER_WIDTH = '1px';
const FIELD_WRAPPER_PADDING = '1rem';

const FieldLabel = styled.label`
  width: 100px;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 400;

  @media screen and (min-width: 897px) and (orientation: landscape),
    screen and (min-width: 768px) and (orientation: portrait) {
    margin: 0;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: ${FIELD_WRAPPER_PADDING};
  column-gap: ${COLUMN_GAP};
  flex-wrap: wrap;
`;

const FieldInput = styled.input`
  width: ${INPUT_WIDTH}px;
  height: ${INPUT_HEIGHT}px;
  align-self: stretch;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  background-color: #fff;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.2px;
  margin-bottom: 0.5rem;

  &[disabled] {
    background-color: #fbfbfb;
  }

  @media screen and (min-width: 897px) and (orientation: landscape),
    screen and (min-width: 768px) and (orientation: portrait) {
    margin: 0;
  }
`;

const EntityField = styled.div`
  padding-right: ${FIELD_WRAPPER_PADDING};
  padding-left: ${FIELD_WRAPPER_PADDING};
`;

const EntityWrapper = styled.div`
  border: ${ENTITY_BORDER_WIDTH} solid #e3e3e3;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;

  ${FieldWrapper} {
    padding-left: 0;
    margin-bottom: 0.5rem;

    &:first-child {
      margin-top: 0.5rem;
    }
  }
`;

const ActionButtonsRow = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  padding: 5px calc(${FIELD_WRAPPER_PADDING} - ${ENTITY_BORDER_WIDTH});
  display: flex;
  align-items: center;
`;

const TrashIcon = styled(Trash)`
  stroke: ${({ theme }) => theme.colors.failure};
  height: 12px;
  width: 12px;
  cursor: pointer;
`;

const FacilityContactCard = (props: Props) => {
  return (
    <EntityWrapper>
      {
        <ActionButtonsRow>
          <TrashIcon
            onClick={() => {
              props.onDeleteUser(props.user.id);
            }}
          />
        </ActionButtonsRow>
      }
      <EntityField>
        <FieldWrapper>
          <FieldLabel>First Name</FieldLabel>
          <FieldInput
            disabled
            value={props.user.firstName}
            placeholder="First Name"
          />
        </FieldWrapper>
      </EntityField>
      <EntityField>
        <FieldWrapper>
          <FieldLabel htmlFor="lastName">Last Name</FieldLabel>
          <FieldInput
            disabled
            value={props.user.lastName}
            placeholder="Last Name"
          />
        </FieldWrapper>
      </EntityField>
      <EntityField>
        <FieldWrapper>
          <FieldLabel htmlFor="email">Email</FieldLabel>
          <FieldInput
            disabled
            value={props.user.email ?? ''}
            placeholder="Email"
          />
        </FieldWrapper>
      </EntityField>
    </EntityWrapper>
  );
};

export default FacilityContactCard;
