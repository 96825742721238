import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function accept(consentId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CONSENT_ACCEPT_START
    });

    return contxtSdk.coordinator.consent
      .accept(consentId)
      .then((userApproval) => {
        dispatch({
          type: actionTypes.CONSENT_ACCEPT_SUCCESS,
          payload: userApproval
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CONSENT_ACCEPT_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function loadConsent() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CONSENT_LOAD_START
    });

    return contxtSdk.coordinator.consent
      .getForCurrentApplication()
      .then((applicationConsent) => {
        dispatch({
          type: actionTypes.CONSENT_LOAD_SUCCESS,
          payload: applicationConsent
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CONSENT_LOAD_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

export { accept, loadConsent };
