import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { lighten } from 'polished';

import { getNavConfig } from '../selectors/navConfig';

const propTypes = {
  navConfig: PropTypes.array.isRequired,
  dynamicModules: PropTypes.array // TODO. specify
};

const Sidebar = styled.div`
  flex: 0 0 60px;
  background-color: #4b4a4a;
  display: flex;
  flex-direction: row;
  height: 100%;
  font-size: 9px;
  box-shadow: inset -3px 3px 3px rgba(0, 0, 0, 0.15);

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
    height: 100%;
    position: initial;
    width: 85px;
  `};
`;

const SidebarItemIcon = styled.div`
  display: flex;
  height: 41px;
  width: 41px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.2);

  svg {
    margin: auto;
    font-size: 15px;
    stroke: rgba(255, 255, 255, 0.7);
  }
`;

const SidebarItemLabel = styled.div`
  padding: 10px;
  padding-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
`;

const SidebarItem = styled.div`
  &.active {
    background: ${({ theme }) => theme.primary};
  }
  &:hover {
    background: ${({ theme }) => lighten(0.05, theme.primary)}};
  }
  :hover ${SidebarItemIcon} {
    background: rgba(0, 0, 0, 0.15);
    svg {
      stroke: rgba(255, 255, 255, 0.9);
    }
  }
  :hover ${SidebarItemLabel} {
    color: rgba(255, 255, 255, 0.9);
  }
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  height: 85px;
  width: 85px;

  ${({ theme }) => theme.media.tablet`
    border-right: 0;
    flex-direction: column;
    right: unset;
    top: 0;
  `};
`;

function ConnectedSidebar({ navConfig }) {
  if (!navConfig.length) {
    return null;
  }
  const content = navConfig.map((c) => {
    const { Icon } = c;

    const inner = (
      <React.Fragment>
        <SidebarItemIcon className="nd-sidebar__item-icon">
          <Icon stroke="#fff" />
        </SidebarItemIcon>
        <SidebarItemLabel className="nd-sidebar__item-label">
          {c.label}
        </SidebarItemLabel>
      </React.Fragment>
    );
    if (c.external) {
      return (
        <SidebarItem
          key={c.label}
          as="a"
          href={c.url}
          target="_blank"
          rel="noopener"
          title={c.label}
        >
          {inner}
        </SidebarItem>
      );
    } else {
      return (
        <SidebarItem
          key={c.label}
          activeClassName="active"
          as={NavLink}
          to={c.url}
          title={c.label}
        >
          {inner}
        </SidebarItem>
      );
    }
  });
  return <Sidebar>{content}</Sidebar>;
}

ConnectedSidebar.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    navConfig: getNavConfig(state)
  };
}

export { ConnectedSidebar as Sidebar };
export default connect(mapStateToProps, null)(ConnectedSidebar);
