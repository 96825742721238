import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  currentVersion: null,
  error: null,
  isLoading: true,
  isAccepting: false
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.CONSENT_LOAD_START:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case actionTypes.CONSENT_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentVersion: action.payload.currentVersion
      };
    case actionTypes.CONSENT_LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.CONSENT_ACCEPT_START:
      return {
        ...state,
        isAccepting: true,
        error: null
      };
    case actionTypes.CONSENT_ACCEPT_SUCCESS: {
      const currentVersion = {
        ...state.currentVersion,
        consent: {
          ...state.currentVersion.consent,
          userApproval: [action.payload]
        }
      };
      return {
        ...state,
        isAccepting: false,
        currentVersion
      };
    }
    case actionTypes.CONSENT_ACCEPT_FAILURE:
      return {
        ...state,
        isAccepting: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
