import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function addFavoriteApplication(applicationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.FAVORITE_APPLICATION_LOADING_START,
      payload: applicationId
    });

    return contxtSdk.coordinator.applications
      .addFavorite(applicationId)
      .then((favoriteApplication) => {
        dispatch({
          type: actionTypes.ADD_FAVORITE_APPLICATION_SUCCESS,
          payload: favoriteApplication
        });

        dispatch({
          type: actionTypes.FAVORITE_APPLICATION_LOADING_STOP,
          payload: applicationId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_FAVORITE_APPLICATION_FAILURE,
          error: true,
          payload: err
        });

        dispatch({
          type: actionTypes.FAVORITE_APPLICATION_LOADING_STOP,
          payload: applicationId
        });

        throw err;
      });
  };
}

function loadFavoriteApplications() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_FAVORITE_APPLICATIONS_START
    });

    return contxtSdk.coordinator.applications
      .getFavorites()
      .then((favoriteApplications) => {
        dispatch({
          type: actionTypes.LOAD_FAVORITE_APPLICATIONS_SUCCESS,
          payload: favoriteApplications
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_FAVORITE_APPLICATIONS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function removeFavoriteApplication(applicationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.FAVORITE_APPLICATION_LOADING_START,
      payload: applicationId
    });

    return contxtSdk.coordinator.applications
      .removeFavorite(applicationId)
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_FAVORITE_APPLICATION_SUCCESS,
          payload: applicationId
        });

        dispatch({
          type: actionTypes.FAVORITE_APPLICATION_LOADING_STOP,
          payload: applicationId
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REMOVE_FAVORITE_APPLICATION_FAILURE,
          error: true,
          payload: err
        });

        dispatch({
          type: actionTypes.FAVORITE_APPLICATION_LOADING_STOP,
          payload: applicationId
        });

        throw err;
      });
  };
}

export {
  addFavoriteApplication,
  loadFavoriteApplications,
  removeFavoriteApplication
};
