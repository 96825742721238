import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function activateUser(userId, user) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.ACTIVATE_USER_START
    });

    return contxtSdk.coordinator.users
      .activate(userId, user)
      .then(() => {
        dispatch({ type: actionTypes.ACTIVATE_USER_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ACTIVATE_USER_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function loadUser() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_USER_START
    });

    return contxtSdk.auth
      .getProfile()
      .then((auth0) => {
        return contxtSdk.coordinator.users.get(auth0.sub).then((userInfo) => {
          dispatch({
            type: actionTypes.LOAD_USER_SUCCESS,
            payload: {
              auth0,
              userInfo
            }
          });
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_USER_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

export { activateUser, loadUser };
