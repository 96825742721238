import React from 'react';
import { ToastContentProps } from 'react-toastify/dist/types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { FacilityGroup, toastDefaultColor } from '@ndustrial/contxt-common/src';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const StyledUndoButton = styled.button`
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px 15px;
  background-color: ${toastDefaultColor};
  outline: 0;
  border: none;
  border-radius: 3px;
  :hover {
    cursor: pointer;
    background-color: ${lighten(0.05, toastDefaultColor)};
  }
`;

export type GroupMovedToastContentProps = Partial<ToastContentProps> & {
  group: FacilityGroup;
  onUndoClick: (originalGroupId: string) => void;
};

export function GroupMovedToastContent({
  group,
  onUndoClick
}: GroupMovedToastContentProps) {
  return (
    <Wrapper>
      <div>
        Removed from existing group:
        <br />
        {group.name}
      </div>

      <StyledUndoButton onClick={() => onUndoClick(group.id)}>
        Undo
      </StyledUndoButton>
    </Wrapper>
  );
}
