import React from 'react';
import Select, {
  ActionMeta,
  MultiValue,
  OnChangeValue,
  SingleValue
} from 'react-select';
import { GroupedOption, Option } from '../types/types';

// The onChange type for the react-select component isn't able to be
// more specific based on the results of isMulti, while our component
// is. We'll just type onChange to this before passing it down, with
// the knowledge that our type is a more strict subset of the parent
// type
export type ReactSelectOnChange<V> =
  | ((
      newValue: SingleValue<Option<V>> | MultiValue<Option<V>>,
      actionMeta: ActionMeta<Option<V>>
    ) => void)
  | undefined;

type SelectPropsCommon<V> = {
  name: string;
  isLoading?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  options: Option<V>[] | GroupedOption<V>[];
  isOptionDisabled?: (option: Option<V>) => boolean;
};

type SelectPropsMulti<V> = {
  value?: readonly Option<V>[];
  defaultValue?: readonly Option<V>[];
  onChange: (option: OnChangeValue<Option<V>, true>) => void;
  isMulti: true;
};

type SelectPropsSingle<V> = {
  value?: SingleValue<Option<V>>;
  defaultValue?: SingleValue<Option<V>>;
  onChange: (option: OnChangeValue<Option<V>, false>) => void;
  isMulti: false;
};

export type SelectProps<V> = SelectPropsCommon<V> &
  (SelectPropsSingle<V> | SelectPropsMulti<V>);

export function ReactSelect<V>({
  name,
  options,
  value,
  onChange,
  isMulti,
  isLoading,
  placeholder,
  isDisabled,
  isClearable,
  defaultValue,
  isOptionDisabled
}: SelectProps<V>) {
  const className = `react-${isMulti ? 'multi' : 'single'}-select`;

  return (
    <Select
      className={className}
      name={name}
      options={options}
      value={value}
      onChange={onChange as ReactSelectOnChange<V>}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      defaultValue={defaultValue}
      isOptionDisabled={isOptionDisabled}
      closeMenuOnSelect={isMulti ? false : true}
    />
  );
}

ReactSelect.defaultProps = {
  isLoading: false,
  isDisabled: false,
  options: [],
  isMulti: false
};
