import styled from 'styled-components';

import { Loader as NdLoader, LoaderDot } from '@ndustrial/nd-loader-react';
import { $grayDark } from '../constants';

export const Loader = styled(NdLoader)`
  height: 100%;
  width: 100%;
  color: ${$grayDark};
  ${LoaderDot} {
    background-color: ${$grayDark};
  }
`;
