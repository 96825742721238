import React from 'react';
import PropTypes, { string } from 'prop-types';
import ReactTable, { ReactTableDefaults } from 'react-table';

import ActionsCell from './ActionsCell';
import Expander from './Expander';
import ExpandedUser from './ExpandedUser';
import styled from 'styled-components';
import { ndColorLink } from '@ndustrial/contxt-common';
import { reactTableCss } from '../../utils/reactTableCss';

const StyledReactTable = styled(ReactTable)`
  flex: 1;
  height: 100%;

  a {
    text-decoration-line: underline;
    font-weight: 400;
  }

  .nd-loader {
    padding: 0;
    justify-content: center;
  }

  .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const StyledEmail = styled.a`
  color: ${ndColorLink};
`;

const propTypes = {
  className: string,
  onRemoveUser: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      authType: PropTypes.string.isRequired,
      applications: PropTypes.array.isRequired,
      roles: PropTypes.array.isRequired,
      projectEnvironments: PropTypes.array.isRequired,
      isLoading: PropTypes.bool
    })
  ).isRequired
};

const columns = ({ onRemoveUser }) => [
  {
    expander: true,
    width: 50,
    Expander: Expander
  },
  {
    accessor: 'fullName',
    className: 'nd-user-table__cell',
    Header: 'Name',
    id: 'name',
    minResizeWidth: 50,
    minWidth: 100,
    maxWidth: 200
  },
  {
    accessor: 'email',
    Cell: function Cell(cell) {
      return (
        <StyledEmail href={`mailto:${cell.value}`}>{cell.value}</StyledEmail>
      );
    },
    className: 'nd-user-table__cell',
    Header: 'Email',
    id: 'email',
    minResizeWidth: 100,
    minWidth: 100,
    maxWidth: 300
  },
  {
    id: 'authType',
    Header: 'Auth Type',
    accessor: 'authType',
    className: 'nd-user-table__cell',
    minResizeWidth: 50,
    minWidth: 50,
    maxWidth: 200
  },
  {
    accessor: (c) => c.applications.length,
    Cell: function Cell({ value }) {
      return `${value} Apps`;
    },
    className: 'nd-user-table__cell',
    Header: 'Apps',
    id: 'apps',
    maxWidth: 100,
    minResizeWidth: 50,
    minWidth: 50
  },
  {
    accessor: (c) => c.roles.length,
    Cell: function Cell({ value }) {
      return `${value} Roles`;
    },
    className: 'nd-user-table__cell',
    Header: 'Roles',
    id: 'roles',
    maxWidth: 100,
    minResizeWidth: 50,
    minWidth: 50
  },
  {
    accessor: (c) => c.projectEnvironments.length,
    Cell: function Cell({ value }) {
      return `${value} Project Environments`;
    },
    className: 'nd-user-table__cell',
    Header: 'Project Environments',
    id: 'projectEnvironments',
    maxWidth: 200,
    minResizeWidth: 50,
    minWidth: 50
  },
  {
    Cell: function Cell(cell) {
      return <ActionsCell cell={cell} onRemoveUser={onRemoveUser} />;
    },
    className: 'nd-user-table__cell',
    Header: 'Actions',
    id: 'actions',
    maxWidth: 100
  }
];

function UsersTable({ className, onRemoveUser, users }) {
  const tableProps = {
    className: `${className} nd-user-table -striped -highlight`,
    column: {
      ...ReactTableDefaults.column,
      headerClassName: 'nd-user-table__header',
      minWidth: 100
    },
    showPageSizeOptions: false,
    defaultPageSize: 50
  };

  return (
    <StyledReactTable
      data={users}
      columns={columns({ onRemoveUser })}
      collapseOnDataChange={false}
      getTdProps={() => {
        return {
          style: {
            padding: '10px 15px',
            fontSize: '0.9rem',
            fontWeight: '400'
          }
        };
      }}
      getTheadThProps={() => {
        return {
          style: {
            padding: '14px 15px',
            fontSize: '0.8rem',
            fontWeight: '700',
            height: '50px',
            display: 'flex',
            alignItems: 'center'
          }
        };
      }}
      SubComponent={(row) => {
        return (
          <ExpandedUser
            id={row.original.id}
            applications={row.original.expandedRow.applications}
            projectEnvironments={row.original.expandedRow.projectEnvironments}
            roles={row.original.expandedRow.roles}
          />
        );
      }}
      {...tableProps}
    />
  );
}

UsersTable.propTypes = propTypes;

export default styled(UsersTable)`
  ${reactTableCss}
`;
