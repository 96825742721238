import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function loadFeaturedApplications(organizationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_FEATURED_APPLICATIONS_START
    });

    return contxtSdk.coordinator.applications
      .getFeatured(organizationId)
      .then((featuredApplications) => {
        dispatch({
          type: actionTypes.LOAD_FEATURED_APPLICATIONS_SUCCESS,
          payload: featuredApplications
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_FEATURED_APPLICATIONS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

export { loadFeaturedApplications };
