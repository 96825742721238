import React from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import styled from 'styled-components';
import { $grayLight, $grayVeryDark } from '@ndustrial/contxt-common';

const StyledFilterGroup = styled.div`
  padding: 20px 15px;
  background-color: ${$grayLight};
  font-weight: 500;
  color: ${$grayVeryDark};
  font-size: 0.85rem;
`;

const propTypes = {
  activeFilter: PropTypes.object.isRequired,
  filterGroup: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired
      })
    ).isRequired
  }).isRequired,
  onFilter: PropTypes.func.isRequired
};

function Grouping({ activeFilter, filterGroup, onFilter }) {
  return (
    <React.Fragment>
      {filterGroup.label ? (
        <StyledFilterGroup className="nd-users-access-filter__group">
          {filterGroup.label}
        </StyledFilterGroup>
      ) : null}
      {filterGroup.filters.map((filter) => (
        <Filter
          key={filter.value ? filter.value : 'all'}
          activeFilter={activeFilter}
          filter={filter}
          onFilter={onFilter}
        />
      ))}
    </React.Fragment>
  );
}

Grouping.propTypes = propTypes;

export default Grouping;
