import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as organizationUsersActionCreators from '../actions/organizationUsers';
import * as rolesActionCreators from '../actions/roles';
import * as usersPermissionsActionCreators from '../actions/usersPermissions';
import Users from './Users';
import Roles from './Roles';
import styled from 'styled-components';
import TabRouter from '@ndustrial/contxt-common/src/components/TabRouter';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 30px;
`;

const Header = styled.div`
  margin-bottom: 20px;
  font-size: 22px;
`;

class ConnectedAdmin extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      organizationUsers: PropTypes.shape({
        loadOrganizationUsers: PropTypes.func.isRequired
      }),
      usersPermissions: PropTypes.shape({
        loadAllUsersPermissions: PropTypes.func.isRequired
      }),
      roles: PropTypes.shape({
        loadRoles: PropTypes.func.isRequired
      })
    }).isRequired,
    selectedOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  };

  componentDidMount() {
    this.props.actions.organizationUsers.loadOrganizationUsers(
      this.props.selectedOrganization.id
    );
    this.props.actions.usersPermissions.loadAllUsersPermissions(
      this.props.selectedOrganization.id
    );
    this.props.actions.roles.loadRoles(this.props.selectedOrganization.id);
  }

  render() {
    return (
      <StyledContent className="nd-content nd-content--fullscreen">
        <Header>Access</Header>
        <TabRouter
          data={[
            {
              slug: 'users',
              label: 'Users',
              component: Users
            },
            {
              slug: 'roles',
              label: 'Roles',
              component: Roles
            }
          ]}
        />
      </StyledContent>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      organizationUsers: bindActionCreators(
        organizationUsersActionCreators,
        dispatch
      ),
      usersPermissions: bindActionCreators(
        usersPermissionsActionCreators,
        dispatch
      ),
      roles: bindActionCreators(rolesActionCreators, dispatch)
    }
  };
}

export { ConnectedAdmin as Admin };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAdmin);
