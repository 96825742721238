import styled from 'styled-components';

const BaseHeading = styled.div`
  padding: 0;
  margin: 0.5rem 0;
`;

const HeadingH1 = styled(BaseHeading)`
  font-size: 1.25rem;
  font-weight: 400;
`;

const HeadingH2 = styled(BaseHeading)`
  font-size: 1.15rem;
  font-weight: 400;
`;

export { HeadingH1, HeadingH2 };
