import {
  Wrapper,
  Menu,
  MenuItem,
  DropdownButton
} from '@ndustrial/nd-dropdown-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledDropdownWrapper = styled(Wrapper)`
  font-size: 6px;
  width: 300px;
`;

const StyledDropdownButton = styled(DropdownButton)`
  font-size: 0.75rem;
  font-weight: 500;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.75rem;
  font-weight: 500;
`;

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

function OrganizationSelector({
  organizations,
  selectedOrganization,
  onOrganizationSelect
}) {
  return (
    <StyledDropdownWrapper
      className="organization-selector"
      onSelection={(value) =>
        onOrganizationSelect({
          id: organizations.find((x) => x.name === value).id,
          label: value
        })
      }
    >
      <StyledDropdownButton>{selectedOrganization.name}</StyledDropdownButton>
      <Menu>
        {organizations.map((org) => (
          <StyledMenuItem key={org.id}>{org.name}</StyledMenuItem>
        ))}
      </Menu>
    </StyledDropdownWrapper>
  );
}

OrganizationSelector.propTypes = propTypes;

export default OrganizationSelector;
