import React, { useMemo } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { InputTextField, InputLabel } from '@ndustrial/nd-inputs-react';
import { nameof } from '@ndustrial/contxt-common';
import Select, { SingleValue } from 'react-select';
import { COUNTRIES, US_STATES } from './constants';
import {
  Facility as FacilityType,
  useGetTimezonesQuery
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import {
  EditorInputLabelStyle,
  EditorInputTextFieldStyle,
  ReactSelectSingleValueStyles
} from '@ndustrial/contxt-common/src';

const AddressWrapper = styled.div`
  display: flex;
`;

const AddressInnerSectionLeft = styled.div`
  flex: 1;
  padding-right: 20px;
`;
const AddressInnerSectionRight = styled.div`
  flex: 1;
`;

const StyledInputTextField = styled(InputTextField)`
  ${EditorInputTextFieldStyle}
`;

const StyledInputLabel = styled(InputLabel)`
  ${EditorInputLabelStyle}
`;

interface Props {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  facilityData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateField: (fieldName: string, newValue: any) => void;
  inputStyle: FlattenSimpleInterpolation;
  fieldWhitelist?: string[];
}

const FacilityEditorHeader = (props: Props) => {
  const { data: timezoneData } = useGetTimezonesQuery();

  function onStateSelectChange(
    selection: SingleValue<{
      value: string;
      label: string;
    }>
  ) {
    props.updateField(nameof<FacilityType>('state'), selection?.value);
  }

  function onTimezoneSelectChange(
    selection: SingleValue<{
      value: string;
      label: string;
    }>
  ) {
    props.updateField(nameof<FacilityType>('timezoneName'), selection?.value);
  }

  function onCountryChange(
    selection: SingleValue<{
      value: string;
      label: string;
    }>
  ) {
    const newValue = selection != null ? selection.value : null;
    props.updateField(nameof<FacilityType>('country'), newValue);
  }

  const resolveCountryValue = (code?: string) => {
    if (code == null) {
      return null;
    }

    const foundCountry = COUNTRIES.find(
      (country: SingleValue<{ value: string; label: string }>) =>
        country?.value === code
    );
    return foundCountry;
  };

  const memoizedStyle = useMemo(() => {
    return ReactSelectSingleValueStyles(40);
  }, []);

  const selectedStateValue = useMemo<
    SingleValue<{ value: string; label: string }> | undefined
  >(() => {
    return US_STATES.find(
      (state: SingleValue<{ value: string; label: string }>) =>
        state?.value === (props.facilityData as FacilityType).state
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [US_STATES, props.facilityData?.state]);

  const memoizedTimezoneOptions = useMemo<
    { value: string; label: string }[]
  >(() => {
    if (timezoneData && timezoneData.timezones) {
      const usersTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const toReturn = timezoneData.timezones.nodes
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((item) => item.name)
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
        .filter((timezone) => timezone !== usersTimezone)
        .map((timezone) => {
          return {
            label: timezone,
            value: timezone
          };
        });

      toReturn?.unshift({
        label: usersTimezone,
        value: usersTimezone
      }); // adds to front of array

      return toReturn;
    } else {
      return [];
    }
  }, [timezoneData]);

  const selectedTimezoneValue = useMemo<
    { value: string; label: string } | undefined
  >(() => {
    return memoizedTimezoneOptions.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (timezone: any) =>
        timezone.value === (props.facilityData as FacilityType).timezoneName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedTimezoneOptions, props.facilityData?.timezoneName]);

  return (
    <div className={props.className}>
      {(!props.fieldWhitelist ||
        props.fieldWhitelist.includes(nameof<FacilityType>('name'))) && (
        <StyledInputTextField
          id={`facility-editor-name`}
          key={`facility-editor-name`}
          label="Name"
          required
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) =>
            props.updateField(nameof<FacilityType>('name'), event.target.value)
          }
          value={props.facilityData['name'] ?? ''}
        />
      )}
      {(!props.fieldWhitelist ||
        props.fieldWhitelist.includes(nameof<FacilityType>('address'))) && (
        <StyledInputTextField
          id={`facility-editor-address`}
          key={`facility-editor-address`}
          label="Address"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) =>
            props.updateField(
              nameof<FacilityType>('address'),
              event.target.value
            )
          }
          value={props.facilityData['address'] ?? ''}
        />
      )}
      {(!props.fieldWhitelist ||
        props.fieldWhitelist.includes(nameof<FacilityType>('address'))) && (
        <AddressWrapper>
          <AddressInnerSectionLeft>
            <StyledInputLabel htmlFor="facility-editor-country" $showPointer>
              Country
            </StyledInputLabel>
            <Select<SingleValue<{ value: string; label: string }>>
              id="facility-editor-country"
              isClearable
              options={COUNTRIES}
              onChange={onCountryChange}
              placeholder="Select a Country..."
              styles={memoizedStyle}
              value={resolveCountryValue(props.facilityData['country'])}
            />
            <StyledInputTextField
              id={`facility-editor-city`}
              key={`facility-editor-city`}
              label="City"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) =>
                props.updateField(
                  nameof<FacilityType>('city'),
                  event.target.value
                )
              }
              value={props.facilityData['city'] ?? ''}
            />
            <StyledInputTextField
              id={`facility-editor-zip`}
              key={`facility-editor-zip`}
              label="ZIP"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) =>
                props.updateField(
                  nameof<FacilityType>('zip'),
                  event.target.value
                )
              }
              value={props.facilityData['zip'] ?? ''}
            />
          </AddressInnerSectionLeft>
          <AddressInnerSectionRight>
            <StyledInputLabel
              htmlFor="facility-editor-state"
              $showPointer={true}
            >
              State
            </StyledInputLabel>
            <Select<SingleValue<{ value: string; label: string }>>
              id="facility-editor-state"
              options={US_STATES}
              placeholder="Select a State..."
              isClearable
              onChange={onStateSelectChange}
              value={selectedStateValue}
              styles={memoizedStyle}
            />
            <StyledInputLabel
              htmlFor="facility-editor-timezone"
              $showPointer={true}
            >
              Timezone
            </StyledInputLabel>
            <Select<SingleValue<{ value: string; label: string }>>
              id="facility-editor-timezone"
              options={memoizedTimezoneOptions}
              placeholder="Select a Timezone..."
              onChange={onTimezoneSelectChange}
              value={selectedTimezoneValue}
              styles={memoizedStyle}
            />
          </AddressInnerSectionRight>
        </AddressWrapper>
      )}
    </div>
  );
};

export default FacilityEditorHeader;
