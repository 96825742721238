import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoading: true,
  items: [],
  loadingItems: [],
  error: null
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.ADD_FAVORITE_APPLICATION_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    case actionTypes.ADD_FAVORITE_APPLICATION_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case actionTypes.FAVORITE_APPLICATION_LOADING_START:
      return {
        ...state,
        error: INITIAL_STATE.error,
        loadingItems: [...state.loadingItems, action.payload]
      };
    case actionTypes.FAVORITE_APPLICATION_LOADING_STOP:
      return {
        ...state,
        loadingItems: state.loadingItems.filter((x) => x !== action.payload)
      };
    case actionTypes.LOAD_FAVORITE_APPLICATIONS_START:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error
      };
    case actionTypes.LOAD_FAVORITE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case actionTypes.LOAD_FAVORITE_APPLICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        items: [],
        error: action.payload
      };
    case actionTypes.REMOVE_FAVORITE_APPLICATION_SUCCESS:
      return {
        ...state,
        items: state.items.filter((x) => x.applicationId !== action.payload)
      };
    case actionTypes.REMOVE_FAVORITE_APPLICATION_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
