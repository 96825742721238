import { atom } from 'recoil';

export const facilityGroupingSearchAtom = atom<string>({
  key: `FacilityGroupingSearchValueAtom`,
  default: ''
});

export const expandToFacilityIdAtom = atom<number>({
  key: `FacilityGroupingExpandToFacilityId`,
  default: -1
});
