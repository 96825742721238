import { css } from 'styled-components';
import { ndInputBorderColor, ndInputPlaceholderColor } from '../../constants';

export const InputTextFieldMarginTop = 15;
export const InputTextFieldMarginBottom = 10;

export const EditorInputTextFieldStyle = css`
  width: 100%;
  margin-top: ${InputTextFieldMarginTop}px;
  margin-bottom: ${InputTextFieldMarginBottom}px;

  div {
    font-style: normal !important;
  }

  input {
    margin-bottom: 0;
    border: 1px solid ${ndInputBorderColor};

    ::placeholder {
      color: ${ndInputPlaceholderColor};
    }
  }
  label {
    font-weight: 400;
  }
`;
