import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { defaultTheme } from '@ndustrial/nd-theme-react';
import { orderBy } from 'lodash';

import { Close, PlusCircle } from '@ndustrial/nd-icons-svg';

import { Menu, MenuList, MenuButton, MenuItem } from './Menu';
import MenuCloseCallback from './MenuCloseCallback';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  associateById: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  disabledItems: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

const defaultProps = {
  selectedItems: [],
  disabledItems: []
};

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 3em;
  margin-bottom: 0;
  padding: 1em;
  outline: none;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};
`;

const MenuItemOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: ${({ disabled }) => (disabled ? '#A0A0A0' : 'inherit')};
  padding: 1em;
`;

const AddResourceButton = styled(PlusCircle)`
  stroke: ${({ theme }) => theme.primary};
  cursor: pointer;
`;

class ResourceSelect extends Component {
  constructor() {
    super();

    this.state = {
      filterValue: '',
      filteredItems: []
    };
  }

  componentDidMount() {
    this.resetSearch();
  }

  resetSearch = () => {
    this.setState({
      filterValue: ''
    });
    this.sortAndFilterItems();
  };

  sortAndFilterItems = () => {
    let filteredItems;

    if (this.state.filterValue) {
      filteredItems = this.props.items.filter(
        ({ name }) =>
          name.toLowerCase().indexOf(this.state.filterValue.toLowerCase()) !==
          -1
      );
    } else {
      filteredItems = this.props.items;
    }

    const selected = filteredItems.filter(
      (item) => this.props.selectedItems.indexOf(item.id) > -1
    );

    const unselected = filteredItems.filter(
      (item) => this.props.selectedItems.indexOf(item.id) === -1
    );

    this.setState({
      filteredItems: [
        ...orderBy(selected, 'name', 'asc'),
        ...orderBy(unselected, 'name', 'asc')
      ]
    });
  };

  handleSearch = (event) => {
    const filterValue = event.target.value.toLowerCase();

    this.setState({
      filterValue
    });
    this.sortAndFilterItems();
  };

  mapItemToOption = (item) => {
    const isDisabled = this.props.disabledItems.indexOf(item.id) > -1;
    const isSelected = this.props.selectedItems.indexOf(item.id) > -1;

    const option = (
      <MenuItemOption key={item.id} disabled={isDisabled}>
        <div>{item.name}</div>
        {isSelected ? <Close fill={defaultTheme.text} /> : null}
      </MenuItemOption>
    );

    if (isDisabled) {
      return option;
    }

    return (
      <MenuItem
        key={item.id}
        onSelect={() => {
          this.props.onSelect(
            this.props.associateById ? item.id : item.slug || item.id
          );
        }}
      >
        {option}
      </MenuItem>
    );
  };

  render() {
    return (
      <div>
        <Menu suppressClassNameWarning>
          {({ isOpen }) => (
            <Fragment>
              <MenuButton aria-label="Application Select">
                <AddResourceButton />
                <MenuCloseCallback isOpen={isOpen} onClose={this.resetSearch} />
              </MenuButton>

              <MenuList>
                <SearchInput
                  placeholder="Search"
                  value={this.state.filterValue}
                  onChange={this.handleSearch}
                />
                {this.state.filteredItems.map(this.mapItemToOption)}
              </MenuList>
            </Fragment>
          )}
        </Menu>
      </div>
    );
  }
}

ResourceSelect.propTypes = propTypes;
ResourceSelect.defaultProps = defaultProps;

export default ResourceSelect;
