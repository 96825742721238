import { css } from 'styled-components';
import { InputTextFieldMarginTop } from './StyledTextInput';

export interface InputLabelProps {
  $showPointer: boolean;
}

export const EditorInputLabelStyle = css<InputLabelProps>`
  margin-top: ${InputTextFieldMarginTop}px;
  margin-bottom: 5px;
  font-weight: 400;
  font-style: normal;

  cursor: ${({ $showPointer }) => ($showPointer ? 'pointer' : 'default')};
`;
