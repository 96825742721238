import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function filterOrganizationsByValue(filterValue) {
  return {
    type: actionTypes.FILTER_ORGANIZATIONS_BY_VALUE,
    payload: filterValue
  };
}

function loadOrganizations() {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_ORGANIZATIONS_START
    });

    return contxtSdk.coordinator.organizations
      .getAll()
      .then((organizations) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATIONS_SUCCESS,
          payload: organizations
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATIONS_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function setSelectedOrganization({ id, name }) {
  contxtSdk.coordinator.setOrganizationId(id);
  return {
    type: actionTypes.SET_SELECTED_ORGANIZATION,
    payload: { id, name }
  };
}

export {
  filterOrganizationsByValue,
  loadOrganizations,
  setSelectedOrganization
};
