import { NotificationManager } from 'react-notifications';
import { get } from 'lodash';

import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function loadRoles(organizationId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.LOAD_ORGANIZATION_ROLES_START
    });

    return contxtSdk.coordinator.roles
      .getByOrganizationId(organizationId)
      .then((roles) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_ROLES_SUCCESS,
          payload: roles
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_ROLES_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function createRole(role) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.CREATE_ROLE_START
    });

    return contxtSdk.coordinator.roles
      .create(role.organization_id, role)
      .then((role) => {
        role.applications = [];
        role.projectEnvironments = [];
        dispatch({
          type: actionTypes.CREATE_ROLE_SUCCESS,
          payload: role
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_ROLE_FAILURE,
          error: true,
          payload: err
        });

        throw err;
      });
  };
}

function removeRole(organizationId, roleId) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.REMOVE_ROLE_LOADING_START,
      payload: roleId
    });

    return contxtSdk.coordinator.roles
      .delete(organizationId, roleId)
      .then(() => {
        NotificationManager.success('Successfully deleted role.');

        dispatch({
          type: actionTypes.REMOVE_ROLE_SUCCESS,
          payload: roleId
        });

        dispatch({
          type: actionTypes.REMOVE_ROLE_LOADING_STOP,
          payload: roleId
        });
      })
      .catch((err) => {
        const errorMessage = get(err, 'response.data.message', err.message);
        NotificationManager.error(errorMessage);

        dispatch({
          type: actionTypes.REMOVE_ROLE_FAILURE,
          error: true,
          payload: err
        });

        dispatch({
          type: actionTypes.REMOVE_ROLE_LOADING_STOP,
          payload: roleId
        });

        throw err;
      });
  };
}

function filterRolesByValue(filterValue) {
  return {
    type: actionTypes.FILTER_ROLES_BY_VALUE,
    payload: filterValue
  };
}

function addApplication(roleId, application) {
  return function (dispatch) {
    return contxtSdk.coordinator.roles
      .addApplication(roleId, application.id)
      .then(() => {
        dispatch({
          type: actionTypes.ADD_ROLE_APPLICATION_SUCCESS,
          payload: {
            roleId: roleId,
            application: application
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_ROLE_APPLICATION_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function removeApplication(roleId, applicationId) {
  return function (dispatch) {
    return contxtSdk.coordinator.roles
      .removeApplication(roleId, applicationId)
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_ROLE_APPLICATION_SUCCESS,
          payload: {
            roleId: roleId,
            applicationId: applicationId
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REMOVE_ROLE_APPLICATION_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function addProjectEnvironment(roleId, projectEnvironment, access_type) {
  return function (dispatch) {
    return contxtSdk.coordinator.roles
      .addProjectEnvironment(roleId, projectEnvironment.id, access_type)
      .then(() => {
        dispatch({
          type: actionTypes.ADD_ROLE_PROJECT_ENVIRONMENT_SUCCESS,
          payload: {
            roleId,
            projectEnvironment
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_ROLE_PROJECT_ENVIRONMENT_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function removeProjectEnvironment(roleId, projectEnvironmentId) {
  return function (dispatch) {
    return contxtSdk.coordinator.roles
      .removeProjectEnvironment(roleId, projectEnvironmentId)
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_ROLE_PROJECT_ENVIRONMENT_SUCCESS,
          payload: {
            roleId,
            projectEnvironmentId
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REMOVE_ROLE_PROJECT_ENVIRONMENT_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}
export {
  addApplication,
  addProjectEnvironment,
  filterRolesByValue,
  loadRoles,
  createRole,
  removeApplication,
  removeRole,
  removeProjectEnvironment
};
