import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoading: true,
  items: [],
  newOrganizationForm: {
    error: null,
    isLoading: false
  },
  selectedOrganization: {},
  error: null,
  valueFilter: ''
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATIONS_START:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error
      };

    case actionTypes.LOAD_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };

    case actionTypes.LOAD_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actionTypes.SET_SELECTED_ORGANIZATION: {
      let org = state.items.find((o) => o.id === action.payload.id);
      return {
        ...state,
        selectedOrganization: org
      };
    }

    case actionTypes.FILTER_ORGANIZATIONS_BY_VALUE:
      return {
        ...state,
        valueFilter: action.payload
      };

    case actionTypes.CREATE_ORGANIZATION_START:
      return {
        ...state,
        newOrganizationForm: {
          error: null,
          isLoading: true
        }
      };

    case actionTypes.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        newOrganizationForm: {
          ...INITIAL_STATE.newOrganizationForm
        }
      };

    case actionTypes.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        newOrganizationForm: {
          error: action.payload,
          isLoading: false
        }
      };

    default:
      return state;
  }
}

export default reducer;
