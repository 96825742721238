import { nameof, Organization } from '@ndustrial/contxt-common/src';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AttributeEditor from './AttributeEditor';
import { AttributeEditorURLParams } from './AttributeEditorURLParams';

export const attributeEditorBasePath = `/organizations/:${nameof<AttributeEditorURLParams>(
  'orgSlug'
)}/asset-manager/attribute-editor`;

function AttributeEditorContainer() {
  const urlParams = useParams<AttributeEditorURLParams>();
  const history = useHistory();
  const selectedOrganization = useSelector(
    (state: { organizations: { selectedOrganization: Organization } }) =>
      state.organizations.selectedOrganization
  );

  function onGoBack() {
    history.push(
      attributeEditorBasePath.replace(
        `:${nameof<AttributeEditorURLParams>('orgSlug')}`,
        selectedOrganization.slug
      )
    );
  }
  // function onDelete(error?: string) {
  //   if (error) {
  //     toast.error(error);
  //   } else {
  //     history.goBack();
  //     toast.success('Successfully Deleted Facility');
  //   }
  // }

  return (
    <AttributeEditor
      onGoBack={onGoBack}
      onSubmit={() => {
        console.log('submit');
      }}
      // onDelete={onDelete}
      isAddNew={urlParams.name === 'new'}
      attributeName={urlParams.name}
    />
  );
}

export default AttributeEditorContainer;
