import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Button, ButtonGroup } from '@ndustrial/nd-button-react';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any
};

function ToggleButtonGroupComponent(props) {
  let { children, className, onChange, value } = props;

  const handleChange = (event) => {
    if (!onChange) {
      return;
    }

    onChange(event.target.value);
  };

  const isValueSelected = (buttonValue) => {
    if (buttonValue === undefined || value === undefined) {
      return false;
    }

    return value === buttonValue;
  };

  return (
    <ToggleButtonGroup className={className}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          selected: isValueSelected(child.props.value),
          onClick: handleChange
        });
      })}
    </ToggleButtonGroup>
  );
}

ToggleButtonGroupComponent.propTypes = propTypes;

const ToggleButton = styled(Button)`
  background-color: ${({ theme, selected }) =>
    selected ? theme.primary : '#fff'};
  color: ${({ theme, selected }) => (selected ? '#fff' : theme.text)};
`;

const DisabledToggleButton = styled(ToggleButton)`
  background-color: ${({ selected }) => (selected ? '#a1a1a1' : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#ccc')};
`;

const ToggleButtonGroup = styled(ButtonGroup)`
  border: 1px solid #d3d3d3;

  > button {
    font-size: 0.625rem;
    margin: 0;

    border: 0;
    border-right: 1px solid #d3d3d3;

    &:last-child {
      border-right: 0;
    }
  }
`;

export {
  DisabledToggleButton,
  ToggleButton,
  ToggleButtonGroupComponent as ToggleButtonGroup
};
