import { defaults, flatMap, sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

const getRawRoles = (state) => state.roles.byId;
const getRawRolesOrder = (state) => state.roles.itemOrder;
const getRawUsers = (state) => state.organizationUsers.items;
const getRawUserPerms = (state) => state.usersPermissions.items;
const getRawRolesValueFilter = (state) =>
  state.roles.valueFilter.trim().toLowerCase();
const getRawRolesLoading = (state) => state.roles.isLoading;
const getRoleById = (state, roleId) => state.roles.byId[roleId];
const getSelectedOrgId = (state) =>
  state.organizations.selectedOrganization &&
  state.organizations.selectedOrganization.id;

const getUsers = createSelector([getRawUsers], (users) => {
  return users.map((user) => {
    return {
      ...user,
      name: user.firstName + ' ' + user.lastName
    };
  });
});

const getRoles = createSelector(
  [getRawRoles, getRawRolesOrder],
  (roles, order) => order.map((id) => roles[id])
);

const getRoleUsers = createSelector([getRawUserPerms], (rawUserPerms) => {
  const roleUsers = {};
  rawUserPerms.forEach((user) => {
    user.roles.forEach((r) => {
      roleUsers[r.id] = roleUsers[r.id] || [];
      roleUsers[r.id].push({
        id: user.userId,
        externallyMapped: r.externallyMapped
      });
    });
  });
  return roleUsers;
});

const getRoleApplications = createSelector([getRoles], (roles) => {
  return sortBy(uniqBy(flatMap(roles, 'applications'), 'id'), 'name');
});

const getRoleEnvironments = createSelector(
  [getRoles, getSelectedOrgId],
  (roles, selectedOrg) => {
    return sortBy(
      uniqBy(flatMap(roles, 'projectEnvironments'), 'id').reduce((acc, s) => {
        if (s && s.organizationId === selectedOrg) {
          acc.push({
            id: s.id,
            name: s.name,
            slug: s.slug,
            organizationId: s.organizationId
          });
        }
        return acc;
      }, []),
      'name'
    );
  }
);

const getRoleApplicationResourceList = createSelector(
  [getRoleById, getRoleApplications],
  (role, applications) => {
    return getResourceList(role.applications, applications);
  }
);

const getRoleEnvironmentResourceList = createSelector(
  [getRoleById, getRoleEnvironments],
  (role, projectEnvironments) => {
    return getResourceList(role.projectEnvironments, projectEnvironments);
  }
);

const getRoleUserResourceList = createSelector(
  [getRoleById, getRoleUsers, getUsers],
  (role, roleUsers, users) => {
    return getResourceList(roleUsers[role.id], users);
  }
);

const getResourceList = (resourceList = [], availableList = []) => {
  let resources = [];
  let available = [];
  availableList.forEach((a) => {
    const resource = resourceList.find((r) => r.id === a.id);
    if (resource) {
      resources.push(defaults({}, a, resource));
    } else {
      available.push(defaults({}, a));
    }
  });
  return {
    resources,
    available
  };
};

const getFilteredRoles = createSelector(
  [getRoles, getRawRolesValueFilter, getRoleUsers, getSelectedOrgId],
  (roles, valueFilter, roleUsers, selectedOrgId) => {
    let filteredRoles;
    const rolesWithFilteredEnvs = roles.map((role) => {
      role.projectEnvironments = role.projectEnvironments.filter(
        (pE) => pE.organizationId === selectedOrgId
      );
      return role;
    });
    if (!valueFilter) {
      filteredRoles = rolesWithFilteredEnvs;
    } else {
      filteredRoles = rolesWithFilteredEnvs.filter((role) => {
        return (
          role.name.toLowerCase().indexOf(valueFilter) > -1 ||
          role.description.toLowerCase().indexOf(valueFilter) > -1
        );
      });
    }

    filteredRoles = filteredRoles.map((r) => {
      r.users = roleUsers[r.id] || [];
      return r;
    });

    return filteredRoles;
  }
);

const getRolesLoading = createSelector([getRawRolesLoading], (rolesLoading) => {
  return rolesLoading;
});

const getRolesValueFilter = createSelector(
  [getRawRolesValueFilter],
  (valueFilter) => {
    return valueFilter;
  }
);

export {
  getFilteredRoles,
  getResourceList,
  getRoleApplicationResourceList,
  getRoleApplications,
  getRoleById,
  getRoles,
  getRoleEnvironmentResourceList,
  getRoleEnvironments,
  getRolesLoading,
  getRolesValueFilter,
  getRoleUserResourceList,
  getRoleUsers,
  getUsers
};
