import { SingleValue, StylesConfig } from 'react-select';
import { ndInputBorderColor, ndInputPlaceholderColor } from '../../constants';
import { defaultTheme } from '@ndustrial/nd-theme-react';
import { css } from 'styled-components';

export const FieldMarginTop = 15;
export const FieldMarginBottom = 10;

export const InputTextFieldStyle = css`
  width: 100%;
  margin-top: ${FieldMarginTop}px;
  margin-bottom: ${FieldMarginBottom}px;

  div {
    font-style: normal !important;
  }

  input {
    margin-bottom: 0;
    border: 1px solid ${ndInputBorderColor};

    ::placeholder {
      color: ${ndInputPlaceholderColor};
    }
  }
  label {
    font-weight: 400;
  }
`;

export interface InputLabelProps {
  $showPointer: boolean;
}

export const InputLabelStyle = css<InputLabelProps>`
  margin-top: ${FieldMarginTop}px;
  margin-bottom: 5px;
  font-weight: 400;
  font-style: normal;

  cursor: ${({ $showPointer }) => ($showPointer ? 'pointer' : 'default')};
`;

export function ReactSelectSingleValueStyles(
  height: number,
  isInvalid?: boolean
): StylesConfig<
  SingleValue<{
    value: string;
    label: string;
  }>
> {
  return {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: isInvalid ? '#da5f73' : ndInputBorderColor,
      borderRadius: '0',
      minHeight: `${height}px`,
      height: `${height}px`,
      color: defaultTheme.colors.text,
      fontWeight: '400 !important',
      boxShadow: 'none',
      '&:hover:not(:disabled)': {
        background: '#00000005',
        borderColor: isInvalid
          ? '#da5f73'
          : state.isFocused
          ? defaultTheme.colors.primary
          : defaultTheme.uiKitInput.border,
        cursor: 'pointer'
      }
    }),

    valueContainer: (provided) => ({
      ...provided,
      height: `${height}px`,
      padding: '0 10px'
    }),

    input: (provided) => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: `${height}px`
    }),
    option: (provided, state) => ({
      ...provided,
      color: defaultTheme.colors.text,
      fontWeight: '400 !important',
      cursor: 'pointer',
      backgroundColor: state.isSelected
        ? defaultTheme.uiKitInput.border
        : 'transparent',
      '&:hover': {
        backgroundColor: defaultTheme.uiKitInput.border
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: defaultTheme.colors.text,
      fontWeight: '400 !important',
      marginLeft: '0'
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: ndInputPlaceholderColor
      };
    }
  };
}
