import {
  Building,
  Database,
  Hamburger,
  Profiles,
  Buildings,
  Grid
} from '@ndustrial/nd-icons-svg';

const navStructure = [
  {
    id: 1,
    label: 'Apps',
    url: '/organizations/{org}/applications',
    Icon: Grid,
    applicationModuleSlugs: ['@ndustrial/contxt-applications']
  },
  {
    id: 2,
    label: 'Projects',
    url: 'https://developers.ndustrial.io/organizations/{orgId}/projects',
    Icon: Hamburger,
    external: true,
    applicationModuleSlugs: ['@ndustrial/contxt-projects']
  },
  {
    id: 3,
    label: 'Access',
    url: '/organizations/{org}/admin',
    Icon: Profiles,
    applicationModuleSlugs: ['@ndustrial/contxt-user-management']
  },
  {
    id: 4,
    label: 'Assets',
    url: '/organizations/{org}/asset-manager',
    Icon: Buildings,
    applicationModuleSlugs: [
      '@ndustrial/facility-editor',
      '@ndustrial/hierarchy-editor',
      '@ndustrial/facility-groupings'
    ]
  },
  {
    id: 5,
    label: 'Provisioning',
    Icon: Building,
    url: '/organizations/{org}/provisioning',
    applicationModuleSlugs: ['@ndustrial/contxt-provisioning']
  },
  {
    id: 6,
    label: 'Data',
    Icon: Database,
    url: '/organizations/{org}/data-explorer',
    applicationModuleSlugs: [
      '@ndustrial/data-explorer-viewer',
      '@ndustrial/data-explorer-editor'
    ]
  }
];

export default navStructure;
