import React from 'react';
import styled from 'styled-components';

import IconButton from '../IconButton';

import { Close } from '@ndustrial/nd-icons-svg';
import { NdReactModalHeaderProps } from '../../types/types';
import { kebabCase } from 'lodash';
import { ndInputBorderColor } from '../../constants';

const StyledLabel = styled.div<{
  $customHeaderStyles: React.CSSProperties | undefined;
}>`
  font-size: 1.3em;
  flex: 1;
  ${({ theme, $customHeaderStyles }) =>
    $customHeaderStyles && Object.keys($customHeaderStyles).includes('color')
      ? ''
      : `color: ${theme.colors.text};`};
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledCloseIcon = styled(Close)`
  height: 40px;
  width: 40px;
  flex: 0;

  g {
    stroke-width: 1;
  }
`;

const StyledIconButton = styled(IconButton)<{
  $customHeaderStyles: React.CSSProperties | undefined;
}>`
  position: relative;
  bottom: -10px;
  right: -10px;
  ${({ $customHeaderStyles }) =>
    $customHeaderStyles && Object.keys($customHeaderStyles).includes('color')
      ? `svg { stroke: ${$customHeaderStyles.color}; }`
      : ''}
`;

interface StyledDivProps {
  $styles?: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  height: 50px;
  border-bottom: 1px solid ${ndInputBorderColor};
  align-items: flex-end;
  position: relative;
  padding-bottom: 8px;
  ${(props) => `${props.$styles}`};
`;

function convertCssToString(styles: React.CSSProperties | undefined) {
  if (styles) {
    return Object.keys(styles).reduce((accumulator, key) => {
      // transform the key from camelCase to kebab-case
      const cssKey = kebabCase(key);
      // remove ' in value
      const cssValue = styles[key as keyof React.CSSProperties]
        ?.toString()
        .replace("'", '');
      // build the result
      return `${accumulator}${cssKey}:${cssValue};`;
    }, '');
  } else return '';
}

const ModalHeader = ({
  className,
  title,
  onCloseClick,
  customStyles
}: NdReactModalHeaderProps) => {
  return (
    <StyledDiv className={className} $styles={convertCssToString(customStyles)}>
      <StyledLabel
        // these are only selectively used
        $customHeaderStyles={customStyles}
      >
        {title}
      </StyledLabel>
      <StyledIconButton
        // these are only selectively used
        $customHeaderStyles={customStyles}
        onClick={onCloseClick}
      >
        <StyledCloseIcon />
      </StyledIconButton>
    </StyledDiv>
  );
};

export default ModalHeader;
