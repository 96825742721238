import React, { useMemo } from 'react';
import styled from 'styled-components';

import GroupingItemContainer from './GroupingItemContainer';
import {
  FacilityGroup,
  useAllGroupsQuery
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { createGroupingTree } from '../../utils/groupingUtilities';
import {
  Loader,
  Section,
  SectionHeaderHeight
} from '@ndustrial/contxt-common/src';

const GroupingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: calc(100% - ${SectionHeaderHeight}px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledSection = styled(Section)`
  height: 100%;
  flex: 5;
`;

const GroupingContainerWithIndex = styled(GroupingItemContainer)<{
  zIndex?: number;
}>`
  z-index: ${({ zIndex }) => zIndex || 0};
`;

export interface FacilityGroupingTree extends FacilityGroup {
  groupings: FacilityGroupingTree[];
  facilityCount: number;
  groupCount: number;
}

const GroupingsContainer = () => {
  const { loading, data, error } = useAllGroupsQuery();

  const groupingTree = useMemo(() => {
    return createGroupingTree(data?.facilityGroups?.nodes as FacilityGroup[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loader label="LOADING GROUPINGS" />;
  if (error) return <div>`Error! ${error.message}`</div>;

  return (
    <StyledSection
      title="Groupings"
      tooltip="Define your facility groupings by creating new ones or adding to an existing one. You may also nest groupings inside other groupings by dragging them onto each other."
    >
      <GroupingsWrapper>
        {groupingTree?.map(
          (grouping: FacilityGroupingTree, index, allGroupings) => (
            <GroupingContainerWithIndex
              zIndex={allGroupings.length - index}
              key={grouping.id}
              group={grouping}
              isRoot={true}
            />
          )
        )}
      </GroupingsWrapper>
    </StyledSection>
  );
};

export default GroupingsContainer;
