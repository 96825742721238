import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Close } from '@ndustrial/nd-icons-svg';
import { Chip, ChipIcon, ChipLabel } from '@ndustrial/nd-chip-react';
import {
  ProjectEnvironmentChip,
  ProjectEnvironmentChipLabel
} from '../components/UsersTable/ProjectEnvironmentChip';

import * as organizationUsersActionCreators from '../actions/organizationUsers';
import * as rolesActionCreators from '../actions/roles';
import * as usersPermissionsActionCreators from '../actions/usersPermissions';
import ResourceList from '../components/ResourceList';
import { ToggleButton, ToggleButtonGroup } from '../components/ToggleButton';
import {
  getRoleApplicationResourceList,
  getRoleById,
  getRoleEnvironmentResourceList,
  getRoleUserResourceList
} from '../selectors/roles';

const ResourceGroup = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid #e6e6e6;
`;

class ConnectedExpandedRole extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      organizationUsers: PropTypes.shape({
        addRole: PropTypes.func.isRequired,
        removeRole: PropTypes.func.isRequired
      }),
      roles: PropTypes.shape({
        addApplication: PropTypes.func.isRequired,
        addProjectEnvironment: PropTypes.func.isRequired,
        removeApplication: PropTypes.func.isRequired,
        removeProjectEnvironment: PropTypes.func.isRequired
      }),
      usersPermissions: PropTypes.shape({
        loadUserPermissions: PropTypes.func.isRequired
      })
    }),
    applications: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    }),
    id: PropTypes.string.isRequired,
    role: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    selectedOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    projectEnvironments: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          roleEnvironmentAccess: PropTypes.shape({
            accessType: PropTypes.oneOf(['reader', 'admin']).isRequired
          }).isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired
        })
      )
    }),
    users: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    })
  };

  handleAddApplication = (applicationId) => {
    return this.props.actions.roles.addApplication(
      this.props.id,
      this.props.applications.available.find((a) => a.id === applicationId)
    );
  };

  handleRemoveApplication = (applicationId) => {
    return this.props.actions.roles.removeApplication(
      this.props.id,
      applicationId
    );
  };

  handleAddProjectEnvironment = (
    projectEnvironmentId,
    accessType = 'reader'
  ) => {
    return this.props.actions.roles.addProjectEnvironment(
      this.props.id,
      {
        ...this.props.projectEnvironments.available.find(
          (s) => s.id === projectEnvironmentId
        ),
        roleEnvironmentAccess: {
          accessType
        }
      },
      accessType
    );
  };

  handleRemoveProjectEnvironment = (projectEnvironmentId) => {
    return this.props.actions.roles.removeProjectEnvironment(
      this.props.id,
      projectEnvironmentId
    );
  };

  handleProjectEnvironmentAccessTypeChange = (
    projectEnvironmentId,
    accessType
  ) => {
    return this.handleRemoveProjectEnvironment(projectEnvironmentId).then(
      () => {
        return this.handleAddProjectEnvironment(
          projectEnvironmentId,
          accessType
        );
      }
    );
  };

  handleAddUser = (userId) => {
    return this.props.actions.organizationUsers
      .addRole(userId, this.props.id)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          userId
        );
      });
  };

  handleRemoveUser = (userId) => {
    return this.props.actions.organizationUsers
      .removeRole(userId, this.props.id)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          userId
        );
      });
  };

  render() {
    return (
      <ResourceGroup>
        <ResourceList
          available={this.props.applications.available}
          label="Applications"
          onAdd={this.handleAddApplication}
          onRemove={this.handleRemoveApplication}
          readOnly={false}
          resources={this.props.applications.resources}
        />
        <ResourceList
          available={this.props.projectEnvironments.available}
          label="Project Environments"
          onRemove={this.handleRemoveProjectEnvironment}
          onAdd={this.handleAddProjectEnvironment}
          associateById={true}
          readOnly={false}
          resources={this.props.projectEnvironments.resources}
        >
          {(resource) => (
            <ProjectEnvironmentChip key={resource.id}>
              <ChipIcon
                onClick={() => {
                  this.handleRemoveProjectEnvironment(resource.id);
                }}
              >
                <Close />
              </ChipIcon>
              <ProjectEnvironmentChipLabel>
                {resource.name}
              </ProjectEnvironmentChipLabel>
              <ToggleButtonGroup
                onChange={(value) =>
                  this.handleProjectEnvironmentAccessTypeChange(
                    resource.id,
                    value
                  )
                }
                value={resource.roleEnvironmentAccess.accessType}
              >
                <ToggleButton value="reader">Reader</ToggleButton>
                <ToggleButton value="admin">Admin</ToggleButton>
              </ToggleButtonGroup>
            </ProjectEnvironmentChip>
          )}
        </ResourceList>
        <ResourceList
          available={this.props.users.available}
          label="Users"
          onRemove={this.handleRemoveUser}
          onAdd={this.handleAddUser}
          readOnly={this.props.role.name === 'user'}
          resources={this.props.users.resources}
        >
          {(resource) => (
            <Chip key={resource.id}>
              {!(
                resource.externallyMapped || this.props.role.name === 'user'
              ) && (
                <ChipIcon
                  onClick={() => {
                    this.handleRemoveUser(resource.id);
                  }}
                >
                  <Close />
                </ChipIcon>
              )}
              <ChipLabel>{resource.name}</ChipLabel>
            </Chip>
          )}
        </ResourceList>
      </ResourceGroup>
    );
  }
}

function mapStateToProps(state, { id }) {
  return {
    applications: getRoleApplicationResourceList(state, id),
    selectedOrganization: state.organizations.selectedOrganization,
    projectEnvironments: getRoleEnvironmentResourceList(state, id),
    users: getRoleUserResourceList(state, id),
    role: getRoleById(state, id)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      organizationUsers: bindActionCreators(
        organizationUsersActionCreators,
        dispatch
      ),
      roles: bindActionCreators(rolesActionCreators, dispatch),
      usersPermissions: bindActionCreators(
        usersPermissionsActionCreators,
        dispatch
      )
    }
  };
}

export { ConnectedExpandedRole as ExpandedRole };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedExpandedRole);
