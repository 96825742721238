import PropTypes from 'prop-types';
import React from 'react';

import DefaultImage from '../assets/default-app.svg';

const propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string
};

function ApplicationImage({ alt, src }) {
  return (
    <img className="nd-application-image" src={src || DefaultImage} alt={alt} />
  );
}

ApplicationImage.propTypes = propTypes;

export default ApplicationImage;
