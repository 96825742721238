import React, { useEffect, useState } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache
} from '@apollo/client';
import { contxtSdk } from '@ndustrial/contxt-common';
import { apolloClientList } from './global';

export interface TenantClientMapping {
  [client: string]: ApolloClient<NormalizedCacheObject>;
}

export interface ApolloProviderWrapperProps {
  orgSlug: string;
  children: React.ReactChild;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contxtSdk?: any;
}

export const ApolloProviderWrapper = ({
  orgSlug,
  children
}: ApolloProviderWrapperProps) => {
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();

  useEffect(() => {
    const buildClient = async () => {
      const token = await contxtSdk.auth.getCurrentApiToken('nionic');
      const uri =
        contxtSdk.nionic._baseUrl.replace('<tenant>', orgSlug) + '/graphql';

      apolloClientList[orgSlug] = new ApolloClient({
        uri,
        headers: {
          Authorization: `Bearer ${token}`
        },
        cache: new InMemoryCache()
      });
      setApolloClient(apolloClientList[orgSlug]);
    };

    // check if we've used this org client yet, if not, create a new one
    if (!apolloClientList[orgSlug]) {
      buildClient();
    }
    // if we have, we'll just use it
    else {
      setApolloClient(apolloClientList[orgSlug]);
    }
  }, [orgSlug]);

  return apolloClient ? (
    <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
  ) : null;
};
