import { css } from 'styled-components';

import { defaultTheme } from '@ndustrial/nd-theme-react';

const sizes = {
  tablet: 768,
  desktop: 992
};

const media = Object.keys(sizes).reduce((accum, label) => {
  accum[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;

  return accum;
}, {});

const theme = {
  ...defaultTheme,
  colors: { ...defaultTheme },
  sizes,
  media,
  fonts: {}
};

const getTheme = function (slug) {
  let newTheme;
  switch (slug) {
    case 'lineage':
      newTheme = {
        ...theme,
        logo: require('../images/Lineage_logo.svg')
      };
      break;
    case 'novozymes':
      newTheme = {
        ...theme,
        logo: require('../images/novozymes_logo_white.svg'),
        primary: '#2d0028',
        secondary: '#c5da00'
      };
      break;
    default:
      newTheme = {
        ...theme,
        logo: require('../images/logo_white.svg'),
        primary: '#45acad',
        secondary: '#0067A0'
      };
  }

  document.documentElement.style.setProperty(
    '--nd-color-primary',
    newTheme.primary
  );
  document.documentElement.style.setProperty(
    '--nd-color-secondary',
    newTheme.secondary
  );
  return newTheme;
};

export default getTheme;
