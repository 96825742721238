import React, { useState } from 'react';
import styled from 'styled-components';

import { Building } from '@ndustrial/nd-icons-svg';
import { LabelSpan } from '@ndustrial/contxt-common';
import HierarchyItemConstants from '../../common/constants/HierarchyItemConstants';
import TrashAction from '../../common/TrashAction';
import { Facility } from '@ndustrial/contxt-common/src/graphql/graphql.generated';

const FacilityActions = styled.span`
  position: relative;
  display: none;
  flex: 0 0 60px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
`;

const FacilityWrapper = styled.div<{ actionsAreVisible: boolean }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: ${HierarchyItemConstants.ITEM_HEIGHT}px;

  &:hover {
    ${FacilityActions} {
      display: flex;
    }
  }

  ${({ actionsAreVisible }) =>
    actionsAreVisible &&
    `
    ${FacilityActions} {
      display: flex;
    }
  `}
`;

const BuildingWrapper = styled.span`
  height: 1.5rem;
  width: 1.5rem;
`;

const FacilityContent = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 5px;
`;

const FacilityNameAndIcon = styled.span`
  display: flex;
  gap: 0.25rem;
  align-items: flex-start;
`;

const FacilityName = styled.span`
  font-weight: 400;
`;

const FacilityLocationAndIcon = styled.span`
  display: flex;
  gap: 0.25rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #5e5e5e;
`;

const FacilityLocation = styled.span`
  font-weight: 400;
  color: #272727;
`;

export interface FacilityProps {
  facility: Facility;
  className?: string;
  onRemove?: () => void;
  removeText?: string;
}

const FacilityItemInHierarchy = React.forwardRef<HTMLDivElement, FacilityProps>(
  function AddGroupInputComponent(props, ref) {
    const { facility, className, onRemove, removeText } = props;
    const [trashOptionOpen, setTrashOptionsOpen] = useState(false);

    return (
      <FacilityWrapper
        className={className}
        actionsAreVisible={trashOptionOpen}
        ref={ref}
      >
        <FacilityContent>
          <FacilityNameAndIcon>
            <BuildingWrapper>
              <Building />
            </BuildingWrapper>
            <FacilityName>{facility.name}</FacilityName>
          </FacilityNameAndIcon>
          <FacilityLocationAndIcon>
            <LabelSpan>Location:</LabelSpan>
            <FacilityLocation>
              {facility.city}, {facility.state}
            </FacilityLocation>
          </FacilityLocationAndIcon>
        </FacilityContent>
        <FacilityActions>
          {onRemove && (
            <TrashAction
              isOpen={trashOptionOpen}
              onToggleOpen={setTrashOptionsOpen}
              onRemoveFromGroup={() => onRemove()}
              removeText={removeText}
            />
          )}
        </FacilityActions>
      </FacilityWrapper>
    );
  }
);

export default FacilityItemInHierarchy;
