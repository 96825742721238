import React from 'react';
import { FacilityEditorURLParams } from './common/FacilityEditorURLParams';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import FacilityEditor from './FacilityEditor';
import { useSelector } from 'react-redux';
import { Organization } from '@ndustrial/contxt-common/src/types/types';
import { nameof } from '@ndustrial/contxt-common/src';

const NEW_FACILITY = 'new';

export const facilityEditorBasePath = `/organizations/:${nameof<FacilityEditorURLParams>(
  'orgSlug'
)}/asset-manager/facility-editor`;

export interface FacilityEditorContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contxtSdk: any;
}

const FacilityEditorContainer = (props: FacilityEditorContainerProps) => {
  const urlParams = useParams<FacilityEditorURLParams>();
  const history = useHistory();
  const selectedOrganization = useSelector(
    (state: { organizations: { selectedOrganization: Organization } }) =>
      state.organizations.selectedOrganization
  );

  function onSubmit(error?: string, warning?: string) {
    if (error) {
      toast.error(error);
    } else if (warning) {
      toast.warning(warning);
    } else {
      toast.success('Saved!');
    }
  }

  function onDelete(error?: string) {
    if (error) {
      toast.error(error);
    } else {
      history.goBack();
      toast.success('Successfully Deleted Facility');
    }
  }

  function onGoBack() {
    history.push(
      facilityEditorBasePath.replace(
        `:${nameof<FacilityEditorURLParams>('orgSlug')}`,
        selectedOrganization.slug
      )
    );
  }

  return (
    <FacilityEditor
      organization={selectedOrganization}
      facilityId={
        urlParams.facilityId === NEW_FACILITY
          ? undefined
          : parseInt(urlParams.facilityId!)
      }
      onSubmit={onSubmit}
      onDelete={onDelete}
      canDelete={true}
      onGoBack={onGoBack}
      contxtSdk={props.contxtSdk}
    />
  );
};

export default FacilityEditorContainer;
