import { get } from 'lodash';
import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as organizationsActionCreators from '../actions/organizations';
import LoadingPage from '../components/LoadingPage';
import Header from './Header';
import Sidebar from './Sidebar';
import styled from 'styled-components';
import { headerHeight } from '../constants/styles';
import { ndPageBackground } from '@ndustrial/contxt-common';

const StyledMain = styled.div`
  display: flex;
  height: calc(100vh - ${headerHeight}px);
`;

const StyledContentWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: ${ndPageBackground};
`;

class ConnectedOrgContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      organizations: PropTypes.shape({
        setSelectedOrganization: PropTypes.func.isRequired
      })
    }).isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    organizations: PropTypes.array,
    selectedOrganization: PropTypes.object
  };

  getOrgSlugFromURL() {
    const urlSegments = this.props.location.pathname.split('/');
    if (urlSegments.length > 2 && urlSegments[1] === 'organizations') {
      return urlSegments[2];
    }
    return undefined;
  }

  componentDidMount() {
    const selectedOrganization =
      this.props.organizations.find(
        (o) => o.slug === this.getOrgSlugFromURL()
      ) || this.props.organizations[0];

    this.props.actions.organizations.setSelectedOrganization(
      selectedOrganization
    );

    if (
      !this.props.location.pathname.startsWith(
        '/organizations/' + selectedOrganization.slug
      )
    ) {
      this.props.history.replace(
        `/organizations/${selectedOrganization.slug}/applications`
      );
    }
  }

  componentDidUpdate(prevProps) {
    const slug = get(this.props, 'selectedOrganization.slug');
    const prevSlug = get(prevProps, 'selectedOrganization.slug');

    if (prevSlug && prevSlug !== slug) {
      this.props.history.push(`/organizations/${slug}/applications`);
    }

    if (this.props.location.pathname === '/') {
      this.props.history.replace(`/organizations/${slug}/applications`);
    }
  }

  render() {
    if (!this.props.selectedOrganization.id) {
      return <LoadingPage label="LOADING" />;
    }
    return (
      <React.Fragment>
        <Route path="/organizations/:orgSlug" component={Header} />
        <StyledMain className="nd-main">
          <Route path="/organizations/:orgSlug" component={Sidebar} />
          <StyledContentWrapper className="nd-content__wrapper">
            {this.props.children}
          </StyledContentWrapper>
        </StyledMain>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    organizations: state.organizations.items,
    selectedOrganization: state.organizations.selectedOrganization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      organizations: bindActionCreators(organizationsActionCreators, dispatch)
    }
  };
}

export { ConnectedOrgContainer as OrgContainer };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConnectedOrgContainer)
);
