import React, { useState } from 'react';
import styled from 'styled-components';
import { InputText } from '@ndustrial/nd-inputs-react';
import { PrimaryButton, SecondaryButton } from '@ndustrial/nd-button-react';
import { Modal } from '@ndustrial/contxt-common';

const StyledDeleteConfirmContent = styled.p`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 400;
  margin: 0;
`;

const StyledDeleteInput = styled(InputText)`
  width: 200px;
`;

const StyledDeleteConfirmFooter = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
`;

const StyledDeleteConfirmButton = styled(PrimaryButton)`
  flex: 1;
  margin: auto 10px;
`;

const StyledDeleteCancelButton = styled(SecondaryButton)`
  flex: 1;
  margin: auto 10px;
  background-color: white;
`;

export interface ConfirmDeleteModalProps {
  onDelete: () => void;
  onRequestClose: () => void;
  // ex: Facility, Attribute, ex
  objectType: string;
  objectValue: string;
}

const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const [deleteInputValue, setDeleteInputValue] = useState<string>();

  const confirmDeleteText = `DELETE ${props.objectType.toUpperCase()}`;

  function onDeleteConfirmClick() {
    if (deleteInputValue === confirmDeleteText) {
      props.onDelete();
    }
  }

  return (
    <Modal
      isOpen={true}
      hideHeader={true}
      onRequestClose={() => {
        props.onRequestClose();
      }}
      customStyles={{
        height: 270,
        width: 500,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0
      }}
    >
      <StyledDeleteConfirmContent>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 600,
            marginBottom: 15,
            marginTop: 15
          }}
        >
          Delete{' '}
          {props.objectType.charAt(0).toUpperCase() + props.objectType.slice(1)}
          ?
          <br />
        </div>
        <div style={{ fontSize: '14px' }}>
          This will permanently delete the following{' '}
          {props.objectType.toLowerCase()}:
        </div>
        <div style={{ fontSize: '14px', marginTop: 15, marginBottom: 20 }}>
          {props.objectValue}
        </div>
        <div style={{ fontSize: '14px', marginBottom: 5 }}>
          Type &quot;{confirmDeleteText}&quot; to confirm
        </div>
        <StyledDeleteInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDeleteInputValue(e.currentTarget.value);
          }}
          type="text"
        />
        <StyledDeleteConfirmFooter>
          <StyledDeleteCancelButton
            onClick={() => {
              setDeleteInputValue('');
              props.onRequestClose();
            }}
          >
            Cancel
          </StyledDeleteCancelButton>
          <StyledDeleteConfirmButton onClick={onDeleteConfirmClick}>
            Confirm
          </StyledDeleteConfirmButton>
        </StyledDeleteConfirmFooter>
      </StyledDeleteConfirmContent>
    </Modal>
  );
};
export default ConfirmDeleteModal;
