import React from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';

import { Drag } from '@ndustrial/nd-icons-svg';
import { FacilityGroupingDropTypes } from './constants/dropTypes';

type DraggableWrapperProps = {
  level: number;
  className?: string;
  isDragging: boolean;
};

const StyledDrag = styled(Drag)`
  font-size: 1.75rem;
  stroke: #c2c2c2;
`;

const DragHandle = styled.span`
  width: 30px;
  cursor: grab;
  visibility: hidden;

  &:active {
    cursor: grabbing;
  }
`;

const DraggableWrapper = styled.div<DraggableWrapperProps>`
  display: flex;  
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  transition: opacity .2s linear;  
  background-color: #fff;
  height: 50px;
  
  padding-left: ${({ level }) => `calc(${level * 1}rem + 0.3rem) };`}

  &:hover {
    background-color: #e7f2ff;
    ${DragHandle} {
      visibility: visible;
    }
  }

  &:active {
    opacity: ${({ isDragging }) => (isDragging ? 0.25 : 1)};
  }
`;

const DraggableInner = styled.div`
  flex-grow: 1;
`;

export interface DraggableProps<T> {
  type: FacilityGroupingDropTypes;
  item: T;
  handleLeftOffset?: number;
  children: React.ReactNode;
}

function Draggable<T>({
  type,
  item,
  handleLeftOffset = 0,
  children
}: DraggableProps<T>) {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type,
      item,
      previewOptions: {},
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [item]
  );

  return (
    <DraggableWrapper
      level={handleLeftOffset}
      ref={dragPreview}
      isDragging={isDragging}
    >
      <DragHandle ref={drag} role={'Handle'}>
        <StyledDrag />
      </DragHandle>
      <DraggableInner>{children}</DraggableInner>
    </DraggableWrapper>
  );
}

export default Draggable;
