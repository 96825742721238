import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import styled from 'styled-components';

import { PrimaryButton, WarningButton } from '@ndustrial/nd-button-react';
import { getProfile } from '../selectors/session';

const tableColumns = [
  {
    accessor: 'name',
    className: 'nd-user-table__cell',
    Header: 'Name'
  },
  {
    accessor: 'service',
    className: 'nd-user-table__cell',
    Header: 'Service'
  },
  {
    accessor: 'link',
    className: 'nd-user-table__cell',
    Header: 'Link',
    Cell: function Cell(cell) {
      const { value } = cell;
      return <a href={value}>{value}</a>;
    }
  }
];

const tableData = [
  {
    name: 'Auth0',
    service: 'Identify Management',
    link: 'https://auth0.com'
  },
  {
    name: 'Pendo',
    service: 'User Engagement',
    link: 'https://pendo.io'
  },
  {
    name: 'Google',
    service: 'Analytics Tracking',
    link: 'https://google.com/analytics'
  },
  {
    name: 'Amazon AWS',
    service: 'Messaging',
    link: 'https://amazon.com/aws'
  },
  {
    name: 'Squarespace',
    service: 'Website Hosting',
    link: 'https://squarespace.com'
  },
  {
    name: 'Mailchimp',
    service: 'Email Marketing',
    link: 'https://mailchimp.com'
  },
  {
    name: 'Mandrill',
    service: 'Email Platform',
    link: 'https://mandrill.com'
  }
];

const tableProps = {
  className: 'nd-user-table -striped -highlight',
  showPagination: false,
  minRows: 0
};

const Container = styled.main`
  padding: 10px;
`;
const Section = styled.section`
  margin-bottom: 20px;
`;
const Heading = styled.h2``;
const Paragraph = styled.p`
  margin-bottom: 10px;
  width: 50%;
  font-size: 0.8rem;
  line-height: 0.9rem;
`;

const ButtonGroup = styled.div`
  > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

ButtonGroup.displayName = 'ButtonGroup';

const propTypes = {
  userProfile: PropTypes.shape({
    userName: PropTypes.string.isRequired
  })
};

function ConnectedYourData({ userProfile }) {
  const dataRequestHref = `mailto:privacy@ndustrial.io?subject=Data request for ${userProfile.userName}&body=Please provide me with all of the data for ${userProfile.userName}`;
  const dataRemoveHref = `mailto:privacy@ndustrial.io?subject=Data removal request for ${userProfile.userName}&body=Please remove all of the data for ${userProfile.userName}`;

  return (
    <Container>
      <Section>
        <Heading>Information that we have:</Heading>
        <ButtonGroup>
          <PrimaryButton as="a" href={dataRequestHref}>
            Request my data
          </PrimaryButton>
          <WarningButton as="a" href={dataRemoveHref}>
            Remove my data
          </WarningButton>
        </ButtonGroup>
      </Section>
      <Section>
        <Heading>3rd Party Vendors</Heading>
        <Paragraph>
          We work with third party vendors and that will often include passing
          your information to them for processing. These vendors provide their
          own safegaurds to ensure that when that data is transfered, it is
          handled properly to ensure security and privacy. Visit their pages
          below to learn more about how they handle your data.
        </Paragraph>
        <ReactTable
          data={tableData}
          columns={tableColumns}
          getTdProps={() => {
            return {
              style: {
                padding: '10px 15px',
                fontSize: '0.9rem',
                fontWeight: '400'
              }
            };
          }}
          getTheadThProps={() => {
            return {
              style: {
                padding: '14px 15px',
                fontSize: '0.8rem',
                fontWeight: '700',
                height: '50px',
                display: 'flex',
                alignItems: 'center'
              }
            };
          }}
          {...tableProps}
        />
      </Section>
    </Container>
  );
}

ConnectedYourData.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    userProfile: getProfile(state)
  };
}

export { ConnectedYourData as YourData };
export default connect(mapStateToProps)(ConnectedYourData);
