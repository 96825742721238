import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigFloat: any;
  BigInt: any;
  Cursor: any;
  Date: any;
  Datetime: any;
  JSON: any;
  UUID: any;
  Upload: any;
};

export enum AccessTokenType {
  MACHINE = 'MACHINE',
  USER = 'USER'
}

/** A filter to be used against AccessTokenType fields. All fields are combined with a logical ‘and.’ */
export type AccessTokenTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AccessTokenType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AccessTokenType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AccessTokenType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AccessTokenType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AccessTokenType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AccessTokenType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AccessTokenType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AccessTokenType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AccessTokenType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AccessTokenType>>;
};

export type AccountCharge = Node & {
  __typename?: 'AccountCharge';
  /** Reads a single `AccountStatement` that is related to this `AccountCharge`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId: Scalars['Int'];
  /** The category of the account charge. */
  category: UtilityAccountChargeCategory;
  /** The time the charge was created. */
  createdAt: Scalars['Datetime'];
  /** The time the charge was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** The end date of the account charge. */
  intervalEnd: Scalars['Date'];
  /** The start date of the account charge. */
  intervalStart: Scalars['Date'];
  /** The label of the account charge. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present */
  lineNumber: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** Overrides the normalized month of the account_statement. */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Overrides the normalized year of the account_statement. */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  statementDate: Scalars['Date'];
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  /** The time the charge was updated. */
  updatedAt: Scalars['Datetime'];
  /** This original value of the charge. */
  value: Scalars['Float'];
};

export type AccountChargeAggregates = {
  __typename?: 'AccountChargeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AccountChargeAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<AccountChargeDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AccountChargeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AccountChargeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AccountChargeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AccountChargeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AccountChargeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AccountChargeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AccountChargeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AccountChargeVarianceSampleAggregates>;
};

export type AccountChargeAverageAggregates = {
  __typename?: 'AccountChargeAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Mean average of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Mean average of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Mean average of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `AccountCharge` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccountChargeCondition = {
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<UtilityAccountChargeCategory>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `normalizedValue` field. */
  normalizedValue?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideStatementMonth` field. */
  overrideStatementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `overrideStatementYear` field. */
  overrideStatementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `statementDate` field. */
  statementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['Float']>;
};

export type AccountChargeDistinctAggregates = {
  __typename?: 'AccountChargeDistinctAggregates';
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of category across the matching connection */
  category?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['JSON']>;
  /** Distinct of label across the matching connection */
  label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of lineNumber across the matching connection */
  lineNumber?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Distinct of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideValue across the matching connection */
  overrideValue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of statementDate across the matching connection */
  statementDate?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of value across the matching connection */
  value?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type AccountChargeDistinctCountAggregates = {
  __typename?: 'AccountChargeDistinctCountAggregates';
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementDate across the matching connection */
  statementDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AccountCharge` object types. All fields are combined with a logical ‘and.’ */
export type AccountChargeFilter = {
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountChargeFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UtilityAccountChargeCategoryFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<DateFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `normalizedValue` field. */
  normalizedValue?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountChargeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountChargeFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideStatementMonth` field. */
  overrideStatementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `overrideStatementYear` field. */
  overrideStatementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `statementDate` field. */
  statementDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

/** An input for mutations affecting `AccountCharge` */
export type AccountChargeInput = {
  /** Mutates a `AccountStatement` as part of the payload */
  accountStatement?: InputMaybe<AccountStatementInput>;
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** The category of the account charge. */
  category: UtilityAccountChargeCategory;
  id?: InputMaybe<Scalars['Int']>;
  /** The end date of the account charge. */
  intervalEnd: Scalars['Date'];
  /** The start date of the account charge. */
  intervalStart: Scalars['Date'];
  /** The label of the account charge. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present */
  lineNumber?: InputMaybe<Scalars['Int']>;
  statementDate: Scalars['Date'];
  /** This original value of the charge. */
  value: Scalars['Float'];
};

export type AccountChargeMaxAggregates = {
  __typename?: 'AccountChargeMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Maximum of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Maximum of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  /** Maximum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Maximum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type AccountChargeMinAggregates = {
  __typename?: 'AccountChargeMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Minimum of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Minimum of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  /** Minimum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Minimum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** Represents an update to a `AccountCharge`. Fields that are set will be updated. */
export type AccountChargePatch = {
  label?: InputMaybe<Scalars['String']>;
  /** Overrides the normalized month of the account_statement. */
  overrideStatementMonth?: InputMaybe<Scalars['Int']>;
  /** Overrides the normalized year of the account_statement. */
  overrideStatementYear?: InputMaybe<Scalars['Int']>;
  statementDate?: InputMaybe<Scalars['Date']>;
  /** Overrides the value of the charge. */
  value?: InputMaybe<Scalars['Float']>;
};

export type AccountChargeStddevPopulationAggregates = {
  __typename?: 'AccountChargeStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type AccountChargeStddevSampleAggregates = {
  __typename?: 'AccountChargeStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type AccountChargeSumAggregates = {
  __typename?: 'AccountChargeSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars['BigInt'];
  /** Sum of normalizedValue across the matching connection */
  normalizedValue: Scalars['Float'];
  /** Sum of overrideStatementMonth across the matching connection */
  overrideStatementMonth: Scalars['BigInt'];
  /** Sum of overrideStatementYear across the matching connection */
  overrideStatementYear: Scalars['BigInt'];
  /** Sum of overrideValue across the matching connection */
  overrideValue: Scalars['Float'];
  /** Sum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Sum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Sum of value across the matching connection */
  value: Scalars['Float'];
};

export type AccountChargeVariancePopulationAggregates = {
  __typename?: 'AccountChargeVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population variance of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Population variance of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population variance of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type AccountChargeVarianceSampleAggregates = {
  __typename?: 'AccountChargeVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Sample variance of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample variance of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `AccountCharge` values. */
export type AccountChargesConnection = {
  __typename?: 'AccountChargesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AccountChargeAggregates>;
  /** A list of edges which contains the `AccountCharge` and cursor to aid in pagination. */
  edges: Array<AccountChargesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AccountChargeAggregates>>;
  /** A list of `AccountCharge` objects. */
  nodes: Array<AccountCharge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountCharge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `AccountCharge` values. */
export type AccountChargesConnectionGroupedAggregatesArgs = {
  groupBy: Array<AccountChargesGroupBy>;
  having?: InputMaybe<AccountChargesHavingInput>;
};

/** A `AccountCharge` edge in the connection. */
export type AccountChargesEdge = {
  __typename?: 'AccountChargesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AccountCharge` at the end of the edge. */
  node: AccountCharge;
};

/** Grouping methods for `AccountCharge` for usage during aggregation. */
export enum AccountChargesGroupBy {
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  CATEGORY = 'CATEGORY',
  CATEGORY_DISTINCT = 'CATEGORY_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  INTERVAL_END = 'INTERVAL_END',
  INTERVAL_END_DISTINCT = 'INTERVAL_END_DISTINCT',
  INTERVAL_START = 'INTERVAL_START',
  INTERVAL_START_DISTINCT = 'INTERVAL_START_DISTINCT',
  LABEL = 'LABEL',
  LABEL_DISTINCT = 'LABEL_DISTINCT',
  LINE_NUMBER = 'LINE_NUMBER',
  LINE_NUMBER_DISTINCT = 'LINE_NUMBER_DISTINCT',
  NORMALIZED_VALUE = 'NORMALIZED_VALUE',
  NORMALIZED_VALUE_DISTINCT = 'NORMALIZED_VALUE_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_LABEL = 'OVERRIDE_LABEL',
  OVERRIDE_LABEL_DISTINCT = 'OVERRIDE_LABEL_DISTINCT',
  OVERRIDE_STATEMENT_MONTH = 'OVERRIDE_STATEMENT_MONTH',
  OVERRIDE_STATEMENT_MONTH_DISTINCT = 'OVERRIDE_STATEMENT_MONTH_DISTINCT',
  OVERRIDE_STATEMENT_YEAR = 'OVERRIDE_STATEMENT_YEAR',
  OVERRIDE_STATEMENT_YEAR_DISTINCT = 'OVERRIDE_STATEMENT_YEAR_DISTINCT',
  OVERRIDE_VALUE = 'OVERRIDE_VALUE',
  OVERRIDE_VALUE_DISTINCT = 'OVERRIDE_VALUE_DISTINCT',
  STATEMENT_DATE = 'STATEMENT_DATE',
  STATEMENT_DATE_DISTINCT = 'STATEMENT_DATE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  VALUE = 'VALUE',
  VALUE_DISTINCT = 'VALUE_DISTINCT'
}

export type AccountChargesHavingAverageAccountChargesStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingAverageAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingAverageAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingAverageAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingDistinctAccountChargesStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingDistinctAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingDistinctCountAccountChargesStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingDistinctCountAccountChargesStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingDistinctCountAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingDistinctCountAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingDistinctAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingDistinctAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `AccountCharge` aggregates. */
export type AccountChargesHavingInput = {
  AND?: InputMaybe<Array<AccountChargesHavingInput>>;
  OR?: InputMaybe<Array<AccountChargesHavingInput>>;
  average?: InputMaybe<AccountChargesHavingAverageInput>;
  distinct?: InputMaybe<AccountChargesHavingDistinctInput>;
  distinctCount?: InputMaybe<AccountChargesHavingDistinctCountInput>;
  max?: InputMaybe<AccountChargesHavingMaxInput>;
  min?: InputMaybe<AccountChargesHavingMinInput>;
  stddevPopulation?: InputMaybe<AccountChargesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AccountChargesHavingStddevSampleInput>;
  sum?: InputMaybe<AccountChargesHavingSumInput>;
  variancePopulation?: InputMaybe<AccountChargesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AccountChargesHavingVarianceSampleInput>;
};

export type AccountChargesHavingMaxAccountChargesStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingMaxAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingMaxAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingMaxAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingMinAccountChargesStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingMinAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingMinAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingMinAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingStddevPopulationAccountChargesStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingStddevPopulationAccountChargesStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingStddevPopulationAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingStddevPopulationAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingStddevSampleAccountChargesStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingStddevSampleAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingStddevSampleAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingStddevSampleAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingSumAccountChargesStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingSumAccountChargesStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type AccountChargesHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingSumAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingSumAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingVariancePopulationAccountChargesStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingVariancePopulationAccountChargesStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingVariancePopulationAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingVariancePopulationAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type AccountChargesHavingVarianceSampleAccountChargesStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingVarianceSampleAccountChargesStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type AccountChargesHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<AccountChargesHavingVarianceSampleAccountChargesStatementMonthInput>;
  statementYear?: InputMaybe<AccountChargesHavingVarianceSampleAccountChargesStatementYearInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Methods to use when ordering `AccountCharge`. */
export enum AccountChargesOrderBy {
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  CATEGORY_ASC = 'CATEGORY_ASC',
  CATEGORY_DESC = 'CATEGORY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTERVAL_END_ASC = 'INTERVAL_END_ASC',
  INTERVAL_END_DESC = 'INTERVAL_END_DESC',
  INTERVAL_START_ASC = 'INTERVAL_START_ASC',
  INTERVAL_START_DESC = 'INTERVAL_START_DESC',
  LABEL_ASC = 'LABEL_ASC',
  LABEL_DESC = 'LABEL_DESC',
  LINE_NUMBER_ASC = 'LINE_NUMBER_ASC',
  LINE_NUMBER_DESC = 'LINE_NUMBER_DESC',
  NATURAL = 'NATURAL',
  NORMALIZED_VALUE_ASC = 'NORMALIZED_VALUE_ASC',
  NORMALIZED_VALUE_DESC = 'NORMALIZED_VALUE_DESC',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_LABEL_ASC = 'OVERRIDE_LABEL_ASC',
  OVERRIDE_LABEL_DESC = 'OVERRIDE_LABEL_DESC',
  OVERRIDE_STATEMENT_MONTH_ASC = 'OVERRIDE_STATEMENT_MONTH_ASC',
  OVERRIDE_STATEMENT_MONTH_DESC = 'OVERRIDE_STATEMENT_MONTH_DESC',
  OVERRIDE_STATEMENT_YEAR_ASC = 'OVERRIDE_STATEMENT_YEAR_ASC',
  OVERRIDE_STATEMENT_YEAR_DESC = 'OVERRIDE_STATEMENT_YEAR_DESC',
  OVERRIDE_VALUE_ASC = 'OVERRIDE_VALUE_ASC',
  OVERRIDE_VALUE_DESC = 'OVERRIDE_VALUE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  STATEMENT_DATE_ASC = 'STATEMENT_DATE_ASC',
  STATEMENT_DATE_DESC = 'STATEMENT_DATE_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC'
}

export type AccountMissingStatement = {
  __typename?: 'AccountMissingStatement';
  metadata?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  statementDate?: Maybe<Scalars['Date']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMeters: UtilityMetersConnection;
};

export type AccountMissingStatementUtilityMetersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  facilityId?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<UtilityMeterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `AccountMissingStatement` object types. All fields are combined with a logical ‘and.’ */
export type AccountMissingStatementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountMissingStatementFilter>>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountMissingStatementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountMissingStatementFilter>>;
  /** Filter by the object’s `statementDate` field. */
  statementDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `AccountMissingStatement` values. */
export type AccountMissingStatementsConnection = {
  __typename?: 'AccountMissingStatementsConnection';
  /** A list of edges which contains the `AccountMissingStatement` and cursor to aid in pagination. */
  edges: Array<AccountMissingStatementsEdge>;
  /** A list of `AccountMissingStatement` objects. */
  nodes: Array<AccountMissingStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountMissingStatement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AccountMissingStatement` edge in the connection. */
export type AccountMissingStatementsEdge = {
  __typename?: 'AccountMissingStatementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AccountMissingStatement` at the end of the edge. */
  node: AccountMissingStatement;
};

export type AccountStatement = Node & {
  __typename?: 'AccountStatement';
  /** Reads and enables pagination through a set of `AccountCharge`. */
  accountCharges: AccountChargesConnection;
  /** Reads and enables pagination through a set of `AccountStatementHistory`. */
  accountStatementHistories: AccountStatementHistoriesConnection;
  /** The time the statement was created. */
  createdAt: Scalars['Datetime'];
  /** The currency code of the statement. */
  currencyCode: Scalars['String'];
  /** The time the statement was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterStatementAccountStatementIdAndFacilityId: AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyConnection;
  id: Scalars['Int'];
  integration: UtilityIntegration;
  /** The identifier provided by the integration source */
  integrationKey: Scalars['String'];
  /** The overall statement billing interval end */
  intervalEnd: Scalars['Date'];
  /** The overall statement billing interval start */
  intervalStart: Scalars['Date'];
  isValidated: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  /** Reads and enables pagination through a set of `MeterStatement`. */
  meterStatements: MeterStatementsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** The normalized month of the statement. */
  statementMonth: Scalars['Int'];
  /** The normalized year of the statement. */
  statementYear: Scalars['Int'];
  totalCharges?: Maybe<Scalars['Float']>;
  /** The time the statement was updated. */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UtilityAccount` that is related to this `AccountStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId: Scalars['Int'];
  /** Reads a single `UtilityAccountStatement` that is related to this `AccountStatement`. */
  utilityAccountStatement?: Maybe<UtilityAccountStatement>;
  /** Reads a single `UtilityAccountStatementRollup` that is related to this `AccountStatement`. */
  utilityAccountStatementRollup?: Maybe<UtilityAccountStatementRollup>;
  /** Reads and enables pagination through a set of `UtilityAccount`. */
  utilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountId: AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
  utilityMeterStatements: UtilityMeterStatementsConnection;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMetersByMeterStatementAccountStatementIdAndUtilityMeterId: AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterId: AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection;
};

export type AccountStatementAccountChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountChargeCondition>;
  filter?: InputMaybe<AccountChargeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountChargesOrderBy>>;
};

export type AccountStatementAccountStatementHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountStatementHistoryCondition>;
  filter?: InputMaybe<AccountStatementHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStatementHistoriesOrderBy>>;
};

export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

export type AccountStatementMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterStatementCondition>;
  filter?: InputMaybe<MeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

export type AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

export type AccountStatementUtilityMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementCondition>;
  filter?: InputMaybe<UtilityMeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
};

export type AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

export type AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

export type AccountStatementAggregates = {
  __typename?: 'AccountStatementAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AccountStatementAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<AccountStatementDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AccountStatementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AccountStatementMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AccountStatementMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AccountStatementStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AccountStatementStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AccountStatementSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AccountStatementVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AccountStatementVarianceSampleAggregates>;
};

export type AccountStatementAverageAggregates = {
  __typename?: 'AccountStatementAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Mean average of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AccountStatement` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AccountStatementCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integration` field. */
  integration?: InputMaybe<UtilityIntegration>;
  /** Checks for equality with the object’s `integrationKey` field. */
  integrationKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `isValidated` field. */
  isValidated?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `normalizedCurrencyCode` field. */
  normalizedCurrencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideCurrencyCode` field. */
  overrideCurrencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statementYear` field. */
  statementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type AccountStatementDistinctAggregates = {
  __typename?: 'AccountStatementDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of integration across the matching connection */
  integration?: Maybe<Scalars['JSON']>;
  /** Distinct of integrationKey across the matching connection */
  integrationKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['JSON']>;
  /** Distinct of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['JSON']>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of normalizedCurrencyCode across the matching connection */
  normalizedCurrencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideCurrencyCode across the matching connection */
  overrideCurrencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  totalCharges?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
};

export type AccountStatementDistinctCountAggregates = {
  __typename?: 'AccountStatementDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integration across the matching connection */
  integration?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationKey across the matching connection */
  integrationKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of normalizedCurrencyCode across the matching connection */
  normalizedCurrencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideCurrencyCode across the matching connection */
  overrideCurrencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementFacilitiesByUtilityMeterStatementAccountStatementIdAndFacilityIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A filter to be used against `AccountStatement` object types. All fields are combined with a logical ‘and.’ */
export type AccountStatementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountStatementFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `integration` field. */
  integration?: InputMaybe<UtilityIntegrationFilter>;
  /** Filter by the object’s `integrationKey` field. */
  integrationKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<DateFilter>;
  /** Filter by the object’s `isValidated` field. */
  isValidated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `normalizedCurrencyCode` field. */
  normalizedCurrencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `normalizedTotalCharges` field. */
  normalizedTotalCharges?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountStatementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountStatementFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideCurrencyCode` field. */
  overrideCurrencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalCharges` field. */
  totalCharges?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `AccountStatementHistory` values. */
export type AccountStatementHistoriesConnection = {
  __typename?: 'AccountStatementHistoriesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AccountStatementHistoryAggregates>;
  /** A list of edges which contains the `AccountStatementHistory` and cursor to aid in pagination. */
  edges: Array<AccountStatementHistoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AccountStatementHistoryAggregates>>;
  /** A list of `AccountStatementHistory` objects. */
  nodes: Array<AccountStatementHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountStatementHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `AccountStatementHistory` values. */
export type AccountStatementHistoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<AccountStatementHistoryGroupBy>;
  having?: InputMaybe<AccountStatementHistoryHavingInput>;
};

/** A `AccountStatementHistory` edge in the connection. */
export type AccountStatementHistoriesEdge = {
  __typename?: 'AccountStatementHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AccountStatementHistory` at the end of the edge. */
  node: AccountStatementHistory;
};

/** Methods to use when ordering `AccountStatementHistory`. */
export enum AccountStatementHistoriesOrderBy {
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  NATURAL = 'NATURAL'
}

export type AccountStatementHistory = {
  __typename?: 'AccountStatementHistory';
  /** Reads a single `AccountStatement` that is related to this `AccountStatementHistory`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId: Scalars['Int'];
  /** The time this statement was created. */
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
};

export type AccountStatementHistoryAggregates = {
  __typename?: 'AccountStatementHistoryAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AccountStatementHistoryAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<AccountStatementHistoryDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AccountStatementHistoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AccountStatementHistoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AccountStatementHistoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AccountStatementHistoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AccountStatementHistoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AccountStatementHistorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AccountStatementHistoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AccountStatementHistoryVarianceSampleAggregates>;
};

export type AccountStatementHistoryAverageAggregates = {
  __typename?: 'AccountStatementHistoryAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AccountStatementHistory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type AccountStatementHistoryCondition = {
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
};

export type AccountStatementHistoryDistinctAggregates = {
  __typename?: 'AccountStatementHistoryDistinctAggregates';
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
};

export type AccountStatementHistoryDistinctCountAggregates = {
  __typename?: 'AccountStatementHistoryDistinctCountAggregates';
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `AccountStatementHistory` object types. All fields are combined with a logical ‘and.’ */
export type AccountStatementHistoryFilter = {
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountStatementHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountStatementHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountStatementHistoryFilter>>;
};

/** Grouping methods for `AccountStatementHistory` for usage during aggregation. */
export enum AccountStatementHistoryGroupBy {
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT'
}

export type AccountStatementHistoryHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AccountStatementHistory` aggregates. */
export type AccountStatementHistoryHavingInput = {
  AND?: InputMaybe<Array<AccountStatementHistoryHavingInput>>;
  OR?: InputMaybe<Array<AccountStatementHistoryHavingInput>>;
  average?: InputMaybe<AccountStatementHistoryHavingAverageInput>;
  distinct?: InputMaybe<AccountStatementHistoryHavingDistinctInput>;
  distinctCount?: InputMaybe<AccountStatementHistoryHavingDistinctCountInput>;
  max?: InputMaybe<AccountStatementHistoryHavingMaxInput>;
  min?: InputMaybe<AccountStatementHistoryHavingMinInput>;
  stddevPopulation?: InputMaybe<AccountStatementHistoryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AccountStatementHistoryHavingStddevSampleInput>;
  sum?: InputMaybe<AccountStatementHistoryHavingSumInput>;
  variancePopulation?: InputMaybe<AccountStatementHistoryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AccountStatementHistoryHavingVarianceSampleInput>;
};

export type AccountStatementHistoryHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AccountStatementHistoryHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AccountStatementHistory` */
export type AccountStatementHistoryInput = {
  /** Mutates a `AccountStatement` as part of the payload */
  accountStatement?: InputMaybe<AccountStatementInput>;
  accountStatementId?: InputMaybe<Scalars['Int']>;
  description: Scalars['String'];
};

export type AccountStatementHistoryMaxAggregates = {
  __typename?: 'AccountStatementHistoryMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
};

export type AccountStatementHistoryMinAggregates = {
  __typename?: 'AccountStatementHistoryMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
};

export type AccountStatementHistoryStddevPopulationAggregates = {
  __typename?: 'AccountStatementHistoryStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementHistoryStddevSampleAggregates = {
  __typename?: 'AccountStatementHistoryStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementHistorySumAggregates = {
  __typename?: 'AccountStatementHistorySumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
};

export type AccountStatementHistoryVariancePopulationAggregates = {
  __typename?: 'AccountStatementHistoryVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementHistoryVarianceSampleAggregates = {
  __typename?: 'AccountStatementHistoryVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
};

/** An input for mutations affecting `AccountStatement` */
export type AccountStatementInput = {
  /** The currency code of the statement. */
  currencyCode: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  integration: UtilityIntegration;
  /** The identifier provided by the integration source */
  integrationKey?: InputMaybe<Scalars['String']>;
  /** The overall statement billing interval end */
  intervalEnd: Scalars['Date'];
  /** The overall statement billing interval start */
  intervalStart: Scalars['Date'];
  isValidated?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** The normalized month of the statement. */
  statementMonth: Scalars['Int'];
  /** The normalized year of the statement. */
  statementYear: Scalars['Int'];
  /** Mutates a `UtilityAccount` as part of the payload */
  utilityAccount?: InputMaybe<UtilityAccountInput>;
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type AccountStatementMaxAggregates = {
  __typename?: 'AccountStatementMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Maximum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type AccountStatementMinAggregates = {
  __typename?: 'AccountStatementMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Minimum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `AccountStatement`. Fields that are set will be updated. */
export type AccountStatementPatch = {
  /** Overrides the currencyCode of the statement. */
  currencyCode?: InputMaybe<Scalars['String']>;
  isValidated?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** The normalized month of the statement. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** The normalized year of the statement. */
  statementYear?: InputMaybe<Scalars['Int']>;
};

export type AccountStatementStddevPopulationAggregates = {
  __typename?: 'AccountStatementStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Population standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementStddevSampleAggregates = {
  __typename?: 'AccountStatementStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementSumAggregates = {
  __typename?: 'AccountStatementSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sum of statementMonth across the matching connection */
  statementMonth: Scalars['BigInt'];
  /** Sum of statementYear across the matching connection */
  statementYear: Scalars['BigInt'];
  /** Sum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
};

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityAccountsByUtilityMeterStatementAccountStatementIdAndUtilityAccountIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A connection to a list of `UtilityMeter` values, with data from `MeterStatement`. */
export type AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `MeterStatement`, and the cursor to aid in pagination. */
    edges: Array<AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `MeterStatement`. */
export type AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `MeterStatement`. */
export type AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** Reads and enables pagination through a set of `MeterStatement`. */
    meterStatements: MeterStatementsConnection;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
  };

/** A `UtilityMeter` edge in the connection, with data from `MeterStatement`. */
export type AccountStatementUtilityMetersByMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdgeMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<MeterStatementCondition>;
    filter?: InputMaybe<MeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type AccountStatementUtilityMetersByUtilityMeterStatementAccountStatementIdAndUtilityMeterIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type AccountStatementVariancePopulationAggregates = {
  __typename?: 'AccountStatementVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Population variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type AccountStatementVarianceSampleAggregates = {
  __typename?: 'AccountStatementVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sample variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AccountStatement` values. */
export type AccountStatementsConnection = {
  __typename?: 'AccountStatementsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AccountStatementAggregates>;
  /** A list of edges which contains the `AccountStatement` and cursor to aid in pagination. */
  edges: Array<AccountStatementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AccountStatementAggregates>>;
  /** A list of `AccountStatement` objects. */
  nodes: Array<AccountStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AccountStatement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `AccountStatement` values. */
export type AccountStatementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AccountStatementsGroupBy>;
  having?: InputMaybe<AccountStatementsHavingInput>;
};

/** A `AccountStatement` edge in the connection. */
export type AccountStatementsEdge = {
  __typename?: 'AccountStatementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AccountStatement` at the end of the edge. */
  node: AccountStatement;
};

/** Grouping methods for `AccountStatement` for usage during aggregation. */
export enum AccountStatementsGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  INTEGRATION = 'INTEGRATION',
  INTEGRATION_DISTINCT = 'INTEGRATION_DISTINCT',
  INTEGRATION_KEY = 'INTEGRATION_KEY',
  INTEGRATION_KEY_DISTINCT = 'INTEGRATION_KEY_DISTINCT',
  INTERVAL_END = 'INTERVAL_END',
  INTERVAL_END_DISTINCT = 'INTERVAL_END_DISTINCT',
  INTERVAL_START = 'INTERVAL_START',
  INTERVAL_START_DISTINCT = 'INTERVAL_START_DISTINCT',
  IS_VALIDATED = 'IS_VALIDATED',
  IS_VALIDATED_DISTINCT = 'IS_VALIDATED_DISTINCT',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  NORMALIZED_CURRENCY_CODE = 'NORMALIZED_CURRENCY_CODE',
  NORMALIZED_CURRENCY_CODE_DISTINCT = 'NORMALIZED_CURRENCY_CODE_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_CURRENCY_CODE = 'OVERRIDE_CURRENCY_CODE',
  OVERRIDE_CURRENCY_CODE_DISTINCT = 'OVERRIDE_CURRENCY_CODE_DISTINCT',
  STATEMENT_MONTH = 'STATEMENT_MONTH',
  STATEMENT_MONTH_DISTINCT = 'STATEMENT_MONTH_DISTINCT',
  STATEMENT_YEAR = 'STATEMENT_YEAR',
  STATEMENT_YEAR_DISTINCT = 'STATEMENT_YEAR_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT'
}

export type AccountStatementsHavingAverageAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingAverageAccountStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AccountStatementsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingAverageAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingAverageAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingDistinctAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingDistinctAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingDistinctCountAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingDistinctCountAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingDistinctCountAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingDistinctCountAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingDistinctAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingDistinctAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `AccountStatement` aggregates. */
export type AccountStatementsHavingInput = {
  AND?: InputMaybe<Array<AccountStatementsHavingInput>>;
  OR?: InputMaybe<Array<AccountStatementsHavingInput>>;
  average?: InputMaybe<AccountStatementsHavingAverageInput>;
  distinct?: InputMaybe<AccountStatementsHavingDistinctInput>;
  distinctCount?: InputMaybe<AccountStatementsHavingDistinctCountInput>;
  max?: InputMaybe<AccountStatementsHavingMaxInput>;
  min?: InputMaybe<AccountStatementsHavingMinInput>;
  stddevPopulation?: InputMaybe<AccountStatementsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AccountStatementsHavingStddevSampleInput>;
  sum?: InputMaybe<AccountStatementsHavingSumInput>;
  variancePopulation?: InputMaybe<AccountStatementsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AccountStatementsHavingVarianceSampleInput>;
};

export type AccountStatementsHavingMaxAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingMaxAccountStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AccountStatementsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingMaxAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingMaxAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingMinAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingMinAccountStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AccountStatementsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingMinAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingMinAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingStddevPopulationAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingStddevPopulationAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingStddevPopulationAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingStddevPopulationAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingStddevSampleAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingStddevSampleAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingStddevSampleAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingStddevSampleAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingSumAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingSumAccountStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AccountStatementsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingSumAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingSumAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingVariancePopulationAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingVariancePopulationAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingVariancePopulationAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingVariancePopulationAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type AccountStatementsHavingVarianceSampleAccountStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingVarianceSampleAccountStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AccountStatementsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  normalizedTotalCharges?: InputMaybe<AccountStatementsHavingVarianceSampleAccountStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  totalCharges?: InputMaybe<AccountStatementsHavingVarianceSampleAccountStatementsTotalChargesInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `AccountStatement`. */
export enum AccountStatementsOrderBy {
  ACCOUNT_CHARGES_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_AVERAGE_CATEGORY_ASC = 'ACCOUNT_CHARGES_AVERAGE_CATEGORY_ASC',
  ACCOUNT_CHARGES_AVERAGE_CATEGORY_DESC = 'ACCOUNT_CHARGES_AVERAGE_CATEGORY_DESC',
  ACCOUNT_CHARGES_AVERAGE_CREATED_AT_ASC = 'ACCOUNT_CHARGES_AVERAGE_CREATED_AT_ASC',
  ACCOUNT_CHARGES_AVERAGE_CREATED_AT_DESC = 'ACCOUNT_CHARGES_AVERAGE_CREATED_AT_DESC',
  ACCOUNT_CHARGES_AVERAGE_DELETED_AT_ASC = 'ACCOUNT_CHARGES_AVERAGE_DELETED_AT_ASC',
  ACCOUNT_CHARGES_AVERAGE_DELETED_AT_DESC = 'ACCOUNT_CHARGES_AVERAGE_DELETED_AT_DESC',
  ACCOUNT_CHARGES_AVERAGE_ID_ASC = 'ACCOUNT_CHARGES_AVERAGE_ID_ASC',
  ACCOUNT_CHARGES_AVERAGE_ID_DESC = 'ACCOUNT_CHARGES_AVERAGE_ID_DESC',
  ACCOUNT_CHARGES_AVERAGE_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_AVERAGE_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_AVERAGE_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_AVERAGE_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_AVERAGE_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_AVERAGE_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_AVERAGE_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_AVERAGE_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_AVERAGE_LABEL_ASC = 'ACCOUNT_CHARGES_AVERAGE_LABEL_ASC',
  ACCOUNT_CHARGES_AVERAGE_LABEL_DESC = 'ACCOUNT_CHARGES_AVERAGE_LABEL_DESC',
  ACCOUNT_CHARGES_AVERAGE_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_AVERAGE_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_AVERAGE_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_AVERAGE_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_AVERAGE_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_AVERAGE_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_AVERAGE_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_AVERAGE_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_AVERAGE_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_AVERAGE_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_AVERAGE_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_AVERAGE_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_AVERAGE_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_AVERAGE_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_AVERAGE_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_AVERAGE_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_AVERAGE_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_AVERAGE_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_AVERAGE_VALUE_ASC = 'ACCOUNT_CHARGES_AVERAGE_VALUE_ASC',
  ACCOUNT_CHARGES_AVERAGE_VALUE_DESC = 'ACCOUNT_CHARGES_AVERAGE_VALUE_DESC',
  ACCOUNT_CHARGES_COUNT_ASC = 'ACCOUNT_CHARGES_COUNT_ASC',
  ACCOUNT_CHARGES_COUNT_DESC = 'ACCOUNT_CHARGES_COUNT_DESC',
  ACCOUNT_CHARGES_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_DISTINCT_CATEGORY_ASC = 'ACCOUNT_CHARGES_DISTINCT_CATEGORY_ASC',
  ACCOUNT_CHARGES_DISTINCT_CATEGORY_DESC = 'ACCOUNT_CHARGES_DISTINCT_CATEGORY_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_CATEGORY_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_CATEGORY_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_CATEGORY_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_CATEGORY_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_CREATED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_CREATED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_CREATED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_CREATED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_DELETED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_DELETED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_DELETED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_DELETED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_ID_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_ID_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_ID_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_ID_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_LABEL_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_LABEL_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_LABEL_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_LABEL_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_COUNT_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_COUNT_VALUE_DESC',
  ACCOUNT_CHARGES_DISTINCT_CREATED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_CREATED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_CREATED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_CREATED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_DELETED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_DELETED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_DELETED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_DELETED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_ID_ASC = 'ACCOUNT_CHARGES_DISTINCT_ID_ASC',
  ACCOUNT_CHARGES_DISTINCT_ID_DESC = 'ACCOUNT_CHARGES_DISTINCT_ID_DESC',
  ACCOUNT_CHARGES_DISTINCT_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_DISTINCT_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_DISTINCT_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_DISTINCT_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_DISTINCT_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_DISTINCT_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_DISTINCT_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_DISTINCT_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_DISTINCT_LABEL_ASC = 'ACCOUNT_CHARGES_DISTINCT_LABEL_ASC',
  ACCOUNT_CHARGES_DISTINCT_LABEL_DESC = 'ACCOUNT_CHARGES_DISTINCT_LABEL_DESC',
  ACCOUNT_CHARGES_DISTINCT_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_DISTINCT_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_DISTINCT_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_DISTINCT_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_DISTINCT_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_DISTINCT_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_DISTINCT_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_DISTINCT_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_DISTINCT_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_DISTINCT_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_DISTINCT_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_DISTINCT_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_DISTINCT_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_DISTINCT_VALUE_ASC = 'ACCOUNT_CHARGES_DISTINCT_VALUE_ASC',
  ACCOUNT_CHARGES_DISTINCT_VALUE_DESC = 'ACCOUNT_CHARGES_DISTINCT_VALUE_DESC',
  ACCOUNT_CHARGES_MAX_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_MAX_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_MAX_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_MAX_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_MAX_CATEGORY_ASC = 'ACCOUNT_CHARGES_MAX_CATEGORY_ASC',
  ACCOUNT_CHARGES_MAX_CATEGORY_DESC = 'ACCOUNT_CHARGES_MAX_CATEGORY_DESC',
  ACCOUNT_CHARGES_MAX_CREATED_AT_ASC = 'ACCOUNT_CHARGES_MAX_CREATED_AT_ASC',
  ACCOUNT_CHARGES_MAX_CREATED_AT_DESC = 'ACCOUNT_CHARGES_MAX_CREATED_AT_DESC',
  ACCOUNT_CHARGES_MAX_DELETED_AT_ASC = 'ACCOUNT_CHARGES_MAX_DELETED_AT_ASC',
  ACCOUNT_CHARGES_MAX_DELETED_AT_DESC = 'ACCOUNT_CHARGES_MAX_DELETED_AT_DESC',
  ACCOUNT_CHARGES_MAX_ID_ASC = 'ACCOUNT_CHARGES_MAX_ID_ASC',
  ACCOUNT_CHARGES_MAX_ID_DESC = 'ACCOUNT_CHARGES_MAX_ID_DESC',
  ACCOUNT_CHARGES_MAX_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_MAX_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_MAX_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_MAX_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_MAX_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_MAX_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_MAX_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_MAX_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_MAX_LABEL_ASC = 'ACCOUNT_CHARGES_MAX_LABEL_ASC',
  ACCOUNT_CHARGES_MAX_LABEL_DESC = 'ACCOUNT_CHARGES_MAX_LABEL_DESC',
  ACCOUNT_CHARGES_MAX_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_MAX_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_MAX_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_MAX_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_MAX_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_MAX_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_MAX_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_MAX_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_MAX_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_MAX_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_MAX_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_MAX_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_MAX_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_MAX_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_MAX_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_MAX_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_MAX_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_MAX_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_MAX_VALUE_ASC = 'ACCOUNT_CHARGES_MAX_VALUE_ASC',
  ACCOUNT_CHARGES_MAX_VALUE_DESC = 'ACCOUNT_CHARGES_MAX_VALUE_DESC',
  ACCOUNT_CHARGES_MIN_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_MIN_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_MIN_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_MIN_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_MIN_CATEGORY_ASC = 'ACCOUNT_CHARGES_MIN_CATEGORY_ASC',
  ACCOUNT_CHARGES_MIN_CATEGORY_DESC = 'ACCOUNT_CHARGES_MIN_CATEGORY_DESC',
  ACCOUNT_CHARGES_MIN_CREATED_AT_ASC = 'ACCOUNT_CHARGES_MIN_CREATED_AT_ASC',
  ACCOUNT_CHARGES_MIN_CREATED_AT_DESC = 'ACCOUNT_CHARGES_MIN_CREATED_AT_DESC',
  ACCOUNT_CHARGES_MIN_DELETED_AT_ASC = 'ACCOUNT_CHARGES_MIN_DELETED_AT_ASC',
  ACCOUNT_CHARGES_MIN_DELETED_AT_DESC = 'ACCOUNT_CHARGES_MIN_DELETED_AT_DESC',
  ACCOUNT_CHARGES_MIN_ID_ASC = 'ACCOUNT_CHARGES_MIN_ID_ASC',
  ACCOUNT_CHARGES_MIN_ID_DESC = 'ACCOUNT_CHARGES_MIN_ID_DESC',
  ACCOUNT_CHARGES_MIN_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_MIN_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_MIN_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_MIN_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_MIN_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_MIN_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_MIN_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_MIN_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_MIN_LABEL_ASC = 'ACCOUNT_CHARGES_MIN_LABEL_ASC',
  ACCOUNT_CHARGES_MIN_LABEL_DESC = 'ACCOUNT_CHARGES_MIN_LABEL_DESC',
  ACCOUNT_CHARGES_MIN_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_MIN_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_MIN_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_MIN_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_MIN_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_MIN_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_MIN_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_MIN_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_MIN_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_MIN_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_MIN_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_MIN_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_MIN_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_MIN_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_MIN_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_MIN_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_MIN_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_MIN_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_MIN_VALUE_ASC = 'ACCOUNT_CHARGES_MIN_VALUE_ASC',
  ACCOUNT_CHARGES_MIN_VALUE_DESC = 'ACCOUNT_CHARGES_MIN_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_CATEGORY_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_CATEGORY_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_CATEGORY_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_CATEGORY_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_CREATED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_CREATED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_CREATED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_CREATED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_DELETED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_DELETED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_DELETED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_DELETED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_ID_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_ID_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_ID_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_ID_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_LABEL_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_LABEL_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_LABEL_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_LABEL_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_POPULATION_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_POPULATION_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_CATEGORY_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_CATEGORY_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_CATEGORY_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_CATEGORY_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_DELETED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_DELETED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_DELETED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_DELETED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_ID_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_ID_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_ID_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_ID_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_LABEL_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_LABEL_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_LABEL_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_LABEL_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_VALUE_ASC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_VALUE_ASC',
  ACCOUNT_CHARGES_STDDEV_SAMPLE_VALUE_DESC = 'ACCOUNT_CHARGES_STDDEV_SAMPLE_VALUE_DESC',
  ACCOUNT_CHARGES_SUM_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_SUM_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_SUM_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_SUM_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_SUM_CATEGORY_ASC = 'ACCOUNT_CHARGES_SUM_CATEGORY_ASC',
  ACCOUNT_CHARGES_SUM_CATEGORY_DESC = 'ACCOUNT_CHARGES_SUM_CATEGORY_DESC',
  ACCOUNT_CHARGES_SUM_CREATED_AT_ASC = 'ACCOUNT_CHARGES_SUM_CREATED_AT_ASC',
  ACCOUNT_CHARGES_SUM_CREATED_AT_DESC = 'ACCOUNT_CHARGES_SUM_CREATED_AT_DESC',
  ACCOUNT_CHARGES_SUM_DELETED_AT_ASC = 'ACCOUNT_CHARGES_SUM_DELETED_AT_ASC',
  ACCOUNT_CHARGES_SUM_DELETED_AT_DESC = 'ACCOUNT_CHARGES_SUM_DELETED_AT_DESC',
  ACCOUNT_CHARGES_SUM_ID_ASC = 'ACCOUNT_CHARGES_SUM_ID_ASC',
  ACCOUNT_CHARGES_SUM_ID_DESC = 'ACCOUNT_CHARGES_SUM_ID_DESC',
  ACCOUNT_CHARGES_SUM_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_SUM_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_SUM_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_SUM_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_SUM_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_SUM_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_SUM_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_SUM_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_SUM_LABEL_ASC = 'ACCOUNT_CHARGES_SUM_LABEL_ASC',
  ACCOUNT_CHARGES_SUM_LABEL_DESC = 'ACCOUNT_CHARGES_SUM_LABEL_DESC',
  ACCOUNT_CHARGES_SUM_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_SUM_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_SUM_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_SUM_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_SUM_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_SUM_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_SUM_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_SUM_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_SUM_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_SUM_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_SUM_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_SUM_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_SUM_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_SUM_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_SUM_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_SUM_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_SUM_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_SUM_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_SUM_VALUE_ASC = 'ACCOUNT_CHARGES_SUM_VALUE_ASC',
  ACCOUNT_CHARGES_SUM_VALUE_DESC = 'ACCOUNT_CHARGES_SUM_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_CATEGORY_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_CATEGORY_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_CATEGORY_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_CATEGORY_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_CREATED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_CREATED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_CREATED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_CREATED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_DELETED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_DELETED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_DELETED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_DELETED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_ID_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_ID_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_ID_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_ID_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_LABEL_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_LABEL_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_LABEL_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_LABEL_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_POPULATION_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_POPULATION_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_CATEGORY_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_CATEGORY_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_CATEGORY_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_CATEGORY_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_DELETED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_DELETED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_DELETED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_DELETED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_ID_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_ID_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_ID_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_ID_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_LABEL_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_LABEL_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_LABEL_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_LABEL_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_STATEMENT_DATE_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_STATEMENT_DATE_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_STATEMENT_DATE_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_STATEMENT_DATE_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_VALUE_ASC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_VALUE_ASC',
  ACCOUNT_CHARGES_VARIANCE_SAMPLE_VALUE_DESC = 'ACCOUNT_CHARGES_VARIANCE_SAMPLE_VALUE_DESC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_COUNT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_COUNT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_COUNT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_COUNT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MAX_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MAX_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_MIN_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_MIN_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_SUM_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_SUM_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'ACCOUNT_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTEGRATION_ASC = 'INTEGRATION_ASC',
  INTEGRATION_DESC = 'INTEGRATION_DESC',
  INTEGRATION_KEY_ASC = 'INTEGRATION_KEY_ASC',
  INTEGRATION_KEY_DESC = 'INTEGRATION_KEY_DESC',
  INTERVAL_END_ASC = 'INTERVAL_END_ASC',
  INTERVAL_END_DESC = 'INTERVAL_END_DESC',
  INTERVAL_START_ASC = 'INTERVAL_START_ASC',
  INTERVAL_START_DESC = 'INTERVAL_START_DESC',
  IS_VALIDATED_ASC = 'IS_VALIDATED_ASC',
  IS_VALIDATED_DESC = 'IS_VALIDATED_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_AVERAGE_CREATED_AT_ASC = 'METER_STATEMENTS_AVERAGE_CREATED_AT_ASC',
  METER_STATEMENTS_AVERAGE_CREATED_AT_DESC = 'METER_STATEMENTS_AVERAGE_CREATED_AT_DESC',
  METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_DELETED_AT_ASC = 'METER_STATEMENTS_AVERAGE_DELETED_AT_ASC',
  METER_STATEMENTS_AVERAGE_DELETED_AT_DESC = 'METER_STATEMENTS_AVERAGE_DELETED_AT_DESC',
  METER_STATEMENTS_AVERAGE_ID_ASC = 'METER_STATEMENTS_AVERAGE_ID_ASC',
  METER_STATEMENTS_AVERAGE_ID_DESC = 'METER_STATEMENTS_AVERAGE_ID_DESC',
  METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_AVERAGE_INTERVAL_END_ASC = 'METER_STATEMENTS_AVERAGE_INTERVAL_END_ASC',
  METER_STATEMENTS_AVERAGE_INTERVAL_END_DESC = 'METER_STATEMENTS_AVERAGE_INTERVAL_END_DESC',
  METER_STATEMENTS_AVERAGE_INTERVAL_START_ASC = 'METER_STATEMENTS_AVERAGE_INTERVAL_START_ASC',
  METER_STATEMENTS_AVERAGE_INTERVAL_START_DESC = 'METER_STATEMENTS_AVERAGE_INTERVAL_START_DESC',
  METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_AVERAGE_METADATA_ASC = 'METER_STATEMENTS_AVERAGE_METADATA_ASC',
  METER_STATEMENTS_AVERAGE_METADATA_DESC = 'METER_STATEMENTS_AVERAGE_METADATA_DESC',
  METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_PDF_LINK_ASC = 'METER_STATEMENTS_AVERAGE_PDF_LINK_ASC',
  METER_STATEMENTS_AVERAGE_PDF_LINK_DESC = 'METER_STATEMENTS_AVERAGE_PDF_LINK_DESC',
  METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_AVERAGE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_AVERAGE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_AVERAGE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_AVERAGE_UPDATED_AT_ASC = 'METER_STATEMENTS_AVERAGE_UPDATED_AT_ASC',
  METER_STATEMENTS_AVERAGE_UPDATED_AT_DESC = 'METER_STATEMENTS_AVERAGE_UPDATED_AT_DESC',
  METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_COUNT_ASC = 'METER_STATEMENTS_COUNT_ASC',
  METER_STATEMENTS_COUNT_DESC = 'METER_STATEMENTS_COUNT_DESC',
  METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_METADATA_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_METADATA_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_METADATA_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_METADATA_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_DISTINCT_CREATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_CREATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_CREATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_CREATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_DELETED_AT_ASC = 'METER_STATEMENTS_DISTINCT_DELETED_AT_ASC',
  METER_STATEMENTS_DISTINCT_DELETED_AT_DESC = 'METER_STATEMENTS_DISTINCT_DELETED_AT_DESC',
  METER_STATEMENTS_DISTINCT_ID_ASC = 'METER_STATEMENTS_DISTINCT_ID_ASC',
  METER_STATEMENTS_DISTINCT_ID_DESC = 'METER_STATEMENTS_DISTINCT_ID_DESC',
  METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_DISTINCT_INTERVAL_END_ASC = 'METER_STATEMENTS_DISTINCT_INTERVAL_END_ASC',
  METER_STATEMENTS_DISTINCT_INTERVAL_END_DESC = 'METER_STATEMENTS_DISTINCT_INTERVAL_END_DESC',
  METER_STATEMENTS_DISTINCT_INTERVAL_START_ASC = 'METER_STATEMENTS_DISTINCT_INTERVAL_START_ASC',
  METER_STATEMENTS_DISTINCT_INTERVAL_START_DESC = 'METER_STATEMENTS_DISTINCT_INTERVAL_START_DESC',
  METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_DISTINCT_METADATA_ASC = 'METER_STATEMENTS_DISTINCT_METADATA_ASC',
  METER_STATEMENTS_DISTINCT_METADATA_DESC = 'METER_STATEMENTS_DISTINCT_METADATA_DESC',
  METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_PDF_LINK_ASC = 'METER_STATEMENTS_DISTINCT_PDF_LINK_ASC',
  METER_STATEMENTS_DISTINCT_PDF_LINK_DESC = 'METER_STATEMENTS_DISTINCT_PDF_LINK_DESC',
  METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_STATEMENT_DATE_ASC = 'METER_STATEMENTS_DISTINCT_STATEMENT_DATE_ASC',
  METER_STATEMENTS_DISTINCT_STATEMENT_DATE_DESC = 'METER_STATEMENTS_DISTINCT_STATEMENT_DATE_DESC',
  METER_STATEMENTS_DISTINCT_UPDATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_UPDATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_UPDATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_UPDATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_MAX_CREATED_AT_ASC = 'METER_STATEMENTS_MAX_CREATED_AT_ASC',
  METER_STATEMENTS_MAX_CREATED_AT_DESC = 'METER_STATEMENTS_MAX_CREATED_AT_DESC',
  METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_DELETED_AT_ASC = 'METER_STATEMENTS_MAX_DELETED_AT_ASC',
  METER_STATEMENTS_MAX_DELETED_AT_DESC = 'METER_STATEMENTS_MAX_DELETED_AT_DESC',
  METER_STATEMENTS_MAX_ID_ASC = 'METER_STATEMENTS_MAX_ID_ASC',
  METER_STATEMENTS_MAX_ID_DESC = 'METER_STATEMENTS_MAX_ID_DESC',
  METER_STATEMENTS_MAX_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_MAX_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_MAX_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_MAX_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_MAX_INTERVAL_END_ASC = 'METER_STATEMENTS_MAX_INTERVAL_END_ASC',
  METER_STATEMENTS_MAX_INTERVAL_END_DESC = 'METER_STATEMENTS_MAX_INTERVAL_END_DESC',
  METER_STATEMENTS_MAX_INTERVAL_START_ASC = 'METER_STATEMENTS_MAX_INTERVAL_START_ASC',
  METER_STATEMENTS_MAX_INTERVAL_START_DESC = 'METER_STATEMENTS_MAX_INTERVAL_START_DESC',
  METER_STATEMENTS_MAX_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_MAX_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_MAX_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_MAX_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  METER_STATEMENTS_MAX_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_MAX_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_MAX_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_MAX_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_MAX_METADATA_ASC = 'METER_STATEMENTS_MAX_METADATA_ASC',
  METER_STATEMENTS_MAX_METADATA_DESC = 'METER_STATEMENTS_MAX_METADATA_DESC',
  METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_AT_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_AT_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_AT_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_AT_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_MAX_PDF_LINK_ASC = 'METER_STATEMENTS_MAX_PDF_LINK_ASC',
  METER_STATEMENTS_MAX_PDF_LINK_DESC = 'METER_STATEMENTS_MAX_PDF_LINK_DESC',
  METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_MAX_STATEMENT_DATE_ASC = 'METER_STATEMENTS_MAX_STATEMENT_DATE_ASC',
  METER_STATEMENTS_MAX_STATEMENT_DATE_DESC = 'METER_STATEMENTS_MAX_STATEMENT_DATE_DESC',
  METER_STATEMENTS_MAX_UPDATED_AT_ASC = 'METER_STATEMENTS_MAX_UPDATED_AT_ASC',
  METER_STATEMENTS_MAX_UPDATED_AT_DESC = 'METER_STATEMENTS_MAX_UPDATED_AT_DESC',
  METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_MIN_CREATED_AT_ASC = 'METER_STATEMENTS_MIN_CREATED_AT_ASC',
  METER_STATEMENTS_MIN_CREATED_AT_DESC = 'METER_STATEMENTS_MIN_CREATED_AT_DESC',
  METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_DELETED_AT_ASC = 'METER_STATEMENTS_MIN_DELETED_AT_ASC',
  METER_STATEMENTS_MIN_DELETED_AT_DESC = 'METER_STATEMENTS_MIN_DELETED_AT_DESC',
  METER_STATEMENTS_MIN_ID_ASC = 'METER_STATEMENTS_MIN_ID_ASC',
  METER_STATEMENTS_MIN_ID_DESC = 'METER_STATEMENTS_MIN_ID_DESC',
  METER_STATEMENTS_MIN_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_MIN_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_MIN_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_MIN_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_MIN_INTERVAL_END_ASC = 'METER_STATEMENTS_MIN_INTERVAL_END_ASC',
  METER_STATEMENTS_MIN_INTERVAL_END_DESC = 'METER_STATEMENTS_MIN_INTERVAL_END_DESC',
  METER_STATEMENTS_MIN_INTERVAL_START_ASC = 'METER_STATEMENTS_MIN_INTERVAL_START_ASC',
  METER_STATEMENTS_MIN_INTERVAL_START_DESC = 'METER_STATEMENTS_MIN_INTERVAL_START_DESC',
  METER_STATEMENTS_MIN_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_MIN_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_MIN_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_MIN_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  METER_STATEMENTS_MIN_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_MIN_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_MIN_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_MIN_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_MIN_METADATA_ASC = 'METER_STATEMENTS_MIN_METADATA_ASC',
  METER_STATEMENTS_MIN_METADATA_DESC = 'METER_STATEMENTS_MIN_METADATA_DESC',
  METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_AT_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_AT_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_AT_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_AT_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_MIN_PDF_LINK_ASC = 'METER_STATEMENTS_MIN_PDF_LINK_ASC',
  METER_STATEMENTS_MIN_PDF_LINK_DESC = 'METER_STATEMENTS_MIN_PDF_LINK_DESC',
  METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_MIN_STATEMENT_DATE_ASC = 'METER_STATEMENTS_MIN_STATEMENT_DATE_ASC',
  METER_STATEMENTS_MIN_STATEMENT_DATE_DESC = 'METER_STATEMENTS_MIN_STATEMENT_DATE_DESC',
  METER_STATEMENTS_MIN_UPDATED_AT_ASC = 'METER_STATEMENTS_MIN_UPDATED_AT_ASC',
  METER_STATEMENTS_MIN_UPDATED_AT_DESC = 'METER_STATEMENTS_MIN_UPDATED_AT_DESC',
  METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_METADATA_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_METADATA_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_METADATA_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_METADATA_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_SUM_CREATED_AT_ASC = 'METER_STATEMENTS_SUM_CREATED_AT_ASC',
  METER_STATEMENTS_SUM_CREATED_AT_DESC = 'METER_STATEMENTS_SUM_CREATED_AT_DESC',
  METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_DELETED_AT_ASC = 'METER_STATEMENTS_SUM_DELETED_AT_ASC',
  METER_STATEMENTS_SUM_DELETED_AT_DESC = 'METER_STATEMENTS_SUM_DELETED_AT_DESC',
  METER_STATEMENTS_SUM_ID_ASC = 'METER_STATEMENTS_SUM_ID_ASC',
  METER_STATEMENTS_SUM_ID_DESC = 'METER_STATEMENTS_SUM_ID_DESC',
  METER_STATEMENTS_SUM_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_SUM_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_SUM_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_SUM_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_SUM_INTERVAL_END_ASC = 'METER_STATEMENTS_SUM_INTERVAL_END_ASC',
  METER_STATEMENTS_SUM_INTERVAL_END_DESC = 'METER_STATEMENTS_SUM_INTERVAL_END_DESC',
  METER_STATEMENTS_SUM_INTERVAL_START_ASC = 'METER_STATEMENTS_SUM_INTERVAL_START_ASC',
  METER_STATEMENTS_SUM_INTERVAL_START_DESC = 'METER_STATEMENTS_SUM_INTERVAL_START_DESC',
  METER_STATEMENTS_SUM_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_SUM_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_SUM_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_SUM_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  METER_STATEMENTS_SUM_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_SUM_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_SUM_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_SUM_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_SUM_METADATA_ASC = 'METER_STATEMENTS_SUM_METADATA_ASC',
  METER_STATEMENTS_SUM_METADATA_DESC = 'METER_STATEMENTS_SUM_METADATA_DESC',
  METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_AT_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_AT_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_AT_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_AT_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_SUM_PDF_LINK_ASC = 'METER_STATEMENTS_SUM_PDF_LINK_ASC',
  METER_STATEMENTS_SUM_PDF_LINK_DESC = 'METER_STATEMENTS_SUM_PDF_LINK_DESC',
  METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_SUM_STATEMENT_DATE_ASC = 'METER_STATEMENTS_SUM_STATEMENT_DATE_ASC',
  METER_STATEMENTS_SUM_STATEMENT_DATE_DESC = 'METER_STATEMENTS_SUM_STATEMENT_DATE_DESC',
  METER_STATEMENTS_SUM_UPDATED_AT_ASC = 'METER_STATEMENTS_SUM_UPDATED_AT_ASC',
  METER_STATEMENTS_SUM_UPDATED_AT_DESC = 'METER_STATEMENTS_SUM_UPDATED_AT_DESC',
  METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  NATURAL = 'NATURAL',
  NORMALIZED_CURRENCY_CODE_ASC = 'NORMALIZED_CURRENCY_CODE_ASC',
  NORMALIZED_CURRENCY_CODE_DESC = 'NORMALIZED_CURRENCY_CODE_DESC',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_CURRENCY_CODE_ASC = 'OVERRIDE_CURRENCY_CODE_ASC',
  OVERRIDE_CURRENCY_CODE_DESC = 'OVERRIDE_CURRENCY_CODE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  STATEMENT_MONTH_ASC = 'STATEMENT_MONTH_ASC',
  STATEMENT_MONTH_DESC = 'STATEMENT_MONTH_DESC',
  STATEMENT_YEAR_ASC = 'STATEMENT_YEAR_ASC',
  STATEMENT_YEAR_DESC = 'STATEMENT_YEAR_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_COUNT_ASC = 'UTILITY_METER_STATEMENTS_COUNT_ASC',
  UTILITY_METER_STATEMENTS_COUNT_DESC = 'UTILITY_METER_STATEMENTS_COUNT_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC'
}

export type AcknowledgeSuggestionInput = {
  acknowledge: Scalars['Boolean'];
  id: Scalars['Int'];
};

export enum Acknowledgement {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  REJECTED = 'REJECTED',
  UNACKNOWLEDGED = 'UNACKNOWLEDGED'
}

/** A filter to be used against Acknowledgement fields. All fields are combined with a logical ‘and.’ */
export type AcknowledgementFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Acknowledgement>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Acknowledgement>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Acknowledgement>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Acknowledgement>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Acknowledgement>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Acknowledgement>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Acknowledgement>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Acknowledgement>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Acknowledgement>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Acknowledgement>>;
};

/** Represents an actor on the related entity. */
export type Actor = {
  __typename?: 'Actor';
  /** The display name of the actor. */
  displayName?: Maybe<Scalars['String']>;
  /** The associated machine user for the given actor. */
  machineUser?: Maybe<MachineUser>;
  type?: Maybe<AccessTokenType>;
  /** The associated user for the given actor. */
  user?: Maybe<User>;
};

/** A filter to be used against `Actor` object types. All fields are combined with a logical ‘and.’ */
export type ActorFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActorFilter>>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActorFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActorFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<AccessTokenTypeFilter>;
};

/** An input for mutations affecting `Actor` */
export type ActorInput = {
  type?: InputMaybe<AccessTokenType>;
};

/** All input for the `addMetricMetadataDependency` mutation. */
export type AddMetricMetadataDependencyInput = {
  childMetric: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  parentMetric: Scalars['String'];
};

/** The output of our `addMetricMetadataDependency` mutation. */
export type AddMetricMetadataDependencyPayload = {
  __typename?: 'AddMetricMetadataDependencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  metricMetadata?: Maybe<Array<MetricMetadatum>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AddProjectControllerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};

export type AddProjectControllerPayload = {
  __typename?: 'AddProjectControllerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
  user: User;
};

export type Asset = {
  nodeId: Scalars['ID'];
};

/** A connection to a list of `BalancingAuthority` values. */
export type BalancingAuthoritiesConnection = {
  __typename?: 'BalancingAuthoritiesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BalancingAuthorityAggregates>;
  /** A list of edges which contains the `BalancingAuthority` and cursor to aid in pagination. */
  edges: Array<BalancingAuthoritiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BalancingAuthorityAggregates>>;
  /** A list of `BalancingAuthority` objects. */
  nodes: Array<BalancingAuthority>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BalancingAuthority` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `BalancingAuthority` values. */
export type BalancingAuthoritiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<BalancingAuthoritiesGroupBy>;
  having?: InputMaybe<BalancingAuthoritiesHavingInput>;
};

/** A `BalancingAuthority` edge in the connection. */
export type BalancingAuthoritiesEdge = {
  __typename?: 'BalancingAuthoritiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BalancingAuthority` at the end of the edge. */
  node: BalancingAuthority;
};

/** Grouping methods for `BalancingAuthority` for usage during aggregation. */
export enum BalancingAuthoritiesGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type BalancingAuthoritiesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BalancingAuthority` aggregates. */
export type BalancingAuthoritiesHavingInput = {
  AND?: InputMaybe<Array<BalancingAuthoritiesHavingInput>>;
  OR?: InputMaybe<Array<BalancingAuthoritiesHavingInput>>;
  average?: InputMaybe<BalancingAuthoritiesHavingAverageInput>;
  distinct?: InputMaybe<BalancingAuthoritiesHavingDistinctInput>;
  distinctCount?: InputMaybe<BalancingAuthoritiesHavingDistinctCountInput>;
  max?: InputMaybe<BalancingAuthoritiesHavingMaxInput>;
  min?: InputMaybe<BalancingAuthoritiesHavingMinInput>;
  stddevPopulation?: InputMaybe<BalancingAuthoritiesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BalancingAuthoritiesHavingStddevSampleInput>;
  sum?: InputMaybe<BalancingAuthoritiesHavingSumInput>;
  variancePopulation?: InputMaybe<BalancingAuthoritiesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BalancingAuthoritiesHavingVarianceSampleInput>;
};

export type BalancingAuthoritiesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BalancingAuthoritiesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `BalancingAuthority`. */
export enum BalancingAuthoritiesOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SLUG_ASC = 'SLUG_ASC',
  SLUG_DESC = 'SLUG_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

/** Represents a balancing authority associated with emissions rate data. */
export type BalancingAuthority = Node & {
  __typename?: 'BalancingAuthority';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  slug: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};

export type BalancingAuthorityAggregates = {
  __typename?: 'BalancingAuthorityAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<BalancingAuthorityDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BalancingAuthorityDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `BalancingAuthority` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BalancingAuthorityCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type BalancingAuthorityDistinctAggregates = {
  __typename?: 'BalancingAuthorityDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of slug across the matching connection */
  slug?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type BalancingAuthorityDistinctCountAggregates = {
  __typename?: 'BalancingAuthorityDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slug across the matching connection */
  slug?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `BalancingAuthority` object types. All fields are combined with a logical ‘and.’ */
export type BalancingAuthorityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BalancingAuthorityFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BalancingAuthorityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BalancingAuthorityFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `BalancingAuthority` */
export type BalancingAuthorityInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** A component. */
export type Component = Node & {
  __typename?: 'Component';
  /** Reads and enables pagination through a set of `ControlEvent`. */
  controlEvents: ControlEventsConnection;
  /** The date the component was created at */
  createdAt: Scalars['Datetime'];
  /** Reads a single `Facility` that is related to this `Component`. */
  facility?: Maybe<Facility>;
  /** The identifier of the facility associated with the component */
  facilityId: Scalars['Int'];
  /** The identifier of the component */
  id: Scalars['Int'];
  /** Reads a single `MachineUser` that is related to this `Component`. */
  machineUser?: Maybe<MachineUser>;
  /** The identifier of the machine user associated with the component */
  machineUserId: Scalars['BigInt'];
  /** Human readable name of the component */
  name: Scalars['String'];
  /** The next or current `ControlEvent` associated with the component. */
  nextControlEvent?: Maybe<ControlEvent>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Whether the component is out of service */
  outOfService: Scalars['Boolean'];
  /** Available permissions for the Component. */
  permissions?: Maybe<ComponentPermissions>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: ComponentProjectsManyToManyConnection;
  /** The date the component was updated at */
  updatedAt: Scalars['Datetime'];
};

/** A component. */
export type ComponentControlEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventCondition>;
  filter?: InputMaybe<ControlEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** A component. */
export type ComponentProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ComponentAggregates = {
  __typename?: 'ComponentAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ComponentAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ComponentDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ComponentDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ComponentMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ComponentMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ComponentStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ComponentStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ComponentSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ComponentVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ComponentVarianceSampleAggregates>;
};

export type ComponentAverageAggregates = {
  __typename?: 'ComponentAverageAggregates';
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Component` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ComponentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `machineUserId` field. */
  machineUserId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `outOfService` field. */
  outOfService?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ComponentDistinctAggregates = {
  __typename?: 'ComponentDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The next or current `ControlEvent` associated with the component.
   */
  nextControlEvent?: Maybe<Scalars['JSON']>;
  /** Distinct of outOfService across the matching connection */
  outOfService?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type ComponentDistinctCountAggregates = {
  __typename?: 'ComponentDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The next or current `ControlEvent` associated with the component.
   */
  nextControlEvent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of outOfService across the matching connection */
  outOfService?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Component` object types. All fields are combined with a logical ‘and.’ */
export type ComponentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ComponentFilter>>;
  /** Filter by the permission can_write */
  canWrite?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `machineUserId` field. */
  machineUserId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ComponentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ComponentFilter>>;
  /** Filter by the object’s `outOfService` field. */
  outOfService?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Component` */
export type ComponentInput = {
  /** The date the component was created at */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The identifier of the facility associated with the component */
  facilityId: Scalars['Int'];
  /** The identifier of the component */
  id?: InputMaybe<Scalars['Int']>;
  /** Mutates a `MachineUser` as part of the payload */
  machineUser?: InputMaybe<MachineUserInput>;
  /** The identifier of the machine user associated with the component */
  machineUserId?: InputMaybe<Scalars['BigInt']>;
  /** Human readable name of the component */
  name: Scalars['String'];
  /** Whether the component is out of service */
  outOfService?: InputMaybe<Scalars['Boolean']>;
  /** The date the component was updated at */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ComponentMaxAggregates = {
  __typename?: 'ComponentMaxAggregates';
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigInt']>;
};

export type ComponentMinAggregates = {
  __typename?: 'ComponentMinAggregates';
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `Component`. Fields that are set will be updated. */
export type ComponentPatch = {
  /** The date the component was created at */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The identifier of the facility associated with the component */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** The identifier of the component */
  id?: InputMaybe<Scalars['Int']>;
  /** The identifier of the machine user associated with the component */
  machineUserId?: InputMaybe<Scalars['BigInt']>;
  /** Human readable name of the component */
  name?: InputMaybe<Scalars['String']>;
  /** Whether the component is out of service */
  outOfService?: InputMaybe<Scalars['Boolean']>;
  /** The date the component was updated at */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Permissive capabilities on a Component. */
export type ComponentPermissions = {
  __typename?: 'ComponentPermissions';
  /** Whether a user can update the component. */
  canWrite: Scalars['Boolean'];
};

/** A connection to a list of `Project` values, with data from `ProjectComponent`. */
export type ComponentProjectsManyToManyConnection = {
  __typename?: 'ComponentProjectsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectComponent`, and the cursor to aid in pagination. */
  edges: Array<ComponentProjectsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Project` values, with data from `ProjectComponent`. */
export type ComponentProjectsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectsGroupBy>;
  having?: InputMaybe<ProjectsHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectComponent`. */
export type ComponentProjectsManyToManyEdge = {
  __typename?: 'ComponentProjectsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

export type ComponentStddevPopulationAggregates = {
  __typename?: 'ComponentStddevPopulationAggregates';
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigFloat']>;
};

export type ComponentStddevSampleAggregates = {
  __typename?: 'ComponentStddevSampleAggregates';
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigFloat']>;
};

export type ComponentSumAggregates = {
  __typename?: 'ComponentSumAggregates';
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of machineUserId across the matching connection */
  machineUserId: Scalars['BigFloat'];
};

export type ComponentVariancePopulationAggregates = {
  __typename?: 'ComponentVariancePopulationAggregates';
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigFloat']>;
};

export type ComponentVarianceSampleAggregates = {
  __typename?: 'ComponentVarianceSampleAggregates';
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of machineUserId across the matching connection */
  machineUserId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Component` values. */
export type ComponentsConnection = {
  __typename?: 'ComponentsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ComponentAggregates>;
  /** A list of edges which contains the `Component` and cursor to aid in pagination. */
  edges: Array<ComponentsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ComponentAggregates>>;
  /** A list of `Component` objects. */
  nodes: Array<Component>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Component` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Component` values. */
export type ComponentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ComponentsGroupBy>;
  having?: InputMaybe<ComponentsHavingInput>;
};

/** A `Component` edge in the connection. */
export type ComponentsEdge = {
  __typename?: 'ComponentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Component` at the end of the edge. */
  node: Component;
};

/** Grouping methods for `Component` for usage during aggregation. */
export enum ComponentsGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  MACHINE_USER_ID = 'MACHINE_USER_ID',
  MACHINE_USER_ID_DISTINCT = 'MACHINE_USER_ID_DISTINCT',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  OUT_OF_SERVICE_DISTINCT = 'OUT_OF_SERVICE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type ComponentsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Component` aggregates. */
export type ComponentsHavingInput = {
  AND?: InputMaybe<Array<ComponentsHavingInput>>;
  OR?: InputMaybe<Array<ComponentsHavingInput>>;
  average?: InputMaybe<ComponentsHavingAverageInput>;
  distinct?: InputMaybe<ComponentsHavingDistinctInput>;
  distinctCount?: InputMaybe<ComponentsHavingDistinctCountInput>;
  max?: InputMaybe<ComponentsHavingMaxInput>;
  min?: InputMaybe<ComponentsHavingMinInput>;
  stddevPopulation?: InputMaybe<ComponentsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ComponentsHavingStddevSampleInput>;
  sum?: InputMaybe<ComponentsHavingSumInput>;
  variancePopulation?: InputMaybe<ComponentsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ComponentsHavingVarianceSampleInput>;
};

export type ComponentsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComponentsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  machineUserId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Component`. */
export enum ComponentsOrderBy {
  CONTROL_EVENTS_AVERAGE_COMPONENT_ID_ASC = 'CONTROL_EVENTS_AVERAGE_COMPONENT_ID_ASC',
  CONTROL_EVENTS_AVERAGE_COMPONENT_ID_DESC = 'CONTROL_EVENTS_AVERAGE_COMPONENT_ID_DESC',
  CONTROL_EVENTS_AVERAGE_CREATED_AT_ASC = 'CONTROL_EVENTS_AVERAGE_CREATED_AT_ASC',
  CONTROL_EVENTS_AVERAGE_CREATED_AT_DESC = 'CONTROL_EVENTS_AVERAGE_CREATED_AT_DESC',
  CONTROL_EVENTS_AVERAGE_END_TIME_ASC = 'CONTROL_EVENTS_AVERAGE_END_TIME_ASC',
  CONTROL_EVENTS_AVERAGE_END_TIME_DESC = 'CONTROL_EVENTS_AVERAGE_END_TIME_DESC',
  CONTROL_EVENTS_AVERAGE_ID_ASC = 'CONTROL_EVENTS_AVERAGE_ID_ASC',
  CONTROL_EVENTS_AVERAGE_ID_DESC = 'CONTROL_EVENTS_AVERAGE_ID_DESC',
  CONTROL_EVENTS_AVERAGE_NAME_ASC = 'CONTROL_EVENTS_AVERAGE_NAME_ASC',
  CONTROL_EVENTS_AVERAGE_NAME_DESC = 'CONTROL_EVENTS_AVERAGE_NAME_DESC',
  CONTROL_EVENTS_AVERAGE_START_TIME_ASC = 'CONTROL_EVENTS_AVERAGE_START_TIME_ASC',
  CONTROL_EVENTS_AVERAGE_START_TIME_DESC = 'CONTROL_EVENTS_AVERAGE_START_TIME_DESC',
  CONTROL_EVENTS_AVERAGE_UPDATED_AT_ASC = 'CONTROL_EVENTS_AVERAGE_UPDATED_AT_ASC',
  CONTROL_EVENTS_AVERAGE_UPDATED_AT_DESC = 'CONTROL_EVENTS_AVERAGE_UPDATED_AT_DESC',
  CONTROL_EVENTS_COUNT_ASC = 'CONTROL_EVENTS_COUNT_ASC',
  CONTROL_EVENTS_COUNT_DESC = 'CONTROL_EVENTS_COUNT_DESC',
  CONTROL_EVENTS_DISTINCT_COMPONENT_ID_ASC = 'CONTROL_EVENTS_DISTINCT_COMPONENT_ID_ASC',
  CONTROL_EVENTS_DISTINCT_COMPONENT_ID_DESC = 'CONTROL_EVENTS_DISTINCT_COMPONENT_ID_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_COMPONENT_ID_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_COMPONENT_ID_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_COMPONENT_ID_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_COMPONENT_ID_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_END_TIME_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_END_TIME_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_END_TIME_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_END_TIME_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_ID_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_ID_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_ID_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_ID_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_NAME_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_NAME_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_NAME_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_NAME_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_START_TIME_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_START_TIME_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_START_TIME_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_START_TIME_DESC',
  CONTROL_EVENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'CONTROL_EVENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  CONTROL_EVENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'CONTROL_EVENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  CONTROL_EVENTS_DISTINCT_CREATED_AT_ASC = 'CONTROL_EVENTS_DISTINCT_CREATED_AT_ASC',
  CONTROL_EVENTS_DISTINCT_CREATED_AT_DESC = 'CONTROL_EVENTS_DISTINCT_CREATED_AT_DESC',
  CONTROL_EVENTS_DISTINCT_END_TIME_ASC = 'CONTROL_EVENTS_DISTINCT_END_TIME_ASC',
  CONTROL_EVENTS_DISTINCT_END_TIME_DESC = 'CONTROL_EVENTS_DISTINCT_END_TIME_DESC',
  CONTROL_EVENTS_DISTINCT_ID_ASC = 'CONTROL_EVENTS_DISTINCT_ID_ASC',
  CONTROL_EVENTS_DISTINCT_ID_DESC = 'CONTROL_EVENTS_DISTINCT_ID_DESC',
  CONTROL_EVENTS_DISTINCT_NAME_ASC = 'CONTROL_EVENTS_DISTINCT_NAME_ASC',
  CONTROL_EVENTS_DISTINCT_NAME_DESC = 'CONTROL_EVENTS_DISTINCT_NAME_DESC',
  CONTROL_EVENTS_DISTINCT_START_TIME_ASC = 'CONTROL_EVENTS_DISTINCT_START_TIME_ASC',
  CONTROL_EVENTS_DISTINCT_START_TIME_DESC = 'CONTROL_EVENTS_DISTINCT_START_TIME_DESC',
  CONTROL_EVENTS_DISTINCT_UPDATED_AT_ASC = 'CONTROL_EVENTS_DISTINCT_UPDATED_AT_ASC',
  CONTROL_EVENTS_DISTINCT_UPDATED_AT_DESC = 'CONTROL_EVENTS_DISTINCT_UPDATED_AT_DESC',
  CONTROL_EVENTS_MAX_COMPONENT_ID_ASC = 'CONTROL_EVENTS_MAX_COMPONENT_ID_ASC',
  CONTROL_EVENTS_MAX_COMPONENT_ID_DESC = 'CONTROL_EVENTS_MAX_COMPONENT_ID_DESC',
  CONTROL_EVENTS_MAX_CREATED_AT_ASC = 'CONTROL_EVENTS_MAX_CREATED_AT_ASC',
  CONTROL_EVENTS_MAX_CREATED_AT_DESC = 'CONTROL_EVENTS_MAX_CREATED_AT_DESC',
  CONTROL_EVENTS_MAX_END_TIME_ASC = 'CONTROL_EVENTS_MAX_END_TIME_ASC',
  CONTROL_EVENTS_MAX_END_TIME_DESC = 'CONTROL_EVENTS_MAX_END_TIME_DESC',
  CONTROL_EVENTS_MAX_ID_ASC = 'CONTROL_EVENTS_MAX_ID_ASC',
  CONTROL_EVENTS_MAX_ID_DESC = 'CONTROL_EVENTS_MAX_ID_DESC',
  CONTROL_EVENTS_MAX_NAME_ASC = 'CONTROL_EVENTS_MAX_NAME_ASC',
  CONTROL_EVENTS_MAX_NAME_DESC = 'CONTROL_EVENTS_MAX_NAME_DESC',
  CONTROL_EVENTS_MAX_START_TIME_ASC = 'CONTROL_EVENTS_MAX_START_TIME_ASC',
  CONTROL_EVENTS_MAX_START_TIME_DESC = 'CONTROL_EVENTS_MAX_START_TIME_DESC',
  CONTROL_EVENTS_MAX_UPDATED_AT_ASC = 'CONTROL_EVENTS_MAX_UPDATED_AT_ASC',
  CONTROL_EVENTS_MAX_UPDATED_AT_DESC = 'CONTROL_EVENTS_MAX_UPDATED_AT_DESC',
  CONTROL_EVENTS_MIN_COMPONENT_ID_ASC = 'CONTROL_EVENTS_MIN_COMPONENT_ID_ASC',
  CONTROL_EVENTS_MIN_COMPONENT_ID_DESC = 'CONTROL_EVENTS_MIN_COMPONENT_ID_DESC',
  CONTROL_EVENTS_MIN_CREATED_AT_ASC = 'CONTROL_EVENTS_MIN_CREATED_AT_ASC',
  CONTROL_EVENTS_MIN_CREATED_AT_DESC = 'CONTROL_EVENTS_MIN_CREATED_AT_DESC',
  CONTROL_EVENTS_MIN_END_TIME_ASC = 'CONTROL_EVENTS_MIN_END_TIME_ASC',
  CONTROL_EVENTS_MIN_END_TIME_DESC = 'CONTROL_EVENTS_MIN_END_TIME_DESC',
  CONTROL_EVENTS_MIN_ID_ASC = 'CONTROL_EVENTS_MIN_ID_ASC',
  CONTROL_EVENTS_MIN_ID_DESC = 'CONTROL_EVENTS_MIN_ID_DESC',
  CONTROL_EVENTS_MIN_NAME_ASC = 'CONTROL_EVENTS_MIN_NAME_ASC',
  CONTROL_EVENTS_MIN_NAME_DESC = 'CONTROL_EVENTS_MIN_NAME_DESC',
  CONTROL_EVENTS_MIN_START_TIME_ASC = 'CONTROL_EVENTS_MIN_START_TIME_ASC',
  CONTROL_EVENTS_MIN_START_TIME_DESC = 'CONTROL_EVENTS_MIN_START_TIME_DESC',
  CONTROL_EVENTS_MIN_UPDATED_AT_ASC = 'CONTROL_EVENTS_MIN_UPDATED_AT_ASC',
  CONTROL_EVENTS_MIN_UPDATED_AT_DESC = 'CONTROL_EVENTS_MIN_UPDATED_AT_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_COMPONENT_ID_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_COMPONENT_ID_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_COMPONENT_ID_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_COMPONENT_ID_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_END_TIME_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_END_TIME_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_END_TIME_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_END_TIME_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_ID_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_ID_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_ID_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_ID_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_NAME_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_NAME_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_NAME_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_NAME_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_START_TIME_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_START_TIME_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_START_TIME_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_START_TIME_DESC',
  CONTROL_EVENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'CONTROL_EVENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  CONTROL_EVENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'CONTROL_EVENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_END_TIME_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_END_TIME_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_END_TIME_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_END_TIME_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_ID_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_ID_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_ID_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_ID_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_NAME_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_NAME_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_NAME_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_NAME_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_START_TIME_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_START_TIME_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_START_TIME_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_START_TIME_DESC',
  CONTROL_EVENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'CONTROL_EVENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CONTROL_EVENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'CONTROL_EVENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CONTROL_EVENTS_SUM_COMPONENT_ID_ASC = 'CONTROL_EVENTS_SUM_COMPONENT_ID_ASC',
  CONTROL_EVENTS_SUM_COMPONENT_ID_DESC = 'CONTROL_EVENTS_SUM_COMPONENT_ID_DESC',
  CONTROL_EVENTS_SUM_CREATED_AT_ASC = 'CONTROL_EVENTS_SUM_CREATED_AT_ASC',
  CONTROL_EVENTS_SUM_CREATED_AT_DESC = 'CONTROL_EVENTS_SUM_CREATED_AT_DESC',
  CONTROL_EVENTS_SUM_END_TIME_ASC = 'CONTROL_EVENTS_SUM_END_TIME_ASC',
  CONTROL_EVENTS_SUM_END_TIME_DESC = 'CONTROL_EVENTS_SUM_END_TIME_DESC',
  CONTROL_EVENTS_SUM_ID_ASC = 'CONTROL_EVENTS_SUM_ID_ASC',
  CONTROL_EVENTS_SUM_ID_DESC = 'CONTROL_EVENTS_SUM_ID_DESC',
  CONTROL_EVENTS_SUM_NAME_ASC = 'CONTROL_EVENTS_SUM_NAME_ASC',
  CONTROL_EVENTS_SUM_NAME_DESC = 'CONTROL_EVENTS_SUM_NAME_DESC',
  CONTROL_EVENTS_SUM_START_TIME_ASC = 'CONTROL_EVENTS_SUM_START_TIME_ASC',
  CONTROL_EVENTS_SUM_START_TIME_DESC = 'CONTROL_EVENTS_SUM_START_TIME_DESC',
  CONTROL_EVENTS_SUM_UPDATED_AT_ASC = 'CONTROL_EVENTS_SUM_UPDATED_AT_ASC',
  CONTROL_EVENTS_SUM_UPDATED_AT_DESC = 'CONTROL_EVENTS_SUM_UPDATED_AT_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_END_TIME_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_END_TIME_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_END_TIME_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_END_TIME_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_ID_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_ID_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_ID_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_ID_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_NAME_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_NAME_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_NAME_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_NAME_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_START_TIME_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_START_TIME_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_START_TIME_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_START_TIME_DESC',
  CONTROL_EVENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'CONTROL_EVENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CONTROL_EVENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'CONTROL_EVENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_END_TIME_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_END_TIME_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_END_TIME_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_END_TIME_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_ID_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_ID_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_ID_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_ID_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_NAME_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_NAME_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_NAME_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_NAME_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_START_TIME_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_START_TIME_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_START_TIME_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_START_TIME_DESC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CONTROL_EVENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'CONTROL_EVENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  MACHINE_USER_ID_ASC = 'MACHINE_USER_ID_ASC',
  MACHINE_USER_ID_DESC = 'MACHINE_USER_ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  OUT_OF_SERVICE_ASC = 'OUT_OF_SERVICE_ASC',
  OUT_OF_SERVICE_DESC = 'OUT_OF_SERVICE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_COUNT_ASC = 'PROJECT_COMPONENTS_COUNT_ASC',
  PROJECT_COMPONENTS_COUNT_DESC = 'PROJECT_COMPONENTS_COUNT_DESC',
  PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_MAX_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_MAX_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_MAX_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_MAX_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_MAX_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_MAX_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_MAX_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_MAX_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_MIN_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_MIN_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_MIN_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_MIN_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_MIN_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_MIN_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_MIN_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_MIN_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_SUM_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_SUM_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_SUM_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_SUM_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_SUM_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_SUM_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_SUM_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_SUM_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

/** A control event. */
export type ControlEvent = Node & {
  __typename?: 'ControlEvent';
  availableStates: Array<ControlEventStateType>;
  /** Reads a single `Component` that is related to this `ControlEvent`. */
  component?: Maybe<Component>;
  /** The identifier of the component associated with the control event */
  componentId: Scalars['Int'];
  /** Reads and enables pagination through a set of `ControlEventActivityLog`. */
  controlEventActivityLogs: ControlEventActivityLogsConnection;
  /** Reads and enables pagination through a set of `ControlEventLog`. */
  controlEventLogs: ControlEventLogsConnection;
  /** Reads and enables pagination through a set of `ControlEventState`. */
  controlEventStates: ControlEventStatesConnection;
  /** The date the control event was created at */
  createdAt: Scalars['Datetime'];
  currentState?: Maybe<Scalars['String']>;
  /** The current `ControlEventState` associated with the control event. */
  currentStateRef?: Maybe<ControlEventState>;
  /** The time the control event ended */
  endTime: Scalars['Datetime'];
  /** The identifier of the control event */
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Available permissions for the ControlEvent. */
  permissions?: Maybe<ControlEventPermissions>;
  /** The time the control event started */
  startTime: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `SuggestionControlEvent`. */
  suggestionControlEvents: SuggestionControlEventsConnection;
  /** Reads and enables pagination through a set of `Suggestion`. */
  suggestions: ControlEventSuggestionsManyToManyConnection;
  updatedAt: Scalars['Datetime'];
};

/** A control event. */
export type ControlEventControlEventActivityLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventActivityLogCondition>;
  filter?: InputMaybe<ControlEventActivityLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventActivityLogsOrderBy>>;
};

/** A control event. */
export type ControlEventControlEventLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventLogCondition>;
  filter?: InputMaybe<ControlEventLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** A control event. */
export type ControlEventControlEventStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventStateCondition>;
  filter?: InputMaybe<ControlEventStateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventStatesOrderBy>>;
};

/** A control event. */
export type ControlEventSuggestionControlEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionControlEventCondition>;
  filter?: InputMaybe<SuggestionControlEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
};

/** A control event. */
export type ControlEventSuggestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionCondition>;
  filter?: InputMaybe<SuggestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionsOrderBy>>;
};

export type ControlEventActivityLog = {
  __typename?: 'ControlEventActivityLog';
  /** The actor who performed the activity. */
  actor?: Maybe<Actor>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventActivityLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The control event associated with this activity log. */
  controlEventId?: Maybe<Scalars['Int']>;
  /** The metadata associated with the activity. */
  metadata?: Maybe<Scalars['JSON']>;
  /** The time at which the activity occurred. */
  time?: Maybe<Scalars['Datetime']>;
  /** The type of activity that occurred. */
  type?: Maybe<Scalars['String']>;
};

export type ControlEventActivityLogAggregates = {
  __typename?: 'ControlEventActivityLogAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ControlEventActivityLogAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ControlEventActivityLogDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ControlEventActivityLogDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ControlEventActivityLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ControlEventActivityLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ControlEventActivityLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ControlEventActivityLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ControlEventActivityLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ControlEventActivityLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ControlEventActivityLogVarianceSampleAggregates>;
};

export type ControlEventActivityLogAverageAggregates = {
  __typename?: 'ControlEventActivityLogAverageAggregates';
  /** Mean average of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ControlEventActivityLog` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ControlEventActivityLogCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<ActorInput>;
  /** Checks for equality with the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `time` field. */
  time?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
};

export type ControlEventActivityLogDistinctAggregates = {
  __typename?: 'ControlEventActivityLogDistinctAggregates';
  /** Distinct of actor across the matching connection */
  actor?: Maybe<Scalars['JSON']>;
  /** Distinct of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['JSON']>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of time across the matching connection */
  time?: Maybe<Scalars['JSON']>;
  /** Distinct of type across the matching connection */
  type?: Maybe<Scalars['JSON']>;
};

export type ControlEventActivityLogDistinctCountAggregates = {
  __typename?: 'ControlEventActivityLogDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of time across the matching connection */
  time?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ControlEventActivityLog` object types. All fields are combined with a logical ‘and.’ */
export type ControlEventActivityLogFilter = {
  /** Filter by the object’s `actor` field. */
  actor?: InputMaybe<ActorFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ControlEventActivityLogFilter>>;
  /** Filter by the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ControlEventActivityLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ControlEventActivityLogFilter>>;
  /** Filter by the object’s `time` field. */
  time?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

export type ControlEventActivityLogMaxAggregates = {
  __typename?: 'ControlEventActivityLogMaxAggregates';
  /** Maximum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
};

export type ControlEventActivityLogMinAggregates = {
  __typename?: 'ControlEventActivityLogMinAggregates';
  /** Minimum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
};

export type ControlEventActivityLogStddevPopulationAggregates = {
  __typename?: 'ControlEventActivityLogStddevPopulationAggregates';
  /** Population standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventActivityLogStddevSampleAggregates = {
  __typename?: 'ControlEventActivityLogStddevSampleAggregates';
  /** Sample standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventActivityLogSumAggregates = {
  __typename?: 'ControlEventActivityLogSumAggregates';
  /** Sum of controlEventId across the matching connection */
  controlEventId: Scalars['BigInt'];
};

export type ControlEventActivityLogVariancePopulationAggregates = {
  __typename?: 'ControlEventActivityLogVariancePopulationAggregates';
  /** Population variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventActivityLogVarianceSampleAggregates = {
  __typename?: 'ControlEventActivityLogVarianceSampleAggregates';
  /** Sample variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ControlEventActivityLog` values. */
export type ControlEventActivityLogsConnection = {
  __typename?: 'ControlEventActivityLogsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ControlEventActivityLogAggregates>;
  /** A list of edges which contains the `ControlEventActivityLog` and cursor to aid in pagination. */
  edges: Array<ControlEventActivityLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ControlEventActivityLogAggregates>>;
  /** A list of `ControlEventActivityLog` objects. */
  nodes: Array<ControlEventActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ControlEventActivityLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ControlEventActivityLog` values. */
export type ControlEventActivityLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ControlEventActivityLogsGroupBy>;
  having?: InputMaybe<ControlEventActivityLogsHavingInput>;
};

/** A `ControlEventActivityLog` edge in the connection. */
export type ControlEventActivityLogsEdge = {
  __typename?: 'ControlEventActivityLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ControlEventActivityLog` at the end of the edge. */
  node: ControlEventActivityLog;
};

/** Grouping methods for `ControlEventActivityLog` for usage during aggregation. */
export enum ControlEventActivityLogsGroupBy {
  ACTOR = 'ACTOR',
  ACTOR_DISTINCT = 'ACTOR_DISTINCT',
  CONTROL_EVENT_ID = 'CONTROL_EVENT_ID',
  CONTROL_EVENT_ID_DISTINCT = 'CONTROL_EVENT_ID_DISTINCT',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  TIME = 'TIME',
  TIME_DISTINCT = 'TIME_DISTINCT',
  TIME_TRUNCATED_TO_DAY = 'TIME_TRUNCATED_TO_DAY',
  TIME_TRUNCATED_TO_HOUR = 'TIME_TRUNCATED_TO_HOUR',
  TYPE = 'TYPE',
  TYPE_DISTINCT = 'TYPE_DISTINCT'
}

export type ControlEventActivityLogsHavingAverageInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingDistinctCountInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingDistinctInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ControlEventActivityLog` aggregates. */
export type ControlEventActivityLogsHavingInput = {
  AND?: InputMaybe<Array<ControlEventActivityLogsHavingInput>>;
  OR?: InputMaybe<Array<ControlEventActivityLogsHavingInput>>;
  average?: InputMaybe<ControlEventActivityLogsHavingAverageInput>;
  distinct?: InputMaybe<ControlEventActivityLogsHavingDistinctInput>;
  distinctCount?: InputMaybe<ControlEventActivityLogsHavingDistinctCountInput>;
  max?: InputMaybe<ControlEventActivityLogsHavingMaxInput>;
  min?: InputMaybe<ControlEventActivityLogsHavingMinInput>;
  stddevPopulation?: InputMaybe<ControlEventActivityLogsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ControlEventActivityLogsHavingStddevSampleInput>;
  sum?: InputMaybe<ControlEventActivityLogsHavingSumInput>;
  variancePopulation?: InputMaybe<ControlEventActivityLogsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ControlEventActivityLogsHavingVarianceSampleInput>;
};

export type ControlEventActivityLogsHavingMaxInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingMinInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingStddevPopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingStddevSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingSumInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingVariancePopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventActivityLogsHavingVarianceSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  time?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `ControlEventActivityLog`. */
export enum ControlEventActivityLogsOrderBy {
  ACTOR_ASC = 'ACTOR_ASC',
  ACTOR_DESC = 'ACTOR_DESC',
  CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ID_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  NATURAL = 'NATURAL',
  TIME_ASC = 'TIME_ASC',
  TIME_DESC = 'TIME_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC'
}

export type ControlEventAggregates = {
  __typename?: 'ControlEventAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ControlEventAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ControlEventDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ControlEventDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ControlEventMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ControlEventMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ControlEventStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ControlEventStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ControlEventSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ControlEventVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ControlEventVarianceSampleAggregates>;
};

export type ControlEventAverageAggregates = {
  __typename?: 'ControlEventAverageAggregates';
  /** Mean average of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ControlEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ControlEventCondition = {
  /** Checks for equality with the object’s `componentId` field. */
  componentId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ControlEventDistinctAggregates = {
  __typename?: 'ControlEventDistinctAggregates';
  /** Distinct of componentId across the matching connection */
  componentId?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  currentState?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The current `ControlEventState` associated with the control event.
   */
  currentStateRef?: Maybe<Scalars['JSON']>;
  /** Distinct of endTime across the matching connection */
  endTime?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /** Distinct of startTime across the matching connection */
  startTime?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type ControlEventDistinctCountAggregates = {
  __typename?: 'ControlEventDistinctCountAggregates';
  /** Distinct count of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  currentState?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The current `ControlEventState` associated with the control event.
   */
  currentStateRef?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endTime across the matching connection */
  endTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startTime across the matching connection */
  startTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ControlEvent` object types. All fields are combined with a logical ‘and.’ */
export type ControlEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ControlEventFilter>>;
  /** Filter by the permission can_write */
  canWrite?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `componentId` field. */
  componentId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentState` field. */
  currentState?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ControlEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ControlEventFilter>>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ControlEvent` */
export type ControlEventInput = {
  /** The identifier of the component associated with the control event */
  componentId: Scalars['Int'];
  /** The date the control event was created at */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The time the control event ended */
  endTime: Scalars['Datetime'];
  /** The identifier of the control event */
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  /** The time the control event started */
  startTime: Scalars['Datetime'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ControlEventLog = Node & {
  __typename?: 'ControlEventLog';
  /** The identifier of the actor who performed the control event state transition. */
  actor?: Maybe<Actor>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The identifier of the control event associated with the control event log entry */
  controlEventId: Scalars['Int'];
  /** The date the control event log entry was created at */
  createdAt: Scalars['Datetime'];
  /** A description of the control event log entry */
  description?: Maybe<Scalars['String']>;
  /** The identifier of the control event log entry */
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The new state of the control event */
  state: Scalars['String'];
  /** The time the control event log entry occurred */
  timestamp: Scalars['Datetime'];
};

export type ControlEventLogAggregates = {
  __typename?: 'ControlEventLogAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ControlEventLogAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ControlEventLogDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ControlEventLogDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ControlEventLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ControlEventLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ControlEventLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ControlEventLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ControlEventLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ControlEventLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ControlEventLogVarianceSampleAggregates>;
};

export type ControlEventLogAverageAggregates = {
  __typename?: 'ControlEventLogAverageAggregates';
  /** Mean average of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ControlEventLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ControlEventLogCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<ActorInput>;
  /** Checks for equality with the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']>;
};

export type ControlEventLogDistinctAggregates = {
  __typename?: 'ControlEventLogDistinctAggregates';
  /** Distinct of actor across the matching connection */
  actor?: Maybe<Scalars['JSON']>;
  /** Distinct of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of state across the matching connection */
  state?: Maybe<Scalars['JSON']>;
  /** Distinct of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['JSON']>;
};

export type ControlEventLogDistinctCountAggregates = {
  __typename?: 'ControlEventLogDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ControlEventLog` object types. All fields are combined with a logical ‘and.’ */
export type ControlEventLogFilter = {
  /** Filter by the object’s `actor` field. */
  actor?: InputMaybe<ActorFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ControlEventLogFilter>>;
  /** Filter by the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ControlEventLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ControlEventLogFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ControlEventLog` */
export type ControlEventLogInput = {
  /** The identifier of the actor who performed the control event state transition. */
  actor?: InputMaybe<ActorInput>;
  /** Mutates a `ControlEvent` as part of the payload */
  controlEvent?: InputMaybe<ControlEventInput>;
  /** The identifier of the control event associated with the control event log entry */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** The date the control event log entry was created at */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A description of the control event log entry */
  description?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event log entry */
  id?: InputMaybe<Scalars['Int']>;
  /** The new state of the control event */
  state: Scalars['String'];
  /** The time the control event log entry occurred */
  timestamp: Scalars['Datetime'];
};

export type ControlEventLogMaxAggregates = {
  __typename?: 'ControlEventLogMaxAggregates';
  /** Maximum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ControlEventLogMinAggregates = {
  __typename?: 'ControlEventLogMinAggregates';
  /** Minimum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `ControlEventLog`. Fields that are set will be updated. */
export type ControlEventLogPatch = {
  /** The identifier of the actor who performed the control event state transition. */
  actor?: InputMaybe<ActorInput>;
  /** The identifier of the control event associated with the control event log entry */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** The date the control event log entry was created at */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** A description of the control event log entry */
  description?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event log entry */
  id?: InputMaybe<Scalars['Int']>;
  /** The new state of the control event */
  state?: InputMaybe<Scalars['String']>;
  /** The time the control event log entry occurred */
  timestamp?: InputMaybe<Scalars['Datetime']>;
};

export type ControlEventLogStddevPopulationAggregates = {
  __typename?: 'ControlEventLogStddevPopulationAggregates';
  /** Population standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventLogStddevSampleAggregates = {
  __typename?: 'ControlEventLogStddevSampleAggregates';
  /** Sample standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventLogSumAggregates = {
  __typename?: 'ControlEventLogSumAggregates';
  /** Sum of controlEventId across the matching connection */
  controlEventId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type ControlEventLogVariancePopulationAggregates = {
  __typename?: 'ControlEventLogVariancePopulationAggregates';
  /** Population variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventLogVarianceSampleAggregates = {
  __typename?: 'ControlEventLogVarianceSampleAggregates';
  /** Sample variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ControlEventLog` values. */
export type ControlEventLogsConnection = {
  __typename?: 'ControlEventLogsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ControlEventLogAggregates>;
  /** A list of edges which contains the `ControlEventLog` and cursor to aid in pagination. */
  edges: Array<ControlEventLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ControlEventLogAggregates>>;
  /** A list of `ControlEventLog` objects. */
  nodes: Array<ControlEventLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ControlEventLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ControlEventLog` values. */
export type ControlEventLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ControlEventLogsGroupBy>;
  having?: InputMaybe<ControlEventLogsHavingInput>;
};

/** A `ControlEventLog` edge in the connection. */
export type ControlEventLogsEdge = {
  __typename?: 'ControlEventLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ControlEventLog` at the end of the edge. */
  node: ControlEventLog;
};

/** Grouping methods for `ControlEventLog` for usage during aggregation. */
export enum ControlEventLogsGroupBy {
  ACTOR = 'ACTOR',
  ACTOR_DISTINCT = 'ACTOR_DISTINCT',
  CONTROL_EVENT_ID = 'CONTROL_EVENT_ID',
  CONTROL_EVENT_ID_DISTINCT = 'CONTROL_EVENT_ID_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  STATE = 'STATE',
  STATE_DISTINCT = 'STATE_DISTINCT',
  TIMESTAMP = 'TIMESTAMP',
  TIMESTAMP_DISTINCT = 'TIMESTAMP_DISTINCT',
  TIMESTAMP_TRUNCATED_TO_DAY = 'TIMESTAMP_TRUNCATED_TO_DAY',
  TIMESTAMP_TRUNCATED_TO_HOUR = 'TIMESTAMP_TRUNCATED_TO_HOUR'
}

export type ControlEventLogsHavingAverageInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingDistinctCountInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingDistinctInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ControlEventLog` aggregates. */
export type ControlEventLogsHavingInput = {
  AND?: InputMaybe<Array<ControlEventLogsHavingInput>>;
  OR?: InputMaybe<Array<ControlEventLogsHavingInput>>;
  average?: InputMaybe<ControlEventLogsHavingAverageInput>;
  distinct?: InputMaybe<ControlEventLogsHavingDistinctInput>;
  distinctCount?: InputMaybe<ControlEventLogsHavingDistinctCountInput>;
  max?: InputMaybe<ControlEventLogsHavingMaxInput>;
  min?: InputMaybe<ControlEventLogsHavingMinInput>;
  stddevPopulation?: InputMaybe<ControlEventLogsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ControlEventLogsHavingStddevSampleInput>;
  sum?: InputMaybe<ControlEventLogsHavingSumInput>;
  variancePopulation?: InputMaybe<ControlEventLogsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ControlEventLogsHavingVarianceSampleInput>;
};

export type ControlEventLogsHavingMaxInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingMinInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingStddevPopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingStddevSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingSumInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingVariancePopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventLogsHavingVarianceSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `ControlEventLog`. */
export enum ControlEventLogsOrderBy {
  ACTOR_ASC = 'ACTOR_ASC',
  ACTOR_DESC = 'ACTOR_DESC',
  CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  TIMESTAMP_ASC = 'TIMESTAMP_ASC',
  TIMESTAMP_DESC = 'TIMESTAMP_DESC'
}

export type ControlEventMaxAggregates = {
  __typename?: 'ControlEventMaxAggregates';
  /** Maximum of componentId across the matching connection */
  componentId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ControlEventMinAggregates = {
  __typename?: 'ControlEventMinAggregates';
  /** Minimum of componentId across the matching connection */
  componentId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `ControlEvent`. Fields that are set will be updated. */
export type ControlEventPatch = {
  /** The time the control event ended */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** The identifier of the control event */
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  /** The time the control event started */
  startTime?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Permissive capabilities on a ControlEvent. */
export type ControlEventPermissions = {
  __typename?: 'ControlEventPermissions';
  /** Whether a user can write to the control event. */
  canWrite: Scalars['Boolean'];
};

export type ControlEventState = Node & {
  __typename?: 'ControlEventState';
  /** The identifier of the actor who performed the control event state transition. */
  actor?: Maybe<Actor>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventState`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The identifier of the control event associated with the control event state transition */
  controlEventId: Scalars['Int'];
  /** The date the control event state transition was created at */
  createdAt: Scalars['Datetime'];
  /** A description of the control event state transition */
  description?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The new state of the control event */
  state: Scalars['String'];
  /** The time the control event state transition occurred */
  timestamp: Scalars['Datetime'];
};

export type ControlEventStateAggregates = {
  __typename?: 'ControlEventStateAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ControlEventStateAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ControlEventStateDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ControlEventStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ControlEventStateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ControlEventStateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ControlEventStateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ControlEventStateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ControlEventStateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ControlEventStateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ControlEventStateVarianceSampleAggregates>;
};

export type ControlEventStateAverageAggregates = {
  __typename?: 'ControlEventStateAverageAggregates';
  /** Mean average of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ControlEventState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ControlEventStateCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<ActorInput>;
  /** Checks for equality with the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: InputMaybe<Scalars['Datetime']>;
};

export type ControlEventStateDistinctAggregates = {
  __typename?: 'ControlEventStateDistinctAggregates';
  /** Distinct of actor across the matching connection */
  actor?: Maybe<Scalars['JSON']>;
  /** Distinct of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of state across the matching connection */
  state?: Maybe<Scalars['JSON']>;
  /** Distinct of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['JSON']>;
};

export type ControlEventStateDistinctCountAggregates = {
  __typename?: 'ControlEventStateDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timestamp across the matching connection */
  timestamp?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ControlEventState` object types. All fields are combined with a logical ‘and.’ */
export type ControlEventStateFilter = {
  /** Filter by the object’s `actor` field. */
  actor?: InputMaybe<ActorFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ControlEventStateFilter>>;
  /** Filter by the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ControlEventStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ControlEventStateFilter>>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: InputMaybe<DatetimeFilter>;
};

export type ControlEventStateMaxAggregates = {
  __typename?: 'ControlEventStateMaxAggregates';
  /** Maximum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ControlEventStateMinAggregates = {
  __typename?: 'ControlEventStateMinAggregates';
  /** Minimum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ControlEventStateStddevPopulationAggregates = {
  __typename?: 'ControlEventStateStddevPopulationAggregates';
  /** Population standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventStateStddevSampleAggregates = {
  __typename?: 'ControlEventStateStddevSampleAggregates';
  /** Sample standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventStateSumAggregates = {
  __typename?: 'ControlEventStateSumAggregates';
  /** Sum of controlEventId across the matching connection */
  controlEventId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export enum ControlEventStateType {
  CANCELLED = 'CANCELLED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  STARTED = 'STARTED'
}

export type ControlEventStateVariancePopulationAggregates = {
  __typename?: 'ControlEventStateVariancePopulationAggregates';
  /** Population variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventStateVarianceSampleAggregates = {
  __typename?: 'ControlEventStateVarianceSampleAggregates';
  /** Sample variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ControlEventState` values. */
export type ControlEventStatesConnection = {
  __typename?: 'ControlEventStatesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ControlEventStateAggregates>;
  /** A list of edges which contains the `ControlEventState` and cursor to aid in pagination. */
  edges: Array<ControlEventStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ControlEventStateAggregates>>;
  /** A list of `ControlEventState` objects. */
  nodes: Array<ControlEventState>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ControlEventState` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ControlEventState` values. */
export type ControlEventStatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ControlEventStatesGroupBy>;
  having?: InputMaybe<ControlEventStatesHavingInput>;
};

/** A `ControlEventState` edge in the connection. */
export type ControlEventStatesEdge = {
  __typename?: 'ControlEventStatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ControlEventState` at the end of the edge. */
  node: ControlEventState;
};

/** Grouping methods for `ControlEventState` for usage during aggregation. */
export enum ControlEventStatesGroupBy {
  ACTOR = 'ACTOR',
  ACTOR_DISTINCT = 'ACTOR_DISTINCT',
  CONTROL_EVENT_ID = 'CONTROL_EVENT_ID',
  CONTROL_EVENT_ID_DISTINCT = 'CONTROL_EVENT_ID_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  STATE = 'STATE',
  STATE_DISTINCT = 'STATE_DISTINCT',
  TIMESTAMP = 'TIMESTAMP',
  TIMESTAMP_DISTINCT = 'TIMESTAMP_DISTINCT',
  TIMESTAMP_TRUNCATED_TO_DAY = 'TIMESTAMP_TRUNCATED_TO_DAY',
  TIMESTAMP_TRUNCATED_TO_HOUR = 'TIMESTAMP_TRUNCATED_TO_HOUR'
}

export type ControlEventStatesHavingAverageInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingDistinctCountInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingDistinctInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ControlEventState` aggregates. */
export type ControlEventStatesHavingInput = {
  AND?: InputMaybe<Array<ControlEventStatesHavingInput>>;
  OR?: InputMaybe<Array<ControlEventStatesHavingInput>>;
  average?: InputMaybe<ControlEventStatesHavingAverageInput>;
  distinct?: InputMaybe<ControlEventStatesHavingDistinctInput>;
  distinctCount?: InputMaybe<ControlEventStatesHavingDistinctCountInput>;
  max?: InputMaybe<ControlEventStatesHavingMaxInput>;
  min?: InputMaybe<ControlEventStatesHavingMinInput>;
  stddevPopulation?: InputMaybe<ControlEventStatesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ControlEventStatesHavingStddevSampleInput>;
  sum?: InputMaybe<ControlEventStatesHavingSumInput>;
  variancePopulation?: InputMaybe<ControlEventStatesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ControlEventStatesHavingVarianceSampleInput>;
};

export type ControlEventStatesHavingMaxInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingMinInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingStddevPopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingStddevSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingSumInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingVariancePopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventStatesHavingVarianceSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  timestamp?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `ControlEventState`. */
export enum ControlEventStatesOrderBy {
  ACTOR_ASC = 'ACTOR_ASC',
  ACTOR_DESC = 'ACTOR_DESC',
  CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  TIMESTAMP_ASC = 'TIMESTAMP_ASC',
  TIMESTAMP_DESC = 'TIMESTAMP_DESC'
}

export type ControlEventStddevPopulationAggregates = {
  __typename?: 'ControlEventStddevPopulationAggregates';
  /** Population standard deviation of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventStddevSampleAggregates = {
  __typename?: 'ControlEventStddevSampleAggregates';
  /** Sample standard deviation of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Suggestion` values, with data from `SuggestionControlEvent`. */
export type ControlEventSuggestionsManyToManyConnection = {
  __typename?: 'ControlEventSuggestionsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SuggestionAggregates>;
  /** A list of edges which contains the `Suggestion`, info from the `SuggestionControlEvent`, and the cursor to aid in pagination. */
  edges: Array<ControlEventSuggestionsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SuggestionAggregates>>;
  /** A list of `Suggestion` objects. */
  nodes: Array<Suggestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Suggestion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Suggestion` values, with data from `SuggestionControlEvent`. */
export type ControlEventSuggestionsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SuggestionsGroupBy>;
  having?: InputMaybe<SuggestionsHavingInput>;
};

/** A `Suggestion` edge in the connection, with data from `SuggestionControlEvent`. */
export type ControlEventSuggestionsManyToManyEdge = {
  __typename?: 'ControlEventSuggestionsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Suggestion` at the end of the edge. */
  node: Suggestion;
};

export type ControlEventSumAggregates = {
  __typename?: 'ControlEventSumAggregates';
  /** Sum of componentId across the matching connection */
  componentId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type ControlEventVariancePopulationAggregates = {
  __typename?: 'ControlEventVariancePopulationAggregates';
  /** Population variance of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ControlEventVarianceSampleAggregates = {
  __typename?: 'ControlEventVarianceSampleAggregates';
  /** Sample variance of componentId across the matching connection */
  componentId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ControlEvent` values. */
export type ControlEventsConnection = {
  __typename?: 'ControlEventsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ControlEventAggregates>;
  /** A list of edges which contains the `ControlEvent` and cursor to aid in pagination. */
  edges: Array<ControlEventsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ControlEventAggregates>>;
  /** A list of `ControlEvent` objects. */
  nodes: Array<ControlEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ControlEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ControlEvent` values. */
export type ControlEventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ControlEventsGroupBy>;
  having?: InputMaybe<ControlEventsHavingInput>;
};

/** A `ControlEvent` edge in the connection. */
export type ControlEventsEdge = {
  __typename?: 'ControlEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ControlEvent` at the end of the edge. */
  node: ControlEvent;
};

/** Grouping methods for `ControlEvent` for usage during aggregation. */
export enum ControlEventsGroupBy {
  COMPONENT_ID = 'COMPONENT_ID',
  COMPONENT_ID_DISTINCT = 'COMPONENT_ID_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  END_TIME = 'END_TIME',
  END_TIME_DISTINCT = 'END_TIME_DISTINCT',
  END_TIME_TRUNCATED_TO_DAY = 'END_TIME_TRUNCATED_TO_DAY',
  END_TIME_TRUNCATED_TO_HOUR = 'END_TIME_TRUNCATED_TO_HOUR',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  START_TIME = 'START_TIME',
  START_TIME_DISTINCT = 'START_TIME_DISTINCT',
  START_TIME_TRUNCATED_TO_DAY = 'START_TIME_TRUNCATED_TO_DAY',
  START_TIME_TRUNCATED_TO_HOUR = 'START_TIME_TRUNCATED_TO_HOUR',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type ControlEventsHavingAverageInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingDistinctCountInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingDistinctInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ControlEvent` aggregates. */
export type ControlEventsHavingInput = {
  AND?: InputMaybe<Array<ControlEventsHavingInput>>;
  OR?: InputMaybe<Array<ControlEventsHavingInput>>;
  average?: InputMaybe<ControlEventsHavingAverageInput>;
  distinct?: InputMaybe<ControlEventsHavingDistinctInput>;
  distinctCount?: InputMaybe<ControlEventsHavingDistinctCountInput>;
  max?: InputMaybe<ControlEventsHavingMaxInput>;
  min?: InputMaybe<ControlEventsHavingMinInput>;
  stddevPopulation?: InputMaybe<ControlEventsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ControlEventsHavingStddevSampleInput>;
  sum?: InputMaybe<ControlEventsHavingSumInput>;
  variancePopulation?: InputMaybe<ControlEventsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ControlEventsHavingVarianceSampleInput>;
};

export type ControlEventsHavingMaxInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingMinInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingStddevPopulationInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingStddevSampleInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingSumInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingVariancePopulationInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ControlEventsHavingVarianceSampleInput = {
  componentId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `ControlEvent`. */
export enum ControlEventsOrderBy {
  COMPONENT_ID_ASC = 'COMPONENT_ID_ASC',
  COMPONENT_ID_DESC = 'COMPONENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_AVERAGE_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_COUNT_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_COUNT_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_COUNT_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_COUNT_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_DISTINCT_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MAX_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MAX_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_MIN_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_MIN_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_SUM_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_SUM_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TIME_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TIME_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TIME_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TIME_DESC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_ASC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_ASC',
  CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_DESC = 'CONTROL_EVENT_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_ACTOR_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_ACTOR_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_ACTOR_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_ACTOR_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_ID_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_ID_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_ID_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_ID_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_STATE_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_STATE_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_STATE_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_STATE_DESC',
  CONTROL_EVENT_LOGS_AVERAGE_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_AVERAGE_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_AVERAGE_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_AVERAGE_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_COUNT_ASC = 'CONTROL_EVENT_LOGS_COUNT_ASC',
  CONTROL_EVENT_LOGS_COUNT_DESC = 'CONTROL_EVENT_LOGS_COUNT_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_ACTOR_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_ACTOR_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_ACTOR_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_ACTOR_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_ACTOR_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_ACTOR_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_ACTOR_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_ACTOR_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_ID_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_ID_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_ID_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_ID_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_STATE_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_STATE_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_STATE_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_STATE_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_COUNT_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_COUNT_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_ID_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_ID_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_ID_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_ID_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_STATE_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_STATE_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_STATE_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_STATE_DESC',
  CONTROL_EVENT_LOGS_DISTINCT_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_DISTINCT_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_DISTINCT_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_DISTINCT_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_MAX_ACTOR_ASC = 'CONTROL_EVENT_LOGS_MAX_ACTOR_ASC',
  CONTROL_EVENT_LOGS_MAX_ACTOR_DESC = 'CONTROL_EVENT_LOGS_MAX_ACTOR_DESC',
  CONTROL_EVENT_LOGS_MAX_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_MAX_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_MAX_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_MAX_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_MAX_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_MAX_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_MAX_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_MAX_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_MAX_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_MAX_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_MAX_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_MAX_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_MAX_ID_ASC = 'CONTROL_EVENT_LOGS_MAX_ID_ASC',
  CONTROL_EVENT_LOGS_MAX_ID_DESC = 'CONTROL_EVENT_LOGS_MAX_ID_DESC',
  CONTROL_EVENT_LOGS_MAX_STATE_ASC = 'CONTROL_EVENT_LOGS_MAX_STATE_ASC',
  CONTROL_EVENT_LOGS_MAX_STATE_DESC = 'CONTROL_EVENT_LOGS_MAX_STATE_DESC',
  CONTROL_EVENT_LOGS_MAX_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_MAX_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_MAX_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_MAX_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_MIN_ACTOR_ASC = 'CONTROL_EVENT_LOGS_MIN_ACTOR_ASC',
  CONTROL_EVENT_LOGS_MIN_ACTOR_DESC = 'CONTROL_EVENT_LOGS_MIN_ACTOR_DESC',
  CONTROL_EVENT_LOGS_MIN_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_MIN_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_MIN_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_MIN_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_MIN_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_MIN_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_MIN_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_MIN_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_MIN_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_MIN_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_MIN_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_MIN_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_MIN_ID_ASC = 'CONTROL_EVENT_LOGS_MIN_ID_ASC',
  CONTROL_EVENT_LOGS_MIN_ID_DESC = 'CONTROL_EVENT_LOGS_MIN_ID_DESC',
  CONTROL_EVENT_LOGS_MIN_STATE_ASC = 'CONTROL_EVENT_LOGS_MIN_STATE_ASC',
  CONTROL_EVENT_LOGS_MIN_STATE_DESC = 'CONTROL_EVENT_LOGS_MIN_STATE_DESC',
  CONTROL_EVENT_LOGS_MIN_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_MIN_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_MIN_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_MIN_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_ID_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_ID_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_ID_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_ID_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_STATE_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_STATE_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_STATE_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_STATE_DESC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_STDDEV_POPULATION_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_STDDEV_POPULATION_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ID_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ID_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ID_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_ID_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_STATE_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_STATE_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_STATE_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_STATE_DESC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_STDDEV_SAMPLE_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_STDDEV_SAMPLE_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_SUM_ACTOR_ASC = 'CONTROL_EVENT_LOGS_SUM_ACTOR_ASC',
  CONTROL_EVENT_LOGS_SUM_ACTOR_DESC = 'CONTROL_EVENT_LOGS_SUM_ACTOR_DESC',
  CONTROL_EVENT_LOGS_SUM_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_SUM_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_SUM_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_SUM_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_SUM_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_SUM_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_SUM_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_SUM_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_SUM_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_SUM_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_SUM_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_SUM_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_SUM_ID_ASC = 'CONTROL_EVENT_LOGS_SUM_ID_ASC',
  CONTROL_EVENT_LOGS_SUM_ID_DESC = 'CONTROL_EVENT_LOGS_SUM_ID_DESC',
  CONTROL_EVENT_LOGS_SUM_STATE_ASC = 'CONTROL_EVENT_LOGS_SUM_STATE_ASC',
  CONTROL_EVENT_LOGS_SUM_STATE_DESC = 'CONTROL_EVENT_LOGS_SUM_STATE_DESC',
  CONTROL_EVENT_LOGS_SUM_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_SUM_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_SUM_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_SUM_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ID_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ID_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ID_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_ID_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_STATE_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_STATE_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_STATE_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_STATE_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_POPULATION_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_POPULATION_TIMESTAMP_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ID_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ID_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ID_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_ID_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_STATE_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_STATE_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_STATE_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_STATE_DESC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_TIMESTAMP_ASC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_TIMESTAMP_DESC = 'CONTROL_EVENT_LOGS_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_AVERAGE_ACTOR_ASC = 'CONTROL_EVENT_STATES_AVERAGE_ACTOR_ASC',
  CONTROL_EVENT_STATES_AVERAGE_ACTOR_DESC = 'CONTROL_EVENT_STATES_AVERAGE_ACTOR_DESC',
  CONTROL_EVENT_STATES_AVERAGE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_AVERAGE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_AVERAGE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_AVERAGE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_AVERAGE_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_AVERAGE_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_AVERAGE_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_AVERAGE_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_AVERAGE_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_AVERAGE_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_AVERAGE_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_AVERAGE_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_AVERAGE_ID_ASC = 'CONTROL_EVENT_STATES_AVERAGE_ID_ASC',
  CONTROL_EVENT_STATES_AVERAGE_ID_DESC = 'CONTROL_EVENT_STATES_AVERAGE_ID_DESC',
  CONTROL_EVENT_STATES_AVERAGE_STATE_ASC = 'CONTROL_EVENT_STATES_AVERAGE_STATE_ASC',
  CONTROL_EVENT_STATES_AVERAGE_STATE_DESC = 'CONTROL_EVENT_STATES_AVERAGE_STATE_DESC',
  CONTROL_EVENT_STATES_AVERAGE_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_AVERAGE_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_AVERAGE_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_AVERAGE_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_COUNT_ASC = 'CONTROL_EVENT_STATES_COUNT_ASC',
  CONTROL_EVENT_STATES_COUNT_DESC = 'CONTROL_EVENT_STATES_COUNT_DESC',
  CONTROL_EVENT_STATES_DISTINCT_ACTOR_ASC = 'CONTROL_EVENT_STATES_DISTINCT_ACTOR_ASC',
  CONTROL_EVENT_STATES_DISTINCT_ACTOR_DESC = 'CONTROL_EVENT_STATES_DISTINCT_ACTOR_DESC',
  CONTROL_EVENT_STATES_DISTINCT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_DISTINCT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_DISTINCT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_DISTINCT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_ACTOR_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_ACTOR_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_ACTOR_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_ACTOR_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_ID_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_ID_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_ID_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_ID_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_STATE_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_STATE_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_STATE_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_STATE_DESC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_DISTINCT_COUNT_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_DISTINCT_COUNT_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_DISTINCT_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_DISTINCT_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_DISTINCT_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_DISTINCT_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_DISTINCT_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_DISTINCT_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_DISTINCT_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_DISTINCT_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_DISTINCT_ID_ASC = 'CONTROL_EVENT_STATES_DISTINCT_ID_ASC',
  CONTROL_EVENT_STATES_DISTINCT_ID_DESC = 'CONTROL_EVENT_STATES_DISTINCT_ID_DESC',
  CONTROL_EVENT_STATES_DISTINCT_STATE_ASC = 'CONTROL_EVENT_STATES_DISTINCT_STATE_ASC',
  CONTROL_EVENT_STATES_DISTINCT_STATE_DESC = 'CONTROL_EVENT_STATES_DISTINCT_STATE_DESC',
  CONTROL_EVENT_STATES_DISTINCT_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_DISTINCT_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_DISTINCT_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_DISTINCT_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_MAX_ACTOR_ASC = 'CONTROL_EVENT_STATES_MAX_ACTOR_ASC',
  CONTROL_EVENT_STATES_MAX_ACTOR_DESC = 'CONTROL_EVENT_STATES_MAX_ACTOR_DESC',
  CONTROL_EVENT_STATES_MAX_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_MAX_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_MAX_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_MAX_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_MAX_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_MAX_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_MAX_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_MAX_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_MAX_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_MAX_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_MAX_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_MAX_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_MAX_ID_ASC = 'CONTROL_EVENT_STATES_MAX_ID_ASC',
  CONTROL_EVENT_STATES_MAX_ID_DESC = 'CONTROL_EVENT_STATES_MAX_ID_DESC',
  CONTROL_EVENT_STATES_MAX_STATE_ASC = 'CONTROL_EVENT_STATES_MAX_STATE_ASC',
  CONTROL_EVENT_STATES_MAX_STATE_DESC = 'CONTROL_EVENT_STATES_MAX_STATE_DESC',
  CONTROL_EVENT_STATES_MAX_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_MAX_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_MAX_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_MAX_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_MIN_ACTOR_ASC = 'CONTROL_EVENT_STATES_MIN_ACTOR_ASC',
  CONTROL_EVENT_STATES_MIN_ACTOR_DESC = 'CONTROL_EVENT_STATES_MIN_ACTOR_DESC',
  CONTROL_EVENT_STATES_MIN_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_MIN_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_MIN_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_MIN_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_MIN_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_MIN_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_MIN_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_MIN_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_MIN_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_MIN_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_MIN_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_MIN_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_MIN_ID_ASC = 'CONTROL_EVENT_STATES_MIN_ID_ASC',
  CONTROL_EVENT_STATES_MIN_ID_DESC = 'CONTROL_EVENT_STATES_MIN_ID_DESC',
  CONTROL_EVENT_STATES_MIN_STATE_ASC = 'CONTROL_EVENT_STATES_MIN_STATE_ASC',
  CONTROL_EVENT_STATES_MIN_STATE_DESC = 'CONTROL_EVENT_STATES_MIN_STATE_DESC',
  CONTROL_EVENT_STATES_MIN_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_MIN_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_MIN_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_MIN_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_ID_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_ID_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_ID_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_ID_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_STATE_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_STATE_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_STATE_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_STATE_DESC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_STDDEV_POPULATION_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_STDDEV_POPULATION_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_ID_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_ID_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_ID_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_ID_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_STATE_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_STATE_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_STATE_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_STATE_DESC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_STDDEV_SAMPLE_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_STDDEV_SAMPLE_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_SUM_ACTOR_ASC = 'CONTROL_EVENT_STATES_SUM_ACTOR_ASC',
  CONTROL_EVENT_STATES_SUM_ACTOR_DESC = 'CONTROL_EVENT_STATES_SUM_ACTOR_DESC',
  CONTROL_EVENT_STATES_SUM_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_SUM_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_SUM_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_SUM_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_SUM_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_SUM_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_SUM_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_SUM_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_SUM_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_SUM_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_SUM_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_SUM_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_SUM_ID_ASC = 'CONTROL_EVENT_STATES_SUM_ID_ASC',
  CONTROL_EVENT_STATES_SUM_ID_DESC = 'CONTROL_EVENT_STATES_SUM_ID_DESC',
  CONTROL_EVENT_STATES_SUM_STATE_ASC = 'CONTROL_EVENT_STATES_SUM_STATE_ASC',
  CONTROL_EVENT_STATES_SUM_STATE_DESC = 'CONTROL_EVENT_STATES_SUM_STATE_DESC',
  CONTROL_EVENT_STATES_SUM_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_SUM_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_SUM_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_SUM_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_ACTOR_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_ACTOR_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_ACTOR_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_ACTOR_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_ID_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_ID_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_ID_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_ID_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_STATE_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_STATE_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_STATE_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_STATE_DESC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_VARIANCE_POPULATION_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_VARIANCE_POPULATION_TIMESTAMP_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ACTOR_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ACTOR_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ACTOR_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ACTOR_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ID_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ID_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ID_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_ID_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_STATE_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_STATE_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_STATE_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_STATE_DESC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_TIMESTAMP_ASC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  CONTROL_EVENT_STATES_VARIANCE_SAMPLE_TIMESTAMP_DESC = 'CONTROL_EVENT_STATES_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  END_TIME_ASC = 'END_TIME_ASC',
  END_TIME_DESC = 'END_TIME_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  START_TIME_ASC = 'START_TIME_ASC',
  START_TIME_DESC = 'START_TIME_DESC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_COUNT_ASC = 'SUGGESTION_CONTROL_EVENTS_COUNT_ASC',
  SUGGESTION_CONTROL_EVENTS_COUNT_DESC = 'SUGGESTION_CONTROL_EVENTS_COUNT_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export enum ControlType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

/** A filter to be used against ControlType fields. All fields are combined with a logical ‘and.’ */
export type ControlTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ControlType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ControlType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ControlType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ControlType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ControlType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ControlType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ControlType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ControlType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ControlType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ControlType>>;
};

export enum Country {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE'
}

/** A filter to be used against Country fields. All fields are combined with a logical ‘and.’ */
export type CountryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Country>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Country>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Country>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Country>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Country>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Country>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Country>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Country>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Country>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Country>>;
};

export type CreateAccessTokenPayload = {
  accessKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: PlatformAccessToken;
};

/** All input for the create `AccountCharge` mutation. */
export type CreateAccountChargeInput = {
  /** The `AccountCharge` to be created by this mutation. */
  accountCharge: AccountChargeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AccountCharge` mutation. */
export type CreateAccountChargePayload = {
  __typename?: 'CreateAccountChargePayload';
  /** The `AccountCharge` that was created by this mutation. */
  accountCharge?: Maybe<AccountCharge>;
  /** An edge for our `AccountCharge`. May be used by Relay 1. */
  accountChargeEdge?: Maybe<AccountChargesEdge>;
  /** Reads a single `AccountStatement` that is related to this `AccountCharge`. */
  accountStatement?: Maybe<AccountStatement>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AccountCharge` mutation. */
export type CreateAccountChargePayloadAccountChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountChargesOrderBy>>;
};

/** All input for the create `AccountStatementHistory` mutation. */
export type CreateAccountStatementHistoryInput = {
  /** The `AccountStatementHistory` to be created by this mutation. */
  accountStatementHistory: AccountStatementHistoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AccountStatementHistory` mutation. */
export type CreateAccountStatementHistoryPayload = {
  __typename?: 'CreateAccountStatementHistoryPayload';
  /** Reads a single `AccountStatement` that is related to this `AccountStatementHistory`. */
  accountStatement?: Maybe<AccountStatement>;
  /** The `AccountStatementHistory` that was created by this mutation. */
  accountStatementHistory?: Maybe<AccountStatementHistory>;
  /** An edge for our `AccountStatementHistory`. May be used by Relay 1. */
  accountStatementHistoryEdge?: Maybe<AccountStatementHistoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AccountStatementHistory` mutation. */
export type CreateAccountStatementHistoryPayloadAccountStatementHistoryEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AccountStatementHistoriesOrderBy>>;
  };

/** All input for the create `AccountStatement` mutation. */
export type CreateAccountStatementInput = {
  /** The `AccountStatement` to be created by this mutation. */
  accountStatement: AccountStatementInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AccountStatement` mutation. */
export type CreateAccountStatementPayload = {
  __typename?: 'CreateAccountStatementPayload';
  /** The `AccountStatement` that was created by this mutation. */
  accountStatement?: Maybe<AccountStatement>;
  /** An edge for our `AccountStatement`. May be used by Relay 1. */
  accountStatementEdge?: Maybe<AccountStatementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `AccountStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
};

/** The output of our create `AccountStatement` mutation. */
export type CreateAccountStatementPayloadAccountStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
};

/** All input for the create `BalancingAuthority` mutation. */
export type CreateBalancingAuthorityInput = {
  /** The `BalancingAuthority` to be created by this mutation. */
  balancingAuthority: BalancingAuthorityInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BalancingAuthority` mutation. */
export type CreateBalancingAuthorityPayload = {
  __typename?: 'CreateBalancingAuthorityPayload';
  /** The `BalancingAuthority` that was created by this mutation. */
  balancingAuthority?: Maybe<BalancingAuthority>;
  /** An edge for our `BalancingAuthority`. May be used by Relay 1. */
  balancingAuthorityEdge?: Maybe<BalancingAuthoritiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BalancingAuthority` mutation. */
export type CreateBalancingAuthorityPayloadBalancingAuthorityEdgeArgs = {
  orderBy?: InputMaybe<Array<BalancingAuthoritiesOrderBy>>;
};

/** All input for the create `Component` mutation. */
export type CreateComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Component` to be created by this mutation. */
  component: ComponentInput;
};

/** The output of our create `Component` mutation. */
export type CreateComponentPayload = {
  __typename?: 'CreateComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Component` that was created by this mutation. */
  component?: Maybe<Component>;
  /** An edge for our `Component`. May be used by Relay 1. */
  componentEdge?: Maybe<ComponentsEdge>;
  /** Reads a single `Facility` that is related to this `Component`. */
  facility?: Maybe<Facility>;
  /** Reads a single `MachineUser` that is related to this `Component`. */
  machineUser?: Maybe<MachineUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Component` mutation. */
export type CreateComponentPayloadComponentEdgeArgs = {
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** All input for the create `ControlEvent` mutation. */
export type CreateControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ControlEvent` to be created by this mutation. */
  controlEvent: ControlEventInput;
};

/** All input for the create `ControlEventLog` mutation. */
export type CreateControlEventLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ControlEventLog` to be created by this mutation. */
  controlEventLog: ControlEventLogInput;
};

/** The output of our create `ControlEventLog` mutation. */
export type CreateControlEventLogPayload = {
  __typename?: 'CreateControlEventLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The `ControlEventLog` that was created by this mutation. */
  controlEventLog?: Maybe<ControlEventLog>;
  /** An edge for our `ControlEventLog`. May be used by Relay 1. */
  controlEventLogEdge?: Maybe<ControlEventLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ControlEventLog` mutation. */
export type CreateControlEventLogPayloadControlEventLogEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** The output of our create `ControlEvent` mutation. */
export type CreateControlEventPayload = {
  __typename?: 'CreateControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ControlEvent`. */
  component?: Maybe<Component>;
  /** The `ControlEvent` that was created by this mutation. */
  controlEvent?: Maybe<ControlEvent>;
  /** An edge for our `ControlEvent`. May be used by Relay 1. */
  controlEventEdge?: Maybe<ControlEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ControlEvent` mutation. */
export type CreateControlEventPayloadControlEventEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** All input for the create `FacilityGroup` mutation. */
export type CreateFacilityGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FacilityGroup` to be created by this mutation. */
  facilityGroup: FacilityGroupInput;
};

/** The output of our create `FacilityGroup` mutation. */
export type CreateFacilityGroupPayload = {
  __typename?: 'CreateFacilityGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FacilityGroup` that was created by this mutation. */
  facilityGroup?: Maybe<FacilityGroup>;
  /** An edge for our `FacilityGroup`. May be used by Relay 1. */
  facilityGroupEdge?: Maybe<FacilityGroupsEdge>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroup`. */
  parent?: Maybe<FacilityGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FacilityGroup` mutation. */
export type CreateFacilityGroupPayloadFacilityGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** All input for the create `FacilityGroupsLinker` mutation. */
export type CreateFacilityGroupsLinkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FacilityGroupsLinker` to be created by this mutation. */
  facilityGroupsLinker: FacilityGroupsLinkerInput;
};

/** The output of our create `FacilityGroupsLinker` mutation. */
export type CreateFacilityGroupsLinkerPayload = {
  __typename?: 'CreateFacilityGroupsLinkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `FacilityGroupsLinker`. */
  facility?: Maybe<Facility>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroupsLinker`. */
  facilityGrouping?: Maybe<FacilityGroup>;
  /** The `FacilityGroupsLinker` that was created by this mutation. */
  facilityGroupsLinker?: Maybe<FacilityGroupsLinker>;
  /** An edge for our `FacilityGroupsLinker`. May be used by Relay 1. */
  facilityGroupsLinkerEdge?: Maybe<FacilityGroupsLinkersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `FacilityGroupsLinker` mutation. */
export type CreateFacilityGroupsLinkerPayloadFacilityGroupsLinkerEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

/** All input for the create `Facility` mutation. */
export type CreateFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Facility` to be created by this mutation. */
  facility: FacilityInput;
};

/** The output of our create `Facility` mutation. */
export type CreateFacilityPayload = {
  __typename?: 'CreateFacilityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Facility` that was created by this mutation. */
  facility?: Maybe<Facility>;
  /** An edge for our `Facility`. May be used by Relay 1. */
  facilityEdge?: Maybe<FacilitiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `Facility`. */
  timezone?: Maybe<Timezone>;
  /** Reads a single `UnitGroup` that is related to this `Facility`. */
  unitGroupByUnitGroup?: Maybe<UnitGroup>;
};

/** The output of our create `Facility` mutation. */
export type CreateFacilityPayloadFacilityEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

export type CreateMachineAccessTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['Datetime']>;
  machineUserId: Scalars['BigInt'];
};

export type CreateMachineAccessTokenPayload = CreateAccessTokenPayload & {
  __typename?: 'CreateMachineAccessTokenPayload';
  accessKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  machineUser: MachineUser;
  token: PlatformAccessToken;
};

/** All input for the create `MachineUser` mutation. */
export type CreateMachineUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MachineUser` to be created by this mutation. */
  machineUser: MachineUserInput;
};

/** The output of our create `MachineUser` mutation. */
export type CreateMachineUserPayload = {
  __typename?: 'CreateMachineUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MachineUser` that was created by this mutation. */
  machineUser?: Maybe<MachineUser>;
  /** An edge for our `MachineUser`. May be used by Relay 1. */
  machineUserEdge?: Maybe<MachineUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MachineUser` mutation. */
export type CreateMachineUserPayloadMachineUserEdgeArgs = {
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** All input for the create `MeterCharge` mutation. */
export type CreateMeterChargeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterCharge` to be created by this mutation. */
  meterCharge: MeterChargeInput;
};

/** The output of our create `MeterCharge` mutation. */
export type CreateMeterChargePayload = {
  __typename?: 'CreateMeterChargePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterCharge` that was created by this mutation. */
  meterCharge?: Maybe<MeterCharge>;
  /** An edge for our `MeterCharge`. May be used by Relay 1. */
  meterChargeEdge?: Maybe<MeterChargesEdge>;
  /** Reads a single `MeterStatement` that is related to this `MeterCharge`. */
  meterStatement?: Maybe<MeterStatement>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MeterCharge` mutation. */
export type CreateMeterChargePayloadMeterChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterChargesOrderBy>>;
};

/** All input for the create `MeterStatementHistory` mutation. */
export type CreateMeterStatementHistoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterStatementHistory` to be created by this mutation. */
  meterStatementHistory: MeterStatementHistoryInput;
};

/** The output of our create `MeterStatementHistory` mutation. */
export type CreateMeterStatementHistoryPayload = {
  __typename?: 'CreateMeterStatementHistoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `MeterStatementHistory`. */
  meterStatement?: Maybe<MeterStatement>;
  /** The `MeterStatementHistory` that was created by this mutation. */
  meterStatementHistory?: Maybe<MeterStatementHistory>;
  /** An edge for our `MeterStatementHistory`. May be used by Relay 1. */
  meterStatementHistoryEdge?: Maybe<MeterStatementHistoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MeterStatementHistory` mutation. */
export type CreateMeterStatementHistoryPayloadMeterStatementHistoryEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterStatementHistoriesOrderBy>>;
};

/** All input for the create `MeterStatement` mutation. */
export type CreateMeterStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterStatement` to be created by this mutation. */
  meterStatement: MeterStatementInput;
};

/** The output of our create `MeterStatement` mutation. */
export type CreateMeterStatementPayload = {
  __typename?: 'CreateMeterStatementPayload';
  /** Reads a single `AccountStatement` that is related to this `MeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterStatement` that was created by this mutation. */
  meterStatement?: Maybe<MeterStatement>;
  /** An edge for our `MeterStatement`. May be used by Relay 1. */
  meterStatementEdge?: Maybe<MeterStatementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityMeter` that is related to this `MeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
};

/** The output of our create `MeterStatement` mutation. */
export type CreateMeterStatementPayloadMeterStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

/** All input for the create `MeterUsage` mutation. */
export type CreateMeterUsageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterUsage` to be created by this mutation. */
  meterUsage: MeterUsageInput;
};

/** The output of our create `MeterUsage` mutation. */
export type CreateMeterUsagePayload = {
  __typename?: 'CreateMeterUsagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `MeterUsage`. */
  meterStatement?: Maybe<MeterStatement>;
  /** The `MeterUsage` that was created by this mutation. */
  meterUsage?: Maybe<MeterUsage>;
  /** An edge for our `MeterUsage`. May be used by Relay 1. */
  meterUsageEdge?: Maybe<MeterUsagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MeterUsage` mutation. */
export type CreateMeterUsagePayloadMeterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterUsagesOrderBy>>;
};

/** All input for the create `MetricDependency` mutation. */
export type CreateMetricDependencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MetricDependency` to be created by this mutation. */
  metricDependency: MetricDependencyInput;
};

/** The output of our create `MetricDependency` mutation. */
export type CreateMetricDependencyPayload = {
  __typename?: 'CreateMetricDependencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MetricDependency` that was created by this mutation. */
  metricDependency?: Maybe<MetricDependency>;
  /** An edge for our `MetricDependency`. May be used by Relay 1. */
  metricDependencyEdge?: Maybe<MetricDependenciesEdge>;
  /** Reads a single `MetricMetadatum` that is related to this `MetricDependency`. */
  metricMetadatumByMetric?: Maybe<MetricMetadatum>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `MetricDependency` mutation. */
export type CreateMetricDependencyPayloadMetricDependencyEdgeArgs = {
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

/** All input for the create `PlatformAccessToken` mutation. */
export type CreatePlatformAccessTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PlatformAccessToken` to be created by this mutation. */
  platformAccessToken: PlatformAccessTokenInput;
};

/** The output of our create `PlatformAccessToken` mutation. */
export type CreatePlatformAccessTokenPayload = {
  __typename?: 'CreatePlatformAccessTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PlatformAccessToken` that was created by this mutation. */
  platformAccessToken?: Maybe<PlatformAccessToken>;
  /** An edge for our `PlatformAccessToken`. May be used by Relay 1. */
  platformAccessTokenEdge?: Maybe<PlatformAccessTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `PlatformAccessToken` mutation. */
export type CreatePlatformAccessTokenPayloadPlatformAccessTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<PlatformAccessTokensOrderBy>>;
};

/** All input for the create `ProjectComponent` mutation. */
export type CreateProjectComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectComponent` to be created by this mutation. */
  projectComponent: ProjectComponentInput;
};

/** The output of our create `ProjectComponent` mutation. */
export type CreateProjectComponentPayload = {
  __typename?: 'CreateProjectComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ProjectComponent`. */
  component?: Maybe<Component>;
  /** Reads a single `Project` that is related to this `ProjectComponent`. */
  project?: Maybe<Project>;
  /** The `ProjectComponent` that was created by this mutation. */
  projectComponent?: Maybe<ProjectComponent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `Project` mutation. */
export type CreateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Project` to be created by this mutation. */
  project: ProjectInput;
};

/** The output of our create `Project` mutation. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `Project`. */
  facility?: Maybe<Facility>;
  /** The `Project` that was created by this mutation. */
  project?: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
  /** Reads a single `ProjectType` that is related to this `Project`. */
  projectType?: Maybe<ProjectType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Project` mutation. */
export type CreateProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the create `ProjectWatcher` mutation. */
export type CreateProjectWatcherInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectWatcher` to be created by this mutation. */
  projectWatcher: ProjectWatcherInput;
};

/** The output of our create `ProjectWatcher` mutation. */
export type CreateProjectWatcherPayload = {
  __typename?: 'CreateProjectWatcherPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectWatcher`. */
  project?: Maybe<Project>;
  /** The `ProjectWatcher` that was created by this mutation. */
  projectWatcher?: Maybe<ProjectWatcher>;
  /** An edge for our `ProjectWatcher`. May be used by Relay 1. */
  projectWatcherEdge?: Maybe<ProjectWatchersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectWatcher`. */
  user?: Maybe<User>;
};

/** The output of our create `ProjectWatcher` mutation. */
export type CreateProjectWatcherPayloadProjectWatcherEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** All input for the create `SuggestionActivityLog` mutation. */
export type CreateSuggestionActivityLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SuggestionActivityLog` to be created by this mutation. */
  suggestionActivityLog: SuggestionActivityLogInput;
};

/** The output of our create `SuggestionActivityLog` mutation. */
export type CreateSuggestionActivityLogPayload = {
  __typename?: 'CreateSuggestionActivityLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Suggestion` that is related to this `SuggestionActivityLog`. */
  suggestion?: Maybe<Suggestion>;
  /** The `SuggestionActivityLog` that was created by this mutation. */
  suggestionActivityLog?: Maybe<SuggestionActivityLog>;
  /** An edge for our `SuggestionActivityLog`. May be used by Relay 1. */
  suggestionActivityLogEdge?: Maybe<SuggestionActivityLogsEdge>;
};

/** The output of our create `SuggestionActivityLog` mutation. */
export type CreateSuggestionActivityLogPayloadSuggestionActivityLogEdgeArgs = {
  orderBy?: InputMaybe<Array<SuggestionActivityLogsOrderBy>>;
};

/** All input for the create `SuggestionControlEvent` mutation. */
export type CreateSuggestionControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SuggestionControlEvent` to be created by this mutation. */
  suggestionControlEvent: SuggestionControlEventInput;
};

/** The output of our create `SuggestionControlEvent` mutation. */
export type CreateSuggestionControlEventPayload = {
  __typename?: 'CreateSuggestionControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `SuggestionControlEvent`. */
  controlEvent?: Maybe<ControlEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Suggestion` that is related to this `SuggestionControlEvent`. */
  suggestion?: Maybe<Suggestion>;
  /** The `SuggestionControlEvent` that was created by this mutation. */
  suggestionControlEvent?: Maybe<SuggestionControlEvent>;
  /** An edge for our `SuggestionControlEvent`. May be used by Relay 1. */
  suggestionControlEventEdge?: Maybe<SuggestionControlEventsEdge>;
};

/** The output of our create `SuggestionControlEvent` mutation. */
export type CreateSuggestionControlEventPayloadSuggestionControlEventEdgeArgs =
  {
    orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
  };

/** All input for the create `Suggestion` mutation. */
export type CreateSuggestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Suggestion` to be created by this mutation. */
  suggestion: SuggestionInput;
};

/** The output of our create `Suggestion` mutation. */
export type CreateSuggestionPayload = {
  __typename?: 'CreateSuggestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `Suggestion`. */
  project?: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Suggestion` that was created by this mutation. */
  suggestion?: Maybe<Suggestion>;
  /** An edge for our `Suggestion`. May be used by Relay 1. */
  suggestionEdge?: Maybe<SuggestionsEdge>;
};

/** The output of our create `Suggestion` mutation. */
export type CreateSuggestionPayloadSuggestionEdgeArgs = {
  orderBy?: InputMaybe<Array<SuggestionsOrderBy>>;
};

export type CreateUserAccessTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  expiresAt?: InputMaybe<Scalars['Datetime']>;
};

export type CreateUserAccessTokenPayload = CreateAccessTokenPayload & {
  __typename?: 'CreateUserAccessTokenPayload';
  accessKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  token: PlatformAccessToken;
  user: User;
};

/** All input for the create `UtilityAccount` mutation. */
export type CreateUtilityAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityAccount` to be created by this mutation. */
  utilityAccount: UtilityAccountInput;
};

/** The output of our create `UtilityAccount` mutation. */
export type CreateUtilityAccountPayload = {
  __typename?: 'CreateUtilityAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityAccount` that was created by this mutation. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** An edge for our `UtilityAccount`. May be used by Relay 1. */
  utilityAccountEdge?: Maybe<UtilityAccountsEdge>;
  /** Reads a single `UtilityProvider` that is related to this `UtilityAccount`. */
  utilityProvider?: Maybe<UtilityProvider>;
};

/** The output of our create `UtilityAccount` mutation. */
export type CreateUtilityAccountPayloadUtilityAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
};

/** All input for the create `UtilityMeter` mutation. */
export type CreateUtilityMeterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityMeter` to be created by this mutation. */
  utilityMeter: UtilityMeterInput;
};

/** The output of our create `UtilityMeter` mutation. */
export type CreateUtilityMeterPayload = {
  __typename?: 'CreateUtilityMeterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `UtilityMeter`. */
  facility?: Maybe<Facility>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeter`. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** The `UtilityMeter` that was created by this mutation. */
  utilityMeter?: Maybe<UtilityMeter>;
  /** An edge for our `UtilityMeter`. May be used by Relay 1. */
  utilityMeterEdge?: Maybe<UtilityMetersEdge>;
};

/** The output of our create `UtilityMeter` mutation. */
export type CreateUtilityMeterPayloadUtilityMeterEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** All input for the create `UtilityProvider` mutation. */
export type CreateUtilityProviderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityProvider` to be created by this mutation. */
  utilityProvider: UtilityProviderInput;
};

/** The output of our create `UtilityProvider` mutation. */
export type CreateUtilityProviderPayload = {
  __typename?: 'CreateUtilityProviderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityProvider` that was created by this mutation. */
  utilityProvider?: Maybe<UtilityProvider>;
  /** An edge for our `UtilityProvider`. May be used by Relay 1. */
  utilityProviderEdge?: Maybe<UtilityProvidersEdge>;
};

/** The output of our create `UtilityProvider` mutation. */
export type CreateUtilityProviderPayloadUtilityProviderEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityProvidersOrderBy>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteAccountCharge` mutation. */
export type DeleteAccountChargeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteAccountCharge` mutation. */
export type DeleteAccountChargePayload = {
  __typename?: 'DeleteAccountChargePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteAccountStatement` mutation. */
export type DeleteAccountStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteAccountStatement` mutation. */
export type DeleteAccountStatementPayload = {
  __typename?: 'DeleteAccountStatementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteComponentByNodeId` mutation. */
export type DeleteComponentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Component` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteComponent` mutation. */
export type DeleteComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the component */
  id: Scalars['Int'];
};

/** The output of our delete `Component` mutation. */
export type DeleteComponentPayload = {
  __typename?: 'DeleteComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Component` that was deleted by this mutation. */
  component?: Maybe<Component>;
  /** An edge for our `Component`. May be used by Relay 1. */
  componentEdge?: Maybe<ComponentsEdge>;
  deletedComponentNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Facility` that is related to this `Component`. */
  facility?: Maybe<Facility>;
  /** Reads a single `MachineUser` that is related to this `Component`. */
  machineUser?: Maybe<MachineUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Component` mutation. */
export type DeleteComponentPayloadComponentEdgeArgs = {
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** All input for the `deleteControlEventByNodeId` mutation. */
export type DeleteControlEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ControlEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteControlEvent` mutation. */
export type DeleteControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event */
  id: Scalars['Int'];
};

/** All input for the `deleteControlEventLogByNodeId` mutation. */
export type DeleteControlEventLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ControlEventLog` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteControlEventLog` mutation. */
export type DeleteControlEventLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event log entry */
  id: Scalars['Int'];
};

/** The output of our delete `ControlEventLog` mutation. */
export type DeleteControlEventLogPayload = {
  __typename?: 'DeleteControlEventLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The `ControlEventLog` that was deleted by this mutation. */
  controlEventLog?: Maybe<ControlEventLog>;
  /** An edge for our `ControlEventLog`. May be used by Relay 1. */
  controlEventLogEdge?: Maybe<ControlEventLogsEdge>;
  deletedControlEventLogNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ControlEventLog` mutation. */
export type DeleteControlEventLogPayloadControlEventLogEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** The output of our delete `ControlEvent` mutation. */
export type DeleteControlEventPayload = {
  __typename?: 'DeleteControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ControlEvent`. */
  component?: Maybe<Component>;
  /** The `ControlEvent` that was deleted by this mutation. */
  controlEvent?: Maybe<ControlEvent>;
  /** An edge for our `ControlEvent`. May be used by Relay 1. */
  controlEventEdge?: Maybe<ControlEventsEdge>;
  deletedControlEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ControlEvent` mutation. */
export type DeleteControlEventPayloadControlEventEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** All input for the `deleteFacilityByNodeId` mutation. */
export type DeleteFacilityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Facility` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFacilityBySlug` mutation. */
export type DeleteFacilityBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The facility’s slug. */
  slug: Scalars['String'];
};

/** All input for the `deleteFacilityGroupByNodeId` mutation. */
export type DeleteFacilityGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FacilityGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFacilityGroup` mutation. */
export type DeleteFacilityGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The facility group’s id. */
  id: Scalars['BigInt'];
};

/** The output of our delete `FacilityGroup` mutation. */
export type DeleteFacilityGroupPayload = {
  __typename?: 'DeleteFacilityGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFacilityGroupNodeId?: Maybe<Scalars['ID']>;
  /** The `FacilityGroup` that was deleted by this mutation. */
  facilityGroup?: Maybe<FacilityGroup>;
  /** An edge for our `FacilityGroup`. May be used by Relay 1. */
  facilityGroupEdge?: Maybe<FacilityGroupsEdge>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroup`. */
  parent?: Maybe<FacilityGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FacilityGroup` mutation. */
export type DeleteFacilityGroupPayloadFacilityGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** All input for the `deleteFacilityGroupsLinkerByNodeId` mutation. */
export type DeleteFacilityGroupsLinkerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FacilityGroupsLinker` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFacilityGroupsLinker` mutation. */
export type DeleteFacilityGroupsLinkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupingId: Scalars['BigInt'];
  facilityId: Scalars['Int'];
};

/** The output of our delete `FacilityGroupsLinker` mutation. */
export type DeleteFacilityGroupsLinkerPayload = {
  __typename?: 'DeleteFacilityGroupsLinkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFacilityGroupsLinkerNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Facility` that is related to this `FacilityGroupsLinker`. */
  facility?: Maybe<Facility>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroupsLinker`. */
  facilityGrouping?: Maybe<FacilityGroup>;
  /** The `FacilityGroupsLinker` that was deleted by this mutation. */
  facilityGroupsLinker?: Maybe<FacilityGroupsLinker>;
  /** An edge for our `FacilityGroupsLinker`. May be used by Relay 1. */
  facilityGroupsLinkerEdge?: Maybe<FacilityGroupsLinkersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `FacilityGroupsLinker` mutation. */
export type DeleteFacilityGroupsLinkerPayloadFacilityGroupsLinkerEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

/** All input for the `deleteFacility` mutation. */
export type DeleteFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the facility. */
  id: Scalars['Int'];
};

/** The output of our delete `Facility` mutation. */
export type DeleteFacilityPayload = {
  __typename?: 'DeleteFacilityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFacilityNodeId?: Maybe<Scalars['ID']>;
  /** The `Facility` that was deleted by this mutation. */
  facility?: Maybe<Facility>;
  /** An edge for our `Facility`. May be used by Relay 1. */
  facilityEdge?: Maybe<FacilitiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `Facility`. */
  timezone?: Maybe<Timezone>;
  /** Reads a single `UnitGroup` that is related to this `Facility`. */
  unitGroupByUnitGroup?: Maybe<UnitGroup>;
};

/** The output of our delete `Facility` mutation. */
export type DeleteFacilityPayloadFacilityEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** All input for the `deleteMachineUserByNodeId` mutation. */
export type DeleteMachineUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MachineUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMachineUser` mutation. */
export type DeleteMachineUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the machine user. */
  id: Scalars['BigInt'];
};

/** The output of our delete `MachineUser` mutation. */
export type DeleteMachineUserPayload = {
  __typename?: 'DeleteMachineUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMachineUserNodeId?: Maybe<Scalars['ID']>;
  /** The `MachineUser` that was deleted by this mutation. */
  machineUser?: Maybe<MachineUser>;
  /** An edge for our `MachineUser`. May be used by Relay 1. */
  machineUserEdge?: Maybe<MachineUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `MachineUser` mutation. */
export type DeleteMachineUserPayloadMachineUserEdgeArgs = {
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** All input for the `deleteMeterCharge` mutation. */
export type DeleteMeterChargeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteMeterCharge` mutation. */
export type DeleteMeterChargePayload = {
  __typename?: 'DeleteMeterChargePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteMeterStatement` mutation. */
export type DeleteMeterStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteMeterStatement` mutation. */
export type DeleteMeterStatementPayload = {
  __typename?: 'DeleteMeterStatementPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteMeterUsage` mutation. */
export type DeleteMeterUsageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteMeterUsage` mutation. */
export type DeleteMeterUsagePayload = {
  __typename?: 'DeleteMeterUsagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteMetricDependencyByNodeId` mutation. */
export type DeleteMetricDependencyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MetricDependency` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMetricDependencyByPath` mutation. */
export type DeleteMetricDependencyByPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
};

/** All input for the `deleteMetricDependency` mutation. */
export type DeleteMetricDependencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['BigInt'];
};

/** The output of our delete `MetricDependency` mutation. */
export type DeleteMetricDependencyPayload = {
  __typename?: 'DeleteMetricDependencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMetricDependencyNodeId?: Maybe<Scalars['ID']>;
  /** The `MetricDependency` that was deleted by this mutation. */
  metricDependency?: Maybe<MetricDependency>;
  /** An edge for our `MetricDependency`. May be used by Relay 1. */
  metricDependencyEdge?: Maybe<MetricDependenciesEdge>;
  /** Reads a single `MetricMetadatum` that is related to this `MetricDependency`. */
  metricMetadatumByMetric?: Maybe<MetricMetadatum>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `MetricDependency` mutation. */
export type DeleteMetricDependencyPayloadMetricDependencyEdgeArgs = {
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

export type DeletePhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type DeletePhoneNumberResponse = {
  __typename?: 'DeletePhoneNumberResponse';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** All input for the `deleteProjectByNodeId` mutation. */
export type DeleteProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Project` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectComponentByNodeId` mutation. */
export type DeleteProjectComponentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectComponent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectComponent` mutation. */
export type DeleteProjectComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  componentId: Scalars['Int'];
  projectId: Scalars['Int'];
};

/** The output of our delete `ProjectComponent` mutation. */
export type DeleteProjectComponentPayload = {
  __typename?: 'DeleteProjectComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ProjectComponent`. */
  component?: Maybe<Component>;
  deletedProjectComponentNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Project` that is related to this `ProjectComponent`. */
  project?: Maybe<Project>;
  /** The `ProjectComponent` that was deleted by this mutation. */
  projectComponent?: Maybe<ProjectComponent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteProject` mutation. */
export type DeleteProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the optimization project. */
  id: Scalars['Int'];
};

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProjectNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Facility` that is related to this `Project`. */
  facility?: Maybe<Facility>;
  /** The `Project` that was deleted by this mutation. */
  project?: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
  /** Reads a single `ProjectType` that is related to this `Project`. */
  projectType?: Maybe<ProjectType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the `deleteProjectWatcherByNodeId` mutation. */
export type DeleteProjectWatcherByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectWatcher` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectWatcher` mutation. */
export type DeleteProjectWatcherInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};

/** The output of our delete `ProjectWatcher` mutation. */
export type DeleteProjectWatcherPayload = {
  __typename?: 'DeleteProjectWatcherPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedProjectWatcherNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Project` that is related to this `ProjectWatcher`. */
  project?: Maybe<Project>;
  /** The `ProjectWatcher` that was deleted by this mutation. */
  projectWatcher?: Maybe<ProjectWatcher>;
  /** An edge for our `ProjectWatcher`. May be used by Relay 1. */
  projectWatcherEdge?: Maybe<ProjectWatchersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectWatcher`. */
  user?: Maybe<User>;
};

/** The output of our delete `ProjectWatcher` mutation. */
export type DeleteProjectWatcherPayloadProjectWatcherEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** All input for the `deleteSuggestionControlEventByNodeId` mutation. */
export type DeleteSuggestionControlEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SuggestionControlEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSuggestionControlEvent` mutation. */
export type DeleteSuggestionControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  controlEventId: Scalars['Int'];
  suggestionId: Scalars['Int'];
};

/** The output of our delete `SuggestionControlEvent` mutation. */
export type DeleteSuggestionControlEventPayload = {
  __typename?: 'DeleteSuggestionControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `SuggestionControlEvent`. */
  controlEvent?: Maybe<ControlEvent>;
  deletedSuggestionControlEventNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Suggestion` that is related to this `SuggestionControlEvent`. */
  suggestion?: Maybe<Suggestion>;
  /** The `SuggestionControlEvent` that was deleted by this mutation. */
  suggestionControlEvent?: Maybe<SuggestionControlEvent>;
  /** An edge for our `SuggestionControlEvent`. May be used by Relay 1. */
  suggestionControlEventEdge?: Maybe<SuggestionControlEventsEdge>;
};

/** The output of our delete `SuggestionControlEvent` mutation. */
export type DeleteSuggestionControlEventPayloadSuggestionControlEventEdgeArgs =
  {
    orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
  };

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier of the user. */
  id: Scalars['String'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteUtilityAccount` mutation. */
export type DeleteUtilityAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteUtilityAccount` mutation. */
export type DeleteUtilityAccountPayload = {
  __typename?: 'DeleteUtilityAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteUtilityMeter` mutation. */
export type DeleteUtilityMeterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteUtilityMeter` mutation. */
export type DeleteUtilityMeterPayload = {
  __typename?: 'DeleteUtilityMeterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteUtilityProviderByName` mutation. */
export type DeleteUtilityProviderByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the provider. */
  name: Scalars['String'];
};

/** All input for the `deleteUtilityProviderByNodeId` mutation. */
export type DeleteUtilityProviderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UtilityProvider` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUtilityProvider` mutation. */
export type DeleteUtilityProviderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the provider. */
  id: Scalars['Int'];
};

/** The output of our delete `UtilityProvider` mutation. */
export type DeleteUtilityProviderPayload = {
  __typename?: 'DeleteUtilityProviderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUtilityProviderNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityProvider` that was deleted by this mutation. */
  utilityProvider?: Maybe<UtilityProvider>;
  /** An edge for our `UtilityProvider`. May be used by Relay 1. */
  utilityProviderEdge?: Maybe<UtilityProvidersEdge>;
};

/** The output of our delete `UtilityProvider` mutation. */
export type DeleteUtilityProviderPayloadUtilityProviderEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityProvidersOrderBy>>;
};

export type DerivedStatementDate = {
  __typename?: 'DerivedStatementDate';
  statementDate?: Maybe<Scalars['Date']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
};

/** An event */
export type Event = Node & {
  __typename?: 'Event';
  /** The associated action. */
  action?: Maybe<Scalars['String']>;
  /** The actor that triggered the event. */
  actor?: Maybe<Actor>;
  /** The time the event was created. */
  createdAt: Scalars['Datetime'];
  event: Scalars['JSON'];
  /** Reads a single `EventType` that is related to this `Event`. */
  eventTypeByType?: Maybe<EventType>;
  /** Reads a single `Facility` that is related to this `Event`. */
  facility?: Maybe<Facility>;
  /** The associated Facility. */
  facilityId: Scalars['Int'];
  /** The identifier of the event. */
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The type of event. */
  type: Scalars['String'];
};

export type EventAggregates = {
  __typename?: 'EventAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EventAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<EventDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EventDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EventMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EventMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EventStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EventStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EventSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EventVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EventVarianceSampleAggregates>;
};

export type EventAverageAggregates = {
  __typename?: 'EventAverageAggregates';
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Event` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EventCondition = {
  /** Checks for equality with the object’s `action` field. */
  action?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<ActorInput>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `event` field. */
  event?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
};

export type EventDistinctAggregates = {
  __typename?: 'EventDistinctAggregates';
  /** Distinct of action across the matching connection */
  action?: Maybe<Scalars['JSON']>;
  /** Distinct of actor across the matching connection */
  actor?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of event across the matching connection */
  event?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of type across the matching connection */
  type?: Maybe<Scalars['JSON']>;
};

export type EventDistinctCountAggregates = {
  __typename?: 'EventDistinctCountAggregates';
  /** Distinct count of action across the matching connection */
  action?: Maybe<Scalars['BigInt']>;
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of event across the matching connection */
  event?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Event` object types. All fields are combined with a logical ‘and.’ */
export type EventFilter = {
  /** Filter by the object’s `action` field. */
  action?: InputMaybe<StringFilter>;
  /** Filter by the object’s `actor` field. */
  actor?: InputMaybe<ActorFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `event` field. */
  event?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** Handler for events. */
export type EventHandler = Node & {
  __typename?: 'EventHandler';
  /** Reads and enables pagination through a set of `EventType`. */
  eventTypesByEventHandlerMetadatumHandlerAndType: EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyConnection;
  /** The name of the event handler. */
  handler: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Handler for events. */
export type EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventTypeCondition>;
  filter?: InputMaybe<EventTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};

export type EventHandlerAggregates = {
  __typename?: 'EventHandlerAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<EventHandlerDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EventHandlerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `EventHandler` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EventHandlerCondition = {
  /** Checks for equality with the object’s `handler` field. */
  handler?: InputMaybe<Scalars['String']>;
};

export type EventHandlerDistinctAggregates = {
  __typename?: 'EventHandlerDistinctAggregates';
  /** Distinct of handler across the matching connection */
  handler?: Maybe<Scalars['JSON']>;
};

export type EventHandlerDistinctCountAggregates = {
  __typename?: 'EventHandlerDistinctCountAggregates';
  /** Distinct count of handler across the matching connection */
  handler?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `EventType` values, with data from `EventHandlerMetadatum`. */
export type EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyConnection =
  {
    __typename?: 'EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EventTypeAggregates>;
    /** A list of edges which contains the `EventType`, info from the `EventHandlerMetadatum`, and the cursor to aid in pagination. */
    edges: Array<EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyEdge>;
    /** A list of `EventType` objects. */
    nodes: Array<EventType>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EventType` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A `EventType` edge in the connection, with data from `EventHandlerMetadatum`. */
export type EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyEdge =
  {
    __typename?: 'EventHandlerEventTypesByEventHandlerMetadatumHandlerAndTypeManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    event: Scalars['JSON'];
    /** The `EventType` at the end of the edge. */
    node: EventType;
  };

/** A filter to be used against `EventHandler` object types. All fields are combined with a logical ‘and.’ */
export type EventHandlerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventHandlerFilter>>;
  /** Filter by the object’s `handler` field. */
  handler?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventHandlerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventHandlerFilter>>;
};

/** Event metadata for specific handlers. */
export type EventHandlerMetadatum = Node & {
  __typename?: 'EventHandlerMetadatum';
  event: Scalars['JSON'];
  /** Reads a single `EventHandler` that is related to this `EventHandlerMetadatum`. */
  eventHandlerByHandler?: Maybe<EventHandler>;
  /** Reads a single `EventType` that is related to this `EventHandlerMetadatum`. */
  eventTypeByType?: Maybe<EventType>;
  /** The event handler. */
  handler: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The type of event. */
  type: Scalars['String'];
};

/** Methods to use when ordering `EventHandler`. */
export enum EventHandlersOrderBy {
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_AVERAGE_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_COUNT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_COUNT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_COUNT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_COUNT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_COUNT_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_DISTINCT_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MAX_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MAX_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_MIN_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_MIN_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_POPULATION_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_STDDEV_SAMPLE_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_SUM_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_SUM_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_POPULATION_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_HANDLER_VARIANCE_SAMPLE_TYPE_DESC',
  HANDLER_ASC = 'HANDLER_ASC',
  HANDLER_DESC = 'HANDLER_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC'
}

export type EventMaxAggregates = {
  __typename?: 'EventMaxAggregates';
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type EventMinAggregates = {
  __typename?: 'EventMinAggregates';
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type EventStddevPopulationAggregates = {
  __typename?: 'EventStddevPopulationAggregates';
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventStddevSampleAggregates = {
  __typename?: 'EventStddevSampleAggregates';
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventSumAggregates = {
  __typename?: 'EventSumAggregates';
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

/** Type of event that user can subscribe to. */
export type EventType = Node & {
  __typename?: 'EventType';
  /** Reads and enables pagination through a set of `EventHandler`. */
  eventHandlersByEventHandlerMetadatumTypeAndHandler: EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyConnection;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByEventTypeAndFacilityId: EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyConnection;
  /** The name of the event type. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Type of event that user can subscribe to. */
export type EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventHandlerCondition>;
  filter?: InputMaybe<EventHandlerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventHandlersOrderBy>>;
};

/** Type of event that user can subscribe to. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

export type EventTypeAggregates = {
  __typename?: 'EventTypeAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<EventTypeDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EventTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `EventType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EventTypeCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

export type EventTypeDistinctAggregates = {
  __typename?: 'EventTypeDistinctAggregates';
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
};

export type EventTypeDistinctCountAggregates = {
  __typename?: 'EventTypeDistinctCountAggregates';
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `EventHandler` values, with data from `EventHandlerMetadatum`. */
export type EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyConnection =
  {
    __typename?: 'EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EventHandlerAggregates>;
    /** A list of edges which contains the `EventHandler`, info from the `EventHandlerMetadatum`, and the cursor to aid in pagination. */
    edges: Array<EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyEdge>;
    /** A list of `EventHandler` objects. */
    nodes: Array<EventHandler>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EventHandler` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A `EventHandler` edge in the connection, with data from `EventHandlerMetadatum`. */
export type EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyEdge =
  {
    __typename?: 'EventTypeEventHandlersByEventHandlerMetadatumTypeAndHandlerManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    event: Scalars['JSON'];
    /** The `EventHandler` at the end of the edge. */
    node: EventHandler;
  };

/** A connection to a list of `Facility` values, with data from `Event`. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyConnection = {
  __typename?: 'EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityAggregates>;
  /** A list of edges which contains the `Facility`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityAggregates>>;
  /** Aggregates a metric by label for the specified filter expression. */
  metricData?: Maybe<MetricDataConnection>;
  /** A list of `Facility` objects. */
  nodes: Array<Facility>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Facility` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Facility` values, with data from `Event`. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `Event`. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `Event`. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyEdge = {
  __typename?: 'EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Event`. */
  events: EventsConnection;
  /** The `Facility` at the end of the edge. */
  node: Facility;
};

/** A `Facility` edge in the connection, with data from `Event`. */
export type EventTypeFacilitiesByEventTypeAndFacilityIdManyToManyEdgeEventsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<EventCondition>;
    filter?: InputMaybe<EventFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<EventsOrderBy>>;
  };

/** A filter to be used against `EventType` object types. All fields are combined with a logical ‘and.’ */
export type EventTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventTypeFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EventTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventTypeFilter>>;
};

/** Methods to use when ordering `EventType`. */
export enum EventTypesOrderBy {
  EVENTS_BY_TYPE_AVERAGE_ACTION_ASC = 'EVENTS_BY_TYPE_AVERAGE_ACTION_ASC',
  EVENTS_BY_TYPE_AVERAGE_ACTION_DESC = 'EVENTS_BY_TYPE_AVERAGE_ACTION_DESC',
  EVENTS_BY_TYPE_AVERAGE_ACTOR_ASC = 'EVENTS_BY_TYPE_AVERAGE_ACTOR_ASC',
  EVENTS_BY_TYPE_AVERAGE_ACTOR_DESC = 'EVENTS_BY_TYPE_AVERAGE_ACTOR_DESC',
  EVENTS_BY_TYPE_AVERAGE_CREATED_AT_ASC = 'EVENTS_BY_TYPE_AVERAGE_CREATED_AT_ASC',
  EVENTS_BY_TYPE_AVERAGE_CREATED_AT_DESC = 'EVENTS_BY_TYPE_AVERAGE_CREATED_AT_DESC',
  EVENTS_BY_TYPE_AVERAGE_EVENT_ASC = 'EVENTS_BY_TYPE_AVERAGE_EVENT_ASC',
  EVENTS_BY_TYPE_AVERAGE_EVENT_DESC = 'EVENTS_BY_TYPE_AVERAGE_EVENT_DESC',
  EVENTS_BY_TYPE_AVERAGE_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_AVERAGE_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_AVERAGE_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_AVERAGE_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_AVERAGE_ID_ASC = 'EVENTS_BY_TYPE_AVERAGE_ID_ASC',
  EVENTS_BY_TYPE_AVERAGE_ID_DESC = 'EVENTS_BY_TYPE_AVERAGE_ID_DESC',
  EVENTS_BY_TYPE_AVERAGE_TYPE_ASC = 'EVENTS_BY_TYPE_AVERAGE_TYPE_ASC',
  EVENTS_BY_TYPE_AVERAGE_TYPE_DESC = 'EVENTS_BY_TYPE_AVERAGE_TYPE_DESC',
  EVENTS_BY_TYPE_COUNT_ASC = 'EVENTS_BY_TYPE_COUNT_ASC',
  EVENTS_BY_TYPE_COUNT_DESC = 'EVENTS_BY_TYPE_COUNT_DESC',
  EVENTS_BY_TYPE_DISTINCT_ACTION_ASC = 'EVENTS_BY_TYPE_DISTINCT_ACTION_ASC',
  EVENTS_BY_TYPE_DISTINCT_ACTION_DESC = 'EVENTS_BY_TYPE_DISTINCT_ACTION_DESC',
  EVENTS_BY_TYPE_DISTINCT_ACTOR_ASC = 'EVENTS_BY_TYPE_DISTINCT_ACTOR_ASC',
  EVENTS_BY_TYPE_DISTINCT_ACTOR_DESC = 'EVENTS_BY_TYPE_DISTINCT_ACTOR_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ACTOR_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ACTOR_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ACTOR_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ACTOR_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_EVENT_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_EVENT_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_EVENT_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_EVENT_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ID_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ID_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_ID_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_ID_DESC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC',
  EVENTS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC = 'EVENTS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC',
  EVENTS_BY_TYPE_DISTINCT_CREATED_AT_ASC = 'EVENTS_BY_TYPE_DISTINCT_CREATED_AT_ASC',
  EVENTS_BY_TYPE_DISTINCT_CREATED_AT_DESC = 'EVENTS_BY_TYPE_DISTINCT_CREATED_AT_DESC',
  EVENTS_BY_TYPE_DISTINCT_EVENT_ASC = 'EVENTS_BY_TYPE_DISTINCT_EVENT_ASC',
  EVENTS_BY_TYPE_DISTINCT_EVENT_DESC = 'EVENTS_BY_TYPE_DISTINCT_EVENT_DESC',
  EVENTS_BY_TYPE_DISTINCT_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_DISTINCT_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_DISTINCT_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_DISTINCT_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_DISTINCT_ID_ASC = 'EVENTS_BY_TYPE_DISTINCT_ID_ASC',
  EVENTS_BY_TYPE_DISTINCT_ID_DESC = 'EVENTS_BY_TYPE_DISTINCT_ID_DESC',
  EVENTS_BY_TYPE_DISTINCT_TYPE_ASC = 'EVENTS_BY_TYPE_DISTINCT_TYPE_ASC',
  EVENTS_BY_TYPE_DISTINCT_TYPE_DESC = 'EVENTS_BY_TYPE_DISTINCT_TYPE_DESC',
  EVENTS_BY_TYPE_MAX_ACTION_ASC = 'EVENTS_BY_TYPE_MAX_ACTION_ASC',
  EVENTS_BY_TYPE_MAX_ACTION_DESC = 'EVENTS_BY_TYPE_MAX_ACTION_DESC',
  EVENTS_BY_TYPE_MAX_ACTOR_ASC = 'EVENTS_BY_TYPE_MAX_ACTOR_ASC',
  EVENTS_BY_TYPE_MAX_ACTOR_DESC = 'EVENTS_BY_TYPE_MAX_ACTOR_DESC',
  EVENTS_BY_TYPE_MAX_CREATED_AT_ASC = 'EVENTS_BY_TYPE_MAX_CREATED_AT_ASC',
  EVENTS_BY_TYPE_MAX_CREATED_AT_DESC = 'EVENTS_BY_TYPE_MAX_CREATED_AT_DESC',
  EVENTS_BY_TYPE_MAX_EVENT_ASC = 'EVENTS_BY_TYPE_MAX_EVENT_ASC',
  EVENTS_BY_TYPE_MAX_EVENT_DESC = 'EVENTS_BY_TYPE_MAX_EVENT_DESC',
  EVENTS_BY_TYPE_MAX_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_MAX_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_MAX_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_MAX_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_MAX_ID_ASC = 'EVENTS_BY_TYPE_MAX_ID_ASC',
  EVENTS_BY_TYPE_MAX_ID_DESC = 'EVENTS_BY_TYPE_MAX_ID_DESC',
  EVENTS_BY_TYPE_MAX_TYPE_ASC = 'EVENTS_BY_TYPE_MAX_TYPE_ASC',
  EVENTS_BY_TYPE_MAX_TYPE_DESC = 'EVENTS_BY_TYPE_MAX_TYPE_DESC',
  EVENTS_BY_TYPE_MIN_ACTION_ASC = 'EVENTS_BY_TYPE_MIN_ACTION_ASC',
  EVENTS_BY_TYPE_MIN_ACTION_DESC = 'EVENTS_BY_TYPE_MIN_ACTION_DESC',
  EVENTS_BY_TYPE_MIN_ACTOR_ASC = 'EVENTS_BY_TYPE_MIN_ACTOR_ASC',
  EVENTS_BY_TYPE_MIN_ACTOR_DESC = 'EVENTS_BY_TYPE_MIN_ACTOR_DESC',
  EVENTS_BY_TYPE_MIN_CREATED_AT_ASC = 'EVENTS_BY_TYPE_MIN_CREATED_AT_ASC',
  EVENTS_BY_TYPE_MIN_CREATED_AT_DESC = 'EVENTS_BY_TYPE_MIN_CREATED_AT_DESC',
  EVENTS_BY_TYPE_MIN_EVENT_ASC = 'EVENTS_BY_TYPE_MIN_EVENT_ASC',
  EVENTS_BY_TYPE_MIN_EVENT_DESC = 'EVENTS_BY_TYPE_MIN_EVENT_DESC',
  EVENTS_BY_TYPE_MIN_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_MIN_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_MIN_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_MIN_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_MIN_ID_ASC = 'EVENTS_BY_TYPE_MIN_ID_ASC',
  EVENTS_BY_TYPE_MIN_ID_DESC = 'EVENTS_BY_TYPE_MIN_ID_DESC',
  EVENTS_BY_TYPE_MIN_TYPE_ASC = 'EVENTS_BY_TYPE_MIN_TYPE_ASC',
  EVENTS_BY_TYPE_MIN_TYPE_DESC = 'EVENTS_BY_TYPE_MIN_TYPE_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ACTOR_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ACTOR_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ACTOR_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ACTOR_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_EVENT_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_EVENT_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_EVENT_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_EVENT_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ID_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ID_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_ID_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_ID_DESC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC',
  EVENTS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC = 'EVENTS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTOR_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTOR_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTOR_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ACTOR_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_EVENT_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_EVENT_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_EVENT_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_EVENT_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ID_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ID_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_ID_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_ID_DESC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC',
  EVENTS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC = 'EVENTS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC',
  EVENTS_BY_TYPE_SUM_ACTION_ASC = 'EVENTS_BY_TYPE_SUM_ACTION_ASC',
  EVENTS_BY_TYPE_SUM_ACTION_DESC = 'EVENTS_BY_TYPE_SUM_ACTION_DESC',
  EVENTS_BY_TYPE_SUM_ACTOR_ASC = 'EVENTS_BY_TYPE_SUM_ACTOR_ASC',
  EVENTS_BY_TYPE_SUM_ACTOR_DESC = 'EVENTS_BY_TYPE_SUM_ACTOR_DESC',
  EVENTS_BY_TYPE_SUM_CREATED_AT_ASC = 'EVENTS_BY_TYPE_SUM_CREATED_AT_ASC',
  EVENTS_BY_TYPE_SUM_CREATED_AT_DESC = 'EVENTS_BY_TYPE_SUM_CREATED_AT_DESC',
  EVENTS_BY_TYPE_SUM_EVENT_ASC = 'EVENTS_BY_TYPE_SUM_EVENT_ASC',
  EVENTS_BY_TYPE_SUM_EVENT_DESC = 'EVENTS_BY_TYPE_SUM_EVENT_DESC',
  EVENTS_BY_TYPE_SUM_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_SUM_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_SUM_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_SUM_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_SUM_ID_ASC = 'EVENTS_BY_TYPE_SUM_ID_ASC',
  EVENTS_BY_TYPE_SUM_ID_DESC = 'EVENTS_BY_TYPE_SUM_ID_DESC',
  EVENTS_BY_TYPE_SUM_TYPE_ASC = 'EVENTS_BY_TYPE_SUM_TYPE_ASC',
  EVENTS_BY_TYPE_SUM_TYPE_DESC = 'EVENTS_BY_TYPE_SUM_TYPE_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTOR_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTOR_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTOR_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ACTOR_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_EVENT_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_EVENT_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_EVENT_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_EVENT_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ID_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ID_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_ID_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_ID_DESC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC',
  EVENTS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC = 'EVENTS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTOR_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTOR_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTOR_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ACTOR_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_EVENT_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_EVENT_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_EVENT_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_EVENT_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ID_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ID_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_ID_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_ID_DESC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC',
  EVENTS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC = 'EVENTS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_AVERAGE_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_COUNT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_COUNT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_COUNT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_COUNT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_COUNT_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_DISTINCT_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MAX_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MAX_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_MIN_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_MIN_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_POPULATION_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_SUM_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_SUM_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_EVENT_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_EVENT_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_EVENT_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_EVENT_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_HANDLER_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_HANDLER_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_HANDLER_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_HANDLER_DESC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC',
  EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC = 'EVENT_HANDLER_METADATA_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_AVERAGE_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_AVERAGE_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_COUNT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_COUNT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_COUNT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_COUNT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_COUNT_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_DISTINCT_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_DISTINCT_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MAX_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MAX_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_MIN_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_MIN_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_POPULATION_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_STDDEV_SAMPLE_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_SUM_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_SUM_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_POPULATION_VALIDATED_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_ID_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_ID_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_ID_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_ID_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_OWNER_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_URI_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_URI_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_URI_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_URI_DESC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_VALIDATED_ASC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_VALIDATED_ASC',
  EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_VALIDATED_DESC = 'EVENT_WEBHOOKS_BY_TYPE_VARIANCE_SAMPLE_VALIDATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_AVERAGE_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_COUNT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_COUNT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_COUNT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_COUNT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_COUNT_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_DISTINCT_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MAX_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_MIN_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_POPULATION_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_STDDEV_SAMPLE_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_SUM_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_POPULATION_USER_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ACTION_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ACTION_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CHANNELS_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CHANNELS_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CHANNELS_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CHANNELS_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_SCOPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_SCOPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_SCOPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_SCOPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_TYPE_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_ID_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_ID_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_ID_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_ID_DESC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_TYPE_ASC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_TYPE_ASC',
  USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_TYPE_DESC = 'USER_SUBSCRIPTIONS_BY_TYPE_VARIANCE_SAMPLE_USER_TYPE_DESC'
}

export type EventVariancePopulationAggregates = {
  __typename?: 'EventVariancePopulationAggregates';
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type EventVarianceSampleAggregates = {
  __typename?: 'EventVarianceSampleAggregates';
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Event` values. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EventAggregates>;
  /** A list of edges which contains the `Event` and cursor to aid in pagination. */
  edges: Array<EventsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EventAggregates>>;
  /** A list of `Event` objects. */
  nodes: Array<Event>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Event` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Event` values. */
export type EventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EventsGroupBy>;
  having?: InputMaybe<EventsHavingInput>;
};

/** A `Event` edge in the connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Event` at the end of the edge. */
  node: Event;
};

/** Grouping methods for `Event` for usage during aggregation. */
export enum EventsGroupBy {
  ACTION = 'ACTION',
  ACTION_DISTINCT = 'ACTION_DISTINCT',
  ACTOR = 'ACTOR',
  ACTOR_DISTINCT = 'ACTOR_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  EVENT = 'EVENT',
  EVENT_DISTINCT = 'EVENT_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  TYPE = 'TYPE',
  TYPE_DISTINCT = 'TYPE_DISTINCT'
}

export type EventsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `Event` aggregates. */
export type EventsHavingInput = {
  AND?: InputMaybe<Array<EventsHavingInput>>;
  OR?: InputMaybe<Array<EventsHavingInput>>;
  average?: InputMaybe<EventsHavingAverageInput>;
  distinct?: InputMaybe<EventsHavingDistinctInput>;
  distinctCount?: InputMaybe<EventsHavingDistinctCountInput>;
  max?: InputMaybe<EventsHavingMaxInput>;
  min?: InputMaybe<EventsHavingMinInput>;
  stddevPopulation?: InputMaybe<EventsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EventsHavingStddevSampleInput>;
  sum?: InputMaybe<EventsHavingSumInput>;
  variancePopulation?: InputMaybe<EventsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EventsHavingVarianceSampleInput>;
};

export type EventsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type EventsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `Event`. */
export enum EventsOrderBy {
  ACTION_ASC = 'ACTION_ASC',
  ACTION_DESC = 'ACTION_DESC',
  ACTOR_ASC = 'ACTOR_ASC',
  ACTOR_DESC = 'ACTOR_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  EVENT_ASC = 'EVENT_ASC',
  EVENT_DESC = 'EVENT_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC'
}

/** A connection to a list of `Facility` values. */
export type FacilitiesConnection = {
  __typename?: 'FacilitiesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityAggregates>;
  /** A list of edges which contains the `Facility` and cursor to aid in pagination. */
  edges: Array<FacilitiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityAggregates>>;
  /** Aggregates a metric by label for the specified filter expression. */
  metricData?: Maybe<MetricDataConnection>;
  /** A list of `Facility` objects. */
  nodes: Array<Facility>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Facility` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Facility` values. */
export type FacilitiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FacilitiesGroupBy>;
  having?: InputMaybe<FacilitiesHavingInput>;
};

/** A connection to a list of `Facility` values. */
export type FacilitiesConnectionMetricDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  aggregation?: MetricDataAggregationMethod;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  samplingWindow?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  unitGroup?: InputMaybe<Scalars['String']>;
  window?: Scalars['String'];
};

/** A `Facility` edge in the connection. */
export type FacilitiesEdge = {
  __typename?: 'FacilitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Facility` at the end of the edge. */
  node: Facility;
};

/** Grouping methods for `Facility` for usage during aggregation. */
export enum FacilitiesGroupBy {
  ADDRESS = 'ADDRESS',
  ADDRESS_DISTINCT = 'ADDRESS_DISTINCT',
  BONUS_LTR = 'BONUS_LTR',
  BONUS_LTR_DISTINCT = 'BONUS_LTR_DISTINCT',
  CITY = 'CITY',
  CITY_DISTINCT = 'CITY_DISTINCT',
  CONFIG = 'CONFIG',
  CONFIG_DISTINCT = 'CONFIG_DISTINCT',
  COUNTRY = 'COUNTRY',
  COUNTRY_DISTINCT = 'COUNTRY_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  FACILITY_CONTACTS = 'FACILITY_CONTACTS',
  FACILITY_CONTACTS_DISTINCT = 'FACILITY_CONTACTS_DISTINCT',
  LATITUDE = 'LATITUDE',
  LATITUDE_DISTINCT = 'LATITUDE_DISTINCT',
  LINK_ACTIVE = 'LINK_ACTIVE',
  LINK_ACTIVE_DISTINCT = 'LINK_ACTIVE_DISTINCT',
  LONGITUDE = 'LONGITUDE',
  LONGITUDE_DISTINCT = 'LONGITUDE_DISTINCT',
  METRICS_ONE_ACTIVE = 'METRICS_ONE_ACTIVE',
  METRICS_ONE_ACTIVE_DISTINCT = 'METRICS_ONE_ACTIVE_DISTINCT',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  NOTE = 'NOTE',
  NOTE_DISTINCT = 'NOTE_DISTINCT',
  N_SIGHT_2_ACTIVE = 'N_SIGHT_2_ACTIVE',
  N_SIGHT_2_ACTIVE_DISTINCT = 'N_SIGHT_2_ACTIVE_DISTINCT',
  N_SIGHT_ACTIVE = 'N_SIGHT_ACTIVE',
  N_SIGHT_ACTIVE_DISTINCT = 'N_SIGHT_ACTIVE_DISTINCT',
  PRE_BONUS_EBITDA = 'PRE_BONUS_EBITDA',
  PRE_BONUS_EBITDA_DISTINCT = 'PRE_BONUS_EBITDA_DISTINCT',
  REAL_TIME_ENABLED = 'REAL_TIME_ENABLED',
  REAL_TIME_ENABLED_DISTINCT = 'REAL_TIME_ENABLED_DISTINCT',
  STATE = 'STATE',
  STATE_DISTINCT = 'STATE_DISTINCT',
  SUPPRESSE_FINANCIALS = 'SUPPRESSE_FINANCIALS',
  SUPPRESSE_FINANCIALS_DISTINCT = 'SUPPRESSE_FINANCIALS_DISTINCT',
  TIMEZONE_NAME = 'TIMEZONE_NAME',
  TIMEZONE_NAME_DISTINCT = 'TIMEZONE_NAME_DISTINCT',
  UNIT_GROUP = 'UNIT_GROUP',
  UNIT_GROUP_DISTINCT = 'UNIT_GROUP_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  ZIP = 'ZIP',
  ZIP_DISTINCT = 'ZIP_DISTINCT'
}

export type FacilitiesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Facility` aggregates. */
export type FacilitiesHavingInput = {
  AND?: InputMaybe<Array<FacilitiesHavingInput>>;
  OR?: InputMaybe<Array<FacilitiesHavingInput>>;
  average?: InputMaybe<FacilitiesHavingAverageInput>;
  distinct?: InputMaybe<FacilitiesHavingDistinctInput>;
  distinctCount?: InputMaybe<FacilitiesHavingDistinctCountInput>;
  max?: InputMaybe<FacilitiesHavingMaxInput>;
  min?: InputMaybe<FacilitiesHavingMinInput>;
  stddevPopulation?: InputMaybe<FacilitiesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FacilitiesHavingStddevSampleInput>;
  sum?: InputMaybe<FacilitiesHavingSumInput>;
  variancePopulation?: InputMaybe<FacilitiesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FacilitiesHavingVarianceSampleInput>;
};

export type FacilitiesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FacilitiesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  latitude?: InputMaybe<HavingFloatFilter>;
  longitude?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Facility`. */
export enum FacilitiesOrderBy {
  ADDRESS_ASC = 'ADDRESS_ASC',
  ADDRESS_DESC = 'ADDRESS_DESC',
  BONUS_LTR_ASC = 'BONUS_LTR_ASC',
  BONUS_LTR_DESC = 'BONUS_LTR_DESC',
  CITY_ASC = 'CITY_ASC',
  CITY_DESC = 'CITY_DESC',
  COMPONENTS_AVERAGE_CREATED_AT_ASC = 'COMPONENTS_AVERAGE_CREATED_AT_ASC',
  COMPONENTS_AVERAGE_CREATED_AT_DESC = 'COMPONENTS_AVERAGE_CREATED_AT_DESC',
  COMPONENTS_AVERAGE_FACILITY_ID_ASC = 'COMPONENTS_AVERAGE_FACILITY_ID_ASC',
  COMPONENTS_AVERAGE_FACILITY_ID_DESC = 'COMPONENTS_AVERAGE_FACILITY_ID_DESC',
  COMPONENTS_AVERAGE_ID_ASC = 'COMPONENTS_AVERAGE_ID_ASC',
  COMPONENTS_AVERAGE_ID_DESC = 'COMPONENTS_AVERAGE_ID_DESC',
  COMPONENTS_AVERAGE_MACHINE_USER_ID_ASC = 'COMPONENTS_AVERAGE_MACHINE_USER_ID_ASC',
  COMPONENTS_AVERAGE_MACHINE_USER_ID_DESC = 'COMPONENTS_AVERAGE_MACHINE_USER_ID_DESC',
  COMPONENTS_AVERAGE_NAME_ASC = 'COMPONENTS_AVERAGE_NAME_ASC',
  COMPONENTS_AVERAGE_NAME_DESC = 'COMPONENTS_AVERAGE_NAME_DESC',
  COMPONENTS_AVERAGE_OUT_OF_SERVICE_ASC = 'COMPONENTS_AVERAGE_OUT_OF_SERVICE_ASC',
  COMPONENTS_AVERAGE_OUT_OF_SERVICE_DESC = 'COMPONENTS_AVERAGE_OUT_OF_SERVICE_DESC',
  COMPONENTS_AVERAGE_UPDATED_AT_ASC = 'COMPONENTS_AVERAGE_UPDATED_AT_ASC',
  COMPONENTS_AVERAGE_UPDATED_AT_DESC = 'COMPONENTS_AVERAGE_UPDATED_AT_DESC',
  COMPONENTS_COUNT_ASC = 'COMPONENTS_COUNT_ASC',
  COMPONENTS_COUNT_DESC = 'COMPONENTS_COUNT_DESC',
  COMPONENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'COMPONENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  COMPONENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'COMPONENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  COMPONENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_NAME_ASC = 'COMPONENTS_DISTINCT_COUNT_NAME_ASC',
  COMPONENTS_DISTINCT_COUNT_NAME_DESC = 'COMPONENTS_DISTINCT_COUNT_NAME_DESC',
  COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_ASC = 'COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_ASC',
  COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_DESC = 'COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_DESC',
  COMPONENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'COMPONENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  COMPONENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'COMPONENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  COMPONENTS_DISTINCT_CREATED_AT_ASC = 'COMPONENTS_DISTINCT_CREATED_AT_ASC',
  COMPONENTS_DISTINCT_CREATED_AT_DESC = 'COMPONENTS_DISTINCT_CREATED_AT_DESC',
  COMPONENTS_DISTINCT_FACILITY_ID_ASC = 'COMPONENTS_DISTINCT_FACILITY_ID_ASC',
  COMPONENTS_DISTINCT_FACILITY_ID_DESC = 'COMPONENTS_DISTINCT_FACILITY_ID_DESC',
  COMPONENTS_DISTINCT_ID_ASC = 'COMPONENTS_DISTINCT_ID_ASC',
  COMPONENTS_DISTINCT_ID_DESC = 'COMPONENTS_DISTINCT_ID_DESC',
  COMPONENTS_DISTINCT_MACHINE_USER_ID_ASC = 'COMPONENTS_DISTINCT_MACHINE_USER_ID_ASC',
  COMPONENTS_DISTINCT_MACHINE_USER_ID_DESC = 'COMPONENTS_DISTINCT_MACHINE_USER_ID_DESC',
  COMPONENTS_DISTINCT_NAME_ASC = 'COMPONENTS_DISTINCT_NAME_ASC',
  COMPONENTS_DISTINCT_NAME_DESC = 'COMPONENTS_DISTINCT_NAME_DESC',
  COMPONENTS_DISTINCT_OUT_OF_SERVICE_ASC = 'COMPONENTS_DISTINCT_OUT_OF_SERVICE_ASC',
  COMPONENTS_DISTINCT_OUT_OF_SERVICE_DESC = 'COMPONENTS_DISTINCT_OUT_OF_SERVICE_DESC',
  COMPONENTS_DISTINCT_UPDATED_AT_ASC = 'COMPONENTS_DISTINCT_UPDATED_AT_ASC',
  COMPONENTS_DISTINCT_UPDATED_AT_DESC = 'COMPONENTS_DISTINCT_UPDATED_AT_DESC',
  COMPONENTS_MAX_CREATED_AT_ASC = 'COMPONENTS_MAX_CREATED_AT_ASC',
  COMPONENTS_MAX_CREATED_AT_DESC = 'COMPONENTS_MAX_CREATED_AT_DESC',
  COMPONENTS_MAX_FACILITY_ID_ASC = 'COMPONENTS_MAX_FACILITY_ID_ASC',
  COMPONENTS_MAX_FACILITY_ID_DESC = 'COMPONENTS_MAX_FACILITY_ID_DESC',
  COMPONENTS_MAX_ID_ASC = 'COMPONENTS_MAX_ID_ASC',
  COMPONENTS_MAX_ID_DESC = 'COMPONENTS_MAX_ID_DESC',
  COMPONENTS_MAX_MACHINE_USER_ID_ASC = 'COMPONENTS_MAX_MACHINE_USER_ID_ASC',
  COMPONENTS_MAX_MACHINE_USER_ID_DESC = 'COMPONENTS_MAX_MACHINE_USER_ID_DESC',
  COMPONENTS_MAX_NAME_ASC = 'COMPONENTS_MAX_NAME_ASC',
  COMPONENTS_MAX_NAME_DESC = 'COMPONENTS_MAX_NAME_DESC',
  COMPONENTS_MAX_OUT_OF_SERVICE_ASC = 'COMPONENTS_MAX_OUT_OF_SERVICE_ASC',
  COMPONENTS_MAX_OUT_OF_SERVICE_DESC = 'COMPONENTS_MAX_OUT_OF_SERVICE_DESC',
  COMPONENTS_MAX_UPDATED_AT_ASC = 'COMPONENTS_MAX_UPDATED_AT_ASC',
  COMPONENTS_MAX_UPDATED_AT_DESC = 'COMPONENTS_MAX_UPDATED_AT_DESC',
  COMPONENTS_MIN_CREATED_AT_ASC = 'COMPONENTS_MIN_CREATED_AT_ASC',
  COMPONENTS_MIN_CREATED_AT_DESC = 'COMPONENTS_MIN_CREATED_AT_DESC',
  COMPONENTS_MIN_FACILITY_ID_ASC = 'COMPONENTS_MIN_FACILITY_ID_ASC',
  COMPONENTS_MIN_FACILITY_ID_DESC = 'COMPONENTS_MIN_FACILITY_ID_DESC',
  COMPONENTS_MIN_ID_ASC = 'COMPONENTS_MIN_ID_ASC',
  COMPONENTS_MIN_ID_DESC = 'COMPONENTS_MIN_ID_DESC',
  COMPONENTS_MIN_MACHINE_USER_ID_ASC = 'COMPONENTS_MIN_MACHINE_USER_ID_ASC',
  COMPONENTS_MIN_MACHINE_USER_ID_DESC = 'COMPONENTS_MIN_MACHINE_USER_ID_DESC',
  COMPONENTS_MIN_NAME_ASC = 'COMPONENTS_MIN_NAME_ASC',
  COMPONENTS_MIN_NAME_DESC = 'COMPONENTS_MIN_NAME_DESC',
  COMPONENTS_MIN_OUT_OF_SERVICE_ASC = 'COMPONENTS_MIN_OUT_OF_SERVICE_ASC',
  COMPONENTS_MIN_OUT_OF_SERVICE_DESC = 'COMPONENTS_MIN_OUT_OF_SERVICE_DESC',
  COMPONENTS_MIN_UPDATED_AT_ASC = 'COMPONENTS_MIN_UPDATED_AT_ASC',
  COMPONENTS_MIN_UPDATED_AT_DESC = 'COMPONENTS_MIN_UPDATED_AT_DESC',
  COMPONENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'COMPONENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  COMPONENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'COMPONENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  COMPONENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_NAME_ASC = 'COMPONENTS_STDDEV_POPULATION_NAME_ASC',
  COMPONENTS_STDDEV_POPULATION_NAME_DESC = 'COMPONENTS_STDDEV_POPULATION_NAME_DESC',
  COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_ASC = 'COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_ASC',
  COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_DESC = 'COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_DESC',
  COMPONENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'COMPONENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  COMPONENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'COMPONENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  COMPONENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'COMPONENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  COMPONENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'COMPONENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_NAME_ASC = 'COMPONENTS_STDDEV_SAMPLE_NAME_ASC',
  COMPONENTS_STDDEV_SAMPLE_NAME_DESC = 'COMPONENTS_STDDEV_SAMPLE_NAME_DESC',
  COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_ASC = 'COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_ASC',
  COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_DESC = 'COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_DESC',
  COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  COMPONENTS_SUM_CREATED_AT_ASC = 'COMPONENTS_SUM_CREATED_AT_ASC',
  COMPONENTS_SUM_CREATED_AT_DESC = 'COMPONENTS_SUM_CREATED_AT_DESC',
  COMPONENTS_SUM_FACILITY_ID_ASC = 'COMPONENTS_SUM_FACILITY_ID_ASC',
  COMPONENTS_SUM_FACILITY_ID_DESC = 'COMPONENTS_SUM_FACILITY_ID_DESC',
  COMPONENTS_SUM_ID_ASC = 'COMPONENTS_SUM_ID_ASC',
  COMPONENTS_SUM_ID_DESC = 'COMPONENTS_SUM_ID_DESC',
  COMPONENTS_SUM_MACHINE_USER_ID_ASC = 'COMPONENTS_SUM_MACHINE_USER_ID_ASC',
  COMPONENTS_SUM_MACHINE_USER_ID_DESC = 'COMPONENTS_SUM_MACHINE_USER_ID_DESC',
  COMPONENTS_SUM_NAME_ASC = 'COMPONENTS_SUM_NAME_ASC',
  COMPONENTS_SUM_NAME_DESC = 'COMPONENTS_SUM_NAME_DESC',
  COMPONENTS_SUM_OUT_OF_SERVICE_ASC = 'COMPONENTS_SUM_OUT_OF_SERVICE_ASC',
  COMPONENTS_SUM_OUT_OF_SERVICE_DESC = 'COMPONENTS_SUM_OUT_OF_SERVICE_DESC',
  COMPONENTS_SUM_UPDATED_AT_ASC = 'COMPONENTS_SUM_UPDATED_AT_ASC',
  COMPONENTS_SUM_UPDATED_AT_DESC = 'COMPONENTS_SUM_UPDATED_AT_DESC',
  COMPONENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'COMPONENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  COMPONENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'COMPONENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_NAME_ASC = 'COMPONENTS_VARIANCE_POPULATION_NAME_ASC',
  COMPONENTS_VARIANCE_POPULATION_NAME_DESC = 'COMPONENTS_VARIANCE_POPULATION_NAME_DESC',
  COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_ASC = 'COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_ASC',
  COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_DESC = 'COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_DESC',
  COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_NAME_ASC = 'COMPONENTS_VARIANCE_SAMPLE_NAME_ASC',
  COMPONENTS_VARIANCE_SAMPLE_NAME_DESC = 'COMPONENTS_VARIANCE_SAMPLE_NAME_DESC',
  COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_ASC = 'COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_ASC',
  COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_DESC = 'COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_DESC',
  COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CONFIG_ASC = 'CONFIG_ASC',
  CONFIG_DESC = 'CONFIG_DESC',
  COUNTRY_ASC = 'COUNTRY_ASC',
  COUNTRY_DESC = 'COUNTRY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  EVENTS_AVERAGE_ACTION_ASC = 'EVENTS_AVERAGE_ACTION_ASC',
  EVENTS_AVERAGE_ACTION_DESC = 'EVENTS_AVERAGE_ACTION_DESC',
  EVENTS_AVERAGE_ACTOR_ASC = 'EVENTS_AVERAGE_ACTOR_ASC',
  EVENTS_AVERAGE_ACTOR_DESC = 'EVENTS_AVERAGE_ACTOR_DESC',
  EVENTS_AVERAGE_CREATED_AT_ASC = 'EVENTS_AVERAGE_CREATED_AT_ASC',
  EVENTS_AVERAGE_CREATED_AT_DESC = 'EVENTS_AVERAGE_CREATED_AT_DESC',
  EVENTS_AVERAGE_EVENT_ASC = 'EVENTS_AVERAGE_EVENT_ASC',
  EVENTS_AVERAGE_EVENT_DESC = 'EVENTS_AVERAGE_EVENT_DESC',
  EVENTS_AVERAGE_FACILITY_ID_ASC = 'EVENTS_AVERAGE_FACILITY_ID_ASC',
  EVENTS_AVERAGE_FACILITY_ID_DESC = 'EVENTS_AVERAGE_FACILITY_ID_DESC',
  EVENTS_AVERAGE_ID_ASC = 'EVENTS_AVERAGE_ID_ASC',
  EVENTS_AVERAGE_ID_DESC = 'EVENTS_AVERAGE_ID_DESC',
  EVENTS_AVERAGE_TYPE_ASC = 'EVENTS_AVERAGE_TYPE_ASC',
  EVENTS_AVERAGE_TYPE_DESC = 'EVENTS_AVERAGE_TYPE_DESC',
  EVENTS_COUNT_ASC = 'EVENTS_COUNT_ASC',
  EVENTS_COUNT_DESC = 'EVENTS_COUNT_DESC',
  EVENTS_DISTINCT_ACTION_ASC = 'EVENTS_DISTINCT_ACTION_ASC',
  EVENTS_DISTINCT_ACTION_DESC = 'EVENTS_DISTINCT_ACTION_DESC',
  EVENTS_DISTINCT_ACTOR_ASC = 'EVENTS_DISTINCT_ACTOR_ASC',
  EVENTS_DISTINCT_ACTOR_DESC = 'EVENTS_DISTINCT_ACTOR_DESC',
  EVENTS_DISTINCT_COUNT_ACTION_ASC = 'EVENTS_DISTINCT_COUNT_ACTION_ASC',
  EVENTS_DISTINCT_COUNT_ACTION_DESC = 'EVENTS_DISTINCT_COUNT_ACTION_DESC',
  EVENTS_DISTINCT_COUNT_ACTOR_ASC = 'EVENTS_DISTINCT_COUNT_ACTOR_ASC',
  EVENTS_DISTINCT_COUNT_ACTOR_DESC = 'EVENTS_DISTINCT_COUNT_ACTOR_DESC',
  EVENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'EVENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  EVENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'EVENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  EVENTS_DISTINCT_COUNT_EVENT_ASC = 'EVENTS_DISTINCT_COUNT_EVENT_ASC',
  EVENTS_DISTINCT_COUNT_EVENT_DESC = 'EVENTS_DISTINCT_COUNT_EVENT_DESC',
  EVENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'EVENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  EVENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'EVENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  EVENTS_DISTINCT_COUNT_ID_ASC = 'EVENTS_DISTINCT_COUNT_ID_ASC',
  EVENTS_DISTINCT_COUNT_ID_DESC = 'EVENTS_DISTINCT_COUNT_ID_DESC',
  EVENTS_DISTINCT_COUNT_TYPE_ASC = 'EVENTS_DISTINCT_COUNT_TYPE_ASC',
  EVENTS_DISTINCT_COUNT_TYPE_DESC = 'EVENTS_DISTINCT_COUNT_TYPE_DESC',
  EVENTS_DISTINCT_CREATED_AT_ASC = 'EVENTS_DISTINCT_CREATED_AT_ASC',
  EVENTS_DISTINCT_CREATED_AT_DESC = 'EVENTS_DISTINCT_CREATED_AT_DESC',
  EVENTS_DISTINCT_EVENT_ASC = 'EVENTS_DISTINCT_EVENT_ASC',
  EVENTS_DISTINCT_EVENT_DESC = 'EVENTS_DISTINCT_EVENT_DESC',
  EVENTS_DISTINCT_FACILITY_ID_ASC = 'EVENTS_DISTINCT_FACILITY_ID_ASC',
  EVENTS_DISTINCT_FACILITY_ID_DESC = 'EVENTS_DISTINCT_FACILITY_ID_DESC',
  EVENTS_DISTINCT_ID_ASC = 'EVENTS_DISTINCT_ID_ASC',
  EVENTS_DISTINCT_ID_DESC = 'EVENTS_DISTINCT_ID_DESC',
  EVENTS_DISTINCT_TYPE_ASC = 'EVENTS_DISTINCT_TYPE_ASC',
  EVENTS_DISTINCT_TYPE_DESC = 'EVENTS_DISTINCT_TYPE_DESC',
  EVENTS_MAX_ACTION_ASC = 'EVENTS_MAX_ACTION_ASC',
  EVENTS_MAX_ACTION_DESC = 'EVENTS_MAX_ACTION_DESC',
  EVENTS_MAX_ACTOR_ASC = 'EVENTS_MAX_ACTOR_ASC',
  EVENTS_MAX_ACTOR_DESC = 'EVENTS_MAX_ACTOR_DESC',
  EVENTS_MAX_CREATED_AT_ASC = 'EVENTS_MAX_CREATED_AT_ASC',
  EVENTS_MAX_CREATED_AT_DESC = 'EVENTS_MAX_CREATED_AT_DESC',
  EVENTS_MAX_EVENT_ASC = 'EVENTS_MAX_EVENT_ASC',
  EVENTS_MAX_EVENT_DESC = 'EVENTS_MAX_EVENT_DESC',
  EVENTS_MAX_FACILITY_ID_ASC = 'EVENTS_MAX_FACILITY_ID_ASC',
  EVENTS_MAX_FACILITY_ID_DESC = 'EVENTS_MAX_FACILITY_ID_DESC',
  EVENTS_MAX_ID_ASC = 'EVENTS_MAX_ID_ASC',
  EVENTS_MAX_ID_DESC = 'EVENTS_MAX_ID_DESC',
  EVENTS_MAX_TYPE_ASC = 'EVENTS_MAX_TYPE_ASC',
  EVENTS_MAX_TYPE_DESC = 'EVENTS_MAX_TYPE_DESC',
  EVENTS_MIN_ACTION_ASC = 'EVENTS_MIN_ACTION_ASC',
  EVENTS_MIN_ACTION_DESC = 'EVENTS_MIN_ACTION_DESC',
  EVENTS_MIN_ACTOR_ASC = 'EVENTS_MIN_ACTOR_ASC',
  EVENTS_MIN_ACTOR_DESC = 'EVENTS_MIN_ACTOR_DESC',
  EVENTS_MIN_CREATED_AT_ASC = 'EVENTS_MIN_CREATED_AT_ASC',
  EVENTS_MIN_CREATED_AT_DESC = 'EVENTS_MIN_CREATED_AT_DESC',
  EVENTS_MIN_EVENT_ASC = 'EVENTS_MIN_EVENT_ASC',
  EVENTS_MIN_EVENT_DESC = 'EVENTS_MIN_EVENT_DESC',
  EVENTS_MIN_FACILITY_ID_ASC = 'EVENTS_MIN_FACILITY_ID_ASC',
  EVENTS_MIN_FACILITY_ID_DESC = 'EVENTS_MIN_FACILITY_ID_DESC',
  EVENTS_MIN_ID_ASC = 'EVENTS_MIN_ID_ASC',
  EVENTS_MIN_ID_DESC = 'EVENTS_MIN_ID_DESC',
  EVENTS_MIN_TYPE_ASC = 'EVENTS_MIN_TYPE_ASC',
  EVENTS_MIN_TYPE_DESC = 'EVENTS_MIN_TYPE_DESC',
  EVENTS_STDDEV_POPULATION_ACTION_ASC = 'EVENTS_STDDEV_POPULATION_ACTION_ASC',
  EVENTS_STDDEV_POPULATION_ACTION_DESC = 'EVENTS_STDDEV_POPULATION_ACTION_DESC',
  EVENTS_STDDEV_POPULATION_ACTOR_ASC = 'EVENTS_STDDEV_POPULATION_ACTOR_ASC',
  EVENTS_STDDEV_POPULATION_ACTOR_DESC = 'EVENTS_STDDEV_POPULATION_ACTOR_DESC',
  EVENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'EVENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  EVENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'EVENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  EVENTS_STDDEV_POPULATION_EVENT_ASC = 'EVENTS_STDDEV_POPULATION_EVENT_ASC',
  EVENTS_STDDEV_POPULATION_EVENT_DESC = 'EVENTS_STDDEV_POPULATION_EVENT_DESC',
  EVENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'EVENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  EVENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'EVENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  EVENTS_STDDEV_POPULATION_ID_ASC = 'EVENTS_STDDEV_POPULATION_ID_ASC',
  EVENTS_STDDEV_POPULATION_ID_DESC = 'EVENTS_STDDEV_POPULATION_ID_DESC',
  EVENTS_STDDEV_POPULATION_TYPE_ASC = 'EVENTS_STDDEV_POPULATION_TYPE_ASC',
  EVENTS_STDDEV_POPULATION_TYPE_DESC = 'EVENTS_STDDEV_POPULATION_TYPE_DESC',
  EVENTS_STDDEV_SAMPLE_ACTION_ASC = 'EVENTS_STDDEV_SAMPLE_ACTION_ASC',
  EVENTS_STDDEV_SAMPLE_ACTION_DESC = 'EVENTS_STDDEV_SAMPLE_ACTION_DESC',
  EVENTS_STDDEV_SAMPLE_ACTOR_ASC = 'EVENTS_STDDEV_SAMPLE_ACTOR_ASC',
  EVENTS_STDDEV_SAMPLE_ACTOR_DESC = 'EVENTS_STDDEV_SAMPLE_ACTOR_DESC',
  EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'EVENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  EVENTS_STDDEV_SAMPLE_EVENT_ASC = 'EVENTS_STDDEV_SAMPLE_EVENT_ASC',
  EVENTS_STDDEV_SAMPLE_EVENT_DESC = 'EVENTS_STDDEV_SAMPLE_EVENT_DESC',
  EVENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'EVENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  EVENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'EVENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  EVENTS_STDDEV_SAMPLE_ID_ASC = 'EVENTS_STDDEV_SAMPLE_ID_ASC',
  EVENTS_STDDEV_SAMPLE_ID_DESC = 'EVENTS_STDDEV_SAMPLE_ID_DESC',
  EVENTS_STDDEV_SAMPLE_TYPE_ASC = 'EVENTS_STDDEV_SAMPLE_TYPE_ASC',
  EVENTS_STDDEV_SAMPLE_TYPE_DESC = 'EVENTS_STDDEV_SAMPLE_TYPE_DESC',
  EVENTS_SUM_ACTION_ASC = 'EVENTS_SUM_ACTION_ASC',
  EVENTS_SUM_ACTION_DESC = 'EVENTS_SUM_ACTION_DESC',
  EVENTS_SUM_ACTOR_ASC = 'EVENTS_SUM_ACTOR_ASC',
  EVENTS_SUM_ACTOR_DESC = 'EVENTS_SUM_ACTOR_DESC',
  EVENTS_SUM_CREATED_AT_ASC = 'EVENTS_SUM_CREATED_AT_ASC',
  EVENTS_SUM_CREATED_AT_DESC = 'EVENTS_SUM_CREATED_AT_DESC',
  EVENTS_SUM_EVENT_ASC = 'EVENTS_SUM_EVENT_ASC',
  EVENTS_SUM_EVENT_DESC = 'EVENTS_SUM_EVENT_DESC',
  EVENTS_SUM_FACILITY_ID_ASC = 'EVENTS_SUM_FACILITY_ID_ASC',
  EVENTS_SUM_FACILITY_ID_DESC = 'EVENTS_SUM_FACILITY_ID_DESC',
  EVENTS_SUM_ID_ASC = 'EVENTS_SUM_ID_ASC',
  EVENTS_SUM_ID_DESC = 'EVENTS_SUM_ID_DESC',
  EVENTS_SUM_TYPE_ASC = 'EVENTS_SUM_TYPE_ASC',
  EVENTS_SUM_TYPE_DESC = 'EVENTS_SUM_TYPE_DESC',
  EVENTS_VARIANCE_POPULATION_ACTION_ASC = 'EVENTS_VARIANCE_POPULATION_ACTION_ASC',
  EVENTS_VARIANCE_POPULATION_ACTION_DESC = 'EVENTS_VARIANCE_POPULATION_ACTION_DESC',
  EVENTS_VARIANCE_POPULATION_ACTOR_ASC = 'EVENTS_VARIANCE_POPULATION_ACTOR_ASC',
  EVENTS_VARIANCE_POPULATION_ACTOR_DESC = 'EVENTS_VARIANCE_POPULATION_ACTOR_DESC',
  EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'EVENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  EVENTS_VARIANCE_POPULATION_EVENT_ASC = 'EVENTS_VARIANCE_POPULATION_EVENT_ASC',
  EVENTS_VARIANCE_POPULATION_EVENT_DESC = 'EVENTS_VARIANCE_POPULATION_EVENT_DESC',
  EVENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'EVENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  EVENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'EVENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  EVENTS_VARIANCE_POPULATION_ID_ASC = 'EVENTS_VARIANCE_POPULATION_ID_ASC',
  EVENTS_VARIANCE_POPULATION_ID_DESC = 'EVENTS_VARIANCE_POPULATION_ID_DESC',
  EVENTS_VARIANCE_POPULATION_TYPE_ASC = 'EVENTS_VARIANCE_POPULATION_TYPE_ASC',
  EVENTS_VARIANCE_POPULATION_TYPE_DESC = 'EVENTS_VARIANCE_POPULATION_TYPE_DESC',
  EVENTS_VARIANCE_SAMPLE_ACTION_ASC = 'EVENTS_VARIANCE_SAMPLE_ACTION_ASC',
  EVENTS_VARIANCE_SAMPLE_ACTION_DESC = 'EVENTS_VARIANCE_SAMPLE_ACTION_DESC',
  EVENTS_VARIANCE_SAMPLE_ACTOR_ASC = 'EVENTS_VARIANCE_SAMPLE_ACTOR_ASC',
  EVENTS_VARIANCE_SAMPLE_ACTOR_DESC = 'EVENTS_VARIANCE_SAMPLE_ACTOR_DESC',
  EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'EVENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EVENTS_VARIANCE_SAMPLE_EVENT_ASC = 'EVENTS_VARIANCE_SAMPLE_EVENT_ASC',
  EVENTS_VARIANCE_SAMPLE_EVENT_DESC = 'EVENTS_VARIANCE_SAMPLE_EVENT_DESC',
  EVENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'EVENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  EVENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'EVENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  EVENTS_VARIANCE_SAMPLE_ID_ASC = 'EVENTS_VARIANCE_SAMPLE_ID_ASC',
  EVENTS_VARIANCE_SAMPLE_ID_DESC = 'EVENTS_VARIANCE_SAMPLE_ID_DESC',
  EVENTS_VARIANCE_SAMPLE_TYPE_ASC = 'EVENTS_VARIANCE_SAMPLE_TYPE_ASC',
  EVENTS_VARIANCE_SAMPLE_TYPE_DESC = 'EVENTS_VARIANCE_SAMPLE_TYPE_DESC',
  FACILITY_CONTACTS_ASC = 'FACILITY_CONTACTS_ASC',
  FACILITY_CONTACTS_DESC = 'FACILITY_CONTACTS_DESC',
  FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_AVERAGE_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_COUNT_ASC = 'FACILITY_GROUPS_LINKERS_COUNT_ASC',
  FACILITY_GROUPS_LINKERS_COUNT_DESC = 'FACILITY_GROUPS_LINKERS_COUNT_DESC',
  FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_DISTINCT_COUNT_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_DISTINCT_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_MAX_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_MAX_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_MAX_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_MAX_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_MAX_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_MAX_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_MAX_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_MAX_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_MIN_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_MIN_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_MIN_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_MIN_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_MIN_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_MIN_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_MIN_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_MIN_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_STDDEV_POPULATION_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_SUM_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_SUM_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_SUM_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_SUM_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_SUM_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_SUM_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_SUM_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_SUM_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  LATITUDE_ASC = 'LATITUDE_ASC',
  LATITUDE_DESC = 'LATITUDE_DESC',
  LINK_ACTIVE_ASC = 'LINK_ACTIVE_ASC',
  LINK_ACTIVE_DESC = 'LINK_ACTIVE_DESC',
  LONGITUDE_ASC = 'LONGITUDE_ASC',
  LONGITUDE_DESC = 'LONGITUDE_DESC',
  METRICS_ONE_ACTIVE_ASC = 'METRICS_ONE_ACTIVE_ASC',
  METRICS_ONE_ACTIVE_DESC = 'METRICS_ONE_ACTIVE_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  NOTE_ASC = 'NOTE_ASC',
  NOTE_DESC = 'NOTE_DESC',
  N_SIGHT_2_ACTIVE_ASC = 'N_SIGHT_2_ACTIVE_ASC',
  N_SIGHT_2_ACTIVE_DESC = 'N_SIGHT_2_ACTIVE_DESC',
  N_SIGHT_ACTIVE_ASC = 'N_SIGHT_ACTIVE_ASC',
  N_SIGHT_ACTIVE_DESC = 'N_SIGHT_ACTIVE_DESC',
  PRE_BONUS_EBITDA_ASC = 'PRE_BONUS_EBITDA_ASC',
  PRE_BONUS_EBITDA_DESC = 'PRE_BONUS_EBITDA_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_AVERAGE_CONFIG_ASC = 'PROJECTS_AVERAGE_CONFIG_ASC',
  PROJECTS_AVERAGE_CONFIG_DESC = 'PROJECTS_AVERAGE_CONFIG_DESC',
  PROJECTS_AVERAGE_CREATED_AT_ASC = 'PROJECTS_AVERAGE_CREATED_AT_ASC',
  PROJECTS_AVERAGE_CREATED_AT_DESC = 'PROJECTS_AVERAGE_CREATED_AT_DESC',
  PROJECTS_AVERAGE_DESCRIPTION_ASC = 'PROJECTS_AVERAGE_DESCRIPTION_ASC',
  PROJECTS_AVERAGE_DESCRIPTION_DESC = 'PROJECTS_AVERAGE_DESCRIPTION_DESC',
  PROJECTS_AVERAGE_END_DATE_ASC = 'PROJECTS_AVERAGE_END_DATE_ASC',
  PROJECTS_AVERAGE_END_DATE_DESC = 'PROJECTS_AVERAGE_END_DATE_DESC',
  PROJECTS_AVERAGE_FACILITY_ID_ASC = 'PROJECTS_AVERAGE_FACILITY_ID_ASC',
  PROJECTS_AVERAGE_FACILITY_ID_DESC = 'PROJECTS_AVERAGE_FACILITY_ID_DESC',
  PROJECTS_AVERAGE_ID_ASC = 'PROJECTS_AVERAGE_ID_ASC',
  PROJECTS_AVERAGE_ID_DESC = 'PROJECTS_AVERAGE_ID_DESC',
  PROJECTS_AVERAGE_NAME_ASC = 'PROJECTS_AVERAGE_NAME_ASC',
  PROJECTS_AVERAGE_NAME_DESC = 'PROJECTS_AVERAGE_NAME_DESC',
  PROJECTS_AVERAGE_PROJECT_TYPE_ID_ASC = 'PROJECTS_AVERAGE_PROJECT_TYPE_ID_ASC',
  PROJECTS_AVERAGE_PROJECT_TYPE_ID_DESC = 'PROJECTS_AVERAGE_PROJECT_TYPE_ID_DESC',
  PROJECTS_AVERAGE_START_DATE_ASC = 'PROJECTS_AVERAGE_START_DATE_ASC',
  PROJECTS_AVERAGE_START_DATE_DESC = 'PROJECTS_AVERAGE_START_DATE_DESC',
  PROJECTS_AVERAGE_UPDATED_AT_ASC = 'PROJECTS_AVERAGE_UPDATED_AT_ASC',
  PROJECTS_AVERAGE_UPDATED_AT_DESC = 'PROJECTS_AVERAGE_UPDATED_AT_DESC',
  PROJECTS_COUNT_ASC = 'PROJECTS_COUNT_ASC',
  PROJECTS_COUNT_DESC = 'PROJECTS_COUNT_DESC',
  PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_DISTINCT_CONFIG_ASC = 'PROJECTS_DISTINCT_CONFIG_ASC',
  PROJECTS_DISTINCT_CONFIG_DESC = 'PROJECTS_DISTINCT_CONFIG_DESC',
  PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_DISTINCT_COUNT_CONFIG_ASC = 'PROJECTS_DISTINCT_COUNT_CONFIG_ASC',
  PROJECTS_DISTINCT_COUNT_CONFIG_DESC = 'PROJECTS_DISTINCT_COUNT_CONFIG_DESC',
  PROJECTS_DISTINCT_COUNT_CREATED_AT_ASC = 'PROJECTS_DISTINCT_COUNT_CREATED_AT_ASC',
  PROJECTS_DISTINCT_COUNT_CREATED_AT_DESC = 'PROJECTS_DISTINCT_COUNT_CREATED_AT_DESC',
  PROJECTS_DISTINCT_COUNT_DESCRIPTION_ASC = 'PROJECTS_DISTINCT_COUNT_DESCRIPTION_ASC',
  PROJECTS_DISTINCT_COUNT_DESCRIPTION_DESC = 'PROJECTS_DISTINCT_COUNT_DESCRIPTION_DESC',
  PROJECTS_DISTINCT_COUNT_END_DATE_ASC = 'PROJECTS_DISTINCT_COUNT_END_DATE_ASC',
  PROJECTS_DISTINCT_COUNT_END_DATE_DESC = 'PROJECTS_DISTINCT_COUNT_END_DATE_DESC',
  PROJECTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'PROJECTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  PROJECTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'PROJECTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  PROJECTS_DISTINCT_COUNT_ID_ASC = 'PROJECTS_DISTINCT_COUNT_ID_ASC',
  PROJECTS_DISTINCT_COUNT_ID_DESC = 'PROJECTS_DISTINCT_COUNT_ID_DESC',
  PROJECTS_DISTINCT_COUNT_NAME_ASC = 'PROJECTS_DISTINCT_COUNT_NAME_ASC',
  PROJECTS_DISTINCT_COUNT_NAME_DESC = 'PROJECTS_DISTINCT_COUNT_NAME_DESC',
  PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_ASC = 'PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_ASC',
  PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_DESC = 'PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_DESC',
  PROJECTS_DISTINCT_COUNT_START_DATE_ASC = 'PROJECTS_DISTINCT_COUNT_START_DATE_ASC',
  PROJECTS_DISTINCT_COUNT_START_DATE_DESC = 'PROJECTS_DISTINCT_COUNT_START_DATE_DESC',
  PROJECTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'PROJECTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  PROJECTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'PROJECTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  PROJECTS_DISTINCT_CREATED_AT_ASC = 'PROJECTS_DISTINCT_CREATED_AT_ASC',
  PROJECTS_DISTINCT_CREATED_AT_DESC = 'PROJECTS_DISTINCT_CREATED_AT_DESC',
  PROJECTS_DISTINCT_DESCRIPTION_ASC = 'PROJECTS_DISTINCT_DESCRIPTION_ASC',
  PROJECTS_DISTINCT_DESCRIPTION_DESC = 'PROJECTS_DISTINCT_DESCRIPTION_DESC',
  PROJECTS_DISTINCT_END_DATE_ASC = 'PROJECTS_DISTINCT_END_DATE_ASC',
  PROJECTS_DISTINCT_END_DATE_DESC = 'PROJECTS_DISTINCT_END_DATE_DESC',
  PROJECTS_DISTINCT_FACILITY_ID_ASC = 'PROJECTS_DISTINCT_FACILITY_ID_ASC',
  PROJECTS_DISTINCT_FACILITY_ID_DESC = 'PROJECTS_DISTINCT_FACILITY_ID_DESC',
  PROJECTS_DISTINCT_ID_ASC = 'PROJECTS_DISTINCT_ID_ASC',
  PROJECTS_DISTINCT_ID_DESC = 'PROJECTS_DISTINCT_ID_DESC',
  PROJECTS_DISTINCT_NAME_ASC = 'PROJECTS_DISTINCT_NAME_ASC',
  PROJECTS_DISTINCT_NAME_DESC = 'PROJECTS_DISTINCT_NAME_DESC',
  PROJECTS_DISTINCT_PROJECT_TYPE_ID_ASC = 'PROJECTS_DISTINCT_PROJECT_TYPE_ID_ASC',
  PROJECTS_DISTINCT_PROJECT_TYPE_ID_DESC = 'PROJECTS_DISTINCT_PROJECT_TYPE_ID_DESC',
  PROJECTS_DISTINCT_START_DATE_ASC = 'PROJECTS_DISTINCT_START_DATE_ASC',
  PROJECTS_DISTINCT_START_DATE_DESC = 'PROJECTS_DISTINCT_START_DATE_DESC',
  PROJECTS_DISTINCT_UPDATED_AT_ASC = 'PROJECTS_DISTINCT_UPDATED_AT_ASC',
  PROJECTS_DISTINCT_UPDATED_AT_DESC = 'PROJECTS_DISTINCT_UPDATED_AT_DESC',
  PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_MAX_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_MAX_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_MAX_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_MAX_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_MAX_CONFIG_ASC = 'PROJECTS_MAX_CONFIG_ASC',
  PROJECTS_MAX_CONFIG_DESC = 'PROJECTS_MAX_CONFIG_DESC',
  PROJECTS_MAX_CREATED_AT_ASC = 'PROJECTS_MAX_CREATED_AT_ASC',
  PROJECTS_MAX_CREATED_AT_DESC = 'PROJECTS_MAX_CREATED_AT_DESC',
  PROJECTS_MAX_DESCRIPTION_ASC = 'PROJECTS_MAX_DESCRIPTION_ASC',
  PROJECTS_MAX_DESCRIPTION_DESC = 'PROJECTS_MAX_DESCRIPTION_DESC',
  PROJECTS_MAX_END_DATE_ASC = 'PROJECTS_MAX_END_DATE_ASC',
  PROJECTS_MAX_END_DATE_DESC = 'PROJECTS_MAX_END_DATE_DESC',
  PROJECTS_MAX_FACILITY_ID_ASC = 'PROJECTS_MAX_FACILITY_ID_ASC',
  PROJECTS_MAX_FACILITY_ID_DESC = 'PROJECTS_MAX_FACILITY_ID_DESC',
  PROJECTS_MAX_ID_ASC = 'PROJECTS_MAX_ID_ASC',
  PROJECTS_MAX_ID_DESC = 'PROJECTS_MAX_ID_DESC',
  PROJECTS_MAX_NAME_ASC = 'PROJECTS_MAX_NAME_ASC',
  PROJECTS_MAX_NAME_DESC = 'PROJECTS_MAX_NAME_DESC',
  PROJECTS_MAX_PROJECT_TYPE_ID_ASC = 'PROJECTS_MAX_PROJECT_TYPE_ID_ASC',
  PROJECTS_MAX_PROJECT_TYPE_ID_DESC = 'PROJECTS_MAX_PROJECT_TYPE_ID_DESC',
  PROJECTS_MAX_START_DATE_ASC = 'PROJECTS_MAX_START_DATE_ASC',
  PROJECTS_MAX_START_DATE_DESC = 'PROJECTS_MAX_START_DATE_DESC',
  PROJECTS_MAX_UPDATED_AT_ASC = 'PROJECTS_MAX_UPDATED_AT_ASC',
  PROJECTS_MAX_UPDATED_AT_DESC = 'PROJECTS_MAX_UPDATED_AT_DESC',
  PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_MIN_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_MIN_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_MIN_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_MIN_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_MIN_CONFIG_ASC = 'PROJECTS_MIN_CONFIG_ASC',
  PROJECTS_MIN_CONFIG_DESC = 'PROJECTS_MIN_CONFIG_DESC',
  PROJECTS_MIN_CREATED_AT_ASC = 'PROJECTS_MIN_CREATED_AT_ASC',
  PROJECTS_MIN_CREATED_AT_DESC = 'PROJECTS_MIN_CREATED_AT_DESC',
  PROJECTS_MIN_DESCRIPTION_ASC = 'PROJECTS_MIN_DESCRIPTION_ASC',
  PROJECTS_MIN_DESCRIPTION_DESC = 'PROJECTS_MIN_DESCRIPTION_DESC',
  PROJECTS_MIN_END_DATE_ASC = 'PROJECTS_MIN_END_DATE_ASC',
  PROJECTS_MIN_END_DATE_DESC = 'PROJECTS_MIN_END_DATE_DESC',
  PROJECTS_MIN_FACILITY_ID_ASC = 'PROJECTS_MIN_FACILITY_ID_ASC',
  PROJECTS_MIN_FACILITY_ID_DESC = 'PROJECTS_MIN_FACILITY_ID_DESC',
  PROJECTS_MIN_ID_ASC = 'PROJECTS_MIN_ID_ASC',
  PROJECTS_MIN_ID_DESC = 'PROJECTS_MIN_ID_DESC',
  PROJECTS_MIN_NAME_ASC = 'PROJECTS_MIN_NAME_ASC',
  PROJECTS_MIN_NAME_DESC = 'PROJECTS_MIN_NAME_DESC',
  PROJECTS_MIN_PROJECT_TYPE_ID_ASC = 'PROJECTS_MIN_PROJECT_TYPE_ID_ASC',
  PROJECTS_MIN_PROJECT_TYPE_ID_DESC = 'PROJECTS_MIN_PROJECT_TYPE_ID_DESC',
  PROJECTS_MIN_START_DATE_ASC = 'PROJECTS_MIN_START_DATE_ASC',
  PROJECTS_MIN_START_DATE_DESC = 'PROJECTS_MIN_START_DATE_DESC',
  PROJECTS_MIN_UPDATED_AT_ASC = 'PROJECTS_MIN_UPDATED_AT_ASC',
  PROJECTS_MIN_UPDATED_AT_DESC = 'PROJECTS_MIN_UPDATED_AT_DESC',
  PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_STDDEV_POPULATION_CONFIG_ASC = 'PROJECTS_STDDEV_POPULATION_CONFIG_ASC',
  PROJECTS_STDDEV_POPULATION_CONFIG_DESC = 'PROJECTS_STDDEV_POPULATION_CONFIG_DESC',
  PROJECTS_STDDEV_POPULATION_CREATED_AT_ASC = 'PROJECTS_STDDEV_POPULATION_CREATED_AT_ASC',
  PROJECTS_STDDEV_POPULATION_CREATED_AT_DESC = 'PROJECTS_STDDEV_POPULATION_CREATED_AT_DESC',
  PROJECTS_STDDEV_POPULATION_DESCRIPTION_ASC = 'PROJECTS_STDDEV_POPULATION_DESCRIPTION_ASC',
  PROJECTS_STDDEV_POPULATION_DESCRIPTION_DESC = 'PROJECTS_STDDEV_POPULATION_DESCRIPTION_DESC',
  PROJECTS_STDDEV_POPULATION_END_DATE_ASC = 'PROJECTS_STDDEV_POPULATION_END_DATE_ASC',
  PROJECTS_STDDEV_POPULATION_END_DATE_DESC = 'PROJECTS_STDDEV_POPULATION_END_DATE_DESC',
  PROJECTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'PROJECTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  PROJECTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'PROJECTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  PROJECTS_STDDEV_POPULATION_ID_ASC = 'PROJECTS_STDDEV_POPULATION_ID_ASC',
  PROJECTS_STDDEV_POPULATION_ID_DESC = 'PROJECTS_STDDEV_POPULATION_ID_DESC',
  PROJECTS_STDDEV_POPULATION_NAME_ASC = 'PROJECTS_STDDEV_POPULATION_NAME_ASC',
  PROJECTS_STDDEV_POPULATION_NAME_DESC = 'PROJECTS_STDDEV_POPULATION_NAME_DESC',
  PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_ASC = 'PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_ASC',
  PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_DESC = 'PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_DESC',
  PROJECTS_STDDEV_POPULATION_START_DATE_ASC = 'PROJECTS_STDDEV_POPULATION_START_DATE_ASC',
  PROJECTS_STDDEV_POPULATION_START_DATE_DESC = 'PROJECTS_STDDEV_POPULATION_START_DATE_DESC',
  PROJECTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'PROJECTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  PROJECTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'PROJECTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_STDDEV_SAMPLE_CONFIG_ASC = 'PROJECTS_STDDEV_SAMPLE_CONFIG_ASC',
  PROJECTS_STDDEV_SAMPLE_CONFIG_DESC = 'PROJECTS_STDDEV_SAMPLE_CONFIG_DESC',
  PROJECTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'PROJECTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  PROJECTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'PROJECTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  PROJECTS_STDDEV_SAMPLE_DESCRIPTION_ASC = 'PROJECTS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PROJECTS_STDDEV_SAMPLE_DESCRIPTION_DESC = 'PROJECTS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PROJECTS_STDDEV_SAMPLE_END_DATE_ASC = 'PROJECTS_STDDEV_SAMPLE_END_DATE_ASC',
  PROJECTS_STDDEV_SAMPLE_END_DATE_DESC = 'PROJECTS_STDDEV_SAMPLE_END_DATE_DESC',
  PROJECTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_NAME_ASC = 'PROJECTS_STDDEV_SAMPLE_NAME_ASC',
  PROJECTS_STDDEV_SAMPLE_NAME_DESC = 'PROJECTS_STDDEV_SAMPLE_NAME_DESC',
  PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_START_DATE_ASC = 'PROJECTS_STDDEV_SAMPLE_START_DATE_ASC',
  PROJECTS_STDDEV_SAMPLE_START_DATE_DESC = 'PROJECTS_STDDEV_SAMPLE_START_DATE_DESC',
  PROJECTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'PROJECTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PROJECTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'PROJECTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_SUM_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_SUM_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_SUM_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_SUM_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_SUM_CONFIG_ASC = 'PROJECTS_SUM_CONFIG_ASC',
  PROJECTS_SUM_CONFIG_DESC = 'PROJECTS_SUM_CONFIG_DESC',
  PROJECTS_SUM_CREATED_AT_ASC = 'PROJECTS_SUM_CREATED_AT_ASC',
  PROJECTS_SUM_CREATED_AT_DESC = 'PROJECTS_SUM_CREATED_AT_DESC',
  PROJECTS_SUM_DESCRIPTION_ASC = 'PROJECTS_SUM_DESCRIPTION_ASC',
  PROJECTS_SUM_DESCRIPTION_DESC = 'PROJECTS_SUM_DESCRIPTION_DESC',
  PROJECTS_SUM_END_DATE_ASC = 'PROJECTS_SUM_END_DATE_ASC',
  PROJECTS_SUM_END_DATE_DESC = 'PROJECTS_SUM_END_DATE_DESC',
  PROJECTS_SUM_FACILITY_ID_ASC = 'PROJECTS_SUM_FACILITY_ID_ASC',
  PROJECTS_SUM_FACILITY_ID_DESC = 'PROJECTS_SUM_FACILITY_ID_DESC',
  PROJECTS_SUM_ID_ASC = 'PROJECTS_SUM_ID_ASC',
  PROJECTS_SUM_ID_DESC = 'PROJECTS_SUM_ID_DESC',
  PROJECTS_SUM_NAME_ASC = 'PROJECTS_SUM_NAME_ASC',
  PROJECTS_SUM_NAME_DESC = 'PROJECTS_SUM_NAME_DESC',
  PROJECTS_SUM_PROJECT_TYPE_ID_ASC = 'PROJECTS_SUM_PROJECT_TYPE_ID_ASC',
  PROJECTS_SUM_PROJECT_TYPE_ID_DESC = 'PROJECTS_SUM_PROJECT_TYPE_ID_DESC',
  PROJECTS_SUM_START_DATE_ASC = 'PROJECTS_SUM_START_DATE_ASC',
  PROJECTS_SUM_START_DATE_DESC = 'PROJECTS_SUM_START_DATE_DESC',
  PROJECTS_SUM_UPDATED_AT_ASC = 'PROJECTS_SUM_UPDATED_AT_ASC',
  PROJECTS_SUM_UPDATED_AT_DESC = 'PROJECTS_SUM_UPDATED_AT_DESC',
  PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_VARIANCE_POPULATION_CONFIG_ASC = 'PROJECTS_VARIANCE_POPULATION_CONFIG_ASC',
  PROJECTS_VARIANCE_POPULATION_CONFIG_DESC = 'PROJECTS_VARIANCE_POPULATION_CONFIG_DESC',
  PROJECTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'PROJECTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  PROJECTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'PROJECTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  PROJECTS_VARIANCE_POPULATION_DESCRIPTION_ASC = 'PROJECTS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PROJECTS_VARIANCE_POPULATION_DESCRIPTION_DESC = 'PROJECTS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PROJECTS_VARIANCE_POPULATION_END_DATE_ASC = 'PROJECTS_VARIANCE_POPULATION_END_DATE_ASC',
  PROJECTS_VARIANCE_POPULATION_END_DATE_DESC = 'PROJECTS_VARIANCE_POPULATION_END_DATE_DESC',
  PROJECTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_NAME_ASC = 'PROJECTS_VARIANCE_POPULATION_NAME_ASC',
  PROJECTS_VARIANCE_POPULATION_NAME_DESC = 'PROJECTS_VARIANCE_POPULATION_NAME_DESC',
  PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_START_DATE_ASC = 'PROJECTS_VARIANCE_POPULATION_START_DATE_ASC',
  PROJECTS_VARIANCE_POPULATION_START_DATE_DESC = 'PROJECTS_VARIANCE_POPULATION_START_DATE_DESC',
  PROJECTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'PROJECTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PROJECTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'PROJECTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_VARIANCE_SAMPLE_CONFIG_ASC = 'PROJECTS_VARIANCE_SAMPLE_CONFIG_ASC',
  PROJECTS_VARIANCE_SAMPLE_CONFIG_DESC = 'PROJECTS_VARIANCE_SAMPLE_CONFIG_DESC',
  PROJECTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'PROJECTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PROJECTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'PROJECTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PROJECTS_VARIANCE_SAMPLE_END_DATE_ASC = 'PROJECTS_VARIANCE_SAMPLE_END_DATE_ASC',
  PROJECTS_VARIANCE_SAMPLE_END_DATE_DESC = 'PROJECTS_VARIANCE_SAMPLE_END_DATE_DESC',
  PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_NAME_ASC = 'PROJECTS_VARIANCE_SAMPLE_NAME_ASC',
  PROJECTS_VARIANCE_SAMPLE_NAME_DESC = 'PROJECTS_VARIANCE_SAMPLE_NAME_DESC',
  PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_START_DATE_ASC = 'PROJECTS_VARIANCE_SAMPLE_START_DATE_ASC',
  PROJECTS_VARIANCE_SAMPLE_START_DATE_DESC = 'PROJECTS_VARIANCE_SAMPLE_START_DATE_DESC',
  PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  REAL_TIME_ENABLED_ASC = 'REAL_TIME_ENABLED_ASC',
  REAL_TIME_ENABLED_DESC = 'REAL_TIME_ENABLED_DESC',
  SLUG_ASC = 'SLUG_ASC',
  SLUG_DESC = 'SLUG_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  SUPPRESSE_FINANCIALS_ASC = 'SUPPRESSE_FINANCIALS_ASC',
  SUPPRESSE_FINANCIALS_DESC = 'SUPPRESSE_FINANCIALS_DESC',
  TIMEZONE_NAME_ASC = 'TIMEZONE_NAME_ASC',
  TIMEZONE_NAME_DESC = 'TIMEZONE_NAME_DESC',
  UNIT_GROUP_ASC = 'UNIT_GROUP_ASC',
  UNIT_GROUP_DESC = 'UNIT_GROUP_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_METERS_AVERAGE_ACTIVE_END_ASC = 'UTILITY_METERS_AVERAGE_ACTIVE_END_ASC',
  UTILITY_METERS_AVERAGE_ACTIVE_END_DESC = 'UTILITY_METERS_AVERAGE_ACTIVE_END_DESC',
  UTILITY_METERS_AVERAGE_ACTIVE_START_ASC = 'UTILITY_METERS_AVERAGE_ACTIVE_START_ASC',
  UTILITY_METERS_AVERAGE_ACTIVE_START_DESC = 'UTILITY_METERS_AVERAGE_ACTIVE_START_DESC',
  UTILITY_METERS_AVERAGE_CREATED_AT_ASC = 'UTILITY_METERS_AVERAGE_CREATED_AT_ASC',
  UTILITY_METERS_AVERAGE_CREATED_AT_DESC = 'UTILITY_METERS_AVERAGE_CREATED_AT_DESC',
  UTILITY_METERS_AVERAGE_DELETED_AT_ASC = 'UTILITY_METERS_AVERAGE_DELETED_AT_ASC',
  UTILITY_METERS_AVERAGE_DELETED_AT_DESC = 'UTILITY_METERS_AVERAGE_DELETED_AT_DESC',
  UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METERS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METERS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METERS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METERS_AVERAGE_ID_ASC = 'UTILITY_METERS_AVERAGE_ID_ASC',
  UTILITY_METERS_AVERAGE_ID_DESC = 'UTILITY_METERS_AVERAGE_ID_DESC',
  UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_AVERAGE_METADATA_ASC = 'UTILITY_METERS_AVERAGE_METADATA_ASC',
  UTILITY_METERS_AVERAGE_METADATA_DESC = 'UTILITY_METERS_AVERAGE_METADATA_DESC',
  UTILITY_METERS_AVERAGE_METER_NUMBER_ASC = 'UTILITY_METERS_AVERAGE_METER_NUMBER_ASC',
  UTILITY_METERS_AVERAGE_METER_NUMBER_DESC = 'UTILITY_METERS_AVERAGE_METER_NUMBER_DESC',
  UTILITY_METERS_AVERAGE_NAME_ASC = 'UTILITY_METERS_AVERAGE_NAME_ASC',
  UTILITY_METERS_AVERAGE_NAME_DESC = 'UTILITY_METERS_AVERAGE_NAME_DESC',
  UTILITY_METERS_AVERAGE_OVERRIDE_AT_ASC = 'UTILITY_METERS_AVERAGE_OVERRIDE_AT_ASC',
  UTILITY_METERS_AVERAGE_OVERRIDE_AT_DESC = 'UTILITY_METERS_AVERAGE_OVERRIDE_AT_DESC',
  UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METERS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METERS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METERS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METERS_AVERAGE_UPDATED_AT_ASC = 'UTILITY_METERS_AVERAGE_UPDATED_AT_ASC',
  UTILITY_METERS_AVERAGE_UPDATED_AT_DESC = 'UTILITY_METERS_AVERAGE_UPDATED_AT_DESC',
  UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_COUNT_ASC = 'UTILITY_METERS_COUNT_ASC',
  UTILITY_METERS_COUNT_DESC = 'UTILITY_METERS_COUNT_DESC',
  UTILITY_METERS_DISTINCT_ACTIVE_END_ASC = 'UTILITY_METERS_DISTINCT_ACTIVE_END_ASC',
  UTILITY_METERS_DISTINCT_ACTIVE_END_DESC = 'UTILITY_METERS_DISTINCT_ACTIVE_END_DESC',
  UTILITY_METERS_DISTINCT_ACTIVE_START_ASC = 'UTILITY_METERS_DISTINCT_ACTIVE_START_ASC',
  UTILITY_METERS_DISTINCT_ACTIVE_START_DESC = 'UTILITY_METERS_DISTINCT_ACTIVE_START_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_DESC',
  UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ID_DESC',
  UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_DISTINCT_COUNT_METADATA_ASC = 'UTILITY_METERS_DISTINCT_COUNT_METADATA_ASC',
  UTILITY_METERS_DISTINCT_COUNT_METADATA_DESC = 'UTILITY_METERS_DISTINCT_COUNT_METADATA_DESC',
  UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_COUNT_NAME_ASC = 'UTILITY_METERS_DISTINCT_COUNT_NAME_ASC',
  UTILITY_METERS_DISTINCT_COUNT_NAME_DESC = 'UTILITY_METERS_DISTINCT_COUNT_NAME_DESC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_DISTINCT_CREATED_AT_ASC = 'UTILITY_METERS_DISTINCT_CREATED_AT_ASC',
  UTILITY_METERS_DISTINCT_CREATED_AT_DESC = 'UTILITY_METERS_DISTINCT_CREATED_AT_DESC',
  UTILITY_METERS_DISTINCT_DELETED_AT_ASC = 'UTILITY_METERS_DISTINCT_DELETED_AT_ASC',
  UTILITY_METERS_DISTINCT_DELETED_AT_DESC = 'UTILITY_METERS_DISTINCT_DELETED_AT_DESC',
  UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METERS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METERS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METERS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METERS_DISTINCT_ID_ASC = 'UTILITY_METERS_DISTINCT_ID_ASC',
  UTILITY_METERS_DISTINCT_ID_DESC = 'UTILITY_METERS_DISTINCT_ID_DESC',
  UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_DISTINCT_METADATA_ASC = 'UTILITY_METERS_DISTINCT_METADATA_ASC',
  UTILITY_METERS_DISTINCT_METADATA_DESC = 'UTILITY_METERS_DISTINCT_METADATA_DESC',
  UTILITY_METERS_DISTINCT_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_NAME_ASC = 'UTILITY_METERS_DISTINCT_NAME_ASC',
  UTILITY_METERS_DISTINCT_NAME_DESC = 'UTILITY_METERS_DISTINCT_NAME_DESC',
  UTILITY_METERS_DISTINCT_OVERRIDE_AT_ASC = 'UTILITY_METERS_DISTINCT_OVERRIDE_AT_ASC',
  UTILITY_METERS_DISTINCT_OVERRIDE_AT_DESC = 'UTILITY_METERS_DISTINCT_OVERRIDE_AT_DESC',
  UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METERS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METERS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METERS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METERS_DISTINCT_UPDATED_AT_ASC = 'UTILITY_METERS_DISTINCT_UPDATED_AT_ASC',
  UTILITY_METERS_DISTINCT_UPDATED_AT_DESC = 'UTILITY_METERS_DISTINCT_UPDATED_AT_DESC',
  UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_MAX_ACTIVE_END_ASC = 'UTILITY_METERS_MAX_ACTIVE_END_ASC',
  UTILITY_METERS_MAX_ACTIVE_END_DESC = 'UTILITY_METERS_MAX_ACTIVE_END_DESC',
  UTILITY_METERS_MAX_ACTIVE_START_ASC = 'UTILITY_METERS_MAX_ACTIVE_START_ASC',
  UTILITY_METERS_MAX_ACTIVE_START_DESC = 'UTILITY_METERS_MAX_ACTIVE_START_DESC',
  UTILITY_METERS_MAX_CREATED_AT_ASC = 'UTILITY_METERS_MAX_CREATED_AT_ASC',
  UTILITY_METERS_MAX_CREATED_AT_DESC = 'UTILITY_METERS_MAX_CREATED_AT_DESC',
  UTILITY_METERS_MAX_DELETED_AT_ASC = 'UTILITY_METERS_MAX_DELETED_AT_ASC',
  UTILITY_METERS_MAX_DELETED_AT_DESC = 'UTILITY_METERS_MAX_DELETED_AT_DESC',
  UTILITY_METERS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_MAX_FACILITY_ID_ASC = 'UTILITY_METERS_MAX_FACILITY_ID_ASC',
  UTILITY_METERS_MAX_FACILITY_ID_DESC = 'UTILITY_METERS_MAX_FACILITY_ID_DESC',
  UTILITY_METERS_MAX_ID_ASC = 'UTILITY_METERS_MAX_ID_ASC',
  UTILITY_METERS_MAX_ID_DESC = 'UTILITY_METERS_MAX_ID_DESC',
  UTILITY_METERS_MAX_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_MAX_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_MAX_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_MAX_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_MAX_METADATA_ASC = 'UTILITY_METERS_MAX_METADATA_ASC',
  UTILITY_METERS_MAX_METADATA_DESC = 'UTILITY_METERS_MAX_METADATA_DESC',
  UTILITY_METERS_MAX_METER_NUMBER_ASC = 'UTILITY_METERS_MAX_METER_NUMBER_ASC',
  UTILITY_METERS_MAX_METER_NUMBER_DESC = 'UTILITY_METERS_MAX_METER_NUMBER_DESC',
  UTILITY_METERS_MAX_NAME_ASC = 'UTILITY_METERS_MAX_NAME_ASC',
  UTILITY_METERS_MAX_NAME_DESC = 'UTILITY_METERS_MAX_NAME_DESC',
  UTILITY_METERS_MAX_OVERRIDE_AT_ASC = 'UTILITY_METERS_MAX_OVERRIDE_AT_ASC',
  UTILITY_METERS_MAX_OVERRIDE_AT_DESC = 'UTILITY_METERS_MAX_OVERRIDE_AT_DESC',
  UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METERS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METERS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METERS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METERS_MAX_UPDATED_AT_ASC = 'UTILITY_METERS_MAX_UPDATED_AT_ASC',
  UTILITY_METERS_MAX_UPDATED_AT_DESC = 'UTILITY_METERS_MAX_UPDATED_AT_DESC',
  UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_MIN_ACTIVE_END_ASC = 'UTILITY_METERS_MIN_ACTIVE_END_ASC',
  UTILITY_METERS_MIN_ACTIVE_END_DESC = 'UTILITY_METERS_MIN_ACTIVE_END_DESC',
  UTILITY_METERS_MIN_ACTIVE_START_ASC = 'UTILITY_METERS_MIN_ACTIVE_START_ASC',
  UTILITY_METERS_MIN_ACTIVE_START_DESC = 'UTILITY_METERS_MIN_ACTIVE_START_DESC',
  UTILITY_METERS_MIN_CREATED_AT_ASC = 'UTILITY_METERS_MIN_CREATED_AT_ASC',
  UTILITY_METERS_MIN_CREATED_AT_DESC = 'UTILITY_METERS_MIN_CREATED_AT_DESC',
  UTILITY_METERS_MIN_DELETED_AT_ASC = 'UTILITY_METERS_MIN_DELETED_AT_ASC',
  UTILITY_METERS_MIN_DELETED_AT_DESC = 'UTILITY_METERS_MIN_DELETED_AT_DESC',
  UTILITY_METERS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_MIN_FACILITY_ID_ASC = 'UTILITY_METERS_MIN_FACILITY_ID_ASC',
  UTILITY_METERS_MIN_FACILITY_ID_DESC = 'UTILITY_METERS_MIN_FACILITY_ID_DESC',
  UTILITY_METERS_MIN_ID_ASC = 'UTILITY_METERS_MIN_ID_ASC',
  UTILITY_METERS_MIN_ID_DESC = 'UTILITY_METERS_MIN_ID_DESC',
  UTILITY_METERS_MIN_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_MIN_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_MIN_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_MIN_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_MIN_METADATA_ASC = 'UTILITY_METERS_MIN_METADATA_ASC',
  UTILITY_METERS_MIN_METADATA_DESC = 'UTILITY_METERS_MIN_METADATA_DESC',
  UTILITY_METERS_MIN_METER_NUMBER_ASC = 'UTILITY_METERS_MIN_METER_NUMBER_ASC',
  UTILITY_METERS_MIN_METER_NUMBER_DESC = 'UTILITY_METERS_MIN_METER_NUMBER_DESC',
  UTILITY_METERS_MIN_NAME_ASC = 'UTILITY_METERS_MIN_NAME_ASC',
  UTILITY_METERS_MIN_NAME_DESC = 'UTILITY_METERS_MIN_NAME_DESC',
  UTILITY_METERS_MIN_OVERRIDE_AT_ASC = 'UTILITY_METERS_MIN_OVERRIDE_AT_ASC',
  UTILITY_METERS_MIN_OVERRIDE_AT_DESC = 'UTILITY_METERS_MIN_OVERRIDE_AT_DESC',
  UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METERS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METERS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METERS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METERS_MIN_UPDATED_AT_ASC = 'UTILITY_METERS_MIN_UPDATED_AT_ASC',
  UTILITY_METERS_MIN_UPDATED_AT_DESC = 'UTILITY_METERS_MIN_UPDATED_AT_DESC',
  UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_DESC',
  UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_STDDEV_POPULATION_METADATA_ASC = 'UTILITY_METERS_STDDEV_POPULATION_METADATA_ASC',
  UTILITY_METERS_STDDEV_POPULATION_METADATA_DESC = 'UTILITY_METERS_STDDEV_POPULATION_METADATA_DESC',
  UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_POPULATION_NAME_ASC = 'UTILITY_METERS_STDDEV_POPULATION_NAME_ASC',
  UTILITY_METERS_STDDEV_POPULATION_NAME_DESC = 'UTILITY_METERS_STDDEV_POPULATION_NAME_DESC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_METADATA_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_METADATA_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_METADATA_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_METADATA_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_NAME_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_NAME_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_NAME_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_NAME_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_SUM_ACTIVE_END_ASC = 'UTILITY_METERS_SUM_ACTIVE_END_ASC',
  UTILITY_METERS_SUM_ACTIVE_END_DESC = 'UTILITY_METERS_SUM_ACTIVE_END_DESC',
  UTILITY_METERS_SUM_ACTIVE_START_ASC = 'UTILITY_METERS_SUM_ACTIVE_START_ASC',
  UTILITY_METERS_SUM_ACTIVE_START_DESC = 'UTILITY_METERS_SUM_ACTIVE_START_DESC',
  UTILITY_METERS_SUM_CREATED_AT_ASC = 'UTILITY_METERS_SUM_CREATED_AT_ASC',
  UTILITY_METERS_SUM_CREATED_AT_DESC = 'UTILITY_METERS_SUM_CREATED_AT_DESC',
  UTILITY_METERS_SUM_DELETED_AT_ASC = 'UTILITY_METERS_SUM_DELETED_AT_ASC',
  UTILITY_METERS_SUM_DELETED_AT_DESC = 'UTILITY_METERS_SUM_DELETED_AT_DESC',
  UTILITY_METERS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_SUM_FACILITY_ID_ASC = 'UTILITY_METERS_SUM_FACILITY_ID_ASC',
  UTILITY_METERS_SUM_FACILITY_ID_DESC = 'UTILITY_METERS_SUM_FACILITY_ID_DESC',
  UTILITY_METERS_SUM_ID_ASC = 'UTILITY_METERS_SUM_ID_ASC',
  UTILITY_METERS_SUM_ID_DESC = 'UTILITY_METERS_SUM_ID_DESC',
  UTILITY_METERS_SUM_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_SUM_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_SUM_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_SUM_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_SUM_METADATA_ASC = 'UTILITY_METERS_SUM_METADATA_ASC',
  UTILITY_METERS_SUM_METADATA_DESC = 'UTILITY_METERS_SUM_METADATA_DESC',
  UTILITY_METERS_SUM_METER_NUMBER_ASC = 'UTILITY_METERS_SUM_METER_NUMBER_ASC',
  UTILITY_METERS_SUM_METER_NUMBER_DESC = 'UTILITY_METERS_SUM_METER_NUMBER_DESC',
  UTILITY_METERS_SUM_NAME_ASC = 'UTILITY_METERS_SUM_NAME_ASC',
  UTILITY_METERS_SUM_NAME_DESC = 'UTILITY_METERS_SUM_NAME_DESC',
  UTILITY_METERS_SUM_OVERRIDE_AT_ASC = 'UTILITY_METERS_SUM_OVERRIDE_AT_ASC',
  UTILITY_METERS_SUM_OVERRIDE_AT_DESC = 'UTILITY_METERS_SUM_OVERRIDE_AT_DESC',
  UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METERS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METERS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METERS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METERS_SUM_UPDATED_AT_ASC = 'UTILITY_METERS_SUM_UPDATED_AT_ASC',
  UTILITY_METERS_SUM_UPDATED_AT_DESC = 'UTILITY_METERS_SUM_UPDATED_AT_DESC',
  UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_METADATA_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_METADATA_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_METADATA_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_METADATA_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_NAME_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_NAME_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_NAME_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_NAME_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_METADATA_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_METADATA_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_METADATA_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_METADATA_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_NAME_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_NAME_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_NAME_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_NAME_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_COUNT_ASC = 'UTILITY_METER_STATEMENTS_COUNT_ASC',
  UTILITY_METER_STATEMENTS_COUNT_DESC = 'UTILITY_METER_STATEMENTS_COUNT_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  ZIP_ASC = 'ZIP_ASC',
  ZIP_DESC = 'ZIP_DESC'
}

/** A facility. */
export type Facility = Asset &
  Node & {
    __typename?: 'Facility';
    /** Reads and enables pagination through a set of `AccountStatement`. */
    accountStatements: AccountStatementsConnection;
    /** Reads and enables pagination through a set of `AccountStatement`. */
    accountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementId: FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyConnection;
    /** The facility’s street address. */
    address?: Maybe<Scalars['String']>;
    /** Bonus LTR */
    bonusLtr: Scalars['Boolean'];
    /** The facility’s city. */
    city?: Maybe<Scalars['String']>;
    /** Reads and enables pagination through a set of `Component`. */
    components: ComponentsConnection;
    config: Scalars['JSON'];
    country: Country;
    /** The time this facility was created. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Reads and enables pagination through a set of `EventType`. */
    eventTypesByEventFacilityIdAndType: FacilityEventTypesByEventFacilityIdAndTypeManyToManyConnection;
    /** User contacts for facility */
    facilityContacts?: Maybe<Scalars['String']>;
    /** Reads and enables pagination through a set of `FacilityGroup`. */
    facilityGroups: FacilityFacilityGroupsManyToManyConnection;
    /** Reads and enables pagination through a set of `FacilityGroupsLinker`. */
    facilityGroupsLinkers: FacilityGroupsLinkersConnection;
    /** The primary unique identifier for the facility. */
    id: Scalars['Int'];
    /** Facility Latitude */
    latitude?: Maybe<Scalars['Float']>;
    /** Link Active */
    linkActive: Scalars['Boolean'];
    /** Facility Longitude */
    longitude?: Maybe<Scalars['Float']>;
    /** Reads and enables pagination through a set of `MachineUser`. */
    machineUsersByComponentFacilityIdAndMachineUserId: FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyConnection;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    metricLabels: Array<MetricLabel>;
    /** metricsOne Active */
    metricsOneActive: Scalars['Boolean'];
    mutableMetricData?: Maybe<MetricDataConnection>;
    /** Enabled in nSight. */
    nSight2Active: Scalars['Boolean'];
    /** nSight Active */
    nSightActive: Scalars['Boolean'];
    /** The facility’s name. */
    name: Scalars['String'];
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    /** The note of Facility */
    note?: Maybe<Scalars['String']>;
    /** Pre-Bonus EBITDA */
    preBonusEbitda: Scalars['Boolean'];
    /** Reads and enables pagination through a set of `ProjectType`. */
    projectTypesByProjectFacilityIdAndProjectTypeId: FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyConnection;
    /** Reads and enables pagination through a set of `Project`. */
    projects: ProjectsConnection;
    /** Flag to use real-time data or just utility data */
    realTimeEnabled: Scalars['Boolean'];
    /** The facility’s slug. */
    slug: Scalars['String'];
    /** The facility’s state. */
    state?: Maybe<Scalars['String']>;
    /** Suppresse Financials */
    suppresseFinancials: Scalars['Boolean'];
    /** Reads a single `Timezone` that is related to this `Facility`. */
    timezone?: Maybe<Timezone>;
    /** The name of the timezone for the facility. */
    timezoneName?: Maybe<Scalars['String']>;
    /** The unit grouping of the facility. */
    unitGroup?: Maybe<Scalars['String']>;
    /** Reads a single `UnitGroup` that is related to this `Facility`. */
    unitGroupByUnitGroup?: Maybe<UnitGroup>;
    /** The time this facility was updated. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Returns a rollup of account statements for a given facility. */
    utilityAccountStatementRollups: UtilityAccountStatementRollupsConnection;
    /** Returns a top level of account statements for a given facility. */
    utilityAccountStatements: UtilityAccountStatementsConnection;
    /** Returns a set of distinct utility accounts for a given facility. */
    utilityAccounts: UtilityAccountsConnection;
    /** Reads and enables pagination through a set of `UtilityAccount`. */
    utilityAccountsByUtilityMeterFacilityIdAndUtilityAccountId: FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyConnection;
    /** Reads and enables pagination through a set of `UtilityAccount`. */
    utilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountId: FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyConnection;
    /** Reads and enables pagination through a set of `UtilityAccount`. */
    utilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountId: FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyConnection;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
    /** Reads and enables pagination through a set of `UtilityMeter`. */
    utilityMeters: UtilityMetersConnection;
    /** Reads and enables pagination through a set of `UtilityMeter`. */
    utilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterId: FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyConnection;
    /** Reads and enables pagination through a set of `UtilityMeter`. */
    utilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterId: FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyConnection;
    /** The facility’s zip code. */
    zip?: Maybe<Scalars['String']>;
  };

/** A facility. */
export type FacilityAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<AccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A facility. */
export type FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<AccountStatementCondition>;
    filter?: InputMaybe<AccountStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
  };

/** A facility. */
export type FacilityComponentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ComponentCondition>;
  filter?: InputMaybe<ComponentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** A facility. */
export type FacilityEventTypesByEventFacilityIdAndTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventTypeCondition>;
  filter?: InputMaybe<EventTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};

/** A facility. */
export type FacilityFacilityGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupCondition>;
  filter?: InputMaybe<FacilityGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** A facility. */
export type FacilityFacilityGroupsLinkersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupsLinkerCondition>;
  filter?: InputMaybe<FacilityGroupsLinkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

/** A facility. */
export type FacilityMachineUsersByComponentFacilityIdAndMachineUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MachineUserCondition>;
  filter?: InputMaybe<MachineUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** A facility. */
export type FacilityMetricDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  aggregation?: InputMaybe<MetricDataAggregationMethod>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  samplingWindow?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  unitGroup?: InputMaybe<Scalars['String']>;
  window?: Scalars['String'];
};

/** A facility. */
export type FacilityMetricLabelsArgs = {
  mutableOnly?: InputMaybe<Scalars['Boolean']>;
};

/** A facility. */
export type FacilityMutableMetricDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  to?: InputMaybe<Scalars['String']>;
};

/** A facility. */
export type FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectTypeCondition>;
  filter?: InputMaybe<ProjectTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectTypesOrderBy>>;
};

/** A facility. */
export type FacilityProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A facility. */
export type FacilityUtilityAccountStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UtilityAccountStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A facility. */
export type FacilityUtilityAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UtilityAccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A facility. */
export type FacilityUtilityAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UtilityAccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A facility. */
export type FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

/** A facility. */
export type FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

/** A facility. */
export type FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

/** A facility. */
export type FacilityUtilityMeterStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
  filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
};

/** A facility. */
export type FacilityUtilityMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementCondition>;
  filter?: InputMaybe<UtilityMeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
};

/** A facility. */
export type FacilityUtilityMetersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterCondition>;
  filter?: InputMaybe<UtilityMeterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** A facility. */
export type FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A facility. */
export type FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyConnection =
  {
    __typename?: 'FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<AccountStatementAggregates>;
    /** A list of edges which contains the `AccountStatement`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<AccountStatementAggregates>>;
    /** A list of `AccountStatement` objects. */
    nodes: Array<AccountStatement>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `AccountStatement` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<AccountStatementsGroupBy>;
    having?: InputMaybe<AccountStatementsHavingInput>;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyEdge =
  {
    __typename?: 'FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `AccountStatement` at the end of the edge. */
    node: AccountStatement;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityAccountStatementsByUtilityMeterStatementFacilityIdAndAccountStatementIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type FacilityAggregates = {
  __typename?: 'FacilityAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FacilityAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<FacilityDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FacilityDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FacilityMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FacilityMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FacilityStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FacilityStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FacilitySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FacilityVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FacilityVarianceSampleAggregates>;
};

export type FacilityAverageAggregates = {
  __typename?: 'FacilityAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Mean average of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `Facility` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FacilityCondition = {
  /** Checks for equality with the object’s `address` field. */
  address?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `bonusLtr` field. */
  bonusLtr?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Country>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `facilityContacts` field. */
  facilityContacts?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `latitude` field. */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `linkActive` field. */
  linkActive?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `longitude` field. */
  longitude?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `metricsOneActive` field. */
  metricsOneActive?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `nSight2Active` field. */
  nSight2Active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `nSightActive` field. */
  nSightActive?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `preBonusEbitda` field. */
  preBonusEbitda?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `realTimeEnabled` field. */
  realTimeEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `suppresseFinancials` field. */
  suppresseFinancials?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `timezoneName` field. */
  timezoneName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `unitGroup` field. */
  unitGroup?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `zip` field. */
  zip?: InputMaybe<Scalars['String']>;
};

export type FacilityDistinctAggregates = {
  __typename?: 'FacilityDistinctAggregates';
  /** Distinct of address across the matching connection */
  address?: Maybe<Scalars['JSON']>;
  /** Distinct of bonusLtr across the matching connection */
  bonusLtr?: Maybe<Scalars['JSON']>;
  /** Distinct of city across the matching connection */
  city?: Maybe<Scalars['JSON']>;
  /** Distinct of config across the matching connection */
  config?: Maybe<Scalars['JSON']>;
  /** Distinct of country across the matching connection */
  country?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityContacts across the matching connection */
  facilityContacts?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of latitude across the matching connection */
  latitude?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of linkActive across the matching connection */
  linkActive?: Maybe<Scalars['JSON']>;
  /** Distinct of longitude across the matching connection */
  longitude?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of metricsOneActive across the matching connection */
  metricsOneActive?: Maybe<Scalars['JSON']>;
  /** Distinct of nSight2Active across the matching connection */
  nSight2Active?: Maybe<Scalars['JSON']>;
  /** Distinct of nSightActive across the matching connection */
  nSightActive?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /** Distinct of note across the matching connection */
  note?: Maybe<Scalars['JSON']>;
  /** Distinct of preBonusEbitda across the matching connection */
  preBonusEbitda?: Maybe<Scalars['JSON']>;
  /** Distinct of realTimeEnabled across the matching connection */
  realTimeEnabled?: Maybe<Scalars['JSON']>;
  /** Distinct of slug across the matching connection */
  slug?: Maybe<Scalars['JSON']>;
  /** Distinct of state across the matching connection */
  state?: Maybe<Scalars['JSON']>;
  /** Distinct of suppresseFinancials across the matching connection */
  suppresseFinancials?: Maybe<Scalars['JSON']>;
  /** Distinct of timezoneName across the matching connection */
  timezoneName?: Maybe<Scalars['JSON']>;
  /** Distinct of unitGroup across the matching connection */
  unitGroup?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of zip across the matching connection */
  zip?: Maybe<Scalars['JSON']>;
};

export type FacilityDistinctCountAggregates = {
  __typename?: 'FacilityDistinctCountAggregates';
  /** Distinct count of address across the matching connection */
  address?: Maybe<Scalars['BigInt']>;
  /** Distinct count of bonusLtr across the matching connection */
  bonusLtr?: Maybe<Scalars['BigInt']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityContacts across the matching connection */
  facilityContacts?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of latitude across the matching connection */
  latitude?: Maybe<Scalars['BigInt']>;
  /** Distinct count of linkActive across the matching connection */
  linkActive?: Maybe<Scalars['BigInt']>;
  /** Distinct count of longitude across the matching connection */
  longitude?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metricsOneActive across the matching connection */
  metricsOneActive?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nSight2Active across the matching connection */
  nSight2Active?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nSightActive across the matching connection */
  nSightActive?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']>;
  /** Distinct count of preBonusEbitda across the matching connection */
  preBonusEbitda?: Maybe<Scalars['BigInt']>;
  /** Distinct count of realTimeEnabled across the matching connection */
  realTimeEnabled?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slug across the matching connection */
  slug?: Maybe<Scalars['BigInt']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of suppresseFinancials across the matching connection */
  suppresseFinancials?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timezoneName across the matching connection */
  timezoneName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unitGroup across the matching connection */
  unitGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of zip across the matching connection */
  zip?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `EventType` values, with data from `Event`. */
export type FacilityEventTypesByEventFacilityIdAndTypeManyToManyConnection = {
  __typename?: 'FacilityEventTypesByEventFacilityIdAndTypeManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EventTypeAggregates>;
  /** A list of edges which contains the `EventType`, info from the `Event`, and the cursor to aid in pagination. */
  edges: Array<FacilityEventTypesByEventFacilityIdAndTypeManyToManyEdge>;
  /** A list of `EventType` objects. */
  nodes: Array<EventType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EventType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EventType` edge in the connection, with data from `Event`. */
export type FacilityEventTypesByEventFacilityIdAndTypeManyToManyEdge = {
  __typename?: 'FacilityEventTypesByEventFacilityIdAndTypeManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** Reads and enables pagination through a set of `Event`. */
  eventsByType: EventsConnection;
  /** The `EventType` at the end of the edge. */
  node: EventType;
};

/** A `EventType` edge in the connection, with data from `Event`. */
export type FacilityEventTypesByEventFacilityIdAndTypeManyToManyEdgeEventsByTypeArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<EventCondition>;
    filter?: InputMaybe<EventFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<EventsOrderBy>>;
  };

/** A connection to a list of `FacilityGroup` values, with data from `FacilityGroupsLinker`. */
export type FacilityFacilityGroupsManyToManyConnection = {
  __typename?: 'FacilityFacilityGroupsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityGroupAggregates>;
  /** A list of edges which contains the `FacilityGroup`, info from the `FacilityGroupsLinker`, and the cursor to aid in pagination. */
  edges: Array<FacilityFacilityGroupsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityGroupAggregates>>;
  /** A list of `FacilityGroup` objects. */
  nodes: Array<FacilityGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FacilityGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `FacilityGroup` values, with data from `FacilityGroupsLinker`. */
export type FacilityFacilityGroupsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FacilityGroupsGroupBy>;
  having?: InputMaybe<FacilityGroupsHavingInput>;
};

/** A `FacilityGroup` edge in the connection, with data from `FacilityGroupsLinker`. */
export type FacilityFacilityGroupsManyToManyEdge = {
  __typename?: 'FacilityFacilityGroupsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FacilityGroup` at the end of the edge. */
  node: FacilityGroup;
};

/** A filter to be used against `Facility` object types. All fields are combined with a logical ‘and.’ */
export type FacilityFilter = {
  /** Filter by the object’s `address` field. */
  address?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FacilityFilter>>;
  /** Filter by the object’s `bonusLtr` field. */
  bonusLtr?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<CountryFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `facilityContacts` field. */
  facilityContacts?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `latitude` field. */
  latitude?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `linkActive` field. */
  linkActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `longitude` field. */
  longitude?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `metricsOneActive` field. */
  metricsOneActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `nSight2Active` field. */
  nSight2Active?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `nSightActive` field. */
  nSightActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FacilityFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FacilityFilter>>;
  /** Filter by the object’s `preBonusEbitda` field. */
  preBonusEbitda?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `realTimeEnabled` field. */
  realTimeEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `suppresseFinancials` field. */
  suppresseFinancials?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `timezoneName` field. */
  timezoneName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unitGroup` field. */
  unitGroup?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `zip` field. */
  zip?: InputMaybe<StringFilter>;
};

/** A grouping of facilities. */
export type FacilityGroup = Node & {
  __typename?: 'FacilityGroup';
  /** Reads and enables pagination through a set of `Facility`. */
  allFacilities: FacilitiesConnection;
  /** Reads and enables pagination through a set of `FacilityGroup`. */
  childFacilityGroups: FacilityGroupsConnection;
  /** Reads and enables pagination through a set of `Facility`. */
  facilities: FacilityGroupFacilitiesManyToManyConnection;
  /** Reads and enables pagination through a set of `FacilityGroupsLinker`. */
  facilityGroupsLinkersByFacilityGroupingId: FacilityGroupsLinkersConnection;
  /** The facility group’s id. */
  id: Scalars['BigInt'];
  /** The facility group’s name. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroup`. */
  parent?: Maybe<FacilityGroup>;
  /** The id of the facility group’s parent node. */
  parentId?: Maybe<Scalars['BigInt']>;
};

/** A grouping of facilities. */
export type FacilityGroupAllFacilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A grouping of facilities. */
export type FacilityGroupChildFacilityGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupCondition>;
  filter?: InputMaybe<FacilityGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** A grouping of facilities. */
export type FacilityGroupFacilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** A grouping of facilities. */
export type FacilityGroupFacilityGroupsLinkersByFacilityGroupingIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupsLinkerCondition>;
  filter?: InputMaybe<FacilityGroupsLinkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

export type FacilityGroupAggregates = {
  __typename?: 'FacilityGroupAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FacilityGroupAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<FacilityGroupDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FacilityGroupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FacilityGroupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FacilityGroupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FacilityGroupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FacilityGroupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FacilityGroupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FacilityGroupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FacilityGroupVarianceSampleAggregates>;
};

export type FacilityGroupAverageAggregates = {
  __typename?: 'FacilityGroupAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FacilityGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FacilityGroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type FacilityGroupDistinctAggregates = {
  __typename?: 'FacilityGroupDistinctAggregates';
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /** Distinct of parentId across the matching connection */
  parentId?: Maybe<Scalars['JSON']>;
};

export type FacilityGroupDistinctCountAggregates = {
  __typename?: 'FacilityGroupDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `FacilityGroupsLinker`. */
export type FacilityGroupFacilitiesManyToManyConnection = {
  __typename?: 'FacilityGroupFacilitiesManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityAggregates>;
  /** A list of edges which contains the `Facility`, info from the `FacilityGroupsLinker`, and the cursor to aid in pagination. */
  edges: Array<FacilityGroupFacilitiesManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityAggregates>>;
  /** Aggregates a metric by label for the specified filter expression. */
  metricData?: Maybe<MetricDataConnection>;
  /** A list of `Facility` objects. */
  nodes: Array<Facility>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Facility` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Facility` values, with data from `FacilityGroupsLinker`. */
export type FacilityGroupFacilitiesManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FacilitiesGroupBy>;
  having?: InputMaybe<FacilitiesHavingInput>;
};

/** A connection to a list of `Facility` values, with data from `FacilityGroupsLinker`. */
export type FacilityGroupFacilitiesManyToManyConnectionMetricDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  aggregation?: MetricDataAggregationMethod;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  samplingWindow?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  unitGroup?: InputMaybe<Scalars['String']>;
  window?: Scalars['String'];
};

/** A `Facility` edge in the connection, with data from `FacilityGroupsLinker`. */
export type FacilityGroupFacilitiesManyToManyEdge = {
  __typename?: 'FacilityGroupFacilitiesManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Facility` at the end of the edge. */
  node: Facility;
};

/** A filter to be used against `FacilityGroup` object types. All fields are combined with a logical ‘and.’ */
export type FacilityGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FacilityGroupFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FacilityGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FacilityGroupFilter>>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<BigIntFilter>;
};

/** An input for mutations affecting `FacilityGroup` */
export type FacilityGroupInput = {
  /** The facility group’s name. */
  name: Scalars['String'];
  /** The id of the facility group’s parent node. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type FacilityGroupMaxAggregates = {
  __typename?: 'FacilityGroupMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type FacilityGroupMinAggregates = {
  __typename?: 'FacilityGroupMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `FacilityGroup`. Fields that are set will be updated. */
export type FacilityGroupPatch = {
  /** The facility group’s name. */
  name?: InputMaybe<Scalars['String']>;
  /** The id of the facility group’s parent node. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type FacilityGroupStddevPopulationAggregates = {
  __typename?: 'FacilityGroupStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupStddevSampleAggregates = {
  __typename?: 'FacilityGroupStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupSumAggregates = {
  __typename?: 'FacilityGroupSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigFloat'];
};

export type FacilityGroupVariancePopulationAggregates = {
  __typename?: 'FacilityGroupVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupVarianceSampleAggregates = {
  __typename?: 'FacilityGroupVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FacilityGroup` values. */
export type FacilityGroupsConnection = {
  __typename?: 'FacilityGroupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityGroupAggregates>;
  /** A list of edges which contains the `FacilityGroup` and cursor to aid in pagination. */
  edges: Array<FacilityGroupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityGroupAggregates>>;
  /** A list of `FacilityGroup` objects. */
  nodes: Array<FacilityGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FacilityGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `FacilityGroup` values. */
export type FacilityGroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FacilityGroupsGroupBy>;
  having?: InputMaybe<FacilityGroupsHavingInput>;
};

/** A `FacilityGroup` edge in the connection. */
export type FacilityGroupsEdge = {
  __typename?: 'FacilityGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FacilityGroup` at the end of the edge. */
  node: FacilityGroup;
};

/** Grouping methods for `FacilityGroup` for usage during aggregation. */
export enum FacilityGroupsGroupBy {
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  PARENT_ID = 'PARENT_ID',
  PARENT_ID_DISTINCT = 'PARENT_ID_DISTINCT'
}

export type FacilityGroupsHavingAverageInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingDistinctCountInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingDistinctInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `FacilityGroup` aggregates. */
export type FacilityGroupsHavingInput = {
  AND?: InputMaybe<Array<FacilityGroupsHavingInput>>;
  OR?: InputMaybe<Array<FacilityGroupsHavingInput>>;
  average?: InputMaybe<FacilityGroupsHavingAverageInput>;
  distinct?: InputMaybe<FacilityGroupsHavingDistinctInput>;
  distinctCount?: InputMaybe<FacilityGroupsHavingDistinctCountInput>;
  max?: InputMaybe<FacilityGroupsHavingMaxInput>;
  min?: InputMaybe<FacilityGroupsHavingMinInput>;
  stddevPopulation?: InputMaybe<FacilityGroupsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FacilityGroupsHavingStddevSampleInput>;
  sum?: InputMaybe<FacilityGroupsHavingSumInput>;
  variancePopulation?: InputMaybe<FacilityGroupsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FacilityGroupsHavingVarianceSampleInput>;
};

export type FacilityGroupsHavingMaxInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingMinInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingStddevPopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingStddevSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingSumInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingVariancePopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsHavingVarianceSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FacilityGroupsLinker = Node & {
  __typename?: 'FacilityGroupsLinker';
  /** Reads a single `Facility` that is related to this `FacilityGroupsLinker`. */
  facility?: Maybe<Facility>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroupsLinker`. */
  facilityGrouping?: Maybe<FacilityGroup>;
  facilityGroupingId: Scalars['BigInt'];
  facilityId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type FacilityGroupsLinkerAggregates = {
  __typename?: 'FacilityGroupsLinkerAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FacilityGroupsLinkerAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<FacilityGroupsLinkerDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FacilityGroupsLinkerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FacilityGroupsLinkerMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FacilityGroupsLinkerMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FacilityGroupsLinkerStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FacilityGroupsLinkerStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FacilityGroupsLinkerSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FacilityGroupsLinkerVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FacilityGroupsLinkerVarianceSampleAggregates>;
};

export type FacilityGroupsLinkerAverageAggregates = {
  __typename?: 'FacilityGroupsLinkerAverageAggregates';
  /** Mean average of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FacilityGroupsLinker` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FacilityGroupsLinkerCondition = {
  /** Checks for equality with the object’s `facilityGroupingId` field. */
  facilityGroupingId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
};

export type FacilityGroupsLinkerDistinctAggregates = {
  __typename?: 'FacilityGroupsLinkerDistinctAggregates';
  /** Distinct of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
};

export type FacilityGroupsLinkerDistinctCountAggregates = {
  __typename?: 'FacilityGroupsLinkerDistinctCountAggregates';
  /** Distinct count of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `FacilityGroupsLinker` object types. All fields are combined with a logical ‘and.’ */
export type FacilityGroupsLinkerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FacilityGroupsLinkerFilter>>;
  /** Filter by the object’s `facilityGroupingId` field. */
  facilityGroupingId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FacilityGroupsLinkerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FacilityGroupsLinkerFilter>>;
};

/** Grouping methods for `FacilityGroupsLinker` for usage during aggregation. */
export enum FacilityGroupsLinkerGroupBy {
  FACILITY_GROUPING_ID = 'FACILITY_GROUPING_ID',
  FACILITY_GROUPING_ID_DISTINCT = 'FACILITY_GROUPING_ID_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT'
}

export type FacilityGroupsLinkerHavingAverageInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingDistinctCountInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingDistinctInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `FacilityGroupsLinker` aggregates. */
export type FacilityGroupsLinkerHavingInput = {
  AND?: InputMaybe<Array<FacilityGroupsLinkerHavingInput>>;
  OR?: InputMaybe<Array<FacilityGroupsLinkerHavingInput>>;
  average?: InputMaybe<FacilityGroupsLinkerHavingAverageInput>;
  distinct?: InputMaybe<FacilityGroupsLinkerHavingDistinctInput>;
  distinctCount?: InputMaybe<FacilityGroupsLinkerHavingDistinctCountInput>;
  max?: InputMaybe<FacilityGroupsLinkerHavingMaxInput>;
  min?: InputMaybe<FacilityGroupsLinkerHavingMinInput>;
  stddevPopulation?: InputMaybe<FacilityGroupsLinkerHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FacilityGroupsLinkerHavingStddevSampleInput>;
  sum?: InputMaybe<FacilityGroupsLinkerHavingSumInput>;
  variancePopulation?: InputMaybe<FacilityGroupsLinkerHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FacilityGroupsLinkerHavingVarianceSampleInput>;
};

export type FacilityGroupsLinkerHavingMaxInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingMinInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingStddevPopulationInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingStddevSampleInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingSumInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingVariancePopulationInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

export type FacilityGroupsLinkerHavingVarianceSampleInput = {
  facilityGroupingId?: InputMaybe<HavingBigintFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
};

/** An input for mutations affecting `FacilityGroupsLinker` */
export type FacilityGroupsLinkerInput = {
  /** Mutates a `FacilityGroup` as part of the payload */
  facilityGroup?: InputMaybe<FacilityGroupInput>;
  facilityGroupingId?: InputMaybe<Scalars['BigInt']>;
  facilityId: Scalars['Int'];
};

export type FacilityGroupsLinkerMaxAggregates = {
  __typename?: 'FacilityGroupsLinkerMaxAggregates';
  /** Maximum of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigInt']>;
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
};

export type FacilityGroupsLinkerMinAggregates = {
  __typename?: 'FacilityGroupsLinkerMinAggregates';
  /** Minimum of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigInt']>;
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `FacilityGroupsLinker`. Fields that are set will be updated. */
export type FacilityGroupsLinkerPatch = {
  facilityGroupingId?: InputMaybe<Scalars['BigInt']>;
  facilityId?: InputMaybe<Scalars['Int']>;
};

export type FacilityGroupsLinkerStddevPopulationAggregates = {
  __typename?: 'FacilityGroupsLinkerStddevPopulationAggregates';
  /** Population standard deviation of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupsLinkerStddevSampleAggregates = {
  __typename?: 'FacilityGroupsLinkerStddevSampleAggregates';
  /** Sample standard deviation of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupsLinkerSumAggregates = {
  __typename?: 'FacilityGroupsLinkerSumAggregates';
  /** Sum of facilityGroupingId across the matching connection */
  facilityGroupingId: Scalars['BigFloat'];
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
};

export type FacilityGroupsLinkerVariancePopulationAggregates = {
  __typename?: 'FacilityGroupsLinkerVariancePopulationAggregates';
  /** Population variance of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
};

export type FacilityGroupsLinkerVarianceSampleAggregates = {
  __typename?: 'FacilityGroupsLinkerVarianceSampleAggregates';
  /** Sample variance of facilityGroupingId across the matching connection */
  facilityGroupingId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FacilityGroupsLinker` values. */
export type FacilityGroupsLinkersConnection = {
  __typename?: 'FacilityGroupsLinkersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FacilityGroupsLinkerAggregates>;
  /** A list of edges which contains the `FacilityGroupsLinker` and cursor to aid in pagination. */
  edges: Array<FacilityGroupsLinkersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FacilityGroupsLinkerAggregates>>;
  /** A list of `FacilityGroupsLinker` objects. */
  nodes: Array<FacilityGroupsLinker>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FacilityGroupsLinker` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `FacilityGroupsLinker` values. */
export type FacilityGroupsLinkersConnectionGroupedAggregatesArgs = {
  groupBy: Array<FacilityGroupsLinkerGroupBy>;
  having?: InputMaybe<FacilityGroupsLinkerHavingInput>;
};

/** A `FacilityGroupsLinker` edge in the connection. */
export type FacilityGroupsLinkersEdge = {
  __typename?: 'FacilityGroupsLinkersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FacilityGroupsLinker` at the end of the edge. */
  node: FacilityGroupsLinker;
};

/** Methods to use when ordering `FacilityGroupsLinker`. */
export enum FacilityGroupsLinkersOrderBy {
  FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPING_ID_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `FacilityGroup`. */
export enum FacilityGroupsOrderBy {
  CHILD_FACILITY_GROUPS_AVERAGE_ID_ASC = 'CHILD_FACILITY_GROUPS_AVERAGE_ID_ASC',
  CHILD_FACILITY_GROUPS_AVERAGE_ID_DESC = 'CHILD_FACILITY_GROUPS_AVERAGE_ID_DESC',
  CHILD_FACILITY_GROUPS_AVERAGE_NAME_ASC = 'CHILD_FACILITY_GROUPS_AVERAGE_NAME_ASC',
  CHILD_FACILITY_GROUPS_AVERAGE_NAME_DESC = 'CHILD_FACILITY_GROUPS_AVERAGE_NAME_DESC',
  CHILD_FACILITY_GROUPS_AVERAGE_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_AVERAGE_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_AVERAGE_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_AVERAGE_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_COUNT_ASC = 'CHILD_FACILITY_GROUPS_COUNT_ASC',
  CHILD_FACILITY_GROUPS_COUNT_DESC = 'CHILD_FACILITY_GROUPS_COUNT_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_ID_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_ID_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_ID_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_ID_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_NAME_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_NAME_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_NAME_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_NAME_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_COUNT_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_COUNT_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_ID_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_ID_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_ID_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_ID_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_NAME_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_NAME_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_NAME_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_NAME_DESC',
  CHILD_FACILITY_GROUPS_DISTINCT_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_DISTINCT_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_DISTINCT_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_DISTINCT_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_MAX_ID_ASC = 'CHILD_FACILITY_GROUPS_MAX_ID_ASC',
  CHILD_FACILITY_GROUPS_MAX_ID_DESC = 'CHILD_FACILITY_GROUPS_MAX_ID_DESC',
  CHILD_FACILITY_GROUPS_MAX_NAME_ASC = 'CHILD_FACILITY_GROUPS_MAX_NAME_ASC',
  CHILD_FACILITY_GROUPS_MAX_NAME_DESC = 'CHILD_FACILITY_GROUPS_MAX_NAME_DESC',
  CHILD_FACILITY_GROUPS_MAX_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_MAX_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_MAX_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_MAX_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_MIN_ID_ASC = 'CHILD_FACILITY_GROUPS_MIN_ID_ASC',
  CHILD_FACILITY_GROUPS_MIN_ID_DESC = 'CHILD_FACILITY_GROUPS_MIN_ID_DESC',
  CHILD_FACILITY_GROUPS_MIN_NAME_ASC = 'CHILD_FACILITY_GROUPS_MIN_NAME_ASC',
  CHILD_FACILITY_GROUPS_MIN_NAME_DESC = 'CHILD_FACILITY_GROUPS_MIN_NAME_DESC',
  CHILD_FACILITY_GROUPS_MIN_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_MIN_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_MIN_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_MIN_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_ID_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_ID_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_ID_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_ID_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_NAME_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_NAME_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_NAME_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_NAME_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_POPULATION_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_POPULATION_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_ID_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_ID_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_ID_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_ID_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_NAME_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_NAME_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_NAME_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_NAME_DESC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_STDDEV_SAMPLE_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_SUM_ID_ASC = 'CHILD_FACILITY_GROUPS_SUM_ID_ASC',
  CHILD_FACILITY_GROUPS_SUM_ID_DESC = 'CHILD_FACILITY_GROUPS_SUM_ID_DESC',
  CHILD_FACILITY_GROUPS_SUM_NAME_ASC = 'CHILD_FACILITY_GROUPS_SUM_NAME_ASC',
  CHILD_FACILITY_GROUPS_SUM_NAME_DESC = 'CHILD_FACILITY_GROUPS_SUM_NAME_DESC',
  CHILD_FACILITY_GROUPS_SUM_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_SUM_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_SUM_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_SUM_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_ID_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_ID_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_ID_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_ID_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_NAME_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_NAME_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_NAME_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_NAME_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_POPULATION_PARENT_ID_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_ID_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_ID_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_ID_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_ID_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_NAME_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_NAME_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_NAME_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_NAME_DESC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_PARENT_ID_ASC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_PARENT_ID_DESC = 'CHILD_FACILITY_GROUPS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_AVERAGE_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_COUNT_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_COUNT_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_COUNT_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_COUNT_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_COUNT_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_DISTINCT_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MAX_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_MIN_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_POPULATION_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_STDDEV_SAMPLE_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_SUM_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_POPULATION_FACILITY_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_GROUPING_ID_DESC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'FACILITY_GROUPS_LINKERS_BY_FACILITY_GROUPING_ID_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PARENT_ID_ASC = 'PARENT_ID_ASC',
  PARENT_ID_DESC = 'PARENT_ID_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Facility` */
export type FacilityInput = {
  /** The facility’s street address. */
  address?: InputMaybe<Scalars['String']>;
  /** Bonus LTR */
  bonusLtr?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s city. */
  city?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['JSON']>;
  country: Country;
  /** The time this facility was created. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User contacts for facility */
  facilityContacts?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the facility. */
  id?: InputMaybe<Scalars['Int']>;
  /** Facility Latitude */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Link Active */
  linkActive?: InputMaybe<Scalars['Boolean']>;
  /** Facility Longitude */
  longitude?: InputMaybe<Scalars['Float']>;
  /** metricsOne Active */
  metricsOneActive?: InputMaybe<Scalars['Boolean']>;
  /** Enabled in nSight. */
  nSight2Active?: InputMaybe<Scalars['Boolean']>;
  /** nSight Active */
  nSightActive?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s name. */
  name: Scalars['String'];
  /** The note of Facility */
  note?: InputMaybe<Scalars['String']>;
  /** Pre-Bonus EBITDA */
  preBonusEbitda?: InputMaybe<Scalars['Boolean']>;
  /** Flag to use real-time data or just utility data */
  realTimeEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s slug. */
  slug: Scalars['String'];
  /** The facility’s state. */
  state?: InputMaybe<Scalars['String']>;
  /** Suppresse Financials */
  suppresseFinancials?: InputMaybe<Scalars['Boolean']>;
  /** The name of the timezone for the facility. */
  timezoneName?: InputMaybe<Scalars['String']>;
  /** The unit grouping of the facility. */
  unitGroup?: InputMaybe<Scalars['String']>;
  /** The time this facility was updated. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The facility’s zip code. */
  zip?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `MachineUser` values, with data from `Component`. */
export type FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyConnection =
  {
    __typename?: 'FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<MachineUserAggregates>;
    /** A list of edges which contains the `MachineUser`, info from the `Component`, and the cursor to aid in pagination. */
    edges: Array<FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<MachineUserAggregates>>;
    /** A list of `MachineUser` objects. */
    nodes: Array<MachineUser>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `MachineUser` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `MachineUser` values, with data from `Component`. */
export type FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<MachineUsersGroupBy>;
    having?: InputMaybe<MachineUsersHavingInput>;
  };

/** A `MachineUser` edge in the connection, with data from `Component`. */
export type FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyEdge =
  {
    __typename?: 'FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyEdge';
    /** Reads and enables pagination through a set of `Component`. */
    components: ComponentsConnection;
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `MachineUser` at the end of the edge. */
    node: MachineUser;
  };

/** A `MachineUser` edge in the connection, with data from `Component`. */
export type FacilityMachineUsersByComponentFacilityIdAndMachineUserIdManyToManyEdgeComponentsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<ComponentCondition>;
    filter?: InputMaybe<ComponentFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
  };

export type FacilityMaxAggregates = {
  __typename?: 'FacilityMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Maximum of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

export type FacilityMinAggregates = {
  __typename?: 'FacilityMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Minimum of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

/** Represents an update to a `Facility`. Fields that are set will be updated. */
export type FacilityPatch = {
  /** The facility’s street address. */
  address?: InputMaybe<Scalars['String']>;
  /** Bonus LTR */
  bonusLtr?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s city. */
  city?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Country>;
  /** The time this facility was created. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User contacts for facility */
  facilityContacts?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the facility. */
  id?: InputMaybe<Scalars['Int']>;
  /** Facility Latitude */
  latitude?: InputMaybe<Scalars['Float']>;
  /** Link Active */
  linkActive?: InputMaybe<Scalars['Boolean']>;
  /** Facility Longitude */
  longitude?: InputMaybe<Scalars['Float']>;
  /** metricsOne Active */
  metricsOneActive?: InputMaybe<Scalars['Boolean']>;
  /** Enabled in nSight. */
  nSight2Active?: InputMaybe<Scalars['Boolean']>;
  /** nSight Active */
  nSightActive?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s name. */
  name?: InputMaybe<Scalars['String']>;
  /** The note of Facility */
  note?: InputMaybe<Scalars['String']>;
  /** Pre-Bonus EBITDA */
  preBonusEbitda?: InputMaybe<Scalars['Boolean']>;
  /** Flag to use real-time data or just utility data */
  realTimeEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The facility’s slug. */
  slug?: InputMaybe<Scalars['String']>;
  /** The facility’s state. */
  state?: InputMaybe<Scalars['String']>;
  /** Suppresse Financials */
  suppresseFinancials?: InputMaybe<Scalars['Boolean']>;
  /** The name of the timezone for the facility. */
  timezoneName?: InputMaybe<Scalars['String']>;
  /** The unit grouping of the facility. */
  unitGroup?: InputMaybe<Scalars['String']>;
  /** The time this facility was updated. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The facility’s zip code. */
  zip?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `ProjectType` values, with data from `Project`. */
export type FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyConnection =
  {
    __typename?: 'FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<ProjectTypeAggregates>;
    /** A list of edges which contains the `ProjectType`, info from the `Project`, and the cursor to aid in pagination. */
    edges: Array<FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<ProjectTypeAggregates>>;
    /** A list of `ProjectType` objects. */
    nodes: Array<ProjectType>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `ProjectType` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `ProjectType` values, with data from `Project`. */
export type FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<ProjectTypesGroupBy>;
    having?: InputMaybe<ProjectTypesHavingInput>;
  };

/** A `ProjectType` edge in the connection, with data from `Project`. */
export type FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyEdge =
  {
    __typename?: 'FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `ProjectType` at the end of the edge. */
    node: ProjectType;
    /** Reads and enables pagination through a set of `Project`. */
    projects: ProjectsConnection;
  };

/** A `ProjectType` edge in the connection, with data from `Project`. */
export type FacilityProjectTypesByProjectFacilityIdAndProjectTypeIdManyToManyEdgeProjectsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<ProjectCondition>;
    filter?: InputMaybe<ProjectFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  };

export type FacilityStddevPopulationAggregates = {
  __typename?: 'FacilityStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Population standard deviation of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

export type FacilityStddevSampleAggregates = {
  __typename?: 'FacilityStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

export type FacilitySumAggregates = {
  __typename?: 'FacilitySumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of latitude across the matching connection */
  latitude: Scalars['Float'];
  /** Sum of longitude across the matching connection */
  longitude: Scalars['Float'];
};

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeter`. */
export type FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeter`, and the cursor to aid in pagination. */
    edges: Array<FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeter`. */
export type FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeter`. */
export type FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeter`. */
    utilityMeters: UtilityMetersConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeter`. */
export type FacilityUtilityAccountsByUtilityMeterFacilityIdAndUtilityAccountIdManyToManyEdgeUtilityMetersArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityUtilityAccountsByUtilityMeterStatementFacilityIdAndUtilityAccountIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityAccountsByUtilityMeterStatementRollupFacilityIdAndUtilityAccountIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type FacilityUtilityMetersByUtilityMeterStatementFacilityIdAndUtilityMeterIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type FacilityUtilityMetersByUtilityMeterStatementRollupFacilityIdAndUtilityMeterIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

export type FacilityVariancePopulationAggregates = {
  __typename?: 'FacilityVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Population variance of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

export type FacilityVarianceSampleAggregates = {
  __typename?: 'FacilityVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of latitude across the matching connection */
  latitude?: Maybe<Scalars['Float']>;
  /** Sample variance of longitude across the matching connection */
  longitude?: Maybe<Scalars['Float']>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

/** A `JSON` edge in the connection. */
export type GroupingEdge = {
  __typename?: 'GroupingEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `JSON` at the end of the edge. */
  node?: Maybe<Scalars['JSON']>;
};

/** A connection to a list of `JSON` values. */
export type GroupingsConnection = {
  __typename?: 'GroupingsConnection';
  /** A list of edges which contains the `JSON` and cursor to aid in pagination. */
  edges: Array<GroupingEdge>;
  /** A list of `JSON` objects. */
  nodes: Array<Maybe<Scalars['JSON']>>;
  /** The count of *all* `JSON` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `JSON` values. */
export type GroupingsWithFacilitiesConnection = {
  __typename?: 'GroupingsWithFacilitiesConnection';
  /** A list of edges which contains the `JSON` and cursor to aid in pagination. */
  edges: Array<GroupingsWithFacilityEdge>;
  /** A list of `JSON` objects. */
  nodes: Array<Maybe<Scalars['JSON']>>;
  /** The count of *all* `JSON` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `JSON` edge in the connection. */
export type GroupingsWithFacilityEdge = {
  __typename?: 'GroupingsWithFacilityEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `JSON` at the end of the edge. */
  node?: Maybe<Scalars['JSON']>;
};

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars['BigInt']>;
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  lessThan?: InputMaybe<Scalars['BigInt']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars['Datetime']>;
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  lessThan?: InputMaybe<Scalars['Datetime']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars['Float']>;
  greaterThan?: InputMaybe<Scalars['Float']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  lessThan?: InputMaybe<Scalars['Float']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  notEqualTo?: InputMaybe<Scalars['Float']>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars['Int']>;
  greaterThan?: InputMaybe<Scalars['Int']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  lessThan?: InputMaybe<Scalars['Int']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  notEqualTo?: InputMaybe<Scalars['Int']>;
};

export type Identity = {
  __typename?: 'Identity';
  authorizationType: Scalars['String'];
  id: Scalars['String'];
  type: AccessTokenType;
};

export type InputFacilityMetricData = {
  data: Scalars['JSON'];
  facilityId: Scalars['Int'];
  label: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  time: Scalars['Datetime'];
};

export type InputMetricData = {
  data: Scalars['JSON'];
  label: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  sourceId: Scalars['String'];
  time: Scalars['Datetime'];
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

/** A filter to be used against JSON List fields. All fields are combined with a logical ‘and.’ */
export type JsonListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['JSON']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['JSON']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
};

/** Machine accounts that have their own set of access/permissions. */
export type MachineUser = Node & {
  __typename?: 'MachineUser';
  /** Reads and enables pagination through a set of `PlatformAccessToken`. */
  accessTokens: PlatformAccessTokensConnection;
  /** Reads and enables pagination through a set of `Component`. */
  components: ComponentsConnection;
  /** The date the machine user was created at. */
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['String'];
  creator?: Maybe<User>;
  description: Scalars['String'];
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByComponentMachineUserIdAndFacilityId: MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyConnection;
  /** The identifier of the machine user. */
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Available permissions for the MachineUser. */
  permissions?: Maybe<MachineUserPermissions>;
  /** The date the machine user was updated at. */
  updatedAt: Scalars['Datetime'];
};

/** Machine accounts that have their own set of access/permissions. */
export type MachineUserAccessTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PlatformAccessTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Machine accounts that have their own set of access/permissions. */
export type MachineUserComponentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ComponentCondition>;
  filter?: InputMaybe<ComponentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** Machine accounts that have their own set of access/permissions. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

export type MachineUserAggregates = {
  __typename?: 'MachineUserAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MachineUserAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MachineUserDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MachineUserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MachineUserMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MachineUserMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MachineUserStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MachineUserStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MachineUserSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MachineUserVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MachineUserVarianceSampleAggregates>;
};

export type MachineUserAverageAggregates = {
  __typename?: 'MachineUserAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MachineUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MachineUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type MachineUserDistinctAggregates = {
  __typename?: 'MachineUserDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of createdBy across the matching connection */
  createdBy?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  creator?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type MachineUserDistinctCountAggregates = {
  __typename?: 'MachineUserDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  creator?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `Component`. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `Component`, and the cursor to aid in pagination. */
    edges: Array<MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `Component`. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `Component`. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `Component`. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyEdge';
    /** Reads and enables pagination through a set of `Component`. */
    components: ComponentsConnection;
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
  };

/** A `Facility` edge in the connection, with data from `Component`. */
export type MachineUserFacilitiesByComponentMachineUserIdAndFacilityIdManyToManyEdgeComponentsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<ComponentCondition>;
    filter?: InputMaybe<ComponentFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
  };

/** A filter to be used against `MachineUser` object types. All fields are combined with a logical ‘and.’ */
export type MachineUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MachineUserFilter>>;
  /** Filter by the permission can_write */
  canWrite?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MachineUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MachineUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `MachineUser` */
export type MachineUserInput = {
  /** The date the machine user was created at. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  /** The date the machine user was updated at. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type MachineUserMaxAggregates = {
  __typename?: 'MachineUserMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type MachineUserMinAggregates = {
  __typename?: 'MachineUserMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `MachineUser`. Fields that are set will be updated. */
export type MachineUserPatch = {
  /** The date the machine user was created at. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** The date the machine user was updated at. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Permissive capabilities on a MachineUser. */
export type MachineUserPermissions = {
  __typename?: 'MachineUserPermissions';
  /** Whether a user can edit a machine user */
  canWrite: Scalars['Boolean'];
};

export type MachineUserStddevPopulationAggregates = {
  __typename?: 'MachineUserStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MachineUserStddevSampleAggregates = {
  __typename?: 'MachineUserStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MachineUserSumAggregates = {
  __typename?: 'MachineUserSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type MachineUserVariancePopulationAggregates = {
  __typename?: 'MachineUserVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MachineUserVarianceSampleAggregates = {
  __typename?: 'MachineUserVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `MachineUser` values. */
export type MachineUsersConnection = {
  __typename?: 'MachineUsersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MachineUserAggregates>;
  /** A list of edges which contains the `MachineUser` and cursor to aid in pagination. */
  edges: Array<MachineUsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MachineUserAggregates>>;
  /** A list of `MachineUser` objects. */
  nodes: Array<MachineUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MachineUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MachineUser` values. */
export type MachineUsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<MachineUsersGroupBy>;
  having?: InputMaybe<MachineUsersHavingInput>;
};

/** A `MachineUser` edge in the connection. */
export type MachineUsersEdge = {
  __typename?: 'MachineUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MachineUser` at the end of the edge. */
  node: MachineUser;
};

/** Grouping methods for `MachineUser` for usage during aggregation. */
export enum MachineUsersGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CREATED_BY = 'CREATED_BY',
  CREATED_BY_DISTINCT = 'CREATED_BY_DISTINCT',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type MachineUsersHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MachineUser` aggregates. */
export type MachineUsersHavingInput = {
  AND?: InputMaybe<Array<MachineUsersHavingInput>>;
  OR?: InputMaybe<Array<MachineUsersHavingInput>>;
  average?: InputMaybe<MachineUsersHavingAverageInput>;
  distinct?: InputMaybe<MachineUsersHavingDistinctInput>;
  distinctCount?: InputMaybe<MachineUsersHavingDistinctCountInput>;
  max?: InputMaybe<MachineUsersHavingMaxInput>;
  min?: InputMaybe<MachineUsersHavingMinInput>;
  stddevPopulation?: InputMaybe<MachineUsersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MachineUsersHavingStddevSampleInput>;
  sum?: InputMaybe<MachineUsersHavingSumInput>;
  variancePopulation?: InputMaybe<MachineUsersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MachineUsersHavingVarianceSampleInput>;
};

export type MachineUsersHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MachineUsersHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `MachineUser`. */
export enum MachineUsersOrderBy {
  COMPONENTS_AVERAGE_CREATED_AT_ASC = 'COMPONENTS_AVERAGE_CREATED_AT_ASC',
  COMPONENTS_AVERAGE_CREATED_AT_DESC = 'COMPONENTS_AVERAGE_CREATED_AT_DESC',
  COMPONENTS_AVERAGE_FACILITY_ID_ASC = 'COMPONENTS_AVERAGE_FACILITY_ID_ASC',
  COMPONENTS_AVERAGE_FACILITY_ID_DESC = 'COMPONENTS_AVERAGE_FACILITY_ID_DESC',
  COMPONENTS_AVERAGE_ID_ASC = 'COMPONENTS_AVERAGE_ID_ASC',
  COMPONENTS_AVERAGE_ID_DESC = 'COMPONENTS_AVERAGE_ID_DESC',
  COMPONENTS_AVERAGE_MACHINE_USER_ID_ASC = 'COMPONENTS_AVERAGE_MACHINE_USER_ID_ASC',
  COMPONENTS_AVERAGE_MACHINE_USER_ID_DESC = 'COMPONENTS_AVERAGE_MACHINE_USER_ID_DESC',
  COMPONENTS_AVERAGE_NAME_ASC = 'COMPONENTS_AVERAGE_NAME_ASC',
  COMPONENTS_AVERAGE_NAME_DESC = 'COMPONENTS_AVERAGE_NAME_DESC',
  COMPONENTS_AVERAGE_OUT_OF_SERVICE_ASC = 'COMPONENTS_AVERAGE_OUT_OF_SERVICE_ASC',
  COMPONENTS_AVERAGE_OUT_OF_SERVICE_DESC = 'COMPONENTS_AVERAGE_OUT_OF_SERVICE_DESC',
  COMPONENTS_AVERAGE_UPDATED_AT_ASC = 'COMPONENTS_AVERAGE_UPDATED_AT_ASC',
  COMPONENTS_AVERAGE_UPDATED_AT_DESC = 'COMPONENTS_AVERAGE_UPDATED_AT_DESC',
  COMPONENTS_COUNT_ASC = 'COMPONENTS_COUNT_ASC',
  COMPONENTS_COUNT_DESC = 'COMPONENTS_COUNT_DESC',
  COMPONENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'COMPONENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  COMPONENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'COMPONENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  COMPONENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_ASC = 'COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_ASC',
  COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_DESC = 'COMPONENTS_DISTINCT_COUNT_MACHINE_USER_ID_DESC',
  COMPONENTS_DISTINCT_COUNT_NAME_ASC = 'COMPONENTS_DISTINCT_COUNT_NAME_ASC',
  COMPONENTS_DISTINCT_COUNT_NAME_DESC = 'COMPONENTS_DISTINCT_COUNT_NAME_DESC',
  COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_ASC = 'COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_ASC',
  COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_DESC = 'COMPONENTS_DISTINCT_COUNT_OUT_OF_SERVICE_DESC',
  COMPONENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'COMPONENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  COMPONENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'COMPONENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  COMPONENTS_DISTINCT_CREATED_AT_ASC = 'COMPONENTS_DISTINCT_CREATED_AT_ASC',
  COMPONENTS_DISTINCT_CREATED_AT_DESC = 'COMPONENTS_DISTINCT_CREATED_AT_DESC',
  COMPONENTS_DISTINCT_FACILITY_ID_ASC = 'COMPONENTS_DISTINCT_FACILITY_ID_ASC',
  COMPONENTS_DISTINCT_FACILITY_ID_DESC = 'COMPONENTS_DISTINCT_FACILITY_ID_DESC',
  COMPONENTS_DISTINCT_ID_ASC = 'COMPONENTS_DISTINCT_ID_ASC',
  COMPONENTS_DISTINCT_ID_DESC = 'COMPONENTS_DISTINCT_ID_DESC',
  COMPONENTS_DISTINCT_MACHINE_USER_ID_ASC = 'COMPONENTS_DISTINCT_MACHINE_USER_ID_ASC',
  COMPONENTS_DISTINCT_MACHINE_USER_ID_DESC = 'COMPONENTS_DISTINCT_MACHINE_USER_ID_DESC',
  COMPONENTS_DISTINCT_NAME_ASC = 'COMPONENTS_DISTINCT_NAME_ASC',
  COMPONENTS_DISTINCT_NAME_DESC = 'COMPONENTS_DISTINCT_NAME_DESC',
  COMPONENTS_DISTINCT_OUT_OF_SERVICE_ASC = 'COMPONENTS_DISTINCT_OUT_OF_SERVICE_ASC',
  COMPONENTS_DISTINCT_OUT_OF_SERVICE_DESC = 'COMPONENTS_DISTINCT_OUT_OF_SERVICE_DESC',
  COMPONENTS_DISTINCT_UPDATED_AT_ASC = 'COMPONENTS_DISTINCT_UPDATED_AT_ASC',
  COMPONENTS_DISTINCT_UPDATED_AT_DESC = 'COMPONENTS_DISTINCT_UPDATED_AT_DESC',
  COMPONENTS_MAX_CREATED_AT_ASC = 'COMPONENTS_MAX_CREATED_AT_ASC',
  COMPONENTS_MAX_CREATED_AT_DESC = 'COMPONENTS_MAX_CREATED_AT_DESC',
  COMPONENTS_MAX_FACILITY_ID_ASC = 'COMPONENTS_MAX_FACILITY_ID_ASC',
  COMPONENTS_MAX_FACILITY_ID_DESC = 'COMPONENTS_MAX_FACILITY_ID_DESC',
  COMPONENTS_MAX_ID_ASC = 'COMPONENTS_MAX_ID_ASC',
  COMPONENTS_MAX_ID_DESC = 'COMPONENTS_MAX_ID_DESC',
  COMPONENTS_MAX_MACHINE_USER_ID_ASC = 'COMPONENTS_MAX_MACHINE_USER_ID_ASC',
  COMPONENTS_MAX_MACHINE_USER_ID_DESC = 'COMPONENTS_MAX_MACHINE_USER_ID_DESC',
  COMPONENTS_MAX_NAME_ASC = 'COMPONENTS_MAX_NAME_ASC',
  COMPONENTS_MAX_NAME_DESC = 'COMPONENTS_MAX_NAME_DESC',
  COMPONENTS_MAX_OUT_OF_SERVICE_ASC = 'COMPONENTS_MAX_OUT_OF_SERVICE_ASC',
  COMPONENTS_MAX_OUT_OF_SERVICE_DESC = 'COMPONENTS_MAX_OUT_OF_SERVICE_DESC',
  COMPONENTS_MAX_UPDATED_AT_ASC = 'COMPONENTS_MAX_UPDATED_AT_ASC',
  COMPONENTS_MAX_UPDATED_AT_DESC = 'COMPONENTS_MAX_UPDATED_AT_DESC',
  COMPONENTS_MIN_CREATED_AT_ASC = 'COMPONENTS_MIN_CREATED_AT_ASC',
  COMPONENTS_MIN_CREATED_AT_DESC = 'COMPONENTS_MIN_CREATED_AT_DESC',
  COMPONENTS_MIN_FACILITY_ID_ASC = 'COMPONENTS_MIN_FACILITY_ID_ASC',
  COMPONENTS_MIN_FACILITY_ID_DESC = 'COMPONENTS_MIN_FACILITY_ID_DESC',
  COMPONENTS_MIN_ID_ASC = 'COMPONENTS_MIN_ID_ASC',
  COMPONENTS_MIN_ID_DESC = 'COMPONENTS_MIN_ID_DESC',
  COMPONENTS_MIN_MACHINE_USER_ID_ASC = 'COMPONENTS_MIN_MACHINE_USER_ID_ASC',
  COMPONENTS_MIN_MACHINE_USER_ID_DESC = 'COMPONENTS_MIN_MACHINE_USER_ID_DESC',
  COMPONENTS_MIN_NAME_ASC = 'COMPONENTS_MIN_NAME_ASC',
  COMPONENTS_MIN_NAME_DESC = 'COMPONENTS_MIN_NAME_DESC',
  COMPONENTS_MIN_OUT_OF_SERVICE_ASC = 'COMPONENTS_MIN_OUT_OF_SERVICE_ASC',
  COMPONENTS_MIN_OUT_OF_SERVICE_DESC = 'COMPONENTS_MIN_OUT_OF_SERVICE_DESC',
  COMPONENTS_MIN_UPDATED_AT_ASC = 'COMPONENTS_MIN_UPDATED_AT_ASC',
  COMPONENTS_MIN_UPDATED_AT_DESC = 'COMPONENTS_MIN_UPDATED_AT_DESC',
  COMPONENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'COMPONENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  COMPONENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'COMPONENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  COMPONENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_ASC = 'COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_ASC',
  COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_DESC = 'COMPONENTS_STDDEV_POPULATION_MACHINE_USER_ID_DESC',
  COMPONENTS_STDDEV_POPULATION_NAME_ASC = 'COMPONENTS_STDDEV_POPULATION_NAME_ASC',
  COMPONENTS_STDDEV_POPULATION_NAME_DESC = 'COMPONENTS_STDDEV_POPULATION_NAME_DESC',
  COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_ASC = 'COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_ASC',
  COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_DESC = 'COMPONENTS_STDDEV_POPULATION_OUT_OF_SERVICE_DESC',
  COMPONENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'COMPONENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  COMPONENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'COMPONENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  COMPONENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'COMPONENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  COMPONENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'COMPONENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_ASC = 'COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_ASC',
  COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_DESC = 'COMPONENTS_STDDEV_SAMPLE_MACHINE_USER_ID_DESC',
  COMPONENTS_STDDEV_SAMPLE_NAME_ASC = 'COMPONENTS_STDDEV_SAMPLE_NAME_ASC',
  COMPONENTS_STDDEV_SAMPLE_NAME_DESC = 'COMPONENTS_STDDEV_SAMPLE_NAME_DESC',
  COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_ASC = 'COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_ASC',
  COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_DESC = 'COMPONENTS_STDDEV_SAMPLE_OUT_OF_SERVICE_DESC',
  COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'COMPONENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  COMPONENTS_SUM_CREATED_AT_ASC = 'COMPONENTS_SUM_CREATED_AT_ASC',
  COMPONENTS_SUM_CREATED_AT_DESC = 'COMPONENTS_SUM_CREATED_AT_DESC',
  COMPONENTS_SUM_FACILITY_ID_ASC = 'COMPONENTS_SUM_FACILITY_ID_ASC',
  COMPONENTS_SUM_FACILITY_ID_DESC = 'COMPONENTS_SUM_FACILITY_ID_DESC',
  COMPONENTS_SUM_ID_ASC = 'COMPONENTS_SUM_ID_ASC',
  COMPONENTS_SUM_ID_DESC = 'COMPONENTS_SUM_ID_DESC',
  COMPONENTS_SUM_MACHINE_USER_ID_ASC = 'COMPONENTS_SUM_MACHINE_USER_ID_ASC',
  COMPONENTS_SUM_MACHINE_USER_ID_DESC = 'COMPONENTS_SUM_MACHINE_USER_ID_DESC',
  COMPONENTS_SUM_NAME_ASC = 'COMPONENTS_SUM_NAME_ASC',
  COMPONENTS_SUM_NAME_DESC = 'COMPONENTS_SUM_NAME_DESC',
  COMPONENTS_SUM_OUT_OF_SERVICE_ASC = 'COMPONENTS_SUM_OUT_OF_SERVICE_ASC',
  COMPONENTS_SUM_OUT_OF_SERVICE_DESC = 'COMPONENTS_SUM_OUT_OF_SERVICE_DESC',
  COMPONENTS_SUM_UPDATED_AT_ASC = 'COMPONENTS_SUM_UPDATED_AT_ASC',
  COMPONENTS_SUM_UPDATED_AT_DESC = 'COMPONENTS_SUM_UPDATED_AT_DESC',
  COMPONENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'COMPONENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  COMPONENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'COMPONENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_ASC = 'COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_ASC',
  COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_DESC = 'COMPONENTS_VARIANCE_POPULATION_MACHINE_USER_ID_DESC',
  COMPONENTS_VARIANCE_POPULATION_NAME_ASC = 'COMPONENTS_VARIANCE_POPULATION_NAME_ASC',
  COMPONENTS_VARIANCE_POPULATION_NAME_DESC = 'COMPONENTS_VARIANCE_POPULATION_NAME_DESC',
  COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_ASC = 'COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_ASC',
  COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_DESC = 'COMPONENTS_VARIANCE_POPULATION_OUT_OF_SERVICE_DESC',
  COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'COMPONENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'COMPONENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_ASC = 'COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_ASC',
  COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_DESC = 'COMPONENTS_VARIANCE_SAMPLE_MACHINE_USER_ID_DESC',
  COMPONENTS_VARIANCE_SAMPLE_NAME_ASC = 'COMPONENTS_VARIANCE_SAMPLE_NAME_ASC',
  COMPONENTS_VARIANCE_SAMPLE_NAME_DESC = 'COMPONENTS_VARIANCE_SAMPLE_NAME_DESC',
  COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_ASC = 'COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_ASC',
  COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_DESC = 'COMPONENTS_VARIANCE_SAMPLE_OUT_OF_SERVICE_DESC',
  COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'COMPONENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_BY_ASC = 'CREATED_BY_ASC',
  CREATED_BY_DESC = 'CREATED_BY_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type MeterCharge = Node & {
  __typename?: 'MeterCharge';
  /** The category of the charge. */
  category: UtilityMeterChargeCategory;
  /** The time this charge was created. */
  createdAt: Scalars['Datetime'];
  /** The time the charge was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** This is set by the extraction process. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present. */
  lineNumber: Scalars['Int'];
  /** Reads a single `MeterStatement` that is related to this `MeterCharge`. */
  meterStatement?: Maybe<MeterStatement>;
  meterStatementId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** The time this charge was updated. */
  updatedAt: Scalars['Datetime'];
  /** The value of the charge. */
  value: Scalars['Float'];
};

export type MeterChargeAggregates = {
  __typename?: 'MeterChargeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MeterChargeAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MeterChargeDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeterChargeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MeterChargeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MeterChargeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MeterChargeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MeterChargeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MeterChargeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MeterChargeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MeterChargeVarianceSampleAggregates>;
};

export type MeterChargeAverageAggregates = {
  __typename?: 'MeterChargeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Mean average of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Mean average of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Mean average of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `MeterCharge` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MeterChargeCondition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<UtilityMeterChargeCategory>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `normalizedValue` field. */
  normalizedValue?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['Float']>;
};

export type MeterChargeDistinctAggregates = {
  __typename?: 'MeterChargeDistinctAggregates';
  /** Distinct of category across the matching connection */
  category?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of label across the matching connection */
  label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of lineNumber across the matching connection */
  lineNumber?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Distinct of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideValue across the matching connection */
  overrideValue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of value across the matching connection */
  value?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type MeterChargeDistinctCountAggregates = {
  __typename?: 'MeterChargeDistinctCountAggregates';
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MeterCharge` object types. All fields are combined with a logical ‘and.’ */
export type MeterChargeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterChargeFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UtilityMeterChargeCategoryFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `normalizedValue` field. */
  normalizedValue?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterChargeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterChargeFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

/** An input for mutations affecting `MeterCharge` */
export type MeterChargeInput = {
  /** The category of the charge. */
  category: UtilityMeterChargeCategory;
  id?: InputMaybe<Scalars['Int']>;
  /** This is set by the extraction process. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present. */
  lineNumber?: InputMaybe<Scalars['Int']>;
  /** Mutates a `MeterStatement` as part of the payload */
  meterStatement?: InputMaybe<MeterStatementInput>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** The value of the charge. */
  value: Scalars['Float'];
};

export type MeterChargeMaxAggregates = {
  __typename?: 'MeterChargeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Maximum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Maximum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Maximum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterChargeMinAggregates = {
  __typename?: 'MeterChargeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Minimum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Minimum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Minimum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** Represents an update to a `MeterCharge`. Fields that are set will be updated. */
export type MeterChargePatch = {
  /** Overrides the label. */
  label?: InputMaybe<Scalars['String']>;
  /** Overrides the value. */
  value?: InputMaybe<Scalars['Float']>;
};

export type MeterChargeStddevPopulationAggregates = {
  __typename?: 'MeterChargeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterChargeStddevSampleAggregates = {
  __typename?: 'MeterChargeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterChargeSumAggregates = {
  __typename?: 'MeterChargeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars['BigInt'];
  /** Sum of meterStatementId across the matching connection */
  meterStatementId: Scalars['BigInt'];
  /** Sum of normalizedValue across the matching connection */
  normalizedValue: Scalars['Float'];
  /** Sum of overrideValue across the matching connection */
  overrideValue: Scalars['Float'];
  /** Sum of value across the matching connection */
  value: Scalars['Float'];
};

export type MeterChargeVariancePopulationAggregates = {
  __typename?: 'MeterChargeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Population variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterChargeVarianceSampleAggregates = {
  __typename?: 'MeterChargeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of normalizedValue across the matching connection */
  normalizedValue?: Maybe<Scalars['Float']>;
  /** Sample variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `MeterCharge` values. */
export type MeterChargesConnection = {
  __typename?: 'MeterChargesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeterChargeAggregates>;
  /** A list of edges which contains the `MeterCharge` and cursor to aid in pagination. */
  edges: Array<MeterChargesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeterChargeAggregates>>;
  /** A list of `MeterCharge` objects. */
  nodes: Array<MeterCharge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeterCharge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MeterCharge` values. */
export type MeterChargesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeterChargesGroupBy>;
  having?: InputMaybe<MeterChargesHavingInput>;
};

/** A `MeterCharge` edge in the connection. */
export type MeterChargesEdge = {
  __typename?: 'MeterChargesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterCharge` at the end of the edge. */
  node: MeterCharge;
};

/** Grouping methods for `MeterCharge` for usage during aggregation. */
export enum MeterChargesGroupBy {
  CATEGORY = 'CATEGORY',
  CATEGORY_DISTINCT = 'CATEGORY_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  LABEL = 'LABEL',
  LABEL_DISTINCT = 'LABEL_DISTINCT',
  LINE_NUMBER = 'LINE_NUMBER',
  LINE_NUMBER_DISTINCT = 'LINE_NUMBER_DISTINCT',
  METER_STATEMENT_ID = 'METER_STATEMENT_ID',
  METER_STATEMENT_ID_DISTINCT = 'METER_STATEMENT_ID_DISTINCT',
  NORMALIZED_VALUE = 'NORMALIZED_VALUE',
  NORMALIZED_VALUE_DISTINCT = 'NORMALIZED_VALUE_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_LABEL = 'OVERRIDE_LABEL',
  OVERRIDE_LABEL_DISTINCT = 'OVERRIDE_LABEL_DISTINCT',
  OVERRIDE_VALUE = 'OVERRIDE_VALUE',
  OVERRIDE_VALUE_DISTINCT = 'OVERRIDE_VALUE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  VALUE = 'VALUE',
  VALUE_DISTINCT = 'VALUE_DISTINCT'
}

export type MeterChargesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `MeterCharge` aggregates. */
export type MeterChargesHavingInput = {
  AND?: InputMaybe<Array<MeterChargesHavingInput>>;
  OR?: InputMaybe<Array<MeterChargesHavingInput>>;
  average?: InputMaybe<MeterChargesHavingAverageInput>;
  distinct?: InputMaybe<MeterChargesHavingDistinctInput>;
  distinctCount?: InputMaybe<MeterChargesHavingDistinctCountInput>;
  max?: InputMaybe<MeterChargesHavingMaxInput>;
  min?: InputMaybe<MeterChargesHavingMinInput>;
  stddevPopulation?: InputMaybe<MeterChargesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeterChargesHavingStddevSampleInput>;
  sum?: InputMaybe<MeterChargesHavingSumInput>;
  variancePopulation?: InputMaybe<MeterChargesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeterChargesHavingVarianceSampleInput>;
};

export type MeterChargesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterChargesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  normalizedValue?: InputMaybe<HavingFloatFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Methods to use when ordering `MeterCharge`. */
export enum MeterChargesOrderBy {
  CATEGORY_ASC = 'CATEGORY_ASC',
  CATEGORY_DESC = 'CATEGORY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  LABEL_ASC = 'LABEL_ASC',
  LABEL_DESC = 'LABEL_DESC',
  LINE_NUMBER_ASC = 'LINE_NUMBER_ASC',
  LINE_NUMBER_DESC = 'LINE_NUMBER_DESC',
  METER_STATEMENT_ID_ASC = 'METER_STATEMENT_ID_ASC',
  METER_STATEMENT_ID_DESC = 'METER_STATEMENT_ID_DESC',
  NATURAL = 'NATURAL',
  NORMALIZED_VALUE_ASC = 'NORMALIZED_VALUE_ASC',
  NORMALIZED_VALUE_DESC = 'NORMALIZED_VALUE_DESC',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_LABEL_ASC = 'OVERRIDE_LABEL_ASC',
  OVERRIDE_LABEL_DESC = 'OVERRIDE_LABEL_DESC',
  OVERRIDE_VALUE_ASC = 'OVERRIDE_VALUE_ASC',
  OVERRIDE_VALUE_DESC = 'OVERRIDE_VALUE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC'
}

export type MeterStatement = Node & {
  __typename?: 'MeterStatement';
  /** Reads a single `AccountStatement` that is related to this `MeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId: Scalars['Int'];
  charges: MeterStatementsChargesConnection;
  /** The time this statement was created. */
  createdAt: Scalars['Datetime'];
  /** The original currency code. */
  currencyCode: Scalars['String'];
  /** The time the statement was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  integrationKey?: Maybe<Scalars['String']>;
  /** The end interval of the meter statement. */
  intervalEnd: Scalars['Date'];
  /** The start interval of the meter statement. */
  intervalStart: Scalars['Date'];
  isAdjustment: Scalars['Boolean'];
  isValidated: Scalars['Boolean'];
  manualUpload: Scalars['Boolean'];
  maxDemand?: Maybe<Scalars['Float']>;
  metadata: Scalars['JSON'];
  /** Reads and enables pagination through a set of `MeterCharge`. */
  meterCharges: MeterChargesConnection;
  /** Reads and enables pagination through a set of `MeterStatementHistory`. */
  meterStatementHistories: MeterStatementHistoriesConnection;
  /** Reads and enables pagination through a set of `MeterUsage`. */
  meterUsages: MeterUsagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  normalizedCharges: MeterStatementsNormalizedChargesConnection;
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** This overrides the primary demand units. */
  overrideDemandUnits?: Maybe<Scalars['String']>;
  /** Overrides the normalized month of the account_statement. */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Overrides the normalized year of the account_statement. */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  /** This overrides the primary usage units. */
  overrideUsageUnits?: Maybe<Scalars['String']>;
  pdfLink?: Maybe<Scalars['String']>;
  primaryDemandUnits?: Maybe<Scalars['String']>;
  primaryUsageUnits?: Maybe<Scalars['String']>;
  /** The date the statement was issued on. */
  statementDate: Scalars['Date'];
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  totalCharges?: Maybe<Scalars['Float']>;
  totalUsage?: Maybe<Scalars['Float']>;
  /** The time this statement was updated. */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UtilityMeter` that is related to this `MeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
  utilityMeterId: Scalars['Int'];
  /** Reads a single `UtilityMeterStatement` that is related to this `MeterStatement`. */
  utilityMeterStatement?: Maybe<UtilityMeterStatement>;
  /** Reads a single `UtilityMeterStatementRollup` that is related to this `MeterStatement`. */
  utilityMeterStatementRollup?: Maybe<UtilityMeterStatementRollup>;
};

export type MeterStatementChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MeterStatementsChargesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementMeterChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterChargeCondition>;
  filter?: InputMaybe<MeterChargeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterChargesOrderBy>>;
};

export type MeterStatementMeterStatementHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterStatementHistoryCondition>;
  filter?: InputMaybe<MeterStatementHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterStatementHistoriesOrderBy>>;
};

export type MeterStatementMeterUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterUsageCondition>;
  filter?: InputMaybe<MeterUsageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterUsagesOrderBy>>;
};

export type MeterStatementNormalizedChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MeterStatementsNormalizedChargesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementAggregates = {
  __typename?: 'MeterStatementAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MeterStatementAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MeterStatementDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeterStatementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MeterStatementMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MeterStatementMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MeterStatementStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MeterStatementStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MeterStatementSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MeterStatementVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MeterStatementVarianceSampleAggregates>;
};

export type MeterStatementAverageAggregates = {
  __typename?: 'MeterStatementAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Mean average of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Mean average of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Mean average of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Mean average of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MeterStatement` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MeterStatementCondition = {
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationKey` field. */
  integrationKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `isAdjustment` field. */
  isAdjustment?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isValidated` field. */
  isValidated?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `manualUpload` field. */
  manualUpload?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `normalizedCurrencyCode` field. */
  normalizedCurrencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideCurrencyCode` field. */
  overrideCurrencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideDemandUnits` field. */
  overrideDemandUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideStatementMonth` field. */
  overrideStatementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `overrideStatementYear` field. */
  overrideStatementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `overrideUsageUnits` field. */
  overrideUsageUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pdfLink` field. */
  pdfLink?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `primaryDemandUnits` field. */
  primaryDemandUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `primaryUsageUnits` field. */
  primaryUsageUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statementDate` field. */
  statementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementDistinctAggregates = {
  __typename?: 'MeterStatementDistinctAggregates';
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of integrationKey across the matching connection */
  integrationKey?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['JSON']>;
  /** Distinct of isAdjustment across the matching connection */
  isAdjustment?: Maybe<Scalars['JSON']>;
  /** Distinct of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['JSON']>;
  /** Distinct of manualUpload across the matching connection */
  manualUpload?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  maxDemand?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of normalizedCurrencyCode across the matching connection */
  normalizedCurrencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideCurrencyCode across the matching connection */
  overrideCurrencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideDemandUnits across the matching connection */
  overrideDemandUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideUsageUnits across the matching connection */
  overrideUsageUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of pdfLink across the matching connection */
  pdfLink?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of primaryDemandUnits across the matching connection */
  primaryDemandUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of primaryUsageUnits across the matching connection */
  primaryUsageUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of statementDate across the matching connection */
  statementDate?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  totalCharges?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of this field across the matching connection. */
  totalUsage?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['JSON']>;
};

export type MeterStatementDistinctCountAggregates = {
  __typename?: 'MeterStatementDistinctCountAggregates';
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationKey across the matching connection */
  integrationKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isAdjustment across the matching connection */
  isAdjustment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['BigInt']>;
  /** Distinct count of manualUpload across the matching connection */
  manualUpload?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of normalizedCurrencyCode across the matching connection */
  normalizedCurrencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideCurrencyCode across the matching connection */
  overrideCurrencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideDemandUnits across the matching connection */
  overrideDemandUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideUsageUnits across the matching connection */
  overrideUsageUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pdfLink across the matching connection */
  pdfLink?: Maybe<Scalars['BigInt']>;
  /** Distinct count of primaryDemandUnits across the matching connection */
  primaryDemandUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of primaryUsageUnits across the matching connection */
  primaryUsageUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementDate across the matching connection */
  statementDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MeterStatement` object types. All fields are combined with a logical ‘and.’ */
export type MeterStatementFilter = {
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterStatementFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `integrationKey` field. */
  integrationKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<DateFilter>;
  /** Filter by the object’s `isAdjustment` field. */
  isAdjustment?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isValidated` field. */
  isValidated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `manualUpload` field. */
  manualUpload?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `maxDemand` field. */
  maxDemand?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `normalizedCurrencyCode` field. */
  normalizedCurrencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `normalizedTotalCharges` field. */
  normalizedTotalCharges?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterStatementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterStatementFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideCurrencyCode` field. */
  overrideCurrencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideDemandUnits` field. */
  overrideDemandUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideStatementMonth` field. */
  overrideStatementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `overrideStatementYear` field. */
  overrideStatementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `overrideUsageUnits` field. */
  overrideUsageUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `pdfLink` field. */
  pdfLink?: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryDemandUnits` field. */
  primaryDemandUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryUsageUnits` field. */
  primaryUsageUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statementDate` field. */
  statementDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalCharges` field. */
  totalCharges?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalUsage` field. */
  totalUsage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<IntFilter>;
};

/** A connection to a list of `MeterStatementHistory` values. */
export type MeterStatementHistoriesConnection = {
  __typename?: 'MeterStatementHistoriesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeterStatementHistoryAggregates>;
  /** A list of edges which contains the `MeterStatementHistory` and cursor to aid in pagination. */
  edges: Array<MeterStatementHistoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeterStatementHistoryAggregates>>;
  /** A list of `MeterStatementHistory` objects. */
  nodes: Array<MeterStatementHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeterStatementHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MeterStatementHistory` values. */
export type MeterStatementHistoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeterStatementHistoryGroupBy>;
  having?: InputMaybe<MeterStatementHistoryHavingInput>;
};

/** A `MeterStatementHistory` edge in the connection. */
export type MeterStatementHistoriesEdge = {
  __typename?: 'MeterStatementHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterStatementHistory` at the end of the edge. */
  node: MeterStatementHistory;
};

/** Methods to use when ordering `MeterStatementHistory`. */
export enum MeterStatementHistoriesOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  METER_STATEMENT_ID_ASC = 'METER_STATEMENT_ID_ASC',
  METER_STATEMENT_ID_DESC = 'METER_STATEMENT_ID_DESC',
  NATURAL = 'NATURAL'
}

export type MeterStatementHistory = {
  __typename?: 'MeterStatementHistory';
  /** The time this statement was created. */
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
  /** Reads a single `MeterStatement` that is related to this `MeterStatementHistory`. */
  meterStatement?: Maybe<MeterStatement>;
  meterStatementId: Scalars['Int'];
};

export type MeterStatementHistoryAggregates = {
  __typename?: 'MeterStatementHistoryAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MeterStatementHistoryAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MeterStatementHistoryDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeterStatementHistoryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MeterStatementHistoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MeterStatementHistoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MeterStatementHistoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MeterStatementHistoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MeterStatementHistorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MeterStatementHistoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MeterStatementHistoryVarianceSampleAggregates>;
};

export type MeterStatementHistoryAverageAggregates = {
  __typename?: 'MeterStatementHistoryAverageAggregates';
  /** Mean average of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MeterStatementHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MeterStatementHistoryCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementHistoryDistinctAggregates = {
  __typename?: 'MeterStatementHistoryDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['JSON']>;
};

export type MeterStatementHistoryDistinctCountAggregates = {
  __typename?: 'MeterStatementHistoryDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MeterStatementHistory` object types. All fields are combined with a logical ‘and.’ */
export type MeterStatementHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterStatementHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterStatementHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterStatementHistoryFilter>>;
};

/** Grouping methods for `MeterStatementHistory` for usage during aggregation. */
export enum MeterStatementHistoryGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  METER_STATEMENT_ID = 'METER_STATEMENT_ID',
  METER_STATEMENT_ID_DISTINCT = 'METER_STATEMENT_ID_DISTINCT'
}

export type MeterStatementHistoryHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `MeterStatementHistory` aggregates. */
export type MeterStatementHistoryHavingInput = {
  AND?: InputMaybe<Array<MeterStatementHistoryHavingInput>>;
  OR?: InputMaybe<Array<MeterStatementHistoryHavingInput>>;
  average?: InputMaybe<MeterStatementHistoryHavingAverageInput>;
  distinct?: InputMaybe<MeterStatementHistoryHavingDistinctInput>;
  distinctCount?: InputMaybe<MeterStatementHistoryHavingDistinctCountInput>;
  max?: InputMaybe<MeterStatementHistoryHavingMaxInput>;
  min?: InputMaybe<MeterStatementHistoryHavingMinInput>;
  stddevPopulation?: InputMaybe<MeterStatementHistoryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeterStatementHistoryHavingStddevSampleInput>;
  sum?: InputMaybe<MeterStatementHistoryHavingSumInput>;
  variancePopulation?: InputMaybe<MeterStatementHistoryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeterStatementHistoryHavingVarianceSampleInput>;
};

export type MeterStatementHistoryHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementHistoryHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
};

/** An input for mutations affecting `MeterStatementHistory` */
export type MeterStatementHistoryInput = {
  description: Scalars['String'];
  /** Mutates a `MeterStatement` as part of the payload */
  meterStatement?: InputMaybe<MeterStatementInput>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementHistoryMaxAggregates = {
  __typename?: 'MeterStatementHistoryMaxAggregates';
  /** Maximum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
};

export type MeterStatementHistoryMinAggregates = {
  __typename?: 'MeterStatementHistoryMinAggregates';
  /** Minimum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
};

export type MeterStatementHistoryStddevPopulationAggregates = {
  __typename?: 'MeterStatementHistoryStddevPopulationAggregates';
  /** Population standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementHistoryStddevSampleAggregates = {
  __typename?: 'MeterStatementHistoryStddevSampleAggregates';
  /** Sample standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementHistorySumAggregates = {
  __typename?: 'MeterStatementHistorySumAggregates';
  /** Sum of meterStatementId across the matching connection */
  meterStatementId: Scalars['BigInt'];
};

export type MeterStatementHistoryVariancePopulationAggregates = {
  __typename?: 'MeterStatementHistoryVariancePopulationAggregates';
  /** Population variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementHistoryVarianceSampleAggregates = {
  __typename?: 'MeterStatementHistoryVarianceSampleAggregates';
  /** Sample variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
};

/** An input for mutations affecting `MeterStatement` */
export type MeterStatementInput = {
  /** Mutates a `AccountStatement` as part of the payload */
  accountStatement?: InputMaybe<AccountStatementInput>;
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** The original currency code. */
  currencyCode: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  integrationKey?: InputMaybe<Scalars['String']>;
  /** The end interval of the meter statement. */
  intervalEnd: Scalars['Date'];
  /** The start interval of the meter statement. */
  intervalStart: Scalars['Date'];
  isAdjustment?: InputMaybe<Scalars['Boolean']>;
  isValidated?: InputMaybe<Scalars['Boolean']>;
  manualUpload?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pdfLink?: InputMaybe<Scalars['Upload']>;
  primaryDemandUnits?: InputMaybe<Scalars['String']>;
  primaryUsageUnits?: InputMaybe<Scalars['String']>;
  /** The date the statement was issued on. */
  statementDate: Scalars['Date'];
  /** Mutates a `UtilityMeter` as part of the payload */
  utilityMeter?: InputMaybe<UtilityMeterInput>;
  utilityMeterId?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementMaxAggregates = {
  __typename?: 'MeterStatementMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Maximum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Maximum of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Maximum of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Maximum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type MeterStatementMinAggregates = {
  __typename?: 'MeterStatementMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Minimum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Minimum of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Minimum of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Minimum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `MeterStatement`. Fields that are set will be updated. */
export type MeterStatementPatch = {
  /** Overrides the original currency code. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** The end interval of the meter statement. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** The start interval of the meter statement. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  isAdjustment?: InputMaybe<Scalars['Boolean']>;
  isValidated?: InputMaybe<Scalars['Boolean']>;
  manualUpload?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** This overrides the primary demand units. */
  overrideDemandUnits?: InputMaybe<Scalars['String']>;
  /** Overrides the normalized month of the account_statement. */
  overrideStatementMonth?: InputMaybe<Scalars['Int']>;
  /** Overrides the normalized year of the account_statement. */
  overrideStatementYear?: InputMaybe<Scalars['Int']>;
  /** This overrides the primary usage units. */
  overrideUsageUnits?: InputMaybe<Scalars['String']>;
  pdfLink?: InputMaybe<Scalars['Upload']>;
  primaryDemandUnits?: InputMaybe<Scalars['String']>;
  primaryUsageUnits?: InputMaybe<Scalars['String']>;
  utilityMeterId?: InputMaybe<Scalars['Int']>;
};

export type MeterStatementStddevPopulationAggregates = {
  __typename?: 'MeterStatementStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Population standard deviation of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Population standard deviation of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Population standard deviation of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Population standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementStddevSampleAggregates = {
  __typename?: 'MeterStatementStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementSumAggregates = {
  __typename?: 'MeterStatementSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Sum of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sum of overrideStatementMonth across the matching connection */
  overrideStatementMonth: Scalars['BigInt'];
  /** Sum of overrideStatementYear across the matching connection */
  overrideStatementYear: Scalars['BigInt'];
  /** Sum of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Sum of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Sum of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sum of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Sum of utilityMeterId across the matching connection */
  utilityMeterId: Scalars['BigInt'];
};

export type MeterStatementVariancePopulationAggregates = {
  __typename?: 'MeterStatementVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Population variance of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Population variance of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Population variance of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Population variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type MeterStatementVarianceSampleAggregates = {
  __typename?: 'MeterStatementVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  maxDemand?: Maybe<Scalars['Float']>;
  /** Sample variance of this field across the matching connection. */
  normalizedTotalCharges?: Maybe<Scalars['Float']>;
  /** Sample variance of overrideStatementMonth across the matching connection */
  overrideStatementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of overrideStatementYear across the matching connection */
  overrideStatementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  totalCharges?: Maybe<Scalars['Float']>;
  /** Sample variance of this field across the matching connection. */
  totalUsage?: Maybe<Scalars['Float']>;
  /** Sample variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/** A `MeterStatementsChargesRecord` edge in the connection. */
export type MeterStatementsChargeEdge = {
  __typename?: 'MeterStatementsChargeEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterStatementsChargesRecord` at the end of the edge. */
  node: MeterStatementsChargesRecord;
};

/** A connection to a list of `MeterStatementsChargesRecord` values. */
export type MeterStatementsChargesConnection = {
  __typename?: 'MeterStatementsChargesConnection';
  /** A list of edges which contains the `MeterStatementsChargesRecord` and cursor to aid in pagination. */
  edges: Array<MeterStatementsChargeEdge>;
  /** A list of `MeterStatementsChargesRecord` objects. */
  nodes: Array<MeterStatementsChargesRecord>;
  /** The count of *all* `MeterStatementsChargesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `charges` query. */
export type MeterStatementsChargesRecord = {
  __typename?: 'MeterStatementsChargesRecord';
  category?: Maybe<UtilityMeterChargeCategory>;
  value?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `MeterStatementsChargesRecord` object types. All fields are combined with a logical ‘and.’ */
export type MeterStatementsChargesRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterStatementsChargesRecordFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UtilityMeterChargeCategoryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterStatementsChargesRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterStatementsChargesRecordFilter>>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

/** A connection to a list of `MeterStatement` values. */
export type MeterStatementsConnection = {
  __typename?: 'MeterStatementsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeterStatementAggregates>;
  /** A list of edges which contains the `MeterStatement` and cursor to aid in pagination. */
  edges: Array<MeterStatementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeterStatementAggregates>>;
  /** A list of `MeterStatement` objects. */
  nodes: Array<MeterStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeterStatement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MeterStatement` values. */
export type MeterStatementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeterStatementsGroupBy>;
  having?: InputMaybe<MeterStatementsHavingInput>;
};

/** A `MeterStatement` edge in the connection. */
export type MeterStatementsEdge = {
  __typename?: 'MeterStatementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterStatement` at the end of the edge. */
  node: MeterStatement;
};

/** Grouping methods for `MeterStatement` for usage during aggregation. */
export enum MeterStatementsGroupBy {
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  INTEGRATION_KEY = 'INTEGRATION_KEY',
  INTEGRATION_KEY_DISTINCT = 'INTEGRATION_KEY_DISTINCT',
  INTERVAL_END = 'INTERVAL_END',
  INTERVAL_END_DISTINCT = 'INTERVAL_END_DISTINCT',
  INTERVAL_START = 'INTERVAL_START',
  INTERVAL_START_DISTINCT = 'INTERVAL_START_DISTINCT',
  IS_ADJUSTMENT = 'IS_ADJUSTMENT',
  IS_ADJUSTMENT_DISTINCT = 'IS_ADJUSTMENT_DISTINCT',
  IS_VALIDATED = 'IS_VALIDATED',
  IS_VALIDATED_DISTINCT = 'IS_VALIDATED_DISTINCT',
  MANUAL_UPLOAD = 'MANUAL_UPLOAD',
  MANUAL_UPLOAD_DISTINCT = 'MANUAL_UPLOAD_DISTINCT',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  NORMALIZED_CURRENCY_CODE = 'NORMALIZED_CURRENCY_CODE',
  NORMALIZED_CURRENCY_CODE_DISTINCT = 'NORMALIZED_CURRENCY_CODE_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_CURRENCY_CODE = 'OVERRIDE_CURRENCY_CODE',
  OVERRIDE_CURRENCY_CODE_DISTINCT = 'OVERRIDE_CURRENCY_CODE_DISTINCT',
  OVERRIDE_DEMAND_UNITS = 'OVERRIDE_DEMAND_UNITS',
  OVERRIDE_DEMAND_UNITS_DISTINCT = 'OVERRIDE_DEMAND_UNITS_DISTINCT',
  OVERRIDE_STATEMENT_MONTH = 'OVERRIDE_STATEMENT_MONTH',
  OVERRIDE_STATEMENT_MONTH_DISTINCT = 'OVERRIDE_STATEMENT_MONTH_DISTINCT',
  OVERRIDE_STATEMENT_YEAR = 'OVERRIDE_STATEMENT_YEAR',
  OVERRIDE_STATEMENT_YEAR_DISTINCT = 'OVERRIDE_STATEMENT_YEAR_DISTINCT',
  OVERRIDE_USAGE_UNITS = 'OVERRIDE_USAGE_UNITS',
  OVERRIDE_USAGE_UNITS_DISTINCT = 'OVERRIDE_USAGE_UNITS_DISTINCT',
  PDF_LINK = 'PDF_LINK',
  PDF_LINK_DISTINCT = 'PDF_LINK_DISTINCT',
  PRIMARY_DEMAND_UNITS = 'PRIMARY_DEMAND_UNITS',
  PRIMARY_DEMAND_UNITS_DISTINCT = 'PRIMARY_DEMAND_UNITS_DISTINCT',
  PRIMARY_USAGE_UNITS = 'PRIMARY_USAGE_UNITS',
  PRIMARY_USAGE_UNITS_DISTINCT = 'PRIMARY_USAGE_UNITS_DISTINCT',
  STATEMENT_DATE = 'STATEMENT_DATE',
  STATEMENT_DATE_DISTINCT = 'STATEMENT_DATE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UTILITY_METER_ID = 'UTILITY_METER_ID',
  UTILITY_METER_ID_DISTINCT = 'UTILITY_METER_ID_DISTINCT'
}

export type MeterStatementsHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingAverageMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingAverageMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingAverageMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingAverageMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingAverageMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingAverageMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingAverageMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingAverageMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingAverageMeterStatementsStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingAverageMeterStatementsStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingAverageMeterStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingAverageMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingDistinctCountMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingDistinctCountMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingDistinctCountMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingDistinctCountMeterStatementsStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingDistinctCountMeterStatementsStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingDistinctCountMeterStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingDistinctCountMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingDistinctMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingDistinctMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingDistinctMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingDistinctMeterStatementsStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingDistinctMeterStatementsStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingDistinctMeterStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingDistinctMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `MeterStatement` aggregates. */
export type MeterStatementsHavingInput = {
  AND?: InputMaybe<Array<MeterStatementsHavingInput>>;
  OR?: InputMaybe<Array<MeterStatementsHavingInput>>;
  average?: InputMaybe<MeterStatementsHavingAverageInput>;
  distinct?: InputMaybe<MeterStatementsHavingDistinctInput>;
  distinctCount?: InputMaybe<MeterStatementsHavingDistinctCountInput>;
  max?: InputMaybe<MeterStatementsHavingMaxInput>;
  min?: InputMaybe<MeterStatementsHavingMinInput>;
  stddevPopulation?: InputMaybe<MeterStatementsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeterStatementsHavingStddevSampleInput>;
  sum?: InputMaybe<MeterStatementsHavingSumInput>;
  variancePopulation?: InputMaybe<MeterStatementsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeterStatementsHavingVarianceSampleInput>;
};

export type MeterStatementsHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingMaxMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingMaxMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingMaxMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingMaxMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingMaxMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingMaxMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMaxMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingMaxMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingMaxMeterStatementsStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMaxMeterStatementsStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMaxMeterStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingMaxMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingMinMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingMinMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingMinMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingMinMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingMinMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingMinMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMinMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingMinMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingMinMeterStatementsStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMinMeterStatementsStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingMinMeterStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingMinMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingStddevPopulationMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingStddevPopulationMeterStatementsMaxDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevPopulationMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevPopulationMeterStatementsStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingStddevPopulationMeterStatementsStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingStddevPopulationMeterStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevPopulationMeterStatementsTotalUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingStddevSampleMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingStddevSampleMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingStddevSampleMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevSampleMeterStatementsStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingStddevSampleMeterStatementsStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingStddevSampleMeterStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingStddevSampleMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingSumMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingSumMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingSumMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingSumMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingSumMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingSumMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingSumMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingSumMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingSumMeterStatementsStatementMonthInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingSumMeterStatementsStatementYearInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingSumMeterStatementsTotalChargesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingSumMeterStatementsTotalUsageInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingVariancePopulationMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingVariancePopulationMeterStatementsMaxDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVariancePopulationMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVariancePopulationMeterStatementsStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingVariancePopulationMeterStatementsStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingVariancePopulationMeterStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVariancePopulationMeterStatementsTotalUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  maxDemand?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsMaxDemandInput>;
  normalizedTotalCharges?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsNormalizedTotalChargesInput>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideStatementMonth?: InputMaybe<HavingIntFilter>;
  overrideStatementYear?: InputMaybe<HavingIntFilter>;
  statementDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsStatementMonthInput>;
  statementYear?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsStatementYearInput>;
  totalCharges?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsTotalChargesInput>;
  totalUsage?: InputMaybe<MeterStatementsHavingVarianceSampleMeterStatementsTotalUsageInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type MeterStatementsHavingVarianceSampleMeterStatementsMaxDemandInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type MeterStatementsHavingVarianceSampleMeterStatementsNormalizedTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVarianceSampleMeterStatementsStatementMonthInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingVarianceSampleMeterStatementsStatementYearInput =
  {
    filter?: InputMaybe<HavingIntFilter>;
  };

export type MeterStatementsHavingVarianceSampleMeterStatementsTotalChargesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type MeterStatementsHavingVarianceSampleMeterStatementsTotalUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

/** A `MeterStatementsNormalizedChargesRecord` edge in the connection. */
export type MeterStatementsNormalizedChargeEdge = {
  __typename?: 'MeterStatementsNormalizedChargeEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterStatementsNormalizedChargesRecord` at the end of the edge. */
  node: MeterStatementsNormalizedChargesRecord;
};

/** A connection to a list of `MeterStatementsNormalizedChargesRecord` values. */
export type MeterStatementsNormalizedChargesConnection = {
  __typename?: 'MeterStatementsNormalizedChargesConnection';
  /** A list of edges which contains the `MeterStatementsNormalizedChargesRecord` and cursor to aid in pagination. */
  edges: Array<MeterStatementsNormalizedChargeEdge>;
  /** A list of `MeterStatementsNormalizedChargesRecord` objects. */
  nodes: Array<MeterStatementsNormalizedChargesRecord>;
  /** The count of *all* `MeterStatementsNormalizedChargesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `normalizedCharges` query. */
export type MeterStatementsNormalizedChargesRecord = {
  __typename?: 'MeterStatementsNormalizedChargesRecord';
  category?: Maybe<UtilityMeterChargeCategory>;
  value?: Maybe<Scalars['Float']>;
};

/** A filter to be used against `MeterStatementsNormalizedChargesRecord` object types. All fields are combined with a logical ‘and.’ */
export type MeterStatementsNormalizedChargesRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterStatementsNormalizedChargesRecordFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UtilityMeterChargeCategoryFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterStatementsNormalizedChargesRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterStatementsNormalizedChargesRecordFilter>>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

/** Methods to use when ordering `MeterStatement`. */
export enum MeterStatementsOrderBy {
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTEGRATION_KEY_ASC = 'INTEGRATION_KEY_ASC',
  INTEGRATION_KEY_DESC = 'INTEGRATION_KEY_DESC',
  INTERVAL_END_ASC = 'INTERVAL_END_ASC',
  INTERVAL_END_DESC = 'INTERVAL_END_DESC',
  INTERVAL_START_ASC = 'INTERVAL_START_ASC',
  INTERVAL_START_DESC = 'INTERVAL_START_DESC',
  IS_ADJUSTMENT_ASC = 'IS_ADJUSTMENT_ASC',
  IS_ADJUSTMENT_DESC = 'IS_ADJUSTMENT_DESC',
  IS_VALIDATED_ASC = 'IS_VALIDATED_ASC',
  IS_VALIDATED_DESC = 'IS_VALIDATED_DESC',
  MANUAL_UPLOAD_ASC = 'MANUAL_UPLOAD_ASC',
  MANUAL_UPLOAD_DESC = 'MANUAL_UPLOAD_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  METER_CHARGES_AVERAGE_CATEGORY_ASC = 'METER_CHARGES_AVERAGE_CATEGORY_ASC',
  METER_CHARGES_AVERAGE_CATEGORY_DESC = 'METER_CHARGES_AVERAGE_CATEGORY_DESC',
  METER_CHARGES_AVERAGE_CREATED_AT_ASC = 'METER_CHARGES_AVERAGE_CREATED_AT_ASC',
  METER_CHARGES_AVERAGE_CREATED_AT_DESC = 'METER_CHARGES_AVERAGE_CREATED_AT_DESC',
  METER_CHARGES_AVERAGE_DELETED_AT_ASC = 'METER_CHARGES_AVERAGE_DELETED_AT_ASC',
  METER_CHARGES_AVERAGE_DELETED_AT_DESC = 'METER_CHARGES_AVERAGE_DELETED_AT_DESC',
  METER_CHARGES_AVERAGE_ID_ASC = 'METER_CHARGES_AVERAGE_ID_ASC',
  METER_CHARGES_AVERAGE_ID_DESC = 'METER_CHARGES_AVERAGE_ID_DESC',
  METER_CHARGES_AVERAGE_LABEL_ASC = 'METER_CHARGES_AVERAGE_LABEL_ASC',
  METER_CHARGES_AVERAGE_LABEL_DESC = 'METER_CHARGES_AVERAGE_LABEL_DESC',
  METER_CHARGES_AVERAGE_LINE_NUMBER_ASC = 'METER_CHARGES_AVERAGE_LINE_NUMBER_ASC',
  METER_CHARGES_AVERAGE_LINE_NUMBER_DESC = 'METER_CHARGES_AVERAGE_LINE_NUMBER_DESC',
  METER_CHARGES_AVERAGE_METER_STATEMENT_ID_ASC = 'METER_CHARGES_AVERAGE_METER_STATEMENT_ID_ASC',
  METER_CHARGES_AVERAGE_METER_STATEMENT_ID_DESC = 'METER_CHARGES_AVERAGE_METER_STATEMENT_ID_DESC',
  METER_CHARGES_AVERAGE_NORMALIZED_VALUE_ASC = 'METER_CHARGES_AVERAGE_NORMALIZED_VALUE_ASC',
  METER_CHARGES_AVERAGE_NORMALIZED_VALUE_DESC = 'METER_CHARGES_AVERAGE_NORMALIZED_VALUE_DESC',
  METER_CHARGES_AVERAGE_OVERRIDE_AT_ASC = 'METER_CHARGES_AVERAGE_OVERRIDE_AT_ASC',
  METER_CHARGES_AVERAGE_OVERRIDE_AT_DESC = 'METER_CHARGES_AVERAGE_OVERRIDE_AT_DESC',
  METER_CHARGES_AVERAGE_OVERRIDE_LABEL_ASC = 'METER_CHARGES_AVERAGE_OVERRIDE_LABEL_ASC',
  METER_CHARGES_AVERAGE_OVERRIDE_LABEL_DESC = 'METER_CHARGES_AVERAGE_OVERRIDE_LABEL_DESC',
  METER_CHARGES_AVERAGE_OVERRIDE_VALUE_ASC = 'METER_CHARGES_AVERAGE_OVERRIDE_VALUE_ASC',
  METER_CHARGES_AVERAGE_OVERRIDE_VALUE_DESC = 'METER_CHARGES_AVERAGE_OVERRIDE_VALUE_DESC',
  METER_CHARGES_AVERAGE_UPDATED_AT_ASC = 'METER_CHARGES_AVERAGE_UPDATED_AT_ASC',
  METER_CHARGES_AVERAGE_UPDATED_AT_DESC = 'METER_CHARGES_AVERAGE_UPDATED_AT_DESC',
  METER_CHARGES_AVERAGE_VALUE_ASC = 'METER_CHARGES_AVERAGE_VALUE_ASC',
  METER_CHARGES_AVERAGE_VALUE_DESC = 'METER_CHARGES_AVERAGE_VALUE_DESC',
  METER_CHARGES_COUNT_ASC = 'METER_CHARGES_COUNT_ASC',
  METER_CHARGES_COUNT_DESC = 'METER_CHARGES_COUNT_DESC',
  METER_CHARGES_DISTINCT_CATEGORY_ASC = 'METER_CHARGES_DISTINCT_CATEGORY_ASC',
  METER_CHARGES_DISTINCT_CATEGORY_DESC = 'METER_CHARGES_DISTINCT_CATEGORY_DESC',
  METER_CHARGES_DISTINCT_COUNT_CATEGORY_ASC = 'METER_CHARGES_DISTINCT_COUNT_CATEGORY_ASC',
  METER_CHARGES_DISTINCT_COUNT_CATEGORY_DESC = 'METER_CHARGES_DISTINCT_COUNT_CATEGORY_DESC',
  METER_CHARGES_DISTINCT_COUNT_CREATED_AT_ASC = 'METER_CHARGES_DISTINCT_COUNT_CREATED_AT_ASC',
  METER_CHARGES_DISTINCT_COUNT_CREATED_AT_DESC = 'METER_CHARGES_DISTINCT_COUNT_CREATED_AT_DESC',
  METER_CHARGES_DISTINCT_COUNT_DELETED_AT_ASC = 'METER_CHARGES_DISTINCT_COUNT_DELETED_AT_ASC',
  METER_CHARGES_DISTINCT_COUNT_DELETED_AT_DESC = 'METER_CHARGES_DISTINCT_COUNT_DELETED_AT_DESC',
  METER_CHARGES_DISTINCT_COUNT_ID_ASC = 'METER_CHARGES_DISTINCT_COUNT_ID_ASC',
  METER_CHARGES_DISTINCT_COUNT_ID_DESC = 'METER_CHARGES_DISTINCT_COUNT_ID_DESC',
  METER_CHARGES_DISTINCT_COUNT_LABEL_ASC = 'METER_CHARGES_DISTINCT_COUNT_LABEL_ASC',
  METER_CHARGES_DISTINCT_COUNT_LABEL_DESC = 'METER_CHARGES_DISTINCT_COUNT_LABEL_DESC',
  METER_CHARGES_DISTINCT_COUNT_LINE_NUMBER_ASC = 'METER_CHARGES_DISTINCT_COUNT_LINE_NUMBER_ASC',
  METER_CHARGES_DISTINCT_COUNT_LINE_NUMBER_DESC = 'METER_CHARGES_DISTINCT_COUNT_LINE_NUMBER_DESC',
  METER_CHARGES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'METER_CHARGES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  METER_CHARGES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'METER_CHARGES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  METER_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_ASC = 'METER_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_ASC',
  METER_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_DESC = 'METER_CHARGES_DISTINCT_COUNT_NORMALIZED_VALUE_DESC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC',
  METER_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC = 'METER_CHARGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC',
  METER_CHARGES_DISTINCT_COUNT_UPDATED_AT_ASC = 'METER_CHARGES_DISTINCT_COUNT_UPDATED_AT_ASC',
  METER_CHARGES_DISTINCT_COUNT_UPDATED_AT_DESC = 'METER_CHARGES_DISTINCT_COUNT_UPDATED_AT_DESC',
  METER_CHARGES_DISTINCT_COUNT_VALUE_ASC = 'METER_CHARGES_DISTINCT_COUNT_VALUE_ASC',
  METER_CHARGES_DISTINCT_COUNT_VALUE_DESC = 'METER_CHARGES_DISTINCT_COUNT_VALUE_DESC',
  METER_CHARGES_DISTINCT_CREATED_AT_ASC = 'METER_CHARGES_DISTINCT_CREATED_AT_ASC',
  METER_CHARGES_DISTINCT_CREATED_AT_DESC = 'METER_CHARGES_DISTINCT_CREATED_AT_DESC',
  METER_CHARGES_DISTINCT_DELETED_AT_ASC = 'METER_CHARGES_DISTINCT_DELETED_AT_ASC',
  METER_CHARGES_DISTINCT_DELETED_AT_DESC = 'METER_CHARGES_DISTINCT_DELETED_AT_DESC',
  METER_CHARGES_DISTINCT_ID_ASC = 'METER_CHARGES_DISTINCT_ID_ASC',
  METER_CHARGES_DISTINCT_ID_DESC = 'METER_CHARGES_DISTINCT_ID_DESC',
  METER_CHARGES_DISTINCT_LABEL_ASC = 'METER_CHARGES_DISTINCT_LABEL_ASC',
  METER_CHARGES_DISTINCT_LABEL_DESC = 'METER_CHARGES_DISTINCT_LABEL_DESC',
  METER_CHARGES_DISTINCT_LINE_NUMBER_ASC = 'METER_CHARGES_DISTINCT_LINE_NUMBER_ASC',
  METER_CHARGES_DISTINCT_LINE_NUMBER_DESC = 'METER_CHARGES_DISTINCT_LINE_NUMBER_DESC',
  METER_CHARGES_DISTINCT_METER_STATEMENT_ID_ASC = 'METER_CHARGES_DISTINCT_METER_STATEMENT_ID_ASC',
  METER_CHARGES_DISTINCT_METER_STATEMENT_ID_DESC = 'METER_CHARGES_DISTINCT_METER_STATEMENT_ID_DESC',
  METER_CHARGES_DISTINCT_NORMALIZED_VALUE_ASC = 'METER_CHARGES_DISTINCT_NORMALIZED_VALUE_ASC',
  METER_CHARGES_DISTINCT_NORMALIZED_VALUE_DESC = 'METER_CHARGES_DISTINCT_NORMALIZED_VALUE_DESC',
  METER_CHARGES_DISTINCT_OVERRIDE_AT_ASC = 'METER_CHARGES_DISTINCT_OVERRIDE_AT_ASC',
  METER_CHARGES_DISTINCT_OVERRIDE_AT_DESC = 'METER_CHARGES_DISTINCT_OVERRIDE_AT_DESC',
  METER_CHARGES_DISTINCT_OVERRIDE_LABEL_ASC = 'METER_CHARGES_DISTINCT_OVERRIDE_LABEL_ASC',
  METER_CHARGES_DISTINCT_OVERRIDE_LABEL_DESC = 'METER_CHARGES_DISTINCT_OVERRIDE_LABEL_DESC',
  METER_CHARGES_DISTINCT_OVERRIDE_VALUE_ASC = 'METER_CHARGES_DISTINCT_OVERRIDE_VALUE_ASC',
  METER_CHARGES_DISTINCT_OVERRIDE_VALUE_DESC = 'METER_CHARGES_DISTINCT_OVERRIDE_VALUE_DESC',
  METER_CHARGES_DISTINCT_UPDATED_AT_ASC = 'METER_CHARGES_DISTINCT_UPDATED_AT_ASC',
  METER_CHARGES_DISTINCT_UPDATED_AT_DESC = 'METER_CHARGES_DISTINCT_UPDATED_AT_DESC',
  METER_CHARGES_DISTINCT_VALUE_ASC = 'METER_CHARGES_DISTINCT_VALUE_ASC',
  METER_CHARGES_DISTINCT_VALUE_DESC = 'METER_CHARGES_DISTINCT_VALUE_DESC',
  METER_CHARGES_MAX_CATEGORY_ASC = 'METER_CHARGES_MAX_CATEGORY_ASC',
  METER_CHARGES_MAX_CATEGORY_DESC = 'METER_CHARGES_MAX_CATEGORY_DESC',
  METER_CHARGES_MAX_CREATED_AT_ASC = 'METER_CHARGES_MAX_CREATED_AT_ASC',
  METER_CHARGES_MAX_CREATED_AT_DESC = 'METER_CHARGES_MAX_CREATED_AT_DESC',
  METER_CHARGES_MAX_DELETED_AT_ASC = 'METER_CHARGES_MAX_DELETED_AT_ASC',
  METER_CHARGES_MAX_DELETED_AT_DESC = 'METER_CHARGES_MAX_DELETED_AT_DESC',
  METER_CHARGES_MAX_ID_ASC = 'METER_CHARGES_MAX_ID_ASC',
  METER_CHARGES_MAX_ID_DESC = 'METER_CHARGES_MAX_ID_DESC',
  METER_CHARGES_MAX_LABEL_ASC = 'METER_CHARGES_MAX_LABEL_ASC',
  METER_CHARGES_MAX_LABEL_DESC = 'METER_CHARGES_MAX_LABEL_DESC',
  METER_CHARGES_MAX_LINE_NUMBER_ASC = 'METER_CHARGES_MAX_LINE_NUMBER_ASC',
  METER_CHARGES_MAX_LINE_NUMBER_DESC = 'METER_CHARGES_MAX_LINE_NUMBER_DESC',
  METER_CHARGES_MAX_METER_STATEMENT_ID_ASC = 'METER_CHARGES_MAX_METER_STATEMENT_ID_ASC',
  METER_CHARGES_MAX_METER_STATEMENT_ID_DESC = 'METER_CHARGES_MAX_METER_STATEMENT_ID_DESC',
  METER_CHARGES_MAX_NORMALIZED_VALUE_ASC = 'METER_CHARGES_MAX_NORMALIZED_VALUE_ASC',
  METER_CHARGES_MAX_NORMALIZED_VALUE_DESC = 'METER_CHARGES_MAX_NORMALIZED_VALUE_DESC',
  METER_CHARGES_MAX_OVERRIDE_AT_ASC = 'METER_CHARGES_MAX_OVERRIDE_AT_ASC',
  METER_CHARGES_MAX_OVERRIDE_AT_DESC = 'METER_CHARGES_MAX_OVERRIDE_AT_DESC',
  METER_CHARGES_MAX_OVERRIDE_LABEL_ASC = 'METER_CHARGES_MAX_OVERRIDE_LABEL_ASC',
  METER_CHARGES_MAX_OVERRIDE_LABEL_DESC = 'METER_CHARGES_MAX_OVERRIDE_LABEL_DESC',
  METER_CHARGES_MAX_OVERRIDE_VALUE_ASC = 'METER_CHARGES_MAX_OVERRIDE_VALUE_ASC',
  METER_CHARGES_MAX_OVERRIDE_VALUE_DESC = 'METER_CHARGES_MAX_OVERRIDE_VALUE_DESC',
  METER_CHARGES_MAX_UPDATED_AT_ASC = 'METER_CHARGES_MAX_UPDATED_AT_ASC',
  METER_CHARGES_MAX_UPDATED_AT_DESC = 'METER_CHARGES_MAX_UPDATED_AT_DESC',
  METER_CHARGES_MAX_VALUE_ASC = 'METER_CHARGES_MAX_VALUE_ASC',
  METER_CHARGES_MAX_VALUE_DESC = 'METER_CHARGES_MAX_VALUE_DESC',
  METER_CHARGES_MIN_CATEGORY_ASC = 'METER_CHARGES_MIN_CATEGORY_ASC',
  METER_CHARGES_MIN_CATEGORY_DESC = 'METER_CHARGES_MIN_CATEGORY_DESC',
  METER_CHARGES_MIN_CREATED_AT_ASC = 'METER_CHARGES_MIN_CREATED_AT_ASC',
  METER_CHARGES_MIN_CREATED_AT_DESC = 'METER_CHARGES_MIN_CREATED_AT_DESC',
  METER_CHARGES_MIN_DELETED_AT_ASC = 'METER_CHARGES_MIN_DELETED_AT_ASC',
  METER_CHARGES_MIN_DELETED_AT_DESC = 'METER_CHARGES_MIN_DELETED_AT_DESC',
  METER_CHARGES_MIN_ID_ASC = 'METER_CHARGES_MIN_ID_ASC',
  METER_CHARGES_MIN_ID_DESC = 'METER_CHARGES_MIN_ID_DESC',
  METER_CHARGES_MIN_LABEL_ASC = 'METER_CHARGES_MIN_LABEL_ASC',
  METER_CHARGES_MIN_LABEL_DESC = 'METER_CHARGES_MIN_LABEL_DESC',
  METER_CHARGES_MIN_LINE_NUMBER_ASC = 'METER_CHARGES_MIN_LINE_NUMBER_ASC',
  METER_CHARGES_MIN_LINE_NUMBER_DESC = 'METER_CHARGES_MIN_LINE_NUMBER_DESC',
  METER_CHARGES_MIN_METER_STATEMENT_ID_ASC = 'METER_CHARGES_MIN_METER_STATEMENT_ID_ASC',
  METER_CHARGES_MIN_METER_STATEMENT_ID_DESC = 'METER_CHARGES_MIN_METER_STATEMENT_ID_DESC',
  METER_CHARGES_MIN_NORMALIZED_VALUE_ASC = 'METER_CHARGES_MIN_NORMALIZED_VALUE_ASC',
  METER_CHARGES_MIN_NORMALIZED_VALUE_DESC = 'METER_CHARGES_MIN_NORMALIZED_VALUE_DESC',
  METER_CHARGES_MIN_OVERRIDE_AT_ASC = 'METER_CHARGES_MIN_OVERRIDE_AT_ASC',
  METER_CHARGES_MIN_OVERRIDE_AT_DESC = 'METER_CHARGES_MIN_OVERRIDE_AT_DESC',
  METER_CHARGES_MIN_OVERRIDE_LABEL_ASC = 'METER_CHARGES_MIN_OVERRIDE_LABEL_ASC',
  METER_CHARGES_MIN_OVERRIDE_LABEL_DESC = 'METER_CHARGES_MIN_OVERRIDE_LABEL_DESC',
  METER_CHARGES_MIN_OVERRIDE_VALUE_ASC = 'METER_CHARGES_MIN_OVERRIDE_VALUE_ASC',
  METER_CHARGES_MIN_OVERRIDE_VALUE_DESC = 'METER_CHARGES_MIN_OVERRIDE_VALUE_DESC',
  METER_CHARGES_MIN_UPDATED_AT_ASC = 'METER_CHARGES_MIN_UPDATED_AT_ASC',
  METER_CHARGES_MIN_UPDATED_AT_DESC = 'METER_CHARGES_MIN_UPDATED_AT_DESC',
  METER_CHARGES_MIN_VALUE_ASC = 'METER_CHARGES_MIN_VALUE_ASC',
  METER_CHARGES_MIN_VALUE_DESC = 'METER_CHARGES_MIN_VALUE_DESC',
  METER_CHARGES_STDDEV_POPULATION_CATEGORY_ASC = 'METER_CHARGES_STDDEV_POPULATION_CATEGORY_ASC',
  METER_CHARGES_STDDEV_POPULATION_CATEGORY_DESC = 'METER_CHARGES_STDDEV_POPULATION_CATEGORY_DESC',
  METER_CHARGES_STDDEV_POPULATION_CREATED_AT_ASC = 'METER_CHARGES_STDDEV_POPULATION_CREATED_AT_ASC',
  METER_CHARGES_STDDEV_POPULATION_CREATED_AT_DESC = 'METER_CHARGES_STDDEV_POPULATION_CREATED_AT_DESC',
  METER_CHARGES_STDDEV_POPULATION_DELETED_AT_ASC = 'METER_CHARGES_STDDEV_POPULATION_DELETED_AT_ASC',
  METER_CHARGES_STDDEV_POPULATION_DELETED_AT_DESC = 'METER_CHARGES_STDDEV_POPULATION_DELETED_AT_DESC',
  METER_CHARGES_STDDEV_POPULATION_ID_ASC = 'METER_CHARGES_STDDEV_POPULATION_ID_ASC',
  METER_CHARGES_STDDEV_POPULATION_ID_DESC = 'METER_CHARGES_STDDEV_POPULATION_ID_DESC',
  METER_CHARGES_STDDEV_POPULATION_LABEL_ASC = 'METER_CHARGES_STDDEV_POPULATION_LABEL_ASC',
  METER_CHARGES_STDDEV_POPULATION_LABEL_DESC = 'METER_CHARGES_STDDEV_POPULATION_LABEL_DESC',
  METER_CHARGES_STDDEV_POPULATION_LINE_NUMBER_ASC = 'METER_CHARGES_STDDEV_POPULATION_LINE_NUMBER_ASC',
  METER_CHARGES_STDDEV_POPULATION_LINE_NUMBER_DESC = 'METER_CHARGES_STDDEV_POPULATION_LINE_NUMBER_DESC',
  METER_CHARGES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'METER_CHARGES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  METER_CHARGES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'METER_CHARGES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  METER_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_ASC = 'METER_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_ASC',
  METER_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_DESC = 'METER_CHARGES_STDDEV_POPULATION_NORMALIZED_VALUE_DESC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC',
  METER_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC = 'METER_CHARGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC',
  METER_CHARGES_STDDEV_POPULATION_UPDATED_AT_ASC = 'METER_CHARGES_STDDEV_POPULATION_UPDATED_AT_ASC',
  METER_CHARGES_STDDEV_POPULATION_UPDATED_AT_DESC = 'METER_CHARGES_STDDEV_POPULATION_UPDATED_AT_DESC',
  METER_CHARGES_STDDEV_POPULATION_VALUE_ASC = 'METER_CHARGES_STDDEV_POPULATION_VALUE_ASC',
  METER_CHARGES_STDDEV_POPULATION_VALUE_DESC = 'METER_CHARGES_STDDEV_POPULATION_VALUE_DESC',
  METER_CHARGES_STDDEV_SAMPLE_CATEGORY_ASC = 'METER_CHARGES_STDDEV_SAMPLE_CATEGORY_ASC',
  METER_CHARGES_STDDEV_SAMPLE_CATEGORY_DESC = 'METER_CHARGES_STDDEV_SAMPLE_CATEGORY_DESC',
  METER_CHARGES_STDDEV_SAMPLE_CREATED_AT_ASC = 'METER_CHARGES_STDDEV_SAMPLE_CREATED_AT_ASC',
  METER_CHARGES_STDDEV_SAMPLE_CREATED_AT_DESC = 'METER_CHARGES_STDDEV_SAMPLE_CREATED_AT_DESC',
  METER_CHARGES_STDDEV_SAMPLE_DELETED_AT_ASC = 'METER_CHARGES_STDDEV_SAMPLE_DELETED_AT_ASC',
  METER_CHARGES_STDDEV_SAMPLE_DELETED_AT_DESC = 'METER_CHARGES_STDDEV_SAMPLE_DELETED_AT_DESC',
  METER_CHARGES_STDDEV_SAMPLE_ID_ASC = 'METER_CHARGES_STDDEV_SAMPLE_ID_ASC',
  METER_CHARGES_STDDEV_SAMPLE_ID_DESC = 'METER_CHARGES_STDDEV_SAMPLE_ID_DESC',
  METER_CHARGES_STDDEV_SAMPLE_LABEL_ASC = 'METER_CHARGES_STDDEV_SAMPLE_LABEL_ASC',
  METER_CHARGES_STDDEV_SAMPLE_LABEL_DESC = 'METER_CHARGES_STDDEV_SAMPLE_LABEL_DESC',
  METER_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_ASC = 'METER_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_ASC',
  METER_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_DESC = 'METER_CHARGES_STDDEV_SAMPLE_LINE_NUMBER_DESC',
  METER_CHARGES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_CHARGES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_CHARGES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_CHARGES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_ASC = 'METER_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_ASC',
  METER_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_DESC = 'METER_CHARGES_STDDEV_SAMPLE_NORMALIZED_VALUE_DESC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC',
  METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC = 'METER_CHARGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC',
  METER_CHARGES_STDDEV_SAMPLE_UPDATED_AT_ASC = 'METER_CHARGES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  METER_CHARGES_STDDEV_SAMPLE_UPDATED_AT_DESC = 'METER_CHARGES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  METER_CHARGES_STDDEV_SAMPLE_VALUE_ASC = 'METER_CHARGES_STDDEV_SAMPLE_VALUE_ASC',
  METER_CHARGES_STDDEV_SAMPLE_VALUE_DESC = 'METER_CHARGES_STDDEV_SAMPLE_VALUE_DESC',
  METER_CHARGES_SUM_CATEGORY_ASC = 'METER_CHARGES_SUM_CATEGORY_ASC',
  METER_CHARGES_SUM_CATEGORY_DESC = 'METER_CHARGES_SUM_CATEGORY_DESC',
  METER_CHARGES_SUM_CREATED_AT_ASC = 'METER_CHARGES_SUM_CREATED_AT_ASC',
  METER_CHARGES_SUM_CREATED_AT_DESC = 'METER_CHARGES_SUM_CREATED_AT_DESC',
  METER_CHARGES_SUM_DELETED_AT_ASC = 'METER_CHARGES_SUM_DELETED_AT_ASC',
  METER_CHARGES_SUM_DELETED_AT_DESC = 'METER_CHARGES_SUM_DELETED_AT_DESC',
  METER_CHARGES_SUM_ID_ASC = 'METER_CHARGES_SUM_ID_ASC',
  METER_CHARGES_SUM_ID_DESC = 'METER_CHARGES_SUM_ID_DESC',
  METER_CHARGES_SUM_LABEL_ASC = 'METER_CHARGES_SUM_LABEL_ASC',
  METER_CHARGES_SUM_LABEL_DESC = 'METER_CHARGES_SUM_LABEL_DESC',
  METER_CHARGES_SUM_LINE_NUMBER_ASC = 'METER_CHARGES_SUM_LINE_NUMBER_ASC',
  METER_CHARGES_SUM_LINE_NUMBER_DESC = 'METER_CHARGES_SUM_LINE_NUMBER_DESC',
  METER_CHARGES_SUM_METER_STATEMENT_ID_ASC = 'METER_CHARGES_SUM_METER_STATEMENT_ID_ASC',
  METER_CHARGES_SUM_METER_STATEMENT_ID_DESC = 'METER_CHARGES_SUM_METER_STATEMENT_ID_DESC',
  METER_CHARGES_SUM_NORMALIZED_VALUE_ASC = 'METER_CHARGES_SUM_NORMALIZED_VALUE_ASC',
  METER_CHARGES_SUM_NORMALIZED_VALUE_DESC = 'METER_CHARGES_SUM_NORMALIZED_VALUE_DESC',
  METER_CHARGES_SUM_OVERRIDE_AT_ASC = 'METER_CHARGES_SUM_OVERRIDE_AT_ASC',
  METER_CHARGES_SUM_OVERRIDE_AT_DESC = 'METER_CHARGES_SUM_OVERRIDE_AT_DESC',
  METER_CHARGES_SUM_OVERRIDE_LABEL_ASC = 'METER_CHARGES_SUM_OVERRIDE_LABEL_ASC',
  METER_CHARGES_SUM_OVERRIDE_LABEL_DESC = 'METER_CHARGES_SUM_OVERRIDE_LABEL_DESC',
  METER_CHARGES_SUM_OVERRIDE_VALUE_ASC = 'METER_CHARGES_SUM_OVERRIDE_VALUE_ASC',
  METER_CHARGES_SUM_OVERRIDE_VALUE_DESC = 'METER_CHARGES_SUM_OVERRIDE_VALUE_DESC',
  METER_CHARGES_SUM_UPDATED_AT_ASC = 'METER_CHARGES_SUM_UPDATED_AT_ASC',
  METER_CHARGES_SUM_UPDATED_AT_DESC = 'METER_CHARGES_SUM_UPDATED_AT_DESC',
  METER_CHARGES_SUM_VALUE_ASC = 'METER_CHARGES_SUM_VALUE_ASC',
  METER_CHARGES_SUM_VALUE_DESC = 'METER_CHARGES_SUM_VALUE_DESC',
  METER_CHARGES_VARIANCE_POPULATION_CATEGORY_ASC = 'METER_CHARGES_VARIANCE_POPULATION_CATEGORY_ASC',
  METER_CHARGES_VARIANCE_POPULATION_CATEGORY_DESC = 'METER_CHARGES_VARIANCE_POPULATION_CATEGORY_DESC',
  METER_CHARGES_VARIANCE_POPULATION_CREATED_AT_ASC = 'METER_CHARGES_VARIANCE_POPULATION_CREATED_AT_ASC',
  METER_CHARGES_VARIANCE_POPULATION_CREATED_AT_DESC = 'METER_CHARGES_VARIANCE_POPULATION_CREATED_AT_DESC',
  METER_CHARGES_VARIANCE_POPULATION_DELETED_AT_ASC = 'METER_CHARGES_VARIANCE_POPULATION_DELETED_AT_ASC',
  METER_CHARGES_VARIANCE_POPULATION_DELETED_AT_DESC = 'METER_CHARGES_VARIANCE_POPULATION_DELETED_AT_DESC',
  METER_CHARGES_VARIANCE_POPULATION_ID_ASC = 'METER_CHARGES_VARIANCE_POPULATION_ID_ASC',
  METER_CHARGES_VARIANCE_POPULATION_ID_DESC = 'METER_CHARGES_VARIANCE_POPULATION_ID_DESC',
  METER_CHARGES_VARIANCE_POPULATION_LABEL_ASC = 'METER_CHARGES_VARIANCE_POPULATION_LABEL_ASC',
  METER_CHARGES_VARIANCE_POPULATION_LABEL_DESC = 'METER_CHARGES_VARIANCE_POPULATION_LABEL_DESC',
  METER_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_ASC = 'METER_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_ASC',
  METER_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_DESC = 'METER_CHARGES_VARIANCE_POPULATION_LINE_NUMBER_DESC',
  METER_CHARGES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'METER_CHARGES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  METER_CHARGES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'METER_CHARGES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  METER_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_ASC = 'METER_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_ASC',
  METER_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_DESC = 'METER_CHARGES_VARIANCE_POPULATION_NORMALIZED_VALUE_DESC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC',
  METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC = 'METER_CHARGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC',
  METER_CHARGES_VARIANCE_POPULATION_UPDATED_AT_ASC = 'METER_CHARGES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  METER_CHARGES_VARIANCE_POPULATION_UPDATED_AT_DESC = 'METER_CHARGES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  METER_CHARGES_VARIANCE_POPULATION_VALUE_ASC = 'METER_CHARGES_VARIANCE_POPULATION_VALUE_ASC',
  METER_CHARGES_VARIANCE_POPULATION_VALUE_DESC = 'METER_CHARGES_VARIANCE_POPULATION_VALUE_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_CATEGORY_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_CATEGORY_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_CATEGORY_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_CATEGORY_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_DELETED_AT_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_DELETED_AT_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_DELETED_AT_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_DELETED_AT_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_ID_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_ID_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_ID_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_ID_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_LABEL_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_LABEL_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_LABEL_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_LABEL_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_NORMALIZED_VALUE_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  METER_CHARGES_VARIANCE_SAMPLE_VALUE_ASC = 'METER_CHARGES_VARIANCE_SAMPLE_VALUE_ASC',
  METER_CHARGES_VARIANCE_SAMPLE_VALUE_DESC = 'METER_CHARGES_VARIANCE_SAMPLE_VALUE_DESC',
  METER_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_AVERAGE_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_AVERAGE_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_AVERAGE_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_AVERAGE_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_AVERAGE_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_AVERAGE_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_COUNT_ASC = 'METER_STATEMENT_HISTORIES_COUNT_ASC',
  METER_STATEMENT_HISTORIES_COUNT_DESC = 'METER_STATEMENT_HISTORIES_COUNT_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_DISTINCT_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_DISTINCT_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_DISTINCT_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_DISTINCT_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_MAX_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_MAX_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_MAX_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_MAX_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_MAX_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_MAX_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_MAX_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_MAX_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_MAX_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_MAX_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_MAX_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_MAX_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_MIN_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_MIN_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_MIN_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_MIN_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_MIN_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_MIN_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_MIN_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_MIN_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_MIN_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_MIN_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_MIN_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_MIN_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_SUM_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_SUM_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_SUM_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_SUM_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_SUM_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_SUM_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_SUM_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_SUM_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_SUM_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_SUM_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_SUM_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_SUM_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_STATEMENT_HISTORIES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_USAGES_AVERAGE_CATEGORY_ASC = 'METER_USAGES_AVERAGE_CATEGORY_ASC',
  METER_USAGES_AVERAGE_CATEGORY_DESC = 'METER_USAGES_AVERAGE_CATEGORY_DESC',
  METER_USAGES_AVERAGE_CREATED_AT_ASC = 'METER_USAGES_AVERAGE_CREATED_AT_ASC',
  METER_USAGES_AVERAGE_CREATED_AT_DESC = 'METER_USAGES_AVERAGE_CREATED_AT_DESC',
  METER_USAGES_AVERAGE_DELETED_AT_ASC = 'METER_USAGES_AVERAGE_DELETED_AT_ASC',
  METER_USAGES_AVERAGE_DELETED_AT_DESC = 'METER_USAGES_AVERAGE_DELETED_AT_DESC',
  METER_USAGES_AVERAGE_ID_ASC = 'METER_USAGES_AVERAGE_ID_ASC',
  METER_USAGES_AVERAGE_ID_DESC = 'METER_USAGES_AVERAGE_ID_DESC',
  METER_USAGES_AVERAGE_LABEL_ASC = 'METER_USAGES_AVERAGE_LABEL_ASC',
  METER_USAGES_AVERAGE_LABEL_DESC = 'METER_USAGES_AVERAGE_LABEL_DESC',
  METER_USAGES_AVERAGE_LINE_NUMBER_ASC = 'METER_USAGES_AVERAGE_LINE_NUMBER_ASC',
  METER_USAGES_AVERAGE_LINE_NUMBER_DESC = 'METER_USAGES_AVERAGE_LINE_NUMBER_DESC',
  METER_USAGES_AVERAGE_METER_STATEMENT_ID_ASC = 'METER_USAGES_AVERAGE_METER_STATEMENT_ID_ASC',
  METER_USAGES_AVERAGE_METER_STATEMENT_ID_DESC = 'METER_USAGES_AVERAGE_METER_STATEMENT_ID_DESC',
  METER_USAGES_AVERAGE_OVERRIDE_AT_ASC = 'METER_USAGES_AVERAGE_OVERRIDE_AT_ASC',
  METER_USAGES_AVERAGE_OVERRIDE_AT_DESC = 'METER_USAGES_AVERAGE_OVERRIDE_AT_DESC',
  METER_USAGES_AVERAGE_OVERRIDE_LABEL_ASC = 'METER_USAGES_AVERAGE_OVERRIDE_LABEL_ASC',
  METER_USAGES_AVERAGE_OVERRIDE_LABEL_DESC = 'METER_USAGES_AVERAGE_OVERRIDE_LABEL_DESC',
  METER_USAGES_AVERAGE_OVERRIDE_UNITS_ASC = 'METER_USAGES_AVERAGE_OVERRIDE_UNITS_ASC',
  METER_USAGES_AVERAGE_OVERRIDE_UNITS_DESC = 'METER_USAGES_AVERAGE_OVERRIDE_UNITS_DESC',
  METER_USAGES_AVERAGE_OVERRIDE_VALUE_ASC = 'METER_USAGES_AVERAGE_OVERRIDE_VALUE_ASC',
  METER_USAGES_AVERAGE_OVERRIDE_VALUE_DESC = 'METER_USAGES_AVERAGE_OVERRIDE_VALUE_DESC',
  METER_USAGES_AVERAGE_UNITS_ASC = 'METER_USAGES_AVERAGE_UNITS_ASC',
  METER_USAGES_AVERAGE_UNITS_DESC = 'METER_USAGES_AVERAGE_UNITS_DESC',
  METER_USAGES_AVERAGE_UPDATED_AT_ASC = 'METER_USAGES_AVERAGE_UPDATED_AT_ASC',
  METER_USAGES_AVERAGE_UPDATED_AT_DESC = 'METER_USAGES_AVERAGE_UPDATED_AT_DESC',
  METER_USAGES_AVERAGE_VALUE_ASC = 'METER_USAGES_AVERAGE_VALUE_ASC',
  METER_USAGES_AVERAGE_VALUE_DESC = 'METER_USAGES_AVERAGE_VALUE_DESC',
  METER_USAGES_COUNT_ASC = 'METER_USAGES_COUNT_ASC',
  METER_USAGES_COUNT_DESC = 'METER_USAGES_COUNT_DESC',
  METER_USAGES_DISTINCT_CATEGORY_ASC = 'METER_USAGES_DISTINCT_CATEGORY_ASC',
  METER_USAGES_DISTINCT_CATEGORY_DESC = 'METER_USAGES_DISTINCT_CATEGORY_DESC',
  METER_USAGES_DISTINCT_COUNT_CATEGORY_ASC = 'METER_USAGES_DISTINCT_COUNT_CATEGORY_ASC',
  METER_USAGES_DISTINCT_COUNT_CATEGORY_DESC = 'METER_USAGES_DISTINCT_COUNT_CATEGORY_DESC',
  METER_USAGES_DISTINCT_COUNT_CREATED_AT_ASC = 'METER_USAGES_DISTINCT_COUNT_CREATED_AT_ASC',
  METER_USAGES_DISTINCT_COUNT_CREATED_AT_DESC = 'METER_USAGES_DISTINCT_COUNT_CREATED_AT_DESC',
  METER_USAGES_DISTINCT_COUNT_DELETED_AT_ASC = 'METER_USAGES_DISTINCT_COUNT_DELETED_AT_ASC',
  METER_USAGES_DISTINCT_COUNT_DELETED_AT_DESC = 'METER_USAGES_DISTINCT_COUNT_DELETED_AT_DESC',
  METER_USAGES_DISTINCT_COUNT_ID_ASC = 'METER_USAGES_DISTINCT_COUNT_ID_ASC',
  METER_USAGES_DISTINCT_COUNT_ID_DESC = 'METER_USAGES_DISTINCT_COUNT_ID_DESC',
  METER_USAGES_DISTINCT_COUNT_LABEL_ASC = 'METER_USAGES_DISTINCT_COUNT_LABEL_ASC',
  METER_USAGES_DISTINCT_COUNT_LABEL_DESC = 'METER_USAGES_DISTINCT_COUNT_LABEL_DESC',
  METER_USAGES_DISTINCT_COUNT_LINE_NUMBER_ASC = 'METER_USAGES_DISTINCT_COUNT_LINE_NUMBER_ASC',
  METER_USAGES_DISTINCT_COUNT_LINE_NUMBER_DESC = 'METER_USAGES_DISTINCT_COUNT_LINE_NUMBER_DESC',
  METER_USAGES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'METER_USAGES_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  METER_USAGES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'METER_USAGES_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_LABEL_ASC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_LABEL_DESC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_UNITS_ASC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_UNITS_ASC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_UNITS_DESC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_UNITS_DESC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_VALUE_ASC',
  METER_USAGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC = 'METER_USAGES_DISTINCT_COUNT_OVERRIDE_VALUE_DESC',
  METER_USAGES_DISTINCT_COUNT_UNITS_ASC = 'METER_USAGES_DISTINCT_COUNT_UNITS_ASC',
  METER_USAGES_DISTINCT_COUNT_UNITS_DESC = 'METER_USAGES_DISTINCT_COUNT_UNITS_DESC',
  METER_USAGES_DISTINCT_COUNT_UPDATED_AT_ASC = 'METER_USAGES_DISTINCT_COUNT_UPDATED_AT_ASC',
  METER_USAGES_DISTINCT_COUNT_UPDATED_AT_DESC = 'METER_USAGES_DISTINCT_COUNT_UPDATED_AT_DESC',
  METER_USAGES_DISTINCT_COUNT_VALUE_ASC = 'METER_USAGES_DISTINCT_COUNT_VALUE_ASC',
  METER_USAGES_DISTINCT_COUNT_VALUE_DESC = 'METER_USAGES_DISTINCT_COUNT_VALUE_DESC',
  METER_USAGES_DISTINCT_CREATED_AT_ASC = 'METER_USAGES_DISTINCT_CREATED_AT_ASC',
  METER_USAGES_DISTINCT_CREATED_AT_DESC = 'METER_USAGES_DISTINCT_CREATED_AT_DESC',
  METER_USAGES_DISTINCT_DELETED_AT_ASC = 'METER_USAGES_DISTINCT_DELETED_AT_ASC',
  METER_USAGES_DISTINCT_DELETED_AT_DESC = 'METER_USAGES_DISTINCT_DELETED_AT_DESC',
  METER_USAGES_DISTINCT_ID_ASC = 'METER_USAGES_DISTINCT_ID_ASC',
  METER_USAGES_DISTINCT_ID_DESC = 'METER_USAGES_DISTINCT_ID_DESC',
  METER_USAGES_DISTINCT_LABEL_ASC = 'METER_USAGES_DISTINCT_LABEL_ASC',
  METER_USAGES_DISTINCT_LABEL_DESC = 'METER_USAGES_DISTINCT_LABEL_DESC',
  METER_USAGES_DISTINCT_LINE_NUMBER_ASC = 'METER_USAGES_DISTINCT_LINE_NUMBER_ASC',
  METER_USAGES_DISTINCT_LINE_NUMBER_DESC = 'METER_USAGES_DISTINCT_LINE_NUMBER_DESC',
  METER_USAGES_DISTINCT_METER_STATEMENT_ID_ASC = 'METER_USAGES_DISTINCT_METER_STATEMENT_ID_ASC',
  METER_USAGES_DISTINCT_METER_STATEMENT_ID_DESC = 'METER_USAGES_DISTINCT_METER_STATEMENT_ID_DESC',
  METER_USAGES_DISTINCT_OVERRIDE_AT_ASC = 'METER_USAGES_DISTINCT_OVERRIDE_AT_ASC',
  METER_USAGES_DISTINCT_OVERRIDE_AT_DESC = 'METER_USAGES_DISTINCT_OVERRIDE_AT_DESC',
  METER_USAGES_DISTINCT_OVERRIDE_LABEL_ASC = 'METER_USAGES_DISTINCT_OVERRIDE_LABEL_ASC',
  METER_USAGES_DISTINCT_OVERRIDE_LABEL_DESC = 'METER_USAGES_DISTINCT_OVERRIDE_LABEL_DESC',
  METER_USAGES_DISTINCT_OVERRIDE_UNITS_ASC = 'METER_USAGES_DISTINCT_OVERRIDE_UNITS_ASC',
  METER_USAGES_DISTINCT_OVERRIDE_UNITS_DESC = 'METER_USAGES_DISTINCT_OVERRIDE_UNITS_DESC',
  METER_USAGES_DISTINCT_OVERRIDE_VALUE_ASC = 'METER_USAGES_DISTINCT_OVERRIDE_VALUE_ASC',
  METER_USAGES_DISTINCT_OVERRIDE_VALUE_DESC = 'METER_USAGES_DISTINCT_OVERRIDE_VALUE_DESC',
  METER_USAGES_DISTINCT_UNITS_ASC = 'METER_USAGES_DISTINCT_UNITS_ASC',
  METER_USAGES_DISTINCT_UNITS_DESC = 'METER_USAGES_DISTINCT_UNITS_DESC',
  METER_USAGES_DISTINCT_UPDATED_AT_ASC = 'METER_USAGES_DISTINCT_UPDATED_AT_ASC',
  METER_USAGES_DISTINCT_UPDATED_AT_DESC = 'METER_USAGES_DISTINCT_UPDATED_AT_DESC',
  METER_USAGES_DISTINCT_VALUE_ASC = 'METER_USAGES_DISTINCT_VALUE_ASC',
  METER_USAGES_DISTINCT_VALUE_DESC = 'METER_USAGES_DISTINCT_VALUE_DESC',
  METER_USAGES_MAX_CATEGORY_ASC = 'METER_USAGES_MAX_CATEGORY_ASC',
  METER_USAGES_MAX_CATEGORY_DESC = 'METER_USAGES_MAX_CATEGORY_DESC',
  METER_USAGES_MAX_CREATED_AT_ASC = 'METER_USAGES_MAX_CREATED_AT_ASC',
  METER_USAGES_MAX_CREATED_AT_DESC = 'METER_USAGES_MAX_CREATED_AT_DESC',
  METER_USAGES_MAX_DELETED_AT_ASC = 'METER_USAGES_MAX_DELETED_AT_ASC',
  METER_USAGES_MAX_DELETED_AT_DESC = 'METER_USAGES_MAX_DELETED_AT_DESC',
  METER_USAGES_MAX_ID_ASC = 'METER_USAGES_MAX_ID_ASC',
  METER_USAGES_MAX_ID_DESC = 'METER_USAGES_MAX_ID_DESC',
  METER_USAGES_MAX_LABEL_ASC = 'METER_USAGES_MAX_LABEL_ASC',
  METER_USAGES_MAX_LABEL_DESC = 'METER_USAGES_MAX_LABEL_DESC',
  METER_USAGES_MAX_LINE_NUMBER_ASC = 'METER_USAGES_MAX_LINE_NUMBER_ASC',
  METER_USAGES_MAX_LINE_NUMBER_DESC = 'METER_USAGES_MAX_LINE_NUMBER_DESC',
  METER_USAGES_MAX_METER_STATEMENT_ID_ASC = 'METER_USAGES_MAX_METER_STATEMENT_ID_ASC',
  METER_USAGES_MAX_METER_STATEMENT_ID_DESC = 'METER_USAGES_MAX_METER_STATEMENT_ID_DESC',
  METER_USAGES_MAX_OVERRIDE_AT_ASC = 'METER_USAGES_MAX_OVERRIDE_AT_ASC',
  METER_USAGES_MAX_OVERRIDE_AT_DESC = 'METER_USAGES_MAX_OVERRIDE_AT_DESC',
  METER_USAGES_MAX_OVERRIDE_LABEL_ASC = 'METER_USAGES_MAX_OVERRIDE_LABEL_ASC',
  METER_USAGES_MAX_OVERRIDE_LABEL_DESC = 'METER_USAGES_MAX_OVERRIDE_LABEL_DESC',
  METER_USAGES_MAX_OVERRIDE_UNITS_ASC = 'METER_USAGES_MAX_OVERRIDE_UNITS_ASC',
  METER_USAGES_MAX_OVERRIDE_UNITS_DESC = 'METER_USAGES_MAX_OVERRIDE_UNITS_DESC',
  METER_USAGES_MAX_OVERRIDE_VALUE_ASC = 'METER_USAGES_MAX_OVERRIDE_VALUE_ASC',
  METER_USAGES_MAX_OVERRIDE_VALUE_DESC = 'METER_USAGES_MAX_OVERRIDE_VALUE_DESC',
  METER_USAGES_MAX_UNITS_ASC = 'METER_USAGES_MAX_UNITS_ASC',
  METER_USAGES_MAX_UNITS_DESC = 'METER_USAGES_MAX_UNITS_DESC',
  METER_USAGES_MAX_UPDATED_AT_ASC = 'METER_USAGES_MAX_UPDATED_AT_ASC',
  METER_USAGES_MAX_UPDATED_AT_DESC = 'METER_USAGES_MAX_UPDATED_AT_DESC',
  METER_USAGES_MAX_VALUE_ASC = 'METER_USAGES_MAX_VALUE_ASC',
  METER_USAGES_MAX_VALUE_DESC = 'METER_USAGES_MAX_VALUE_DESC',
  METER_USAGES_MIN_CATEGORY_ASC = 'METER_USAGES_MIN_CATEGORY_ASC',
  METER_USAGES_MIN_CATEGORY_DESC = 'METER_USAGES_MIN_CATEGORY_DESC',
  METER_USAGES_MIN_CREATED_AT_ASC = 'METER_USAGES_MIN_CREATED_AT_ASC',
  METER_USAGES_MIN_CREATED_AT_DESC = 'METER_USAGES_MIN_CREATED_AT_DESC',
  METER_USAGES_MIN_DELETED_AT_ASC = 'METER_USAGES_MIN_DELETED_AT_ASC',
  METER_USAGES_MIN_DELETED_AT_DESC = 'METER_USAGES_MIN_DELETED_AT_DESC',
  METER_USAGES_MIN_ID_ASC = 'METER_USAGES_MIN_ID_ASC',
  METER_USAGES_MIN_ID_DESC = 'METER_USAGES_MIN_ID_DESC',
  METER_USAGES_MIN_LABEL_ASC = 'METER_USAGES_MIN_LABEL_ASC',
  METER_USAGES_MIN_LABEL_DESC = 'METER_USAGES_MIN_LABEL_DESC',
  METER_USAGES_MIN_LINE_NUMBER_ASC = 'METER_USAGES_MIN_LINE_NUMBER_ASC',
  METER_USAGES_MIN_LINE_NUMBER_DESC = 'METER_USAGES_MIN_LINE_NUMBER_DESC',
  METER_USAGES_MIN_METER_STATEMENT_ID_ASC = 'METER_USAGES_MIN_METER_STATEMENT_ID_ASC',
  METER_USAGES_MIN_METER_STATEMENT_ID_DESC = 'METER_USAGES_MIN_METER_STATEMENT_ID_DESC',
  METER_USAGES_MIN_OVERRIDE_AT_ASC = 'METER_USAGES_MIN_OVERRIDE_AT_ASC',
  METER_USAGES_MIN_OVERRIDE_AT_DESC = 'METER_USAGES_MIN_OVERRIDE_AT_DESC',
  METER_USAGES_MIN_OVERRIDE_LABEL_ASC = 'METER_USAGES_MIN_OVERRIDE_LABEL_ASC',
  METER_USAGES_MIN_OVERRIDE_LABEL_DESC = 'METER_USAGES_MIN_OVERRIDE_LABEL_DESC',
  METER_USAGES_MIN_OVERRIDE_UNITS_ASC = 'METER_USAGES_MIN_OVERRIDE_UNITS_ASC',
  METER_USAGES_MIN_OVERRIDE_UNITS_DESC = 'METER_USAGES_MIN_OVERRIDE_UNITS_DESC',
  METER_USAGES_MIN_OVERRIDE_VALUE_ASC = 'METER_USAGES_MIN_OVERRIDE_VALUE_ASC',
  METER_USAGES_MIN_OVERRIDE_VALUE_DESC = 'METER_USAGES_MIN_OVERRIDE_VALUE_DESC',
  METER_USAGES_MIN_UNITS_ASC = 'METER_USAGES_MIN_UNITS_ASC',
  METER_USAGES_MIN_UNITS_DESC = 'METER_USAGES_MIN_UNITS_DESC',
  METER_USAGES_MIN_UPDATED_AT_ASC = 'METER_USAGES_MIN_UPDATED_AT_ASC',
  METER_USAGES_MIN_UPDATED_AT_DESC = 'METER_USAGES_MIN_UPDATED_AT_DESC',
  METER_USAGES_MIN_VALUE_ASC = 'METER_USAGES_MIN_VALUE_ASC',
  METER_USAGES_MIN_VALUE_DESC = 'METER_USAGES_MIN_VALUE_DESC',
  METER_USAGES_STDDEV_POPULATION_CATEGORY_ASC = 'METER_USAGES_STDDEV_POPULATION_CATEGORY_ASC',
  METER_USAGES_STDDEV_POPULATION_CATEGORY_DESC = 'METER_USAGES_STDDEV_POPULATION_CATEGORY_DESC',
  METER_USAGES_STDDEV_POPULATION_CREATED_AT_ASC = 'METER_USAGES_STDDEV_POPULATION_CREATED_AT_ASC',
  METER_USAGES_STDDEV_POPULATION_CREATED_AT_DESC = 'METER_USAGES_STDDEV_POPULATION_CREATED_AT_DESC',
  METER_USAGES_STDDEV_POPULATION_DELETED_AT_ASC = 'METER_USAGES_STDDEV_POPULATION_DELETED_AT_ASC',
  METER_USAGES_STDDEV_POPULATION_DELETED_AT_DESC = 'METER_USAGES_STDDEV_POPULATION_DELETED_AT_DESC',
  METER_USAGES_STDDEV_POPULATION_ID_ASC = 'METER_USAGES_STDDEV_POPULATION_ID_ASC',
  METER_USAGES_STDDEV_POPULATION_ID_DESC = 'METER_USAGES_STDDEV_POPULATION_ID_DESC',
  METER_USAGES_STDDEV_POPULATION_LABEL_ASC = 'METER_USAGES_STDDEV_POPULATION_LABEL_ASC',
  METER_USAGES_STDDEV_POPULATION_LABEL_DESC = 'METER_USAGES_STDDEV_POPULATION_LABEL_DESC',
  METER_USAGES_STDDEV_POPULATION_LINE_NUMBER_ASC = 'METER_USAGES_STDDEV_POPULATION_LINE_NUMBER_ASC',
  METER_USAGES_STDDEV_POPULATION_LINE_NUMBER_DESC = 'METER_USAGES_STDDEV_POPULATION_LINE_NUMBER_DESC',
  METER_USAGES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'METER_USAGES_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  METER_USAGES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'METER_USAGES_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_LABEL_ASC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_LABEL_DESC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_UNITS_ASC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_UNITS_ASC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_UNITS_DESC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_UNITS_DESC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_VALUE_ASC',
  METER_USAGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC = 'METER_USAGES_STDDEV_POPULATION_OVERRIDE_VALUE_DESC',
  METER_USAGES_STDDEV_POPULATION_UNITS_ASC = 'METER_USAGES_STDDEV_POPULATION_UNITS_ASC',
  METER_USAGES_STDDEV_POPULATION_UNITS_DESC = 'METER_USAGES_STDDEV_POPULATION_UNITS_DESC',
  METER_USAGES_STDDEV_POPULATION_UPDATED_AT_ASC = 'METER_USAGES_STDDEV_POPULATION_UPDATED_AT_ASC',
  METER_USAGES_STDDEV_POPULATION_UPDATED_AT_DESC = 'METER_USAGES_STDDEV_POPULATION_UPDATED_AT_DESC',
  METER_USAGES_STDDEV_POPULATION_VALUE_ASC = 'METER_USAGES_STDDEV_POPULATION_VALUE_ASC',
  METER_USAGES_STDDEV_POPULATION_VALUE_DESC = 'METER_USAGES_STDDEV_POPULATION_VALUE_DESC',
  METER_USAGES_STDDEV_SAMPLE_CATEGORY_ASC = 'METER_USAGES_STDDEV_SAMPLE_CATEGORY_ASC',
  METER_USAGES_STDDEV_SAMPLE_CATEGORY_DESC = 'METER_USAGES_STDDEV_SAMPLE_CATEGORY_DESC',
  METER_USAGES_STDDEV_SAMPLE_CREATED_AT_ASC = 'METER_USAGES_STDDEV_SAMPLE_CREATED_AT_ASC',
  METER_USAGES_STDDEV_SAMPLE_CREATED_AT_DESC = 'METER_USAGES_STDDEV_SAMPLE_CREATED_AT_DESC',
  METER_USAGES_STDDEV_SAMPLE_DELETED_AT_ASC = 'METER_USAGES_STDDEV_SAMPLE_DELETED_AT_ASC',
  METER_USAGES_STDDEV_SAMPLE_DELETED_AT_DESC = 'METER_USAGES_STDDEV_SAMPLE_DELETED_AT_DESC',
  METER_USAGES_STDDEV_SAMPLE_ID_ASC = 'METER_USAGES_STDDEV_SAMPLE_ID_ASC',
  METER_USAGES_STDDEV_SAMPLE_ID_DESC = 'METER_USAGES_STDDEV_SAMPLE_ID_DESC',
  METER_USAGES_STDDEV_SAMPLE_LABEL_ASC = 'METER_USAGES_STDDEV_SAMPLE_LABEL_ASC',
  METER_USAGES_STDDEV_SAMPLE_LABEL_DESC = 'METER_USAGES_STDDEV_SAMPLE_LABEL_DESC',
  METER_USAGES_STDDEV_SAMPLE_LINE_NUMBER_ASC = 'METER_USAGES_STDDEV_SAMPLE_LINE_NUMBER_ASC',
  METER_USAGES_STDDEV_SAMPLE_LINE_NUMBER_DESC = 'METER_USAGES_STDDEV_SAMPLE_LINE_NUMBER_DESC',
  METER_USAGES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_USAGES_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_USAGES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_USAGES_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_LABEL_ASC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_LABEL_DESC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_UNITS_ASC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_UNITS_ASC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_UNITS_DESC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_UNITS_DESC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_VALUE_ASC',
  METER_USAGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC = 'METER_USAGES_STDDEV_SAMPLE_OVERRIDE_VALUE_DESC',
  METER_USAGES_STDDEV_SAMPLE_UNITS_ASC = 'METER_USAGES_STDDEV_SAMPLE_UNITS_ASC',
  METER_USAGES_STDDEV_SAMPLE_UNITS_DESC = 'METER_USAGES_STDDEV_SAMPLE_UNITS_DESC',
  METER_USAGES_STDDEV_SAMPLE_UPDATED_AT_ASC = 'METER_USAGES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  METER_USAGES_STDDEV_SAMPLE_UPDATED_AT_DESC = 'METER_USAGES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  METER_USAGES_STDDEV_SAMPLE_VALUE_ASC = 'METER_USAGES_STDDEV_SAMPLE_VALUE_ASC',
  METER_USAGES_STDDEV_SAMPLE_VALUE_DESC = 'METER_USAGES_STDDEV_SAMPLE_VALUE_DESC',
  METER_USAGES_SUM_CATEGORY_ASC = 'METER_USAGES_SUM_CATEGORY_ASC',
  METER_USAGES_SUM_CATEGORY_DESC = 'METER_USAGES_SUM_CATEGORY_DESC',
  METER_USAGES_SUM_CREATED_AT_ASC = 'METER_USAGES_SUM_CREATED_AT_ASC',
  METER_USAGES_SUM_CREATED_AT_DESC = 'METER_USAGES_SUM_CREATED_AT_DESC',
  METER_USAGES_SUM_DELETED_AT_ASC = 'METER_USAGES_SUM_DELETED_AT_ASC',
  METER_USAGES_SUM_DELETED_AT_DESC = 'METER_USAGES_SUM_DELETED_AT_DESC',
  METER_USAGES_SUM_ID_ASC = 'METER_USAGES_SUM_ID_ASC',
  METER_USAGES_SUM_ID_DESC = 'METER_USAGES_SUM_ID_DESC',
  METER_USAGES_SUM_LABEL_ASC = 'METER_USAGES_SUM_LABEL_ASC',
  METER_USAGES_SUM_LABEL_DESC = 'METER_USAGES_SUM_LABEL_DESC',
  METER_USAGES_SUM_LINE_NUMBER_ASC = 'METER_USAGES_SUM_LINE_NUMBER_ASC',
  METER_USAGES_SUM_LINE_NUMBER_DESC = 'METER_USAGES_SUM_LINE_NUMBER_DESC',
  METER_USAGES_SUM_METER_STATEMENT_ID_ASC = 'METER_USAGES_SUM_METER_STATEMENT_ID_ASC',
  METER_USAGES_SUM_METER_STATEMENT_ID_DESC = 'METER_USAGES_SUM_METER_STATEMENT_ID_DESC',
  METER_USAGES_SUM_OVERRIDE_AT_ASC = 'METER_USAGES_SUM_OVERRIDE_AT_ASC',
  METER_USAGES_SUM_OVERRIDE_AT_DESC = 'METER_USAGES_SUM_OVERRIDE_AT_DESC',
  METER_USAGES_SUM_OVERRIDE_LABEL_ASC = 'METER_USAGES_SUM_OVERRIDE_LABEL_ASC',
  METER_USAGES_SUM_OVERRIDE_LABEL_DESC = 'METER_USAGES_SUM_OVERRIDE_LABEL_DESC',
  METER_USAGES_SUM_OVERRIDE_UNITS_ASC = 'METER_USAGES_SUM_OVERRIDE_UNITS_ASC',
  METER_USAGES_SUM_OVERRIDE_UNITS_DESC = 'METER_USAGES_SUM_OVERRIDE_UNITS_DESC',
  METER_USAGES_SUM_OVERRIDE_VALUE_ASC = 'METER_USAGES_SUM_OVERRIDE_VALUE_ASC',
  METER_USAGES_SUM_OVERRIDE_VALUE_DESC = 'METER_USAGES_SUM_OVERRIDE_VALUE_DESC',
  METER_USAGES_SUM_UNITS_ASC = 'METER_USAGES_SUM_UNITS_ASC',
  METER_USAGES_SUM_UNITS_DESC = 'METER_USAGES_SUM_UNITS_DESC',
  METER_USAGES_SUM_UPDATED_AT_ASC = 'METER_USAGES_SUM_UPDATED_AT_ASC',
  METER_USAGES_SUM_UPDATED_AT_DESC = 'METER_USAGES_SUM_UPDATED_AT_DESC',
  METER_USAGES_SUM_VALUE_ASC = 'METER_USAGES_SUM_VALUE_ASC',
  METER_USAGES_SUM_VALUE_DESC = 'METER_USAGES_SUM_VALUE_DESC',
  METER_USAGES_VARIANCE_POPULATION_CATEGORY_ASC = 'METER_USAGES_VARIANCE_POPULATION_CATEGORY_ASC',
  METER_USAGES_VARIANCE_POPULATION_CATEGORY_DESC = 'METER_USAGES_VARIANCE_POPULATION_CATEGORY_DESC',
  METER_USAGES_VARIANCE_POPULATION_CREATED_AT_ASC = 'METER_USAGES_VARIANCE_POPULATION_CREATED_AT_ASC',
  METER_USAGES_VARIANCE_POPULATION_CREATED_AT_DESC = 'METER_USAGES_VARIANCE_POPULATION_CREATED_AT_DESC',
  METER_USAGES_VARIANCE_POPULATION_DELETED_AT_ASC = 'METER_USAGES_VARIANCE_POPULATION_DELETED_AT_ASC',
  METER_USAGES_VARIANCE_POPULATION_DELETED_AT_DESC = 'METER_USAGES_VARIANCE_POPULATION_DELETED_AT_DESC',
  METER_USAGES_VARIANCE_POPULATION_ID_ASC = 'METER_USAGES_VARIANCE_POPULATION_ID_ASC',
  METER_USAGES_VARIANCE_POPULATION_ID_DESC = 'METER_USAGES_VARIANCE_POPULATION_ID_DESC',
  METER_USAGES_VARIANCE_POPULATION_LABEL_ASC = 'METER_USAGES_VARIANCE_POPULATION_LABEL_ASC',
  METER_USAGES_VARIANCE_POPULATION_LABEL_DESC = 'METER_USAGES_VARIANCE_POPULATION_LABEL_DESC',
  METER_USAGES_VARIANCE_POPULATION_LINE_NUMBER_ASC = 'METER_USAGES_VARIANCE_POPULATION_LINE_NUMBER_ASC',
  METER_USAGES_VARIANCE_POPULATION_LINE_NUMBER_DESC = 'METER_USAGES_VARIANCE_POPULATION_LINE_NUMBER_DESC',
  METER_USAGES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'METER_USAGES_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  METER_USAGES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'METER_USAGES_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_LABEL_ASC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_LABEL_DESC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_UNITS_ASC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_UNITS_ASC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_UNITS_DESC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_UNITS_DESC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_VALUE_ASC',
  METER_USAGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC = 'METER_USAGES_VARIANCE_POPULATION_OVERRIDE_VALUE_DESC',
  METER_USAGES_VARIANCE_POPULATION_UNITS_ASC = 'METER_USAGES_VARIANCE_POPULATION_UNITS_ASC',
  METER_USAGES_VARIANCE_POPULATION_UNITS_DESC = 'METER_USAGES_VARIANCE_POPULATION_UNITS_DESC',
  METER_USAGES_VARIANCE_POPULATION_UPDATED_AT_ASC = 'METER_USAGES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  METER_USAGES_VARIANCE_POPULATION_UPDATED_AT_DESC = 'METER_USAGES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  METER_USAGES_VARIANCE_POPULATION_VALUE_ASC = 'METER_USAGES_VARIANCE_POPULATION_VALUE_ASC',
  METER_USAGES_VARIANCE_POPULATION_VALUE_DESC = 'METER_USAGES_VARIANCE_POPULATION_VALUE_DESC',
  METER_USAGES_VARIANCE_SAMPLE_CATEGORY_ASC = 'METER_USAGES_VARIANCE_SAMPLE_CATEGORY_ASC',
  METER_USAGES_VARIANCE_SAMPLE_CATEGORY_DESC = 'METER_USAGES_VARIANCE_SAMPLE_CATEGORY_DESC',
  METER_USAGES_VARIANCE_SAMPLE_CREATED_AT_ASC = 'METER_USAGES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  METER_USAGES_VARIANCE_SAMPLE_CREATED_AT_DESC = 'METER_USAGES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  METER_USAGES_VARIANCE_SAMPLE_DELETED_AT_ASC = 'METER_USAGES_VARIANCE_SAMPLE_DELETED_AT_ASC',
  METER_USAGES_VARIANCE_SAMPLE_DELETED_AT_DESC = 'METER_USAGES_VARIANCE_SAMPLE_DELETED_AT_DESC',
  METER_USAGES_VARIANCE_SAMPLE_ID_ASC = 'METER_USAGES_VARIANCE_SAMPLE_ID_ASC',
  METER_USAGES_VARIANCE_SAMPLE_ID_DESC = 'METER_USAGES_VARIANCE_SAMPLE_ID_DESC',
  METER_USAGES_VARIANCE_SAMPLE_LABEL_ASC = 'METER_USAGES_VARIANCE_SAMPLE_LABEL_ASC',
  METER_USAGES_VARIANCE_SAMPLE_LABEL_DESC = 'METER_USAGES_VARIANCE_SAMPLE_LABEL_DESC',
  METER_USAGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC = 'METER_USAGES_VARIANCE_SAMPLE_LINE_NUMBER_ASC',
  METER_USAGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC = 'METER_USAGES_VARIANCE_SAMPLE_LINE_NUMBER_DESC',
  METER_USAGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'METER_USAGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  METER_USAGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'METER_USAGES_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_ASC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_LABEL_DESC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_UNITS_ASC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_UNITS_ASC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_UNITS_DESC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_UNITS_DESC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_ASC',
  METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC = 'METER_USAGES_VARIANCE_SAMPLE_OVERRIDE_VALUE_DESC',
  METER_USAGES_VARIANCE_SAMPLE_UNITS_ASC = 'METER_USAGES_VARIANCE_SAMPLE_UNITS_ASC',
  METER_USAGES_VARIANCE_SAMPLE_UNITS_DESC = 'METER_USAGES_VARIANCE_SAMPLE_UNITS_DESC',
  METER_USAGES_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'METER_USAGES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  METER_USAGES_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'METER_USAGES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  METER_USAGES_VARIANCE_SAMPLE_VALUE_ASC = 'METER_USAGES_VARIANCE_SAMPLE_VALUE_ASC',
  METER_USAGES_VARIANCE_SAMPLE_VALUE_DESC = 'METER_USAGES_VARIANCE_SAMPLE_VALUE_DESC',
  NATURAL = 'NATURAL',
  NORMALIZED_CURRENCY_CODE_ASC = 'NORMALIZED_CURRENCY_CODE_ASC',
  NORMALIZED_CURRENCY_CODE_DESC = 'NORMALIZED_CURRENCY_CODE_DESC',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_CURRENCY_CODE_ASC = 'OVERRIDE_CURRENCY_CODE_ASC',
  OVERRIDE_CURRENCY_CODE_DESC = 'OVERRIDE_CURRENCY_CODE_DESC',
  OVERRIDE_DEMAND_UNITS_ASC = 'OVERRIDE_DEMAND_UNITS_ASC',
  OVERRIDE_DEMAND_UNITS_DESC = 'OVERRIDE_DEMAND_UNITS_DESC',
  OVERRIDE_STATEMENT_MONTH_ASC = 'OVERRIDE_STATEMENT_MONTH_ASC',
  OVERRIDE_STATEMENT_MONTH_DESC = 'OVERRIDE_STATEMENT_MONTH_DESC',
  OVERRIDE_STATEMENT_YEAR_ASC = 'OVERRIDE_STATEMENT_YEAR_ASC',
  OVERRIDE_STATEMENT_YEAR_DESC = 'OVERRIDE_STATEMENT_YEAR_DESC',
  OVERRIDE_USAGE_UNITS_ASC = 'OVERRIDE_USAGE_UNITS_ASC',
  OVERRIDE_USAGE_UNITS_DESC = 'OVERRIDE_USAGE_UNITS_DESC',
  PDF_LINK_ASC = 'PDF_LINK_ASC',
  PDF_LINK_DESC = 'PDF_LINK_DESC',
  PRIMARY_DEMAND_UNITS_ASC = 'PRIMARY_DEMAND_UNITS_ASC',
  PRIMARY_DEMAND_UNITS_DESC = 'PRIMARY_DEMAND_UNITS_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PRIMARY_USAGE_UNITS_ASC = 'PRIMARY_USAGE_UNITS_ASC',
  PRIMARY_USAGE_UNITS_DESC = 'PRIMARY_USAGE_UNITS_DESC',
  STATEMENT_DATE_ASC = 'STATEMENT_DATE_ASC',
  STATEMENT_DATE_DESC = 'STATEMENT_DATE_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_METER_ID_ASC = 'UTILITY_METER_ID_ASC',
  UTILITY_METER_ID_DESC = 'UTILITY_METER_ID_DESC'
}

export type MeterUsage = Node & {
  __typename?: 'MeterUsage';
  /** The category of the usage. */
  category: UtilityMeterUsageCategory;
  /** The time this usage was created. */
  createdAt: Scalars['Datetime'];
  /** The time the usage was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** This is set by the extraction process. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present. */
  lineNumber: Scalars['Int'];
  /** Reads a single `MeterStatement` that is related to this `MeterUsage`. */
  meterStatement?: Maybe<MeterStatement>;
  meterStatementId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** This is set by the extraction process. */
  units: Scalars['String'];
  /** The time this usage was updated. */
  updatedAt: Scalars['Datetime'];
  /** This is set by the extraction process. */
  value: Scalars['Float'];
};

export type MeterUsageAggregates = {
  __typename?: 'MeterUsageAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MeterUsageAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MeterUsageDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeterUsageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MeterUsageMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MeterUsageMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MeterUsageStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MeterUsageStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MeterUsageSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MeterUsageVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MeterUsageVarianceSampleAggregates>;
};

export type MeterUsageAverageAggregates = {
  __typename?: 'MeterUsageAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Mean average of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Mean average of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `MeterUsage` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MeterUsageCondition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<UtilityMeterUsageCategory>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideUnits` field. */
  overrideUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `units` field. */
  units?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['Float']>;
};

export type MeterUsageDistinctAggregates = {
  __typename?: 'MeterUsageDistinctAggregates';
  /** Distinct of category across the matching connection */
  category?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of label across the matching connection */
  label?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of lineNumber across the matching connection */
  lineNumber?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Distinct of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideUnits across the matching connection */
  overrideUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideValue across the matching connection */
  overrideValue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of units across the matching connection */
  units?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of value across the matching connection */
  value?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type MeterUsageDistinctCountAggregates = {
  __typename?: 'MeterUsageDistinctCountAggregates';
  /** Distinct count of category across the matching connection */
  category?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideLabel across the matching connection */
  overrideLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideUnits across the matching connection */
  overrideUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['BigInt']>;
  /** Distinct count of units across the matching connection */
  units?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MeterUsage` object types. All fields are combined with a logical ‘and.’ */
export type MeterUsageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeterUsageFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<UtilityMeterUsageCategoryFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeterUsageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeterUsageFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideLabel` field. */
  overrideLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideUnits` field. */
  overrideUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `overrideValue` field. */
  overrideValue?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `units` field. */
  units?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

/** An input for mutations affecting `MeterUsage` */
export type MeterUsageInput = {
  /** The category of the usage. */
  category: UtilityMeterUsageCategory;
  id?: InputMaybe<Scalars['Int']>;
  /** This is set by the extraction process. */
  label: Scalars['String'];
  /** This is used to further distinguish otherwise duplicate rows that need to be present. */
  lineNumber: Scalars['Int'];
  /** Mutates a `MeterStatement` as part of the payload */
  meterStatement?: InputMaybe<MeterStatementInput>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** This is set by the extraction process. */
  units: Scalars['String'];
  /** This is set by the extraction process. */
  value: Scalars['Float'];
};

export type MeterUsageMaxAggregates = {
  __typename?: 'MeterUsageMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Maximum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Maximum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterUsageMinAggregates = {
  __typename?: 'MeterUsageMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Minimum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Minimum of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** Represents an update to a `MeterUsage`. Fields that are set will be updated. */
export type MeterUsagePatch = {
  /** This overrides the label. */
  label?: InputMaybe<Scalars['String']>;
  /** This overrides the units. */
  units?: InputMaybe<Scalars['String']>;
  /** This overrides the value. */
  value?: InputMaybe<Scalars['Float']>;
};

export type MeterUsageStddevPopulationAggregates = {
  __typename?: 'MeterUsageStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterUsageStddevSampleAggregates = {
  __typename?: 'MeterUsageStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterUsageSumAggregates = {
  __typename?: 'MeterUsageSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars['BigInt'];
  /** Sum of meterStatementId across the matching connection */
  meterStatementId: Scalars['BigInt'];
  /** Sum of overrideValue across the matching connection */
  overrideValue: Scalars['Float'];
  /** Sum of value across the matching connection */
  value: Scalars['Float'];
};

export type MeterUsageVariancePopulationAggregates = {
  __typename?: 'MeterUsageVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Population variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

export type MeterUsageVarianceSampleAggregates = {
  __typename?: 'MeterUsageVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of overrideValue across the matching connection */
  overrideValue?: Maybe<Scalars['Float']>;
  /** Sample variance of value across the matching connection */
  value?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `MeterUsage` values. */
export type MeterUsagesConnection = {
  __typename?: 'MeterUsagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeterUsageAggregates>;
  /** A list of edges which contains the `MeterUsage` and cursor to aid in pagination. */
  edges: Array<MeterUsagesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeterUsageAggregates>>;
  /** A list of `MeterUsage` objects. */
  nodes: Array<MeterUsage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeterUsage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MeterUsage` values. */
export type MeterUsagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeterUsagesGroupBy>;
  having?: InputMaybe<MeterUsagesHavingInput>;
};

/** A `MeterUsage` edge in the connection. */
export type MeterUsagesEdge = {
  __typename?: 'MeterUsagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MeterUsage` at the end of the edge. */
  node: MeterUsage;
};

/** Grouping methods for `MeterUsage` for usage during aggregation. */
export enum MeterUsagesGroupBy {
  CATEGORY = 'CATEGORY',
  CATEGORY_DISTINCT = 'CATEGORY_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  LABEL = 'LABEL',
  LABEL_DISTINCT = 'LABEL_DISTINCT',
  LINE_NUMBER = 'LINE_NUMBER',
  LINE_NUMBER_DISTINCT = 'LINE_NUMBER_DISTINCT',
  METER_STATEMENT_ID = 'METER_STATEMENT_ID',
  METER_STATEMENT_ID_DISTINCT = 'METER_STATEMENT_ID_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_LABEL = 'OVERRIDE_LABEL',
  OVERRIDE_LABEL_DISTINCT = 'OVERRIDE_LABEL_DISTINCT',
  OVERRIDE_UNITS = 'OVERRIDE_UNITS',
  OVERRIDE_UNITS_DISTINCT = 'OVERRIDE_UNITS_DISTINCT',
  OVERRIDE_VALUE = 'OVERRIDE_VALUE',
  OVERRIDE_VALUE_DISTINCT = 'OVERRIDE_VALUE_DISTINCT',
  UNITS = 'UNITS',
  UNITS_DISTINCT = 'UNITS_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  VALUE = 'VALUE',
  VALUE_DISTINCT = 'VALUE_DISTINCT'
}

export type MeterUsagesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `MeterUsage` aggregates. */
export type MeterUsagesHavingInput = {
  AND?: InputMaybe<Array<MeterUsagesHavingInput>>;
  OR?: InputMaybe<Array<MeterUsagesHavingInput>>;
  average?: InputMaybe<MeterUsagesHavingAverageInput>;
  distinct?: InputMaybe<MeterUsagesHavingDistinctInput>;
  distinctCount?: InputMaybe<MeterUsagesHavingDistinctCountInput>;
  max?: InputMaybe<MeterUsagesHavingMaxInput>;
  min?: InputMaybe<MeterUsagesHavingMinInput>;
  stddevPopulation?: InputMaybe<MeterUsagesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeterUsagesHavingStddevSampleInput>;
  sum?: InputMaybe<MeterUsagesHavingSumInput>;
  variancePopulation?: InputMaybe<MeterUsagesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeterUsagesHavingVarianceSampleInput>;
};

export type MeterUsagesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type MeterUsagesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  overrideValue?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Methods to use when ordering `MeterUsage`. */
export enum MeterUsagesOrderBy {
  CATEGORY_ASC = 'CATEGORY_ASC',
  CATEGORY_DESC = 'CATEGORY_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  LABEL_ASC = 'LABEL_ASC',
  LABEL_DESC = 'LABEL_DESC',
  LINE_NUMBER_ASC = 'LINE_NUMBER_ASC',
  LINE_NUMBER_DESC = 'LINE_NUMBER_DESC',
  METER_STATEMENT_ID_ASC = 'METER_STATEMENT_ID_ASC',
  METER_STATEMENT_ID_DESC = 'METER_STATEMENT_ID_DESC',
  NATURAL = 'NATURAL',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_LABEL_ASC = 'OVERRIDE_LABEL_ASC',
  OVERRIDE_LABEL_DESC = 'OVERRIDE_LABEL_DESC',
  OVERRIDE_UNITS_ASC = 'OVERRIDE_UNITS_ASC',
  OVERRIDE_UNITS_DESC = 'OVERRIDE_UNITS_DESC',
  OVERRIDE_VALUE_ASC = 'OVERRIDE_VALUE_ASC',
  OVERRIDE_VALUE_DESC = 'OVERRIDE_VALUE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UNITS_ASC = 'UNITS_ASC',
  UNITS_DESC = 'UNITS_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  VALUE_ASC = 'VALUE_ASC',
  VALUE_DESC = 'VALUE_DESC'
}

export type MetricAggregate = {
  __typename?: 'MetricAggregate';
  AVG?: Maybe<Scalars['JSON']>;
  COUNT?: Maybe<Scalars['JSON']>;
  FIRST?: Maybe<Scalars['JSON']>;
  LAST?: Maybe<Scalars['JSON']>;
  MAX?: Maybe<Scalars['JSON']>;
  MEDIAN?: Maybe<Scalars['JSON']>;
  MIN?: Maybe<Scalars['JSON']>;
  SUM?: Maybe<Scalars['JSON']>;
};

export type MetricData = Node & {
  __typename?: 'MetricData';
  data: Scalars['JSON'];
  label: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  nodeId: Scalars['ID'];
  sourceId: Scalars['String'];
  time: Scalars['String'];
};

export type MetricDataMetadataArgs = {
  drillIn?: InputMaybe<Scalars['Boolean']>;
};

export enum MetricDataAggregationMethod {
  AVG = 'AVG',
  COUNT = 'COUNT',
  FIRST = 'FIRST',
  LAST = 'LAST',
  MAX = 'MAX',
  MEDIAN = 'MEDIAN',
  MIN = 'MIN',
  SUM = 'SUM'
}

export type MetricDataConnection = {
  __typename?: 'MetricDataConnection';
  aggregates?: Maybe<MetricAggregate>;
  edges: Array<MetricDataEdge>;
  explain?: Maybe<MetricDataExplain>;
  nodes: Array<MetricData>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MetricDataEdge = {
  __typename?: 'MetricDataEdge';
  cursor?: Maybe<Scalars['Cursor']>;
  node: MetricData;
};

export type MetricDataExplain = {
  __typename?: 'MetricDataExplain';
  unit?: Maybe<Scalars['String']>;
};

export enum MetricDataOrderBy {
  TIME_ASC = 'TIME_ASC',
  TIME_DESC = 'TIME_DESC'
}

export enum MetricDatatype {
  BOOLEAN = 'BOOLEAN',
  FLOAT = 'FLOAT',
  INT = 'INT',
  LONG = 'LONG',
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT'
}

/** A filter to be used against MetricDatatype fields. All fields are combined with a logical ‘and.’ */
export type MetricDatatypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MetricDatatype>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MetricDatatype>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MetricDatatype>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MetricDatatype>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MetricDatatype>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MetricDatatype>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MetricDatatype>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MetricDatatype>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MetricDatatype>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MetricDatatype>>;
};

/** A connection to a list of `MetricDependency` values. */
export type MetricDependenciesConnection = {
  __typename?: 'MetricDependenciesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MetricDependencyAggregates>;
  /** A list of edges which contains the `MetricDependency` and cursor to aid in pagination. */
  edges: Array<MetricDependenciesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MetricDependencyAggregates>>;
  /** A list of `MetricDependency` objects. */
  nodes: Array<MetricDependency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MetricDependency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MetricDependency` values. */
export type MetricDependenciesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MetricDependenciesGroupBy>;
  having?: InputMaybe<MetricDependenciesHavingInput>;
};

/** A `MetricDependency` edge in the connection. */
export type MetricDependenciesEdge = {
  __typename?: 'MetricDependenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MetricDependency` at the end of the edge. */
  node: MetricDependency;
};

/** Grouping methods for `MetricDependency` for usage during aggregation. */
export enum MetricDependenciesGroupBy {
  METRIC = 'METRIC',
  METRIC_DISTINCT = 'METRIC_DISTINCT'
}

export type MetricDependenciesHavingAverageInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingDistinctCountInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingDistinctInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `MetricDependency` aggregates. */
export type MetricDependenciesHavingInput = {
  AND?: InputMaybe<Array<MetricDependenciesHavingInput>>;
  OR?: InputMaybe<Array<MetricDependenciesHavingInput>>;
  average?: InputMaybe<MetricDependenciesHavingAverageInput>;
  distinct?: InputMaybe<MetricDependenciesHavingDistinctInput>;
  distinctCount?: InputMaybe<MetricDependenciesHavingDistinctCountInput>;
  max?: InputMaybe<MetricDependenciesHavingMaxInput>;
  min?: InputMaybe<MetricDependenciesHavingMinInput>;
  stddevPopulation?: InputMaybe<MetricDependenciesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MetricDependenciesHavingStddevSampleInput>;
  sum?: InputMaybe<MetricDependenciesHavingSumInput>;
  variancePopulation?: InputMaybe<MetricDependenciesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MetricDependenciesHavingVarianceSampleInput>;
};

export type MetricDependenciesHavingMaxInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingMinInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingStddevPopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingStddevSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingSumInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingVariancePopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

export type MetricDependenciesHavingVarianceSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `MetricDependency`. */
export enum MetricDependenciesOrderBy {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  METRIC_ASC = 'METRIC_ASC',
  METRIC_DESC = 'METRIC_DESC',
  NATURAL = 'NATURAL',
  PATH_ASC = 'PATH_ASC',
  PATH_DESC = 'PATH_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC'
}

export type MetricDependency = Node & {
  __typename?: 'MetricDependency';
  id: Scalars['BigInt'];
  metric: Scalars['String'];
  /** Reads a single `MetricMetadatum` that is related to this `MetricDependency`. */
  metricMetadatumByMetric?: Maybe<MetricMetadatum>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  path: Scalars['String'];
};

export type MetricDependencyAggregates = {
  __typename?: 'MetricDependencyAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MetricDependencyAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MetricDependencyDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MetricDependencyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MetricDependencyMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MetricDependencyMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MetricDependencyStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MetricDependencyStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MetricDependencySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MetricDependencyVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MetricDependencyVarianceSampleAggregates>;
};

export type MetricDependencyAverageAggregates = {
  __typename?: 'MetricDependencyAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MetricDependency` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MetricDependencyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `metric` field. */
  metric?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars['String']>;
};

export type MetricDependencyDistinctAggregates = {
  __typename?: 'MetricDependencyDistinctAggregates';
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of metric across the matching connection */
  metric?: Maybe<Scalars['JSON']>;
  /** Distinct of path across the matching connection */
  path?: Maybe<Scalars['JSON']>;
};

export type MetricDependencyDistinctCountAggregates = {
  __typename?: 'MetricDependencyDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metric across the matching connection */
  metric?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MetricDependency` object types. All fields are combined with a logical ‘and.’ */
export type MetricDependencyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MetricDependencyFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `metric` field. */
  metric?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MetricDependencyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MetricDependencyFilter>>;
};

/** An input for mutations affecting `MetricDependency` */
export type MetricDependencyInput = {
  metric: Scalars['String'];
  path: Scalars['String'];
};

export type MetricDependencyMaxAggregates = {
  __typename?: 'MetricDependencyMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type MetricDependencyMinAggregates = {
  __typename?: 'MetricDependencyMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `MetricDependency`. Fields that are set will be updated. */
export type MetricDependencyPatch = {
  metric?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type MetricDependencyStddevPopulationAggregates = {
  __typename?: 'MetricDependencyStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MetricDependencyStddevSampleAggregates = {
  __typename?: 'MetricDependencyStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MetricDependencySumAggregates = {
  __typename?: 'MetricDependencySumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type MetricDependencyVariancePopulationAggregates = {
  __typename?: 'MetricDependencyVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MetricDependencyVarianceSampleAggregates = {
  __typename?: 'MetricDependencyVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type MetricLabel = Node & {
  __typename?: 'MetricLabel';
  label: Scalars['String'];
  nodeId: Scalars['ID'];
  sourceId: Scalars['String'];
};

/** A connection to a list of `MetricMetadatum` values. */
export type MetricMetadataConnection = {
  __typename?: 'MetricMetadataConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MetricMetadatumAggregates>;
  /** A list of edges which contains the `MetricMetadatum` and cursor to aid in pagination. */
  edges: Array<MetricMetadataEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MetricMetadatumAggregates>>;
  /** A list of `MetricMetadatum` objects. */
  nodes: Array<MetricMetadatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MetricMetadatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `MetricMetadatum` values. */
export type MetricMetadataConnectionGroupedAggregatesArgs = {
  groupBy: Array<MetricMetadataGroupBy>;
  having?: InputMaybe<MetricMetadataHavingInput>;
};

/** A `MetricMetadatum` edge in the connection. */
export type MetricMetadataEdge = {
  __typename?: 'MetricMetadataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MetricMetadatum` at the end of the edge. */
  node: MetricMetadatum;
};

/** Grouping methods for `MetricMetadatum` for usage during aggregation. */
export enum MetricMetadataGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DATA_TYPE = 'DATA_TYPE',
  DATA_TYPE_DISTINCT = 'DATA_TYPE_DISTINCT',
  EXPRESSION = 'EXPRESSION',
  EXPRESSION_DISTINCT = 'EXPRESSION_DISTINCT',
  HUMAN_LABEL = 'HUMAN_LABEL',
  HUMAN_LABEL_DISTINCT = 'HUMAN_LABEL_DISTINCT',
  IS_COMPOSITE = 'IS_COMPOSITE',
  IS_COMPOSITE_DISTINCT = 'IS_COMPOSITE_DISTINCT',
  IS_INTERNAL = 'IS_INTERNAL',
  IS_INTERNAL_DISTINCT = 'IS_INTERNAL_DISTINCT',
  IS_SPARSE = 'IS_SPARSE',
  IS_SPARSE_DISTINCT = 'IS_SPARSE_DISTINCT',
  TABLE = 'TABLE',
  TABLE_DISTINCT = 'TABLE_DISTINCT',
  UNIT = 'UNIT',
  UNIT_DIMENSION = 'UNIT_DIMENSION',
  UNIT_DIMENSION_DISTINCT = 'UNIT_DIMENSION_DISTINCT',
  UNIT_DISTINCT = 'UNIT_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type MetricMetadataHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MetricMetadatum` aggregates. */
export type MetricMetadataHavingInput = {
  AND?: InputMaybe<Array<MetricMetadataHavingInput>>;
  OR?: InputMaybe<Array<MetricMetadataHavingInput>>;
  average?: InputMaybe<MetricMetadataHavingAverageInput>;
  distinct?: InputMaybe<MetricMetadataHavingDistinctInput>;
  distinctCount?: InputMaybe<MetricMetadataHavingDistinctCountInput>;
  max?: InputMaybe<MetricMetadataHavingMaxInput>;
  min?: InputMaybe<MetricMetadataHavingMinInput>;
  stddevPopulation?: InputMaybe<MetricMetadataHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MetricMetadataHavingStddevSampleInput>;
  sum?: InputMaybe<MetricMetadataHavingSumInput>;
  variancePopulation?: InputMaybe<MetricMetadataHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MetricMetadataHavingVarianceSampleInput>;
};

export type MetricMetadataHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MetricMetadataHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `MetricMetadatum`. */
export enum MetricMetadataOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DATA_TYPE_ASC = 'DATA_TYPE_ASC',
  DATA_TYPE_DESC = 'DATA_TYPE_DESC',
  EXPRESSION_ASC = 'EXPRESSION_ASC',
  EXPRESSION_DESC = 'EXPRESSION_DESC',
  HUMAN_LABEL_ASC = 'HUMAN_LABEL_ASC',
  HUMAN_LABEL_DESC = 'HUMAN_LABEL_DESC',
  IS_COMPOSITE_ASC = 'IS_COMPOSITE_ASC',
  IS_COMPOSITE_DESC = 'IS_COMPOSITE_DESC',
  IS_INTERNAL_ASC = 'IS_INTERNAL_ASC',
  IS_INTERNAL_DESC = 'IS_INTERNAL_DESC',
  IS_SPARSE_ASC = 'IS_SPARSE_ASC',
  IS_SPARSE_DESC = 'IS_SPARSE_DESC',
  LABEL_ASC = 'LABEL_ASC',
  LABEL_DESC = 'LABEL_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_AVERAGE_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_COUNT_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_COUNT_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_COUNT_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_COUNT_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_COUNT_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_DISTINCT_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MAX_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MAX_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_MIN_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_MIN_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_POPULATION_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_STDDEV_SAMPLE_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_SUM_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_SUM_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_POPULATION_PATH_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_ID_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_ID_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_ID_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_ID_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_METRIC_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_METRIC_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_METRIC_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_METRIC_DESC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_PATH_ASC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_PATH_ASC',
  METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_PATH_DESC = 'METRIC_DEPENDENCIES_BY_METRIC_VARIANCE_SAMPLE_PATH_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  TABLE_ASC = 'TABLE_ASC',
  TABLE_DESC = 'TABLE_DESC',
  UNIT_ASC = 'UNIT_ASC',
  UNIT_DESC = 'UNIT_DESC',
  UNIT_DIMENSION_ASC = 'UNIT_DIMENSION_ASC',
  UNIT_DIMENSION_DESC = 'UNIT_DIMENSION_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

/** Metadata describing a given metric by label. */
export type MetricMetadatum = Node & {
  __typename?: 'MetricMetadatum';
  /** Returns a flattened list of all downstream dependencies on the metric. */
  allDependencies: MetricMetadataConnection;
  /** Returns a flattened list of all upstream dependents on the metric. */
  allDependents: MetricMetadataConnection;
  /** The time the metric metadata was created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The data type of the metric. */
  dataType?: Maybe<MetricDatatype>;
  /** Returns a list of direct dependencies for the metric. */
  dependencies: MetricMetadataConnection;
  /** A flattened list of all downstream dependencies on the metric. */
  dependencyList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Returns a list of direct dependents for the metric. */
  dependents: MetricMetadataConnection;
  /** A flattened list of all upstream dependents on the metric. */
  dependentsList?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The query expression used to derive the metric. */
  expression?: Maybe<Scalars['String']>;
  /** The human-readable label of the metric. */
  humanLabel: Scalars['String'];
  /** A flag that indicates the metric will be computed using other metrics. */
  isComposite?: Maybe<Scalars['Boolean']>;
  /** A flag that indicates  the metric is precalculated internally. */
  isInternal?: Maybe<Scalars['Boolean']>;
  /** A flag that indicates the metric should be interpolated when used. */
  isSparse?: Maybe<Scalars['Boolean']>;
  /** The label of the metric. */
  label: Scalars['String'];
  /** Reads and enables pagination through a set of `MetricDependency`. */
  metricDependenciesByMetric: MetricDependenciesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The underlining table that stores the data stream of the metric. */
  table: Scalars['String'];
  /** The default unit that describes the raw data stream of the metric. */
  unit: Scalars['String'];
  /** The unit dimension of the metric. */
  unitDimension: Scalars['String'];
  /** The time the metric metadata was updated. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Metadata describing a given metric by label. */
export type MetricMetadatumAllDependenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MetricMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Metadata describing a given metric by label. */
export type MetricMetadatumAllDependentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MetricMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Metadata describing a given metric by label. */
export type MetricMetadatumDependenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MetricMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Metadata describing a given metric by label. */
export type MetricMetadatumDependentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MetricMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Metadata describing a given metric by label. */
export type MetricMetadatumMetricDependenciesByMetricArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MetricDependencyCondition>;
  filter?: InputMaybe<MetricDependencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

export type MetricMetadatumAggregates = {
  __typename?: 'MetricMetadatumAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<MetricMetadatumDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MetricMetadatumDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `MetricMetadatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MetricMetadatumCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dataType` field. */
  dataType?: InputMaybe<MetricDatatype>;
  /** Checks for equality with the object’s `expression` field. */
  expression?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `humanLabel` field. */
  humanLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `isComposite` field. */
  isComposite?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isInternal` field. */
  isInternal?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSparse` field. */
  isSparse?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `table` field. */
  table?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `unit` field. */
  unit?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `unitDimension` field. */
  unitDimension?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type MetricMetadatumDistinctAggregates = {
  __typename?: 'MetricMetadatumDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of dataType across the matching connection */
  dataType?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * A flattened list of all downstream dependencies on the metric.
   */
  dependencyList?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * A flattened list of all upstream dependents on the metric.
   */
  dependentsList?: Maybe<Scalars['JSON']>;
  /** Distinct of expression across the matching connection */
  expression?: Maybe<Scalars['JSON']>;
  /** Distinct of humanLabel across the matching connection */
  humanLabel?: Maybe<Scalars['JSON']>;
  /** Distinct of isComposite across the matching connection */
  isComposite?: Maybe<Scalars['JSON']>;
  /** Distinct of isInternal across the matching connection */
  isInternal?: Maybe<Scalars['JSON']>;
  /** Distinct of isSparse across the matching connection */
  isSparse?: Maybe<Scalars['JSON']>;
  /** Distinct of label across the matching connection */
  label?: Maybe<Scalars['JSON']>;
  /** Distinct of table across the matching connection */
  table?: Maybe<Scalars['JSON']>;
  /** Distinct of unit across the matching connection */
  unit?: Maybe<Scalars['JSON']>;
  /** Distinct of unitDimension across the matching connection */
  unitDimension?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type MetricMetadatumDistinctCountAggregates = {
  __typename?: 'MetricMetadatumDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dataType across the matching connection */
  dataType?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * A flattened list of all downstream dependencies on the metric.
   */
  dependencyList?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * A flattened list of all upstream dependents on the metric.
   */
  dependentsList?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expression across the matching connection */
  expression?: Maybe<Scalars['BigInt']>;
  /** Distinct count of humanLabel across the matching connection */
  humanLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isComposite across the matching connection */
  isComposite?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isInternal across the matching connection */
  isInternal?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isSparse across the matching connection */
  isSparse?: Maybe<Scalars['BigInt']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']>;
  /** Distinct count of table across the matching connection */
  table?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unit across the matching connection */
  unit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unitDimension across the matching connection */
  unitDimension?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MetricMetadatum` object types. All fields are combined with a logical ‘and.’ */
export type MetricMetadatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MetricMetadatumFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dataType` field. */
  dataType?: InputMaybe<MetricDatatypeFilter>;
  /** Filter by the object’s `dependencyList` field. */
  dependencyList?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `dependentsList` field. */
  dependentsList?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `expression` field. */
  expression?: InputMaybe<StringFilter>;
  /** Filter by the object’s `humanLabel` field. */
  humanLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isComposite` field. */
  isComposite?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isInternal` field. */
  isInternal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSparse` field. */
  isSparse?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MetricMetadatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MetricMetadatumFilter>>;
  /** Filter by the object’s `table` field. */
  table?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unit` field. */
  unit?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unitDimension` field. */
  unitDimension?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeSuggestion?: Maybe<Suggestion>;
  /** Adds a child dependency to the specific metric */
  addMetricMetadataDependency?: Maybe<AddMetricMetadataDependencyPayload>;
  addProjectController?: Maybe<AddProjectControllerPayload>;
  /** Creates a single `AccountCharge`. */
  createAccountCharge?: Maybe<CreateAccountChargePayload>;
  /** Creates a single `AccountStatement`. */
  createAccountStatement?: Maybe<CreateAccountStatementPayload>;
  /** Creates a single `AccountStatementHistory`. */
  createAccountStatementHistory?: Maybe<CreateAccountStatementHistoryPayload>;
  /** Creates a single `BalancingAuthority`. */
  createBalancingAuthority?: Maybe<CreateBalancingAuthorityPayload>;
  /** Creates a single `Component`. */
  createComponent?: Maybe<CreateComponentPayload>;
  /** Creates a single `ControlEvent`. */
  createControlEvent?: Maybe<CreateControlEventPayload>;
  /** Creates a single `ControlEventLog`. */
  createControlEventLog?: Maybe<CreateControlEventLogPayload>;
  /** Creates a single `Facility`. */
  createFacility?: Maybe<CreateFacilityPayload>;
  /** Creates a single `FacilityGroup`. */
  createFacilityGroup?: Maybe<CreateFacilityGroupPayload>;
  /** Creates a single `FacilityGroupsLinker`. */
  createFacilityGroupsLinker?: Maybe<CreateFacilityGroupsLinkerPayload>;
  createMachineAccessToken?: Maybe<CreateMachineAccessTokenPayload>;
  /** Creates a single `MachineUser`. */
  createMachineUser?: Maybe<CreateMachineUserPayload>;
  /** Creates a single `MeterCharge`. */
  createMeterCharge?: Maybe<CreateMeterChargePayload>;
  /** Creates a single `MeterStatement`. */
  createMeterStatement?: Maybe<CreateMeterStatementPayload>;
  /** Creates a single `MeterStatementHistory`. */
  createMeterStatementHistory?: Maybe<CreateMeterStatementHistoryPayload>;
  /** Creates a single `MeterUsage`. */
  createMeterUsage?: Maybe<CreateMeterUsagePayload>;
  /** Creates a single `MetricDependency`. */
  createMetricDependency?: Maybe<CreateMetricDependencyPayload>;
  /** Creates a single `PlatformAccessToken`. */
  createPlatformAccessToken?: Maybe<CreatePlatformAccessTokenPayload>;
  /** Creates a single `Project`. */
  createProject?: Maybe<CreateProjectPayload>;
  /** Creates a single `ProjectComponent`. */
  createProjectComponent?: Maybe<CreateProjectComponentPayload>;
  /** Creates a single `ProjectWatcher`. */
  createProjectWatcher?: Maybe<CreateProjectWatcherPayload>;
  /** Creates a single `Suggestion`. */
  createSuggestion?: Maybe<CreateSuggestionPayload>;
  /** Creates a single `SuggestionActivityLog`. */
  createSuggestionActivityLog?: Maybe<CreateSuggestionActivityLogPayload>;
  /** Creates a single `SuggestionControlEvent`. */
  createSuggestionControlEvent?: Maybe<CreateSuggestionControlEventPayload>;
  createUserAccessToken?: Maybe<CreateUserAccessTokenPayload>;
  /** Creates a single `UtilityAccount`. */
  createUtilityAccount?: Maybe<CreateUtilityAccountPayload>;
  /** Creates a single `UtilityMeter`. */
  createUtilityMeter?: Maybe<CreateUtilityMeterPayload>;
  /** Creates a single `UtilityProvider`. */
  createUtilityProvider?: Maybe<CreateUtilityProviderPayload>;
  /** Paranoid deletes a account charge. */
  deleteAccountCharge?: Maybe<DeleteAccountChargePayload>;
  /** Paranoid deletes a account statement. */
  deleteAccountStatement?: Maybe<DeleteAccountStatementPayload>;
  /** Deletes a single `Component` using a unique key. */
  deleteComponent?: Maybe<DeleteComponentPayload>;
  /** Deletes a single `Component` using its globally unique id. */
  deleteComponentByNodeId?: Maybe<DeleteComponentPayload>;
  /** Deletes a single `ControlEvent` using a unique key. */
  deleteControlEvent?: Maybe<DeleteControlEventPayload>;
  /** Deletes a single `ControlEvent` using its globally unique id. */
  deleteControlEventByNodeId?: Maybe<DeleteControlEventPayload>;
  /** Deletes a single `ControlEventLog` using a unique key. */
  deleteControlEventLog?: Maybe<DeleteControlEventLogPayload>;
  /** Deletes a single `ControlEventLog` using its globally unique id. */
  deleteControlEventLogByNodeId?: Maybe<DeleteControlEventLogPayload>;
  /** Deletes a single `Facility` using a unique key. */
  deleteFacility?: Maybe<DeleteFacilityPayload>;
  /** Deletes a single `Facility` using its globally unique id. */
  deleteFacilityByNodeId?: Maybe<DeleteFacilityPayload>;
  /** Deletes a single `Facility` using a unique key. */
  deleteFacilityBySlug?: Maybe<DeleteFacilityPayload>;
  /** Deletes a single `FacilityGroup` using a unique key. */
  deleteFacilityGroup?: Maybe<DeleteFacilityGroupPayload>;
  /** Deletes a single `FacilityGroup` using its globally unique id. */
  deleteFacilityGroupByNodeId?: Maybe<DeleteFacilityGroupPayload>;
  /** Deletes a single `FacilityGroupsLinker` using a unique key. */
  deleteFacilityGroupsLinker?: Maybe<DeleteFacilityGroupsLinkerPayload>;
  /** Deletes a single `FacilityGroupsLinker` using its globally unique id. */
  deleteFacilityGroupsLinkerByNodeId?: Maybe<DeleteFacilityGroupsLinkerPayload>;
  deleteFacilityMutableMetricData?: Maybe<Scalars['Int']>;
  /** Deletes a single `MachineUser` using a unique key. */
  deleteMachineUser?: Maybe<DeleteMachineUserPayload>;
  /** Deletes a single `MachineUser` using its globally unique id. */
  deleteMachineUserByNodeId?: Maybe<DeleteMachineUserPayload>;
  /** Paranoid deletes a meter charge. */
  deleteMeterCharge?: Maybe<DeleteMeterChargePayload>;
  /** Paranoid deletes a meter statement. */
  deleteMeterStatement?: Maybe<DeleteMeterStatementPayload>;
  /** Paranoid deletes a meter usage. */
  deleteMeterUsage?: Maybe<DeleteMeterUsagePayload>;
  /** Deletes a single `MetricDependency` using a unique key. */
  deleteMetricDependency?: Maybe<DeleteMetricDependencyPayload>;
  /** Deletes a single `MetricDependency` using its globally unique id. */
  deleteMetricDependencyByNodeId?: Maybe<DeleteMetricDependencyPayload>;
  /** Deletes a single `MetricDependency` using a unique key. */
  deleteMetricDependencyByPath?: Maybe<DeleteMetricDependencyPayload>;
  deleteMutableMetricData?: Maybe<Scalars['Int']>;
  deletePhoneNumber: DeletePhoneNumberResponse;
  /** Deletes a single `Project` using a unique key. */
  deleteProject?: Maybe<DeleteProjectPayload>;
  /** Deletes a single `Project` using its globally unique id. */
  deleteProjectByNodeId?: Maybe<DeleteProjectPayload>;
  /** Deletes a single `ProjectComponent` using a unique key. */
  deleteProjectComponent?: Maybe<DeleteProjectComponentPayload>;
  /** Deletes a single `ProjectComponent` using its globally unique id. */
  deleteProjectComponentByNodeId?: Maybe<DeleteProjectComponentPayload>;
  /** Deletes a single `ProjectWatcher` using a unique key. */
  deleteProjectWatcher?: Maybe<DeleteProjectWatcherPayload>;
  /** Deletes a single `ProjectWatcher` using its globally unique id. */
  deleteProjectWatcherByNodeId?: Maybe<DeleteProjectWatcherPayload>;
  /** Deletes a single `SuggestionControlEvent` using a unique key. */
  deleteSuggestionControlEvent?: Maybe<DeleteSuggestionControlEventPayload>;
  /** Deletes a single `SuggestionControlEvent` using its globally unique id. */
  deleteSuggestionControlEventByNodeId?: Maybe<DeleteSuggestionControlEventPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Paranoid deletes a utility account. */
  deleteUtilityAccount?: Maybe<DeleteUtilityAccountPayload>;
  /** Paranoid deletes a utility meter. */
  deleteUtilityMeter?: Maybe<DeleteUtilityMeterPayload>;
  /** Deletes a single `UtilityProvider` using a unique key. */
  deleteUtilityProvider?: Maybe<DeleteUtilityProviderPayload>;
  /** Deletes a single `UtilityProvider` using a unique key. */
  deleteUtilityProviderByName?: Maybe<DeleteUtilityProviderPayload>;
  /** Deletes a single `UtilityProvider` using its globally unique id. */
  deleteUtilityProviderByNodeId?: Maybe<DeleteUtilityProviderPayload>;
  extend?: Maybe<Scalars['Boolean']>;
  regenerateAccessToken?: Maybe<CreateAccessTokenPayload>;
  /** Removes a child dependency from the specific metric */
  removeMetricMetadataDependency?: Maybe<RemoveMetricMetadataDependencyPayload>;
  removeProjectController?: Maybe<RemoveProjectControllerPayload>;
  /** Custom mutation for assigning a meter_statement and its account_statement to a new meter and its account, respectively */
  reparentMeterStatement?: Maybe<ReparentMeterStatementPayload>;
  revokeAccessToken?: Maybe<Scalars['Boolean']>;
  transitionControlEvent?: Maybe<ControlEvent>;
  triggerPhoneNumberVerification: VerifyPhoneNumberResponse;
  /** Updates a single `AccountCharge` using a unique key and a patch. */
  updateAccountCharge?: Maybe<UpdateAccountChargePayload>;
  /** Updates a single `AccountCharge` using its globally unique id and a patch. */
  updateAccountChargeByNodeId?: Maybe<UpdateAccountChargePayload>;
  /** Updates a single `AccountStatement` using a unique key and a patch. */
  updateAccountStatement?: Maybe<UpdateAccountStatementPayload>;
  /** Updates a single `AccountStatement` using its globally unique id and a patch. */
  updateAccountStatementByNodeId?: Maybe<UpdateAccountStatementPayload>;
  /** Updates a single `Component` using a unique key and a patch. */
  updateComponent?: Maybe<UpdateComponentPayload>;
  /** Updates a single `Component` using its globally unique id and a patch. */
  updateComponentByNodeId?: Maybe<UpdateComponentPayload>;
  /** Updates a single `ControlEvent` using a unique key and a patch. */
  updateControlEvent?: Maybe<UpdateControlEventPayload>;
  /** Updates a single `ControlEvent` using its globally unique id and a patch. */
  updateControlEventByNodeId?: Maybe<UpdateControlEventPayload>;
  /** Updates a single `ControlEventLog` using a unique key and a patch. */
  updateControlEventLog?: Maybe<UpdateControlEventLogPayload>;
  /** Updates a single `ControlEventLog` using its globally unique id and a patch. */
  updateControlEventLogByNodeId?: Maybe<UpdateControlEventLogPayload>;
  /** Updates a single `Facility` using a unique key and a patch. */
  updateFacility?: Maybe<UpdateFacilityPayload>;
  /** Updates a single `Facility` using its globally unique id and a patch. */
  updateFacilityByNodeId?: Maybe<UpdateFacilityPayload>;
  /** Updates a single `Facility` using a unique key and a patch. */
  updateFacilityBySlug?: Maybe<UpdateFacilityPayload>;
  /** Updates a single `FacilityGroup` using a unique key and a patch. */
  updateFacilityGroup?: Maybe<UpdateFacilityGroupPayload>;
  /** Updates a single `FacilityGroup` using its globally unique id and a patch. */
  updateFacilityGroupByNodeId?: Maybe<UpdateFacilityGroupPayload>;
  /** Updates a single `FacilityGroupsLinker` using a unique key and a patch. */
  updateFacilityGroupsLinker?: Maybe<UpdateFacilityGroupsLinkerPayload>;
  /** Updates a single `FacilityGroupsLinker` using its globally unique id and a patch. */
  updateFacilityGroupsLinkerByNodeId?: Maybe<UpdateFacilityGroupsLinkerPayload>;
  updateFacilityMutableMetricData?: Maybe<Scalars['Int']>;
  /** Updates a single `MachineUser` using a unique key and a patch. */
  updateMachineUser?: Maybe<UpdateMachineUserPayload>;
  /** Updates a single `MachineUser` using its globally unique id and a patch. */
  updateMachineUserByNodeId?: Maybe<UpdateMachineUserPayload>;
  /** Updates a single `MeterCharge` using a unique key and a patch. */
  updateMeterCharge?: Maybe<UpdateMeterChargePayload>;
  /** Updates a single `MeterCharge` using its globally unique id and a patch. */
  updateMeterChargeByNodeId?: Maybe<UpdateMeterChargePayload>;
  /** Updates a single `MeterStatement` using a unique key and a patch. */
  updateMeterStatement?: Maybe<UpdateMeterStatementPayload>;
  /** Updates a single `MeterStatement` using its globally unique id and a patch. */
  updateMeterStatementByNodeId?: Maybe<UpdateMeterStatementPayload>;
  /** Updates a single `MeterUsage` using a unique key and a patch. */
  updateMeterUsage?: Maybe<UpdateMeterUsagePayload>;
  /** Updates a single `MeterUsage` using its globally unique id and a patch. */
  updateMeterUsageByNodeId?: Maybe<UpdateMeterUsagePayload>;
  /** Updates a single `MetricDependency` using a unique key and a patch. */
  updateMetricDependency?: Maybe<UpdateMetricDependencyPayload>;
  /** Updates a single `MetricDependency` using its globally unique id and a patch. */
  updateMetricDependencyByNodeId?: Maybe<UpdateMetricDependencyPayload>;
  /** Updates a single `MetricDependency` using a unique key and a patch. */
  updateMetricDependencyByPath?: Maybe<UpdateMetricDependencyPayload>;
  updateMutableMetricData?: Maybe<Scalars['Int']>;
  updatePhoneNumber: UpdatePhoneNumberResponse;
  /** Updates a single `Project` using a unique key and a patch. */
  updateProject?: Maybe<UpdateProjectPayload>;
  /** Updates a single `Project` using its globally unique id and a patch. */
  updateProjectByNodeId?: Maybe<UpdateProjectPayload>;
  /** Updates a single `ProjectComponent` using a unique key and a patch. */
  updateProjectComponent?: Maybe<UpdateProjectComponentPayload>;
  /** Updates a single `ProjectComponent` using its globally unique id and a patch. */
  updateProjectComponentByNodeId?: Maybe<UpdateProjectComponentPayload>;
  /** Updates a single `ProjectWatcher` using a unique key and a patch. */
  updateProjectWatcher?: Maybe<UpdateProjectWatcherPayload>;
  /** Updates a single `ProjectWatcher` using its globally unique id and a patch. */
  updateProjectWatcherByNodeId?: Maybe<UpdateProjectWatcherPayload>;
  /** Updates a single `Suggestion` using a unique key and a patch. */
  updateSuggestion?: Maybe<UpdateSuggestionPayload>;
  /** Updates a single `Suggestion` using its globally unique id and a patch. */
  updateSuggestionByNodeId?: Maybe<UpdateSuggestionPayload>;
  /** Updates a single `SuggestionControlEvent` using a unique key and a patch. */
  updateSuggestionControlEvent?: Maybe<UpdateSuggestionControlEventPayload>;
  /** Updates a single `SuggestionControlEvent` using its globally unique id and a patch. */
  updateSuggestionControlEventByNodeId?: Maybe<UpdateSuggestionControlEventPayload>;
  /** Updates a single `UtilityAccount` using a unique key and a patch. */
  updateUtilityAccount?: Maybe<UpdateUtilityAccountPayload>;
  /** Updates a single `UtilityAccount` using its globally unique id and a patch. */
  updateUtilityAccountByNodeId?: Maybe<UpdateUtilityAccountPayload>;
  /** Updates a single `UtilityMeter` using a unique key and a patch. */
  updateUtilityMeter?: Maybe<UpdateUtilityMeterPayload>;
  /** Updates a single `UtilityMeter` using its globally unique id and a patch. */
  updateUtilityMeterByNodeId?: Maybe<UpdateUtilityMeterPayload>;
  /** Updates a single `UtilityProvider` using a unique key and a patch. */
  updateUtilityProvider?: Maybe<UpdateUtilityProviderPayload>;
  /** Updates a single `UtilityProvider` using a unique key and a patch. */
  updateUtilityProviderByName?: Maybe<UpdateUtilityProviderPayload>;
  /** Updates a single `UtilityProvider` using its globally unique id and a patch. */
  updateUtilityProviderByNodeId?: Maybe<UpdateUtilityProviderPayload>;
  /** Upserts a single `AccountCharge`. */
  upsertAccountCharge?: Maybe<UpsertAccountChargePayload>;
  /** Upserts a single `AccountStatement`. */
  upsertAccountStatement?: Maybe<UpsertAccountStatementPayload>;
  /** Upserts a single `BalancingAuthority`. */
  upsertBalancingAuthority?: Maybe<UpsertBalancingAuthorityPayload>;
  /** Upserts a single `ControlEventLog`. */
  upsertControlEventLog?: Maybe<UpsertControlEventLogPayload>;
  /** Upserts a single `MachineUser`. */
  upsertMachineUser?: Maybe<UpsertMachineUserPayload>;
  /** Upserts a single `MeterCharge`. */
  upsertMeterCharge?: Maybe<UpsertMeterChargePayload>;
  /** Upserts a single `MeterStatement`. */
  upsertMeterStatement?: Maybe<UpsertMeterStatementPayload>;
  /** Upserts a single `MeterUsage`. */
  upsertMeterUsage?: Maybe<UpsertMeterUsagePayload>;
  /** Upserts a single `MetricDependency`. */
  upsertMetricDependency?: Maybe<UpsertMetricDependencyPayload>;
  /** Upserts a single `ProjectComponent`. */
  upsertProjectComponent?: Maybe<UpsertProjectComponentPayload>;
  upsertProjectSubscription?: Maybe<UpsertProjectSubscriptionPayload>;
  /** Upserts a single `ProjectWatcher`. */
  upsertProjectWatcher?: Maybe<UpsertProjectWatcherPayload>;
  /** Upserts a single `SuggestionControlEvent`. */
  upsertSuggestionControlEvent?: Maybe<UpsertSuggestionControlEventPayload>;
  upsertUrjanetUtilityProvider?: Maybe<UpsertUrjanetUtilityProviderPayload>;
  /** Upserts a single `UtilityAccount`. */
  upsertUtilityAccount?: Maybe<UpsertUtilityAccountPayload>;
  /** Upserts a single `UtilityMeter`. */
  upsertUtilityMeter?: Maybe<UpsertUtilityMeterPayload>;
  /** Upserts a single `UtilityProvider`. */
  upsertUtilityProvider?: Maybe<UpsertUtilityProviderPayload>;
  withdrawSuggestion?: Maybe<Suggestion>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcknowledgeSuggestionArgs = {
  input: AcknowledgeSuggestionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddMetricMetadataDependencyArgs = {
  input: AddMetricMetadataDependencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddProjectControllerArgs = {
  input: AddProjectControllerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountChargeArgs = {
  input: CreateAccountChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountStatementArgs = {
  input: CreateAccountStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountStatementHistoryArgs = {
  input: CreateAccountStatementHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBalancingAuthorityArgs = {
  input: CreateBalancingAuthorityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateComponentArgs = {
  input: CreateComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateControlEventArgs = {
  input: CreateControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateControlEventLogArgs = {
  input: CreateControlEventLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFacilityArgs = {
  input: CreateFacilityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFacilityGroupArgs = {
  input: CreateFacilityGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFacilityGroupsLinkerArgs = {
  input: CreateFacilityGroupsLinkerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMachineAccessTokenArgs = {
  input: CreateMachineAccessTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMachineUserArgs = {
  input: CreateMachineUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterChargeArgs = {
  input: CreateMeterChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterStatementArgs = {
  input: CreateMeterStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterStatementHistoryArgs = {
  input: CreateMeterStatementHistoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMeterUsageArgs = {
  input: CreateMeterUsageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMetricDependencyArgs = {
  input: CreateMetricDependencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlatformAccessTokenArgs = {
  input: CreatePlatformAccessTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectComponentArgs = {
  input: CreateProjectComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectWatcherArgs = {
  input: CreateProjectWatcherInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSuggestionArgs = {
  input: CreateSuggestionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSuggestionActivityLogArgs = {
  input: CreateSuggestionActivityLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSuggestionControlEventArgs = {
  input: CreateSuggestionControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserAccessTokenArgs = {
  input: CreateUserAccessTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUtilityAccountArgs = {
  input: CreateUtilityAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUtilityMeterArgs = {
  input: CreateUtilityMeterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUtilityProviderArgs = {
  input: CreateUtilityProviderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountChargeArgs = {
  input: DeleteAccountChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountStatementArgs = {
  input: DeleteAccountStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComponentArgs = {
  input: DeleteComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComponentByNodeIdArgs = {
  input: DeleteComponentByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteControlEventArgs = {
  input: DeleteControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteControlEventByNodeIdArgs = {
  input: DeleteControlEventByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteControlEventLogArgs = {
  input: DeleteControlEventLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteControlEventLogByNodeIdArgs = {
  input: DeleteControlEventLogByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityArgs = {
  input: DeleteFacilityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityByNodeIdArgs = {
  input: DeleteFacilityByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityBySlugArgs = {
  input: DeleteFacilityBySlugInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityGroupArgs = {
  input: DeleteFacilityGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityGroupByNodeIdArgs = {
  input: DeleteFacilityGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityGroupsLinkerArgs = {
  input: DeleteFacilityGroupsLinkerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityGroupsLinkerByNodeIdArgs = {
  input: DeleteFacilityGroupsLinkerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFacilityMutableMetricDataArgs = {
  facilityId: Scalars['Int'];
  from?: InputMaybe<Scalars['Datetime']>;
  label: Scalars['String'];
  to?: InputMaybe<Scalars['Datetime']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMachineUserArgs = {
  input: DeleteMachineUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMachineUserByNodeIdArgs = {
  input: DeleteMachineUserByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeterChargeArgs = {
  input: DeleteMeterChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeterStatementArgs = {
  input: DeleteMeterStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMeterUsageArgs = {
  input: DeleteMeterUsageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetricDependencyArgs = {
  input: DeleteMetricDependencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetricDependencyByNodeIdArgs = {
  input: DeleteMetricDependencyByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMetricDependencyByPathArgs = {
  input: DeleteMetricDependencyByPathInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMutableMetricDataArgs = {
  from?: InputMaybe<Scalars['Datetime']>;
  label: Scalars['String'];
  sourceId?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['Datetime']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePhoneNumberArgs = {
  input?: InputMaybe<DeletePhoneNumberInput>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectByNodeIdArgs = {
  input: DeleteProjectByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectComponentArgs = {
  input: DeleteProjectComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectComponentByNodeIdArgs = {
  input: DeleteProjectComponentByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectWatcherArgs = {
  input: DeleteProjectWatcherInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectWatcherByNodeIdArgs = {
  input: DeleteProjectWatcherByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSuggestionControlEventArgs = {
  input: DeleteSuggestionControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSuggestionControlEventByNodeIdArgs = {
  input: DeleteSuggestionControlEventByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUtilityAccountArgs = {
  input: DeleteUtilityAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUtilityMeterArgs = {
  input: DeleteUtilityMeterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUtilityProviderArgs = {
  input: DeleteUtilityProviderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUtilityProviderByNameArgs = {
  input: DeleteUtilityProviderByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUtilityProviderByNodeIdArgs = {
  input: DeleteUtilityProviderByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationExtendArgs = {
  failOnExists?: InputMaybe<Scalars['Boolean']>;
  payload: Scalars['String'];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegenerateAccessTokenArgs = {
  input: RegenerateAccessTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveMetricMetadataDependencyArgs = {
  input: RemoveMetricMetadataDependencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveProjectControllerArgs = {
  input: RemoveProjectControllerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationReparentMeterStatementArgs = {
  input: ReparentMeterStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevokeAccessTokenArgs = {
  input: RevokeAccessTokenInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransitionControlEventArgs = {
  input: TransitionControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationTriggerPhoneNumberVerificationArgs = {
  input: VerifyPhoneNumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountChargeArgs = {
  input: UpdateAccountChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountChargeByNodeIdArgs = {
  input: UpdateAccountChargeByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountStatementArgs = {
  input: UpdateAccountStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountStatementByNodeIdArgs = {
  input: UpdateAccountStatementByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComponentArgs = {
  input: UpdateComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComponentByNodeIdArgs = {
  input: UpdateComponentByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateControlEventArgs = {
  input: UpdateControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateControlEventByNodeIdArgs = {
  input: UpdateControlEventByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateControlEventLogArgs = {
  input: UpdateControlEventLogInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateControlEventLogByNodeIdArgs = {
  input: UpdateControlEventLogByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityArgs = {
  input: UpdateFacilityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityByNodeIdArgs = {
  input: UpdateFacilityByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityBySlugArgs = {
  input: UpdateFacilityBySlugInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityGroupArgs = {
  input: UpdateFacilityGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityGroupByNodeIdArgs = {
  input: UpdateFacilityGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityGroupsLinkerArgs = {
  input: UpdateFacilityGroupsLinkerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityGroupsLinkerByNodeIdArgs = {
  input: UpdateFacilityGroupsLinkerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFacilityMutableMetricDataArgs = {
  deleteExisting?: InputMaybe<Scalars['Boolean']>;
  metricData: Array<InputFacilityMetricData>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMachineUserArgs = {
  input: UpdateMachineUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMachineUserByNodeIdArgs = {
  input: UpdateMachineUserByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterChargeArgs = {
  input: UpdateMeterChargeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterChargeByNodeIdArgs = {
  input: UpdateMeterChargeByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterStatementArgs = {
  input: UpdateMeterStatementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterStatementByNodeIdArgs = {
  input: UpdateMeterStatementByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterUsageArgs = {
  input: UpdateMeterUsageInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMeterUsageByNodeIdArgs = {
  input: UpdateMeterUsageByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetricDependencyArgs = {
  input: UpdateMetricDependencyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetricDependencyByNodeIdArgs = {
  input: UpdateMetricDependencyByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMetricDependencyByPathArgs = {
  input: UpdateMetricDependencyByPathInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMutableMetricDataArgs = {
  deleteExisting?: InputMaybe<Scalars['Boolean']>;
  metricData: Array<InputMetricData>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePhoneNumberArgs = {
  input: UpdatePhoneNumberInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectByNodeIdArgs = {
  input: UpdateProjectByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectComponentArgs = {
  input: UpdateProjectComponentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectComponentByNodeIdArgs = {
  input: UpdateProjectComponentByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectWatcherArgs = {
  input: UpdateProjectWatcherInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectWatcherByNodeIdArgs = {
  input: UpdateProjectWatcherByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSuggestionArgs = {
  input: UpdateSuggestionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSuggestionByNodeIdArgs = {
  input: UpdateSuggestionByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSuggestionControlEventArgs = {
  input: UpdateSuggestionControlEventInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSuggestionControlEventByNodeIdArgs = {
  input: UpdateSuggestionControlEventByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityAccountArgs = {
  input: UpdateUtilityAccountInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityAccountByNodeIdArgs = {
  input: UpdateUtilityAccountByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityMeterArgs = {
  input: UpdateUtilityMeterInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityMeterByNodeIdArgs = {
  input: UpdateUtilityMeterByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityProviderArgs = {
  input: UpdateUtilityProviderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityProviderByNameArgs = {
  input: UpdateUtilityProviderByNameInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUtilityProviderByNodeIdArgs = {
  input: UpdateUtilityProviderByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertAccountChargeArgs = {
  input: UpsertAccountChargeInput;
  where?: InputMaybe<UpsertAccountChargeWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertAccountStatementArgs = {
  input: UpsertAccountStatementInput;
  where?: InputMaybe<UpsertAccountStatementWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertBalancingAuthorityArgs = {
  input: UpsertBalancingAuthorityInput;
  where?: InputMaybe<UpsertBalancingAuthorityWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertControlEventLogArgs = {
  input: UpsertControlEventLogInput;
  where?: InputMaybe<UpsertControlEventLogWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMachineUserArgs = {
  input: UpsertMachineUserInput;
  where?: InputMaybe<UpsertMachineUserWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMeterChargeArgs = {
  input: UpsertMeterChargeInput;
  where?: InputMaybe<UpsertMeterChargeWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMeterStatementArgs = {
  input: UpsertMeterStatementInput;
  where?: InputMaybe<UpsertMeterStatementWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMeterUsageArgs = {
  input: UpsertMeterUsageInput;
  where?: InputMaybe<UpsertMeterUsageWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMetricDependencyArgs = {
  input: UpsertMetricDependencyInput;
  where?: InputMaybe<UpsertMetricDependencyWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertProjectComponentArgs = {
  input: UpsertProjectComponentInput;
  where?: InputMaybe<UpsertProjectComponentWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertProjectSubscriptionArgs = {
  input: UpsertProjectSubscriptionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertProjectWatcherArgs = {
  input: UpsertProjectWatcherInput;
  where?: InputMaybe<UpsertProjectWatcherWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertSuggestionControlEventArgs = {
  input: UpsertSuggestionControlEventInput;
  where?: InputMaybe<UpsertSuggestionControlEventWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUrjanetUtilityProviderArgs = {
  input: UpsertUrjanetUtilityProviderInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUtilityAccountArgs = {
  input: UpsertUtilityAccountInput;
  where?: InputMaybe<UpsertUtilityAccountWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUtilityMeterArgs = {
  input: UpsertUtilityMeterInput;
  where?: InputMaybe<UpsertUtilityMeterWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUtilityProviderArgs = {
  input: UpsertUtilityProviderInput;
  where?: InputMaybe<UpsertUtilityProviderWhere>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationWithdrawSuggestionArgs = {
  input: WithdrawSuggestionInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

/** A filter to be used against boolean permission fields. */
export type PermissionBooleanFilter = {
  equalTo?: InputMaybe<Scalars['Boolean']>;
};

export type PlatformAccessToken = {
  __typename?: 'PlatformAccessToken';
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  machineUser?: Maybe<MachineUser>;
  ownerId?: Maybe<Scalars['String']>;
  type?: Maybe<AccessTokenType>;
  user?: Maybe<User>;
};

export type PlatformAccessTokenAggregates = {
  __typename?: 'PlatformAccessTokenAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<PlatformAccessTokenAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<PlatformAccessTokenDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PlatformAccessTokenDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<PlatformAccessTokenMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<PlatformAccessTokenMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<PlatformAccessTokenStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<PlatformAccessTokenStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<PlatformAccessTokenSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<PlatformAccessTokenVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<PlatformAccessTokenVarianceSampleAggregates>;
};

export type PlatformAccessTokenAverageAggregates = {
  __typename?: 'PlatformAccessTokenAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `PlatformAccessToken` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PlatformAccessTokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<AccessTokenType>;
};

export type PlatformAccessTokenDistinctAggregates = {
  __typename?: 'PlatformAccessTokenDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of expiresAt across the matching connection */
  expiresAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  machineUser?: Maybe<Scalars['JSON']>;
  /** Distinct of ownerId across the matching connection */
  ownerId?: Maybe<Scalars['JSON']>;
  /** Distinct of type across the matching connection */
  type?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  user?: Maybe<Scalars['JSON']>;
};

export type PlatformAccessTokenDistinctCountAggregates = {
  __typename?: 'PlatformAccessTokenDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expiresAt across the matching connection */
  expiresAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  machineUser?: Maybe<Scalars['BigInt']>;
  /** Distinct count of ownerId across the matching connection */
  ownerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  user?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `PlatformAccessToken` object types. All fields are combined with a logical ‘and.’ */
export type PlatformAccessTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PlatformAccessTokenFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PlatformAccessTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PlatformAccessTokenFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<AccessTokenTypeFilter>;
};

/** An input for mutations affecting `PlatformAccessToken` */
export type PlatformAccessTokenInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  ownerId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AccessTokenType>;
};

export type PlatformAccessTokenMaxAggregates = {
  __typename?: 'PlatformAccessTokenMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type PlatformAccessTokenMinAggregates = {
  __typename?: 'PlatformAccessTokenMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type PlatformAccessTokenStddevPopulationAggregates = {
  __typename?: 'PlatformAccessTokenStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type PlatformAccessTokenStddevSampleAggregates = {
  __typename?: 'PlatformAccessTokenStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type PlatformAccessTokenSumAggregates = {
  __typename?: 'PlatformAccessTokenSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type PlatformAccessTokenVariancePopulationAggregates = {
  __typename?: 'PlatformAccessTokenVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type PlatformAccessTokenVarianceSampleAggregates = {
  __typename?: 'PlatformAccessTokenVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `PlatformAccessToken` values. */
export type PlatformAccessTokensConnection = {
  __typename?: 'PlatformAccessTokensConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PlatformAccessTokenAggregates>;
  /** A list of edges which contains the `PlatformAccessToken` and cursor to aid in pagination. */
  edges: Array<PlatformAccessTokensEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PlatformAccessTokenAggregates>>;
  /** A list of `PlatformAccessToken` objects. */
  nodes: Array<PlatformAccessToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlatformAccessToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `PlatformAccessToken` values. */
export type PlatformAccessTokensConnectionGroupedAggregatesArgs = {
  groupBy: Array<PlatformAccessTokensGroupBy>;
  having?: InputMaybe<PlatformAccessTokensHavingInput>;
};

/** A `PlatformAccessToken` edge in the connection. */
export type PlatformAccessTokensEdge = {
  __typename?: 'PlatformAccessTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PlatformAccessToken` at the end of the edge. */
  node: PlatformAccessToken;
};

/** Grouping methods for `PlatformAccessToken` for usage during aggregation. */
export enum PlatformAccessTokensGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  EXPIRES_AT = 'EXPIRES_AT',
  EXPIRES_AT_DISTINCT = 'EXPIRES_AT_DISTINCT',
  EXPIRES_AT_TRUNCATED_TO_DAY = 'EXPIRES_AT_TRUNCATED_TO_DAY',
  EXPIRES_AT_TRUNCATED_TO_HOUR = 'EXPIRES_AT_TRUNCATED_TO_HOUR',
  ID = 'ID',
  ID_DISTINCT = 'ID_DISTINCT',
  OWNER_ID = 'OWNER_ID',
  OWNER_ID_DISTINCT = 'OWNER_ID_DISTINCT',
  TYPE = 'TYPE',
  TYPE_DISTINCT = 'TYPE_DISTINCT'
}

export type PlatformAccessTokensHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `PlatformAccessToken` aggregates. */
export type PlatformAccessTokensHavingInput = {
  AND?: InputMaybe<Array<PlatformAccessTokensHavingInput>>;
  OR?: InputMaybe<Array<PlatformAccessTokensHavingInput>>;
  average?: InputMaybe<PlatformAccessTokensHavingAverageInput>;
  distinct?: InputMaybe<PlatformAccessTokensHavingDistinctInput>;
  distinctCount?: InputMaybe<PlatformAccessTokensHavingDistinctCountInput>;
  max?: InputMaybe<PlatformAccessTokensHavingMaxInput>;
  min?: InputMaybe<PlatformAccessTokensHavingMinInput>;
  stddevPopulation?: InputMaybe<PlatformAccessTokensHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PlatformAccessTokensHavingStddevSampleInput>;
  sum?: InputMaybe<PlatformAccessTokensHavingSumInput>;
  variancePopulation?: InputMaybe<PlatformAccessTokensHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PlatformAccessTokensHavingVarianceSampleInput>;
};

export type PlatformAccessTokensHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type PlatformAccessTokensHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  expiresAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `PlatformAccessToken`. */
export enum PlatformAccessTokensOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  EXPIRES_AT_ASC = 'EXPIRES_AT_ASC',
  EXPIRES_AT_DESC = 'EXPIRES_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NATURAL = 'NATURAL',
  OWNER_ID_ASC = 'OWNER_ID_ASC',
  OWNER_ID_DESC = 'OWNER_ID_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC'
}

/** Platform errors that can be received as part of query/mutation responses */
export enum PlatformError {
  /** An error occurred while handling the data request. */
  BAD_DATA_ERROR = 'BAD_DATA_ERROR',
  /** An entity already exists for the given parameters. */
  DUPLICATE_ENTRY_ERROR = 'DUPLICATE_ENTRY_ERROR',
  /** One or more input values are invalid. */
  INVALID_INPUT_ERROR = 'INVALID_INPUT_ERROR',
  /** Entity not found. */
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  /** Unauthorized */
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  /** An unknown server error has occurred. */
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

/** An optimization project for a specific facility. */
export type Project = Node & {
  __typename?: 'Project';
  /** The threshold in minutes that will be applied to a suggestion start time to restrict acknowledgements. */
  acknowledgementWindowThreshold: Scalars['Int'];
  /** Whether suggestions for this project will be auto-acknowledged. */
  autoAcknowledge: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Component`. */
  components: ProjectComponentsManyToManyConnection;
  config: Scalars['JSON'];
  /** Returns the control type of a project. */
  controlType?: Maybe<ControlType>;
  controllers: UsersConnection;
  /** The time the project was created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The description of optimization project. */
  description?: Maybe<Scalars['String']>;
  /** The date in which the optimization project ends. */
  endDate?: Maybe<Scalars['Date']>;
  /** Reads a single `Facility` that is related to this `Project`. */
  facility?: Maybe<Facility>;
  /** The identifier of the facility. */
  facilityId: Scalars['Int'];
  /** The identifier of the optimization project. */
  id: Scalars['Int'];
  /** Is current user is watching project. */
  isWatching?: Maybe<Scalars['Boolean']>;
  /** The name of optimization project. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Available permissions for the Project. */
  permissions?: Maybe<ProjectPermissions>;
  /** Reads a single `ProjectType` that is related to this `Project`. */
  projectType?: Maybe<ProjectType>;
  /** The type of optimization project. */
  projectTypeId: Scalars['Int'];
  /** The name of the project type */
  projectTypeName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ProjectWatcher`. */
  projectWatchers: ProjectWatchersConnection;
  /** The date in which the optimization project starts. */
  startDate?: Maybe<Scalars['Date']>;
  /** Reads and enables pagination through a set of `Suggestion`. */
  suggestions: SuggestionsConnection;
  /** The time the project was updated . */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `User`. */
  watchers: ProjectWatchersManyToManyConnection;
};

/** An optimization project for a specific facility. */
export type ProjectComponentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ComponentCondition>;
  filter?: InputMaybe<ComponentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** An optimization project for a specific facility. */
export type ProjectControllersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** An optimization project for a specific facility. */
export type ProjectProjectWatchersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectWatcherCondition>;
  filter?: InputMaybe<ProjectWatcherFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** An optimization project for a specific facility. */
export type ProjectSuggestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionCondition>;
  filter?: InputMaybe<SuggestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionsOrderBy>>;
};

/** An optimization project for a specific facility. */
export type ProjectWatchersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type ProjectAggregates = {
  __typename?: 'ProjectAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProjectAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ProjectDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProjectDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProjectMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProjectMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProjectStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProjectStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProjectSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProjectVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProjectVarianceSampleAggregates>;
};

export type ProjectAverageAggregates = {
  __typename?: 'ProjectAverageAggregates';
  /** Mean average of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigFloat']>;
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectComponent = Node & {
  __typename?: 'ProjectComponent';
  /** Reads a single `Component` that is related to this `ProjectComponent`. */
  component?: Maybe<Component>;
  componentId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectComponent`. */
  project?: Maybe<Project>;
  projectId: Scalars['Int'];
};

/** An input for mutations affecting `ProjectComponent` */
export type ProjectComponentInput = {
  componentId: Scalars['Int'];
  /** Mutates a `Project` as part of the payload */
  project?: InputMaybe<ProjectInput>;
  projectId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `ProjectComponent`. Fields that are set will be updated. */
export type ProjectComponentPatch = {
  componentId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Component` values, with data from `ProjectComponent`. */
export type ProjectComponentsManyToManyConnection = {
  __typename?: 'ProjectComponentsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ComponentAggregates>;
  /** A list of edges which contains the `Component`, info from the `ProjectComponent`, and the cursor to aid in pagination. */
  edges: Array<ProjectComponentsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ComponentAggregates>>;
  /** A list of `Component` objects. */
  nodes: Array<Component>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Component` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Component` values, with data from `ProjectComponent`. */
export type ProjectComponentsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ComponentsGroupBy>;
  having?: InputMaybe<ComponentsHavingInput>;
};

/** A `Component` edge in the connection, with data from `ProjectComponent`. */
export type ProjectComponentsManyToManyEdge = {
  __typename?: 'ProjectComponentsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Component` at the end of the edge. */
  node: Component;
};

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `acknowledgementWindowThreshold` field. */
  acknowledgementWindowThreshold?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `autoAcknowledge` field. */
  autoAcknowledge?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `projectTypeId` field. */
  projectTypeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ProjectDistinctAggregates = {
  __typename?: 'ProjectDistinctAggregates';
  /** Distinct of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['JSON']>;
  /** Distinct of autoAcknowledge across the matching connection */
  autoAcknowledge?: Maybe<Scalars['JSON']>;
  /** Distinct of config across the matching connection */
  config?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the control type of a project.
   */
  controlType?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of endDate across the matching connection */
  endDate?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Is current user is watching project.
   */
  isWatching?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /** Distinct of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The name of the project type
   */
  projectTypeName?: Maybe<Scalars['JSON']>;
  /** Distinct of startDate across the matching connection */
  startDate?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type ProjectDistinctCountAggregates = {
  __typename?: 'ProjectDistinctCountAggregates';
  /** Distinct count of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigInt']>;
  /** Distinct count of autoAcknowledge across the matching connection */
  autoAcknowledge?: Maybe<Scalars['BigInt']>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the control type of a project.
   */
  controlType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDate across the matching connection */
  endDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Is current user is watching project.
   */
  isWatching?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The name of the project type
   */
  projectTypeName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDate across the matching connection */
  startDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `acknowledgementWindowThreshold` field. */
  acknowledgementWindowThreshold?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `autoAcknowledge` field. */
  autoAcknowledge?: InputMaybe<BooleanFilter>;
  /** Filter by the permission can_control */
  canControl?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `controlType` field. */
  controlType?: InputMaybe<ControlTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isWatching` field. */
  isWatching?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `projectTypeId` field. */
  projectTypeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectTypeName` field. */
  projectTypeName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Project` */
export type ProjectInput = {
  /** The threshold in minutes that will be applied to a suggestion start time to restrict acknowledgements. */
  acknowledgementWindowThreshold?: InputMaybe<Scalars['Int']>;
  /** Whether suggestions for this project will be auto-acknowledged. */
  autoAcknowledge?: InputMaybe<Scalars['Boolean']>;
  config?: InputMaybe<Scalars['JSON']>;
  /** The description of optimization project. */
  description?: InputMaybe<Scalars['String']>;
  /** The date in which the optimization project ends. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** The identifier of the facility. */
  facilityId: Scalars['Int'];
  /** The name of optimization project. */
  name: Scalars['String'];
  /** The type of optimization project. */
  projectTypeId: Scalars['Int'];
  /** The date in which the optimization project starts. */
  startDate?: InputMaybe<Scalars['Date']>;
};

export type ProjectMaxAggregates = {
  __typename?: 'ProjectMaxAggregates';
  /** Maximum of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['Int']>;
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['Int']>;
};

export type ProjectMinAggregates = {
  __typename?: 'ProjectMinAggregates';
  /** Minimum of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['Int']>;
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Project`. Fields that are set will be updated. */
export type ProjectPatch = {
  /** The threshold in minutes that will be applied to a suggestion start time to restrict acknowledgements. */
  acknowledgementWindowThreshold?: InputMaybe<Scalars['Int']>;
  /** Whether suggestions for this project will be auto-acknowledged. */
  autoAcknowledge?: InputMaybe<Scalars['Boolean']>;
  config?: InputMaybe<Scalars['JSON']>;
  /** The description of optimization project. */
  description?: InputMaybe<Scalars['String']>;
  /** The date in which the optimization project ends. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** The identifier of the facility. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** The name of optimization project. */
  name?: InputMaybe<Scalars['String']>;
  /** The type of optimization project. */
  projectTypeId?: InputMaybe<Scalars['Int']>;
  /** The date in which the optimization project starts. */
  startDate?: InputMaybe<Scalars['Date']>;
};

/** Permissive capabilities on a Project. */
export type ProjectPermissions = {
  __typename?: 'ProjectPermissions';
  /** Whether a user can approve and/or transition suggestions in the project. */
  canControl: Scalars['Boolean'];
};

export type ProjectStddevPopulationAggregates = {
  __typename?: 'ProjectStddevPopulationAggregates';
  /** Population standard deviation of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectStddevSampleAggregates = {
  __typename?: 'ProjectStddevSampleAggregates';
  /** Sample standard deviation of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectSumAggregates = {
  __typename?: 'ProjectSumAggregates';
  /** Sum of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold: Scalars['BigInt'];
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of projectTypeId across the matching connection */
  projectTypeId: Scalars['BigInt'];
};

/** Type of optimization project that can be associated to a facility. */
export type ProjectType = Node & {
  __typename?: 'ProjectType';
  /** The description of the project type */
  description?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByProjectProjectTypeIdAndFacilityId: ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyConnection;
  /** The identifier of the project type */
  id: Scalars['Int'];
  /** The name of the project type */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection;
};

/** Type of optimization project that can be associated to a facility. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** Type of optimization project that can be associated to a facility. */
export type ProjectTypeProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectTypeAggregates = {
  __typename?: 'ProjectTypeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProjectTypeAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ProjectTypeDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProjectTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProjectTypeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProjectTypeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProjectTypeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProjectTypeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProjectTypeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProjectTypeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProjectTypeVarianceSampleAggregates>;
};

export type ProjectTypeAverageAggregates = {
  __typename?: 'ProjectTypeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ProjectType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProjectTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectTypeDistinctAggregates = {
  __typename?: 'ProjectTypeDistinctAggregates';
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
};

export type ProjectTypeDistinctCountAggregates = {
  __typename?: 'ProjectTypeDistinctCountAggregates';
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `Project`. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `Project`, and the cursor to aid in pagination. */
    edges: Array<ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `Project`. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `Project`. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `Project`. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `Project`. */
    projects: ProjectsConnection;
  };

/** A `Facility` edge in the connection, with data from `Project`. */
export type ProjectTypeFacilitiesByProjectProjectTypeIdAndFacilityIdManyToManyEdgeProjectsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<ProjectCondition>;
    filter?: InputMaybe<ProjectFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
  };

/** A filter to be used against `ProjectType` object types. All fields are combined with a logical ‘and.’ */
export type ProjectTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectTypeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectTypeFilter>>;
};

export type ProjectTypeMaxAggregates = {
  __typename?: 'ProjectTypeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ProjectTypeMinAggregates = {
  __typename?: 'ProjectTypeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type ProjectTypeStddevPopulationAggregates = {
  __typename?: 'ProjectTypeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProjectTypeStddevSampleAggregates = {
  __typename?: 'ProjectTypeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProjectTypeSumAggregates = {
  __typename?: 'ProjectTypeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type ProjectTypeVariancePopulationAggregates = {
  __typename?: 'ProjectTypeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type ProjectTypeVarianceSampleAggregates = {
  __typename?: 'ProjectTypeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ProjectType` values. */
export type ProjectTypesConnection = {
  __typename?: 'ProjectTypesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProjectTypeAggregates>;
  /** A list of edges which contains the `ProjectType` and cursor to aid in pagination. */
  edges: Array<ProjectTypesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProjectTypeAggregates>>;
  /** A list of `ProjectType` objects. */
  nodes: Array<ProjectType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ProjectType` values. */
export type ProjectTypesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectTypesGroupBy>;
  having?: InputMaybe<ProjectTypesHavingInput>;
};

/** A `ProjectType` edge in the connection. */
export type ProjectTypesEdge = {
  __typename?: 'ProjectTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProjectType` at the end of the edge. */
  node: ProjectType;
};

/** Grouping methods for `ProjectType` for usage during aggregation. */
export enum ProjectTypesGroupBy {
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT'
}

export type ProjectTypesHavingAverageInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingDistinctCountInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingDistinctInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectType` aggregates. */
export type ProjectTypesHavingInput = {
  AND?: InputMaybe<Array<ProjectTypesHavingInput>>;
  OR?: InputMaybe<Array<ProjectTypesHavingInput>>;
  average?: InputMaybe<ProjectTypesHavingAverageInput>;
  distinct?: InputMaybe<ProjectTypesHavingDistinctInput>;
  distinctCount?: InputMaybe<ProjectTypesHavingDistinctCountInput>;
  max?: InputMaybe<ProjectTypesHavingMaxInput>;
  min?: InputMaybe<ProjectTypesHavingMinInput>;
  stddevPopulation?: InputMaybe<ProjectTypesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProjectTypesHavingStddevSampleInput>;
  sum?: InputMaybe<ProjectTypesHavingSumInput>;
  variancePopulation?: InputMaybe<ProjectTypesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProjectTypesHavingVarianceSampleInput>;
};

export type ProjectTypesHavingMaxInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingMinInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingStddevPopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingStddevSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingSumInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingVariancePopulationInput = {
  id?: InputMaybe<HavingIntFilter>;
};

export type ProjectTypesHavingVarianceSampleInput = {
  id?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `ProjectType`. */
export enum ProjectTypesOrderBy {
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_AVERAGE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_AVERAGE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_AVERAGE_CONFIG_ASC = 'PROJECTS_AVERAGE_CONFIG_ASC',
  PROJECTS_AVERAGE_CONFIG_DESC = 'PROJECTS_AVERAGE_CONFIG_DESC',
  PROJECTS_AVERAGE_CREATED_AT_ASC = 'PROJECTS_AVERAGE_CREATED_AT_ASC',
  PROJECTS_AVERAGE_CREATED_AT_DESC = 'PROJECTS_AVERAGE_CREATED_AT_DESC',
  PROJECTS_AVERAGE_DESCRIPTION_ASC = 'PROJECTS_AVERAGE_DESCRIPTION_ASC',
  PROJECTS_AVERAGE_DESCRIPTION_DESC = 'PROJECTS_AVERAGE_DESCRIPTION_DESC',
  PROJECTS_AVERAGE_END_DATE_ASC = 'PROJECTS_AVERAGE_END_DATE_ASC',
  PROJECTS_AVERAGE_END_DATE_DESC = 'PROJECTS_AVERAGE_END_DATE_DESC',
  PROJECTS_AVERAGE_FACILITY_ID_ASC = 'PROJECTS_AVERAGE_FACILITY_ID_ASC',
  PROJECTS_AVERAGE_FACILITY_ID_DESC = 'PROJECTS_AVERAGE_FACILITY_ID_DESC',
  PROJECTS_AVERAGE_ID_ASC = 'PROJECTS_AVERAGE_ID_ASC',
  PROJECTS_AVERAGE_ID_DESC = 'PROJECTS_AVERAGE_ID_DESC',
  PROJECTS_AVERAGE_NAME_ASC = 'PROJECTS_AVERAGE_NAME_ASC',
  PROJECTS_AVERAGE_NAME_DESC = 'PROJECTS_AVERAGE_NAME_DESC',
  PROJECTS_AVERAGE_PROJECT_TYPE_ID_ASC = 'PROJECTS_AVERAGE_PROJECT_TYPE_ID_ASC',
  PROJECTS_AVERAGE_PROJECT_TYPE_ID_DESC = 'PROJECTS_AVERAGE_PROJECT_TYPE_ID_DESC',
  PROJECTS_AVERAGE_START_DATE_ASC = 'PROJECTS_AVERAGE_START_DATE_ASC',
  PROJECTS_AVERAGE_START_DATE_DESC = 'PROJECTS_AVERAGE_START_DATE_DESC',
  PROJECTS_AVERAGE_UPDATED_AT_ASC = 'PROJECTS_AVERAGE_UPDATED_AT_ASC',
  PROJECTS_AVERAGE_UPDATED_AT_DESC = 'PROJECTS_AVERAGE_UPDATED_AT_DESC',
  PROJECTS_COUNT_ASC = 'PROJECTS_COUNT_ASC',
  PROJECTS_COUNT_DESC = 'PROJECTS_COUNT_DESC',
  PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_DISTINCT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_DISTINCT_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_DISTINCT_CONFIG_ASC = 'PROJECTS_DISTINCT_CONFIG_ASC',
  PROJECTS_DISTINCT_CONFIG_DESC = 'PROJECTS_DISTINCT_CONFIG_DESC',
  PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_DISTINCT_COUNT_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_DISTINCT_COUNT_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_DISTINCT_COUNT_CONFIG_ASC = 'PROJECTS_DISTINCT_COUNT_CONFIG_ASC',
  PROJECTS_DISTINCT_COUNT_CONFIG_DESC = 'PROJECTS_DISTINCT_COUNT_CONFIG_DESC',
  PROJECTS_DISTINCT_COUNT_CREATED_AT_ASC = 'PROJECTS_DISTINCT_COUNT_CREATED_AT_ASC',
  PROJECTS_DISTINCT_COUNT_CREATED_AT_DESC = 'PROJECTS_DISTINCT_COUNT_CREATED_AT_DESC',
  PROJECTS_DISTINCT_COUNT_DESCRIPTION_ASC = 'PROJECTS_DISTINCT_COUNT_DESCRIPTION_ASC',
  PROJECTS_DISTINCT_COUNT_DESCRIPTION_DESC = 'PROJECTS_DISTINCT_COUNT_DESCRIPTION_DESC',
  PROJECTS_DISTINCT_COUNT_END_DATE_ASC = 'PROJECTS_DISTINCT_COUNT_END_DATE_ASC',
  PROJECTS_DISTINCT_COUNT_END_DATE_DESC = 'PROJECTS_DISTINCT_COUNT_END_DATE_DESC',
  PROJECTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'PROJECTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  PROJECTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'PROJECTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  PROJECTS_DISTINCT_COUNT_ID_ASC = 'PROJECTS_DISTINCT_COUNT_ID_ASC',
  PROJECTS_DISTINCT_COUNT_ID_DESC = 'PROJECTS_DISTINCT_COUNT_ID_DESC',
  PROJECTS_DISTINCT_COUNT_NAME_ASC = 'PROJECTS_DISTINCT_COUNT_NAME_ASC',
  PROJECTS_DISTINCT_COUNT_NAME_DESC = 'PROJECTS_DISTINCT_COUNT_NAME_DESC',
  PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_ASC = 'PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_ASC',
  PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_DESC = 'PROJECTS_DISTINCT_COUNT_PROJECT_TYPE_ID_DESC',
  PROJECTS_DISTINCT_COUNT_START_DATE_ASC = 'PROJECTS_DISTINCT_COUNT_START_DATE_ASC',
  PROJECTS_DISTINCT_COUNT_START_DATE_DESC = 'PROJECTS_DISTINCT_COUNT_START_DATE_DESC',
  PROJECTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'PROJECTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  PROJECTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'PROJECTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  PROJECTS_DISTINCT_CREATED_AT_ASC = 'PROJECTS_DISTINCT_CREATED_AT_ASC',
  PROJECTS_DISTINCT_CREATED_AT_DESC = 'PROJECTS_DISTINCT_CREATED_AT_DESC',
  PROJECTS_DISTINCT_DESCRIPTION_ASC = 'PROJECTS_DISTINCT_DESCRIPTION_ASC',
  PROJECTS_DISTINCT_DESCRIPTION_DESC = 'PROJECTS_DISTINCT_DESCRIPTION_DESC',
  PROJECTS_DISTINCT_END_DATE_ASC = 'PROJECTS_DISTINCT_END_DATE_ASC',
  PROJECTS_DISTINCT_END_DATE_DESC = 'PROJECTS_DISTINCT_END_DATE_DESC',
  PROJECTS_DISTINCT_FACILITY_ID_ASC = 'PROJECTS_DISTINCT_FACILITY_ID_ASC',
  PROJECTS_DISTINCT_FACILITY_ID_DESC = 'PROJECTS_DISTINCT_FACILITY_ID_DESC',
  PROJECTS_DISTINCT_ID_ASC = 'PROJECTS_DISTINCT_ID_ASC',
  PROJECTS_DISTINCT_ID_DESC = 'PROJECTS_DISTINCT_ID_DESC',
  PROJECTS_DISTINCT_NAME_ASC = 'PROJECTS_DISTINCT_NAME_ASC',
  PROJECTS_DISTINCT_NAME_DESC = 'PROJECTS_DISTINCT_NAME_DESC',
  PROJECTS_DISTINCT_PROJECT_TYPE_ID_ASC = 'PROJECTS_DISTINCT_PROJECT_TYPE_ID_ASC',
  PROJECTS_DISTINCT_PROJECT_TYPE_ID_DESC = 'PROJECTS_DISTINCT_PROJECT_TYPE_ID_DESC',
  PROJECTS_DISTINCT_START_DATE_ASC = 'PROJECTS_DISTINCT_START_DATE_ASC',
  PROJECTS_DISTINCT_START_DATE_DESC = 'PROJECTS_DISTINCT_START_DATE_DESC',
  PROJECTS_DISTINCT_UPDATED_AT_ASC = 'PROJECTS_DISTINCT_UPDATED_AT_ASC',
  PROJECTS_DISTINCT_UPDATED_AT_DESC = 'PROJECTS_DISTINCT_UPDATED_AT_DESC',
  PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_MAX_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_MAX_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_MAX_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_MAX_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_MAX_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_MAX_CONFIG_ASC = 'PROJECTS_MAX_CONFIG_ASC',
  PROJECTS_MAX_CONFIG_DESC = 'PROJECTS_MAX_CONFIG_DESC',
  PROJECTS_MAX_CREATED_AT_ASC = 'PROJECTS_MAX_CREATED_AT_ASC',
  PROJECTS_MAX_CREATED_AT_DESC = 'PROJECTS_MAX_CREATED_AT_DESC',
  PROJECTS_MAX_DESCRIPTION_ASC = 'PROJECTS_MAX_DESCRIPTION_ASC',
  PROJECTS_MAX_DESCRIPTION_DESC = 'PROJECTS_MAX_DESCRIPTION_DESC',
  PROJECTS_MAX_END_DATE_ASC = 'PROJECTS_MAX_END_DATE_ASC',
  PROJECTS_MAX_END_DATE_DESC = 'PROJECTS_MAX_END_DATE_DESC',
  PROJECTS_MAX_FACILITY_ID_ASC = 'PROJECTS_MAX_FACILITY_ID_ASC',
  PROJECTS_MAX_FACILITY_ID_DESC = 'PROJECTS_MAX_FACILITY_ID_DESC',
  PROJECTS_MAX_ID_ASC = 'PROJECTS_MAX_ID_ASC',
  PROJECTS_MAX_ID_DESC = 'PROJECTS_MAX_ID_DESC',
  PROJECTS_MAX_NAME_ASC = 'PROJECTS_MAX_NAME_ASC',
  PROJECTS_MAX_NAME_DESC = 'PROJECTS_MAX_NAME_DESC',
  PROJECTS_MAX_PROJECT_TYPE_ID_ASC = 'PROJECTS_MAX_PROJECT_TYPE_ID_ASC',
  PROJECTS_MAX_PROJECT_TYPE_ID_DESC = 'PROJECTS_MAX_PROJECT_TYPE_ID_DESC',
  PROJECTS_MAX_START_DATE_ASC = 'PROJECTS_MAX_START_DATE_ASC',
  PROJECTS_MAX_START_DATE_DESC = 'PROJECTS_MAX_START_DATE_DESC',
  PROJECTS_MAX_UPDATED_AT_ASC = 'PROJECTS_MAX_UPDATED_AT_ASC',
  PROJECTS_MAX_UPDATED_AT_DESC = 'PROJECTS_MAX_UPDATED_AT_DESC',
  PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_MIN_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_MIN_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_MIN_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_MIN_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_MIN_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_MIN_CONFIG_ASC = 'PROJECTS_MIN_CONFIG_ASC',
  PROJECTS_MIN_CONFIG_DESC = 'PROJECTS_MIN_CONFIG_DESC',
  PROJECTS_MIN_CREATED_AT_ASC = 'PROJECTS_MIN_CREATED_AT_ASC',
  PROJECTS_MIN_CREATED_AT_DESC = 'PROJECTS_MIN_CREATED_AT_DESC',
  PROJECTS_MIN_DESCRIPTION_ASC = 'PROJECTS_MIN_DESCRIPTION_ASC',
  PROJECTS_MIN_DESCRIPTION_DESC = 'PROJECTS_MIN_DESCRIPTION_DESC',
  PROJECTS_MIN_END_DATE_ASC = 'PROJECTS_MIN_END_DATE_ASC',
  PROJECTS_MIN_END_DATE_DESC = 'PROJECTS_MIN_END_DATE_DESC',
  PROJECTS_MIN_FACILITY_ID_ASC = 'PROJECTS_MIN_FACILITY_ID_ASC',
  PROJECTS_MIN_FACILITY_ID_DESC = 'PROJECTS_MIN_FACILITY_ID_DESC',
  PROJECTS_MIN_ID_ASC = 'PROJECTS_MIN_ID_ASC',
  PROJECTS_MIN_ID_DESC = 'PROJECTS_MIN_ID_DESC',
  PROJECTS_MIN_NAME_ASC = 'PROJECTS_MIN_NAME_ASC',
  PROJECTS_MIN_NAME_DESC = 'PROJECTS_MIN_NAME_DESC',
  PROJECTS_MIN_PROJECT_TYPE_ID_ASC = 'PROJECTS_MIN_PROJECT_TYPE_ID_ASC',
  PROJECTS_MIN_PROJECT_TYPE_ID_DESC = 'PROJECTS_MIN_PROJECT_TYPE_ID_DESC',
  PROJECTS_MIN_START_DATE_ASC = 'PROJECTS_MIN_START_DATE_ASC',
  PROJECTS_MIN_START_DATE_DESC = 'PROJECTS_MIN_START_DATE_DESC',
  PROJECTS_MIN_UPDATED_AT_ASC = 'PROJECTS_MIN_UPDATED_AT_ASC',
  PROJECTS_MIN_UPDATED_AT_DESC = 'PROJECTS_MIN_UPDATED_AT_DESC',
  PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_STDDEV_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_STDDEV_POPULATION_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_STDDEV_POPULATION_CONFIG_ASC = 'PROJECTS_STDDEV_POPULATION_CONFIG_ASC',
  PROJECTS_STDDEV_POPULATION_CONFIG_DESC = 'PROJECTS_STDDEV_POPULATION_CONFIG_DESC',
  PROJECTS_STDDEV_POPULATION_CREATED_AT_ASC = 'PROJECTS_STDDEV_POPULATION_CREATED_AT_ASC',
  PROJECTS_STDDEV_POPULATION_CREATED_AT_DESC = 'PROJECTS_STDDEV_POPULATION_CREATED_AT_DESC',
  PROJECTS_STDDEV_POPULATION_DESCRIPTION_ASC = 'PROJECTS_STDDEV_POPULATION_DESCRIPTION_ASC',
  PROJECTS_STDDEV_POPULATION_DESCRIPTION_DESC = 'PROJECTS_STDDEV_POPULATION_DESCRIPTION_DESC',
  PROJECTS_STDDEV_POPULATION_END_DATE_ASC = 'PROJECTS_STDDEV_POPULATION_END_DATE_ASC',
  PROJECTS_STDDEV_POPULATION_END_DATE_DESC = 'PROJECTS_STDDEV_POPULATION_END_DATE_DESC',
  PROJECTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'PROJECTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  PROJECTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'PROJECTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  PROJECTS_STDDEV_POPULATION_ID_ASC = 'PROJECTS_STDDEV_POPULATION_ID_ASC',
  PROJECTS_STDDEV_POPULATION_ID_DESC = 'PROJECTS_STDDEV_POPULATION_ID_DESC',
  PROJECTS_STDDEV_POPULATION_NAME_ASC = 'PROJECTS_STDDEV_POPULATION_NAME_ASC',
  PROJECTS_STDDEV_POPULATION_NAME_DESC = 'PROJECTS_STDDEV_POPULATION_NAME_DESC',
  PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_ASC = 'PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_ASC',
  PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_DESC = 'PROJECTS_STDDEV_POPULATION_PROJECT_TYPE_ID_DESC',
  PROJECTS_STDDEV_POPULATION_START_DATE_ASC = 'PROJECTS_STDDEV_POPULATION_START_DATE_ASC',
  PROJECTS_STDDEV_POPULATION_START_DATE_DESC = 'PROJECTS_STDDEV_POPULATION_START_DATE_DESC',
  PROJECTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'PROJECTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  PROJECTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'PROJECTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_STDDEV_SAMPLE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_STDDEV_SAMPLE_CONFIG_ASC = 'PROJECTS_STDDEV_SAMPLE_CONFIG_ASC',
  PROJECTS_STDDEV_SAMPLE_CONFIG_DESC = 'PROJECTS_STDDEV_SAMPLE_CONFIG_DESC',
  PROJECTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'PROJECTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  PROJECTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'PROJECTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  PROJECTS_STDDEV_SAMPLE_DESCRIPTION_ASC = 'PROJECTS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PROJECTS_STDDEV_SAMPLE_DESCRIPTION_DESC = 'PROJECTS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PROJECTS_STDDEV_SAMPLE_END_DATE_ASC = 'PROJECTS_STDDEV_SAMPLE_END_DATE_ASC',
  PROJECTS_STDDEV_SAMPLE_END_DATE_DESC = 'PROJECTS_STDDEV_SAMPLE_END_DATE_DESC',
  PROJECTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_NAME_ASC = 'PROJECTS_STDDEV_SAMPLE_NAME_ASC',
  PROJECTS_STDDEV_SAMPLE_NAME_DESC = 'PROJECTS_STDDEV_SAMPLE_NAME_DESC',
  PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_ASC = 'PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_ASC',
  PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_DESC = 'PROJECTS_STDDEV_SAMPLE_PROJECT_TYPE_ID_DESC',
  PROJECTS_STDDEV_SAMPLE_START_DATE_ASC = 'PROJECTS_STDDEV_SAMPLE_START_DATE_ASC',
  PROJECTS_STDDEV_SAMPLE_START_DATE_DESC = 'PROJECTS_STDDEV_SAMPLE_START_DATE_DESC',
  PROJECTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'PROJECTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PROJECTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'PROJECTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_SUM_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_SUM_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_SUM_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_SUM_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_SUM_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_SUM_CONFIG_ASC = 'PROJECTS_SUM_CONFIG_ASC',
  PROJECTS_SUM_CONFIG_DESC = 'PROJECTS_SUM_CONFIG_DESC',
  PROJECTS_SUM_CREATED_AT_ASC = 'PROJECTS_SUM_CREATED_AT_ASC',
  PROJECTS_SUM_CREATED_AT_DESC = 'PROJECTS_SUM_CREATED_AT_DESC',
  PROJECTS_SUM_DESCRIPTION_ASC = 'PROJECTS_SUM_DESCRIPTION_ASC',
  PROJECTS_SUM_DESCRIPTION_DESC = 'PROJECTS_SUM_DESCRIPTION_DESC',
  PROJECTS_SUM_END_DATE_ASC = 'PROJECTS_SUM_END_DATE_ASC',
  PROJECTS_SUM_END_DATE_DESC = 'PROJECTS_SUM_END_DATE_DESC',
  PROJECTS_SUM_FACILITY_ID_ASC = 'PROJECTS_SUM_FACILITY_ID_ASC',
  PROJECTS_SUM_FACILITY_ID_DESC = 'PROJECTS_SUM_FACILITY_ID_DESC',
  PROJECTS_SUM_ID_ASC = 'PROJECTS_SUM_ID_ASC',
  PROJECTS_SUM_ID_DESC = 'PROJECTS_SUM_ID_DESC',
  PROJECTS_SUM_NAME_ASC = 'PROJECTS_SUM_NAME_ASC',
  PROJECTS_SUM_NAME_DESC = 'PROJECTS_SUM_NAME_DESC',
  PROJECTS_SUM_PROJECT_TYPE_ID_ASC = 'PROJECTS_SUM_PROJECT_TYPE_ID_ASC',
  PROJECTS_SUM_PROJECT_TYPE_ID_DESC = 'PROJECTS_SUM_PROJECT_TYPE_ID_DESC',
  PROJECTS_SUM_START_DATE_ASC = 'PROJECTS_SUM_START_DATE_ASC',
  PROJECTS_SUM_START_DATE_DESC = 'PROJECTS_SUM_START_DATE_DESC',
  PROJECTS_SUM_UPDATED_AT_ASC = 'PROJECTS_SUM_UPDATED_AT_ASC',
  PROJECTS_SUM_UPDATED_AT_DESC = 'PROJECTS_SUM_UPDATED_AT_DESC',
  PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_VARIANCE_POPULATION_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_VARIANCE_POPULATION_CONFIG_ASC = 'PROJECTS_VARIANCE_POPULATION_CONFIG_ASC',
  PROJECTS_VARIANCE_POPULATION_CONFIG_DESC = 'PROJECTS_VARIANCE_POPULATION_CONFIG_DESC',
  PROJECTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'PROJECTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  PROJECTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'PROJECTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  PROJECTS_VARIANCE_POPULATION_DESCRIPTION_ASC = 'PROJECTS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PROJECTS_VARIANCE_POPULATION_DESCRIPTION_DESC = 'PROJECTS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PROJECTS_VARIANCE_POPULATION_END_DATE_ASC = 'PROJECTS_VARIANCE_POPULATION_END_DATE_ASC',
  PROJECTS_VARIANCE_POPULATION_END_DATE_DESC = 'PROJECTS_VARIANCE_POPULATION_END_DATE_DESC',
  PROJECTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_NAME_ASC = 'PROJECTS_VARIANCE_POPULATION_NAME_ASC',
  PROJECTS_VARIANCE_POPULATION_NAME_DESC = 'PROJECTS_VARIANCE_POPULATION_NAME_DESC',
  PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_ASC = 'PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_ASC',
  PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_DESC = 'PROJECTS_VARIANCE_POPULATION_PROJECT_TYPE_ID_DESC',
  PROJECTS_VARIANCE_POPULATION_START_DATE_ASC = 'PROJECTS_VARIANCE_POPULATION_START_DATE_ASC',
  PROJECTS_VARIANCE_POPULATION_START_DATE_DESC = 'PROJECTS_VARIANCE_POPULATION_START_DATE_DESC',
  PROJECTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'PROJECTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PROJECTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'PROJECTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'PROJECTS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_ASC = 'PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_ASC',
  PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_DESC = 'PROJECTS_VARIANCE_SAMPLE_AUTO_ACKNOWLEDGE_DESC',
  PROJECTS_VARIANCE_SAMPLE_CONFIG_ASC = 'PROJECTS_VARIANCE_SAMPLE_CONFIG_ASC',
  PROJECTS_VARIANCE_SAMPLE_CONFIG_DESC = 'PROJECTS_VARIANCE_SAMPLE_CONFIG_DESC',
  PROJECTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'PROJECTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PROJECTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'PROJECTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'PROJECTS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PROJECTS_VARIANCE_SAMPLE_END_DATE_ASC = 'PROJECTS_VARIANCE_SAMPLE_END_DATE_ASC',
  PROJECTS_VARIANCE_SAMPLE_END_DATE_DESC = 'PROJECTS_VARIANCE_SAMPLE_END_DATE_DESC',
  PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_NAME_ASC = 'PROJECTS_VARIANCE_SAMPLE_NAME_ASC',
  PROJECTS_VARIANCE_SAMPLE_NAME_DESC = 'PROJECTS_VARIANCE_SAMPLE_NAME_DESC',
  PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_ASC = 'PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_ASC',
  PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_DESC = 'PROJECTS_VARIANCE_SAMPLE_PROJECT_TYPE_ID_DESC',
  PROJECTS_VARIANCE_SAMPLE_START_DATE_ASC = 'PROJECTS_VARIANCE_SAMPLE_START_DATE_ASC',
  PROJECTS_VARIANCE_SAMPLE_START_DATE_DESC = 'PROJECTS_VARIANCE_SAMPLE_START_DATE_DESC',
  PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'PROJECTS_VARIANCE_SAMPLE_UPDATED_AT_DESC'
}

export type ProjectVariancePopulationAggregates = {
  __typename?: 'ProjectVariancePopulationAggregates';
  /** Population variance of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigFloat']>;
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectVarianceSampleAggregates = {
  __typename?: 'ProjectVarianceSampleAggregates';
  /** Sample variance of acknowledgementWindowThreshold across the matching connection */
  acknowledgementWindowThreshold?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of projectTypeId across the matching connection */
  projectTypeId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectWatcher = Node & {
  __typename?: 'ProjectWatcher';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectWatcher`. */
  project?: Maybe<Project>;
  projectId: Scalars['Int'];
  subscribedChannels?: Maybe<Array<Maybe<UserSubscriptionChannel>>>;
  /** Reads a single `User` that is related to this `ProjectWatcher`. */
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type ProjectWatcherAggregates = {
  __typename?: 'ProjectWatcherAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ProjectWatcherAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<ProjectWatcherDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProjectWatcherDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ProjectWatcherMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ProjectWatcherMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ProjectWatcherStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ProjectWatcherStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ProjectWatcherSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ProjectWatcherVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ProjectWatcherVarianceSampleAggregates>;
};

export type ProjectWatcherAverageAggregates = {
  __typename?: 'ProjectWatcherAverageAggregates';
  /** Mean average of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ProjectWatcher` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectWatcherCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['String']>;
};

export type ProjectWatcherDistinctAggregates = {
  __typename?: 'ProjectWatcherDistinctAggregates';
  /** Distinct of projectId across the matching connection */
  projectId?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  subscribedChannels?: Maybe<Scalars['JSON']>;
  /** Distinct of userId across the matching connection */
  userId?: Maybe<Scalars['JSON']>;
};

export type ProjectWatcherDistinctCountAggregates = {
  __typename?: 'ProjectWatcherDistinctCountAggregates';
  /** Distinct count of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  subscribedChannels?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ProjectWatcher` object types. All fields are combined with a logical ‘and.’ */
export type ProjectWatcherFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectWatcherFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectWatcherFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectWatcherFilter>>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `subscribedChannels` field. */
  subscribedChannels?: InputMaybe<UserSubscriptionChannelListFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ProjectWatcher` */
export type ProjectWatcherInput = {
  /** Mutates a `Project` as part of the payload */
  project?: InputMaybe<ProjectInput>;
  projectId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type ProjectWatcherMaxAggregates = {
  __typename?: 'ProjectWatcherMaxAggregates';
  /** Maximum of projectId across the matching connection */
  projectId?: Maybe<Scalars['Int']>;
};

export type ProjectWatcherMinAggregates = {
  __typename?: 'ProjectWatcherMinAggregates';
  /** Minimum of projectId across the matching connection */
  projectId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `ProjectWatcher`. Fields that are set will be updated. */
export type ProjectWatcherPatch = {
  projectId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ProjectWatcherStddevPopulationAggregates = {
  __typename?: 'ProjectWatcherStddevPopulationAggregates';
  /** Population standard deviation of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectWatcherStddevSampleAggregates = {
  __typename?: 'ProjectWatcherStddevSampleAggregates';
  /** Sample standard deviation of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectWatcherSumAggregates = {
  __typename?: 'ProjectWatcherSumAggregates';
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt'];
};

export type ProjectWatcherVariancePopulationAggregates = {
  __typename?: 'ProjectWatcherVariancePopulationAggregates';
  /** Population variance of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type ProjectWatcherVarianceSampleAggregates = {
  __typename?: 'ProjectWatcherVarianceSampleAggregates';
  /** Sample variance of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ProjectWatcher` values. */
export type ProjectWatchersConnection = {
  __typename?: 'ProjectWatchersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProjectWatcherAggregates>;
  /** A list of edges which contains the `ProjectWatcher` and cursor to aid in pagination. */
  edges: Array<ProjectWatchersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProjectWatcherAggregates>>;
  /** A list of `ProjectWatcher` objects. */
  nodes: Array<ProjectWatcher>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectWatcher` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ProjectWatcher` values. */
export type ProjectWatchersConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectWatchersGroupBy>;
  having?: InputMaybe<ProjectWatchersHavingInput>;
};

/** A `ProjectWatcher` edge in the connection. */
export type ProjectWatchersEdge = {
  __typename?: 'ProjectWatchersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProjectWatcher` at the end of the edge. */
  node: ProjectWatcher;
};

/** Grouping methods for `ProjectWatcher` for usage during aggregation. */
export enum ProjectWatchersGroupBy {
  PROJECT_ID = 'PROJECT_ID',
  PROJECT_ID_DISTINCT = 'PROJECT_ID_DISTINCT',
  USER_ID = 'USER_ID',
  USER_ID_DISTINCT = 'USER_ID_DISTINCT'
}

export type ProjectWatchersHavingAverageInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingDistinctCountInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingDistinctInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectWatcher` aggregates. */
export type ProjectWatchersHavingInput = {
  AND?: InputMaybe<Array<ProjectWatchersHavingInput>>;
  OR?: InputMaybe<Array<ProjectWatchersHavingInput>>;
  average?: InputMaybe<ProjectWatchersHavingAverageInput>;
  distinct?: InputMaybe<ProjectWatchersHavingDistinctInput>;
  distinctCount?: InputMaybe<ProjectWatchersHavingDistinctCountInput>;
  max?: InputMaybe<ProjectWatchersHavingMaxInput>;
  min?: InputMaybe<ProjectWatchersHavingMinInput>;
  stddevPopulation?: InputMaybe<ProjectWatchersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProjectWatchersHavingStddevSampleInput>;
  sum?: InputMaybe<ProjectWatchersHavingSumInput>;
  variancePopulation?: InputMaybe<ProjectWatchersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProjectWatchersHavingVarianceSampleInput>;
};

export type ProjectWatchersHavingMaxInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingMinInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingStddevPopulationInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingStddevSampleInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingSumInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingVariancePopulationInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

export type ProjectWatchersHavingVarianceSampleInput = {
  projectId?: InputMaybe<HavingIntFilter>;
};

/** A connection to a list of `User` values, with data from `ProjectWatcher`. */
export type ProjectWatchersManyToManyConnection = {
  __typename?: 'ProjectWatchersManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectWatcher`, and the cursor to aid in pagination. */
  edges: Array<ProjectWatchersManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `User` values, with data from `ProjectWatcher`. */
export type ProjectWatchersManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UsersGroupBy>;
  having?: InputMaybe<UsersHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectWatcher`. */
export type ProjectWatchersManyToManyEdge = {
  __typename?: 'ProjectWatchersManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `ProjectWatcher`. */
export enum ProjectWatchersOrderBy {
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECT_ID_ASC = 'PROJECT_ID_ASC',
  PROJECT_ID_DESC = 'PROJECT_ID_DESC',
  USER_ID_ASC = 'USER_ID_ASC',
  USER_ID_DESC = 'USER_ID_DESC'
}

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Project` values. */
export type ProjectsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectsGroupBy>;
  having?: InputMaybe<ProjectsHavingInput>;
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

/** Grouping methods for `Project` for usage during aggregation. */
export enum ProjectsGroupBy {
  ACKNOWLEDGEMENT_WINDOW_THRESHOLD = 'ACKNOWLEDGEMENT_WINDOW_THRESHOLD',
  ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DISTINCT = 'ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DISTINCT',
  AUTO_ACKNOWLEDGE = 'AUTO_ACKNOWLEDGE',
  AUTO_ACKNOWLEDGE_DISTINCT = 'AUTO_ACKNOWLEDGE_DISTINCT',
  CONFIG = 'CONFIG',
  CONFIG_DISTINCT = 'CONFIG_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  END_DATE = 'END_DATE',
  END_DATE_DISTINCT = 'END_DATE_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  PROJECT_TYPE_ID = 'PROJECT_TYPE_ID',
  PROJECT_TYPE_ID_DISTINCT = 'PROJECT_TYPE_ID_DISTINCT',
  START_DATE = 'START_DATE',
  START_DATE_DISTINCT = 'START_DATE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type ProjectsHavingAverageInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingDistinctCountInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingDistinctInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Project` aggregates. */
export type ProjectsHavingInput = {
  AND?: InputMaybe<Array<ProjectsHavingInput>>;
  OR?: InputMaybe<Array<ProjectsHavingInput>>;
  average?: InputMaybe<ProjectsHavingAverageInput>;
  distinct?: InputMaybe<ProjectsHavingDistinctInput>;
  distinctCount?: InputMaybe<ProjectsHavingDistinctCountInput>;
  max?: InputMaybe<ProjectsHavingMaxInput>;
  min?: InputMaybe<ProjectsHavingMinInput>;
  stddevPopulation?: InputMaybe<ProjectsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProjectsHavingStddevSampleInput>;
  sum?: InputMaybe<ProjectsHavingSumInput>;
  variancePopulation?: InputMaybe<ProjectsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProjectsHavingVarianceSampleInput>;
};

export type ProjectsHavingMaxInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingMinInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingStddevPopulationInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingStddevSampleInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingSumInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingVariancePopulationInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectsHavingVarianceSampleInput = {
  acknowledgementWindowThreshold?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  projectTypeId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC = 'ACKNOWLEDGEMENT_WINDOW_THRESHOLD_ASC',
  ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC = 'ACKNOWLEDGEMENT_WINDOW_THRESHOLD_DESC',
  AUTO_ACKNOWLEDGE_ASC = 'AUTO_ACKNOWLEDGE_ASC',
  AUTO_ACKNOWLEDGE_DESC = 'AUTO_ACKNOWLEDGE_DESC',
  CONFIG_ASC = 'CONFIG_ASC',
  CONFIG_DESC = 'CONFIG_DESC',
  CONTROL_TYPE_ASC = 'CONTROL_TYPE_ASC',
  CONTROL_TYPE_DESC = 'CONTROL_TYPE_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  END_DATE_ASC = 'END_DATE_ASC',
  END_DATE_DESC = 'END_DATE_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_WATCHING_ASC = 'IS_WATCHING_ASC',
  IS_WATCHING_DESC = 'IS_WATCHING_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_AVERAGE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_AVERAGE_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_COUNT_ASC = 'PROJECT_COMPONENTS_COUNT_ASC',
  PROJECT_COMPONENTS_COUNT_DESC = 'PROJECT_COMPONENTS_COUNT_DESC',
  PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_COUNT_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_DISTINCT_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_MAX_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_MAX_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_MAX_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_MAX_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_MAX_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_MAX_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_MAX_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_MAX_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_MIN_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_MIN_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_MIN_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_MIN_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_MIN_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_MIN_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_MIN_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_MIN_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_POPULATION_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_SUM_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_SUM_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_SUM_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_SUM_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_SUM_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_SUM_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_SUM_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_SUM_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_COMPONENT_ID_DESC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_ASC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_DESC = 'PROJECT_COMPONENTS_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PROJECT_TYPE_ID_ASC = 'PROJECT_TYPE_ID_ASC',
  PROJECT_TYPE_ID_DESC = 'PROJECT_TYPE_ID_DESC',
  PROJECT_TYPE_NAME_ASC = 'PROJECT_TYPE_NAME_ASC',
  PROJECT_TYPE_NAME_DESC = 'PROJECT_TYPE_NAME_DESC',
  PROJECT_WATCHERS_AVERAGE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_AVERAGE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_AVERAGE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_AVERAGE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_AVERAGE_USER_ID_ASC = 'PROJECT_WATCHERS_AVERAGE_USER_ID_ASC',
  PROJECT_WATCHERS_AVERAGE_USER_ID_DESC = 'PROJECT_WATCHERS_AVERAGE_USER_ID_DESC',
  PROJECT_WATCHERS_COUNT_ASC = 'PROJECT_WATCHERS_COUNT_ASC',
  PROJECT_WATCHERS_COUNT_DESC = 'PROJECT_WATCHERS_COUNT_DESC',
  PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_PROJECT_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_PROJECT_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_PROJECT_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_PROJECT_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_USER_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_USER_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_USER_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_USER_ID_DESC',
  PROJECT_WATCHERS_MAX_PROJECT_ID_ASC = 'PROJECT_WATCHERS_MAX_PROJECT_ID_ASC',
  PROJECT_WATCHERS_MAX_PROJECT_ID_DESC = 'PROJECT_WATCHERS_MAX_PROJECT_ID_DESC',
  PROJECT_WATCHERS_MAX_USER_ID_ASC = 'PROJECT_WATCHERS_MAX_USER_ID_ASC',
  PROJECT_WATCHERS_MAX_USER_ID_DESC = 'PROJECT_WATCHERS_MAX_USER_ID_DESC',
  PROJECT_WATCHERS_MIN_PROJECT_ID_ASC = 'PROJECT_WATCHERS_MIN_PROJECT_ID_ASC',
  PROJECT_WATCHERS_MIN_PROJECT_ID_DESC = 'PROJECT_WATCHERS_MIN_PROJECT_ID_DESC',
  PROJECT_WATCHERS_MIN_USER_ID_ASC = 'PROJECT_WATCHERS_MIN_USER_ID_ASC',
  PROJECT_WATCHERS_MIN_USER_ID_DESC = 'PROJECT_WATCHERS_MIN_USER_ID_DESC',
  PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_ASC = 'PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_ASC',
  PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_DESC = 'PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_DESC',
  PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_ASC = 'PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_ASC',
  PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_DESC = 'PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_DESC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_ASC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_ASC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_DESC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_DESC',
  PROJECT_WATCHERS_SUM_PROJECT_ID_ASC = 'PROJECT_WATCHERS_SUM_PROJECT_ID_ASC',
  PROJECT_WATCHERS_SUM_PROJECT_ID_DESC = 'PROJECT_WATCHERS_SUM_PROJECT_ID_DESC',
  PROJECT_WATCHERS_SUM_USER_ID_ASC = 'PROJECT_WATCHERS_SUM_USER_ID_ASC',
  PROJECT_WATCHERS_SUM_USER_ID_DESC = 'PROJECT_WATCHERS_SUM_USER_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  SUGGESTIONS_AVERAGE_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_AVERAGE_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_AVERAGE_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_AVERAGE_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_AVERAGE_CREATED_AT_ASC = 'SUGGESTIONS_AVERAGE_CREATED_AT_ASC',
  SUGGESTIONS_AVERAGE_CREATED_AT_DESC = 'SUGGESTIONS_AVERAGE_CREATED_AT_DESC',
  SUGGESTIONS_AVERAGE_DESCRIPTION_ASC = 'SUGGESTIONS_AVERAGE_DESCRIPTION_ASC',
  SUGGESTIONS_AVERAGE_DESCRIPTION_DESC = 'SUGGESTIONS_AVERAGE_DESCRIPTION_DESC',
  SUGGESTIONS_AVERAGE_END_TIME_ASC = 'SUGGESTIONS_AVERAGE_END_TIME_ASC',
  SUGGESTIONS_AVERAGE_END_TIME_DESC = 'SUGGESTIONS_AVERAGE_END_TIME_DESC',
  SUGGESTIONS_AVERAGE_ID_ASC = 'SUGGESTIONS_AVERAGE_ID_ASC',
  SUGGESTIONS_AVERAGE_ID_DESC = 'SUGGESTIONS_AVERAGE_ID_DESC',
  SUGGESTIONS_AVERAGE_METADATA_ASC = 'SUGGESTIONS_AVERAGE_METADATA_ASC',
  SUGGESTIONS_AVERAGE_METADATA_DESC = 'SUGGESTIONS_AVERAGE_METADATA_DESC',
  SUGGESTIONS_AVERAGE_PROJECT_ID_ASC = 'SUGGESTIONS_AVERAGE_PROJECT_ID_ASC',
  SUGGESTIONS_AVERAGE_PROJECT_ID_DESC = 'SUGGESTIONS_AVERAGE_PROJECT_ID_DESC',
  SUGGESTIONS_AVERAGE_START_TIME_ASC = 'SUGGESTIONS_AVERAGE_START_TIME_ASC',
  SUGGESTIONS_AVERAGE_START_TIME_DESC = 'SUGGESTIONS_AVERAGE_START_TIME_DESC',
  SUGGESTIONS_AVERAGE_UPDATED_AT_ASC = 'SUGGESTIONS_AVERAGE_UPDATED_AT_ASC',
  SUGGESTIONS_AVERAGE_UPDATED_AT_DESC = 'SUGGESTIONS_AVERAGE_UPDATED_AT_DESC',
  SUGGESTIONS_AVERAGE_WITHDRAWN_AT_ASC = 'SUGGESTIONS_AVERAGE_WITHDRAWN_AT_ASC',
  SUGGESTIONS_AVERAGE_WITHDRAWN_AT_DESC = 'SUGGESTIONS_AVERAGE_WITHDRAWN_AT_DESC',
  SUGGESTIONS_COUNT_ASC = 'SUGGESTIONS_COUNT_ASC',
  SUGGESTIONS_COUNT_DESC = 'SUGGESTIONS_COUNT_DESC',
  SUGGESTIONS_DISTINCT_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_DISTINCT_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_DISTINCT_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_DISTINCT_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_DISTINCT_COUNT_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_DISTINCT_COUNT_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_DISTINCT_COUNT_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_DISTINCT_COUNT_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_DISTINCT_COUNT_CREATED_AT_ASC = 'SUGGESTIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  SUGGESTIONS_DISTINCT_COUNT_CREATED_AT_DESC = 'SUGGESTIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  SUGGESTIONS_DISTINCT_COUNT_DESCRIPTION_ASC = 'SUGGESTIONS_DISTINCT_COUNT_DESCRIPTION_ASC',
  SUGGESTIONS_DISTINCT_COUNT_DESCRIPTION_DESC = 'SUGGESTIONS_DISTINCT_COUNT_DESCRIPTION_DESC',
  SUGGESTIONS_DISTINCT_COUNT_END_TIME_ASC = 'SUGGESTIONS_DISTINCT_COUNT_END_TIME_ASC',
  SUGGESTIONS_DISTINCT_COUNT_END_TIME_DESC = 'SUGGESTIONS_DISTINCT_COUNT_END_TIME_DESC',
  SUGGESTIONS_DISTINCT_COUNT_ID_ASC = 'SUGGESTIONS_DISTINCT_COUNT_ID_ASC',
  SUGGESTIONS_DISTINCT_COUNT_ID_DESC = 'SUGGESTIONS_DISTINCT_COUNT_ID_DESC',
  SUGGESTIONS_DISTINCT_COUNT_METADATA_ASC = 'SUGGESTIONS_DISTINCT_COUNT_METADATA_ASC',
  SUGGESTIONS_DISTINCT_COUNT_METADATA_DESC = 'SUGGESTIONS_DISTINCT_COUNT_METADATA_DESC',
  SUGGESTIONS_DISTINCT_COUNT_PROJECT_ID_ASC = 'SUGGESTIONS_DISTINCT_COUNT_PROJECT_ID_ASC',
  SUGGESTIONS_DISTINCT_COUNT_PROJECT_ID_DESC = 'SUGGESTIONS_DISTINCT_COUNT_PROJECT_ID_DESC',
  SUGGESTIONS_DISTINCT_COUNT_START_TIME_ASC = 'SUGGESTIONS_DISTINCT_COUNT_START_TIME_ASC',
  SUGGESTIONS_DISTINCT_COUNT_START_TIME_DESC = 'SUGGESTIONS_DISTINCT_COUNT_START_TIME_DESC',
  SUGGESTIONS_DISTINCT_COUNT_UPDATED_AT_ASC = 'SUGGESTIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  SUGGESTIONS_DISTINCT_COUNT_UPDATED_AT_DESC = 'SUGGESTIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  SUGGESTIONS_DISTINCT_COUNT_WITHDRAWN_AT_ASC = 'SUGGESTIONS_DISTINCT_COUNT_WITHDRAWN_AT_ASC',
  SUGGESTIONS_DISTINCT_COUNT_WITHDRAWN_AT_DESC = 'SUGGESTIONS_DISTINCT_COUNT_WITHDRAWN_AT_DESC',
  SUGGESTIONS_DISTINCT_CREATED_AT_ASC = 'SUGGESTIONS_DISTINCT_CREATED_AT_ASC',
  SUGGESTIONS_DISTINCT_CREATED_AT_DESC = 'SUGGESTIONS_DISTINCT_CREATED_AT_DESC',
  SUGGESTIONS_DISTINCT_DESCRIPTION_ASC = 'SUGGESTIONS_DISTINCT_DESCRIPTION_ASC',
  SUGGESTIONS_DISTINCT_DESCRIPTION_DESC = 'SUGGESTIONS_DISTINCT_DESCRIPTION_DESC',
  SUGGESTIONS_DISTINCT_END_TIME_ASC = 'SUGGESTIONS_DISTINCT_END_TIME_ASC',
  SUGGESTIONS_DISTINCT_END_TIME_DESC = 'SUGGESTIONS_DISTINCT_END_TIME_DESC',
  SUGGESTIONS_DISTINCT_ID_ASC = 'SUGGESTIONS_DISTINCT_ID_ASC',
  SUGGESTIONS_DISTINCT_ID_DESC = 'SUGGESTIONS_DISTINCT_ID_DESC',
  SUGGESTIONS_DISTINCT_METADATA_ASC = 'SUGGESTIONS_DISTINCT_METADATA_ASC',
  SUGGESTIONS_DISTINCT_METADATA_DESC = 'SUGGESTIONS_DISTINCT_METADATA_DESC',
  SUGGESTIONS_DISTINCT_PROJECT_ID_ASC = 'SUGGESTIONS_DISTINCT_PROJECT_ID_ASC',
  SUGGESTIONS_DISTINCT_PROJECT_ID_DESC = 'SUGGESTIONS_DISTINCT_PROJECT_ID_DESC',
  SUGGESTIONS_DISTINCT_START_TIME_ASC = 'SUGGESTIONS_DISTINCT_START_TIME_ASC',
  SUGGESTIONS_DISTINCT_START_TIME_DESC = 'SUGGESTIONS_DISTINCT_START_TIME_DESC',
  SUGGESTIONS_DISTINCT_UPDATED_AT_ASC = 'SUGGESTIONS_DISTINCT_UPDATED_AT_ASC',
  SUGGESTIONS_DISTINCT_UPDATED_AT_DESC = 'SUGGESTIONS_DISTINCT_UPDATED_AT_DESC',
  SUGGESTIONS_DISTINCT_WITHDRAWN_AT_ASC = 'SUGGESTIONS_DISTINCT_WITHDRAWN_AT_ASC',
  SUGGESTIONS_DISTINCT_WITHDRAWN_AT_DESC = 'SUGGESTIONS_DISTINCT_WITHDRAWN_AT_DESC',
  SUGGESTIONS_MAX_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_MAX_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_MAX_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_MAX_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_MAX_CREATED_AT_ASC = 'SUGGESTIONS_MAX_CREATED_AT_ASC',
  SUGGESTIONS_MAX_CREATED_AT_DESC = 'SUGGESTIONS_MAX_CREATED_AT_DESC',
  SUGGESTIONS_MAX_DESCRIPTION_ASC = 'SUGGESTIONS_MAX_DESCRIPTION_ASC',
  SUGGESTIONS_MAX_DESCRIPTION_DESC = 'SUGGESTIONS_MAX_DESCRIPTION_DESC',
  SUGGESTIONS_MAX_END_TIME_ASC = 'SUGGESTIONS_MAX_END_TIME_ASC',
  SUGGESTIONS_MAX_END_TIME_DESC = 'SUGGESTIONS_MAX_END_TIME_DESC',
  SUGGESTIONS_MAX_ID_ASC = 'SUGGESTIONS_MAX_ID_ASC',
  SUGGESTIONS_MAX_ID_DESC = 'SUGGESTIONS_MAX_ID_DESC',
  SUGGESTIONS_MAX_METADATA_ASC = 'SUGGESTIONS_MAX_METADATA_ASC',
  SUGGESTIONS_MAX_METADATA_DESC = 'SUGGESTIONS_MAX_METADATA_DESC',
  SUGGESTIONS_MAX_PROJECT_ID_ASC = 'SUGGESTIONS_MAX_PROJECT_ID_ASC',
  SUGGESTIONS_MAX_PROJECT_ID_DESC = 'SUGGESTIONS_MAX_PROJECT_ID_DESC',
  SUGGESTIONS_MAX_START_TIME_ASC = 'SUGGESTIONS_MAX_START_TIME_ASC',
  SUGGESTIONS_MAX_START_TIME_DESC = 'SUGGESTIONS_MAX_START_TIME_DESC',
  SUGGESTIONS_MAX_UPDATED_AT_ASC = 'SUGGESTIONS_MAX_UPDATED_AT_ASC',
  SUGGESTIONS_MAX_UPDATED_AT_DESC = 'SUGGESTIONS_MAX_UPDATED_AT_DESC',
  SUGGESTIONS_MAX_WITHDRAWN_AT_ASC = 'SUGGESTIONS_MAX_WITHDRAWN_AT_ASC',
  SUGGESTIONS_MAX_WITHDRAWN_AT_DESC = 'SUGGESTIONS_MAX_WITHDRAWN_AT_DESC',
  SUGGESTIONS_MIN_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_MIN_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_MIN_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_MIN_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_MIN_CREATED_AT_ASC = 'SUGGESTIONS_MIN_CREATED_AT_ASC',
  SUGGESTIONS_MIN_CREATED_AT_DESC = 'SUGGESTIONS_MIN_CREATED_AT_DESC',
  SUGGESTIONS_MIN_DESCRIPTION_ASC = 'SUGGESTIONS_MIN_DESCRIPTION_ASC',
  SUGGESTIONS_MIN_DESCRIPTION_DESC = 'SUGGESTIONS_MIN_DESCRIPTION_DESC',
  SUGGESTIONS_MIN_END_TIME_ASC = 'SUGGESTIONS_MIN_END_TIME_ASC',
  SUGGESTIONS_MIN_END_TIME_DESC = 'SUGGESTIONS_MIN_END_TIME_DESC',
  SUGGESTIONS_MIN_ID_ASC = 'SUGGESTIONS_MIN_ID_ASC',
  SUGGESTIONS_MIN_ID_DESC = 'SUGGESTIONS_MIN_ID_DESC',
  SUGGESTIONS_MIN_METADATA_ASC = 'SUGGESTIONS_MIN_METADATA_ASC',
  SUGGESTIONS_MIN_METADATA_DESC = 'SUGGESTIONS_MIN_METADATA_DESC',
  SUGGESTIONS_MIN_PROJECT_ID_ASC = 'SUGGESTIONS_MIN_PROJECT_ID_ASC',
  SUGGESTIONS_MIN_PROJECT_ID_DESC = 'SUGGESTIONS_MIN_PROJECT_ID_DESC',
  SUGGESTIONS_MIN_START_TIME_ASC = 'SUGGESTIONS_MIN_START_TIME_ASC',
  SUGGESTIONS_MIN_START_TIME_DESC = 'SUGGESTIONS_MIN_START_TIME_DESC',
  SUGGESTIONS_MIN_UPDATED_AT_ASC = 'SUGGESTIONS_MIN_UPDATED_AT_ASC',
  SUGGESTIONS_MIN_UPDATED_AT_DESC = 'SUGGESTIONS_MIN_UPDATED_AT_DESC',
  SUGGESTIONS_MIN_WITHDRAWN_AT_ASC = 'SUGGESTIONS_MIN_WITHDRAWN_AT_ASC',
  SUGGESTIONS_MIN_WITHDRAWN_AT_DESC = 'SUGGESTIONS_MIN_WITHDRAWN_AT_DESC',
  SUGGESTIONS_STDDEV_POPULATION_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_STDDEV_POPULATION_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_STDDEV_POPULATION_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_STDDEV_POPULATION_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_STDDEV_POPULATION_CREATED_AT_ASC = 'SUGGESTIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  SUGGESTIONS_STDDEV_POPULATION_CREATED_AT_DESC = 'SUGGESTIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  SUGGESTIONS_STDDEV_POPULATION_DESCRIPTION_ASC = 'SUGGESTIONS_STDDEV_POPULATION_DESCRIPTION_ASC',
  SUGGESTIONS_STDDEV_POPULATION_DESCRIPTION_DESC = 'SUGGESTIONS_STDDEV_POPULATION_DESCRIPTION_DESC',
  SUGGESTIONS_STDDEV_POPULATION_END_TIME_ASC = 'SUGGESTIONS_STDDEV_POPULATION_END_TIME_ASC',
  SUGGESTIONS_STDDEV_POPULATION_END_TIME_DESC = 'SUGGESTIONS_STDDEV_POPULATION_END_TIME_DESC',
  SUGGESTIONS_STDDEV_POPULATION_ID_ASC = 'SUGGESTIONS_STDDEV_POPULATION_ID_ASC',
  SUGGESTIONS_STDDEV_POPULATION_ID_DESC = 'SUGGESTIONS_STDDEV_POPULATION_ID_DESC',
  SUGGESTIONS_STDDEV_POPULATION_METADATA_ASC = 'SUGGESTIONS_STDDEV_POPULATION_METADATA_ASC',
  SUGGESTIONS_STDDEV_POPULATION_METADATA_DESC = 'SUGGESTIONS_STDDEV_POPULATION_METADATA_DESC',
  SUGGESTIONS_STDDEV_POPULATION_PROJECT_ID_ASC = 'SUGGESTIONS_STDDEV_POPULATION_PROJECT_ID_ASC',
  SUGGESTIONS_STDDEV_POPULATION_PROJECT_ID_DESC = 'SUGGESTIONS_STDDEV_POPULATION_PROJECT_ID_DESC',
  SUGGESTIONS_STDDEV_POPULATION_START_TIME_ASC = 'SUGGESTIONS_STDDEV_POPULATION_START_TIME_ASC',
  SUGGESTIONS_STDDEV_POPULATION_START_TIME_DESC = 'SUGGESTIONS_STDDEV_POPULATION_START_TIME_DESC',
  SUGGESTIONS_STDDEV_POPULATION_UPDATED_AT_ASC = 'SUGGESTIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  SUGGESTIONS_STDDEV_POPULATION_UPDATED_AT_DESC = 'SUGGESTIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  SUGGESTIONS_STDDEV_POPULATION_WITHDRAWN_AT_ASC = 'SUGGESTIONS_STDDEV_POPULATION_WITHDRAWN_AT_ASC',
  SUGGESTIONS_STDDEV_POPULATION_WITHDRAWN_AT_DESC = 'SUGGESTIONS_STDDEV_POPULATION_WITHDRAWN_AT_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_CREATED_AT_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_CREATED_AT_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_DESCRIPTION_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_DESCRIPTION_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_END_TIME_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_END_TIME_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_END_TIME_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_END_TIME_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_ID_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_ID_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_ID_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_ID_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_METADATA_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_METADATA_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_METADATA_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_METADATA_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_PROJECT_ID_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_PROJECT_ID_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_START_TIME_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_START_TIME_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_START_TIME_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_START_TIME_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SUGGESTIONS_STDDEV_SAMPLE_WITHDRAWN_AT_ASC = 'SUGGESTIONS_STDDEV_SAMPLE_WITHDRAWN_AT_ASC',
  SUGGESTIONS_STDDEV_SAMPLE_WITHDRAWN_AT_DESC = 'SUGGESTIONS_STDDEV_SAMPLE_WITHDRAWN_AT_DESC',
  SUGGESTIONS_SUM_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_SUM_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_SUM_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_SUM_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_SUM_CREATED_AT_ASC = 'SUGGESTIONS_SUM_CREATED_AT_ASC',
  SUGGESTIONS_SUM_CREATED_AT_DESC = 'SUGGESTIONS_SUM_CREATED_AT_DESC',
  SUGGESTIONS_SUM_DESCRIPTION_ASC = 'SUGGESTIONS_SUM_DESCRIPTION_ASC',
  SUGGESTIONS_SUM_DESCRIPTION_DESC = 'SUGGESTIONS_SUM_DESCRIPTION_DESC',
  SUGGESTIONS_SUM_END_TIME_ASC = 'SUGGESTIONS_SUM_END_TIME_ASC',
  SUGGESTIONS_SUM_END_TIME_DESC = 'SUGGESTIONS_SUM_END_TIME_DESC',
  SUGGESTIONS_SUM_ID_ASC = 'SUGGESTIONS_SUM_ID_ASC',
  SUGGESTIONS_SUM_ID_DESC = 'SUGGESTIONS_SUM_ID_DESC',
  SUGGESTIONS_SUM_METADATA_ASC = 'SUGGESTIONS_SUM_METADATA_ASC',
  SUGGESTIONS_SUM_METADATA_DESC = 'SUGGESTIONS_SUM_METADATA_DESC',
  SUGGESTIONS_SUM_PROJECT_ID_ASC = 'SUGGESTIONS_SUM_PROJECT_ID_ASC',
  SUGGESTIONS_SUM_PROJECT_ID_DESC = 'SUGGESTIONS_SUM_PROJECT_ID_DESC',
  SUGGESTIONS_SUM_START_TIME_ASC = 'SUGGESTIONS_SUM_START_TIME_ASC',
  SUGGESTIONS_SUM_START_TIME_DESC = 'SUGGESTIONS_SUM_START_TIME_DESC',
  SUGGESTIONS_SUM_UPDATED_AT_ASC = 'SUGGESTIONS_SUM_UPDATED_AT_ASC',
  SUGGESTIONS_SUM_UPDATED_AT_DESC = 'SUGGESTIONS_SUM_UPDATED_AT_DESC',
  SUGGESTIONS_SUM_WITHDRAWN_AT_ASC = 'SUGGESTIONS_SUM_WITHDRAWN_AT_ASC',
  SUGGESTIONS_SUM_WITHDRAWN_AT_DESC = 'SUGGESTIONS_SUM_WITHDRAWN_AT_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_CREATED_AT_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_CREATED_AT_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_DESCRIPTION_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_DESCRIPTION_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_END_TIME_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_END_TIME_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_END_TIME_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_END_TIME_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_ID_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_ID_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_ID_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_ID_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_METADATA_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_METADATA_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_METADATA_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_METADATA_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_PROJECT_ID_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_PROJECT_ID_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_START_TIME_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_START_TIME_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_START_TIME_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_START_TIME_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SUGGESTIONS_VARIANCE_POPULATION_WITHDRAWN_AT_ASC = 'SUGGESTIONS_VARIANCE_POPULATION_WITHDRAWN_AT_ASC',
  SUGGESTIONS_VARIANCE_POPULATION_WITHDRAWN_AT_DESC = 'SUGGESTIONS_VARIANCE_POPULATION_WITHDRAWN_AT_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_STATUS_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_STATUS_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_STATUS_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_ACKNOWLEDGEMENT_STATUS_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_DESCRIPTION_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_DESCRIPTION_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_END_TIME_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_END_TIME_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_END_TIME_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_END_TIME_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_ID_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_ID_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_ID_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_ID_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_METADATA_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_METADATA_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_METADATA_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_METADATA_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_PROJECT_ID_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_PROJECT_ID_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_START_TIME_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_START_TIME_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_START_TIME_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_START_TIME_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SUGGESTIONS_VARIANCE_SAMPLE_WITHDRAWN_AT_ASC = 'SUGGESTIONS_VARIANCE_SAMPLE_WITHDRAWN_AT_ASC',
  SUGGESTIONS_VARIANCE_SAMPLE_WITHDRAWN_AT_DESC = 'SUGGESTIONS_VARIANCE_SAMPLE_WITHDRAWN_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  accountCharge?: Maybe<AccountCharge>;
  /** Reads a single `AccountCharge` using its globally unique `ID`. */
  accountChargeByNodeId?: Maybe<AccountCharge>;
  /** Reads and enables pagination through a set of `AccountCharge`. */
  accountCharges?: Maybe<AccountChargesConnection>;
  accountStatement?: Maybe<AccountStatement>;
  /** Reads a single `AccountStatement` using its globally unique `ID`. */
  accountStatementByNodeId?: Maybe<AccountStatement>;
  /** Reads and enables pagination through a set of `AccountStatementHistory`. */
  accountStatementHistories?: Maybe<AccountStatementHistoriesConnection>;
  /** Reads and enables pagination through a set of `AccountStatement`. */
  accountStatements?: Maybe<AccountStatementsConnection>;
  /** Reads and enables pagination through a set of `BalancingAuthority`. */
  balancingAuthorities?: Maybe<BalancingAuthoritiesConnection>;
  balancingAuthority?: Maybe<BalancingAuthority>;
  /** Reads a single `BalancingAuthority` using its globally unique `ID`. */
  balancingAuthorityByNodeId?: Maybe<BalancingAuthority>;
  component?: Maybe<Component>;
  /** Reads a single `Component` using its globally unique `ID`. */
  componentByNodeId?: Maybe<Component>;
  /** Reads and enables pagination through a set of `Component`. */
  components?: Maybe<ComponentsConnection>;
  controlEvent?: Maybe<ControlEvent>;
  /** Reads a single `ControlEvent` using its globally unique `ID`. */
  controlEventByNodeId?: Maybe<ControlEvent>;
  controlEventLog?: Maybe<ControlEventLog>;
  /** Reads a single `ControlEventLog` using its globally unique `ID`. */
  controlEventLogByNodeId?: Maybe<ControlEventLog>;
  /** Reads and enables pagination through a set of `ControlEventLog`. */
  controlEventLogs?: Maybe<ControlEventLogsConnection>;
  /** Reads a single `ControlEventState` using its globally unique `ID`. */
  controlEventStateByNodeId?: Maybe<ControlEventState>;
  /** Reads and enables pagination through a set of `ControlEvent`. */
  controlEvents?: Maybe<ControlEventsConnection>;
  currentIdentity?: Maybe<Identity>;
  deriveStatementDate?: Maybe<DerivedStatementDate>;
  event?: Maybe<Event>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  eventHandler?: Maybe<EventHandler>;
  /** Reads a single `EventHandler` using its globally unique `ID`. */
  eventHandlerByNodeId?: Maybe<EventHandler>;
  eventHandlerMetadatum?: Maybe<EventHandlerMetadatum>;
  /** Reads a single `EventHandlerMetadatum` using its globally unique `ID`. */
  eventHandlerMetadatumByNodeId?: Maybe<EventHandlerMetadatum>;
  eventType?: Maybe<EventType>;
  /** Reads a single `EventType` using its globally unique `ID`. */
  eventTypeByNodeId?: Maybe<EventType>;
  /** Reads and enables pagination through a set of `Facility`. */
  facilities?: Maybe<FacilitiesConnection>;
  facility?: Maybe<Facility>;
  /** Reads a single `Facility` using its globally unique `ID`. */
  facilityByNodeId?: Maybe<Facility>;
  facilityBySlug?: Maybe<Facility>;
  facilityGroup?: Maybe<FacilityGroup>;
  /** Reads a single `FacilityGroup` using its globally unique `ID`. */
  facilityGroupByNodeId?: Maybe<FacilityGroup>;
  /** Reads and enables pagination through a set of `FacilityGroup`. */
  facilityGroups?: Maybe<FacilityGroupsConnection>;
  facilityGroupsLinker?: Maybe<FacilityGroupsLinker>;
  /** Reads a single `FacilityGroupsLinker` using its globally unique `ID`. */
  facilityGroupsLinkerByNodeId?: Maybe<FacilityGroupsLinker>;
  /** Reads and enables pagination through a set of `FacilityGroupsLinker`. */
  facilityGroupsLinkers?: Maybe<FacilityGroupsLinkersConnection>;
  /** A json object of all facility groups built from the root. */
  groupings?: Maybe<GroupingsConnection>;
  /** A json object of all facility groups with their facilities built from the root and all ungrouped facilities */
  groupingsWithFacilities?: Maybe<GroupingsWithFacilitiesConnection>;
  machineUser?: Maybe<MachineUser>;
  /** Reads a single `MachineUser` using its globally unique `ID`. */
  machineUserByNodeId?: Maybe<MachineUser>;
  /** Reads and enables pagination through a set of `MachineUser`. */
  machineUsers?: Maybe<MachineUsersConnection>;
  meterCharge?: Maybe<MeterCharge>;
  /** Reads a single `MeterCharge` using its globally unique `ID`. */
  meterChargeByNodeId?: Maybe<MeterCharge>;
  /** Reads and enables pagination through a set of `MeterCharge`. */
  meterCharges?: Maybe<MeterChargesConnection>;
  meterStatement?: Maybe<MeterStatement>;
  /** Reads a single `MeterStatement` using its globally unique `ID`. */
  meterStatementByNodeId?: Maybe<MeterStatement>;
  /** Reads and enables pagination through a set of `MeterStatementHistory`. */
  meterStatementHistories?: Maybe<MeterStatementHistoriesConnection>;
  meterStatementPdf?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `MeterStatement`. */
  meterStatements?: Maybe<MeterStatementsConnection>;
  meterUsage?: Maybe<MeterUsage>;
  /** Reads a single `MeterUsage` using its globally unique `ID`. */
  meterUsageByNodeId?: Maybe<MeterUsage>;
  /** Reads and enables pagination through a set of `MeterUsage`. */
  meterUsages?: Maybe<MeterUsagesConnection>;
  /** @deprecated Use `sourceData` for IoT time series data. */
  metricData?: Maybe<MetricDataConnection>;
  /** Reads and enables pagination through a set of `MetricDependency`. */
  metricDependencies?: Maybe<MetricDependenciesConnection>;
  metricDependency?: Maybe<MetricDependency>;
  /** Reads a single `MetricDependency` using its globally unique `ID`. */
  metricDependencyByNodeId?: Maybe<MetricDependency>;
  metricDependencyByPath?: Maybe<MetricDependency>;
  /** @deprecated Use `sourceLabels` for IoT device name and field discovery. */
  metricLabels: Array<MetricLabel>;
  /** Reads and enables pagination through a set of `MetricMetadatum`. */
  metricMetadata?: Maybe<MetricMetadataConnection>;
  metricMetadatum?: Maybe<MetricMetadatum>;
  /** Reads a single `MetricMetadatum` using its globally unique `ID`. */
  metricMetadatumByNodeId?: Maybe<MetricMetadatum>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PlatformAccessToken`. */
  platformAccessTokens?: Maybe<PlatformAccessTokensConnection>;
  /** Returns a list of known platform errors */
  platformErrors: Array<PlatformError>;
  /** Returns the current user`s profile. */
  profile?: Maybe<User>;
  project?: Maybe<Project>;
  /** Reads a single `Project` using its globally unique `ID`. */
  projectByNodeId?: Maybe<Project>;
  projectComponent?: Maybe<ProjectComponent>;
  /** Reads a single `ProjectComponent` using its globally unique `ID`. */
  projectComponentByNodeId?: Maybe<ProjectComponent>;
  projectType?: Maybe<ProjectType>;
  projectTypeByName?: Maybe<ProjectType>;
  /** Reads a single `ProjectType` using its globally unique `ID`. */
  projectTypeByNodeId?: Maybe<ProjectType>;
  /** Reads and enables pagination through a set of `ProjectType`. */
  projectTypes?: Maybe<ProjectTypesConnection>;
  projectWatcher?: Maybe<ProjectWatcher>;
  /** Reads a single `ProjectWatcher` using its globally unique `ID`. */
  projectWatcherByNodeId?: Maybe<ProjectWatcher>;
  /** Reads and enables pagination through a set of `ProjectWatcher`. */
  projectWatchers?: Maybe<ProjectWatchersConnection>;
  /** Reads and enables pagination through a set of `Project`. */
  projects?: Maybe<ProjectsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  sourceData?: Maybe<MetricDataConnection>;
  sourceLabels: Array<SourceLabel>;
  suggestion?: Maybe<Suggestion>;
  /** Reads and enables pagination through a set of `SuggestionActivityLog`. */
  suggestionActivityLogs?: Maybe<SuggestionActivityLogsConnection>;
  /** Reads a single `Suggestion` using its globally unique `ID`. */
  suggestionByNodeId?: Maybe<Suggestion>;
  suggestionControlEvent?: Maybe<SuggestionControlEvent>;
  /** Reads a single `SuggestionControlEvent` using its globally unique `ID`. */
  suggestionControlEventByNodeId?: Maybe<SuggestionControlEvent>;
  /** Reads and enables pagination through a set of `Suggestion`. */
  suggestions?: Maybe<SuggestionsConnection>;
  timezone?: Maybe<Timezone>;
  /** Reads a single `Timezone` using its globally unique `ID`. */
  timezoneByNodeId?: Maybe<Timezone>;
  /** Reads and enables pagination through a set of `Timezone`. */
  timezones?: Maybe<TimezonesConnection>;
  unitDimension?: Maybe<UnitDimension>;
  /** Reads a single `UnitDimension` using its globally unique `ID`. */
  unitDimensionByNodeId?: Maybe<UnitDimension>;
  /** Reads and enables pagination through a set of `UnitDimension`. */
  unitDimensions?: Maybe<UnitDimensionsConnection>;
  unitGroup?: Maybe<UnitGroup>;
  /** Reads a single `UnitGroup` using its globally unique `ID`. */
  unitGroupByNodeId?: Maybe<UnitGroup>;
  unitGroupDimension?: Maybe<UnitGroupDimension>;
  /** Reads a single `UnitGroupDimension` using its globally unique `ID`. */
  unitGroupDimensionByNodeId?: Maybe<UnitGroupDimension>;
  /** Reads and enables pagination through a set of `UnitGroupDimension`. */
  unitGroupDimensions?: Maybe<UnitGroupDimensionsConnection>;
  /** Reads and enables pagination through a set of `UnitGroup`. */
  unitGroups?: Maybe<UnitGroupsConnection>;
  user?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  utilityAccount?: Maybe<UtilityAccount>;
  /** Reads a single `UtilityAccount` using its globally unique `ID`. */
  utilityAccountByNodeId?: Maybe<UtilityAccount>;
  utilityAccountStatement?: Maybe<UtilityAccountStatement>;
  /** Reads a single `UtilityAccountStatement` using its globally unique `ID`. */
  utilityAccountStatementByNodeId?: Maybe<UtilityAccountStatement>;
  utilityAccountStatementRollup?: Maybe<UtilityAccountStatementRollup>;
  /** Reads a single `UtilityAccountStatementRollup` using its globally unique `ID`. */
  utilityAccountStatementRollupByNodeId?: Maybe<UtilityAccountStatementRollup>;
  /** Reads and enables pagination through a set of `UtilityAccountStatementRollup`. */
  utilityAccountStatementRollups?: Maybe<UtilityAccountStatementRollupsConnection>;
  /** Reads and enables pagination through a set of `UtilityAccountStatement`. */
  utilityAccountStatements?: Maybe<UtilityAccountStatementsConnection>;
  /** Reads and enables pagination through a set of `UtilityAccount`. */
  utilityAccounts?: Maybe<UtilityAccountsConnection>;
  utilityMeter?: Maybe<UtilityMeter>;
  /** Reads a single `UtilityMeter` using its globally unique `ID`. */
  utilityMeterByNodeId?: Maybe<UtilityMeter>;
  utilityMeterStatement?: Maybe<UtilityMeterStatement>;
  /** Reads a single `UtilityMeterStatement` using its globally unique `ID`. */
  utilityMeterStatementByNodeId?: Maybe<UtilityMeterStatement>;
  utilityMeterStatementRollup?: Maybe<UtilityMeterStatementRollup>;
  /** Reads a single `UtilityMeterStatementRollup` using its globally unique `ID`. */
  utilityMeterStatementRollupByNodeId?: Maybe<UtilityMeterStatementRollup>;
  /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
  utilityMeterStatementRollups?: Maybe<UtilityMeterStatementRollupsConnection>;
  /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
  utilityMeterStatements?: Maybe<UtilityMeterStatementsConnection>;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMeters?: Maybe<UtilityMetersConnection>;
  utilityProvider?: Maybe<UtilityProvider>;
  utilityProviderByName?: Maybe<UtilityProvider>;
  /** Reads a single `UtilityProvider` using its globally unique `ID`. */
  utilityProviderByNodeId?: Maybe<UtilityProvider>;
  /** Reads and enables pagination through a set of `UtilityProvider`. */
  utilityProviders?: Maybe<UtilityProvidersConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountChargeArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountChargeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountChargeCondition>;
  filter?: InputMaybe<AccountChargeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountChargesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountStatementArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountStatementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountStatementHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountStatementHistoryCondition>;
  filter?: InputMaybe<AccountStatementHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStatementHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountStatementCondition>;
  filter?: InputMaybe<AccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBalancingAuthoritiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BalancingAuthorityCondition>;
  filter?: InputMaybe<BalancingAuthorityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BalancingAuthoritiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBalancingAuthorityArgs = {
  slug: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBalancingAuthorityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryComponentArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryComponentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryComponentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ComponentCondition>;
  filter?: InputMaybe<ComponentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventLogArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventLogCondition>;
  filter?: InputMaybe<ControlEventLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventStateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryControlEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventCondition>;
  filter?: InputMaybe<ControlEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDeriveStatementDateArgs = {
  intervalBegin: Scalars['Date'];
  intervalEnd: Scalars['Date'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['BigInt'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventHandlerArgs = {
  handler: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventHandlerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventHandlerMetadatumArgs = {
  handler: Scalars['String'];
  type: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventHandlerMetadatumByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventTypeArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEventTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityBySlugArgs = {
  slug: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupArgs = {
  id: Scalars['BigInt'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupCondition>;
  filter?: InputMaybe<FacilityGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupsLinkerArgs = {
  facilityGroupingId: Scalars['BigInt'];
  facilityId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupsLinkerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryFacilityGroupsLinkersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityGroupsLinkerCondition>;
  filter?: InputMaybe<FacilityGroupsLinkerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<JsonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGroupingsWithFacilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  facilityFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<JsonFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMachineUserArgs = {
  id: Scalars['BigInt'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMachineUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMachineUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MachineUserCondition>;
  filter?: InputMaybe<MachineUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterChargeArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterChargeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterChargeCondition>;
  filter?: InputMaybe<MeterChargeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterChargesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterStatementArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterStatementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterStatementHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterStatementHistoryCondition>;
  filter?: InputMaybe<MeterStatementHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterStatementHistoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterStatementPdfArgs = {
  id: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterStatementCondition>;
  filter?: InputMaybe<MeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterUsageArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterUsageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMeterUsagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterUsageCondition>;
  filter?: InputMaybe<MeterUsageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterUsagesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  aggregation?: InputMaybe<MetricDataAggregationMethod>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  sourceId: Scalars['String'];
  to?: InputMaybe<Scalars['String']>;
  window?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricDependenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MetricDependencyCondition>;
  filter?: InputMaybe<MetricDependencyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricDependencyArgs = {
  id: Scalars['BigInt'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricDependencyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricDependencyByPathArgs = {
  path: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricMetadataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MetricMetadatumCondition>;
  filter?: InputMaybe<MetricMetadatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MetricMetadataOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricMetadatumArgs = {
  label: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryMetricMetadatumByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPlatformAccessTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PlatformAccessTokenCondition>;
  filter?: InputMaybe<PlatformAccessTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PlatformAccessTokensOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectComponentArgs = {
  componentId: Scalars['Int'];
  projectId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectComponentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectTypeArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectTypeByNameArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectTypeCondition>;
  filter?: InputMaybe<ProjectTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectWatcherArgs = {
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectWatcherByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectWatchersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectWatcherCondition>;
  filter?: InputMaybe<ProjectWatcherFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySourceDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  aggregation?: InputMaybe<MetricDataAggregationMethod>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: MetricDataOrderBy;
  sourceId: Scalars['String'];
  to?: InputMaybe<Scalars['String']>;
  window?: InputMaybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionActivityLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionActivityLogCondition>;
  filter?: InputMaybe<SuggestionActivityLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionActivityLogsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionControlEventArgs = {
  controlEventId: Scalars['Int'];
  suggestionId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionControlEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySuggestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionCondition>;
  filter?: InputMaybe<SuggestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryTimezoneArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTimezoneByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTimezonesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TimezoneCondition>;
  filter?: InputMaybe<TimezoneFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimezonesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitDimensionArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitDimensionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitDimensionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitDimensionCondition>;
  filter?: InputMaybe<UnitDimensionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitDimensionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupDimensionArgs = {
  unitDimension: Scalars['String'];
  unitGroup: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupDimensionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupDimensionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitGroupDimensionCondition>;
  filter?: InputMaybe<UnitGroupDimensionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitGroupDimensionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUnitGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitGroupCondition>;
  filter?: InputMaybe<UnitGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitGroupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementArgs = {
  accountStatementId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementRollupArgs = {
  accountStatementId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementRollupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountStatementRollupCondition>;
  filter?: InputMaybe<UtilityAccountStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountStatementRollupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountStatementCondition>;
  filter?: InputMaybe<UtilityAccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountStatementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountCondition>;
  filter?: InputMaybe<UtilityAccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementArgs = {
  meterStatementId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementRollupArgs = {
  meterStatementId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementRollupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
  filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementCondition>;
  filter?: InputMaybe<UtilityMeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityMetersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterCondition>;
  filter?: InputMaybe<UtilityMeterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityProviderArgs = {
  id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityProviderByNameArgs = {
  name: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityProviderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryUtilityProvidersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityProviderCondition>;
  filter?: InputMaybe<UtilityProviderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityProvidersOrderBy>>;
};

export type RegenerateAccessTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['BigInt'];
};

/** All input for the `removeMetricMetadataDependency` mutation. */
export type RemoveMetricMetadataDependencyInput = {
  childMetric: Scalars['String'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  parentMetric: Scalars['String'];
};

/** The output of our `removeMetricMetadataDependency` mutation. */
export type RemoveMetricMetadataDependencyPayload = {
  __typename?: 'RemoveMetricMetadataDependencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  metricMetadata?: Maybe<Array<MetricMetadatum>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type RemoveProjectControllerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};

export type RemoveProjectControllerPayload = {
  __typename?: 'RemoveProjectControllerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project: Project;
  user: User;
};

/** All input for the `reparentMeterStatement` mutation. */
export type ReparentMeterStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  destAccountStatementId?: InputMaybe<Scalars['Int']>;
  destUtilityMeterId?: InputMaybe<Scalars['Int']>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
};

/** The output of our `reparentMeterStatement` mutation. */
export type ReparentMeterStatementPayload = {
  __typename?: 'ReparentMeterStatementPayload';
  /** Reads a single `AccountStatement` that is related to this `MeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  meterStatement?: Maybe<MeterStatement>;
  /** An edge for our `MeterStatement`. May be used by Relay 1. */
  meterStatementEdge?: Maybe<MeterStatementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityMeter` that is related to this `MeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
};

/** The output of our `reparentMeterStatement` mutation. */
export type ReparentMeterStatementPayloadMeterStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

export type RevokeAccessTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['BigInt'];
};

export type SourceLabel = Node & {
  __typename?: 'SourceLabel';
  /** IoT device field name */
  label: Scalars['String'];
  nodeId: Scalars['ID'];
  /** IoT device name */
  sourceId: Scalars['String'];
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A suggestion for the associated optimization project. */
export type Suggestion = Node & {
  __typename?: 'Suggestion';
  /** Returns the timestamp at which an acknowledgement cannot occur past. */
  acknowledgementCutoff?: Maybe<Scalars['Datetime']>;
  /** The current acknowledgement status on the suggestion. */
  acknowledgementStatus: Acknowledgement;
  availableAcknowledgements: Array<Acknowledgement>;
  /** Reads and enables pagination through a set of `ControlEvent`. */
  controlEvents: SuggestionControlEventsManyToManyConnection;
  /** Returns the control type of a suggestion. */
  controlType?: Maybe<ControlType>;
  /** The time the suggestion was created. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** The description of suggestions. */
  description?: Maybe<Scalars['String']>;
  /** The date in which the suggestion event ends. */
  endTime?: Maybe<Scalars['Datetime']>;
  /** The `Facility` associated with the suggestion. */
  facility?: Maybe<Facility>;
  /** Facility Id */
  facilityId?: Maybe<Scalars['Int']>;
  /** Facility Name */
  facilityName?: Maybe<Scalars['String']>;
  /** The identifier of the suggestions. */
  id: Scalars['Int'];
  isAcknowledged?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  /** Is current user is watching the suggestion’s project. */
  isWatching?: Maybe<Scalars['Boolean']>;
  isWithdrawn?: Maybe<Scalars['Boolean']>;
  metadata: Scalars['JSON'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Available permissions for the Suggestion. */
  permissions?: Maybe<SuggestionPermissions>;
  /** Reads a single `Project` that is related to this `Suggestion`. */
  project?: Maybe<Project>;
  /** The identifier of the project. */
  projectId: Scalars['Int'];
  /** Project Type */
  projectType?: Maybe<Scalars['String']>;
  /** The time in which the suggestion event begins. */
  startTime: Scalars['Datetime'];
  /** The current state of the suggestion. */
  state?: Maybe<SuggestionState>;
  /** Reads and enables pagination through a set of `SuggestionActivityLog`. */
  suggestionActivityLogs: SuggestionActivityLogsConnection;
  /** Reads and enables pagination through a set of `SuggestionControlEvent`. */
  suggestionControlEvents: SuggestionControlEventsConnection;
  /** The time the suggestion was updated. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** The timestamp when the suggestion was withdrawn. */
  withdrawnAt?: Maybe<Scalars['Datetime']>;
};

/** A suggestion for the associated optimization project. */
export type SuggestionControlEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ControlEventCondition>;
  filter?: InputMaybe<ControlEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** A suggestion for the associated optimization project. */
export type SuggestionSuggestionActivityLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionActivityLogCondition>;
  filter?: InputMaybe<SuggestionActivityLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionActivityLogsOrderBy>>;
};

/** A suggestion for the associated optimization project. */
export type SuggestionSuggestionControlEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SuggestionControlEventCondition>;
  filter?: InputMaybe<SuggestionControlEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
};

export type SuggestionActivityLog = {
  __typename?: 'SuggestionActivityLog';
  /** The identifier of the actor who triggered the activity. */
  actor?: Maybe<Actor>;
  /** The time the suggestion activity event occurred. */
  createdAt: Scalars['Datetime'];
  /** Metadata about the activity. */
  metadata: Scalars['JSON'];
  /** Reads a single `Suggestion` that is related to this `SuggestionActivityLog`. */
  suggestion?: Maybe<Suggestion>;
  /** The identifier of the suggestion. */
  suggestionId: Scalars['Int'];
  /** The type of activity. */
  type: SuggestionActivityType;
};

export type SuggestionActivityLogAggregates = {
  __typename?: 'SuggestionActivityLogAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SuggestionActivityLogAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<SuggestionActivityLogDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SuggestionActivityLogDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SuggestionActivityLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SuggestionActivityLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SuggestionActivityLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SuggestionActivityLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SuggestionActivityLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SuggestionActivityLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SuggestionActivityLogVarianceSampleAggregates>;
};

export type SuggestionActivityLogAverageAggregates = {
  __typename?: 'SuggestionActivityLogAverageAggregates';
  /** Mean average of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `SuggestionActivityLog` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SuggestionActivityLogCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<ActorInput>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `suggestionId` field. */
  suggestionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<SuggestionActivityType>;
};

export type SuggestionActivityLogDistinctAggregates = {
  __typename?: 'SuggestionActivityLogDistinctAggregates';
  /** Distinct of actor across the matching connection */
  actor?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['JSON']>;
  /** Distinct of type across the matching connection */
  type?: Maybe<Scalars['JSON']>;
};

export type SuggestionActivityLogDistinctCountAggregates = {
  __typename?: 'SuggestionActivityLogDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `SuggestionActivityLog` object types. All fields are combined with a logical ‘and.’ */
export type SuggestionActivityLogFilter = {
  /** Filter by the object’s `actor` field. */
  actor?: InputMaybe<ActorFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SuggestionActivityLogFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SuggestionActivityLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SuggestionActivityLogFilter>>;
  /** Filter by the object’s `suggestionId` field. */
  suggestionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<SuggestionActivityTypeFilter>;
};

/** An input for mutations affecting `SuggestionActivityLog` */
export type SuggestionActivityLogInput = {
  /** The identifier of the actor who triggered the activity. */
  actor?: InputMaybe<ActorInput>;
  /** The time the suggestion activity event occurred. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Metadata about the activity. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Mutates a `Suggestion` as part of the payload */
  suggestion?: InputMaybe<SuggestionInput>;
  /** The identifier of the suggestion. */
  suggestionId?: InputMaybe<Scalars['Int']>;
  /** The type of activity. */
  type: SuggestionActivityType;
};

export type SuggestionActivityLogMaxAggregates = {
  __typename?: 'SuggestionActivityLogMaxAggregates';
  /** Maximum of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['Int']>;
};

export type SuggestionActivityLogMinAggregates = {
  __typename?: 'SuggestionActivityLogMinAggregates';
  /** Minimum of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['Int']>;
};

export type SuggestionActivityLogStddevPopulationAggregates = {
  __typename?: 'SuggestionActivityLogStddevPopulationAggregates';
  /** Population standard deviation of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionActivityLogStddevSampleAggregates = {
  __typename?: 'SuggestionActivityLogStddevSampleAggregates';
  /** Sample standard deviation of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionActivityLogSumAggregates = {
  __typename?: 'SuggestionActivityLogSumAggregates';
  /** Sum of suggestionId across the matching connection */
  suggestionId: Scalars['BigInt'];
};

export type SuggestionActivityLogVariancePopulationAggregates = {
  __typename?: 'SuggestionActivityLogVariancePopulationAggregates';
  /** Population variance of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionActivityLogVarianceSampleAggregates = {
  __typename?: 'SuggestionActivityLogVarianceSampleAggregates';
  /** Sample variance of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `SuggestionActivityLog` values. */
export type SuggestionActivityLogsConnection = {
  __typename?: 'SuggestionActivityLogsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SuggestionActivityLogAggregates>;
  /** A list of edges which contains the `SuggestionActivityLog` and cursor to aid in pagination. */
  edges: Array<SuggestionActivityLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SuggestionActivityLogAggregates>>;
  /** A list of `SuggestionActivityLog` objects. */
  nodes: Array<SuggestionActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SuggestionActivityLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `SuggestionActivityLog` values. */
export type SuggestionActivityLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SuggestionActivityLogsGroupBy>;
  having?: InputMaybe<SuggestionActivityLogsHavingInput>;
};

/** A `SuggestionActivityLog` edge in the connection. */
export type SuggestionActivityLogsEdge = {
  __typename?: 'SuggestionActivityLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SuggestionActivityLog` at the end of the edge. */
  node: SuggestionActivityLog;
};

/** Grouping methods for `SuggestionActivityLog` for usage during aggregation. */
export enum SuggestionActivityLogsGroupBy {
  ACTOR = 'ACTOR',
  ACTOR_DISTINCT = 'ACTOR_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  SUGGESTION_ID = 'SUGGESTION_ID',
  SUGGESTION_ID_DISTINCT = 'SUGGESTION_ID_DISTINCT',
  TYPE = 'TYPE',
  TYPE_DISTINCT = 'TYPE_DISTINCT'
}

export type SuggestionActivityLogsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `SuggestionActivityLog` aggregates. */
export type SuggestionActivityLogsHavingInput = {
  AND?: InputMaybe<Array<SuggestionActivityLogsHavingInput>>;
  OR?: InputMaybe<Array<SuggestionActivityLogsHavingInput>>;
  average?: InputMaybe<SuggestionActivityLogsHavingAverageInput>;
  distinct?: InputMaybe<SuggestionActivityLogsHavingDistinctInput>;
  distinctCount?: InputMaybe<SuggestionActivityLogsHavingDistinctCountInput>;
  max?: InputMaybe<SuggestionActivityLogsHavingMaxInput>;
  min?: InputMaybe<SuggestionActivityLogsHavingMinInput>;
  stddevPopulation?: InputMaybe<SuggestionActivityLogsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SuggestionActivityLogsHavingStddevSampleInput>;
  sum?: InputMaybe<SuggestionActivityLogsHavingSumInput>;
  variancePopulation?: InputMaybe<SuggestionActivityLogsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SuggestionActivityLogsHavingVarianceSampleInput>;
};

export type SuggestionActivityLogsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionActivityLogsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `SuggestionActivityLog`. */
export enum SuggestionActivityLogsOrderBy {
  ACTOR_ASC = 'ACTOR_ASC',
  ACTOR_DESC = 'ACTOR_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  NATURAL = 'NATURAL',
  SUGGESTION_ID_ASC = 'SUGGESTION_ID_ASC',
  SUGGESTION_ID_DESC = 'SUGGESTION_ID_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC'
}

export enum SuggestionActivityType {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED',
  WITHDRAWN = 'WITHDRAWN'
}

/** A filter to be used against SuggestionActivityType fields. All fields are combined with a logical ‘and.’ */
export type SuggestionActivityTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SuggestionActivityType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SuggestionActivityType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SuggestionActivityType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SuggestionActivityType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SuggestionActivityType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SuggestionActivityType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SuggestionActivityType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SuggestionActivityType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SuggestionActivityType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SuggestionActivityType>>;
};

export type SuggestionAggregates = {
  __typename?: 'SuggestionAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SuggestionAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<SuggestionDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SuggestionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SuggestionMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SuggestionMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SuggestionStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SuggestionStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SuggestionSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SuggestionVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SuggestionVarianceSampleAggregates>;
};

export type SuggestionAverageAggregates = {
  __typename?: 'SuggestionAverageAggregates';
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Suggestion` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SuggestionCondition = {
  /** Checks for equality with the object’s `acknowledgementStatus` field. */
  acknowledgementStatus?: InputMaybe<Acknowledgement>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `withdrawnAt` field. */
  withdrawnAt?: InputMaybe<Scalars['Datetime']>;
};

export type SuggestionControlEvent = Node & {
  __typename?: 'SuggestionControlEvent';
  /** Reads a single `ControlEvent` that is related to this `SuggestionControlEvent`. */
  controlEvent?: Maybe<ControlEvent>;
  controlEventId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Suggestion` that is related to this `SuggestionControlEvent`. */
  suggestion?: Maybe<Suggestion>;
  suggestionId: Scalars['Int'];
};

export type SuggestionControlEventAggregates = {
  __typename?: 'SuggestionControlEventAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SuggestionControlEventAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<SuggestionControlEventDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SuggestionControlEventDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SuggestionControlEventMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SuggestionControlEventMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SuggestionControlEventStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SuggestionControlEventStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SuggestionControlEventSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SuggestionControlEventVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SuggestionControlEventVarianceSampleAggregates>;
};

export type SuggestionControlEventAverageAggregates = {
  __typename?: 'SuggestionControlEventAverageAggregates';
  /** Mean average of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `SuggestionControlEvent` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SuggestionControlEventCondition = {
  /** Checks for equality with the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `suggestionId` field. */
  suggestionId?: InputMaybe<Scalars['Int']>;
};

export type SuggestionControlEventDistinctAggregates = {
  __typename?: 'SuggestionControlEventDistinctAggregates';
  /** Distinct of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['JSON']>;
  /** Distinct of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['JSON']>;
};

export type SuggestionControlEventDistinctCountAggregates = {
  __typename?: 'SuggestionControlEventDistinctCountAggregates';
  /** Distinct count of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `SuggestionControlEvent` object types. All fields are combined with a logical ‘and.’ */
export type SuggestionControlEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SuggestionControlEventFilter>>;
  /** Filter by the object’s `controlEventId` field. */
  controlEventId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SuggestionControlEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SuggestionControlEventFilter>>;
  /** Filter by the object’s `suggestionId` field. */
  suggestionId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SuggestionControlEvent` */
export type SuggestionControlEventInput = {
  /** Mutates a `ControlEvent` as part of the payload */
  controlEvent?: InputMaybe<ControlEventInput>;
  controlEventId?: InputMaybe<Scalars['Int']>;
  suggestionId: Scalars['Int'];
};

export type SuggestionControlEventMaxAggregates = {
  __typename?: 'SuggestionControlEventMaxAggregates';
  /** Maximum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Maximum of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['Int']>;
};

export type SuggestionControlEventMinAggregates = {
  __typename?: 'SuggestionControlEventMinAggregates';
  /** Minimum of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['Int']>;
  /** Minimum of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `SuggestionControlEvent`. Fields that are set will be updated. */
export type SuggestionControlEventPatch = {
  controlEventId?: InputMaybe<Scalars['Int']>;
  suggestionId?: InputMaybe<Scalars['Int']>;
};

export type SuggestionControlEventStddevPopulationAggregates = {
  __typename?: 'SuggestionControlEventStddevPopulationAggregates';
  /** Population standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionControlEventStddevSampleAggregates = {
  __typename?: 'SuggestionControlEventStddevSampleAggregates';
  /** Sample standard deviation of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionControlEventSumAggregates = {
  __typename?: 'SuggestionControlEventSumAggregates';
  /** Sum of controlEventId across the matching connection */
  controlEventId: Scalars['BigInt'];
  /** Sum of suggestionId across the matching connection */
  suggestionId: Scalars['BigInt'];
};

export type SuggestionControlEventVariancePopulationAggregates = {
  __typename?: 'SuggestionControlEventVariancePopulationAggregates';
  /** Population variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionControlEventVarianceSampleAggregates = {
  __typename?: 'SuggestionControlEventVarianceSampleAggregates';
  /** Sample variance of controlEventId across the matching connection */
  controlEventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of suggestionId across the matching connection */
  suggestionId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `SuggestionControlEvent` values. */
export type SuggestionControlEventsConnection = {
  __typename?: 'SuggestionControlEventsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SuggestionControlEventAggregates>;
  /** A list of edges which contains the `SuggestionControlEvent` and cursor to aid in pagination. */
  edges: Array<SuggestionControlEventsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SuggestionControlEventAggregates>>;
  /** A list of `SuggestionControlEvent` objects. */
  nodes: Array<SuggestionControlEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SuggestionControlEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `SuggestionControlEvent` values. */
export type SuggestionControlEventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SuggestionControlEventsGroupBy>;
  having?: InputMaybe<SuggestionControlEventsHavingInput>;
};

/** A `SuggestionControlEvent` edge in the connection. */
export type SuggestionControlEventsEdge = {
  __typename?: 'SuggestionControlEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SuggestionControlEvent` at the end of the edge. */
  node: SuggestionControlEvent;
};

/** Grouping methods for `SuggestionControlEvent` for usage during aggregation. */
export enum SuggestionControlEventsGroupBy {
  CONTROL_EVENT_ID = 'CONTROL_EVENT_ID',
  CONTROL_EVENT_ID_DISTINCT = 'CONTROL_EVENT_ID_DISTINCT',
  SUGGESTION_ID = 'SUGGESTION_ID',
  SUGGESTION_ID_DISTINCT = 'SUGGESTION_ID_DISTINCT'
}

export type SuggestionControlEventsHavingAverageInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingDistinctCountInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingDistinctInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `SuggestionControlEvent` aggregates. */
export type SuggestionControlEventsHavingInput = {
  AND?: InputMaybe<Array<SuggestionControlEventsHavingInput>>;
  OR?: InputMaybe<Array<SuggestionControlEventsHavingInput>>;
  average?: InputMaybe<SuggestionControlEventsHavingAverageInput>;
  distinct?: InputMaybe<SuggestionControlEventsHavingDistinctInput>;
  distinctCount?: InputMaybe<SuggestionControlEventsHavingDistinctCountInput>;
  max?: InputMaybe<SuggestionControlEventsHavingMaxInput>;
  min?: InputMaybe<SuggestionControlEventsHavingMinInput>;
  stddevPopulation?: InputMaybe<SuggestionControlEventsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SuggestionControlEventsHavingStddevSampleInput>;
  sum?: InputMaybe<SuggestionControlEventsHavingSumInput>;
  variancePopulation?: InputMaybe<SuggestionControlEventsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SuggestionControlEventsHavingVarianceSampleInput>;
};

export type SuggestionControlEventsHavingMaxInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingMinInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingStddevPopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingStddevSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingSumInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingVariancePopulationInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

export type SuggestionControlEventsHavingVarianceSampleInput = {
  controlEventId?: InputMaybe<HavingIntFilter>;
  suggestionId?: InputMaybe<HavingIntFilter>;
};

/** A connection to a list of `ControlEvent` values, with data from `SuggestionControlEvent`. */
export type SuggestionControlEventsManyToManyConnection = {
  __typename?: 'SuggestionControlEventsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ControlEventAggregates>;
  /** A list of edges which contains the `ControlEvent`, info from the `SuggestionControlEvent`, and the cursor to aid in pagination. */
  edges: Array<SuggestionControlEventsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ControlEventAggregates>>;
  /** A list of `ControlEvent` objects. */
  nodes: Array<ControlEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ControlEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ControlEvent` values, with data from `SuggestionControlEvent`. */
export type SuggestionControlEventsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ControlEventsGroupBy>;
  having?: InputMaybe<ControlEventsHavingInput>;
};

/** A `ControlEvent` edge in the connection, with data from `SuggestionControlEvent`. */
export type SuggestionControlEventsManyToManyEdge = {
  __typename?: 'SuggestionControlEventsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ControlEvent` at the end of the edge. */
  node: ControlEvent;
};

/** Methods to use when ordering `SuggestionControlEvent`. */
export enum SuggestionControlEventsOrderBy {
  CONTROL_EVENT_ID_ASC = 'CONTROL_EVENT_ID_ASC',
  CONTROL_EVENT_ID_DESC = 'CONTROL_EVENT_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SUGGESTION_ID_ASC = 'SUGGESTION_ID_ASC',
  SUGGESTION_ID_DESC = 'SUGGESTION_ID_DESC'
}

export type SuggestionDistinctAggregates = {
  __typename?: 'SuggestionDistinctAggregates';
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the timestamp at which an acknowledgement cannot occur past.
   */
  acknowledgementCutoff?: Maybe<Scalars['JSON']>;
  /** Distinct of acknowledgementStatus across the matching connection */
  acknowledgementStatus?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the control type of a suggestion.
   */
  controlType?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of description across the matching connection */
  description?: Maybe<Scalars['JSON']>;
  /** Distinct of endTime across the matching connection */
  endTime?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The `Facility` associated with the suggestion.
   */
  facility?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Facility Name
   */
  facilityName?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  isAcknowledged?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  isRejected?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Is current user is watching the suggestion’s project.
   */
  isWatching?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  isWithdrawn?: Maybe<Scalars['JSON']>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of projectId across the matching connection */
  projectId?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Project Type
   */
  projectType?: Maybe<Scalars['JSON']>;
  /** Distinct of startTime across the matching connection */
  startTime?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The current state of the suggestion.
   */
  state?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of withdrawnAt across the matching connection */
  withdrawnAt?: Maybe<Scalars['JSON']>;
};

export type SuggestionDistinctCountAggregates = {
  __typename?: 'SuggestionDistinctCountAggregates';
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the timestamp at which an acknowledgement cannot occur past.
   */
  acknowledgementCutoff?: Maybe<Scalars['BigInt']>;
  /** Distinct count of acknowledgementStatus across the matching connection */
  acknowledgementStatus?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the control type of a suggestion.
   */
  controlType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endTime across the matching connection */
  endTime?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The `Facility` associated with the suggestion.
   */
  facility?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Facility Name
   */
  facilityName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  isAcknowledged?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  isRejected?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Is current user is watching the suggestion’s project.
   */
  isWatching?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  isWithdrawn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Project Type
   */
  projectType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startTime across the matching connection */
  startTime?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The current state of the suggestion.
   */
  state?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of withdrawnAt across the matching connection */
  withdrawnAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Suggestion` object types. All fields are combined with a logical ‘and.’ */
export type SuggestionFilter = {
  /** Filter by the object’s `acknowledgementCutoff` field. */
  acknowledgementCutoff?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `acknowledgementStatus` field. */
  acknowledgementStatus?: InputMaybe<AcknowledgementFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SuggestionFilter>>;
  /** Filter by the permission can_control */
  canControl?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `controlType` field. */
  controlType?: InputMaybe<ControlTypeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `facilityName` field. */
  facilityName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isAcknowledged` field. */
  isAcknowledged?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isRejected` field. */
  isRejected?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isWatching` field. */
  isWatching?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isWithdrawn` field. */
  isWithdrawn?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SuggestionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SuggestionFilter>>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectType` field. */
  projectType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<SuggestionStateFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `withdrawnAt` field. */
  withdrawnAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Suggestion` */
export type SuggestionInput = {
  /** The description of suggestions. */
  description?: InputMaybe<Scalars['String']>;
  /** The date in which the suggestion event ends. */
  endTime?: InputMaybe<Scalars['Datetime']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Mutates a `Project` as part of the payload */
  project?: InputMaybe<ProjectInput>;
  /** The identifier of the project. */
  projectId?: InputMaybe<Scalars['Int']>;
  /** The time in which the suggestion event begins. */
  startTime: Scalars['Datetime'];
};

export type SuggestionMaxAggregates = {
  __typename?: 'SuggestionMaxAggregates';
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of projectId across the matching connection */
  projectId?: Maybe<Scalars['Int']>;
};

export type SuggestionMinAggregates = {
  __typename?: 'SuggestionMinAggregates';
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of projectId across the matching connection */
  projectId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Suggestion`. Fields that are set will be updated. */
export type SuggestionPatch = {
  /** The description of suggestions. */
  description?: InputMaybe<Scalars['String']>;
  /** The date in which the suggestion event ends. */
  endTime?: InputMaybe<Scalars['Datetime']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** The identifier of the project. */
  projectId?: InputMaybe<Scalars['Int']>;
  /** The time in which the suggestion event begins. */
  startTime?: InputMaybe<Scalars['Datetime']>;
};

/** Permissive capabilities on a Suggestion. */
export type SuggestionPermissions = {
  __typename?: 'SuggestionPermissions';
  /** Whether a user can approve and/or transition the suggestion. */
  canControl: Scalars['Boolean'];
};

export enum SuggestionState {
  ACTIVE = 'ACTIVE',
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
  WITHDRAWN = 'WITHDRAWN'
}

/** A filter to be used against SuggestionState fields. All fields are combined with a logical ‘and.’ */
export type SuggestionStateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SuggestionState>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SuggestionState>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SuggestionState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SuggestionState>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SuggestionState>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SuggestionState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SuggestionState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SuggestionState>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SuggestionState>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SuggestionState>>;
};

export type SuggestionStddevPopulationAggregates = {
  __typename?: 'SuggestionStddevPopulationAggregates';
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionStddevSampleAggregates = {
  __typename?: 'SuggestionStddevSampleAggregates';
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionSumAggregates = {
  __typename?: 'SuggestionSumAggregates';
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt'];
};

export type SuggestionVariancePopulationAggregates = {
  __typename?: 'SuggestionVariancePopulationAggregates';
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

export type SuggestionVarianceSampleAggregates = {
  __typename?: 'SuggestionVarianceSampleAggregates';
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Facility Id
   */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of projectId across the matching connection */
  projectId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Suggestion` values. */
export type SuggestionsConnection = {
  __typename?: 'SuggestionsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SuggestionAggregates>;
  /** A list of edges which contains the `Suggestion` and cursor to aid in pagination. */
  edges: Array<SuggestionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SuggestionAggregates>>;
  /** A list of `Suggestion` objects. */
  nodes: Array<Suggestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Suggestion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Suggestion` values. */
export type SuggestionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SuggestionsGroupBy>;
  having?: InputMaybe<SuggestionsHavingInput>;
};

/** A `Suggestion` edge in the connection. */
export type SuggestionsEdge = {
  __typename?: 'SuggestionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Suggestion` at the end of the edge. */
  node: Suggestion;
};

/** Grouping methods for `Suggestion` for usage during aggregation. */
export enum SuggestionsGroupBy {
  ACKNOWLEDGEMENT_STATUS = 'ACKNOWLEDGEMENT_STATUS',
  ACKNOWLEDGEMENT_STATUS_DISTINCT = 'ACKNOWLEDGEMENT_STATUS_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DESCRIPTION = 'DESCRIPTION',
  DESCRIPTION_DISTINCT = 'DESCRIPTION_DISTINCT',
  END_TIME = 'END_TIME',
  END_TIME_DISTINCT = 'END_TIME_DISTINCT',
  END_TIME_TRUNCATED_TO_DAY = 'END_TIME_TRUNCATED_TO_DAY',
  END_TIME_TRUNCATED_TO_HOUR = 'END_TIME_TRUNCATED_TO_HOUR',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  PROJECT_ID = 'PROJECT_ID',
  PROJECT_ID_DISTINCT = 'PROJECT_ID_DISTINCT',
  START_TIME = 'START_TIME',
  START_TIME_DISTINCT = 'START_TIME_DISTINCT',
  START_TIME_TRUNCATED_TO_DAY = 'START_TIME_TRUNCATED_TO_DAY',
  START_TIME_TRUNCATED_TO_HOUR = 'START_TIME_TRUNCATED_TO_HOUR',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  WITHDRAWN_AT = 'WITHDRAWN_AT',
  WITHDRAWN_AT_DISTINCT = 'WITHDRAWN_AT_DISTINCT',
  WITHDRAWN_AT_TRUNCATED_TO_DAY = 'WITHDRAWN_AT_TRUNCATED_TO_DAY',
  WITHDRAWN_AT_TRUNCATED_TO_HOUR = 'WITHDRAWN_AT_TRUNCATED_TO_HOUR'
}

export type SuggestionsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingAverageSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingAverageSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingDistinctCountInput = {
  acknowledgementCutoff?: InputMaybe<SuggestionsHavingDistinctCountSuggestionsAcknowledgementCutoffInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingDistinctCountSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingDistinctCountSuggestionsAcknowledgementCutoffInput =
  {
    filter?: InputMaybe<HavingDatetimeFilter>;
  };

export type SuggestionsHavingDistinctCountSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingDistinctInput = {
  acknowledgementCutoff?: InputMaybe<SuggestionsHavingDistinctSuggestionsAcknowledgementCutoffInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingDistinctSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingDistinctSuggestionsAcknowledgementCutoffInput = {
  filter?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingDistinctSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Suggestion` aggregates. */
export type SuggestionsHavingInput = {
  AND?: InputMaybe<Array<SuggestionsHavingInput>>;
  OR?: InputMaybe<Array<SuggestionsHavingInput>>;
  average?: InputMaybe<SuggestionsHavingAverageInput>;
  distinct?: InputMaybe<SuggestionsHavingDistinctInput>;
  distinctCount?: InputMaybe<SuggestionsHavingDistinctCountInput>;
  max?: InputMaybe<SuggestionsHavingMaxInput>;
  min?: InputMaybe<SuggestionsHavingMinInput>;
  stddevPopulation?: InputMaybe<SuggestionsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SuggestionsHavingStddevSampleInput>;
  sum?: InputMaybe<SuggestionsHavingSumInput>;
  variancePopulation?: InputMaybe<SuggestionsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SuggestionsHavingVarianceSampleInput>;
};

export type SuggestionsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingMaxSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingMaxSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingMinSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingMinSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingStddevPopulationSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingStddevPopulationSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingStddevSampleSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingStddevSampleSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingSumSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingSumSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingVariancePopulationSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingVariancePopulationSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

export type SuggestionsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<SuggestionsHavingVarianceSampleSuggestionsFacilityIdInput>;
  id?: InputMaybe<HavingIntFilter>;
  projectId?: InputMaybe<HavingIntFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  withdrawnAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SuggestionsHavingVarianceSampleSuggestionsFacilityIdInput = {
  filter?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `Suggestion`. */
export enum SuggestionsOrderBy {
  ACKNOWLEDGEMENT_CUTOFF_ASC = 'ACKNOWLEDGEMENT_CUTOFF_ASC',
  ACKNOWLEDGEMENT_CUTOFF_DESC = 'ACKNOWLEDGEMENT_CUTOFF_DESC',
  ACKNOWLEDGEMENT_STATUS_ASC = 'ACKNOWLEDGEMENT_STATUS_ASC',
  ACKNOWLEDGEMENT_STATUS_DESC = 'ACKNOWLEDGEMENT_STATUS_DESC',
  CONTROL_TYPE_ASC = 'CONTROL_TYPE_ASC',
  CONTROL_TYPE_DESC = 'CONTROL_TYPE_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DESCRIPTION_ASC = 'DESCRIPTION_ASC',
  DESCRIPTION_DESC = 'DESCRIPTION_DESC',
  END_TIME_ASC = 'END_TIME_ASC',
  END_TIME_DESC = 'END_TIME_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  FACILITY_NAME_ASC = 'FACILITY_NAME_ASC',
  FACILITY_NAME_DESC = 'FACILITY_NAME_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  IS_WATCHING_ASC = 'IS_WATCHING_ASC',
  IS_WATCHING_DESC = 'IS_WATCHING_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECT_ID_ASC = 'PROJECT_ID_ASC',
  PROJECT_ID_DESC = 'PROJECT_ID_DESC',
  PROJECT_TYPE_ASC = 'PROJECT_TYPE_ASC',
  PROJECT_TYPE_DESC = 'PROJECT_TYPE_DESC',
  RELEVANCY_ASC = 'RELEVANCY_ASC',
  RELEVANCY_DESC = 'RELEVANCY_DESC',
  START_TIME_ASC = 'START_TIME_ASC',
  START_TIME_DESC = 'START_TIME_DESC',
  STATE_ASC = 'STATE_ASC',
  STATE_DESC = 'STATE_DESC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_AVERAGE_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_AVERAGE_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_COUNT_ASC = 'SUGGESTION_ACTIVITY_LOGS_COUNT_ASC',
  SUGGESTION_ACTIVITY_LOGS_COUNT_DESC = 'SUGGESTION_ACTIVITY_LOGS_COUNT_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_COUNT_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_DISTINCT_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_DISTINCT_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_MAX_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_MAX_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_MAX_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_MAX_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_MAX_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_MAX_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_MAX_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_MAX_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_MAX_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_MAX_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_MAX_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_MAX_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_MAX_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_MAX_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_MAX_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_MAX_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_MAX_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_MAX_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_MAX_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_MAX_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_MIN_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_MIN_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_MIN_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_MIN_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_MIN_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_MIN_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_MIN_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_MIN_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_MIN_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_MIN_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_MIN_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_MIN_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_MIN_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_MIN_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_MIN_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_MIN_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_MIN_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_MIN_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_MIN_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_MIN_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_POPULATION_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_STDDEV_SAMPLE_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_SUM_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_SUM_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_SUM_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_SUM_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_SUM_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_SUM_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_SUM_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_SUM_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_SUM_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_SUM_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_SUM_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_SUM_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_SUM_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_SUM_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_SUM_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_SUM_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_SUM_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_SUM_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_SUM_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_SUM_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_POPULATION_TYPE_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_ACTOR_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_METADATA_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_ASC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_ASC',
  SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_DESC = 'SUGGESTION_ACTIVITY_LOGS_VARIANCE_SAMPLE_TYPE_DESC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_AVERAGE_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_COUNT_ASC = 'SUGGESTION_CONTROL_EVENTS_COUNT_ASC',
  SUGGESTION_CONTROL_EVENTS_COUNT_DESC = 'SUGGESTION_CONTROL_EVENTS_COUNT_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_COUNT_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_DISTINCT_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MAX_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MAX_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MIN_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_MIN_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_STDDEV_SAMPLE_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_SUM_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_SUM_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_POPULATION_SUGGESTION_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_CONTROL_EVENT_ID_DESC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_ASC',
  SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC = 'SUGGESTION_CONTROL_EVENTS_VARIANCE_SAMPLE_SUGGESTION_ID_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  WITHDRAWN_AT_ASC = 'WITHDRAWN_AT_ASC',
  WITHDRAWN_AT_DESC = 'WITHDRAWN_AT_DESC'
}

/** Represents a timezone type. */
export type Timezone = Node & {
  __typename?: 'Timezone';
  /** The abbreviation of the timezone. */
  abbreviation?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByTimezoneName: FacilitiesConnection;
  /** Whether the timezone follows daylight savings time (DST). */
  isDst?: Maybe<Scalars['Boolean']>;
  /** The name of the timezone. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `UnitGroup`. */
  unitGroupsByFacilityTimezoneNameAndUnitGroup: TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyConnection;
  /** The offset of the timezone as an interval. */
  utcOffset?: Maybe<Interval>;
};

/** Represents a timezone type. */
export type TimezoneFacilitiesByTimezoneNameArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** Represents a timezone type. */
export type TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitGroupCondition>;
  filter?: InputMaybe<UnitGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitGroupsOrderBy>>;
};

export type TimezoneAggregates = {
  __typename?: 'TimezoneAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<TimezoneDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TimezoneDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `Timezone` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TimezoneCondition = {
  /** Checks for equality with the object’s `abbreviation` field. */
  abbreviation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `isDst` field. */
  isDst?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `utcOffset` field. */
  utcOffset?: InputMaybe<IntervalInput>;
};

export type TimezoneDistinctAggregates = {
  __typename?: 'TimezoneDistinctAggregates';
  /** Distinct of abbreviation across the matching connection */
  abbreviation?: Maybe<Scalars['JSON']>;
  /** Distinct of isDst across the matching connection */
  isDst?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
  /** Distinct of utcOffset across the matching connection */
  utcOffset?: Maybe<Scalars['JSON']>;
};

export type TimezoneDistinctCountAggregates = {
  __typename?: 'TimezoneDistinctCountAggregates';
  /** Distinct count of abbreviation across the matching connection */
  abbreviation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isDst across the matching connection */
  isDst?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utcOffset across the matching connection */
  utcOffset?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Timezone` object types. All fields are combined with a logical ‘and.’ */
export type TimezoneFilter = {
  /** Filter by the object’s `abbreviation` field. */
  abbreviation?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TimezoneFilter>>;
  /** Filter by the object’s `isDst` field. */
  isDst?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TimezoneFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TimezoneFilter>>;
  /** Filter by the object’s `utcOffset` field. */
  utcOffset?: InputMaybe<IntervalFilter>;
};

/** A connection to a list of `UnitGroup` values, with data from `Facility`. */
export type TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyConnection =
  {
    __typename?: 'TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UnitGroupAggregates>;
    /** A list of edges which contains the `UnitGroup`, info from the `Facility`, and the cursor to aid in pagination. */
    edges: Array<TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyEdge>;
    /** A list of `UnitGroup` objects. */
    nodes: Array<UnitGroup>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UnitGroup` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A `UnitGroup` edge in the connection, with data from `Facility`. */
export type TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyEdge =
  {
    __typename?: 'TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** Reads and enables pagination through a set of `Facility`. */
    facilitiesByUnitGroup: FacilitiesConnection;
    /** The `UnitGroup` at the end of the edge. */
    node: UnitGroup;
  };

/** A `UnitGroup` edge in the connection, with data from `Facility`. */
export type TimezoneUnitGroupsByFacilityTimezoneNameAndUnitGroupManyToManyEdgeFacilitiesByUnitGroupArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

/** A connection to a list of `Timezone` values. */
export type TimezonesConnection = {
  __typename?: 'TimezonesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TimezoneAggregates>;
  /** A list of edges which contains the `Timezone` and cursor to aid in pagination. */
  edges: Array<TimezonesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TimezoneAggregates>>;
  /** A list of `Timezone` objects. */
  nodes: Array<Timezone>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Timezone` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Timezone` values. */
export type TimezonesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TimezonesGroupBy>;
  having?: InputMaybe<TimezonesHavingInput>;
};

/** A `Timezone` edge in the connection. */
export type TimezonesEdge = {
  __typename?: 'TimezonesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Timezone` at the end of the edge. */
  node: Timezone;
};

/** Grouping methods for `Timezone` for usage during aggregation. */
export enum TimezonesGroupBy {
  ABBREVIATION = 'ABBREVIATION',
  ABBREVIATION_DISTINCT = 'ABBREVIATION_DISTINCT',
  IS_DST = 'IS_DST',
  IS_DST_DISTINCT = 'IS_DST_DISTINCT',
  UTC_OFFSET = 'UTC_OFFSET',
  UTC_OFFSET_DISTINCT = 'UTC_OFFSET_DISTINCT'
}

/** Conditions for `Timezone` aggregates. */
export type TimezonesHavingInput = {
  AND?: InputMaybe<Array<TimezonesHavingInput>>;
  OR?: InputMaybe<Array<TimezonesHavingInput>>;
};

/** Methods to use when ordering `Timezone`. */
export enum TimezonesOrderBy {
  ABBREVIATION_ASC = 'ABBREVIATION_ASC',
  ABBREVIATION_DESC = 'ABBREVIATION_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_AVERAGE_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_COUNT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_COUNT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_COUNT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_COUNT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_COUNT_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_DISTINCT_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MAX_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MAX_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_MIN_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_MIN_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_POPULATION_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_STDDEV_SAMPLE_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_SUM_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_SUM_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_POPULATION_ZIP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ADDRESS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ADDRESS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ADDRESS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ADDRESS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_BONUS_LTR_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_BONUS_LTR_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_BONUS_LTR_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_BONUS_LTR_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CITY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CITY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CITY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CITY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CONFIG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CONFIG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CONFIG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CONFIG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_COUNTRY_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_COUNTRY_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_COUNTRY_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_COUNTRY_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CREATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CREATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ID_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ID_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ID_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ID_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LATITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LATITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LATITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LATITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LINK_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LINK_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LINK_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LINK_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LONGITUDE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LONGITUDE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LONGITUDE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_LONGITUDE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NOTE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NOTE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NOTE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_NOTE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SLUG_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SLUG_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SLUG_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SLUG_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_STATE_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_STATE_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_STATE_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_STATE_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UNIT_GROUP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UNIT_GROUP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UNIT_GROUP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UNIT_GROUP_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ZIP_ASC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ZIP_ASC',
  FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ZIP_DESC = 'FACILITIES_BY_TIMEZONE_NAME_VARIANCE_SAMPLE_ZIP_DESC',
  IS_DST_ASC = 'IS_DST_ASC',
  IS_DST_DESC = 'IS_DST_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UTC_OFFSET_ASC = 'UTC_OFFSET_ASC',
  UTC_OFFSET_DESC = 'UTC_OFFSET_DESC'
}

export type TransitionControlEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  controlEventId: Scalars['Int'];
  state: ControlEventStateType;
};

/** A unit dimension that identifies the base of a unit. */
export type UnitDimension = Node & {
  __typename?: 'UnitDimension';
  /** Returns a distinct list of units that are in use across facilities. */
  activeMappings?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The name of a unit dimension. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `UnitGroupDimension`. */
  unitGroupDimensionsByUnitDimension: UnitGroupDimensionsConnection;
  /** Reads and enables pagination through a set of `UnitGroup`. */
  unitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroup: UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyConnection;
};

/** A unit dimension that identifies the base of a unit. */
export type UnitDimensionUnitGroupDimensionsByUnitDimensionArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitGroupDimensionCondition>;
  filter?: InputMaybe<UnitGroupDimensionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitGroupDimensionsOrderBy>>;
};

/** A unit dimension that identifies the base of a unit. */
export type UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UnitGroupCondition>;
    filter?: InputMaybe<UnitGroupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UnitGroupsOrderBy>>;
  };

export type UnitDimensionAggregates = {
  __typename?: 'UnitDimensionAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UnitDimensionDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UnitDimensionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `UnitDimension` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UnitDimensionCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

export type UnitDimensionDistinctAggregates = {
  __typename?: 'UnitDimensionDistinctAggregates';
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns a distinct list of units that are in use across facilities.
   */
  activeMappings?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
};

export type UnitDimensionDistinctCountAggregates = {
  __typename?: 'UnitDimensionDistinctCountAggregates';
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns a distinct list of units that are in use across facilities.
   */
  activeMappings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UnitDimension` object types. All fields are combined with a logical ‘and.’ */
export type UnitDimensionFilter = {
  /** Filter by the object’s `activeMappings` field. */
  activeMappings?: InputMaybe<StringListFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnitDimensionFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UnitDimensionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnitDimensionFilter>>;
};

/** A connection to a list of `UnitGroup` values, with data from `UnitGroupDimension`. */
export type UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyConnection =
  {
    __typename?: 'UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UnitGroupAggregates>;
    /** A list of edges which contains the `UnitGroup`, info from the `UnitGroupDimension`, and the cursor to aid in pagination. */
    edges: Array<UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyEdge>;
    /** A list of `UnitGroup` objects. */
    nodes: Array<UnitGroup>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UnitGroup` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A `UnitGroup` edge in the connection, with data from `UnitGroupDimension`. */
export type UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyEdge =
  {
    __typename?: 'UnitDimensionUnitGroupsByUnitGroupDimensionUnitDimensionAndUnitGroupManyToManyEdge';
    /** The time the unit group dimension was created. */
    createdAt: Scalars['Datetime'];
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UnitGroup` at the end of the edge. */
    node: UnitGroup;
    /** The value of the unit for the dimension. */
    unit: Scalars['String'];
    /** The time the unit group dimension was updated. */
    updatedAt: Scalars['Datetime'];
  };

/** A connection to a list of `UnitDimension` values. */
export type UnitDimensionsConnection = {
  __typename?: 'UnitDimensionsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UnitDimensionAggregates>;
  /** A list of edges which contains the `UnitDimension` and cursor to aid in pagination. */
  edges: Array<UnitDimensionsEdge>;
  /** A list of `UnitDimension` objects. */
  nodes: Array<UnitDimension>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnitDimension` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UnitDimension` edge in the connection. */
export type UnitDimensionsEdge = {
  __typename?: 'UnitDimensionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UnitDimension` at the end of the edge. */
  node: UnitDimension;
};

/** Methods to use when ordering `UnitDimension`. */
export enum UnitDimensionsOrderBy {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_AVERAGE_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_COUNT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_COUNT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_COUNT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_COUNT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_COUNT_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_DISTINCT_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MAX_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_MIN_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_POPULATION_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_SUM_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_DIMENSION_VARIANCE_SAMPLE_UPDATED_AT_DESC'
}

/** A grouping used to collect values for a set of unit dimensions. */
export type UnitGroup = Node & {
  __typename?: 'UnitGroup';
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUnitGroup: FacilitiesConnection;
  /** The name of a unique unit group. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Timezone`. */
  timezonesByFacilityUnitGroupAndTimezoneName: UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyConnection;
  /** Reads and enables pagination through a set of `UnitDimension`. */
  unitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimension: UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyConnection;
  /** Reads and enables pagination through a set of `UnitGroupDimension`. */
  unitGroupDimensionsByUnitGroup: UnitGroupDimensionsConnection;
};

/** A grouping used to collect values for a set of unit dimensions. */
export type UnitGroupFacilitiesByUnitGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FacilityCondition>;
  filter?: InputMaybe<FacilityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** A grouping used to collect values for a set of unit dimensions. */
export type UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TimezoneCondition>;
  filter?: InputMaybe<TimezoneFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TimezonesOrderBy>>;
};

/** A grouping used to collect values for a set of unit dimensions. */
export type UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UnitDimensionCondition>;
    filter?: InputMaybe<UnitDimensionFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UnitDimensionsOrderBy>>;
  };

/** A grouping used to collect values for a set of unit dimensions. */
export type UnitGroupUnitGroupDimensionsByUnitGroupArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UnitGroupDimensionCondition>;
  filter?: InputMaybe<UnitGroupDimensionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UnitGroupDimensionsOrderBy>>;
};

export type UnitGroupAggregates = {
  __typename?: 'UnitGroupAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UnitGroupDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UnitGroupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `UnitGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UnitGroupCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A mapping of a unit dimension for a specific unit group. */
export type UnitGroupDimension = Node & {
  __typename?: 'UnitGroupDimension';
  /** The time the unit group dimension was created. */
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The value of the unit for the dimension. */
  unit: Scalars['String'];
  /** The associated unit dimension. */
  unitDimension: Scalars['String'];
  /** Reads a single `UnitDimension` that is related to this `UnitGroupDimension`. */
  unitDimensionByUnitDimension?: Maybe<UnitDimension>;
  /** The associated unit grouping. */
  unitGroup: Scalars['String'];
  /** Reads a single `UnitGroup` that is related to this `UnitGroupDimension`. */
  unitGroupByUnitGroup?: Maybe<UnitGroup>;
  /** The time the unit group dimension was updated. */
  updatedAt: Scalars['Datetime'];
};

export type UnitGroupDimensionAggregates = {
  __typename?: 'UnitGroupDimensionAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UnitGroupDimensionDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UnitGroupDimensionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `UnitGroupDimension` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UnitGroupDimensionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `unit` field. */
  unit?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `unitDimension` field. */
  unitDimension?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `unitGroup` field. */
  unitGroup?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type UnitGroupDimensionDistinctAggregates = {
  __typename?: 'UnitGroupDimensionDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of unit across the matching connection */
  unit?: Maybe<Scalars['JSON']>;
  /** Distinct of unitDimension across the matching connection */
  unitDimension?: Maybe<Scalars['JSON']>;
  /** Distinct of unitGroup across the matching connection */
  unitGroup?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type UnitGroupDimensionDistinctCountAggregates = {
  __typename?: 'UnitGroupDimensionDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unit across the matching connection */
  unit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unitDimension across the matching connection */
  unitDimension?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unitGroup across the matching connection */
  unitGroup?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UnitGroupDimension` object types. All fields are combined with a logical ‘and.’ */
export type UnitGroupDimensionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnitGroupDimensionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UnitGroupDimensionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnitGroupDimensionFilter>>;
  /** Filter by the object’s `unit` field. */
  unit?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unitDimension` field. */
  unitDimension?: InputMaybe<StringFilter>;
  /** Filter by the object’s `unitGroup` field. */
  unitGroup?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `UnitGroupDimension` values. */
export type UnitGroupDimensionsConnection = {
  __typename?: 'UnitGroupDimensionsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UnitGroupDimensionAggregates>;
  /** A list of edges which contains the `UnitGroupDimension` and cursor to aid in pagination. */
  edges: Array<UnitGroupDimensionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UnitGroupDimensionAggregates>>;
  /** A list of `UnitGroupDimension` objects. */
  nodes: Array<UnitGroupDimension>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnitGroupDimension` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UnitGroupDimension` values. */
export type UnitGroupDimensionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UnitGroupDimensionsGroupBy>;
  having?: InputMaybe<UnitGroupDimensionsHavingInput>;
};

/** A `UnitGroupDimension` edge in the connection. */
export type UnitGroupDimensionsEdge = {
  __typename?: 'UnitGroupDimensionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UnitGroupDimension` at the end of the edge. */
  node: UnitGroupDimension;
};

/** Grouping methods for `UnitGroupDimension` for usage during aggregation. */
export enum UnitGroupDimensionsGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  UNIT = 'UNIT',
  UNIT_DIMENSION = 'UNIT_DIMENSION',
  UNIT_DIMENSION_DISTINCT = 'UNIT_DIMENSION_DISTINCT',
  UNIT_DISTINCT = 'UNIT_DISTINCT',
  UNIT_GROUP = 'UNIT_GROUP',
  UNIT_GROUP_DISTINCT = 'UNIT_GROUP_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type UnitGroupDimensionsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UnitGroupDimension` aggregates. */
export type UnitGroupDimensionsHavingInput = {
  AND?: InputMaybe<Array<UnitGroupDimensionsHavingInput>>;
  OR?: InputMaybe<Array<UnitGroupDimensionsHavingInput>>;
  average?: InputMaybe<UnitGroupDimensionsHavingAverageInput>;
  distinct?: InputMaybe<UnitGroupDimensionsHavingDistinctInput>;
  distinctCount?: InputMaybe<UnitGroupDimensionsHavingDistinctCountInput>;
  max?: InputMaybe<UnitGroupDimensionsHavingMaxInput>;
  min?: InputMaybe<UnitGroupDimensionsHavingMinInput>;
  stddevPopulation?: InputMaybe<UnitGroupDimensionsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UnitGroupDimensionsHavingStddevSampleInput>;
  sum?: InputMaybe<UnitGroupDimensionsHavingSumInput>;
  variancePopulation?: InputMaybe<UnitGroupDimensionsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UnitGroupDimensionsHavingVarianceSampleInput>;
};

export type UnitGroupDimensionsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UnitGroupDimensionsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `UnitGroupDimension`. */
export enum UnitGroupDimensionsOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UNIT_ASC = 'UNIT_ASC',
  UNIT_DESC = 'UNIT_DESC',
  UNIT_DIMENSION_ASC = 'UNIT_DIMENSION_ASC',
  UNIT_DIMENSION_DESC = 'UNIT_DIMENSION_DESC',
  UNIT_GROUP_ASC = 'UNIT_GROUP_ASC',
  UNIT_GROUP_DESC = 'UNIT_GROUP_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type UnitGroupDistinctAggregates = {
  __typename?: 'UnitGroupDistinctAggregates';
  /** Distinct of name across the matching connection */
  name?: Maybe<Scalars['JSON']>;
};

export type UnitGroupDistinctCountAggregates = {
  __typename?: 'UnitGroupDistinctCountAggregates';
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UnitGroup` object types. All fields are combined with a logical ‘and.’ */
export type UnitGroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UnitGroupFilter>>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UnitGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UnitGroupFilter>>;
};

/** A connection to a list of `Timezone` values, with data from `Facility`. */
export type UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyConnection =
  {
    __typename?: 'UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<TimezoneAggregates>;
    /** A list of edges which contains the `Timezone`, info from the `Facility`, and the cursor to aid in pagination. */
    edges: Array<UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<TimezoneAggregates>>;
    /** A list of `Timezone` objects. */
    nodes: Array<Timezone>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Timezone` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Timezone` values, with data from `Facility`. */
export type UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<TimezonesGroupBy>;
    having?: InputMaybe<TimezonesHavingInput>;
  };

/** A `Timezone` edge in the connection, with data from `Facility`. */
export type UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyEdge =
  {
    __typename?: 'UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** Reads and enables pagination through a set of `Facility`. */
    facilitiesByTimezoneName: FacilitiesConnection;
    /** The `Timezone` at the end of the edge. */
    node: Timezone;
  };

/** A `Timezone` edge in the connection, with data from `Facility`. */
export type UnitGroupTimezonesByFacilityUnitGroupAndTimezoneNameManyToManyEdgeFacilitiesByTimezoneNameArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

/** A connection to a list of `UnitDimension` values, with data from `UnitGroupDimension`. */
export type UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyConnection =
  {
    __typename?: 'UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UnitDimensionAggregates>;
    /** A list of edges which contains the `UnitDimension`, info from the `UnitGroupDimension`, and the cursor to aid in pagination. */
    edges: Array<UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyEdge>;
    /** A list of `UnitDimension` objects. */
    nodes: Array<UnitDimension>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UnitDimension` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A `UnitDimension` edge in the connection, with data from `UnitGroupDimension`. */
export type UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyEdge =
  {
    __typename?: 'UnitGroupUnitDimensionsByUnitGroupDimensionUnitGroupAndUnitDimensionManyToManyEdge';
    /** The time the unit group dimension was created. */
    createdAt: Scalars['Datetime'];
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UnitDimension` at the end of the edge. */
    node: UnitDimension;
    /** The value of the unit for the dimension. */
    unit: Scalars['String'];
    /** The time the unit group dimension was updated. */
    updatedAt: Scalars['Datetime'];
  };

/** A connection to a list of `UnitGroup` values. */
export type UnitGroupsConnection = {
  __typename?: 'UnitGroupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UnitGroupAggregates>;
  /** A list of edges which contains the `UnitGroup` and cursor to aid in pagination. */
  edges: Array<UnitGroupsEdge>;
  /** A list of `UnitGroup` objects. */
  nodes: Array<UnitGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UnitGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UnitGroup` edge in the connection. */
export type UnitGroupsEdge = {
  __typename?: 'UnitGroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UnitGroup` at the end of the edge. */
  node: UnitGroup;
};

/** Methods to use when ordering `UnitGroup`. */
export enum UnitGroupsOrderBy {
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_AVERAGE_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_AVERAGE_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_COUNT_ASC = 'FACILITIES_BY_UNIT_GROUP_COUNT_ASC',
  FACILITIES_BY_UNIT_GROUP_COUNT_DESC = 'FACILITIES_BY_UNIT_GROUP_COUNT_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_COUNT_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_DISTINCT_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_DISTINCT_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_MAX_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_MAX_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_MAX_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_MAX_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_MIN_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_MIN_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_MIN_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_MIN_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_POPULATION_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_STDDEV_SAMPLE_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_SUM_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_SUM_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_SUM_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_SUM_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_POPULATION_ZIP_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ADDRESS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ADDRESS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ADDRESS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ADDRESS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_BONUS_LTR_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_BONUS_LTR_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_BONUS_LTR_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_BONUS_LTR_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CITY_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CITY_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CITY_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CITY_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CONFIG_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CONFIG_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CONFIG_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CONFIG_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_COUNTRY_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_COUNTRY_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_COUNTRY_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_COUNTRY_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_FACILITY_CONTACTS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_FACILITY_CONTACTS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_FACILITY_CONTACTS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_FACILITY_CONTACTS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ID_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ID_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ID_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ID_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LATITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LATITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LATITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LATITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LINK_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LINK_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LINK_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LINK_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LONGITUDE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LONGITUDE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LONGITUDE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_LONGITUDE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_METRICS_ONE_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NOTE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NOTE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NOTE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_NOTE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_2_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_N_SIGHT_ACTIVE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_PRE_BONUS_EBITDA_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_REAL_TIME_ENABLED_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_REAL_TIME_ENABLED_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_REAL_TIME_ENABLED_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_REAL_TIME_ENABLED_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SLUG_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SLUG_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SLUG_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SLUG_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_STATE_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_STATE_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_STATE_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_STATE_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_SUPPRESSE_FINANCIALS_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_TIMEZONE_NAME_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_TIMEZONE_NAME_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_TIMEZONE_NAME_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_TIMEZONE_NAME_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ZIP_ASC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ZIP_ASC',
  FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ZIP_DESC = 'FACILITIES_BY_UNIT_GROUP_VARIANCE_SAMPLE_ZIP_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_AVERAGE_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_COUNT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_COUNT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_COUNT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_COUNT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_COUNT_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_DISTINCT_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MAX_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_MIN_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_POPULATION_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_SUM_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DIMENSION_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DIMENSION_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DIMENSION_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_DIMENSION_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UNIT_GROUP_DESC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'UNIT_GROUP_DIMENSIONS_BY_UNIT_GROUP_VARIANCE_SAMPLE_UPDATED_AT_DESC'
}

/** All input for the `updateAccountChargeByNodeId` mutation. */
export type UpdateAccountChargeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountCharge` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AccountCharge` being updated. */
  patch: AccountChargePatch;
};

/** All input for the `updateAccountCharge` mutation. */
export type UpdateAccountChargeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AccountCharge` being updated. */
  patch: AccountChargePatch;
};

/** The output of our update `AccountCharge` mutation. */
export type UpdateAccountChargePayload = {
  __typename?: 'UpdateAccountChargePayload';
  /** The `AccountCharge` that was updated by this mutation. */
  accountCharge?: Maybe<AccountCharge>;
  /** An edge for our `AccountCharge`. May be used by Relay 1. */
  accountChargeEdge?: Maybe<AccountChargesEdge>;
  /** Reads a single `AccountStatement` that is related to this `AccountCharge`. */
  accountStatement?: Maybe<AccountStatement>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AccountCharge` mutation. */
export type UpdateAccountChargePayloadAccountChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountChargesOrderBy>>;
};

/** All input for the `updateAccountStatementByNodeId` mutation. */
export type UpdateAccountStatementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AccountStatement` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AccountStatement` being updated. */
  patch: AccountStatementPatch;
};

/** All input for the `updateAccountStatement` mutation. */
export type UpdateAccountStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AccountStatement` being updated. */
  patch: AccountStatementPatch;
};

/** The output of our update `AccountStatement` mutation. */
export type UpdateAccountStatementPayload = {
  __typename?: 'UpdateAccountStatementPayload';
  /** The `AccountStatement` that was updated by this mutation. */
  accountStatement?: Maybe<AccountStatement>;
  /** An edge for our `AccountStatement`. May be used by Relay 1. */
  accountStatementEdge?: Maybe<AccountStatementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `AccountStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
};

/** The output of our update `AccountStatement` mutation. */
export type UpdateAccountStatementPayloadAccountStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
};

/** All input for the `updateComponentByNodeId` mutation. */
export type UpdateComponentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Component` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Component` being updated. */
  patch: ComponentPatch;
};

/** All input for the `updateComponent` mutation. */
export type UpdateComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the component */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Component` being updated. */
  patch: ComponentPatch;
};

/** The output of our update `Component` mutation. */
export type UpdateComponentPayload = {
  __typename?: 'UpdateComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Component` that was updated by this mutation. */
  component?: Maybe<Component>;
  /** An edge for our `Component`. May be used by Relay 1. */
  componentEdge?: Maybe<ComponentsEdge>;
  /** Reads a single `Facility` that is related to this `Component`. */
  facility?: Maybe<Facility>;
  /** Reads a single `MachineUser` that is related to this `Component`. */
  machineUser?: Maybe<MachineUser>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Component` mutation. */
export type UpdateComponentPayloadComponentEdgeArgs = {
  orderBy?: InputMaybe<Array<ComponentsOrderBy>>;
};

/** All input for the `updateControlEventByNodeId` mutation. */
export type UpdateControlEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ControlEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ControlEvent` being updated. */
  patch: ControlEventPatch;
};

/** All input for the `updateControlEvent` mutation. */
export type UpdateControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ControlEvent` being updated. */
  patch: ControlEventPatch;
};

/** All input for the `updateControlEventLogByNodeId` mutation. */
export type UpdateControlEventLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ControlEventLog` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ControlEventLog` being updated. */
  patch: ControlEventLogPatch;
};

/** All input for the `updateControlEventLog` mutation. */
export type UpdateControlEventLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the control event log entry */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ControlEventLog` being updated. */
  patch: ControlEventLogPatch;
};

/** The output of our update `ControlEventLog` mutation. */
export type UpdateControlEventLogPayload = {
  __typename?: 'UpdateControlEventLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The `ControlEventLog` that was updated by this mutation. */
  controlEventLog?: Maybe<ControlEventLog>;
  /** An edge for our `ControlEventLog`. May be used by Relay 1. */
  controlEventLogEdge?: Maybe<ControlEventLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ControlEventLog` mutation. */
export type UpdateControlEventLogPayloadControlEventLogEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** The output of our update `ControlEvent` mutation. */
export type UpdateControlEventPayload = {
  __typename?: 'UpdateControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ControlEvent`. */
  component?: Maybe<Component>;
  /** The `ControlEvent` that was updated by this mutation. */
  controlEvent?: Maybe<ControlEvent>;
  /** An edge for our `ControlEvent`. May be used by Relay 1. */
  controlEventEdge?: Maybe<ControlEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ControlEvent` mutation. */
export type UpdateControlEventPayloadControlEventEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventsOrderBy>>;
};

/** All input for the `updateFacilityByNodeId` mutation. */
export type UpdateFacilityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Facility` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Facility` being updated. */
  patch: FacilityPatch;
};

/** All input for the `updateFacilityBySlug` mutation. */
export type UpdateFacilityBySlugInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Facility` being updated. */
  patch: FacilityPatch;
  /** The facility’s slug. */
  slug: Scalars['String'];
};

/** All input for the `updateFacilityGroupByNodeId` mutation. */
export type UpdateFacilityGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FacilityGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FacilityGroup` being updated. */
  patch: FacilityGroupPatch;
};

/** All input for the `updateFacilityGroup` mutation. */
export type UpdateFacilityGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The facility group’s id. */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `FacilityGroup` being updated. */
  patch: FacilityGroupPatch;
};

/** The output of our update `FacilityGroup` mutation. */
export type UpdateFacilityGroupPayload = {
  __typename?: 'UpdateFacilityGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FacilityGroup` that was updated by this mutation. */
  facilityGroup?: Maybe<FacilityGroup>;
  /** An edge for our `FacilityGroup`. May be used by Relay 1. */
  facilityGroupEdge?: Maybe<FacilityGroupsEdge>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroup`. */
  parent?: Maybe<FacilityGroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FacilityGroup` mutation. */
export type UpdateFacilityGroupPayloadFacilityGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsOrderBy>>;
};

/** All input for the `updateFacilityGroupsLinkerByNodeId` mutation. */
export type UpdateFacilityGroupsLinkerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FacilityGroupsLinker` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FacilityGroupsLinker` being updated. */
  patch: FacilityGroupsLinkerPatch;
};

/** All input for the `updateFacilityGroupsLinker` mutation. */
export type UpdateFacilityGroupsLinkerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  facilityGroupingId: Scalars['BigInt'];
  facilityId: Scalars['Int'];
  /** An object where the defined keys will be set on the `FacilityGroupsLinker` being updated. */
  patch: FacilityGroupsLinkerPatch;
};

/** The output of our update `FacilityGroupsLinker` mutation. */
export type UpdateFacilityGroupsLinkerPayload = {
  __typename?: 'UpdateFacilityGroupsLinkerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `FacilityGroupsLinker`. */
  facility?: Maybe<Facility>;
  /** Reads a single `FacilityGroup` that is related to this `FacilityGroupsLinker`. */
  facilityGrouping?: Maybe<FacilityGroup>;
  /** The `FacilityGroupsLinker` that was updated by this mutation. */
  facilityGroupsLinker?: Maybe<FacilityGroupsLinker>;
  /** An edge for our `FacilityGroupsLinker`. May be used by Relay 1. */
  facilityGroupsLinkerEdge?: Maybe<FacilityGroupsLinkersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `FacilityGroupsLinker` mutation. */
export type UpdateFacilityGroupsLinkerPayloadFacilityGroupsLinkerEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilityGroupsLinkersOrderBy>>;
};

/** All input for the `updateFacility` mutation. */
export type UpdateFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the facility. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Facility` being updated. */
  patch: FacilityPatch;
};

/** The output of our update `Facility` mutation. */
export type UpdateFacilityPayload = {
  __typename?: 'UpdateFacilityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Facility` that was updated by this mutation. */
  facility?: Maybe<Facility>;
  /** An edge for our `Facility`. May be used by Relay 1. */
  facilityEdge?: Maybe<FacilitiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Timezone` that is related to this `Facility`. */
  timezone?: Maybe<Timezone>;
  /** Reads a single `UnitGroup` that is related to this `Facility`. */
  unitGroupByUnitGroup?: Maybe<UnitGroup>;
};

/** The output of our update `Facility` mutation. */
export type UpdateFacilityPayloadFacilityEdgeArgs = {
  orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
};

/** All input for the `updateMachineUserByNodeId` mutation. */
export type UpdateMachineUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MachineUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MachineUser` being updated. */
  patch: MachineUserPatch;
};

/** All input for the `updateMachineUser` mutation. */
export type UpdateMachineUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the machine user. */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `MachineUser` being updated. */
  patch: MachineUserPatch;
};

/** The output of our update `MachineUser` mutation. */
export type UpdateMachineUserPayload = {
  __typename?: 'UpdateMachineUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MachineUser` that was updated by this mutation. */
  machineUser?: Maybe<MachineUser>;
  /** An edge for our `MachineUser`. May be used by Relay 1. */
  machineUserEdge?: Maybe<MachineUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `MachineUser` mutation. */
export type UpdateMachineUserPayloadMachineUserEdgeArgs = {
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** All input for the `updateMeterChargeByNodeId` mutation. */
export type UpdateMeterChargeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MeterCharge` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MeterCharge` being updated. */
  patch: MeterChargePatch;
};

/** All input for the `updateMeterCharge` mutation. */
export type UpdateMeterChargeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `MeterCharge` being updated. */
  patch: MeterChargePatch;
};

/** The output of our update `MeterCharge` mutation. */
export type UpdateMeterChargePayload = {
  __typename?: 'UpdateMeterChargePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterCharge` that was updated by this mutation. */
  meterCharge?: Maybe<MeterCharge>;
  /** An edge for our `MeterCharge`. May be used by Relay 1. */
  meterChargeEdge?: Maybe<MeterChargesEdge>;
  /** Reads a single `MeterStatement` that is related to this `MeterCharge`. */
  meterStatement?: Maybe<MeterStatement>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `MeterCharge` mutation. */
export type UpdateMeterChargePayloadMeterChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterChargesOrderBy>>;
};

/** All input for the `updateMeterStatementByNodeId` mutation. */
export type UpdateMeterStatementByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MeterStatement` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MeterStatement` being updated. */
  patch: MeterStatementPatch;
};

/** All input for the `updateMeterStatement` mutation. */
export type UpdateMeterStatementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `MeterStatement` being updated. */
  patch: MeterStatementPatch;
};

/** The output of our update `MeterStatement` mutation. */
export type UpdateMeterStatementPayload = {
  __typename?: 'UpdateMeterStatementPayload';
  /** Reads a single `AccountStatement` that is related to this `MeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterStatement` that was updated by this mutation. */
  meterStatement?: Maybe<MeterStatement>;
  /** An edge for our `MeterStatement`. May be used by Relay 1. */
  meterStatementEdge?: Maybe<MeterStatementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityMeter` that is related to this `MeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
};

/** The output of our update `MeterStatement` mutation. */
export type UpdateMeterStatementPayloadMeterStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

/** All input for the `updateMeterUsageByNodeId` mutation. */
export type UpdateMeterUsageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MeterUsage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MeterUsage` being updated. */
  patch: MeterUsagePatch;
};

/** All input for the `updateMeterUsage` mutation. */
export type UpdateMeterUsageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `MeterUsage` being updated. */
  patch: MeterUsagePatch;
};

/** The output of our update `MeterUsage` mutation. */
export type UpdateMeterUsagePayload = {
  __typename?: 'UpdateMeterUsagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `MeterUsage`. */
  meterStatement?: Maybe<MeterStatement>;
  /** The `MeterUsage` that was updated by this mutation. */
  meterUsage?: Maybe<MeterUsage>;
  /** An edge for our `MeterUsage`. May be used by Relay 1. */
  meterUsageEdge?: Maybe<MeterUsagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `MeterUsage` mutation. */
export type UpdateMeterUsagePayloadMeterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterUsagesOrderBy>>;
};

/** All input for the `updateMetricDependencyByNodeId` mutation. */
export type UpdateMetricDependencyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MetricDependency` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MetricDependency` being updated. */
  patch: MetricDependencyPatch;
};

/** All input for the `updateMetricDependencyByPath` mutation. */
export type UpdateMetricDependencyByPathInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `MetricDependency` being updated. */
  patch: MetricDependencyPatch;
  path: Scalars['String'];
};

/** All input for the `updateMetricDependency` mutation. */
export type UpdateMetricDependencyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `MetricDependency` being updated. */
  patch: MetricDependencyPatch;
};

/** The output of our update `MetricDependency` mutation. */
export type UpdateMetricDependencyPayload = {
  __typename?: 'UpdateMetricDependencyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MetricDependency` that was updated by this mutation. */
  metricDependency?: Maybe<MetricDependency>;
  /** An edge for our `MetricDependency`. May be used by Relay 1. */
  metricDependencyEdge?: Maybe<MetricDependenciesEdge>;
  /** Reads a single `MetricMetadatum` that is related to this `MetricDependency`. */
  metricMetadatumByMetric?: Maybe<MetricMetadatum>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `MetricDependency` mutation. */
export type UpdateMetricDependencyPayloadMetricDependencyEdgeArgs = {
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

export type UpdatePhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type UpdatePhoneNumberResponse = {
  __typename?: 'UpdatePhoneNumberResponse';
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** All input for the `updateProjectByNodeId` mutation. */
export type UpdateProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Project` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch;
};

/** All input for the `updateProjectComponentByNodeId` mutation. */
export type UpdateProjectComponentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectComponent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectComponent` being updated. */
  patch: ProjectComponentPatch;
};

/** All input for the `updateProjectComponent` mutation. */
export type UpdateProjectComponentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  componentId: Scalars['Int'];
  /** An object where the defined keys will be set on the `ProjectComponent` being updated. */
  patch: ProjectComponentPatch;
  projectId: Scalars['Int'];
};

/** The output of our update `ProjectComponent` mutation. */
export type UpdateProjectComponentPayload = {
  __typename?: 'UpdateProjectComponentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ProjectComponent`. */
  component?: Maybe<Component>;
  /** Reads a single `Project` that is related to this `ProjectComponent`. */
  project?: Maybe<Project>;
  /** The `ProjectComponent` that was updated by this mutation. */
  projectComponent?: Maybe<ProjectComponent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateProject` mutation. */
export type UpdateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the optimization project. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch;
};

/** The output of our update `Project` mutation. */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `Project`. */
  facility?: Maybe<Facility>;
  /** The `Project` that was updated by this mutation. */
  project?: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge?: Maybe<ProjectsEdge>;
  /** Reads a single `ProjectType` that is related to this `Project`. */
  projectType?: Maybe<ProjectType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Project` mutation. */
export type UpdateProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the `updateProjectWatcherByNodeId` mutation. */
export type UpdateProjectWatcherByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectWatcher` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectWatcher` being updated. */
  patch: ProjectWatcherPatch;
};

/** All input for the `updateProjectWatcher` mutation. */
export type UpdateProjectWatcherInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectWatcher` being updated. */
  patch: ProjectWatcherPatch;
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};

/** The output of our update `ProjectWatcher` mutation. */
export type UpdateProjectWatcherPayload = {
  __typename?: 'UpdateProjectWatcherPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectWatcher`. */
  project?: Maybe<Project>;
  /** The `ProjectWatcher` that was updated by this mutation. */
  projectWatcher?: Maybe<ProjectWatcher>;
  /** An edge for our `ProjectWatcher`. May be used by Relay 1. */
  projectWatcherEdge?: Maybe<ProjectWatchersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectWatcher`. */
  user?: Maybe<User>;
};

/** The output of our update `ProjectWatcher` mutation. */
export type UpdateProjectWatcherPayloadProjectWatcherEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** All input for the `updateSuggestionByNodeId` mutation. */
export type UpdateSuggestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Suggestion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Suggestion` being updated. */
  patch: SuggestionPatch;
};

/** All input for the `updateSuggestionControlEventByNodeId` mutation. */
export type UpdateSuggestionControlEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SuggestionControlEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SuggestionControlEvent` being updated. */
  patch: SuggestionControlEventPatch;
};

/** All input for the `updateSuggestionControlEvent` mutation. */
export type UpdateSuggestionControlEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  controlEventId: Scalars['Int'];
  /** An object where the defined keys will be set on the `SuggestionControlEvent` being updated. */
  patch: SuggestionControlEventPatch;
  suggestionId: Scalars['Int'];
};

/** The output of our update `SuggestionControlEvent` mutation. */
export type UpdateSuggestionControlEventPayload = {
  __typename?: 'UpdateSuggestionControlEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `SuggestionControlEvent`. */
  controlEvent?: Maybe<ControlEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Suggestion` that is related to this `SuggestionControlEvent`. */
  suggestion?: Maybe<Suggestion>;
  /** The `SuggestionControlEvent` that was updated by this mutation. */
  suggestionControlEvent?: Maybe<SuggestionControlEvent>;
  /** An edge for our `SuggestionControlEvent`. May be used by Relay 1. */
  suggestionControlEventEdge?: Maybe<SuggestionControlEventsEdge>;
};

/** The output of our update `SuggestionControlEvent` mutation. */
export type UpdateSuggestionControlEventPayloadSuggestionControlEventEdgeArgs =
  {
    orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
  };

/** All input for the `updateSuggestion` mutation. */
export type UpdateSuggestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The identifier of the suggestions. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Suggestion` being updated. */
  patch: SuggestionPatch;
};

/** The output of our update `Suggestion` mutation. */
export type UpdateSuggestionPayload = {
  __typename?: 'UpdateSuggestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `Suggestion`. */
  project?: Maybe<Project>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Suggestion` that was updated by this mutation. */
  suggestion?: Maybe<Suggestion>;
  /** An edge for our `Suggestion`. May be used by Relay 1. */
  suggestionEdge?: Maybe<SuggestionsEdge>;
};

/** The output of our update `Suggestion` mutation. */
export type UpdateSuggestionPayloadSuggestionEdgeArgs = {
  orderBy?: InputMaybe<Array<SuggestionsOrderBy>>;
};

/** All input for the `updateUtilityAccountByNodeId` mutation. */
export type UpdateUtilityAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UtilityAccount` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UtilityAccount` being updated. */
  patch: UtilityAccountPatch;
};

/** All input for the `updateUtilityAccount` mutation. */
export type UpdateUtilityAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier of the account. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UtilityAccount` being updated. */
  patch: UtilityAccountPatch;
};

/** The output of our update `UtilityAccount` mutation. */
export type UpdateUtilityAccountPayload = {
  __typename?: 'UpdateUtilityAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityAccount` that was updated by this mutation. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** An edge for our `UtilityAccount`. May be used by Relay 1. */
  utilityAccountEdge?: Maybe<UtilityAccountsEdge>;
  /** Reads a single `UtilityProvider` that is related to this `UtilityAccount`. */
  utilityProvider?: Maybe<UtilityProvider>;
};

/** The output of our update `UtilityAccount` mutation. */
export type UpdateUtilityAccountPayloadUtilityAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
};

/** All input for the `updateUtilityMeterByNodeId` mutation. */
export type UpdateUtilityMeterByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UtilityMeter` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UtilityMeter` being updated. */
  patch: UtilityMeterPatch;
};

/** All input for the `updateUtilityMeter` mutation. */
export type UpdateUtilityMeterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UtilityMeter` being updated. */
  patch: UtilityMeterPatch;
};

/** The output of our update `UtilityMeter` mutation. */
export type UpdateUtilityMeterPayload = {
  __typename?: 'UpdateUtilityMeterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `UtilityMeter`. */
  facility?: Maybe<Facility>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeter`. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** The `UtilityMeter` that was updated by this mutation. */
  utilityMeter?: Maybe<UtilityMeter>;
  /** An edge for our `UtilityMeter`. May be used by Relay 1. */
  utilityMeterEdge?: Maybe<UtilityMetersEdge>;
};

/** The output of our update `UtilityMeter` mutation. */
export type UpdateUtilityMeterPayloadUtilityMeterEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** All input for the `updateUtilityProviderByName` mutation. */
export type UpdateUtilityProviderByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the provider. */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `UtilityProvider` being updated. */
  patch: UtilityProviderPatch;
};

/** All input for the `updateUtilityProviderByNodeId` mutation. */
export type UpdateUtilityProviderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UtilityProvider` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UtilityProvider` being updated. */
  patch: UtilityProviderPatch;
};

/** All input for the `updateUtilityProvider` mutation. */
export type UpdateUtilityProviderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The primary unique identifier for the provider. */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UtilityProvider` being updated. */
  patch: UtilityProviderPatch;
};

/** The output of our update `UtilityProvider` mutation. */
export type UpdateUtilityProviderPayload = {
  __typename?: 'UpdateUtilityProviderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityProvider` that was updated by this mutation. */
  utilityProvider?: Maybe<UtilityProvider>;
  /** An edge for our `UtilityProvider`. May be used by Relay 1. */
  utilityProviderEdge?: Maybe<UtilityProvidersEdge>;
};

/** The output of our update `UtilityProvider` mutation. */
export type UpdateUtilityProviderPayloadUtilityProviderEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityProvidersOrderBy>>;
};

/** All input for the upsert `AccountCharge` mutation. */
export type UpsertAccountChargeInput = {
  /** The `AccountCharge` to be upserted by this mutation. */
  accountCharge: AccountChargeInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `AccountCharge` mutation. */
export type UpsertAccountChargePayload = {
  __typename?: 'UpsertAccountChargePayload';
  /** The `AccountCharge` that was upserted by this mutation. */
  accountCharge?: Maybe<AccountCharge>;
  /** An edge for our `AccountCharge`. May be used by Relay 1. */
  accountChargeEdge?: Maybe<AccountChargesEdge>;
  /** Reads a single `AccountStatement` that is related to this `AccountCharge`. */
  accountStatement?: Maybe<AccountStatement>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `AccountCharge` mutation. */
export type UpsertAccountChargePayloadAccountChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountChargesOrderBy>>;
};

/** Where conditions for the upsert `AccountCharge` mutation. */
export type UpsertAccountChargeWhere = {
  accountStatementId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<UtilityAccountChargeCategory>;
  id?: InputMaybe<Scalars['Int']>;
  intervalEnd?: InputMaybe<Scalars['Date']>;
  intervalStart?: InputMaybe<Scalars['Date']>;
  label?: InputMaybe<Scalars['String']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `AccountStatement` mutation. */
export type UpsertAccountStatementInput = {
  /** The `AccountStatement` to be upserted by this mutation. */
  accountStatement: AccountStatementInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `AccountStatement` mutation. */
export type UpsertAccountStatementPayload = {
  __typename?: 'UpsertAccountStatementPayload';
  /** The `AccountStatement` that was upserted by this mutation. */
  accountStatement?: Maybe<AccountStatement>;
  /** An edge for our `AccountStatement`. May be used by Relay 1. */
  accountStatementEdge?: Maybe<AccountStatementsEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `AccountStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
};

/** The output of our upsert `AccountStatement` mutation. */
export type UpsertAccountStatementPayloadAccountStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
};

/** Where conditions for the upsert `AccountStatement` mutation. */
export type UpsertAccountStatementWhere = {
  id?: InputMaybe<Scalars['Int']>;
  integration?: InputMaybe<UtilityIntegration>;
  integrationKey?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `BalancingAuthority` mutation. */
export type UpsertBalancingAuthorityInput = {
  /** The `BalancingAuthority` to be upserted by this mutation. */
  balancingAuthority: BalancingAuthorityInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our upsert `BalancingAuthority` mutation. */
export type UpsertBalancingAuthorityPayload = {
  __typename?: 'UpsertBalancingAuthorityPayload';
  /** The `BalancingAuthority` that was upserted by this mutation. */
  balancingAuthority?: Maybe<BalancingAuthority>;
  /** An edge for our `BalancingAuthority`. May be used by Relay 1. */
  balancingAuthorityEdge?: Maybe<BalancingAuthoritiesEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `BalancingAuthority` mutation. */
export type UpsertBalancingAuthorityPayloadBalancingAuthorityEdgeArgs = {
  orderBy?: InputMaybe<Array<BalancingAuthoritiesOrderBy>>;
};

/** Where conditions for the upsert `BalancingAuthority` mutation. */
export type UpsertBalancingAuthorityWhere = {
  slug?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `ControlEventLog` mutation. */
export type UpsertControlEventLogInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ControlEventLog` to be upserted by this mutation. */
  controlEventLog: ControlEventLogInput;
};

/** The output of our upsert `ControlEventLog` mutation. */
export type UpsertControlEventLogPayload = {
  __typename?: 'UpsertControlEventLogPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `ControlEventLog`. */
  controlEvent?: Maybe<ControlEvent>;
  /** The `ControlEventLog` that was upserted by this mutation. */
  controlEventLog?: Maybe<ControlEventLog>;
  /** An edge for our `ControlEventLog`. May be used by Relay 1. */
  controlEventLogEdge?: Maybe<ControlEventLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `ControlEventLog` mutation. */
export type UpsertControlEventLogPayloadControlEventLogEdgeArgs = {
  orderBy?: InputMaybe<Array<ControlEventLogsOrderBy>>;
};

/** Where conditions for the upsert `ControlEventLog` mutation. */
export type UpsertControlEventLogWhere = {
  id?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `MachineUser` mutation. */
export type UpsertMachineUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MachineUser` to be upserted by this mutation. */
  machineUser: MachineUserInput;
};

/** The output of our upsert `MachineUser` mutation. */
export type UpsertMachineUserPayload = {
  __typename?: 'UpsertMachineUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MachineUser` that was upserted by this mutation. */
  machineUser?: Maybe<MachineUser>;
  /** An edge for our `MachineUser`. May be used by Relay 1. */
  machineUserEdge?: Maybe<MachineUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `MachineUser` mutation. */
export type UpsertMachineUserPayloadMachineUserEdgeArgs = {
  orderBy?: InputMaybe<Array<MachineUsersOrderBy>>;
};

/** Where conditions for the upsert `MachineUser` mutation. */
export type UpsertMachineUserWhere = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/** All input for the upsert `MeterCharge` mutation. */
export type UpsertMeterChargeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterCharge` to be upserted by this mutation. */
  meterCharge: MeterChargeInput;
};

/** The output of our upsert `MeterCharge` mutation. */
export type UpsertMeterChargePayload = {
  __typename?: 'UpsertMeterChargePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterCharge` that was upserted by this mutation. */
  meterCharge?: Maybe<MeterCharge>;
  /** An edge for our `MeterCharge`. May be used by Relay 1. */
  meterChargeEdge?: Maybe<MeterChargesEdge>;
  /** Reads a single `MeterStatement` that is related to this `MeterCharge`. */
  meterStatement?: Maybe<MeterStatement>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `MeterCharge` mutation. */
export type UpsertMeterChargePayloadMeterChargeEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterChargesOrderBy>>;
};

/** Where conditions for the upsert `MeterCharge` mutation. */
export type UpsertMeterChargeWhere = {
  category?: InputMaybe<UtilityMeterChargeCategory>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `MeterStatement` mutation. */
export type UpsertMeterStatementInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterStatement` to be upserted by this mutation. */
  meterStatement: MeterStatementInput;
};

/** The output of our upsert `MeterStatement` mutation. */
export type UpsertMeterStatementPayload = {
  __typename?: 'UpsertMeterStatementPayload';
  /** Reads a single `AccountStatement` that is related to this `MeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MeterStatement` that was upserted by this mutation. */
  meterStatement?: Maybe<MeterStatement>;
  /** An edge for our `MeterStatement`. May be used by Relay 1. */
  meterStatementEdge?: Maybe<MeterStatementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityMeter` that is related to this `MeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
};

/** The output of our upsert `MeterStatement` mutation. */
export type UpsertMeterStatementPayloadMeterStatementEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

/** Where conditions for the upsert `MeterStatement` mutation. */
export type UpsertMeterStatementWhere = {
  id?: InputMaybe<Scalars['Int']>;
  integrationKey?: InputMaybe<Scalars['String']>;
  statementDate?: InputMaybe<Scalars['Date']>;
};

/** All input for the upsert `MeterUsage` mutation. */
export type UpsertMeterUsageInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MeterUsage` to be upserted by this mutation. */
  meterUsage: MeterUsageInput;
};

/** The output of our upsert `MeterUsage` mutation. */
export type UpsertMeterUsagePayload = {
  __typename?: 'UpsertMeterUsagePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `MeterUsage`. */
  meterStatement?: Maybe<MeterStatement>;
  /** The `MeterUsage` that was upserted by this mutation. */
  meterUsage?: Maybe<MeterUsage>;
  /** An edge for our `MeterUsage`. May be used by Relay 1. */
  meterUsageEdge?: Maybe<MeterUsagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `MeterUsage` mutation. */
export type UpsertMeterUsagePayloadMeterUsageEdgeArgs = {
  orderBy?: InputMaybe<Array<MeterUsagesOrderBy>>;
};

/** Where conditions for the upsert `MeterUsage` mutation. */
export type UpsertMeterUsageWhere = {
  category?: InputMaybe<UtilityMeterUsageCategory>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  meterStatementId?: InputMaybe<Scalars['Int']>;
  units?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `MetricDependency` mutation. */
export type UpsertMetricDependencyInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MetricDependency` to be upserted by this mutation. */
  metricDependency: MetricDependencyInput;
};

/** The output of our upsert `MetricDependency` mutation. */
export type UpsertMetricDependencyPayload = {
  __typename?: 'UpsertMetricDependencyPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `MetricDependency` that was upserted by this mutation. */
  metricDependency?: Maybe<MetricDependency>;
  /** An edge for our `MetricDependency`. May be used by Relay 1. */
  metricDependencyEdge?: Maybe<MetricDependenciesEdge>;
  /** Reads a single `MetricMetadatum` that is related to this `MetricDependency`. */
  metricMetadatumByMetric?: Maybe<MetricMetadatum>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `MetricDependency` mutation. */
export type UpsertMetricDependencyPayloadMetricDependencyEdgeArgs = {
  orderBy?: InputMaybe<Array<MetricDependenciesOrderBy>>;
};

/** Where conditions for the upsert `MetricDependency` mutation. */
export type UpsertMetricDependencyWhere = {
  id?: InputMaybe<Scalars['BigInt']>;
  path?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `ProjectComponent` mutation. */
export type UpsertProjectComponentInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectComponent` to be upserted by this mutation. */
  projectComponent: ProjectComponentInput;
};

/** The output of our upsert `ProjectComponent` mutation. */
export type UpsertProjectComponentPayload = {
  __typename?: 'UpsertProjectComponentPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Component` that is related to this `ProjectComponent`. */
  component?: Maybe<Component>;
  /** Reads a single `Project` that is related to this `ProjectComponent`. */
  project?: Maybe<Project>;
  /** The `ProjectComponent` that was upserted by this mutation. */
  projectComponent?: Maybe<ProjectComponent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Where conditions for the upsert `ProjectComponent` mutation. */
export type UpsertProjectComponentWhere = {
  componentId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
};

/** All input for the `upsertProjectSubscription` mutation. */
export type UpsertProjectSubscriptionInput = {
  channels?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
};

/** The output of our `upsertProjectSubscription` mutation. */
export type UpsertProjectSubscriptionPayload = {
  __typename?: 'UpsertProjectSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  userSubscriptions?: Maybe<Array<UserSubscription>>;
};

/** All input for the upsert `ProjectWatcher` mutation. */
export type UpsertProjectWatcherInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectWatcher` to be upserted by this mutation. */
  projectWatcher: ProjectWatcherInput;
};

/** The output of our upsert `ProjectWatcher` mutation. */
export type UpsertProjectWatcherPayload = {
  __typename?: 'UpsertProjectWatcherPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectWatcher`. */
  project?: Maybe<Project>;
  /** The `ProjectWatcher` that was upserted by this mutation. */
  projectWatcher?: Maybe<ProjectWatcher>;
  /** An edge for our `ProjectWatcher`. May be used by Relay 1. */
  projectWatcherEdge?: Maybe<ProjectWatchersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectWatcher`. */
  user?: Maybe<User>;
};

/** The output of our upsert `ProjectWatcher` mutation. */
export type UpsertProjectWatcherPayloadProjectWatcherEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** Where conditions for the upsert `ProjectWatcher` mutation. */
export type UpsertProjectWatcherWhere = {
  projectId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `SuggestionControlEvent` mutation. */
export type UpsertSuggestionControlEventInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SuggestionControlEvent` to be upserted by this mutation. */
  suggestionControlEvent: SuggestionControlEventInput;
};

/** The output of our upsert `SuggestionControlEvent` mutation. */
export type UpsertSuggestionControlEventPayload = {
  __typename?: 'UpsertSuggestionControlEventPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `ControlEvent` that is related to this `SuggestionControlEvent`. */
  controlEvent?: Maybe<ControlEvent>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Suggestion` that is related to this `SuggestionControlEvent`. */
  suggestion?: Maybe<Suggestion>;
  /** The `SuggestionControlEvent` that was upserted by this mutation. */
  suggestionControlEvent?: Maybe<SuggestionControlEvent>;
  /** An edge for our `SuggestionControlEvent`. May be used by Relay 1. */
  suggestionControlEventEdge?: Maybe<SuggestionControlEventsEdge>;
};

/** The output of our upsert `SuggestionControlEvent` mutation. */
export type UpsertSuggestionControlEventPayloadSuggestionControlEventEdgeArgs =
  {
    orderBy?: InputMaybe<Array<SuggestionControlEventsOrderBy>>;
  };

/** Where conditions for the upsert `SuggestionControlEvent` mutation. */
export type UpsertSuggestionControlEventWhere = {
  controlEventId?: InputMaybe<Scalars['Int']>;
  suggestionId?: InputMaybe<Scalars['Int']>;
};

/** All input for the `upsertUrjanetUtilityProvider` mutation. */
export type UpsertUrjanetUtilityProviderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  urjanetSlug: Scalars['String'];
};

/** The output of our `upsertUrjanetUtilityProvider` mutation. */
export type UpsertUrjanetUtilityProviderPayload = {
  __typename?: 'UpsertUrjanetUtilityProviderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recordId?: Maybe<Scalars['Int']>;
};

/** All input for the upsert `UtilityAccount` mutation. */
export type UpsertUtilityAccountInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityAccount` to be upserted by this mutation. */
  utilityAccount: UtilityAccountInput;
};

/** The output of our upsert `UtilityAccount` mutation. */
export type UpsertUtilityAccountPayload = {
  __typename?: 'UpsertUtilityAccountPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityAccount` that was upserted by this mutation. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** An edge for our `UtilityAccount`. May be used by Relay 1. */
  utilityAccountEdge?: Maybe<UtilityAccountsEdge>;
  /** Reads a single `UtilityProvider` that is related to this `UtilityAccount`. */
  utilityProvider?: Maybe<UtilityProvider>;
};

/** The output of our upsert `UtilityAccount` mutation. */
export type UpsertUtilityAccountPayloadUtilityAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
};

/** Where conditions for the upsert `UtilityAccount` mutation. */
export type UpsertUtilityAccountWhere = {
  id?: InputMaybe<Scalars['Int']>;
  integration?: InputMaybe<UtilityIntegration>;
  integrationAccountKey?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `UtilityMeter` mutation. */
export type UpsertUtilityMeterInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityMeter` to be upserted by this mutation. */
  utilityMeter: UtilityMeterInput;
};

/** The output of our upsert `UtilityMeter` mutation. */
export type UpsertUtilityMeterPayload = {
  __typename?: 'UpsertUtilityMeterPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Facility` that is related to this `UtilityMeter`. */
  facility?: Maybe<Facility>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeter`. */
  utilityAccount?: Maybe<UtilityAccount>;
  /** The `UtilityMeter` that was upserted by this mutation. */
  utilityMeter?: Maybe<UtilityMeter>;
  /** An edge for our `UtilityMeter`. May be used by Relay 1. */
  utilityMeterEdge?: Maybe<UtilityMetersEdge>;
};

/** The output of our upsert `UtilityMeter` mutation. */
export type UpsertUtilityMeterPayloadUtilityMeterEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** Where conditions for the upsert `UtilityMeter` mutation. */
export type UpsertUtilityMeterWhere = {
  id?: InputMaybe<Scalars['Int']>;
  integrationMeterKey?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<UtilityMeterServiceType>;
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

/** All input for the upsert `UtilityProvider` mutation. */
export type UpsertUtilityProviderInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UtilityProvider` to be upserted by this mutation. */
  utilityProvider: UtilityProviderInput;
};

/** The output of our upsert `UtilityProvider` mutation. */
export type UpsertUtilityProviderPayload = {
  __typename?: 'UpsertUtilityProviderPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UtilityProvider` that was upserted by this mutation. */
  utilityProvider?: Maybe<UtilityProvider>;
  /** An edge for our `UtilityProvider`. May be used by Relay 1. */
  utilityProviderEdge?: Maybe<UtilityProvidersEdge>;
};

/** The output of our upsert `UtilityProvider` mutation. */
export type UpsertUtilityProviderPayloadUtilityProviderEdgeArgs = {
  orderBy?: InputMaybe<Array<UtilityProvidersOrderBy>>;
};

/** Where conditions for the upsert `UtilityProvider` mutation. */
export type UpsertUtilityProviderWhere = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A user. */
export type User = Node & {
  __typename?: 'User';
  /** Reads and enables pagination through a set of `PlatformAccessToken`. */
  accessTokens: PlatformAccessTokensConnection;
  /** The time the user was created. */
  createdAt: Scalars['Datetime'];
  /** The user’s email address. */
  email?: Maybe<Scalars['String']>;
  /** The user’s first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The primary unique identifier of the user. */
  id: Scalars['String'];
  /** The user’s last name. */
  lastName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Available permissions for the User. */
  permissions?: Maybe<UserPermissions>;
  /** The user’s phone number. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ProjectWatcher`. */
  projectWatchers: ProjectWatchersConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projects: UserProjectsManyToManyConnection;
  /** The time the user was updated . */
  updatedAt: Scalars['Datetime'];
};

/** A user. */
export type UserAccessTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<PlatformAccessTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A user. */
export type UserProjectWatchersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectWatcherCondition>;
  filter?: InputMaybe<ProjectWatcherFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectWatchersOrderBy>>;
};

/** A user. */
export type UserProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type UserAggregates = {
  __typename?: 'UserAggregates';
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UserDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type UserDistinctAggregates = {
  __typename?: 'UserDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of email across the matching connection */
  email?: Maybe<Scalars['JSON']>;
  /** Distinct of firstName across the matching connection */
  firstName?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of lastName across the matching connection */
  lastName?: Maybe<Scalars['JSON']>;
  /** Distinct of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type UserDistinctCountAggregates = {
  __typename?: 'UserDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the permission canEdit */
  canEdit?: InputMaybe<PermissionBooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Permissive capabilities on a User. */
export type UserPermissions = {
  __typename?: 'UserPermissions';
  /** Whether a user can edit the settings of a user. */
  canEdit: Scalars['Boolean'];
};

/** A connection to a list of `Project` values, with data from `ProjectWatcher`. */
export type UserProjectsManyToManyConnection = {
  __typename?: 'UserProjectsManyToManyConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectWatcher`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `Project` values, with data from `ProjectWatcher`. */
export type UserProjectsManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectsGroupBy>;
  having?: InputMaybe<ProjectsHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectWatcher`. */
export type UserProjectsManyToManyEdge = {
  __typename?: 'UserProjectsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

/** A user subscription for a specific event type and action. */
export type UserSubscription = Node & {
  __typename?: 'UserSubscription';
  /** The associated action. */
  action?: Maybe<Scalars['String']>;
  channels: Scalars['JSON'];
  /** The time the subscription was created. */
  createdAt: Scalars['Datetime'];
  /** Reads a single `EventType` that is related to this `UserSubscription`. */
  eventTypeByType?: Maybe<EventType>;
  /** The identifier of the subscription. */
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  scope: Scalars['JSON'];
  /** The type of event. */
  type: Scalars['String'];
  /** The time the subscription was updated. */
  updatedAt: Scalars['Datetime'];
  /** The associated User. */
  userId?: Maybe<Scalars['String']>;
  userType?: Maybe<AccessTokenType>;
};

export enum UserSubscriptionChannel {
  SMS = 'SMS'
}

/** A filter to be used against UserSubscriptionChannel List fields. All fields are combined with a logical ‘and.’ */
export type UserSubscriptionChannelListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<UserSubscriptionChannel>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<UserSubscriptionChannel>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<UserSubscriptionChannel>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<UserSubscriptionChannel>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<UserSubscriptionChannel>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<UserSubscriptionChannel>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<UserSubscriptionChannel>>>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `User` values. */
export type UsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UsersGroupBy>;
  having?: InputMaybe<UsersHavingInput>;
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Grouping methods for `User` for usage during aggregation. */
export enum UsersGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  EMAIL = 'EMAIL',
  EMAIL_DISTINCT = 'EMAIL_DISTINCT',
  FIRST_NAME = 'FIRST_NAME',
  FIRST_NAME_DISTINCT = 'FIRST_NAME_DISTINCT',
  LAST_NAME = 'LAST_NAME',
  LAST_NAME_DISTINCT = 'LAST_NAME_DISTINCT',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PHONE_NUMBER_DISTINCT = 'PHONE_NUMBER_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type UsersHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `User` aggregates. */
export type UsersHavingInput = {
  AND?: InputMaybe<Array<UsersHavingInput>>;
  OR?: InputMaybe<Array<UsersHavingInput>>;
  average?: InputMaybe<UsersHavingAverageInput>;
  distinct?: InputMaybe<UsersHavingDistinctInput>;
  distinctCount?: InputMaybe<UsersHavingDistinctCountInput>;
  max?: InputMaybe<UsersHavingMaxInput>;
  min?: InputMaybe<UsersHavingMinInput>;
  stddevPopulation?: InputMaybe<UsersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UsersHavingStddevSampleInput>;
  sum?: InputMaybe<UsersHavingSumInput>;
  variancePopulation?: InputMaybe<UsersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UsersHavingVarianceSampleInput>;
};

export type UsersHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  EMAIL_ASC = 'EMAIL_ASC',
  EMAIL_DESC = 'EMAIL_DESC',
  FIRST_NAME_ASC = 'FIRST_NAME_ASC',
  FIRST_NAME_DESC = 'FIRST_NAME_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  LAST_NAME_ASC = 'LAST_NAME_ASC',
  LAST_NAME_DESC = 'LAST_NAME_DESC',
  NATURAL = 'NATURAL',
  PHONE_NUMBER_ASC = 'PHONE_NUMBER_ASC',
  PHONE_NUMBER_DESC = 'PHONE_NUMBER_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROJECT_WATCHERS_AVERAGE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_AVERAGE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_AVERAGE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_AVERAGE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_AVERAGE_USER_ID_ASC = 'PROJECT_WATCHERS_AVERAGE_USER_ID_ASC',
  PROJECT_WATCHERS_AVERAGE_USER_ID_DESC = 'PROJECT_WATCHERS_AVERAGE_USER_ID_DESC',
  PROJECT_WATCHERS_COUNT_ASC = 'PROJECT_WATCHERS_COUNT_ASC',
  PROJECT_WATCHERS_COUNT_DESC = 'PROJECT_WATCHERS_COUNT_DESC',
  PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_COUNT_PROJECT_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_COUNT_USER_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_PROJECT_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_PROJECT_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_PROJECT_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_PROJECT_ID_DESC',
  PROJECT_WATCHERS_DISTINCT_USER_ID_ASC = 'PROJECT_WATCHERS_DISTINCT_USER_ID_ASC',
  PROJECT_WATCHERS_DISTINCT_USER_ID_DESC = 'PROJECT_WATCHERS_DISTINCT_USER_ID_DESC',
  PROJECT_WATCHERS_MAX_PROJECT_ID_ASC = 'PROJECT_WATCHERS_MAX_PROJECT_ID_ASC',
  PROJECT_WATCHERS_MAX_PROJECT_ID_DESC = 'PROJECT_WATCHERS_MAX_PROJECT_ID_DESC',
  PROJECT_WATCHERS_MAX_USER_ID_ASC = 'PROJECT_WATCHERS_MAX_USER_ID_ASC',
  PROJECT_WATCHERS_MAX_USER_ID_DESC = 'PROJECT_WATCHERS_MAX_USER_ID_DESC',
  PROJECT_WATCHERS_MIN_PROJECT_ID_ASC = 'PROJECT_WATCHERS_MIN_PROJECT_ID_ASC',
  PROJECT_WATCHERS_MIN_PROJECT_ID_DESC = 'PROJECT_WATCHERS_MIN_PROJECT_ID_DESC',
  PROJECT_WATCHERS_MIN_USER_ID_ASC = 'PROJECT_WATCHERS_MIN_USER_ID_ASC',
  PROJECT_WATCHERS_MIN_USER_ID_DESC = 'PROJECT_WATCHERS_MIN_USER_ID_DESC',
  PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_ASC = 'PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_ASC',
  PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_DESC = 'PROJECT_WATCHERS_STDDEV_POPULATION_PROJECT_ID_DESC',
  PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_ASC = 'PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_ASC',
  PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_DESC = 'PROJECT_WATCHERS_STDDEV_POPULATION_USER_ID_DESC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_ASC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_ASC',
  PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_DESC = 'PROJECT_WATCHERS_STDDEV_SAMPLE_USER_ID_DESC',
  PROJECT_WATCHERS_SUM_PROJECT_ID_ASC = 'PROJECT_WATCHERS_SUM_PROJECT_ID_ASC',
  PROJECT_WATCHERS_SUM_PROJECT_ID_DESC = 'PROJECT_WATCHERS_SUM_PROJECT_ID_DESC',
  PROJECT_WATCHERS_SUM_USER_ID_ASC = 'PROJECT_WATCHERS_SUM_USER_ID_ASC',
  PROJECT_WATCHERS_SUM_USER_ID_DESC = 'PROJECT_WATCHERS_SUM_USER_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_POPULATION_USER_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_ASC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_ASC',
  PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_DESC = 'PROJECT_WATCHERS_VARIANCE_SAMPLE_USER_ID_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

/** A utility account. */
export type UtilityAccount = Node & {
  __typename?: 'UtilityAccount';
  /** The account’s number. */
  accountNumber: Scalars['String'];
  /** Reads and enables pagination through a set of `AccountStatement`. */
  accountStatements: AccountStatementsConnection;
  /** Reads and enables pagination through a set of `AccountStatement`. */
  accountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementId: UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyConnection;
  /** The time the account was created. */
  createdAt: Scalars['Datetime'];
  /** The time the account was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityId: UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityId: UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterUtilityAccountIdAndFacilityId: UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyConnection;
  /** The primary unique identifier of the account. */
  id: Scalars['Int'];
  /** The integration that owns the account. */
  integration: UtilityIntegration;
  /** The identifier in the integration’s system. */
  integrationAccountKey: Scalars['String'];
  /** Extra metadata about the account. */
  metadata: Scalars['JSON'];
  /** Returns a set of year/month combinations that are missing as statements across all meters for a given account. Also provides a list of `UtilityMeters` that correspond to the missing bills for each year/month combiantion. */
  missingStatements: AccountMissingStatementsConnection;
  /** The account’s name. */
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The time the account was updated. */
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UtilityAccountStatementRollup`. */
  utilityAccountStatementRollups: UtilityAccountStatementRollupsConnection;
  /** Reads and enables pagination through a set of `UtilityAccountStatement`. */
  utilityAccountStatements: UtilityAccountStatementsConnection;
  /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
  utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
  utilityMeterStatements: UtilityMeterStatementsConnection;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMeters: UtilityMetersConnection;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterId: UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UtilityMeter`. */
  utilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterId: UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyConnection;
  /** Reads a single `UtilityProvider` that is related to this `UtilityAccount`. */
  utilityProvider?: Maybe<UtilityProvider>;
  /** The account’s provider. */
  utilityProviderId: Scalars['Int'];
};

/** A utility account. */
export type UtilityAccountAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountStatementCondition>;
  filter?: InputMaybe<AccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
};

/** A utility account. */
export type UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<AccountStatementCondition>;
    filter?: InputMaybe<AccountStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
  };

/** A utility account. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

/** A utility account. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

/** A utility account. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

/** A utility account. */
export type UtilityAccountMissingStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  facilityId?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AccountMissingStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A utility account. */
export type UtilityAccountUtilityAccountStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountStatementRollupCondition>;
  filter?: InputMaybe<UtilityAccountStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountStatementRollupsOrderBy>>;
};

/** A utility account. */
export type UtilityAccountUtilityAccountStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountStatementCondition>;
  filter?: InputMaybe<UtilityAccountStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountStatementsOrderBy>>;
};

/** A utility account. */
export type UtilityAccountUtilityMeterStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
  filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
};

/** A utility account. */
export type UtilityAccountUtilityMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementCondition>;
  filter?: InputMaybe<UtilityMeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
};

/** A utility account. */
export type UtilityAccountUtilityMetersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterCondition>;
  filter?: InputMaybe<UtilityMeterFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
};

/** A utility account. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A utility account. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<AccountStatementAggregates>;
    /** A list of edges which contains the `AccountStatement`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<AccountStatementAggregates>>;
    /** A list of `AccountStatement` objects. */
    nodes: Array<AccountStatement>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `AccountStatement` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<AccountStatementsGroupBy>;
    having?: InputMaybe<AccountStatementsHavingInput>;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `AccountStatement` at the end of the edge. */
    node: AccountStatement;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountAccountStatementsByUtilityMeterStatementUtilityAccountIdAndAccountStatementIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type UtilityAccountAggregates = {
  __typename?: 'UtilityAccountAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityAccountAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityAccountDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityAccountDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityAccountMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityAccountMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityAccountStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityAccountStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityAccountSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityAccountVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityAccountVarianceSampleAggregates>;
};

export type UtilityAccountAverageAggregates = {
  __typename?: 'UtilityAccountAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigFloat']>;
};

export enum UtilityAccountChargeCategory {
  CREDITS = 'CREDITS',
  DEMAND = 'DEMAND',
  FEES = 'FEES',
  TAXES = 'TAXES',
  USAGE = 'USAGE'
}

/** A filter to be used against UtilityAccountChargeCategory fields. All fields are combined with a logical ‘and.’ */
export type UtilityAccountChargeCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UtilityAccountChargeCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UtilityAccountChargeCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UtilityAccountChargeCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UtilityAccountChargeCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UtilityAccountChargeCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UtilityAccountChargeCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UtilityAccountChargeCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UtilityAccountChargeCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UtilityAccountChargeCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UtilityAccountChargeCategory>>;
};

/**
 * A condition to be used against `UtilityAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UtilityAccountCondition = {
  /** Checks for equality with the object’s `accountNumber` field. */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integration` field. */
  integration?: InputMaybe<UtilityIntegration>;
  /** Checks for equality with the object’s `integrationAccountKey` field. */
  integrationAccountKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `utilityProviderId` field. */
  utilityProviderId?: InputMaybe<Scalars['Int']>;
};

export type UtilityAccountDistinctAggregates = {
  __typename?: 'UtilityAccountDistinctAggregates';
  /** Distinct of accountNumber across the matching connection */
  accountNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of this field across the matching connection. */
  displayName?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of integration across the matching connection */
  integration?: Maybe<Scalars['JSON']>;
  /** Distinct of integrationAccountKey across the matching connection */
  integrationAccountKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['JSON']>;
};

export type UtilityAccountDistinctCountAggregates = {
  __typename?: 'UtilityAccountDistinctCountAggregates';
  /** Distinct count of accountNumber across the matching connection */
  accountNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  displayName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integration across the matching connection */
  integration?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationAccountKey across the matching connection */
  integrationAccountKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementRollupUtilityAccountIdAndFacilityIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountFacilitiesByUtilityMeterStatementUtilityAccountIdAndFacilityIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeter`. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeter`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeter`. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeter`. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeter`. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeter`. */
    utilityMeters: UtilityMetersConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeter`. */
export type UtilityAccountFacilitiesByUtilityMeterUtilityAccountIdAndFacilityIdManyToManyEdgeUtilityMetersArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterCondition>;
    filter?: InputMaybe<UtilityMeterFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMetersOrderBy>>;
  };

/** A filter to be used against `UtilityAccount` object types. All fields are combined with a logical ‘and.’ */
export type UtilityAccountFilter = {
  /** Filter by the object’s `accountNumber` field. */
  accountNumber?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityAccountFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `integration` field. */
  integration?: InputMaybe<UtilityIntegrationFilter>;
  /** Filter by the object’s `integrationAccountKey` field. */
  integrationAccountKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityAccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityAccountFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `utilityProviderId` field. */
  utilityProviderId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UtilityAccount` */
export type UtilityAccountInput = {
  /** The account’s number. */
  accountNumber: Scalars['String'];
  /** The primary unique identifier of the account. */
  id?: InputMaybe<Scalars['Int']>;
  /** The integration that owns the account. */
  integration: UtilityIntegration;
  /** The identifier in the integration’s system. */
  integrationAccountKey?: InputMaybe<Scalars['String']>;
  /** Extra metadata about the account. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** The account’s name. */
  name?: InputMaybe<Scalars['String']>;
  /** Mutates a `UtilityProvider` as part of the payload */
  utilityProvider?: InputMaybe<UtilityProviderInput>;
  /** The account’s provider. */
  utilityProviderId?: InputMaybe<Scalars['Int']>;
};

export type UtilityAccountMaxAggregates = {
  __typename?: 'UtilityAccountMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountMinAggregates = {
  __typename?: 'UtilityAccountMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `UtilityAccount`. Fields that are set will be updated. */
export type UtilityAccountPatch = {
  /** Extra metadata about the account. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** The account’s name. */
  name?: InputMaybe<Scalars['String']>;
  /** The account’s provider. */
  utilityProviderId?: InputMaybe<Scalars['Int']>;
};

export type UtilityAccountStatement = Node & {
  __typename?: 'UtilityAccountStatement';
  accountLabel?: Maybe<Scalars['String']>;
  /** Reads a single `AccountStatement` that is related to this `UtilityAccountStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId: Scalars['Int'];
  billArrivalDate?: Maybe<Scalars['Datetime']>;
  currencyCode?: Maybe<Scalars['String']>;
  intervalEnd?: Maybe<Scalars['Date']>;
  intervalStart?: Maybe<Scalars['Date']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityAccountStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementAggregates = {
  __typename?: 'UtilityAccountStatementAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityAccountStatementAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityAccountStatementDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityAccountStatementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityAccountStatementMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityAccountStatementMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityAccountStatementStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityAccountStatementStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityAccountStatementSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityAccountStatementVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityAccountStatementVarianceSampleAggregates>;
};

export type UtilityAccountStatementAverageAggregates = {
  __typename?: 'UtilityAccountStatementAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UtilityAccountStatement` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UtilityAccountStatementCondition = {
  /** Checks for equality with the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statementYear` field. */
  statementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type UtilityAccountStatementDistinctAggregates = {
  __typename?: 'UtilityAccountStatementDistinctAggregates';
  /** Distinct of accountLabel across the matching connection */
  accountLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['JSON']>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['JSON']>;
  /** Distinct of provider across the matching connection */
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
};

export type UtilityAccountStatementDistinctCountAggregates = {
  __typename?: 'UtilityAccountStatementDistinctCountAggregates';
  /** Distinct count of accountLabel across the matching connection */
  accountLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UtilityAccountStatement` object types. All fields are combined with a logical ‘and.’ */
export type UtilityAccountStatementFilter = {
  /** Filter by the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityAccountStatementFilter>>;
  /** Filter by the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityAccountStatementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityAccountStatementFilter>>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
};

export type UtilityAccountStatementMaxAggregates = {
  __typename?: 'UtilityAccountStatementMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementMinAggregates = {
  __typename?: 'UtilityAccountStatementMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementRollup = Node & {
  __typename?: 'UtilityAccountStatementRollup';
  accountLabel?: Maybe<Scalars['String']>;
  /** Reads a single `AccountStatement` that is related to this `UtilityAccountStatementRollup`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId: Scalars['Int'];
  billArrivalDate?: Maybe<Scalars['Datetime']>;
  billCost?: Maybe<Scalars['Float']>;
  billDemandCost?: Maybe<Scalars['Float']>;
  billUsageCost?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['Float']>;
  intervalEnd?: Maybe<Scalars['Date']>;
  intervalStart?: Maybe<Scalars['Date']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  percentChange?: Maybe<Scalars['Float']>;
  provider?: Maybe<Scalars['String']>;
  serviceTypes?: Maybe<Array<Maybe<UtilityMeterServiceType>>>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Float']>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityAccountStatementRollup`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementRollupAggregates = {
  __typename?: 'UtilityAccountStatementRollupAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityAccountStatementRollupAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityAccountStatementRollupDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityAccountStatementRollupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityAccountStatementRollupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityAccountStatementRollupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityAccountStatementRollupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityAccountStatementRollupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityAccountStatementRollupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityAccountStatementRollupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityAccountStatementRollupVarianceSampleAggregates>;
};

export type UtilityAccountStatementRollupAverageAggregates = {
  __typename?: 'UtilityAccountStatementRollupAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Mean average of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Mean average of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Mean average of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Mean average of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Mean average of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Mean average of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UtilityAccountStatementRollup` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UtilityAccountStatementRollupCondition = {
  /** Checks for equality with the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `billCost` field. */
  billCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billDemandCost` field. */
  billDemandCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billUsageCost` field. */
  billUsageCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `credits` field. */
  credits?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fees` field. */
  fees?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `percentChange` field. */
  percentChange?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statementYear` field. */
  statementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `taxes` field. */
  taxes?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type UtilityAccountStatementRollupDistinctAggregates = {
  __typename?: 'UtilityAccountStatementRollupDistinctAggregates';
  /** Distinct of accountLabel across the matching connection */
  accountLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['JSON']>;
  /** Distinct of billCost across the matching connection */
  billCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of credits across the matching connection */
  credits?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of fees across the matching connection */
  fees?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['JSON']>;
  /** Distinct of percentChange across the matching connection */
  percentChange?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of provider across the matching connection */
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of this field across the matching connection. */
  serviceTypes?: Maybe<Scalars['JSON']>;
  /** Distinct of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of taxes across the matching connection */
  taxes?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
};

export type UtilityAccountStatementRollupDistinctCountAggregates = {
  __typename?: 'UtilityAccountStatementRollupDistinctCountAggregates';
  /** Distinct count of accountLabel across the matching connection */
  accountLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billCost across the matching connection */
  billCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of credits across the matching connection */
  credits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fees across the matching connection */
  fees?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalEnd across the matching connection */
  intervalEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of intervalStart across the matching connection */
  intervalStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  serviceTypes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of taxes across the matching connection */
  taxes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UtilityAccountStatementRollup` object types. All fields are combined with a logical ‘and.’ */
export type UtilityAccountStatementRollupFilter = {
  /** Filter by the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityAccountStatementRollupFilter>>;
  /** Filter by the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `billCost` field. */
  billCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billDemandCost` field. */
  billDemandCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billUsageCost` field. */
  billUsageCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `credits` field. */
  credits?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fees` field. */
  fees?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `intervalEnd` field. */
  intervalEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `intervalStart` field. */
  intervalStart?: InputMaybe<DateFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityAccountStatementRollupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityAccountStatementRollupFilter>>;
  /** Filter by the object’s `percentChange` field. */
  percentChange?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `serviceTypes` field. */
  serviceTypes?: InputMaybe<UtilityMeterServiceTypeListFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxes` field. */
  taxes?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
};

export type UtilityAccountStatementRollupMaxAggregates = {
  __typename?: 'UtilityAccountStatementRollupMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Maximum of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Maximum of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Maximum of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Maximum of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Maximum of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Maximum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementRollupMinAggregates = {
  __typename?: 'UtilityAccountStatementRollupMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Minimum of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Minimum of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Minimum of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Minimum of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Minimum of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Minimum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityAccountStatementRollupStddevPopulationAggregates = {
  __typename?: 'UtilityAccountStatementRollupStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Population standard deviation of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Population standard deviation of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Population standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementRollupStddevSampleAggregates = {
  __typename?: 'UtilityAccountStatementRollupStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementRollupSumAggregates = {
  __typename?: 'UtilityAccountStatementRollupSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /** Sum of billCost across the matching connection */
  billCost: Scalars['Float'];
  /** Sum of billDemandCost across the matching connection */
  billDemandCost: Scalars['Float'];
  /** Sum of billUsageCost across the matching connection */
  billUsageCost: Scalars['Float'];
  /** Sum of credits across the matching connection */
  credits: Scalars['Float'];
  /** Sum of fees across the matching connection */
  fees: Scalars['Float'];
  /** Sum of percentChange across the matching connection */
  percentChange: Scalars['Float'];
  /** Sum of statementMonth across the matching connection */
  statementMonth: Scalars['BigInt'];
  /** Sum of statementYear across the matching connection */
  statementYear: Scalars['BigInt'];
  /** Sum of taxes across the matching connection */
  taxes: Scalars['Float'];
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
};

export type UtilityAccountStatementRollupVariancePopulationAggregates = {
  __typename?: 'UtilityAccountStatementRollupVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Population variance of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Population variance of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Population variance of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Population variance of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Population variance of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Population variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementRollupVarianceSampleAggregates = {
  __typename?: 'UtilityAccountStatementRollupVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample variance of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Sample variance of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Sample variance of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Sample variance of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Sample variance of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Sample variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityAccountStatementRollup` values. */
export type UtilityAccountStatementRollupsConnection = {
  __typename?: 'UtilityAccountStatementRollupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityAccountStatementRollupAggregates>;
  /** A list of edges which contains the `UtilityAccountStatementRollup` and cursor to aid in pagination. */
  edges: Array<UtilityAccountStatementRollupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityAccountStatementRollupAggregates>>;
  /** A list of `UtilityAccountStatementRollup` objects. */
  nodes: Array<UtilityAccountStatementRollup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityAccountStatementRollup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityAccountStatementRollup` values. */
export type UtilityAccountStatementRollupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityAccountStatementRollupsGroupBy>;
  having?: InputMaybe<UtilityAccountStatementRollupsHavingInput>;
};

/** A `UtilityAccountStatementRollup` edge in the connection. */
export type UtilityAccountStatementRollupsEdge = {
  __typename?: 'UtilityAccountStatementRollupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityAccountStatementRollup` at the end of the edge. */
  node: UtilityAccountStatementRollup;
};

/** Grouping methods for `UtilityAccountStatementRollup` for usage during aggregation. */
export enum UtilityAccountStatementRollupsGroupBy {
  ACCOUNT_LABEL = 'ACCOUNT_LABEL',
  ACCOUNT_LABEL_DISTINCT = 'ACCOUNT_LABEL_DISTINCT',
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  BILL_ARRIVAL_DATE = 'BILL_ARRIVAL_DATE',
  BILL_ARRIVAL_DATE_DISTINCT = 'BILL_ARRIVAL_DATE_DISTINCT',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR',
  BILL_COST = 'BILL_COST',
  BILL_COST_DISTINCT = 'BILL_COST_DISTINCT',
  BILL_DEMAND_COST = 'BILL_DEMAND_COST',
  BILL_DEMAND_COST_DISTINCT = 'BILL_DEMAND_COST_DISTINCT',
  BILL_USAGE_COST = 'BILL_USAGE_COST',
  BILL_USAGE_COST_DISTINCT = 'BILL_USAGE_COST_DISTINCT',
  CREDITS = 'CREDITS',
  CREDITS_DISTINCT = 'CREDITS_DISTINCT',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  FEES = 'FEES',
  FEES_DISTINCT = 'FEES_DISTINCT',
  INTERVAL_END = 'INTERVAL_END',
  INTERVAL_END_DISTINCT = 'INTERVAL_END_DISTINCT',
  INTERVAL_START = 'INTERVAL_START',
  INTERVAL_START_DISTINCT = 'INTERVAL_START_DISTINCT',
  PERCENT_CHANGE = 'PERCENT_CHANGE',
  PERCENT_CHANGE_DISTINCT = 'PERCENT_CHANGE_DISTINCT',
  PROVIDER = 'PROVIDER',
  PROVIDER_DISTINCT = 'PROVIDER_DISTINCT',
  STATEMENT_MONTH = 'STATEMENT_MONTH',
  STATEMENT_MONTH_DISTINCT = 'STATEMENT_MONTH_DISTINCT',
  STATEMENT_YEAR = 'STATEMENT_YEAR',
  STATEMENT_YEAR_DISTINCT = 'STATEMENT_YEAR_DISTINCT',
  TAXES = 'TAXES',
  TAXES_DISTINCT = 'TAXES_DISTINCT',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT'
}

export type UtilityAccountStatementRollupsHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UtilityAccountStatementRollup` aggregates. */
export type UtilityAccountStatementRollupsHavingInput = {
  AND?: InputMaybe<Array<UtilityAccountStatementRollupsHavingInput>>;
  OR?: InputMaybe<Array<UtilityAccountStatementRollupsHavingInput>>;
  average?: InputMaybe<UtilityAccountStatementRollupsHavingAverageInput>;
  distinct?: InputMaybe<UtilityAccountStatementRollupsHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityAccountStatementRollupsHavingDistinctCountInput>;
  max?: InputMaybe<UtilityAccountStatementRollupsHavingMaxInput>;
  min?: InputMaybe<UtilityAccountStatementRollupsHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityAccountStatementRollupsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityAccountStatementRollupsHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityAccountStatementRollupsHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityAccountStatementRollupsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityAccountStatementRollupsHavingVarianceSampleInput>;
};

export type UtilityAccountStatementRollupsHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementRollupsHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `UtilityAccountStatementRollup`. */
export enum UtilityAccountStatementRollupsOrderBy {
  ACCOUNT_LABEL_ASC = 'ACCOUNT_LABEL_ASC',
  ACCOUNT_LABEL_DESC = 'ACCOUNT_LABEL_DESC',
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  BILL_ARRIVAL_DATE_ASC = 'BILL_ARRIVAL_DATE_ASC',
  BILL_ARRIVAL_DATE_DESC = 'BILL_ARRIVAL_DATE_DESC',
  BILL_COST_ASC = 'BILL_COST_ASC',
  BILL_COST_DESC = 'BILL_COST_DESC',
  BILL_DEMAND_COST_ASC = 'BILL_DEMAND_COST_ASC',
  BILL_DEMAND_COST_DESC = 'BILL_DEMAND_COST_DESC',
  BILL_USAGE_COST_ASC = 'BILL_USAGE_COST_ASC',
  BILL_USAGE_COST_DESC = 'BILL_USAGE_COST_DESC',
  CREDITS_ASC = 'CREDITS_ASC',
  CREDITS_DESC = 'CREDITS_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  FEES_ASC = 'FEES_ASC',
  FEES_DESC = 'FEES_DESC',
  INTERVAL_END_ASC = 'INTERVAL_END_ASC',
  INTERVAL_END_DESC = 'INTERVAL_END_DESC',
  INTERVAL_START_ASC = 'INTERVAL_START_ASC',
  INTERVAL_START_DESC = 'INTERVAL_START_DESC',
  NATURAL = 'NATURAL',
  PERCENT_CHANGE_ASC = 'PERCENT_CHANGE_ASC',
  PERCENT_CHANGE_DESC = 'PERCENT_CHANGE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROVIDER_ASC = 'PROVIDER_ASC',
  PROVIDER_DESC = 'PROVIDER_DESC',
  STATEMENT_MONTH_ASC = 'STATEMENT_MONTH_ASC',
  STATEMENT_MONTH_DESC = 'STATEMENT_MONTH_DESC',
  STATEMENT_YEAR_ASC = 'STATEMENT_YEAR_ASC',
  STATEMENT_YEAR_DESC = 'STATEMENT_YEAR_DESC',
  TAXES_ASC = 'TAXES_ASC',
  TAXES_DESC = 'TAXES_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC'
}

export type UtilityAccountStatementStddevPopulationAggregates = {
  __typename?: 'UtilityAccountStatementStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementStddevSampleAggregates = {
  __typename?: 'UtilityAccountStatementStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementSumAggregates = {
  __typename?: 'UtilityAccountStatementSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /** Sum of statementMonth across the matching connection */
  statementMonth: Scalars['BigInt'];
  /** Sum of statementYear across the matching connection */
  statementYear: Scalars['BigInt'];
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
};

export type UtilityAccountStatementVariancePopulationAggregates = {
  __typename?: 'UtilityAccountStatementVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStatementVarianceSampleAggregates = {
  __typename?: 'UtilityAccountStatementVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityAccountStatement` values. */
export type UtilityAccountStatementsConnection = {
  __typename?: 'UtilityAccountStatementsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityAccountStatementAggregates>;
  /** A list of edges which contains the `UtilityAccountStatement` and cursor to aid in pagination. */
  edges: Array<UtilityAccountStatementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityAccountStatementAggregates>>;
  /** A list of `UtilityAccountStatement` objects. */
  nodes: Array<UtilityAccountStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityAccountStatement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityAccountStatement` values. */
export type UtilityAccountStatementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityAccountStatementsGroupBy>;
  having?: InputMaybe<UtilityAccountStatementsHavingInput>;
};

/** A `UtilityAccountStatement` edge in the connection. */
export type UtilityAccountStatementsEdge = {
  __typename?: 'UtilityAccountStatementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityAccountStatement` at the end of the edge. */
  node: UtilityAccountStatement;
};

/** Grouping methods for `UtilityAccountStatement` for usage during aggregation. */
export enum UtilityAccountStatementsGroupBy {
  ACCOUNT_LABEL = 'ACCOUNT_LABEL',
  ACCOUNT_LABEL_DISTINCT = 'ACCOUNT_LABEL_DISTINCT',
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  BILL_ARRIVAL_DATE = 'BILL_ARRIVAL_DATE',
  BILL_ARRIVAL_DATE_DISTINCT = 'BILL_ARRIVAL_DATE_DISTINCT',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  INTERVAL_END = 'INTERVAL_END',
  INTERVAL_END_DISTINCT = 'INTERVAL_END_DISTINCT',
  INTERVAL_START = 'INTERVAL_START',
  INTERVAL_START_DISTINCT = 'INTERVAL_START_DISTINCT',
  PROVIDER = 'PROVIDER',
  PROVIDER_DISTINCT = 'PROVIDER_DISTINCT',
  STATEMENT_MONTH = 'STATEMENT_MONTH',
  STATEMENT_MONTH_DISTINCT = 'STATEMENT_MONTH_DISTINCT',
  STATEMENT_YEAR = 'STATEMENT_YEAR',
  STATEMENT_YEAR_DISTINCT = 'STATEMENT_YEAR_DISTINCT',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT'
}

export type UtilityAccountStatementsHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UtilityAccountStatement` aggregates. */
export type UtilityAccountStatementsHavingInput = {
  AND?: InputMaybe<Array<UtilityAccountStatementsHavingInput>>;
  OR?: InputMaybe<Array<UtilityAccountStatementsHavingInput>>;
  average?: InputMaybe<UtilityAccountStatementsHavingAverageInput>;
  distinct?: InputMaybe<UtilityAccountStatementsHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityAccountStatementsHavingDistinctCountInput>;
  max?: InputMaybe<UtilityAccountStatementsHavingMaxInput>;
  min?: InputMaybe<UtilityAccountStatementsHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityAccountStatementsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityAccountStatementsHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityAccountStatementsHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityAccountStatementsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityAccountStatementsHavingVarianceSampleInput>;
};

export type UtilityAccountStatementsHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountStatementsHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  intervalEnd?: InputMaybe<HavingDatetimeFilter>;
  intervalStart?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `UtilityAccountStatement`. */
export enum UtilityAccountStatementsOrderBy {
  ACCOUNT_LABEL_ASC = 'ACCOUNT_LABEL_ASC',
  ACCOUNT_LABEL_DESC = 'ACCOUNT_LABEL_DESC',
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  BILL_ARRIVAL_DATE_ASC = 'BILL_ARRIVAL_DATE_ASC',
  BILL_ARRIVAL_DATE_DESC = 'BILL_ARRIVAL_DATE_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  INTERVAL_END_ASC = 'INTERVAL_END_ASC',
  INTERVAL_END_DESC = 'INTERVAL_END_DESC',
  INTERVAL_START_ASC = 'INTERVAL_START_ASC',
  INTERVAL_START_DESC = 'INTERVAL_START_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROVIDER_ASC = 'PROVIDER_ASC',
  PROVIDER_DESC = 'PROVIDER_DESC',
  STATEMENT_MONTH_ASC = 'STATEMENT_MONTH_ASC',
  STATEMENT_MONTH_DESC = 'STATEMENT_MONTH_DESC',
  STATEMENT_YEAR_ASC = 'STATEMENT_YEAR_ASC',
  STATEMENT_YEAR_DESC = 'STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC'
}

export type UtilityAccountStddevPopulationAggregates = {
  __typename?: 'UtilityAccountStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountStddevSampleAggregates = {
  __typename?: 'UtilityAccountStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountSumAggregates = {
  __typename?: 'UtilityAccountSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of utilityProviderId across the matching connection */
  utilityProviderId: Scalars['BigInt'];
};

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementRollupUtilityAccountIdAndUtilityMeterIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyConnection =
  {
    __typename?: 'UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityMeterAggregates>;
    /** A list of edges which contains the `UtilityMeter`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
    /** A list of `UtilityMeter` objects. */
    nodes: Array<UtilityMeter>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityMeter` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityMeter` values, with data from `UtilityMeterStatement`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityMetersGroupBy>;
    having?: InputMaybe<UtilityMetersHavingInput>;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyEdge =
  {
    __typename?: 'UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityMeter` at the end of the edge. */
    node: UtilityMeter;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityMeter` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityAccountUtilityMetersByUtilityMeterStatementUtilityAccountIdAndUtilityMeterIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type UtilityAccountVariancePopulationAggregates = {
  __typename?: 'UtilityAccountVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityAccountVarianceSampleAggregates = {
  __typename?: 'UtilityAccountVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of utilityProviderId across the matching connection */
  utilityProviderId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityAccount` values. */
export type UtilityAccountsConnection = {
  __typename?: 'UtilityAccountsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityAccountAggregates>;
  /** A list of edges which contains the `UtilityAccount` and cursor to aid in pagination. */
  edges: Array<UtilityAccountsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
  /** A list of `UtilityAccount` objects. */
  nodes: Array<UtilityAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityAccount` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityAccount` values. */
export type UtilityAccountsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityAccountsGroupBy>;
  having?: InputMaybe<UtilityAccountsHavingInput>;
};

/** A `UtilityAccount` edge in the connection. */
export type UtilityAccountsEdge = {
  __typename?: 'UtilityAccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityAccount` at the end of the edge. */
  node: UtilityAccount;
};

/** Grouping methods for `UtilityAccount` for usage during aggregation. */
export enum UtilityAccountsGroupBy {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  ACCOUNT_NUMBER_DISTINCT = 'ACCOUNT_NUMBER_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  INTEGRATION = 'INTEGRATION',
  INTEGRATION_ACCOUNT_KEY = 'INTEGRATION_ACCOUNT_KEY',
  INTEGRATION_ACCOUNT_KEY_DISTINCT = 'INTEGRATION_ACCOUNT_KEY_DISTINCT',
  INTEGRATION_DISTINCT = 'INTEGRATION_DISTINCT',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UTILITY_PROVIDER_ID = 'UTILITY_PROVIDER_ID',
  UTILITY_PROVIDER_ID_DISTINCT = 'UTILITY_PROVIDER_ID_DISTINCT'
}

export type UtilityAccountsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UtilityAccount` aggregates. */
export type UtilityAccountsHavingInput = {
  AND?: InputMaybe<Array<UtilityAccountsHavingInput>>;
  OR?: InputMaybe<Array<UtilityAccountsHavingInput>>;
  average?: InputMaybe<UtilityAccountsHavingAverageInput>;
  distinct?: InputMaybe<UtilityAccountsHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityAccountsHavingDistinctCountInput>;
  max?: InputMaybe<UtilityAccountsHavingMaxInput>;
  min?: InputMaybe<UtilityAccountsHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityAccountsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityAccountsHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityAccountsHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityAccountsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityAccountsHavingVarianceSampleInput>;
};

export type UtilityAccountsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

export type UtilityAccountsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityProviderId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `UtilityAccount`. */
export enum UtilityAccountsOrderBy {
  ACCOUNT_NUMBER_ASC = 'ACCOUNT_NUMBER_ASC',
  ACCOUNT_NUMBER_DESC = 'ACCOUNT_NUMBER_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_ID_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_ID_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_ID_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_ID_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_METADATA_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_METADATA_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_METADATA_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_METADATA_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_COUNT_ASC = 'ACCOUNT_STATEMENTS_COUNT_ASC',
  ACCOUNT_STATEMENTS_COUNT_DESC = 'ACCOUNT_STATEMENTS_COUNT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_ID_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_ID_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_ID_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_ID_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_METADATA_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_METADATA_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_METADATA_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_METADATA_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_ID_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_ID_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_ID_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_ID_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_METADATA_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_METADATA_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_METADATA_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_METADATA_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_MAX_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_MAX_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_MAX_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_MAX_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MAX_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_MAX_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_MAX_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_MAX_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_MAX_ID_ASC = 'ACCOUNT_STATEMENTS_MAX_ID_ASC',
  ACCOUNT_STATEMENTS_MAX_ID_DESC = 'ACCOUNT_STATEMENTS_MAX_ID_DESC',
  ACCOUNT_STATEMENTS_MAX_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_MAX_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_MAX_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_MAX_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_MAX_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_MAX_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_MAX_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_MAX_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_MAX_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_MAX_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_MAX_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_MAX_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_MAX_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_MAX_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_MAX_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_MAX_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_MAX_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_MAX_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_MAX_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_MAX_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_MAX_METADATA_ASC = 'ACCOUNT_STATEMENTS_MAX_METADATA_ASC',
  ACCOUNT_STATEMENTS_MAX_METADATA_DESC = 'ACCOUNT_STATEMENTS_MAX_METADATA_DESC',
  ACCOUNT_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MAX_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_MAX_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_MAX_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_MAX_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_MAX_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_MAX_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_MAX_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_MAX_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_MIN_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_MIN_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_MIN_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_MIN_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MIN_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_MIN_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_MIN_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_MIN_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_MIN_ID_ASC = 'ACCOUNT_STATEMENTS_MIN_ID_ASC',
  ACCOUNT_STATEMENTS_MIN_ID_DESC = 'ACCOUNT_STATEMENTS_MIN_ID_DESC',
  ACCOUNT_STATEMENTS_MIN_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_MIN_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_MIN_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_MIN_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_MIN_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_MIN_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_MIN_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_MIN_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_MIN_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_MIN_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_MIN_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_MIN_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_MIN_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_MIN_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_MIN_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_MIN_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_MIN_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_MIN_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_MIN_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_MIN_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_MIN_METADATA_ASC = 'ACCOUNT_STATEMENTS_MIN_METADATA_ASC',
  ACCOUNT_STATEMENTS_MIN_METADATA_DESC = 'ACCOUNT_STATEMENTS_MIN_METADATA_DESC',
  ACCOUNT_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MIN_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_MIN_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_MIN_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_MIN_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_MIN_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_MIN_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_MIN_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_MIN_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_ID_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_ID_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_ID_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_ID_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_METADATA_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_METADATA_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_METADATA_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_METADATA_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ID_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ID_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ID_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ID_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_SUM_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_SUM_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_SUM_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_SUM_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_SUM_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_SUM_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_SUM_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_SUM_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_SUM_ID_ASC = 'ACCOUNT_STATEMENTS_SUM_ID_ASC',
  ACCOUNT_STATEMENTS_SUM_ID_DESC = 'ACCOUNT_STATEMENTS_SUM_ID_DESC',
  ACCOUNT_STATEMENTS_SUM_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_SUM_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_SUM_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_SUM_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_SUM_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_SUM_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_SUM_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_SUM_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_SUM_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_SUM_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_SUM_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_SUM_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_SUM_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_SUM_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_SUM_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_SUM_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_SUM_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_SUM_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_SUM_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_SUM_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_SUM_METADATA_ASC = 'ACCOUNT_STATEMENTS_SUM_METADATA_ASC',
  ACCOUNT_STATEMENTS_SUM_METADATA_DESC = 'ACCOUNT_STATEMENTS_SUM_METADATA_DESC',
  ACCOUNT_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_SUM_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_SUM_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_SUM_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_SUM_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_SUM_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_SUM_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_SUM_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_SUM_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ID_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ID_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ID_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ID_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ID_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ID_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ID_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ID_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTEGRATION_ACCOUNT_KEY_ASC = 'INTEGRATION_ACCOUNT_KEY_ASC',
  INTEGRATION_ACCOUNT_KEY_DESC = 'INTEGRATION_ACCOUNT_KEY_DESC',
  INTEGRATION_ASC = 'INTEGRATION_ASC',
  INTEGRATION_DESC = 'INTEGRATION_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_COUNT_ASC = 'UTILITY_ACCOUNT_STATEMENTS_COUNT_ASC',
  UTILITY_ACCOUNT_STATEMENTS_COUNT_DESC = 'UTILITY_ACCOUNT_STATEMENTS_COUNT_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_COUNT_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_COUNT_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_COUNT_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_COUNT_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_AVERAGE_ACTIVE_END_ASC = 'UTILITY_METERS_AVERAGE_ACTIVE_END_ASC',
  UTILITY_METERS_AVERAGE_ACTIVE_END_DESC = 'UTILITY_METERS_AVERAGE_ACTIVE_END_DESC',
  UTILITY_METERS_AVERAGE_ACTIVE_START_ASC = 'UTILITY_METERS_AVERAGE_ACTIVE_START_ASC',
  UTILITY_METERS_AVERAGE_ACTIVE_START_DESC = 'UTILITY_METERS_AVERAGE_ACTIVE_START_DESC',
  UTILITY_METERS_AVERAGE_CREATED_AT_ASC = 'UTILITY_METERS_AVERAGE_CREATED_AT_ASC',
  UTILITY_METERS_AVERAGE_CREATED_AT_DESC = 'UTILITY_METERS_AVERAGE_CREATED_AT_DESC',
  UTILITY_METERS_AVERAGE_DELETED_AT_ASC = 'UTILITY_METERS_AVERAGE_DELETED_AT_ASC',
  UTILITY_METERS_AVERAGE_DELETED_AT_DESC = 'UTILITY_METERS_AVERAGE_DELETED_AT_DESC',
  UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METERS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METERS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METERS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METERS_AVERAGE_ID_ASC = 'UTILITY_METERS_AVERAGE_ID_ASC',
  UTILITY_METERS_AVERAGE_ID_DESC = 'UTILITY_METERS_AVERAGE_ID_DESC',
  UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_AVERAGE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_AVERAGE_METADATA_ASC = 'UTILITY_METERS_AVERAGE_METADATA_ASC',
  UTILITY_METERS_AVERAGE_METADATA_DESC = 'UTILITY_METERS_AVERAGE_METADATA_DESC',
  UTILITY_METERS_AVERAGE_METER_NUMBER_ASC = 'UTILITY_METERS_AVERAGE_METER_NUMBER_ASC',
  UTILITY_METERS_AVERAGE_METER_NUMBER_DESC = 'UTILITY_METERS_AVERAGE_METER_NUMBER_DESC',
  UTILITY_METERS_AVERAGE_NAME_ASC = 'UTILITY_METERS_AVERAGE_NAME_ASC',
  UTILITY_METERS_AVERAGE_NAME_DESC = 'UTILITY_METERS_AVERAGE_NAME_DESC',
  UTILITY_METERS_AVERAGE_OVERRIDE_AT_ASC = 'UTILITY_METERS_AVERAGE_OVERRIDE_AT_ASC',
  UTILITY_METERS_AVERAGE_OVERRIDE_AT_DESC = 'UTILITY_METERS_AVERAGE_OVERRIDE_AT_DESC',
  UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_AVERAGE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METERS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METERS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METERS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METERS_AVERAGE_UPDATED_AT_ASC = 'UTILITY_METERS_AVERAGE_UPDATED_AT_ASC',
  UTILITY_METERS_AVERAGE_UPDATED_AT_DESC = 'UTILITY_METERS_AVERAGE_UPDATED_AT_DESC',
  UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_COUNT_ASC = 'UTILITY_METERS_COUNT_ASC',
  UTILITY_METERS_COUNT_DESC = 'UTILITY_METERS_COUNT_DESC',
  UTILITY_METERS_DISTINCT_ACTIVE_END_ASC = 'UTILITY_METERS_DISTINCT_ACTIVE_END_ASC',
  UTILITY_METERS_DISTINCT_ACTIVE_END_DESC = 'UTILITY_METERS_DISTINCT_ACTIVE_END_DESC',
  UTILITY_METERS_DISTINCT_ACTIVE_START_ASC = 'UTILITY_METERS_DISTINCT_ACTIVE_START_ASC',
  UTILITY_METERS_DISTINCT_ACTIVE_START_DESC = 'UTILITY_METERS_DISTINCT_ACTIVE_START_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_END_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ACTIVE_START_DESC',
  UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_CREATED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_DELETED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METERS_DISTINCT_COUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_ID_DESC',
  UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_DISTINCT_COUNT_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_DISTINCT_COUNT_METADATA_ASC = 'UTILITY_METERS_DISTINCT_COUNT_METADATA_ASC',
  UTILITY_METERS_DISTINCT_COUNT_METADATA_DESC = 'UTILITY_METERS_DISTINCT_COUNT_METADATA_DESC',
  UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_COUNT_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_COUNT_NAME_ASC = 'UTILITY_METERS_DISTINCT_COUNT_NAME_ASC',
  UTILITY_METERS_DISTINCT_COUNT_NAME_DESC = 'UTILITY_METERS_DISTINCT_COUNT_NAME_DESC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_COUNT_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METERS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_ASC = 'UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_DESC = 'UTILITY_METERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_DISTINCT_CREATED_AT_ASC = 'UTILITY_METERS_DISTINCT_CREATED_AT_ASC',
  UTILITY_METERS_DISTINCT_CREATED_AT_DESC = 'UTILITY_METERS_DISTINCT_CREATED_AT_DESC',
  UTILITY_METERS_DISTINCT_DELETED_AT_ASC = 'UTILITY_METERS_DISTINCT_DELETED_AT_ASC',
  UTILITY_METERS_DISTINCT_DELETED_AT_DESC = 'UTILITY_METERS_DISTINCT_DELETED_AT_DESC',
  UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METERS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METERS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METERS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METERS_DISTINCT_ID_ASC = 'UTILITY_METERS_DISTINCT_ID_ASC',
  UTILITY_METERS_DISTINCT_ID_DESC = 'UTILITY_METERS_DISTINCT_ID_DESC',
  UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_DISTINCT_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_DISTINCT_METADATA_ASC = 'UTILITY_METERS_DISTINCT_METADATA_ASC',
  UTILITY_METERS_DISTINCT_METADATA_DESC = 'UTILITY_METERS_DISTINCT_METADATA_DESC',
  UTILITY_METERS_DISTINCT_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_NAME_ASC = 'UTILITY_METERS_DISTINCT_NAME_ASC',
  UTILITY_METERS_DISTINCT_NAME_DESC = 'UTILITY_METERS_DISTINCT_NAME_DESC',
  UTILITY_METERS_DISTINCT_OVERRIDE_AT_ASC = 'UTILITY_METERS_DISTINCT_OVERRIDE_AT_ASC',
  UTILITY_METERS_DISTINCT_OVERRIDE_AT_DESC = 'UTILITY_METERS_DISTINCT_OVERRIDE_AT_DESC',
  UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_DISTINCT_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METERS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METERS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METERS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METERS_DISTINCT_UPDATED_AT_ASC = 'UTILITY_METERS_DISTINCT_UPDATED_AT_ASC',
  UTILITY_METERS_DISTINCT_UPDATED_AT_DESC = 'UTILITY_METERS_DISTINCT_UPDATED_AT_DESC',
  UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_MAX_ACTIVE_END_ASC = 'UTILITY_METERS_MAX_ACTIVE_END_ASC',
  UTILITY_METERS_MAX_ACTIVE_END_DESC = 'UTILITY_METERS_MAX_ACTIVE_END_DESC',
  UTILITY_METERS_MAX_ACTIVE_START_ASC = 'UTILITY_METERS_MAX_ACTIVE_START_ASC',
  UTILITY_METERS_MAX_ACTIVE_START_DESC = 'UTILITY_METERS_MAX_ACTIVE_START_DESC',
  UTILITY_METERS_MAX_CREATED_AT_ASC = 'UTILITY_METERS_MAX_CREATED_AT_ASC',
  UTILITY_METERS_MAX_CREATED_AT_DESC = 'UTILITY_METERS_MAX_CREATED_AT_DESC',
  UTILITY_METERS_MAX_DELETED_AT_ASC = 'UTILITY_METERS_MAX_DELETED_AT_ASC',
  UTILITY_METERS_MAX_DELETED_AT_DESC = 'UTILITY_METERS_MAX_DELETED_AT_DESC',
  UTILITY_METERS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_MAX_FACILITY_ID_ASC = 'UTILITY_METERS_MAX_FACILITY_ID_ASC',
  UTILITY_METERS_MAX_FACILITY_ID_DESC = 'UTILITY_METERS_MAX_FACILITY_ID_DESC',
  UTILITY_METERS_MAX_ID_ASC = 'UTILITY_METERS_MAX_ID_ASC',
  UTILITY_METERS_MAX_ID_DESC = 'UTILITY_METERS_MAX_ID_DESC',
  UTILITY_METERS_MAX_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_MAX_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_MAX_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_MAX_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_MAX_METADATA_ASC = 'UTILITY_METERS_MAX_METADATA_ASC',
  UTILITY_METERS_MAX_METADATA_DESC = 'UTILITY_METERS_MAX_METADATA_DESC',
  UTILITY_METERS_MAX_METER_NUMBER_ASC = 'UTILITY_METERS_MAX_METER_NUMBER_ASC',
  UTILITY_METERS_MAX_METER_NUMBER_DESC = 'UTILITY_METERS_MAX_METER_NUMBER_DESC',
  UTILITY_METERS_MAX_NAME_ASC = 'UTILITY_METERS_MAX_NAME_ASC',
  UTILITY_METERS_MAX_NAME_DESC = 'UTILITY_METERS_MAX_NAME_DESC',
  UTILITY_METERS_MAX_OVERRIDE_AT_ASC = 'UTILITY_METERS_MAX_OVERRIDE_AT_ASC',
  UTILITY_METERS_MAX_OVERRIDE_AT_DESC = 'UTILITY_METERS_MAX_OVERRIDE_AT_DESC',
  UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_MAX_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METERS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METERS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METERS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METERS_MAX_UPDATED_AT_ASC = 'UTILITY_METERS_MAX_UPDATED_AT_ASC',
  UTILITY_METERS_MAX_UPDATED_AT_DESC = 'UTILITY_METERS_MAX_UPDATED_AT_DESC',
  UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_MIN_ACTIVE_END_ASC = 'UTILITY_METERS_MIN_ACTIVE_END_ASC',
  UTILITY_METERS_MIN_ACTIVE_END_DESC = 'UTILITY_METERS_MIN_ACTIVE_END_DESC',
  UTILITY_METERS_MIN_ACTIVE_START_ASC = 'UTILITY_METERS_MIN_ACTIVE_START_ASC',
  UTILITY_METERS_MIN_ACTIVE_START_DESC = 'UTILITY_METERS_MIN_ACTIVE_START_DESC',
  UTILITY_METERS_MIN_CREATED_AT_ASC = 'UTILITY_METERS_MIN_CREATED_AT_ASC',
  UTILITY_METERS_MIN_CREATED_AT_DESC = 'UTILITY_METERS_MIN_CREATED_AT_DESC',
  UTILITY_METERS_MIN_DELETED_AT_ASC = 'UTILITY_METERS_MIN_DELETED_AT_ASC',
  UTILITY_METERS_MIN_DELETED_AT_DESC = 'UTILITY_METERS_MIN_DELETED_AT_DESC',
  UTILITY_METERS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_MIN_FACILITY_ID_ASC = 'UTILITY_METERS_MIN_FACILITY_ID_ASC',
  UTILITY_METERS_MIN_FACILITY_ID_DESC = 'UTILITY_METERS_MIN_FACILITY_ID_DESC',
  UTILITY_METERS_MIN_ID_ASC = 'UTILITY_METERS_MIN_ID_ASC',
  UTILITY_METERS_MIN_ID_DESC = 'UTILITY_METERS_MIN_ID_DESC',
  UTILITY_METERS_MIN_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_MIN_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_MIN_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_MIN_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_MIN_METADATA_ASC = 'UTILITY_METERS_MIN_METADATA_ASC',
  UTILITY_METERS_MIN_METADATA_DESC = 'UTILITY_METERS_MIN_METADATA_DESC',
  UTILITY_METERS_MIN_METER_NUMBER_ASC = 'UTILITY_METERS_MIN_METER_NUMBER_ASC',
  UTILITY_METERS_MIN_METER_NUMBER_DESC = 'UTILITY_METERS_MIN_METER_NUMBER_DESC',
  UTILITY_METERS_MIN_NAME_ASC = 'UTILITY_METERS_MIN_NAME_ASC',
  UTILITY_METERS_MIN_NAME_DESC = 'UTILITY_METERS_MIN_NAME_DESC',
  UTILITY_METERS_MIN_OVERRIDE_AT_ASC = 'UTILITY_METERS_MIN_OVERRIDE_AT_ASC',
  UTILITY_METERS_MIN_OVERRIDE_AT_DESC = 'UTILITY_METERS_MIN_OVERRIDE_AT_DESC',
  UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_MIN_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METERS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METERS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METERS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METERS_MIN_UPDATED_AT_ASC = 'UTILITY_METERS_MIN_UPDATED_AT_ASC',
  UTILITY_METERS_MIN_UPDATED_AT_DESC = 'UTILITY_METERS_MIN_UPDATED_AT_DESC',
  UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_END_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ACTIVE_START_DESC',
  UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_CREATED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_DELETED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_ID_DESC',
  UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_STDDEV_POPULATION_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_STDDEV_POPULATION_METADATA_ASC = 'UTILITY_METERS_STDDEV_POPULATION_METADATA_ASC',
  UTILITY_METERS_STDDEV_POPULATION_METADATA_DESC = 'UTILITY_METERS_STDDEV_POPULATION_METADATA_DESC',
  UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_POPULATION_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_POPULATION_NAME_ASC = 'UTILITY_METERS_STDDEV_POPULATION_NAME_ASC',
  UTILITY_METERS_STDDEV_POPULATION_NAME_DESC = 'UTILITY_METERS_STDDEV_POPULATION_NAME_DESC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_POPULATION_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METERS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_ASC = 'UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_DESC = 'UTILITY_METERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_END_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ACTIVE_START_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_DELETED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_ID_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_METADATA_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_METADATA_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_METADATA_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_METADATA_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_NAME_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_NAME_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_NAME_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_NAME_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_SUM_ACTIVE_END_ASC = 'UTILITY_METERS_SUM_ACTIVE_END_ASC',
  UTILITY_METERS_SUM_ACTIVE_END_DESC = 'UTILITY_METERS_SUM_ACTIVE_END_DESC',
  UTILITY_METERS_SUM_ACTIVE_START_ASC = 'UTILITY_METERS_SUM_ACTIVE_START_ASC',
  UTILITY_METERS_SUM_ACTIVE_START_DESC = 'UTILITY_METERS_SUM_ACTIVE_START_DESC',
  UTILITY_METERS_SUM_CREATED_AT_ASC = 'UTILITY_METERS_SUM_CREATED_AT_ASC',
  UTILITY_METERS_SUM_CREATED_AT_DESC = 'UTILITY_METERS_SUM_CREATED_AT_DESC',
  UTILITY_METERS_SUM_DELETED_AT_ASC = 'UTILITY_METERS_SUM_DELETED_AT_ASC',
  UTILITY_METERS_SUM_DELETED_AT_DESC = 'UTILITY_METERS_SUM_DELETED_AT_DESC',
  UTILITY_METERS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_SUM_FACILITY_ID_ASC = 'UTILITY_METERS_SUM_FACILITY_ID_ASC',
  UTILITY_METERS_SUM_FACILITY_ID_DESC = 'UTILITY_METERS_SUM_FACILITY_ID_DESC',
  UTILITY_METERS_SUM_ID_ASC = 'UTILITY_METERS_SUM_ID_ASC',
  UTILITY_METERS_SUM_ID_DESC = 'UTILITY_METERS_SUM_ID_DESC',
  UTILITY_METERS_SUM_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_SUM_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_SUM_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_SUM_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_SUM_METADATA_ASC = 'UTILITY_METERS_SUM_METADATA_ASC',
  UTILITY_METERS_SUM_METADATA_DESC = 'UTILITY_METERS_SUM_METADATA_DESC',
  UTILITY_METERS_SUM_METER_NUMBER_ASC = 'UTILITY_METERS_SUM_METER_NUMBER_ASC',
  UTILITY_METERS_SUM_METER_NUMBER_DESC = 'UTILITY_METERS_SUM_METER_NUMBER_DESC',
  UTILITY_METERS_SUM_NAME_ASC = 'UTILITY_METERS_SUM_NAME_ASC',
  UTILITY_METERS_SUM_NAME_DESC = 'UTILITY_METERS_SUM_NAME_DESC',
  UTILITY_METERS_SUM_OVERRIDE_AT_ASC = 'UTILITY_METERS_SUM_OVERRIDE_AT_ASC',
  UTILITY_METERS_SUM_OVERRIDE_AT_DESC = 'UTILITY_METERS_SUM_OVERRIDE_AT_DESC',
  UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_SUM_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METERS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METERS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METERS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METERS_SUM_UPDATED_AT_ASC = 'UTILITY_METERS_SUM_UPDATED_AT_ASC',
  UTILITY_METERS_SUM_UPDATED_AT_DESC = 'UTILITY_METERS_SUM_UPDATED_AT_DESC',
  UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_END_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ACTIVE_START_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_DELETED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_ID_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_METADATA_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_METADATA_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_METADATA_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_METADATA_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_NAME_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_NAME_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_NAME_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_NAME_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_END_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ACTIVE_START_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_ID_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_INTEGRATION_METER_KEY_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_METADATA_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_METADATA_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_METADATA_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_METADATA_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_NAME_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_NAME_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_NAME_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_NAME_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_OVERRIDE_METER_NUMBER_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METERS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_COUNT_ASC = 'UTILITY_METER_STATEMENTS_COUNT_ASC',
  UTILITY_METER_STATEMENTS_COUNT_DESC = 'UTILITY_METER_STATEMENTS_COUNT_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_PROVIDER_ID_ASC = 'UTILITY_PROVIDER_ID_ASC',
  UTILITY_PROVIDER_ID_DESC = 'UTILITY_PROVIDER_ID_DESC'
}

export enum UtilityIntegration {
  MANUAL = 'MANUAL',
  URJANET = 'URJANET'
}

/** A filter to be used against UtilityIntegration fields. All fields are combined with a logical ‘and.’ */
export type UtilityIntegrationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UtilityIntegration>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UtilityIntegration>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UtilityIntegration>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UtilityIntegration>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UtilityIntegration>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UtilityIntegration>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UtilityIntegration>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UtilityIntegration>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UtilityIntegration>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UtilityIntegration>>;
};

export type UtilityMeter = Node & {
  __typename?: 'UtilityMeter';
  /** Reads and enables pagination through a set of `AccountStatement`. */
  accountStatementsByMeterStatementUtilityMeterIdAndAccountStatementId: UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection;
  /** Reads and enables pagination through a set of `AccountStatement`. */
  accountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementId: UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection;
  activeEnd?: Maybe<Scalars['Date']>;
  activeStart: Scalars['Date'];
  /** The time the meter was created. */
  createdAt: Scalars['Datetime'];
  /** The time the meter was deleted. */
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Indicates that the meter`s usage should not be included in calcuations. */
  excludeUsage: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityId: UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Facility`. */
  facilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityId: UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyConnection;
  /** Reads a single `Facility` that is related to this `UtilityMeter`. */
  facility?: Maybe<Facility>;
  facilityId: Scalars['Int'];
  id: Scalars['Int'];
  integrationMeterKey: Scalars['String'];
  metadata: Scalars['JSON'];
  meterNumber: Scalars['String'];
  /** Reads and enables pagination through a set of `MeterStatement`. */
  meterStatements: MeterStatementsConnection;
  /** Returns a set of year/month combinations that are missing as statements for a given meter. */
  missingStatements: UtilityMetersMissingStatementsConnection;
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  overrideAt?: Maybe<Scalars['Datetime']>;
  /** The service type of the meter. */
  serviceType: UtilityMeterServiceType;
  /** The time the meter was updated. */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeter`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId: Scalars['Int'];
  /** Reads and enables pagination through a set of `UtilityAccount`. */
  utilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountId: UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UtilityAccount`. */
  utilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountId: UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
  utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
  utilityMeterStatements: UtilityMeterStatementsConnection;
};

export type UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<AccountStatementCondition>;
    filter?: InputMaybe<AccountStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
  };

export type UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<AccountStatementCondition>;
    filter?: InputMaybe<AccountStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<AccountStatementsOrderBy>>;
  };

export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<FacilityCondition>;
    filter?: InputMaybe<FacilityFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<FacilitiesOrderBy>>;
  };

export type UtilityMeterMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MeterStatementCondition>;
  filter?: InputMaybe<MeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
};

export type UtilityMeterMissingStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UtilityMetersMissingStatementsRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

export type UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityAccountCondition>;
    filter?: InputMaybe<UtilityAccountFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
  };

export type UtilityMeterUtilityMeterStatementRollupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
  filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
};

export type UtilityMeterUtilityMeterStatementsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityMeterStatementCondition>;
  filter?: InputMaybe<UtilityMeterStatementFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
};

/** A connection to a list of `AccountStatement` values, with data from `MeterStatement`. */
export type UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<AccountStatementAggregates>;
    /** A list of edges which contains the `AccountStatement`, info from the `MeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<AccountStatementAggregates>>;
    /** A list of `AccountStatement` objects. */
    nodes: Array<AccountStatement>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `AccountStatement` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `AccountStatement` values, with data from `MeterStatement`. */
export type UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<AccountStatementsGroupBy>;
    having?: InputMaybe<AccountStatementsHavingInput>;
  };

/** A `AccountStatement` edge in the connection, with data from `MeterStatement`. */
export type UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** Reads and enables pagination through a set of `MeterStatement`. */
    meterStatements: MeterStatementsConnection;
    /** The `AccountStatement` at the end of the edge. */
    node: AccountStatement;
  };

/** A `AccountStatement` edge in the connection, with data from `MeterStatement`. */
export type UtilityMeterAccountStatementsByMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdgeMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<MeterStatementCondition>;
    filter?: InputMaybe<MeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<MeterStatementsOrderBy>>;
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<AccountStatementAggregates>;
    /** A list of edges which contains the `AccountStatement`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<AccountStatementAggregates>>;
    /** A list of `AccountStatement` objects. */
    nodes: Array<AccountStatement>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `AccountStatement` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `AccountStatement` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<AccountStatementsGroupBy>;
    having?: InputMaybe<AccountStatementsHavingInput>;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `AccountStatement` at the end of the edge. */
    node: AccountStatement;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `AccountStatement` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterAccountStatementsByUtilityMeterStatementUtilityMeterIdAndAccountStatementIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type UtilityMeterAggregates = {
  __typename?: 'UtilityMeterAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityMeterAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityMeterDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityMeterDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityMeterMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityMeterMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityMeterStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityMeterStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityMeterSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityMeterVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityMeterVarianceSampleAggregates>;
};

export type UtilityMeterAverageAggregates = {
  __typename?: 'UtilityMeterAverageAggregates';
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export enum UtilityMeterChargeCategory {
  CREDITS = 'CREDITS',
  DEMAND = 'DEMAND',
  FEES = 'FEES',
  TAXES = 'TAXES',
  USAGE = 'USAGE'
}

/** A filter to be used against UtilityMeterChargeCategory fields. All fields are combined with a logical ‘and.’ */
export type UtilityMeterChargeCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UtilityMeterChargeCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UtilityMeterChargeCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UtilityMeterChargeCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UtilityMeterChargeCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UtilityMeterChargeCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UtilityMeterChargeCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UtilityMeterChargeCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UtilityMeterChargeCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UtilityMeterChargeCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UtilityMeterChargeCategory>>;
};

/**
 * A condition to be used against `UtilityMeter` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UtilityMeterCondition = {
  /** Checks for equality with the object’s `activeEnd` field. */
  activeEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `activeStart` field. */
  activeStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `excludeUsage` field. */
  excludeUsage?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationMeterKey` field. */
  integrationMeterKey?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `meterNumber` field. */
  meterNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `overrideMeterNumber` field. */
  overrideMeterNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type UtilityMeterDistinctAggregates = {
  __typename?: 'UtilityMeterDistinctAggregates';
  /** Distinct of activeEnd across the matching connection */
  activeEnd?: Maybe<Scalars['JSON']>;
  /** Distinct of activeStart across the matching connection */
  activeStart?: Maybe<Scalars['JSON']>;
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of excludeUsage across the matching connection */
  excludeUsage?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of integrationMeterKey across the matching connection */
  integrationMeterKey?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of metadata across the matching connection */
  metadata?: Maybe<Scalars['JSON']>;
  /** Distinct of meterNumber across the matching connection */
  meterNumber?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['JSON']>;
  /** Distinct of overrideMeterNumber across the matching connection */
  overrideMeterNumber?: Maybe<Scalars['JSON']>;
  /** Distinct of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['JSON']>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
};

export type UtilityMeterDistinctCountAggregates = {
  __typename?: 'UtilityMeterDistinctCountAggregates';
  /** Distinct count of activeEnd across the matching connection */
  activeEnd?: Maybe<Scalars['BigInt']>;
  /** Distinct count of activeStart across the matching connection */
  activeStart?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedAt across the matching connection */
  deletedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excludeUsage across the matching connection */
  excludeUsage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationMeterKey across the matching connection */
  integrationMeterKey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadata across the matching connection */
  metadata?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterNumber across the matching connection */
  meterNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideAt across the matching connection */
  overrideAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of overrideMeterNumber across the matching connection */
  overrideMeterNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementRollupUtilityMeterIdAndFacilityIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<FacilityAggregates>;
    /** A list of edges which contains the `Facility`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<FacilityAggregates>>;
    /** Aggregates a metric by label for the specified filter expression. */
    metricData?: Maybe<MetricDataConnection>;
    /** A list of `Facility` objects. */
    nodes: Array<Facility>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Facility` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<FacilitiesGroupBy>;
    having?: InputMaybe<FacilitiesHavingInput>;
  };

/** A connection to a list of `Facility` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyConnectionMetricDataArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    aggregation?: MetricDataAggregationMethod;
    before?: InputMaybe<Scalars['Cursor']>;
    first?: InputMaybe<Scalars['Int']>;
    from?: InputMaybe<Scalars['String']>;
    label: Scalars['String'];
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: MetricDataOrderBy;
    samplingWindow?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
    unit?: InputMaybe<Scalars['String']>;
    unitGroup?: InputMaybe<Scalars['String']>;
    window?: Scalars['String'];
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Facility` at the end of the edge. */
    node: Facility;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `Facility` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterFacilitiesByUtilityMeterStatementUtilityMeterIdAndFacilityIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

/** A filter to be used against `UtilityMeter` object types. All fields are combined with a logical ‘and.’ */
export type UtilityMeterFilter = {
  /** Filter by the object’s `activeEnd` field. */
  activeEnd?: InputMaybe<DateFilter>;
  /** Filter by the object’s `activeStart` field. */
  activeStart?: InputMaybe<DateFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityMeterFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `excludeUsage` field. */
  excludeUsage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `integrationMeterKey` field. */
  integrationMeterKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metadata` field. */
  metadata?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `meterNumber` field. */
  meterNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityMeterFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityMeterFilter>>;
  /** Filter by the object’s `overrideAt` field. */
  overrideAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `overrideMeterNumber` field. */
  overrideMeterNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UtilityMeter` */
export type UtilityMeterInput = {
  activeEnd?: InputMaybe<Scalars['Date']>;
  activeStart?: InputMaybe<Scalars['Date']>;
  /** Indicates that the meter`s usage should not be included in calcuations. */
  excludeUsage?: InputMaybe<Scalars['Boolean']>;
  facilityId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  integrationMeterKey?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  meterNumber: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** The service type of the meter. */
  serviceType: UtilityMeterServiceType;
  /** Mutates a `UtilityAccount` as part of the payload */
  utilityAccount?: InputMaybe<UtilityAccountInput>;
  utilityAccountId?: InputMaybe<Scalars['Int']>;
};

export type UtilityMeterMaxAggregates = {
  __typename?: 'UtilityMeterMaxAggregates';
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterMinAggregates = {
  __typename?: 'UtilityMeterMinAggregates';
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `UtilityMeter`. Fields that are set will be updated. */
export type UtilityMeterPatch = {
  activeEnd?: InputMaybe<Scalars['Date']>;
  activeStart?: InputMaybe<Scalars['Date']>;
  /** Indicates that the meter`s usage should not be included in calcuations. */
  excludeUsage?: InputMaybe<Scalars['Boolean']>;
  facilityId?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Overrides the original meter_number. */
  meterNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  overrideAt?: InputMaybe<Scalars['Datetime']>;
  /** The service type of the meter. */
  serviceType?: InputMaybe<UtilityMeterServiceType>;
};

export enum UtilityMeterServiceType {
  ELECTRIC = 'ELECTRIC',
  IRRIGATION = 'IRRIGATION',
  LIGHTING = 'LIGHTING',
  NATURAL_GAS = 'NATURAL_GAS',
  OTHER = 'OTHER',
  PROPANE = 'PROPANE',
  SANITATION = 'SANITATION',
  SEWER = 'SEWER',
  SOLAR = 'SOLAR',
  WATER = 'WATER'
}

/** A filter to be used against UtilityMeterServiceType fields. All fields are combined with a logical ‘and.’ */
export type UtilityMeterServiceTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UtilityMeterServiceType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UtilityMeterServiceType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UtilityMeterServiceType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UtilityMeterServiceType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UtilityMeterServiceType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UtilityMeterServiceType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UtilityMeterServiceType>>;
};

/** A filter to be used against UtilityMeterServiceType List fields. All fields are combined with a logical ‘and.’ */
export type UtilityMeterServiceTypeListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<UtilityMeterServiceType>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<UtilityMeterServiceType>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<UtilityMeterServiceType>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<UtilityMeterServiceType>>>;
};

export type UtilityMeterStatement = Node & {
  __typename?: 'UtilityMeterStatement';
  accountLabel?: Maybe<Scalars['String']>;
  /** Reads a single `AccountStatement` that is related to this `UtilityMeterStatement`. */
  accountStatement?: Maybe<AccountStatement>;
  accountStatementId?: Maybe<Scalars['Int']>;
  billArrivalDate?: Maybe<Scalars['Datetime']>;
  /** Returns the total charges of a given utility_meter_statements (view) row. */
  billCost?: Maybe<Scalars['Float']>;
  billDemand?: Maybe<Scalars['Float']>;
  /** Returns the total demand charges of a given utility_meter_statements (view) row. */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Returns the total demand of a given utility_meter_statements (view) row. */
  billUsage?: Maybe<Scalars['Float']>;
  /** Returns the total usage charges of a given utility_meter_statements (view) row. */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Returns the total credits of a given utility_meter_statements (view) row. */
  credits?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInBill?: Maybe<Scalars['Int']>;
  demandUnits?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  excludeUsage?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Facility` that is related to this `UtilityMeterStatement`. */
  facility?: Maybe<Facility>;
  facilityId?: Maybe<Scalars['Int']>;
  /** Returns the total fee charges of a given utility_meter_statements (view) row. */
  fees?: Maybe<Scalars['Float']>;
  isValidated?: Maybe<Scalars['Boolean']>;
  meterLabel?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `UtilityMeterStatement`. */
  meterStatement?: Maybe<MeterStatement>;
  meterStatementId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  serviceType?: Maybe<UtilityMeterServiceType>;
  startDate?: Maybe<Scalars['Date']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  /** Returns the total tax charges of a given utility_meter_statements (view) row. */
  taxes?: Maybe<Scalars['Float']>;
  usageUnits?: Maybe<Scalars['String']>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeterStatement`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Reads a single `UtilityMeter` that is related to this `UtilityMeterStatement`. */
  utilityMeter?: Maybe<UtilityMeter>;
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementAggregates = {
  __typename?: 'UtilityMeterStatementAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityMeterStatementAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityMeterStatementDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityMeterStatementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityMeterStatementMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityMeterStatementMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityMeterStatementStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityMeterStatementStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityMeterStatementSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityMeterStatementVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityMeterStatementVarianceSampleAggregates>;
};

export type UtilityMeterStatementAverageAggregates = {
  __typename?: 'UtilityMeterStatementAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Mean average of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Mean average of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Mean average of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UtilityMeterStatement` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UtilityMeterStatementCondition = {
  /** Checks for equality with the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `daysInBill` field. */
  daysInBill?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `demandUnits` field. */
  demandUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `excludeUsage` field. */
  excludeUsage?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isValidated` field. */
  isValidated?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meterLabel` field. */
  meterLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceType>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statementYear` field. */
  statementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `usageUnits` field. */
  usageUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<Scalars['Int']>;
};

export type UtilityMeterStatementDistinctAggregates = {
  __typename?: 'UtilityMeterStatementDistinctAggregates';
  /** Distinct of accountLabel across the matching connection */
  accountLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of this field across the matching connection. */
  billDemand?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['JSON']>;
  /** Distinct of demandUnits across the matching connection */
  demandUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of endDate across the matching connection */
  endDate?: Maybe<Scalars['JSON']>;
  /** Distinct of excludeUsage across the matching connection */
  excludeUsage?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['JSON']>;
  /** Distinct of meterLabel across the matching connection */
  meterLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of provider across the matching connection */
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['JSON']>;
  /** Distinct of startDate across the matching connection */
  startDate?: Maybe<Scalars['JSON']>;
  /** Distinct of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of usageUnits across the matching connection */
  usageUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['JSON']>;
};

export type UtilityMeterStatementDistinctCountAggregates = {
  __typename?: 'UtilityMeterStatementDistinctCountAggregates';
  /** Distinct count of accountLabel across the matching connection */
  accountLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  billDemand?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigInt']>;
  /** Distinct count of demandUnits across the matching connection */
  demandUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDate across the matching connection */
  endDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excludeUsage across the matching connection */
  excludeUsage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterLabel across the matching connection */
  meterLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDate across the matching connection */
  startDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of usageUnits across the matching connection */
  usageUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UtilityMeterStatement` object types. All fields are combined with a logical ‘and.’ */
export type UtilityMeterStatementFilter = {
  /** Filter by the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityMeterStatementFilter>>;
  /** Filter by the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `billCost` field. */
  billCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billDemand` field. */
  billDemand?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billDemandCost` field. */
  billDemandCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billUsage` field. */
  billUsage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billUsageCost` field. */
  billUsageCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `credits` field. */
  credits?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `daysInBill` field. */
  daysInBill?: InputMaybe<IntFilter>;
  /** Filter by the object’s `demandUnits` field. */
  demandUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `excludeUsage` field. */
  excludeUsage?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fees` field. */
  fees?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `isValidated` field. */
  isValidated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meterLabel` field. */
  meterLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityMeterStatementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityMeterStatementFilter>>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceTypeFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxes` field. */
  taxes?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `usageUnits` field. */
  usageUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<IntFilter>;
};

export type UtilityMeterStatementMaxAggregates = {
  __typename?: 'UtilityMeterStatementMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Maximum of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Maximum of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['Int']>;
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Maximum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Maximum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementMinAggregates = {
  __typename?: 'UtilityMeterStatementMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Minimum of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Minimum of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['Int']>;
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Minimum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Minimum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementRollup = Node & {
  __typename?: 'UtilityMeterStatementRollup';
  accountLabel?: Maybe<Scalars['String']>;
  accountStatementId?: Maybe<Scalars['Int']>;
  billArrivalDate?: Maybe<Scalars['Datetime']>;
  billCost?: Maybe<Scalars['Float']>;
  billDemand?: Maybe<Scalars['Float']>;
  billDemandCost?: Maybe<Scalars['Float']>;
  billUsage?: Maybe<Scalars['Float']>;
  billUsageCost?: Maybe<Scalars['Float']>;
  blendedRate?: Maybe<Scalars['Float']>;
  credits?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  daysInBill?: Maybe<Scalars['Int']>;
  demandUnits?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  /** Reads a single `Facility` that is related to this `UtilityMeterStatementRollup`. */
  facility?: Maybe<Facility>;
  facilityId?: Maybe<Scalars['Int']>;
  /** The name of the facility associated with the meter statement. */
  facilityName?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['Float']>;
  isValidated?: Maybe<Scalars['Boolean']>;
  loadFactor?: Maybe<Scalars['Float']>;
  meterLabel?: Maybe<Scalars['String']>;
  /** Reads a single `MeterStatement` that is related to this `UtilityMeterStatementRollup`. */
  meterStatement?: Maybe<MeterStatement>;
  meterStatementId: Scalars['Int'];
  netBillUsage?: Maybe<Scalars['Float']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  percentChange?: Maybe<Scalars['Float']>;
  provider?: Maybe<Scalars['String']>;
  serviceType?: Maybe<UtilityMeterServiceType>;
  startDate?: Maybe<Scalars['Date']>;
  /** The statement date of the meter statement. */
  statementDate?: Maybe<Scalars['String']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  taxes?: Maybe<Scalars['Float']>;
  usageUnits?: Maybe<Scalars['String']>;
  /** Reads a single `UtilityAccount` that is related to this `UtilityMeterStatementRollup`. */
  utilityAccount?: Maybe<UtilityAccount>;
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Reads a single `UtilityMeter` that is related to this `UtilityMeterStatementRollup`. */
  utilityMeter?: Maybe<UtilityMeter>;
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementRollupAggregates = {
  __typename?: 'UtilityMeterStatementRollupAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityMeterStatementRollupAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityMeterStatementRollupDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityMeterStatementRollupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityMeterStatementRollupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityMeterStatementRollupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityMeterStatementRollupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityMeterStatementRollupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityMeterStatementRollupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityMeterStatementRollupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityMeterStatementRollupVarianceSampleAggregates>;
};

export type UtilityMeterStatementRollupAverageAggregates = {
  __typename?: 'UtilityMeterStatementRollupAverageAggregates';
  /** Mean average of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Mean average of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Mean average of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Mean average of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Mean average of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Mean average of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Mean average of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Mean average of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Mean average of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Mean average of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Mean average of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Mean average of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Mean average of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Mean average of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Mean average of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UtilityMeterStatementRollup` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UtilityMeterStatementRollupCondition = {
  /** Checks for equality with the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `billCost` field. */
  billCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billDemand` field. */
  billDemand?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billDemandCost` field. */
  billDemandCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billUsage` field. */
  billUsage?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `billUsageCost` field. */
  billUsageCost?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `blendedRate` field. */
  blendedRate?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `credits` field. */
  credits?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `daysInBill` field. */
  daysInBill?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `demandUnits` field. */
  demandUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fees` field. */
  fees?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `isValidated` field. */
  isValidated?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `loadFactor` field. */
  loadFactor?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `meterLabel` field. */
  meterLabel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `netBillUsage` field. */
  netBillUsage?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `percentChange` field. */
  percentChange?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceType>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `statementYear` field. */
  statementYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `taxes` field. */
  taxes?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `usageUnits` field. */
  usageUnits?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<Scalars['Int']>;
};

export type UtilityMeterStatementRollupDistinctAggregates = {
  __typename?: 'UtilityMeterStatementRollupDistinctAggregates';
  /** Distinct of accountLabel across the matching connection */
  accountLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['JSON']>;
  /** Distinct of billCost across the matching connection */
  billCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billDemand across the matching connection */
  billDemand?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billUsage across the matching connection */
  billUsage?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of blendedRate across the matching connection */
  blendedRate?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of credits across the matching connection */
  credits?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['JSON']>;
  /** Distinct of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['JSON']>;
  /** Distinct of demandUnits across the matching connection */
  demandUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of endDate across the matching connection */
  endDate?: Maybe<Scalars['JSON']>;
  /** Distinct of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The name of the facility associated with the meter statement.
   */
  facilityName?: Maybe<Scalars['JSON']>;
  /** Distinct of fees across the matching connection */
  fees?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['JSON']>;
  /** Distinct of loadFactor across the matching connection */
  loadFactor?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of meterLabel across the matching connection */
  meterLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['JSON']>;
  /** Distinct of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of percentChange across the matching connection */
  percentChange?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of provider across the matching connection */
  provider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['JSON']>;
  /** Distinct of startDate across the matching connection */
  startDate?: Maybe<Scalars['JSON']>;
  /**
   * Distinct of this field across the matching connection.
   *
   * ---
   *
   * The statement date of the meter statement.
   */
  statementDate?: Maybe<Scalars['JSON']>;
  /** Distinct of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['JSON']>;
  /** Distinct of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['JSON']>;
  /** Distinct of taxes across the matching connection */
  taxes?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** Distinct of usageUnits across the matching connection */
  usageUnits?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['JSON']>;
  /** Distinct of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['JSON']>;
};

export type UtilityMeterStatementRollupDistinctCountAggregates = {
  __typename?: 'UtilityMeterStatementRollupDistinctCountAggregates';
  /** Distinct count of accountLabel across the matching connection */
  accountLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billArrivalDate across the matching connection */
  billArrivalDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billCost across the matching connection */
  billCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['BigInt']>;
  /** Distinct count of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of credits across the matching connection */
  credits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of currencyCode across the matching connection */
  currencyCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigInt']>;
  /** Distinct count of demandUnits across the matching connection */
  demandUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDate across the matching connection */
  endDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The name of the facility associated with the meter statement.
   */
  facilityName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fees across the matching connection */
  fees?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isValidated across the matching connection */
  isValidated?: Maybe<Scalars['BigInt']>;
  /** Distinct count of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterLabel across the matching connection */
  meterLabel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['BigInt']>;
  /** Distinct count of provider across the matching connection */
  provider?: Maybe<Scalars['BigInt']>;
  /** Distinct count of serviceType across the matching connection */
  serviceType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDate across the matching connection */
  startDate?: Maybe<Scalars['BigInt']>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * The statement date of the meter statement.
   */
  statementDate?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigInt']>;
  /** Distinct count of taxes across the matching connection */
  taxes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of usageUnits across the matching connection */
  usageUnits?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UtilityMeterStatementRollup` object types. All fields are combined with a logical ‘and.’ */
export type UtilityMeterStatementRollupFilter = {
  /** Filter by the object’s `accountLabel` field. */
  accountLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountStatementId` field. */
  accountStatementId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityMeterStatementRollupFilter>>;
  /** Filter by the object’s `billArrivalDate` field. */
  billArrivalDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `billCost` field. */
  billCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billDemand` field. */
  billDemand?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billDemandCost` field. */
  billDemandCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billUsage` field. */
  billUsage?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `billUsageCost` field. */
  billUsageCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `blendedRate` field. */
  blendedRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `credits` field. */
  credits?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `daysInBill` field. */
  daysInBill?: InputMaybe<IntFilter>;
  /** Filter by the object’s `demandUnits` field. */
  demandUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `facilityName` field. */
  facilityName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `fees` field. */
  fees?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `isValidated` field. */
  isValidated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `loadFactor` field. */
  loadFactor?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `meterLabel` field. */
  meterLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `meterStatementId` field. */
  meterStatementId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `netBillUsage` field. */
  netBillUsage?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityMeterStatementRollupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityMeterStatementRollupFilter>>;
  /** Filter by the object’s `percentChange` field. */
  percentChange?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `serviceType` field. */
  serviceType?: InputMaybe<UtilityMeterServiceTypeFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementDate` field. */
  statementDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `taxes` field. */
  taxes?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `usageUnits` field. */
  usageUnits?: InputMaybe<StringFilter>;
  /** Filter by the object’s `utilityAccountId` field. */
  utilityAccountId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<IntFilter>;
};

export type UtilityMeterStatementRollupMaxAggregates = {
  __typename?: 'UtilityMeterStatementRollupMaxAggregates';
  /** Maximum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Maximum of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Maximum of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Maximum of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Maximum of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Maximum of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Maximum of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Maximum of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['Int']>;
  /** Maximum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Maximum of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Maximum of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Maximum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Maximum of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Maximum of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Maximum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Maximum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Maximum of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Maximum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Maximum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementRollupMinAggregates = {
  __typename?: 'UtilityMeterStatementRollupMinAggregates';
  /** Minimum of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Minimum of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Minimum of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Minimum of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Minimum of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Minimum of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Minimum of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Minimum of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['Int']>;
  /** Minimum of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['Int']>;
  /** Minimum of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Minimum of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Minimum of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['Int']>;
  /** Minimum of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Minimum of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Minimum of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['Int']>;
  /** Minimum of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['Int']>;
  /** Minimum of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Minimum of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['Int']>;
  /** Minimum of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['Int']>;
};

export type UtilityMeterStatementRollupStddevPopulationAggregates = {
  __typename?: 'UtilityMeterStatementRollupStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Population standard deviation of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Population standard deviation of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Population standard deviation of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Population standard deviation of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Population standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Population standard deviation of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Population standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementRollupStddevSampleAggregates = {
  __typename?: 'UtilityMeterStatementRollupStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementRollupSumAggregates = {
  __typename?: 'UtilityMeterStatementRollupSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /** Sum of billCost across the matching connection */
  billCost: Scalars['Float'];
  /** Sum of billDemand across the matching connection */
  billDemand: Scalars['Float'];
  /** Sum of billDemandCost across the matching connection */
  billDemandCost: Scalars['Float'];
  /** Sum of billUsage across the matching connection */
  billUsage: Scalars['Float'];
  /** Sum of billUsageCost across the matching connection */
  billUsageCost: Scalars['Float'];
  /** Sum of blendedRate across the matching connection */
  blendedRate: Scalars['Float'];
  /** Sum of credits across the matching connection */
  credits: Scalars['Float'];
  /** Sum of daysInBill across the matching connection */
  daysInBill: Scalars['BigInt'];
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /** Sum of fees across the matching connection */
  fees: Scalars['Float'];
  /** Sum of loadFactor across the matching connection */
  loadFactor: Scalars['Float'];
  /** Sum of meterStatementId across the matching connection */
  meterStatementId: Scalars['BigInt'];
  /** Sum of netBillUsage across the matching connection */
  netBillUsage: Scalars['Float'];
  /** Sum of percentChange across the matching connection */
  percentChange: Scalars['Float'];
  /** Sum of statementMonth across the matching connection */
  statementMonth: Scalars['BigInt'];
  /** Sum of statementYear across the matching connection */
  statementYear: Scalars['BigInt'];
  /** Sum of taxes across the matching connection */
  taxes: Scalars['Float'];
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
  /** Sum of utilityMeterId across the matching connection */
  utilityMeterId: Scalars['BigInt'];
};

export type UtilityMeterStatementRollupVariancePopulationAggregates = {
  __typename?: 'UtilityMeterStatementRollupVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Population variance of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Population variance of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Population variance of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Population variance of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Population variance of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Population variance of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Population variance of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Population variance of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Population variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Population variance of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Population variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Population variance of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementRollupVarianceSampleAggregates = {
  __typename?: 'UtilityMeterStatementRollupVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of billCost across the matching connection */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample variance of billDemand across the matching connection */
  billDemand?: Maybe<Scalars['Float']>;
  /** Sample variance of billDemandCost across the matching connection */
  billDemandCost?: Maybe<Scalars['Float']>;
  /** Sample variance of billUsage across the matching connection */
  billUsage?: Maybe<Scalars['Float']>;
  /** Sample variance of billUsageCost across the matching connection */
  billUsageCost?: Maybe<Scalars['Float']>;
  /** Sample variance of blendedRate across the matching connection */
  blendedRate?: Maybe<Scalars['Float']>;
  /** Sample variance of credits across the matching connection */
  credits?: Maybe<Scalars['Float']>;
  /** Sample variance of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fees across the matching connection */
  fees?: Maybe<Scalars['Float']>;
  /** Sample variance of loadFactor across the matching connection */
  loadFactor?: Maybe<Scalars['Float']>;
  /** Sample variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of netBillUsage across the matching connection */
  netBillUsage?: Maybe<Scalars['Float']>;
  /** Sample variance of percentChange across the matching connection */
  percentChange?: Maybe<Scalars['Float']>;
  /** Sample variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of taxes across the matching connection */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityMeterStatementRollup` values. */
export type UtilityMeterStatementRollupsConnection = {
  __typename?: 'UtilityMeterStatementRollupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityMeterStatementRollupAggregates>;
  /** A list of edges which contains the `UtilityMeterStatementRollup` and cursor to aid in pagination. */
  edges: Array<UtilityMeterStatementRollupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityMeterStatementRollupAggregates>>;
  /** A list of `UtilityMeterStatementRollup` objects. */
  nodes: Array<UtilityMeterStatementRollup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityMeterStatementRollup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityMeterStatementRollup` values. */
export type UtilityMeterStatementRollupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityMeterStatementRollupsGroupBy>;
  having?: InputMaybe<UtilityMeterStatementRollupsHavingInput>;
};

/** A `UtilityMeterStatementRollup` edge in the connection. */
export type UtilityMeterStatementRollupsEdge = {
  __typename?: 'UtilityMeterStatementRollupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityMeterStatementRollup` at the end of the edge. */
  node: UtilityMeterStatementRollup;
};

/** Grouping methods for `UtilityMeterStatementRollup` for usage during aggregation. */
export enum UtilityMeterStatementRollupsGroupBy {
  ACCOUNT_LABEL = 'ACCOUNT_LABEL',
  ACCOUNT_LABEL_DISTINCT = 'ACCOUNT_LABEL_DISTINCT',
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  BILL_ARRIVAL_DATE = 'BILL_ARRIVAL_DATE',
  BILL_ARRIVAL_DATE_DISTINCT = 'BILL_ARRIVAL_DATE_DISTINCT',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR',
  BILL_COST = 'BILL_COST',
  BILL_COST_DISTINCT = 'BILL_COST_DISTINCT',
  BILL_DEMAND = 'BILL_DEMAND',
  BILL_DEMAND_COST = 'BILL_DEMAND_COST',
  BILL_DEMAND_COST_DISTINCT = 'BILL_DEMAND_COST_DISTINCT',
  BILL_DEMAND_DISTINCT = 'BILL_DEMAND_DISTINCT',
  BILL_USAGE = 'BILL_USAGE',
  BILL_USAGE_COST = 'BILL_USAGE_COST',
  BILL_USAGE_COST_DISTINCT = 'BILL_USAGE_COST_DISTINCT',
  BILL_USAGE_DISTINCT = 'BILL_USAGE_DISTINCT',
  BLENDED_RATE = 'BLENDED_RATE',
  BLENDED_RATE_DISTINCT = 'BLENDED_RATE_DISTINCT',
  CREDITS = 'CREDITS',
  CREDITS_DISTINCT = 'CREDITS_DISTINCT',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  DAYS_IN_BILL = 'DAYS_IN_BILL',
  DAYS_IN_BILL_DISTINCT = 'DAYS_IN_BILL_DISTINCT',
  DEMAND_UNITS = 'DEMAND_UNITS',
  DEMAND_UNITS_DISTINCT = 'DEMAND_UNITS_DISTINCT',
  END_DATE = 'END_DATE',
  END_DATE_DISTINCT = 'END_DATE_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  FEES = 'FEES',
  FEES_DISTINCT = 'FEES_DISTINCT',
  IS_VALIDATED = 'IS_VALIDATED',
  IS_VALIDATED_DISTINCT = 'IS_VALIDATED_DISTINCT',
  LOAD_FACTOR = 'LOAD_FACTOR',
  LOAD_FACTOR_DISTINCT = 'LOAD_FACTOR_DISTINCT',
  METER_LABEL = 'METER_LABEL',
  METER_LABEL_DISTINCT = 'METER_LABEL_DISTINCT',
  METER_STATEMENT_ID = 'METER_STATEMENT_ID',
  METER_STATEMENT_ID_DISTINCT = 'METER_STATEMENT_ID_DISTINCT',
  NET_BILL_USAGE = 'NET_BILL_USAGE',
  NET_BILL_USAGE_DISTINCT = 'NET_BILL_USAGE_DISTINCT',
  PERCENT_CHANGE = 'PERCENT_CHANGE',
  PERCENT_CHANGE_DISTINCT = 'PERCENT_CHANGE_DISTINCT',
  PROVIDER = 'PROVIDER',
  PROVIDER_DISTINCT = 'PROVIDER_DISTINCT',
  SERVICE_TYPE = 'SERVICE_TYPE',
  SERVICE_TYPE_DISTINCT = 'SERVICE_TYPE_DISTINCT',
  START_DATE = 'START_DATE',
  START_DATE_DISTINCT = 'START_DATE_DISTINCT',
  STATEMENT_MONTH = 'STATEMENT_MONTH',
  STATEMENT_MONTH_DISTINCT = 'STATEMENT_MONTH_DISTINCT',
  STATEMENT_YEAR = 'STATEMENT_YEAR',
  STATEMENT_YEAR_DISTINCT = 'STATEMENT_YEAR_DISTINCT',
  TAXES = 'TAXES',
  TAXES_DISTINCT = 'TAXES_DISTINCT',
  USAGE_UNITS = 'USAGE_UNITS',
  USAGE_UNITS_DISTINCT = 'USAGE_UNITS_DISTINCT',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT',
  UTILITY_METER_ID = 'UTILITY_METER_ID',
  UTILITY_METER_ID_DISTINCT = 'UTILITY_METER_ID_DISTINCT'
}

export type UtilityMeterStatementRollupsHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UtilityMeterStatementRollup` aggregates. */
export type UtilityMeterStatementRollupsHavingInput = {
  AND?: InputMaybe<Array<UtilityMeterStatementRollupsHavingInput>>;
  OR?: InputMaybe<Array<UtilityMeterStatementRollupsHavingInput>>;
  average?: InputMaybe<UtilityMeterStatementRollupsHavingAverageInput>;
  distinct?: InputMaybe<UtilityMeterStatementRollupsHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityMeterStatementRollupsHavingDistinctCountInput>;
  max?: InputMaybe<UtilityMeterStatementRollupsHavingMaxInput>;
  min?: InputMaybe<UtilityMeterStatementRollupsHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityMeterStatementRollupsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityMeterStatementRollupsHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityMeterStatementRollupsHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityMeterStatementRollupsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityMeterStatementRollupsHavingVarianceSampleInput>;
};

export type UtilityMeterStatementRollupsHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementRollupsHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<HavingFloatFilter>;
  billDemand?: InputMaybe<HavingFloatFilter>;
  billDemandCost?: InputMaybe<HavingFloatFilter>;
  billUsage?: InputMaybe<HavingFloatFilter>;
  billUsageCost?: InputMaybe<HavingFloatFilter>;
  blendedRate?: InputMaybe<HavingFloatFilter>;
  credits?: InputMaybe<HavingFloatFilter>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<HavingFloatFilter>;
  loadFactor?: InputMaybe<HavingFloatFilter>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  netBillUsage?: InputMaybe<HavingFloatFilter>;
  percentChange?: InputMaybe<HavingFloatFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<HavingFloatFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `UtilityMeterStatementRollup`. */
export enum UtilityMeterStatementRollupsOrderBy {
  ACCOUNT_LABEL_ASC = 'ACCOUNT_LABEL_ASC',
  ACCOUNT_LABEL_DESC = 'ACCOUNT_LABEL_DESC',
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  BILL_ARRIVAL_DATE_ASC = 'BILL_ARRIVAL_DATE_ASC',
  BILL_ARRIVAL_DATE_DESC = 'BILL_ARRIVAL_DATE_DESC',
  BILL_COST_ASC = 'BILL_COST_ASC',
  BILL_COST_DESC = 'BILL_COST_DESC',
  BILL_DEMAND_ASC = 'BILL_DEMAND_ASC',
  BILL_DEMAND_COST_ASC = 'BILL_DEMAND_COST_ASC',
  BILL_DEMAND_COST_DESC = 'BILL_DEMAND_COST_DESC',
  BILL_DEMAND_DESC = 'BILL_DEMAND_DESC',
  BILL_USAGE_ASC = 'BILL_USAGE_ASC',
  BILL_USAGE_COST_ASC = 'BILL_USAGE_COST_ASC',
  BILL_USAGE_COST_DESC = 'BILL_USAGE_COST_DESC',
  BILL_USAGE_DESC = 'BILL_USAGE_DESC',
  BLENDED_RATE_ASC = 'BLENDED_RATE_ASC',
  BLENDED_RATE_DESC = 'BLENDED_RATE_DESC',
  CREDITS_ASC = 'CREDITS_ASC',
  CREDITS_DESC = 'CREDITS_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DAYS_IN_BILL_ASC = 'DAYS_IN_BILL_ASC',
  DAYS_IN_BILL_DESC = 'DAYS_IN_BILL_DESC',
  DEMAND_UNITS_ASC = 'DEMAND_UNITS_ASC',
  DEMAND_UNITS_DESC = 'DEMAND_UNITS_DESC',
  END_DATE_ASC = 'END_DATE_ASC',
  END_DATE_DESC = 'END_DATE_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  FACILITY_NAME_ASC = 'FACILITY_NAME_ASC',
  FACILITY_NAME_DESC = 'FACILITY_NAME_DESC',
  FEES_ASC = 'FEES_ASC',
  FEES_DESC = 'FEES_DESC',
  IS_VALIDATED_ASC = 'IS_VALIDATED_ASC',
  IS_VALIDATED_DESC = 'IS_VALIDATED_DESC',
  LOAD_FACTOR_ASC = 'LOAD_FACTOR_ASC',
  LOAD_FACTOR_DESC = 'LOAD_FACTOR_DESC',
  METER_LABEL_ASC = 'METER_LABEL_ASC',
  METER_LABEL_DESC = 'METER_LABEL_DESC',
  METER_STATEMENT_ID_ASC = 'METER_STATEMENT_ID_ASC',
  METER_STATEMENT_ID_DESC = 'METER_STATEMENT_ID_DESC',
  NATURAL = 'NATURAL',
  NET_BILL_USAGE_ASC = 'NET_BILL_USAGE_ASC',
  NET_BILL_USAGE_DESC = 'NET_BILL_USAGE_DESC',
  PERCENT_CHANGE_ASC = 'PERCENT_CHANGE_ASC',
  PERCENT_CHANGE_DESC = 'PERCENT_CHANGE_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROVIDER_ASC = 'PROVIDER_ASC',
  PROVIDER_DESC = 'PROVIDER_DESC',
  SERVICE_TYPE_ASC = 'SERVICE_TYPE_ASC',
  SERVICE_TYPE_DESC = 'SERVICE_TYPE_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  STATEMENT_DATE_ASC = 'STATEMENT_DATE_ASC',
  STATEMENT_DATE_DESC = 'STATEMENT_DATE_DESC',
  STATEMENT_MONTH_ASC = 'STATEMENT_MONTH_ASC',
  STATEMENT_MONTH_DESC = 'STATEMENT_MONTH_DESC',
  STATEMENT_YEAR_ASC = 'STATEMENT_YEAR_ASC',
  STATEMENT_YEAR_DESC = 'STATEMENT_YEAR_DESC',
  TAXES_ASC = 'TAXES_ASC',
  TAXES_DESC = 'TAXES_DESC',
  USAGE_UNITS_ASC = 'USAGE_UNITS_ASC',
  USAGE_UNITS_DESC = 'USAGE_UNITS_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_ID_ASC = 'UTILITY_METER_ID_ASC',
  UTILITY_METER_ID_DESC = 'UTILITY_METER_ID_DESC'
}

export type UtilityMeterStatementStddevPopulationAggregates = {
  __typename?: 'UtilityMeterStatementStddevPopulationAggregates';
  /** Population standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Population standard deviation of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Population standard deviation of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Population standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementStddevSampleAggregates = {
  __typename?: 'UtilityMeterStatementStddevSampleAggregates';
  /** Sample standard deviation of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementSumAggregates = {
  __typename?: 'UtilityMeterStatementSumAggregates';
  /** Sum of accountStatementId across the matching connection */
  accountStatementId: Scalars['BigInt'];
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Sum of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Sum of daysInBill across the matching connection */
  daysInBill: Scalars['BigInt'];
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Sum of meterStatementId across the matching connection */
  meterStatementId: Scalars['BigInt'];
  /** Sum of statementMonth across the matching connection */
  statementMonth: Scalars['BigInt'];
  /** Sum of statementYear across the matching connection */
  statementYear: Scalars['BigInt'];
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
  /** Sum of utilityMeterId across the matching connection */
  utilityMeterId: Scalars['BigInt'];
};

export type UtilityMeterStatementVariancePopulationAggregates = {
  __typename?: 'UtilityMeterStatementVariancePopulationAggregates';
  /** Population variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Population variance of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Population variance of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Population variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStatementVarianceSampleAggregates = {
  __typename?: 'UtilityMeterStatementVarianceSampleAggregates';
  /** Sample variance of accountStatementId across the matching connection */
  accountStatementId?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total charges of a given utility_meter_statements (view) row.
   */
  billCost?: Maybe<Scalars['Float']>;
  /** Sample variance of this field across the matching connection. */
  billDemand?: Maybe<Scalars['Float']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand charges of a given utility_meter_statements (view) row.
   */
  billDemandCost?: Maybe<Scalars['Float']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total demand of a given utility_meter_statements (view) row.
   */
  billUsage?: Maybe<Scalars['Float']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total usage charges of a given utility_meter_statements (view) row.
   */
  billUsageCost?: Maybe<Scalars['Float']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total credits of a given utility_meter_statements (view) row.
   */
  credits?: Maybe<Scalars['Float']>;
  /** Sample variance of daysInBill across the matching connection */
  daysInBill?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total fee charges of a given utility_meter_statements (view) row.
   */
  fees?: Maybe<Scalars['Float']>;
  /** Sample variance of meterStatementId across the matching connection */
  meterStatementId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementMonth across the matching connection */
  statementMonth?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statementYear across the matching connection */
  statementYear?: Maybe<Scalars['BigFloat']>;
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * Returns the total tax charges of a given utility_meter_statements (view) row.
   */
  taxes?: Maybe<Scalars['Float']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of utilityMeterId across the matching connection */
  utilityMeterId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityMeterStatement` values. */
export type UtilityMeterStatementsConnection = {
  __typename?: 'UtilityMeterStatementsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityMeterStatementAggregates>;
  /** A list of edges which contains the `UtilityMeterStatement` and cursor to aid in pagination. */
  edges: Array<UtilityMeterStatementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityMeterStatementAggregates>>;
  /** A list of `UtilityMeterStatement` objects. */
  nodes: Array<UtilityMeterStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityMeterStatement` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityMeterStatement` values. */
export type UtilityMeterStatementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityMeterStatementsGroupBy>;
  having?: InputMaybe<UtilityMeterStatementsHavingInput>;
};

/** A `UtilityMeterStatement` edge in the connection. */
export type UtilityMeterStatementsEdge = {
  __typename?: 'UtilityMeterStatementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityMeterStatement` at the end of the edge. */
  node: UtilityMeterStatement;
};

/** Grouping methods for `UtilityMeterStatement` for usage during aggregation. */
export enum UtilityMeterStatementsGroupBy {
  ACCOUNT_LABEL = 'ACCOUNT_LABEL',
  ACCOUNT_LABEL_DISTINCT = 'ACCOUNT_LABEL_DISTINCT',
  ACCOUNT_STATEMENT_ID = 'ACCOUNT_STATEMENT_ID',
  ACCOUNT_STATEMENT_ID_DISTINCT = 'ACCOUNT_STATEMENT_ID_DISTINCT',
  BILL_ARRIVAL_DATE = 'BILL_ARRIVAL_DATE',
  BILL_ARRIVAL_DATE_DISTINCT = 'BILL_ARRIVAL_DATE_DISTINCT',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_DAY',
  BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR = 'BILL_ARRIVAL_DATE_TRUNCATED_TO_HOUR',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CURRENCY_CODE_DISTINCT = 'CURRENCY_CODE_DISTINCT',
  DAYS_IN_BILL = 'DAYS_IN_BILL',
  DAYS_IN_BILL_DISTINCT = 'DAYS_IN_BILL_DISTINCT',
  DEMAND_UNITS = 'DEMAND_UNITS',
  DEMAND_UNITS_DISTINCT = 'DEMAND_UNITS_DISTINCT',
  END_DATE = 'END_DATE',
  END_DATE_DISTINCT = 'END_DATE_DISTINCT',
  EXCLUDE_USAGE = 'EXCLUDE_USAGE',
  EXCLUDE_USAGE_DISTINCT = 'EXCLUDE_USAGE_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  IS_VALIDATED = 'IS_VALIDATED',
  IS_VALIDATED_DISTINCT = 'IS_VALIDATED_DISTINCT',
  METER_LABEL = 'METER_LABEL',
  METER_LABEL_DISTINCT = 'METER_LABEL_DISTINCT',
  METER_STATEMENT_ID = 'METER_STATEMENT_ID',
  METER_STATEMENT_ID_DISTINCT = 'METER_STATEMENT_ID_DISTINCT',
  PROVIDER = 'PROVIDER',
  PROVIDER_DISTINCT = 'PROVIDER_DISTINCT',
  SERVICE_TYPE = 'SERVICE_TYPE',
  SERVICE_TYPE_DISTINCT = 'SERVICE_TYPE_DISTINCT',
  START_DATE = 'START_DATE',
  START_DATE_DISTINCT = 'START_DATE_DISTINCT',
  STATEMENT_MONTH = 'STATEMENT_MONTH',
  STATEMENT_MONTH_DISTINCT = 'STATEMENT_MONTH_DISTINCT',
  STATEMENT_YEAR = 'STATEMENT_YEAR',
  STATEMENT_YEAR_DISTINCT = 'STATEMENT_YEAR_DISTINCT',
  USAGE_UNITS = 'USAGE_UNITS',
  USAGE_UNITS_DISTINCT = 'USAGE_UNITS_DISTINCT',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT',
  UTILITY_METER_ID = 'UTILITY_METER_ID',
  UTILITY_METER_ID_DISTINCT = 'UTILITY_METER_ID_DISTINCT'
}

export type UtilityMeterStatementsHavingAverageInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingAverageUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingAverageUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctCountUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingDistinctUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingDistinctUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

/** Conditions for `UtilityMeterStatement` aggregates. */
export type UtilityMeterStatementsHavingInput = {
  AND?: InputMaybe<Array<UtilityMeterStatementsHavingInput>>;
  OR?: InputMaybe<Array<UtilityMeterStatementsHavingInput>>;
  average?: InputMaybe<UtilityMeterStatementsHavingAverageInput>;
  distinct?: InputMaybe<UtilityMeterStatementsHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityMeterStatementsHavingDistinctCountInput>;
  max?: InputMaybe<UtilityMeterStatementsHavingMaxInput>;
  min?: InputMaybe<UtilityMeterStatementsHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityMeterStatementsHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityMeterStatementsHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleInput>;
};

export type UtilityMeterStatementsHavingMaxInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingMaxUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsFeesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingMaxUtilityMeterStatementsTaxesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingMinInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingMinUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsFeesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingMinUtilityMeterStatementsTaxesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingStddevPopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevPopulationUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingStddevSampleUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingSumUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsFeesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingSumUtilityMeterStatementsTaxesInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type UtilityMeterStatementsHavingVariancePopulationInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVariancePopulationUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleInput = {
  accountStatementId?: InputMaybe<HavingIntFilter>;
  billArrivalDate?: InputMaybe<HavingDatetimeFilter>;
  billCost?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillCostInput>;
  billDemand?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillDemandInput>;
  billDemandCost?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillDemandCostInput>;
  billUsage?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillUsageInput>;
  billUsageCost?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillUsageCostInput>;
  credits?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsCreditsInput>;
  daysInBill?: InputMaybe<HavingIntFilter>;
  endDate?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  fees?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsFeesInput>;
  meterStatementId?: InputMaybe<HavingIntFilter>;
  startDate?: InputMaybe<HavingDatetimeFilter>;
  statementMonth?: InputMaybe<HavingIntFilter>;
  statementYear?: InputMaybe<HavingIntFilter>;
  taxes?: InputMaybe<UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsTaxesInput>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
  utilityMeterId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillDemandCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillDemandInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillUsageCostInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsBillUsageInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsCreditsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsFeesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type UtilityMeterStatementsHavingVarianceSampleUtilityMeterStatementsTaxesInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

/** Methods to use when ordering `UtilityMeterStatement`. */
export enum UtilityMeterStatementsOrderBy {
  ACCOUNT_LABEL_ASC = 'ACCOUNT_LABEL_ASC',
  ACCOUNT_LABEL_DESC = 'ACCOUNT_LABEL_DESC',
  ACCOUNT_STATEMENT_ID_ASC = 'ACCOUNT_STATEMENT_ID_ASC',
  ACCOUNT_STATEMENT_ID_DESC = 'ACCOUNT_STATEMENT_ID_DESC',
  BILL_ARRIVAL_DATE_ASC = 'BILL_ARRIVAL_DATE_ASC',
  BILL_ARRIVAL_DATE_DESC = 'BILL_ARRIVAL_DATE_DESC',
  CURRENCY_CODE_ASC = 'CURRENCY_CODE_ASC',
  CURRENCY_CODE_DESC = 'CURRENCY_CODE_DESC',
  DAYS_IN_BILL_ASC = 'DAYS_IN_BILL_ASC',
  DAYS_IN_BILL_DESC = 'DAYS_IN_BILL_DESC',
  DEMAND_UNITS_ASC = 'DEMAND_UNITS_ASC',
  DEMAND_UNITS_DESC = 'DEMAND_UNITS_DESC',
  END_DATE_ASC = 'END_DATE_ASC',
  END_DATE_DESC = 'END_DATE_DESC',
  EXCLUDE_USAGE_ASC = 'EXCLUDE_USAGE_ASC',
  EXCLUDE_USAGE_DESC = 'EXCLUDE_USAGE_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  IS_VALIDATED_ASC = 'IS_VALIDATED_ASC',
  IS_VALIDATED_DESC = 'IS_VALIDATED_DESC',
  METER_LABEL_ASC = 'METER_LABEL_ASC',
  METER_LABEL_DESC = 'METER_LABEL_DESC',
  METER_STATEMENT_ID_ASC = 'METER_STATEMENT_ID_ASC',
  METER_STATEMENT_ID_DESC = 'METER_STATEMENT_ID_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  PROVIDER_ASC = 'PROVIDER_ASC',
  PROVIDER_DESC = 'PROVIDER_DESC',
  SERVICE_TYPE_ASC = 'SERVICE_TYPE_ASC',
  SERVICE_TYPE_DESC = 'SERVICE_TYPE_DESC',
  START_DATE_ASC = 'START_DATE_ASC',
  START_DATE_DESC = 'START_DATE_DESC',
  STATEMENT_MONTH_ASC = 'STATEMENT_MONTH_ASC',
  STATEMENT_MONTH_DESC = 'STATEMENT_MONTH_DESC',
  STATEMENT_YEAR_ASC = 'STATEMENT_YEAR_ASC',
  STATEMENT_YEAR_DESC = 'STATEMENT_YEAR_DESC',
  USAGE_UNITS_ASC = 'USAGE_UNITS_ASC',
  USAGE_UNITS_DESC = 'USAGE_UNITS_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_ID_ASC = 'UTILITY_METER_ID_ASC',
  UTILITY_METER_ID_DESC = 'UTILITY_METER_ID_DESC'
}

export type UtilityMeterStddevPopulationAggregates = {
  __typename?: 'UtilityMeterStddevPopulationAggregates';
  /** Population standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterStddevSampleAggregates = {
  __typename?: 'UtilityMeterStddevSampleAggregates';
  /** Sample standard deviation of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterSumAggregates = {
  __typename?: 'UtilityMeterSumAggregates';
  /** Sum of facilityId across the matching connection */
  facilityId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of utilityAccountId across the matching connection */
  utilityAccountId: Scalars['BigInt'];
};

export enum UtilityMeterUsageCategory {
  DEMAND = 'DEMAND',
  USAGE = 'USAGE'
}

/** A filter to be used against UtilityMeterUsageCategory fields. All fields are combined with a logical ‘and.’ */
export type UtilityMeterUsageCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UtilityMeterUsageCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UtilityMeterUsageCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UtilityMeterUsageCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UtilityMeterUsageCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UtilityMeterUsageCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UtilityMeterUsageCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UtilityMeterUsageCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UtilityMeterUsageCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UtilityMeterUsageCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UtilityMeterUsageCategory>>;
};

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeterStatementRollup`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeterStatementRollup`. */
    utilityMeterStatementRollups: UtilityMeterStatementRollupsConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatementRollup`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementRollupUtilityMeterIdAndUtilityAccountIdManyToManyEdgeUtilityMeterStatementRollupsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementRollupCondition>;
    filter?: InputMaybe<UtilityMeterStatementRollupFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementRollupsOrderBy>>;
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyConnection =
  {
    __typename?: 'UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyConnection';
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<UtilityAccountAggregates>;
    /** A list of edges which contains the `UtilityAccount`, info from the `UtilityMeterStatement`, and the cursor to aid in pagination. */
    edges: Array<UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyEdge>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<UtilityAccountAggregates>>;
    /** A list of `UtilityAccount` objects. */
    nodes: Array<UtilityAccount>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `UtilityAccount` you could get from the connection. */
    totalCount: Scalars['Int'];
  };

/** A connection to a list of `UtilityAccount` values, with data from `UtilityMeterStatement`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<UtilityAccountsGroupBy>;
    having?: InputMaybe<UtilityAccountsHavingInput>;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyEdge =
  {
    __typename?: 'UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `UtilityAccount` at the end of the edge. */
    node: UtilityAccount;
    /** Reads and enables pagination through a set of `UtilityMeterStatement`. */
    utilityMeterStatements: UtilityMeterStatementsConnection;
  };

/** A `UtilityAccount` edge in the connection, with data from `UtilityMeterStatement`. */
export type UtilityMeterUtilityAccountsByUtilityMeterStatementUtilityMeterIdAndUtilityAccountIdManyToManyEdgeUtilityMeterStatementsArgs =
  {
    after?: InputMaybe<Scalars['Cursor']>;
    before?: InputMaybe<Scalars['Cursor']>;
    condition?: InputMaybe<UtilityMeterStatementCondition>;
    filter?: InputMaybe<UtilityMeterStatementFilter>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Array<UtilityMeterStatementsOrderBy>>;
  };

export type UtilityMeterVariancePopulationAggregates = {
  __typename?: 'UtilityMeterVariancePopulationAggregates';
  /** Population variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

export type UtilityMeterVarianceSampleAggregates = {
  __typename?: 'UtilityMeterVarianceSampleAggregates';
  /** Sample variance of facilityId across the matching connection */
  facilityId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of utilityAccountId across the matching connection */
  utilityAccountId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityMeter` values. */
export type UtilityMetersConnection = {
  __typename?: 'UtilityMetersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityMeterAggregates>;
  /** A list of edges which contains the `UtilityMeter` and cursor to aid in pagination. */
  edges: Array<UtilityMetersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityMeterAggregates>>;
  /** A list of `UtilityMeter` objects. */
  nodes: Array<UtilityMeter>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityMeter` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityMeter` values. */
export type UtilityMetersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityMetersGroupBy>;
  having?: InputMaybe<UtilityMetersHavingInput>;
};

/** A `UtilityMeter` edge in the connection. */
export type UtilityMetersEdge = {
  __typename?: 'UtilityMetersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityMeter` at the end of the edge. */
  node: UtilityMeter;
};

/** Grouping methods for `UtilityMeter` for usage during aggregation. */
export enum UtilityMetersGroupBy {
  ACTIVE_END = 'ACTIVE_END',
  ACTIVE_END_DISTINCT = 'ACTIVE_END_DISTINCT',
  ACTIVE_START = 'ACTIVE_START',
  ACTIVE_START_DISTINCT = 'ACTIVE_START_DISTINCT',
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  DELETED_AT = 'DELETED_AT',
  DELETED_AT_DISTINCT = 'DELETED_AT_DISTINCT',
  DELETED_AT_TRUNCATED_TO_DAY = 'DELETED_AT_TRUNCATED_TO_DAY',
  DELETED_AT_TRUNCATED_TO_HOUR = 'DELETED_AT_TRUNCATED_TO_HOUR',
  EXCLUDE_USAGE = 'EXCLUDE_USAGE',
  EXCLUDE_USAGE_DISTINCT = 'EXCLUDE_USAGE_DISTINCT',
  FACILITY_ID = 'FACILITY_ID',
  FACILITY_ID_DISTINCT = 'FACILITY_ID_DISTINCT',
  INTEGRATION_METER_KEY = 'INTEGRATION_METER_KEY',
  INTEGRATION_METER_KEY_DISTINCT = 'INTEGRATION_METER_KEY_DISTINCT',
  METADATA = 'METADATA',
  METADATA_DISTINCT = 'METADATA_DISTINCT',
  METER_NUMBER = 'METER_NUMBER',
  METER_NUMBER_DISTINCT = 'METER_NUMBER_DISTINCT',
  NAME = 'NAME',
  NAME_DISTINCT = 'NAME_DISTINCT',
  OVERRIDE_AT = 'OVERRIDE_AT',
  OVERRIDE_AT_DISTINCT = 'OVERRIDE_AT_DISTINCT',
  OVERRIDE_AT_TRUNCATED_TO_DAY = 'OVERRIDE_AT_TRUNCATED_TO_DAY',
  OVERRIDE_AT_TRUNCATED_TO_HOUR = 'OVERRIDE_AT_TRUNCATED_TO_HOUR',
  OVERRIDE_METER_NUMBER = 'OVERRIDE_METER_NUMBER',
  OVERRIDE_METER_NUMBER_DISTINCT = 'OVERRIDE_METER_NUMBER_DISTINCT',
  SERVICE_TYPE = 'SERVICE_TYPE',
  SERVICE_TYPE_DISTINCT = 'SERVICE_TYPE_DISTINCT',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UTILITY_ACCOUNT_ID = 'UTILITY_ACCOUNT_ID',
  UTILITY_ACCOUNT_ID_DISTINCT = 'UTILITY_ACCOUNT_ID_DISTINCT'
}

export type UtilityMetersHavingAverageInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingDistinctCountInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingDistinctInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UtilityMeter` aggregates. */
export type UtilityMetersHavingInput = {
  AND?: InputMaybe<Array<UtilityMetersHavingInput>>;
  OR?: InputMaybe<Array<UtilityMetersHavingInput>>;
  average?: InputMaybe<UtilityMetersHavingAverageInput>;
  distinct?: InputMaybe<UtilityMetersHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityMetersHavingDistinctCountInput>;
  max?: InputMaybe<UtilityMetersHavingMaxInput>;
  min?: InputMaybe<UtilityMetersHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityMetersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityMetersHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityMetersHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityMetersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityMetersHavingVarianceSampleInput>;
};

export type UtilityMetersHavingMaxInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingMinInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingStddevPopulationInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingStddevSampleInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingSumInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingVariancePopulationInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

export type UtilityMetersHavingVarianceSampleInput = {
  activeEnd?: InputMaybe<HavingDatetimeFilter>;
  activeStart?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  deletedAt?: InputMaybe<HavingDatetimeFilter>;
  facilityId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  overrideAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  utilityAccountId?: InputMaybe<HavingIntFilter>;
};

/** A `UtilityMetersMissingStatementsRecord` edge in the connection. */
export type UtilityMetersMissingStatementEdge = {
  __typename?: 'UtilityMetersMissingStatementEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityMetersMissingStatementsRecord` at the end of the edge. */
  node: UtilityMetersMissingStatementsRecord;
};

/** A connection to a list of `UtilityMetersMissingStatementsRecord` values. */
export type UtilityMetersMissingStatementsConnection = {
  __typename?: 'UtilityMetersMissingStatementsConnection';
  /** A list of edges which contains the `UtilityMetersMissingStatementsRecord` and cursor to aid in pagination. */
  edges: Array<UtilityMetersMissingStatementEdge>;
  /** A list of `UtilityMetersMissingStatementsRecord` objects. */
  nodes: Array<UtilityMetersMissingStatementsRecord>;
  /** The count of *all* `UtilityMetersMissingStatementsRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `missingStatements` query. */
export type UtilityMetersMissingStatementsRecord = {
  __typename?: 'UtilityMetersMissingStatementsRecord';
  facilityId?: Maybe<Scalars['Int']>;
  statementDate?: Maybe<Scalars['Date']>;
  statementMonth?: Maybe<Scalars['Int']>;
  statementYear?: Maybe<Scalars['Int']>;
  utilityMeterId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `UtilityMetersMissingStatementsRecord` object types. All fields are combined with a logical ‘and.’ */
export type UtilityMetersMissingStatementsRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityMetersMissingStatementsRecordFilter>>;
  /** Filter by the object’s `facilityId` field. */
  facilityId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityMetersMissingStatementsRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityMetersMissingStatementsRecordFilter>>;
  /** Filter by the object’s `statementDate` field. */
  statementDate?: InputMaybe<DateFilter>;
  /** Filter by the object’s `statementMonth` field. */
  statementMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `statementYear` field. */
  statementYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `utilityMeterId` field. */
  utilityMeterId?: InputMaybe<IntFilter>;
};

/** Methods to use when ordering `UtilityMeter`. */
export enum UtilityMetersOrderBy {
  ACTIVE_END_ASC = 'ACTIVE_END_ASC',
  ACTIVE_END_DESC = 'ACTIVE_END_DESC',
  ACTIVE_START_ASC = 'ACTIVE_START_ASC',
  ACTIVE_START_DESC = 'ACTIVE_START_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  DELETED_AT_ASC = 'DELETED_AT_ASC',
  DELETED_AT_DESC = 'DELETED_AT_DESC',
  EXCLUDE_USAGE_ASC = 'EXCLUDE_USAGE_ASC',
  EXCLUDE_USAGE_DESC = 'EXCLUDE_USAGE_DESC',
  FACILITY_ID_ASC = 'FACILITY_ID_ASC',
  FACILITY_ID_DESC = 'FACILITY_ID_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  INTEGRATION_METER_KEY_ASC = 'INTEGRATION_METER_KEY_ASC',
  INTEGRATION_METER_KEY_DESC = 'INTEGRATION_METER_KEY_DESC',
  METADATA_ASC = 'METADATA_ASC',
  METADATA_DESC = 'METADATA_DESC',
  METER_NUMBER_ASC = 'METER_NUMBER_ASC',
  METER_NUMBER_DESC = 'METER_NUMBER_DESC',
  METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_AVERAGE_CREATED_AT_ASC = 'METER_STATEMENTS_AVERAGE_CREATED_AT_ASC',
  METER_STATEMENTS_AVERAGE_CREATED_AT_DESC = 'METER_STATEMENTS_AVERAGE_CREATED_AT_DESC',
  METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_DELETED_AT_ASC = 'METER_STATEMENTS_AVERAGE_DELETED_AT_ASC',
  METER_STATEMENTS_AVERAGE_DELETED_AT_DESC = 'METER_STATEMENTS_AVERAGE_DELETED_AT_DESC',
  METER_STATEMENTS_AVERAGE_ID_ASC = 'METER_STATEMENTS_AVERAGE_ID_ASC',
  METER_STATEMENTS_AVERAGE_ID_DESC = 'METER_STATEMENTS_AVERAGE_ID_DESC',
  METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_AVERAGE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_AVERAGE_INTERVAL_END_ASC = 'METER_STATEMENTS_AVERAGE_INTERVAL_END_ASC',
  METER_STATEMENTS_AVERAGE_INTERVAL_END_DESC = 'METER_STATEMENTS_AVERAGE_INTERVAL_END_DESC',
  METER_STATEMENTS_AVERAGE_INTERVAL_START_ASC = 'METER_STATEMENTS_AVERAGE_INTERVAL_START_ASC',
  METER_STATEMENTS_AVERAGE_INTERVAL_START_DESC = 'METER_STATEMENTS_AVERAGE_INTERVAL_START_DESC',
  METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_AVERAGE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_AVERAGE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_AVERAGE_METADATA_ASC = 'METER_STATEMENTS_AVERAGE_METADATA_ASC',
  METER_STATEMENTS_AVERAGE_METADATA_DESC = 'METER_STATEMENTS_AVERAGE_METADATA_DESC',
  METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_PDF_LINK_ASC = 'METER_STATEMENTS_AVERAGE_PDF_LINK_ASC',
  METER_STATEMENTS_AVERAGE_PDF_LINK_DESC = 'METER_STATEMENTS_AVERAGE_PDF_LINK_DESC',
  METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_AVERAGE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_AVERAGE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_AVERAGE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_AVERAGE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_AVERAGE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_AVERAGE_UPDATED_AT_ASC = 'METER_STATEMENTS_AVERAGE_UPDATED_AT_ASC',
  METER_STATEMENTS_AVERAGE_UPDATED_AT_DESC = 'METER_STATEMENTS_AVERAGE_UPDATED_AT_DESC',
  METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_COUNT_ASC = 'METER_STATEMENTS_COUNT_ASC',
  METER_STATEMENTS_COUNT_DESC = 'METER_STATEMENTS_COUNT_DESC',
  METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_CREATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_DELETED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_ID_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_END_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_INTERVAL_START_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_METADATA_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_METADATA_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_METADATA_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_METADATA_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PDF_LINK_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_DATE_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_DISTINCT_CREATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_CREATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_CREATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_CREATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_DELETED_AT_ASC = 'METER_STATEMENTS_DISTINCT_DELETED_AT_ASC',
  METER_STATEMENTS_DISTINCT_DELETED_AT_DESC = 'METER_STATEMENTS_DISTINCT_DELETED_AT_DESC',
  METER_STATEMENTS_DISTINCT_ID_ASC = 'METER_STATEMENTS_DISTINCT_ID_ASC',
  METER_STATEMENTS_DISTINCT_ID_DESC = 'METER_STATEMENTS_DISTINCT_ID_DESC',
  METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_DISTINCT_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_DISTINCT_INTERVAL_END_ASC = 'METER_STATEMENTS_DISTINCT_INTERVAL_END_ASC',
  METER_STATEMENTS_DISTINCT_INTERVAL_END_DESC = 'METER_STATEMENTS_DISTINCT_INTERVAL_END_DESC',
  METER_STATEMENTS_DISTINCT_INTERVAL_START_ASC = 'METER_STATEMENTS_DISTINCT_INTERVAL_START_ASC',
  METER_STATEMENTS_DISTINCT_INTERVAL_START_DESC = 'METER_STATEMENTS_DISTINCT_INTERVAL_START_DESC',
  METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_DISTINCT_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_DISTINCT_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_DISTINCT_METADATA_ASC = 'METER_STATEMENTS_DISTINCT_METADATA_ASC',
  METER_STATEMENTS_DISTINCT_METADATA_DESC = 'METER_STATEMENTS_DISTINCT_METADATA_DESC',
  METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_AT_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_AT_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_PDF_LINK_ASC = 'METER_STATEMENTS_DISTINCT_PDF_LINK_ASC',
  METER_STATEMENTS_DISTINCT_PDF_LINK_DESC = 'METER_STATEMENTS_DISTINCT_PDF_LINK_DESC',
  METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_DISTINCT_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_DISTINCT_STATEMENT_DATE_ASC = 'METER_STATEMENTS_DISTINCT_STATEMENT_DATE_ASC',
  METER_STATEMENTS_DISTINCT_STATEMENT_DATE_DESC = 'METER_STATEMENTS_DISTINCT_STATEMENT_DATE_DESC',
  METER_STATEMENTS_DISTINCT_UPDATED_AT_ASC = 'METER_STATEMENTS_DISTINCT_UPDATED_AT_ASC',
  METER_STATEMENTS_DISTINCT_UPDATED_AT_DESC = 'METER_STATEMENTS_DISTINCT_UPDATED_AT_DESC',
  METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_MAX_CREATED_AT_ASC = 'METER_STATEMENTS_MAX_CREATED_AT_ASC',
  METER_STATEMENTS_MAX_CREATED_AT_DESC = 'METER_STATEMENTS_MAX_CREATED_AT_DESC',
  METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_DELETED_AT_ASC = 'METER_STATEMENTS_MAX_DELETED_AT_ASC',
  METER_STATEMENTS_MAX_DELETED_AT_DESC = 'METER_STATEMENTS_MAX_DELETED_AT_DESC',
  METER_STATEMENTS_MAX_ID_ASC = 'METER_STATEMENTS_MAX_ID_ASC',
  METER_STATEMENTS_MAX_ID_DESC = 'METER_STATEMENTS_MAX_ID_DESC',
  METER_STATEMENTS_MAX_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_MAX_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_MAX_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_MAX_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_MAX_INTERVAL_END_ASC = 'METER_STATEMENTS_MAX_INTERVAL_END_ASC',
  METER_STATEMENTS_MAX_INTERVAL_END_DESC = 'METER_STATEMENTS_MAX_INTERVAL_END_DESC',
  METER_STATEMENTS_MAX_INTERVAL_START_ASC = 'METER_STATEMENTS_MAX_INTERVAL_START_ASC',
  METER_STATEMENTS_MAX_INTERVAL_START_DESC = 'METER_STATEMENTS_MAX_INTERVAL_START_DESC',
  METER_STATEMENTS_MAX_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_MAX_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_MAX_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_MAX_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  METER_STATEMENTS_MAX_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_MAX_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_MAX_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_MAX_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_MAX_METADATA_ASC = 'METER_STATEMENTS_MAX_METADATA_ASC',
  METER_STATEMENTS_MAX_METADATA_DESC = 'METER_STATEMENTS_MAX_METADATA_DESC',
  METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_AT_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_AT_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_AT_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_AT_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_MAX_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_MAX_PDF_LINK_ASC = 'METER_STATEMENTS_MAX_PDF_LINK_ASC',
  METER_STATEMENTS_MAX_PDF_LINK_DESC = 'METER_STATEMENTS_MAX_PDF_LINK_DESC',
  METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MAX_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_MAX_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_MAX_STATEMENT_DATE_ASC = 'METER_STATEMENTS_MAX_STATEMENT_DATE_ASC',
  METER_STATEMENTS_MAX_STATEMENT_DATE_DESC = 'METER_STATEMENTS_MAX_STATEMENT_DATE_DESC',
  METER_STATEMENTS_MAX_UPDATED_AT_ASC = 'METER_STATEMENTS_MAX_UPDATED_AT_ASC',
  METER_STATEMENTS_MAX_UPDATED_AT_DESC = 'METER_STATEMENTS_MAX_UPDATED_AT_DESC',
  METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_MIN_CREATED_AT_ASC = 'METER_STATEMENTS_MIN_CREATED_AT_ASC',
  METER_STATEMENTS_MIN_CREATED_AT_DESC = 'METER_STATEMENTS_MIN_CREATED_AT_DESC',
  METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_DELETED_AT_ASC = 'METER_STATEMENTS_MIN_DELETED_AT_ASC',
  METER_STATEMENTS_MIN_DELETED_AT_DESC = 'METER_STATEMENTS_MIN_DELETED_AT_DESC',
  METER_STATEMENTS_MIN_ID_ASC = 'METER_STATEMENTS_MIN_ID_ASC',
  METER_STATEMENTS_MIN_ID_DESC = 'METER_STATEMENTS_MIN_ID_DESC',
  METER_STATEMENTS_MIN_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_MIN_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_MIN_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_MIN_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_MIN_INTERVAL_END_ASC = 'METER_STATEMENTS_MIN_INTERVAL_END_ASC',
  METER_STATEMENTS_MIN_INTERVAL_END_DESC = 'METER_STATEMENTS_MIN_INTERVAL_END_DESC',
  METER_STATEMENTS_MIN_INTERVAL_START_ASC = 'METER_STATEMENTS_MIN_INTERVAL_START_ASC',
  METER_STATEMENTS_MIN_INTERVAL_START_DESC = 'METER_STATEMENTS_MIN_INTERVAL_START_DESC',
  METER_STATEMENTS_MIN_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_MIN_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_MIN_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_MIN_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  METER_STATEMENTS_MIN_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_MIN_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_MIN_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_MIN_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_MIN_METADATA_ASC = 'METER_STATEMENTS_MIN_METADATA_ASC',
  METER_STATEMENTS_MIN_METADATA_DESC = 'METER_STATEMENTS_MIN_METADATA_DESC',
  METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_AT_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_AT_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_AT_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_AT_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_MIN_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_MIN_PDF_LINK_ASC = 'METER_STATEMENTS_MIN_PDF_LINK_ASC',
  METER_STATEMENTS_MIN_PDF_LINK_DESC = 'METER_STATEMENTS_MIN_PDF_LINK_DESC',
  METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_MIN_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_MIN_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_MIN_STATEMENT_DATE_ASC = 'METER_STATEMENTS_MIN_STATEMENT_DATE_ASC',
  METER_STATEMENTS_MIN_STATEMENT_DATE_DESC = 'METER_STATEMENTS_MIN_STATEMENT_DATE_DESC',
  METER_STATEMENTS_MIN_UPDATED_AT_ASC = 'METER_STATEMENTS_MIN_UPDATED_AT_ASC',
  METER_STATEMENTS_MIN_UPDATED_AT_DESC = 'METER_STATEMENTS_MIN_UPDATED_AT_DESC',
  METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_CREATED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_DELETED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_ID_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_END_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_INTERVAL_START_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_METADATA_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_METADATA_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_METADATA_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_METADATA_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PDF_LINK_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_DATE_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_DELETED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_ID_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_END_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_INTERVAL_START_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_METADATA_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_METADATA_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PDF_LINK_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_SUM_CREATED_AT_ASC = 'METER_STATEMENTS_SUM_CREATED_AT_ASC',
  METER_STATEMENTS_SUM_CREATED_AT_DESC = 'METER_STATEMENTS_SUM_CREATED_AT_DESC',
  METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_DELETED_AT_ASC = 'METER_STATEMENTS_SUM_DELETED_AT_ASC',
  METER_STATEMENTS_SUM_DELETED_AT_DESC = 'METER_STATEMENTS_SUM_DELETED_AT_DESC',
  METER_STATEMENTS_SUM_ID_ASC = 'METER_STATEMENTS_SUM_ID_ASC',
  METER_STATEMENTS_SUM_ID_DESC = 'METER_STATEMENTS_SUM_ID_DESC',
  METER_STATEMENTS_SUM_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_SUM_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_SUM_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_SUM_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_SUM_INTERVAL_END_ASC = 'METER_STATEMENTS_SUM_INTERVAL_END_ASC',
  METER_STATEMENTS_SUM_INTERVAL_END_DESC = 'METER_STATEMENTS_SUM_INTERVAL_END_DESC',
  METER_STATEMENTS_SUM_INTERVAL_START_ASC = 'METER_STATEMENTS_SUM_INTERVAL_START_ASC',
  METER_STATEMENTS_SUM_INTERVAL_START_DESC = 'METER_STATEMENTS_SUM_INTERVAL_START_DESC',
  METER_STATEMENTS_SUM_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_SUM_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_SUM_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_SUM_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  METER_STATEMENTS_SUM_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_SUM_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_SUM_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_SUM_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_SUM_METADATA_ASC = 'METER_STATEMENTS_SUM_METADATA_ASC',
  METER_STATEMENTS_SUM_METADATA_DESC = 'METER_STATEMENTS_SUM_METADATA_DESC',
  METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_AT_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_AT_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_AT_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_AT_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_SUM_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_SUM_PDF_LINK_ASC = 'METER_STATEMENTS_SUM_PDF_LINK_ASC',
  METER_STATEMENTS_SUM_PDF_LINK_DESC = 'METER_STATEMENTS_SUM_PDF_LINK_DESC',
  METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_SUM_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_SUM_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_SUM_STATEMENT_DATE_ASC = 'METER_STATEMENTS_SUM_STATEMENT_DATE_ASC',
  METER_STATEMENTS_SUM_STATEMENT_DATE_DESC = 'METER_STATEMENTS_SUM_STATEMENT_DATE_DESC',
  METER_STATEMENTS_SUM_UPDATED_AT_ASC = 'METER_STATEMENTS_SUM_UPDATED_AT_ASC',
  METER_STATEMENTS_SUM_UPDATED_AT_DESC = 'METER_STATEMENTS_SUM_UPDATED_AT_DESC',
  METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_DELETED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_ID_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_END_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_INTERVAL_START_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_METADATA_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_METADATA_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PDF_LINK_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_DATE_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_ID_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTEGRATION_KEY_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_END_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_INTERVAL_START_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_ADJUSTMENT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_MANUAL_UPLOAD_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_METADATA_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_NORMALIZED_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_CURRENCY_CODE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_MONTH_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_STATEMENT_YEAR_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_OVERRIDE_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PDF_LINK_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_DEMAND_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_PRIMARY_USAGE_UNITS_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_DATE_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  OVERRIDE_AT_ASC = 'OVERRIDE_AT_ASC',
  OVERRIDE_AT_DESC = 'OVERRIDE_AT_DESC',
  OVERRIDE_METER_NUMBER_ASC = 'OVERRIDE_METER_NUMBER_ASC',
  OVERRIDE_METER_NUMBER_DESC = 'OVERRIDE_METER_NUMBER_DESC',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  SERVICE_TYPE_ASC = 'SERVICE_TYPE_ASC',
  SERVICE_TYPE_DESC = 'SERVICE_TYPE_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_ACCOUNT_ID_ASC = 'UTILITY_ACCOUNT_ID_ASC',
  UTILITY_ACCOUNT_ID_DESC = 'UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_COUNT_ASC = 'UTILITY_METER_STATEMENTS_COUNT_ASC',
  UTILITY_METER_STATEMENTS_COUNT_DESC = 'UTILITY_METER_STATEMENTS_COUNT_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MAX_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_MIN_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_SUM_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_EXCLUDE_USAGE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENTS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_AVERAGE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_COUNT_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_COUNT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_DISTINCT_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MAX_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_MIN_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_STDDEV_SAMPLE_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_SUM_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_POPULATION_UTILITY_METER_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_ACCOUNT_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_ARRIVAL_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_DEMAND_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_COST_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_BLENDED_RATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CREDITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_CURRENCY_CODE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DAYS_IN_BILL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_DEMAND_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_END_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FACILITY_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_FEES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_IS_VALIDATED_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_LOAD_FACTOR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_LABEL_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_METER_STATEMENT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_NET_BILL_USAGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PERCENT_CHANGE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_PROVIDER_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_SERVICE_TYPE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_START_DATE_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_MONTH_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_STATEMENT_YEAR_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_TAXES_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_USAGE_UNITS_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_ACCOUNT_ID_DESC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_ASC',
  UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC = 'UTILITY_METER_STATEMENT_ROLLUPS_VARIANCE_SAMPLE_UTILITY_METER_ID_DESC'
}

/** A utility provider. */
export type UtilityProvider = Node & {
  __typename?: 'UtilityProvider';
  /** The time this provider was created. */
  createdAt: Scalars['Datetime'];
  /** The primary unique identifier for the provider. */
  id: Scalars['Int'];
  /** The name of the provider. */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The time this provider was updated. */
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UtilityAccount`. */
  utilityAccounts: UtilityAccountsConnection;
};

/** A utility provider. */
export type UtilityProviderUtilityAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UtilityAccountCondition>;
  filter?: InputMaybe<UtilityAccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UtilityAccountsOrderBy>>;
};

export type UtilityProviderAggregates = {
  __typename?: 'UtilityProviderAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UtilityProviderAverageAggregates>;
  /** Distinct aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinct?: Maybe<UtilityProviderDistinctAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UtilityProviderDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UtilityProviderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UtilityProviderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UtilityProviderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UtilityProviderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UtilityProviderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UtilityProviderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UtilityProviderVarianceSampleAggregates>;
};

export type UtilityProviderAverageAggregates = {
  __typename?: 'UtilityProviderAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `UtilityProvider` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UtilityProviderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type UtilityProviderDistinctAggregates = {
  __typename?: 'UtilityProviderDistinctAggregates';
  /** Distinct of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['JSON']>;
  /** Distinct of id across the matching connection */
  id?: Maybe<Scalars['JSON']>;
  /** Distinct of name across the matching connection */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Distinct of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['JSON']>;
};

export type UtilityProviderDistinctCountAggregates = {
  __typename?: 'UtilityProviderDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UtilityProvider` object types. All fields are combined with a logical ‘and.’ */
export type UtilityProviderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UtilityProviderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UtilityProviderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UtilityProviderFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `UtilityProvider` */
export type UtilityProviderInput = {
  /** The primary unique identifier for the provider. */
  id?: InputMaybe<Scalars['Int']>;
  /** The name of the provider. */
  name: Scalars['String'];
};

export type UtilityProviderMaxAggregates = {
  __typename?: 'UtilityProviderMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type UtilityProviderMinAggregates = {
  __typename?: 'UtilityProviderMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `UtilityProvider`. Fields that are set will be updated. */
export type UtilityProviderPatch = {
  /** The name of the provider. */
  name?: InputMaybe<Scalars['String']>;
};

export type UtilityProviderStddevPopulationAggregates = {
  __typename?: 'UtilityProviderStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UtilityProviderStddevSampleAggregates = {
  __typename?: 'UtilityProviderStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UtilityProviderSumAggregates = {
  __typename?: 'UtilityProviderSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type UtilityProviderVariancePopulationAggregates = {
  __typename?: 'UtilityProviderVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type UtilityProviderVarianceSampleAggregates = {
  __typename?: 'UtilityProviderVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `UtilityProvider` values. */
export type UtilityProvidersConnection = {
  __typename?: 'UtilityProvidersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UtilityProviderAggregates>;
  /** A list of edges which contains the `UtilityProvider` and cursor to aid in pagination. */
  edges: Array<UtilityProvidersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UtilityProviderAggregates>>;
  /** A list of `UtilityProvider` objects. */
  nodes: Array<UtilityProvider>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UtilityProvider` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `UtilityProvider` values. */
export type UtilityProvidersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UtilityProvidersGroupBy>;
  having?: InputMaybe<UtilityProvidersHavingInput>;
};

/** A `UtilityProvider` edge in the connection. */
export type UtilityProvidersEdge = {
  __typename?: 'UtilityProvidersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UtilityProvider` at the end of the edge. */
  node: UtilityProvider;
};

/** Grouping methods for `UtilityProvider` for usage during aggregation. */
export enum UtilityProvidersGroupBy {
  CREATED_AT = 'CREATED_AT',
  CREATED_AT_DISTINCT = 'CREATED_AT_DISTINCT',
  CREATED_AT_TRUNCATED_TO_DAY = 'CREATED_AT_TRUNCATED_TO_DAY',
  CREATED_AT_TRUNCATED_TO_HOUR = 'CREATED_AT_TRUNCATED_TO_HOUR',
  UPDATED_AT = 'UPDATED_AT',
  UPDATED_AT_DISTINCT = 'UPDATED_AT_DISTINCT',
  UPDATED_AT_TRUNCATED_TO_DAY = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UPDATED_AT_TRUNCATED_TO_HOUR = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type UtilityProvidersHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingDistinctInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UtilityProvider` aggregates. */
export type UtilityProvidersHavingInput = {
  AND?: InputMaybe<Array<UtilityProvidersHavingInput>>;
  OR?: InputMaybe<Array<UtilityProvidersHavingInput>>;
  average?: InputMaybe<UtilityProvidersHavingAverageInput>;
  distinct?: InputMaybe<UtilityProvidersHavingDistinctInput>;
  distinctCount?: InputMaybe<UtilityProvidersHavingDistinctCountInput>;
  max?: InputMaybe<UtilityProvidersHavingMaxInput>;
  min?: InputMaybe<UtilityProvidersHavingMinInput>;
  stddevPopulation?: InputMaybe<UtilityProvidersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UtilityProvidersHavingStddevSampleInput>;
  sum?: InputMaybe<UtilityProvidersHavingSumInput>;
  variancePopulation?: InputMaybe<UtilityProvidersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UtilityProvidersHavingVarianceSampleInput>;
};

export type UtilityProvidersHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UtilityProvidersHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `UtilityProvider`. */
export enum UtilityProvidersOrderBy {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  NATURAL = 'NATURAL',
  PRIMARY_KEY_ASC = 'PRIMARY_KEY_ASC',
  PRIMARY_KEY_DESC = 'PRIMARY_KEY_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_AVERAGE_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_AVERAGE_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_AVERAGE_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_AVERAGE_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_AVERAGE_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_AVERAGE_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_AVERAGE_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_AVERAGE_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_AVERAGE_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_AVERAGE_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_AVERAGE_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_AVERAGE_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_AVERAGE_ID_ASC = 'UTILITY_ACCOUNTS_AVERAGE_ID_ASC',
  UTILITY_ACCOUNTS_AVERAGE_ID_DESC = 'UTILITY_ACCOUNTS_AVERAGE_ID_DESC',
  UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_AVERAGE_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_AVERAGE_METADATA_ASC = 'UTILITY_ACCOUNTS_AVERAGE_METADATA_ASC',
  UTILITY_ACCOUNTS_AVERAGE_METADATA_DESC = 'UTILITY_ACCOUNTS_AVERAGE_METADATA_DESC',
  UTILITY_ACCOUNTS_AVERAGE_NAME_ASC = 'UTILITY_ACCOUNTS_AVERAGE_NAME_ASC',
  UTILITY_ACCOUNTS_AVERAGE_NAME_DESC = 'UTILITY_ACCOUNTS_AVERAGE_NAME_DESC',
  UTILITY_ACCOUNTS_AVERAGE_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_AVERAGE_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_AVERAGE_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_AVERAGE_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_AVERAGE_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_AVERAGE_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_AVERAGE_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_AVERAGE_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_COUNT_ASC = 'UTILITY_ACCOUNTS_COUNT_ASC',
  UTILITY_ACCOUNTS_COUNT_DESC = 'UTILITY_ACCOUNTS_COUNT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_DISTINCT_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_DISTINCT_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_DISTINCT_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_ID_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_ID_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_ID_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_ID_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_METADATA_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_METADATA_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_METADATA_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_METADATA_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_NAME_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_NAME_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_NAME_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_NAME_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_DISTINCT_COUNT_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_DISTINCT_COUNT_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_DISTINCT_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_ID_ASC = 'UTILITY_ACCOUNTS_DISTINCT_ID_ASC',
  UTILITY_ACCOUNTS_DISTINCT_ID_DESC = 'UTILITY_ACCOUNTS_DISTINCT_ID_DESC',
  UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_DISTINCT_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_DISTINCT_METADATA_ASC = 'UTILITY_ACCOUNTS_DISTINCT_METADATA_ASC',
  UTILITY_ACCOUNTS_DISTINCT_METADATA_DESC = 'UTILITY_ACCOUNTS_DISTINCT_METADATA_DESC',
  UTILITY_ACCOUNTS_DISTINCT_NAME_ASC = 'UTILITY_ACCOUNTS_DISTINCT_NAME_ASC',
  UTILITY_ACCOUNTS_DISTINCT_NAME_DESC = 'UTILITY_ACCOUNTS_DISTINCT_NAME_DESC',
  UTILITY_ACCOUNTS_DISTINCT_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_DISTINCT_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_DISTINCT_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_DISTINCT_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_DISTINCT_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_DISTINCT_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_DISTINCT_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_DISTINCT_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_MAX_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_MAX_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_MAX_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_MAX_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_MAX_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_MAX_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_MAX_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_MAX_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_MAX_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_MAX_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_MAX_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_MAX_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_MAX_ID_ASC = 'UTILITY_ACCOUNTS_MAX_ID_ASC',
  UTILITY_ACCOUNTS_MAX_ID_DESC = 'UTILITY_ACCOUNTS_MAX_ID_DESC',
  UTILITY_ACCOUNTS_MAX_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_MAX_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_MAX_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_MAX_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_MAX_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_MAX_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_MAX_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_MAX_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_MAX_METADATA_ASC = 'UTILITY_ACCOUNTS_MAX_METADATA_ASC',
  UTILITY_ACCOUNTS_MAX_METADATA_DESC = 'UTILITY_ACCOUNTS_MAX_METADATA_DESC',
  UTILITY_ACCOUNTS_MAX_NAME_ASC = 'UTILITY_ACCOUNTS_MAX_NAME_ASC',
  UTILITY_ACCOUNTS_MAX_NAME_DESC = 'UTILITY_ACCOUNTS_MAX_NAME_DESC',
  UTILITY_ACCOUNTS_MAX_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_MAX_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_MAX_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_MAX_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_MAX_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_MAX_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_MAX_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_MAX_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_MIN_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_MIN_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_MIN_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_MIN_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_MIN_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_MIN_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_MIN_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_MIN_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_MIN_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_MIN_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_MIN_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_MIN_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_MIN_ID_ASC = 'UTILITY_ACCOUNTS_MIN_ID_ASC',
  UTILITY_ACCOUNTS_MIN_ID_DESC = 'UTILITY_ACCOUNTS_MIN_ID_DESC',
  UTILITY_ACCOUNTS_MIN_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_MIN_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_MIN_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_MIN_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_MIN_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_MIN_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_MIN_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_MIN_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_MIN_METADATA_ASC = 'UTILITY_ACCOUNTS_MIN_METADATA_ASC',
  UTILITY_ACCOUNTS_MIN_METADATA_DESC = 'UTILITY_ACCOUNTS_MIN_METADATA_DESC',
  UTILITY_ACCOUNTS_MIN_NAME_ASC = 'UTILITY_ACCOUNTS_MIN_NAME_ASC',
  UTILITY_ACCOUNTS_MIN_NAME_DESC = 'UTILITY_ACCOUNTS_MIN_NAME_DESC',
  UTILITY_ACCOUNTS_MIN_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_MIN_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_MIN_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_MIN_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_MIN_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_MIN_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_MIN_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_MIN_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_ID_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_ID_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_ID_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_ID_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_METADATA_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_METADATA_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_METADATA_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_METADATA_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_NAME_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_NAME_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_NAME_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_NAME_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_STDDEV_POPULATION_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_STDDEV_POPULATION_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_ID_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_ID_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_ID_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_ID_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_METADATA_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_METADATA_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_METADATA_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_METADATA_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_NAME_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_NAME_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_NAME_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_NAME_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_STDDEV_SAMPLE_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_STDDEV_SAMPLE_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_SUM_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_SUM_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_SUM_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_SUM_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_SUM_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_SUM_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_SUM_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_SUM_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_SUM_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_SUM_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_SUM_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_SUM_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_SUM_ID_ASC = 'UTILITY_ACCOUNTS_SUM_ID_ASC',
  UTILITY_ACCOUNTS_SUM_ID_DESC = 'UTILITY_ACCOUNTS_SUM_ID_DESC',
  UTILITY_ACCOUNTS_SUM_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_SUM_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_SUM_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_SUM_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_SUM_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_SUM_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_SUM_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_SUM_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_SUM_METADATA_ASC = 'UTILITY_ACCOUNTS_SUM_METADATA_ASC',
  UTILITY_ACCOUNTS_SUM_METADATA_DESC = 'UTILITY_ACCOUNTS_SUM_METADATA_DESC',
  UTILITY_ACCOUNTS_SUM_NAME_ASC = 'UTILITY_ACCOUNTS_SUM_NAME_ASC',
  UTILITY_ACCOUNTS_SUM_NAME_DESC = 'UTILITY_ACCOUNTS_SUM_NAME_DESC',
  UTILITY_ACCOUNTS_SUM_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_SUM_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_SUM_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_SUM_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_SUM_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_SUM_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_SUM_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_SUM_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_ID_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_ID_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_ID_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_ID_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_METADATA_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_METADATA_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_METADATA_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_METADATA_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_NAME_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_NAME_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_NAME_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_NAME_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_VARIANCE_POPULATION_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_VARIANCE_POPULATION_UTILITY_PROVIDER_ID_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ACCOUNT_NUMBER_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ACCOUNT_NUMBER_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ACCOUNT_NUMBER_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ACCOUNT_NUMBER_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_CREATED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_CREATED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_DELETED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_DELETED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_DELETED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_DELETED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ID_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ID_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ID_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_ID_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ACCOUNT_KEY_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ACCOUNT_KEY_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ACCOUNT_KEY_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ACCOUNT_KEY_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_INTEGRATION_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_METADATA_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_METADATA_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_METADATA_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_METADATA_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_NAME_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_NAME_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_NAME_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_NAME_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UTILITY_PROVIDER_ID_ASC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UTILITY_PROVIDER_ID_ASC',
  UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UTILITY_PROVIDER_ID_DESC = 'UTILITY_ACCOUNTS_VARIANCE_SAMPLE_UTILITY_PROVIDER_ID_DESC'
}

export type VerifyPhoneNumberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type VerifyPhoneNumberResponse = {
  __typename?: 'VerifyPhoneNumberResponse';
  attempts: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  verificationId: Scalars['String'];
};

export type WithdrawSuggestionInput = {
  id: Scalars['Int'];
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByURL?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL'
}

export type AllFaciltiesQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<FacilitiesOrderBy> | FacilitiesOrderBy>;
}>;

export type AllFaciltiesQuery = {
  __typename?: 'Query';
  facilities?: {
    __typename?: 'FacilitiesConnection';
    nodes: Array<{ __typename?: 'Facility'; id: number; name: string }>;
  } | null;
};

export type ExtendMutationVariables = Exact<{
  payload: Scalars['String'];
  failOnExists?: InputMaybe<Scalars['Boolean']>;
}>;

export type ExtendMutation = {
  __typename?: 'Mutation';
  extend?: boolean | null;
};

export type GetFacilitySchemaQueryVariables = Exact<{ [key: string]: never }>;

export type GetFacilitySchemaQuery = {
  __typename?: 'Query';
  __type?: {
    __typename?: '__Type';
    fields?: Array<{
      __typename?: '__Field';
      name: string;
      description?: string | null;
      type: {
        __typename?: '__Type';
        name?: string | null;
        kind: __TypeKind;
        ofType?: {
          __typename?: '__Type';
          name?: string | null;
          kind: __TypeKind;
        } | null;
      };
    }> | null;
  } | null;
};

export type AllFacilitiesWithAddressQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllFacilitiesWithAddressQuery = {
  __typename?: 'Query';
  facilities?: {
    __typename?: 'FacilitiesConnection';
    nodes: Array<{
      __typename?: 'Facility';
      id: number;
      slug: string;
      name: string;
      address?: string | null;
    }>;
  } | null;
};

export type DeleteFacilityByNodeIdMutationVariables = Exact<{
  input: DeleteFacilityByNodeIdInput;
}>;

export type DeleteFacilityByNodeIdMutation = {
  __typename?: 'Mutation';
  deleteFacilityByNodeId?: {
    __typename?: 'DeleteFacilityPayload';
    deletedFacilityNodeId?: string | null;
  } | null;
};

export type GetTimezonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTimezonesQuery = {
  __typename?: 'Query';
  timezones?: {
    __typename?: 'TimezonesConnection';
    nodes: Array<{
      __typename?: 'Timezone';
      abbreviation?: string | null;
      name: string;
      isDst?: boolean | null;
      utcOffset?: { __typename?: 'Interval'; hours?: number | null } | null;
    }>;
  } | null;
};

export type FacilityGroupByIdQueryVariables = Exact<{
  facilityGroupByIdId: Scalars['BigInt'];
}>;

export type FacilityGroupByIdQuery = {
  __typename?: 'Query';
  facilityGroup?: {
    __typename?: 'FacilityGroup';
    facilities: {
      __typename?: 'FacilityGroupFacilitiesManyToManyConnection';
      nodes: Array<{
        __typename?: 'Facility';
        id: number;
        name: string;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
      }>;
    };
  } | null;
};

export type AllFacilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type AllFacilitiesQuery = {
  __typename?: 'Query';
  facilities?: {
    __typename?: 'FacilitiesConnection';
    nodes: Array<{
      __typename?: 'Facility';
      name: string;
      address?: string | null;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      slug: string;
      id: number;
      facilityGroups: {
        __typename?: 'FacilityFacilityGroupsManyToManyConnection';
        nodes: Array<{ __typename?: 'FacilityGroup'; id: any; name: string }>;
      };
    }>;
  } | null;
};

export type CreateFacilityGroupMutationVariables = Exact<{
  input: CreateFacilityGroupInput;
}>;

export type CreateFacilityGroupMutation = {
  __typename?: 'Mutation';
  createFacilityGroup?: {
    __typename?: 'CreateFacilityGroupPayload';
    facilityGroup?: {
      __typename?: 'FacilityGroup';
      name: string;
      parentId?: any | null;
      id: any;
    } | null;
  } | null;
};

export type UpdateFacilityGroupByIdMutationVariables = Exact<{
  input: UpdateFacilityGroupInput;
}>;

export type UpdateFacilityGroupByIdMutation = {
  __typename?: 'Mutation';
  updateFacilityGroup?: {
    __typename?: 'UpdateFacilityGroupPayload';
    facilityGroup?: {
      __typename?: 'FacilityGroup';
      id: any;
      name: string;
      parent?: { __typename?: 'FacilityGroup'; id: any; name: string } | null;
    } | null;
  } | null;
};

export type AllGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type AllGroupsQuery = {
  __typename?: 'Query';
  facilityGroups?: {
    __typename?: 'FacilityGroupsConnection';
    nodes: Array<{
      __typename?: 'FacilityGroup';
      id: any;
      name: string;
      parentId?: any | null;
      facilities: {
        __typename?: 'FacilityGroupFacilitiesManyToManyConnection';
        nodes: Array<{
          __typename?: 'Facility';
          id: number;
          name: string;
          address?: string | null;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          slug: string;
          facilityGroups: {
            __typename?: 'FacilityFacilityGroupsManyToManyConnection';
            nodes: Array<{ __typename?: 'FacilityGroup'; id: any }>;
          };
        }>;
      };
    }>;
  } | null;
};

export type AddFacilityToGroupMutationVariables = Exact<{
  input: CreateFacilityGroupsLinkerInput;
}>;

export type AddFacilityToGroupMutation = {
  __typename?: 'Mutation';
  createFacilityGroupsLinker?: {
    __typename?: 'CreateFacilityGroupsLinkerPayload';
    facilityGroupsLinker?: {
      __typename?: 'FacilityGroupsLinker';
      facilityGroupingId: any;
      facilityId: number;
    } | null;
  } | null;
};

export type RemoveFacilityFromGroupMutationVariables = Exact<{
  input: DeleteFacilityGroupsLinkerInput;
}>;

export type RemoveFacilityFromGroupMutation = {
  __typename?: 'Mutation';
  deleteFacilityGroupsLinker?: {
    __typename?: 'DeleteFacilityGroupsLinkerPayload';
    facilityGroupsLinker?: {
      __typename?: 'FacilityGroupsLinker';
      facilityGroupingId: any;
      facilityId: number;
    } | null;
  } | null;
};

export type DeleteFacilityGroupByIdMutationVariables = Exact<{
  input: DeleteFacilityGroupInput;
}>;

export type DeleteFacilityGroupByIdMutation = {
  __typename?: 'Mutation';
  deleteFacilityGroup?: {
    __typename?: 'DeleteFacilityGroupPayload';
    facilityGroup?: {
      __typename?: 'FacilityGroup';
      name: string;
      id: any;
    } | null;
  } | null;
};

export const AllFaciltiesDocument = gql`
  query AllFacilties($orderBy: [FacilitiesOrderBy!]) {
    facilities(orderBy: $orderBy) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useAllFaciltiesQuery__
 *
 * To run a query within a React component, call `useAllFaciltiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFaciltiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFaciltiesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllFaciltiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFaciltiesQuery,
    AllFaciltiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllFaciltiesQuery, AllFaciltiesQueryVariables>(
    AllFaciltiesDocument,
    options
  );
}
export function useAllFaciltiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFaciltiesQuery,
    AllFaciltiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllFaciltiesQuery, AllFaciltiesQueryVariables>(
    AllFaciltiesDocument,
    options
  );
}
export type AllFaciltiesQueryHookResult = ReturnType<
  typeof useAllFaciltiesQuery
>;
export type AllFaciltiesLazyQueryHookResult = ReturnType<
  typeof useAllFaciltiesLazyQuery
>;
export type AllFaciltiesQueryResult = Apollo.QueryResult<
  AllFaciltiesQuery,
  AllFaciltiesQueryVariables
>;
export const ExtendDocument = gql`
  mutation Extend($payload: String!, $failOnExists: Boolean) {
    extend(payload: $payload, failOnExists: $failOnExists)
  }
`;
export type ExtendMutationFn = Apollo.MutationFunction<
  ExtendMutation,
  ExtendMutationVariables
>;

/**
 * __useExtendMutation__
 *
 * To run a mutation, you first call `useExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendMutation, { data, loading, error }] = useExtendMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *      failOnExists: // value for 'failOnExists'
 *   },
 * });
 */
export function useExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExtendMutation,
    ExtendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExtendMutation, ExtendMutationVariables>(
    ExtendDocument,
    options
  );
}
export type ExtendMutationHookResult = ReturnType<typeof useExtendMutation>;
export type ExtendMutationResult = Apollo.MutationResult<ExtendMutation>;
export type ExtendMutationOptions = Apollo.BaseMutationOptions<
  ExtendMutation,
  ExtendMutationVariables
>;
export const GetFacilitySchemaDocument = gql`
  query getFacilitySchema {
    __type(name: "Facility") {
      fields {
        name
        description
        type {
          name
          kind
          ofType {
            name
            kind
          }
        }
      }
    }
  }
`;

/**
 * __useGetFacilitySchemaQuery__
 *
 * To run a query within a React component, call `useGetFacilitySchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilitySchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilitySchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFacilitySchemaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFacilitySchemaQuery,
    GetFacilitySchemaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFacilitySchemaQuery,
    GetFacilitySchemaQueryVariables
  >(GetFacilitySchemaDocument, options);
}
export function useGetFacilitySchemaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilitySchemaQuery,
    GetFacilitySchemaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFacilitySchemaQuery,
    GetFacilitySchemaQueryVariables
  >(GetFacilitySchemaDocument, options);
}
export type GetFacilitySchemaQueryHookResult = ReturnType<
  typeof useGetFacilitySchemaQuery
>;
export type GetFacilitySchemaLazyQueryHookResult = ReturnType<
  typeof useGetFacilitySchemaLazyQuery
>;
export type GetFacilitySchemaQueryResult = Apollo.QueryResult<
  GetFacilitySchemaQuery,
  GetFacilitySchemaQueryVariables
>;
export const AllFacilitiesWithAddressDocument = gql`
  query AllFacilitiesWithAddress {
    facilities {
      nodes {
        id
        slug
        name
        address
      }
    }
  }
`;

/**
 * __useAllFacilitiesWithAddressQuery__
 *
 * To run a query within a React component, call `useAllFacilitiesWithAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFacilitiesWithAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFacilitiesWithAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFacilitiesWithAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFacilitiesWithAddressQuery,
    AllFacilitiesWithAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllFacilitiesWithAddressQuery,
    AllFacilitiesWithAddressQueryVariables
  >(AllFacilitiesWithAddressDocument, options);
}
export function useAllFacilitiesWithAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFacilitiesWithAddressQuery,
    AllFacilitiesWithAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllFacilitiesWithAddressQuery,
    AllFacilitiesWithAddressQueryVariables
  >(AllFacilitiesWithAddressDocument, options);
}
export type AllFacilitiesWithAddressQueryHookResult = ReturnType<
  typeof useAllFacilitiesWithAddressQuery
>;
export type AllFacilitiesWithAddressLazyQueryHookResult = ReturnType<
  typeof useAllFacilitiesWithAddressLazyQuery
>;
export type AllFacilitiesWithAddressQueryResult = Apollo.QueryResult<
  AllFacilitiesWithAddressQuery,
  AllFacilitiesWithAddressQueryVariables
>;
export const DeleteFacilityByNodeIdDocument = gql`
  mutation DeleteFacilityByNodeId($input: DeleteFacilityByNodeIdInput!) {
    deleteFacilityByNodeId(input: $input) {
      deletedFacilityNodeId
    }
  }
`;
export type DeleteFacilityByNodeIdMutationFn = Apollo.MutationFunction<
  DeleteFacilityByNodeIdMutation,
  DeleteFacilityByNodeIdMutationVariables
>;

/**
 * __useDeleteFacilityByNodeIdMutation__
 *
 * To run a mutation, you first call `useDeleteFacilityByNodeIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFacilityByNodeIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFacilityByNodeIdMutation, { data, loading, error }] = useDeleteFacilityByNodeIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFacilityByNodeIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFacilityByNodeIdMutation,
    DeleteFacilityByNodeIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFacilityByNodeIdMutation,
    DeleteFacilityByNodeIdMutationVariables
  >(DeleteFacilityByNodeIdDocument, options);
}
export type DeleteFacilityByNodeIdMutationHookResult = ReturnType<
  typeof useDeleteFacilityByNodeIdMutation
>;
export type DeleteFacilityByNodeIdMutationResult =
  Apollo.MutationResult<DeleteFacilityByNodeIdMutation>;
export type DeleteFacilityByNodeIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteFacilityByNodeIdMutation,
  DeleteFacilityByNodeIdMutationVariables
>;
export const GetTimezonesDocument = gql`
  query getTimezones {
    timezones {
      nodes {
        abbreviation
        name
        isDst
        utcOffset {
          hours
        }
      }
    }
  }
`;

/**
 * __useGetTimezonesQuery__
 *
 * To run a query within a React component, call `useGetTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimezonesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTimezonesQuery,
    GetTimezonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(
    GetTimezonesDocument,
    options
  );
}
export function useGetTimezonesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTimezonesQuery,
    GetTimezonesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTimezonesQuery, GetTimezonesQueryVariables>(
    GetTimezonesDocument,
    options
  );
}
export type GetTimezonesQueryHookResult = ReturnType<
  typeof useGetTimezonesQuery
>;
export type GetTimezonesLazyQueryHookResult = ReturnType<
  typeof useGetTimezonesLazyQuery
>;
export type GetTimezonesQueryResult = Apollo.QueryResult<
  GetTimezonesQuery,
  GetTimezonesQueryVariables
>;
export const FacilityGroupByIdDocument = gql`
  query FacilityGroupById($facilityGroupByIdId: BigInt!) {
    facilityGroup(id: $facilityGroupByIdId) {
      facilities {
        nodes {
          id
          name
          city
          state
          zip
        }
      }
    }
  }
`;

/**
 * __useFacilityGroupByIdQuery__
 *
 * To run a query within a React component, call `useFacilityGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityGroupByIdQuery({
 *   variables: {
 *      facilityGroupByIdId: // value for 'facilityGroupByIdId'
 *   },
 * });
 */
export function useFacilityGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FacilityGroupByIdQuery,
    FacilityGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FacilityGroupByIdQuery,
    FacilityGroupByIdQueryVariables
  >(FacilityGroupByIdDocument, options);
}
export function useFacilityGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FacilityGroupByIdQuery,
    FacilityGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FacilityGroupByIdQuery,
    FacilityGroupByIdQueryVariables
  >(FacilityGroupByIdDocument, options);
}
export type FacilityGroupByIdQueryHookResult = ReturnType<
  typeof useFacilityGroupByIdQuery
>;
export type FacilityGroupByIdLazyQueryHookResult = ReturnType<
  typeof useFacilityGroupByIdLazyQuery
>;
export type FacilityGroupByIdQueryResult = Apollo.QueryResult<
  FacilityGroupByIdQuery,
  FacilityGroupByIdQueryVariables
>;
export const AllFacilitiesDocument = gql`
  query AllFacilities {
    facilities {
      nodes {
        name
        address
        city
        state
        zip
        slug
        id
        facilityGroups {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAllFacilitiesQuery__
 *
 * To run a query within a React component, call `useAllFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllFacilitiesQuery,
    AllFacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllFacilitiesQuery, AllFacilitiesQueryVariables>(
    AllFacilitiesDocument,
    options
  );
}
export function useAllFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllFacilitiesQuery,
    AllFacilitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllFacilitiesQuery, AllFacilitiesQueryVariables>(
    AllFacilitiesDocument,
    options
  );
}
export type AllFacilitiesQueryHookResult = ReturnType<
  typeof useAllFacilitiesQuery
>;
export type AllFacilitiesLazyQueryHookResult = ReturnType<
  typeof useAllFacilitiesLazyQuery
>;
export type AllFacilitiesQueryResult = Apollo.QueryResult<
  AllFacilitiesQuery,
  AllFacilitiesQueryVariables
>;
export const CreateFacilityGroupDocument = gql`
  mutation CreateFacilityGroup($input: CreateFacilityGroupInput!) {
    createFacilityGroup(input: $input) {
      facilityGroup {
        name
        parentId
        id
      }
    }
  }
`;
export type CreateFacilityGroupMutationFn = Apollo.MutationFunction<
  CreateFacilityGroupMutation,
  CreateFacilityGroupMutationVariables
>;

/**
 * __useCreateFacilityGroupMutation__
 *
 * To run a mutation, you first call `useCreateFacilityGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFacilityGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFacilityGroupMutation, { data, loading, error }] = useCreateFacilityGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFacilityGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFacilityGroupMutation,
    CreateFacilityGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFacilityGroupMutation,
    CreateFacilityGroupMutationVariables
  >(CreateFacilityGroupDocument, options);
}
export type CreateFacilityGroupMutationHookResult = ReturnType<
  typeof useCreateFacilityGroupMutation
>;
export type CreateFacilityGroupMutationResult =
  Apollo.MutationResult<CreateFacilityGroupMutation>;
export type CreateFacilityGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateFacilityGroupMutation,
  CreateFacilityGroupMutationVariables
>;
export const UpdateFacilityGroupByIdDocument = gql`
  mutation UpdateFacilityGroupById($input: UpdateFacilityGroupInput!) {
    updateFacilityGroup(input: $input) {
      facilityGroup {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`;
export type UpdateFacilityGroupByIdMutationFn = Apollo.MutationFunction<
  UpdateFacilityGroupByIdMutation,
  UpdateFacilityGroupByIdMutationVariables
>;

/**
 * __useUpdateFacilityGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityGroupByIdMutation, { data, loading, error }] = useUpdateFacilityGroupByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFacilityGroupByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFacilityGroupByIdMutation,
    UpdateFacilityGroupByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFacilityGroupByIdMutation,
    UpdateFacilityGroupByIdMutationVariables
  >(UpdateFacilityGroupByIdDocument, options);
}
export type UpdateFacilityGroupByIdMutationHookResult = ReturnType<
  typeof useUpdateFacilityGroupByIdMutation
>;
export type UpdateFacilityGroupByIdMutationResult =
  Apollo.MutationResult<UpdateFacilityGroupByIdMutation>;
export type UpdateFacilityGroupByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateFacilityGroupByIdMutation,
  UpdateFacilityGroupByIdMutationVariables
>;
export const AllGroupsDocument = gql`
  query AllGroups {
    facilityGroups {
      nodes {
        id
        name
        parentId
        facilities {
          nodes {
            id
            name
            address
            city
            state
            zip
            slug
            facilityGroups {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllGroupsQuery__
 *
 * To run a query within a React component, call `useAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllGroupsQuery, AllGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllGroupsQuery, AllGroupsQueryVariables>(
    AllGroupsDocument,
    options
  );
}
export function useAllGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGroupsQuery,
    AllGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllGroupsQuery, AllGroupsQueryVariables>(
    AllGroupsDocument,
    options
  );
}
export type AllGroupsQueryHookResult = ReturnType<typeof useAllGroupsQuery>;
export type AllGroupsLazyQueryHookResult = ReturnType<
  typeof useAllGroupsLazyQuery
>;
export type AllGroupsQueryResult = Apollo.QueryResult<
  AllGroupsQuery,
  AllGroupsQueryVariables
>;
export const AddFacilityToGroupDocument = gql`
  mutation AddFacilityToGroup($input: CreateFacilityGroupsLinkerInput!) {
    createFacilityGroupsLinker(input: $input) {
      facilityGroupsLinker {
        facilityGroupingId
        facilityId
      }
    }
  }
`;
export type AddFacilityToGroupMutationFn = Apollo.MutationFunction<
  AddFacilityToGroupMutation,
  AddFacilityToGroupMutationVariables
>;

/**
 * __useAddFacilityToGroupMutation__
 *
 * To run a mutation, you first call `useAddFacilityToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFacilityToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFacilityToGroupMutation, { data, loading, error }] = useAddFacilityToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFacilityToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFacilityToGroupMutation,
    AddFacilityToGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddFacilityToGroupMutation,
    AddFacilityToGroupMutationVariables
  >(AddFacilityToGroupDocument, options);
}
export type AddFacilityToGroupMutationHookResult = ReturnType<
  typeof useAddFacilityToGroupMutation
>;
export type AddFacilityToGroupMutationResult =
  Apollo.MutationResult<AddFacilityToGroupMutation>;
export type AddFacilityToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddFacilityToGroupMutation,
  AddFacilityToGroupMutationVariables
>;
export const RemoveFacilityFromGroupDocument = gql`
  mutation RemoveFacilityFromGroup($input: DeleteFacilityGroupsLinkerInput!) {
    deleteFacilityGroupsLinker(input: $input) {
      facilityGroupsLinker {
        facilityGroupingId
        facilityId
      }
    }
  }
`;
export type RemoveFacilityFromGroupMutationFn = Apollo.MutationFunction<
  RemoveFacilityFromGroupMutation,
  RemoveFacilityFromGroupMutationVariables
>;

/**
 * __useRemoveFacilityFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveFacilityFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFacilityFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFacilityFromGroupMutation, { data, loading, error }] = useRemoveFacilityFromGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFacilityFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFacilityFromGroupMutation,
    RemoveFacilityFromGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFacilityFromGroupMutation,
    RemoveFacilityFromGroupMutationVariables
  >(RemoveFacilityFromGroupDocument, options);
}
export type RemoveFacilityFromGroupMutationHookResult = ReturnType<
  typeof useRemoveFacilityFromGroupMutation
>;
export type RemoveFacilityFromGroupMutationResult =
  Apollo.MutationResult<RemoveFacilityFromGroupMutation>;
export type RemoveFacilityFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveFacilityFromGroupMutation,
  RemoveFacilityFromGroupMutationVariables
>;
export const DeleteFacilityGroupByIdDocument = gql`
  mutation DeleteFacilityGroupById($input: DeleteFacilityGroupInput!) {
    deleteFacilityGroup(input: $input) {
      facilityGroup {
        name
        id
      }
    }
  }
`;
export type DeleteFacilityGroupByIdMutationFn = Apollo.MutationFunction<
  DeleteFacilityGroupByIdMutation,
  DeleteFacilityGroupByIdMutationVariables
>;

/**
 * __useDeleteFacilityGroupByIdMutation__
 *
 * To run a mutation, you first call `useDeleteFacilityGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFacilityGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFacilityGroupByIdMutation, { data, loading, error }] = useDeleteFacilityGroupByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFacilityGroupByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFacilityGroupByIdMutation,
    DeleteFacilityGroupByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFacilityGroupByIdMutation,
    DeleteFacilityGroupByIdMutationVariables
  >(DeleteFacilityGroupByIdDocument, options);
}
export type DeleteFacilityGroupByIdMutationHookResult = ReturnType<
  typeof useDeleteFacilityGroupByIdMutation
>;
export type DeleteFacilityGroupByIdMutationResult =
  Apollo.MutationResult<DeleteFacilityGroupByIdMutation>;
export type DeleteFacilityGroupByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteFacilityGroupByIdMutation,
  DeleteFacilityGroupByIdMutationVariables
>;
