import React, { Component } from 'react';
import { FixedSizeList as List } from 'react-window';

const height = 35;

interface Props {
  children: JSX.Element[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  maxHeight: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue: () => any;
}

class CustomReactSelectList extends Component<Props> {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    const optionsCount = children.length ?? 1;
    const optionsHeight = optionsCount > 8 ? maxHeight : optionsCount * height;

    return (
      <List
        height={optionsHeight}
        width={'100%'}
        itemCount={optionsCount}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default CustomReactSelectList;
