import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  auth0: {},
  userInfo: {
    roles: []
  },
  error: null
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.ACTIVATE_USER_START:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case actionTypes.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.LOAD_USER_START:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error
      };
    case actionTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        auth0: action.payload.auth0,
        userInfo: action.payload.userInfo
      };
    case actionTypes.LOAD_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
