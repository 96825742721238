import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }).isRequired,
  label: PropTypes.string.isRequired
};

const defaultProps = {
  type: 'text'
};

function ValidatedInput({ className, input, meta, label, type }) {
  const showError = meta.error && meta.touched;
  const classes = classNames({
    'nd-input__container': true,
    'nd-input__container--error': showError
  });

  return (
    <div className={`${className} ${classes}`}>
      <label
        className={`nd-input__label ${showError ? 'nd-input-label-error' : ''}`}
      >
        {label}
        {showError && <span> ({meta.error})</span>}
      </label>
      <input
        className={`nd-input ${showError ? 'nd-input-error' : ''}`}
        type={type}
        {...input}
      />
    </div>
  );
}

ValidatedInput.propTypes = propTypes;
ValidatedInput.defaultProps = defaultProps;

export default ValidatedInput;
