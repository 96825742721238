import { createSelector } from 'reselect';

const getrawOrganizations = (state) => state.organizations.items;
const getRawOrganizationsValueFilter = (state) =>
  state.organizations.valueFilter.trim().toLowerCase();

const getOrganizationsValueFilter = createSelector(
  [getRawOrganizationsValueFilter],
  (valueFilter) => {
    return valueFilter;
  }
);

const getFilteredOrganizations = createSelector(
  [getrawOrganizations, getRawOrganizationsValueFilter],
  (organizations, valueFilter) => {
    if (!valueFilter) {
      return organizations;
    }

    return organizations.filter((organization) => {
      return (
        organization.name.toLowerCase().indexOf(valueFilter) !== -1 ||
        organization.slug.toLowerCase().indexOf(valueFilter) !== -1
      );
    });
  }
);

export { getFilteredOrganizations, getOrganizationsValueFilter };
