import React from 'react';
import AddGroupInput from './AddGroupInput';
import { OnAddGroupProps } from '../../common/hooks/useOnAddGroup';
import { Modal } from '@ndustrial/contxt-common/src';

interface AddGroupInputModalProps {
  onAddGroup: ({ name, parentId }: OnAddGroupProps) => void;
  isOpen: boolean;
  onRequestClose: () => void;
}

function AddGroupInputModal({
  onAddGroup,
  isOpen,
  onRequestClose
}: AddGroupInputModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      customStyles={{
        height: 145,
        width: 400,
        padding: '30px'
      }}
      hideHeader={true}
    >
      <AddGroupInput onAddGroup={onAddGroup} isActive={isOpen} />
    </Modal>
  );
}

export default AddGroupInputModal;
