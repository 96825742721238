import { FixedSizeList as List } from 'react-window';
import React, { ReactNode } from 'react';

import HierarchyItemConstants from './constants/HierarchyItemConstants';
interface VirtualizedFixedSizeListProps<T> {
  height: number;
  items: T[];
  render: (item: T) => ReactNode;
}

function VirtualizedFixedSizedList({
  height,
  items,
  render
}: VirtualizedFixedSizeListProps<unknown>) {
  return (
    <List
      className="List"
      height={height}
      itemData={items}
      itemCount={items.length}
      itemSize={HierarchyItemConstants.ITEM_HEIGHT}
      width="100%"
      overscanCount={5}
    >
      {({ index, data, style }) => (
        <div key={index} style={style}>
          {render(data[index])}
        </div>
      )}
    </List>
  );
}

export default VirtualizedFixedSizedList;
