import React from 'react';
import styled from 'styled-components';

import { Building, Tree } from '@ndustrial/nd-icons-svg';
import { LabelSpan } from '@ndustrial/contxt-common';
import HierarchyItemConstants from '../../common/constants/HierarchyItemConstants';
import { Facility } from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { useSetRecoilState } from 'recoil';
import { expandToFacilityIdAtom } from '../../Atoms';
import { toast } from 'react-toastify';

const FacilityActions = styled.span`
  position: relative;
  display: none;
  flex: 0 0 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 15px;
`;

const FacilityWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: ${HierarchyItemConstants.ITEM_HEIGHT}px;

  &:hover {
    ${FacilityActions} {
      display: flex;
    }
  }
`;

const StyledTreeIcon = styled(Tree)`
  margin-top: auto;
  margin-bottom: auto;
  stroke: gray;

  &:hover {
    stroke: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

const BuildingWrapper = styled.span`
  height: 1.5rem;
  width: 1.5rem;
`;

const FacilityContent = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 5px;
`;

const FacilityNameAndIcon = styled.span`
  display: flex;
  gap: 0.25rem;
  align-items: flex-start;
`;

const FacilityName = styled.span`
  font-weight: 400;
`;

const FacilityLocationAndIcon = styled.span`
  display: flex;
  gap: 0.25rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #5e5e5e;
`;

const FacilityLocation = styled.span`
  font-weight: 400;
  color: #272727;
`;

export interface FacilityProps {
  facility: Facility;
  className?: string;
}

const FacilityItemInList = ({ facility, className }: FacilityProps) => {
  const setExpandToFacilityId = useSetRecoilState(expandToFacilityIdAtom);
  return (
    <FacilityWrapper className={className}>
      <FacilityContent>
        <FacilityNameAndIcon>
          <BuildingWrapper>
            <Building />
          </BuildingWrapper>
          <FacilityName>{facility.name}</FacilityName>
        </FacilityNameAndIcon>
        <FacilityLocationAndIcon>
          <LabelSpan>Location:</LabelSpan>
          <FacilityLocation>
            {facility.city}, {facility.state}
          </FacilityLocation>
        </FacilityLocationAndIcon>
      </FacilityContent>
      <FacilityActions>
        <StyledTreeIcon
          onClick={() => {
            if (facility.facilityGroups?.nodes?.length === 0) {
              toast.info('Facility not in any group');
            } else {
              setExpandToFacilityId(facility.id);
            }
          }}
        />
      </FacilityActions>
    </FacilityWrapper>
  );
};

export default FacilityItemInList;
