import React, { useMemo, useState } from 'react';

import styled from 'styled-components';

import { getApplicationModules } from '../selectors/applicationsGroupings';
import { useSelector } from 'react-redux';
import { Organization } from '../../../types/organizations';
import { ApplicationModule } from '../../../types/sdk';
import TabRouter, {
  TabRouterPropsData
} from '@ndustrial/contxt-common/src/components/TabRouter';
import { Loader } from '@ndustrial/contxt-common/src';
import { RecoilRoot } from 'recoil';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 30px;
`;

const Header = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`;

export interface ModuleComponentProps {
  className?: string;
  orgSlug: string;
}

const PackageEntryPoint = ({
  customApplicationModules,
  label,
  CustomHeader
}: {
  customApplicationModules: Record<string, ApplicationModule>;
  label: string;
  CustomHeader?: React.ComponentType;
}) => {
  const applicationModules = useSelector(getApplicationModules);
  const selectedOrganization = useSelector(
    (state: { organizations: { selectedOrganization: Organization } }) =>
      state.organizations.selectedOrganization
  );

  const am = applicationModules.reduce(
    (modules: ApplicationModule[], next: ApplicationModule) => {
      if (customApplicationModules[next.slug]) {
        return [...modules, customApplicationModules[next.slug]];
      }

      return modules;
    },
    []
  );

  const memoizedTabs: TabRouterPropsData[] = useMemo(() => {
    return am.map((module: ApplicationModule) => {
      const { Component, path } = module;
      return {
        slug: path,
        label: module.label,
        component: Component
      };
    });
  }, [am]);

  if (memoizedTabs.length === 0) {
    return <Loader />;
  }

  // TODO, fallback screen when no modules exist for the organization needs created
  //  or the check happens when the navigation link is loaded

  return (
    <RecoilRoot>
      <Wrapper>
        {CustomHeader && <CustomHeader />}
        {!CustomHeader && <Header>{label}</Header>}
        <TabRouter orgSlug={selectedOrganization.slug} data={memoizedTabs} />
      </Wrapper>
    </RecoilRoot>
  );
};

export default PackageEntryPoint;
