import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  defaultApplicationRoute: null,
  groupings: [],
  loading: false,
  error: null
};

/* eslint-disable */
// example data
const tempGroupings = {
  applicationGroupings: [
    {
      applicationId: 26,
      applicationModules: [
        {
          applicationGroupingId: 'e712bbbf-f479-494d-ac09-9f4ff116f9d7',
          createdAt: '2021-12-15T20:51:59.402Z',
          description: 'Add and remove facilities',
          externalApplication: {
            domainName: null,
            vhost: null
          },
          externalApplicationId: null,
          externalLink: null,
          iconUrl:
            'https://ndustrialio-public.s3-us-west-2.amazonaws.com/images/icons/buildings-white.svg',
          id: '5d0ede9b-408d-4e9a-9658-f26c1762706f',
          label: 'Manage Facilities',
          position: 0,
          requiresFacility: false,
          updatedAt: '2021-12-15T20:51:59.402Z',
          slug: '@ndustrial/asset-manager'
        }
      ],
      createdAt: '2021-12-15T20:03:10.657Z',
      iconUrl: null,
      id: 'e712bbbf-f479-494d-ac09-9f4ff116f9d7',
      label: 'Asset Manager',
      position: 0,
      updatedAt: '2021-12-15T20:03:10.657Z'
    }
  ]
};
/* eslint-enable */

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_APPLICATION_GROUPINGS_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.LOAD_APPLICATION_GROUPINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case actionTypes.LOAD_APPLICATION_GROUPINGS_SUCCESS:
      return {
        ...state,
        groupings: action.payload,
        loading: false,
        error: null
      };

    case actionTypes.RESET_APPLICATION_GROUPINGS:
      return {
        ...state,
        groupings: INITIAL_STATE.groupings
      };

    case actionTypes.RESET_DEFAULT_APPLICATION_ROUTE:
      return {
        ...state,
        defaultApplicationRoute: INITIAL_STATE.defaultApplicationRoute
      };

    case actionTypes.SET_DEFAULT_APPLICATION_ROUTE:
      return {
        ...state,
        defaultApplicationRoute: action.payload
      };

    default:
      return state;
  }
}

export { INITIAL_STATE };
export default reducer;
