import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChevronRight } from '@ndustrial/nd-icons-svg';

const ExpanderArrowContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ExpanderArrow = styled(ChevronRight)`
  stroke: ${({ theme }) => theme.primary};
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${({ expanded }) =>
    expanded &&
    `
      transform: rotate(90deg);
    `};
`;

const propTypes = {
  isExpanded: PropTypes.any
};

function Expander({ isExpanded }) {
  return (
    <ExpanderArrowContainer>
      <ExpanderArrow expanded={isExpanded ? 1 : 0} />
    </ExpanderArrowContainer>
  );
}

Expander.propTypes = propTypes;

export default Expander;
