import { head } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { loadUsers } from '../../actions/provisioning';

const OrganizationItem = styled.div`
  background-color: ${(props) => {
    const { organizationId, selectedOrganizationId, theme } = props;

    return organizationId === selectedOrganizationId ? theme.primary : '#fff';
  }};
  color: ${(props) => {
    const { organizationId, selectedOrganizationId } = props;

    return organizationId === selectedOrganizationId ? '#fff' : '#606060';
  }};
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 20px 15px;

  &:hover {
    background-color: ${(props) => {
      const { organizationId, selectedOrganizationId, theme } = props;

      return organizationId === selectedOrganizationId
        ? theme.primary
        : '#fbfbfb';
    }};
  }
`;

const AccessContainer = styled.div`
  flex-basis: 300px;
  border-left: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  height: 100%;
  overflow-y: auto;

  ${OrganizationItem}:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedOrganizationId: PropTypes.string,
  setOrganizationId: PropTypes.func.isRequired
};

function OrganizationsAccessFilter(props) {
  const { selectedOrganizationId, organizations, setOrganizationId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedOrganizationId) {
      const first = head(organizations);

      return setOrganizationId(first.id);
    }

    dispatch(loadUsers(selectedOrganizationId));
  }, [dispatch, organizations, selectedOrganizationId, setOrganizationId]);

  return (
    <AccessContainer>
      {organizations.map((organization) => {
        return (
          <OrganizationItem
            key={organization.id}
            onClick={() => setOrganizationId(organization.id)}
            organizationId={organization.id}
            selectedOrganizationId={selectedOrganizationId}
          >
            {organization.name}
          </OrganizationItem>
        );
      })}
    </AccessContainer>
  );
}

OrganizationsAccessFilter.propTypes = propTypes;

export default OrganizationsAccessFilter;
