import React from 'react';
import styled from 'styled-components';
import { ChevronRight } from '@ndustrial/nd-icons-svg';

const StyledChevronRight = styled(ChevronRight)`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.25rem;
`;

interface StyledChevronProps {
  isExpanded: boolean;
  inactive: boolean;
}

const ChevronWrapper = styled.div<StyledChevronProps>`
  display: flex;
  ${StyledChevronRight} {
    transition: transform 0.1s linear;
    transform: ${({ isExpanded }) =>
      isExpanded ? `rotate(90deg)` : `rotate(0deg)`};
    stroke: ${({ inactive }) => (inactive ? `#b0b0b0` : `#000`)};
  }
`;

interface AnimatedExpandedChevronProps extends StyledChevronProps {
  className?: string;
}

const AnimatedExpandChevron = ({
  isExpanded,
  className,
  inactive
}: AnimatedExpandedChevronProps) => (
  <ChevronWrapper
    className={className}
    isExpanded={isExpanded}
    inactive={inactive}
  >
    <StyledChevronRight />
  </ChevronWrapper>
);

export default AnimatedExpandChevron;
