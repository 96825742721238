import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft, Trash } from '@ndustrial/nd-icons-svg';
import { PrimaryButton, WarningButton } from '@ndustrial/nd-button-react';
import { InputTextField, InputLabel } from '@ndustrial/nd-inputs-react';
import {
  ConfirmDeleteModal,
  EditorInputLabelStyle,
  EditorInputTextFieldStyle,
  facilityAttributeIsRequired,
  FacilityFieldType,
  GetFacilitySchemaQuery,
  nameof,
  ReactSelectSingleValueStyles,
  ScalarNameType,
  Scalars,
  ToggleSwitch,
  useExtendMutation,
  useGetFacilitySchemaQuery,
  __TypeKind
} from '@ndustrial/contxt-common/src';
import Select, { SingleValue } from 'react-select';
import { toast } from 'react-toastify';

const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 30px;
`;

const Header = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
`;

const HeaderDiv = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  flex: 1;
`;

const StyledArrowBack = styled(ArrowLeft)`
  stroke: #838383;
  height: 45px;
  width: 45px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;

  :hover {
    stroke: #646464;
    cursor: pointer;
    background-color: #e8e8e8;
    border-radius: 25px;
  }
`;

const StyledSaveButton = styled(PrimaryButton)`
  flex: 0 1 auto;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
`;

const StyledDeleteButton = styled(WarningButton)`
  flex: 0 0 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 50px;
`;

const StyledErrorDiv = styled.div`
  padding: 10px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  height: 100%;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
`;

const StyledInputLabel = styled(InputLabel)`
  ${EditorInputLabelStyle}
`;

export interface AttributeEditorProps {
  className?: string;
  onGoBack?: () => void;
  onSubmit: () => void;
  attributeName?: string;
  onDelete?: () => void;
  isAddNew?: boolean;
}

const StyledInputTextField = styled(InputTextField)`
  ${EditorInputTextFieldStyle}
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  border-bottom: 1px solid #ebebeb;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export default function AttributeEditor(props: AttributeEditorProps) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [attributeSchema, setAttributeSchema] = useState<
    FacilityFieldType | undefined
  >();
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [attributeType, setAttributeType] = useState<
    ScalarNameType | undefined
  >();

  const [extendFacility] = useExtendMutation();

  const allFacilityFieldsQuery = useGetFacilitySchemaQuery({
    onCompleted: (data: GetFacilitySchemaQuery) => {
      if (!props.isAddNew) {
        const attribute = data.__type?.fields?.find(
          (field) => field.name === props.attributeName
        );
        setAttributeSchema(attribute);
        if (facilityAttributeIsRequired(attribute)) {
          setIsRequired(true);
          setAttributeType(attribute?.type.ofType?.name as ScalarNameType);
        } else {
          setIsRequired(false);
          setAttributeType(attribute?.type.name as ScalarNameType);
        }
      } else {
        setAttributeSchema({ name: '', type: { kind: __TypeKind.SCALAR } });
      }
    }
  });
  const memoizedStyle = useMemo(() => {
    return ReactSelectSingleValueStyles(40, attributeType === undefined);
  }, [attributeType]);

  if (allFacilityFieldsQuery.error)
    return (
      <StyledErrorDiv className={props.className}>
        {allFacilityFieldsQuery.error.message}
      </StyledErrorDiv>
    );

  function constructExtendPayload() {
    return `extend type Facility{"""${attributeSchema?.description}""" ${
      attributeSchema?.name
    }: ${attributeType}${isRequired ? '!' : ''} ${
      isRequired ? '@defaultValue' : ''
    }}`;
  }

  function onSubmit() {
    if (
      !attributeSchema?.name ||
      !attributeSchema.description ||
      !attributeType
    ) {
      toast.error('Please provide all required fields');
    } else {
      extendFacility({
        variables: {
          payload: constructExtendPayload()
        }
      })
        .then(() => {
          toast.success('Successfully Added Attribute');
          if (props.onGoBack) props.onGoBack();
        })
        .catch((e) => toast.error(e.message));
    }
  }

  function onDeleteConfirmClick() {
    if (props.onDelete) {
      props.onDelete();
    }
  }

  function getTypeOptions() {
    return [
      {
        value: nameof<Scalars>('Boolean'),
        label: 'True/False'
      },
      {
        value: nameof<Scalars>('Datetime'),
        label: 'Date'
      },
      {
        value: nameof<Scalars>('Float'),
        label: 'Number (with decimals)'
      },
      {
        value: nameof<Scalars>('Int'),
        label: 'Number (no decimals)'
      },
      {
        value: nameof<Scalars>('String'),
        label: 'Text'
      }
    ];
  }

  const typeOptions = getTypeOptions();

  const selectedType = typeOptions.find((x) => x.value === attributeType);

  function updateName(newValue: string) {
    if (attributeSchema) {
      setAttributeSchema({
        ...attributeSchema,
        name: newValue
      });

      // can be used once editing is implemented:
      // setUpdatedAttributeFields((oldFields) => {
      //   if (!oldFields.includes(nameof<fieldType>('name'))) {
      //     return [...oldFields, nameof<fieldType>('name')];
      //   } else {
      //     return oldFields;
      //   }
      // });
    }
  }

  function updateDescription(newValue: string) {
    if (attributeSchema) {
      setAttributeSchema({
        ...attributeSchema,
        description: newValue
      });

      // can be used once editing is implemented:
      // setUpdatedAttributeFields((oldFields) => {
      //   if (!oldFields.includes(nameof<fieldType>('description'))) {
      //     return [...oldFields, nameof<fieldType>('description')];
      //   } else {
      //     return oldFields;
      //   }
      // });
    }
  }

  return (
    <PageWrapper className={props.className}>
      <Header>
        {props.onGoBack && <StyledArrowBack onClick={props.onGoBack} />}
        <HeaderDiv>{props.isAddNew ? 'Add' : 'Edit'} Attribute</HeaderDiv>
        <StyledSaveButton onClick={onSubmit}>Save</StyledSaveButton>
        {!props.isAddNew && props.onDelete && (
          <StyledDeleteButton onClick={() => setShowDeleteModal(true)}>
            <Trash />
          </StyledDeleteButton>
        )}
      </Header>
      <FormWrapper>
        <StyledInputTextField
          id={`facility-editor-name`}
          key={`facility-editor-name`}
          label="Name"
          required
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => updateName(event.target.value)}
          value={attributeSchema?.name}
        />
        <StyledInputTextField
          id={`facility-editor-description`}
          key={`facility-editor-description`}
          label="Description"
          required
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => updateDescription(event.target.value)}
          value={attributeSchema?.description}
        />
        <StyledInputLabel htmlFor="facility-editor-state" $showPointer={true}>
          Type
        </StyledInputLabel>
        <Select<SingleValue<{ value: string; label: string }>>
          id="attribute-editor-type"
          options={typeOptions}
          placeholder="Select a Type..."
          onChange={(
            selection: SingleValue<{
              value: string;
              label: string;
            }>
          ) => {
            setAttributeType(selection?.value as ScalarNameType);
          }}
          value={selectedType}
          styles={memoizedStyle}
        />
        <StyledToggleSwitch
          label={'Required'}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setIsRequired(e.target.checked)
          }
          checked={isRequired}
        />
      </FormWrapper>
      {showDeleteModal && attributeSchema && (
        <ConfirmDeleteModal
          objectType="attribute"
          objectValue={attributeSchema.name}
          onDelete={onDeleteConfirmClick}
          onRequestClose={() => setShowDeleteModal(false)}
        />
      )}
    </PageWrapper>
  );
}
