import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { intersection } from 'lodash';

import { getRoleNames, getSessionLoading } from '../selectors/session';
import LoadingPage from '../components/LoadingPage';

class ConnectedProtectedRoute extends Component {
  static propTypes = {
    allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    component: PropTypes.elementType.isRequired,
    isLoading: PropTypes.bool.isRequired,
    currentRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    path: PropTypes.string.isRequired,
    redirectPath: PropTypes.string.isRequired
  };

  hasPermissions = () => {
    return (
      intersection(this.props.allowedRoles, this.props.currentRoles).length > 0
    );
  };

  render() {
    if (this.props.isLoading) {
      return <Route path={this.props.path} component={LoadingPage} />;
    }
    return (
      // This switch is necessary for <Redirect /> to pass url params
      <Switch>
        {this.hasPermissions() ? (
          <Route path={this.props.path} component={this.props.component} />
        ) : (
          <Redirect from={this.props.path} to={this.props.redirectPath} />
        )}
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentRoles: getRoleNames(state),
    isLoading: getSessionLoading(state)
  };
}

export { ConnectedProtectedRoute as ProtectedRoute };
export default connect(mapStateToProps, null)(ConnectedProtectedRoute);
