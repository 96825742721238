import PropTypes from 'prop-types';
import { Component } from 'react';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

class MenuCloseCallback extends Component {
  componentDidUpdate(prevProps) {
    if (!this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.props.onClose();
    }
  }

  render() {
    return null;
  }
}

MenuCloseCallback.propTypes = propTypes;

export default MenuCloseCallback;
