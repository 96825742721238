import React from 'react';

import FacilityEditor from './FacilityEditor';
import { Organization } from '@ndustrial/contxt-common/src/types/types';

import FacilityList from './FacilityList';
import FacilityEditorContainer from './FacilityEditorContainer';
import FacilityListHeader from './FacilityListHeader';

export { FacilityList };
export { FacilityEditorContainer };
export { FacilityListHeader };

export default FacilityEditor;

export interface FacilityEditorProps {
  className?: string;
  organization: Organization;
  facilityId?: number;
  onSubmit: (error?: string, warning?: string) => void;
  onDelete?: (error?: string) => void;
  canDelete?: boolean;
  fieldWhitelist?: string[];
  onGoBack?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contxtSdk: any;
}

// TODO update this to be more accurate once done with config changes
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nd: any;
  }
}
