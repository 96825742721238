import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  children: PropTypes.node.isRequired
};

const StyledDiv = styled.div`
  height: 100vh;
  width: 100vw;
`;

function SplashScreen({ children }) {
  return <StyledDiv className="splash-screen__wrapper">{children}</StyledDiv>;
}

SplashScreen.propTypes = propTypes;

export default SplashScreen;
