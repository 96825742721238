import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import * as consentActionCreators from '../actions/consent';
import {
  getConsent,
  getConsentRequired,
  isConsentLoading,
  isConsentAccepting
} from '../selectors/session';
import { Loader } from '@ndustrial/contxt-common';
import { PrimaryButton } from '@ndustrial/nd-button-react';
import { Check } from '@ndustrial/nd-icons-svg';

const ConsentContainer = styled.section`
  border: 1px solid #e6e6e6;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const ConsentHeader = styled.header`
  padding: 20px;
  background-color: #f8f8f8;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConsentTitleContainer = styled.div``;

const ConsentTitle = styled.h2`
  font-size: 1rem;
  line-height: 1rem;
`;

const ConsentSubTitle = styled.h3`
  font-size: 0.7rem;
  line-height: 0.8rem;
  margin-bottom: 0;
  font-style: italic;
`;

const ConsentAcceptButton = styled(PrimaryButton)``;
const ConsentAcceptedIcon = styled(Check)`
  stroke: ${({ theme }) => theme.success};
  width: 30px;
  height: 30px;
`;

const ConsentForm = styled.main`
  flex: 1;

  padding: 20px;
  font-size: 0.75rem;
  height: 100%;
  overflow: scroll;

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1rem;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`;

class ConnectedConsent extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      consent: PropTypes.shape({
        accept: PropTypes.func.isRequired
      })
    }).isRequired,
    consent: PropTypes.object,
    isConsentAccepting: PropTypes.bool.isRequired,
    isConsentLoading: PropTypes.bool.isRequired,
    isConsentRequired: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
  };

  handleAccept = () => {
    return this.props.actions.consent.accept(this.props.consent.id).then(() => {
      this.props.history.push('/');
    });
  };

  render() {
    if (this.props.isConsentLoading) {
      return <Loader />;
    }

    return (
      <ConsentContainer>
        <ConsentHeader>
          <ConsentTitleContainer>
            <ConsentTitle>{this.props.consent.title}</ConsentTitle>
            <ConsentSubTitle>
              {this.props.consent.subTitle} - Published:{' '}
              {this.props.consent.createdAt}
            </ConsentSubTitle>
          </ConsentTitleContainer>
          {this.props.isConsentAccepting ? (
            <Loader />
          ) : this.props.isConsentRequired ? (
            <ConsentAcceptButton onClick={this.handleAccept}>
              Accept
            </ConsentAcceptButton>
          ) : (
            <ConsentAcceptedIcon />
          )}
        </ConsentHeader>
        <ConsentForm
          dangerouslySetInnerHTML={{ __html: this.props.consent.formText }}
        />
      </ConsentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    consent: getConsent(state),
    isConsentRequired: getConsentRequired(state),
    isConsentLoading: isConsentLoading(state),
    isConsentAccepting: isConsentAccepting(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      consent: bindActionCreators(consentActionCreators, dispatch)
    }
  };
}

export { ConnectedConsent as Consent };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConnectedConsent));
