import React from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { PrimaryButton } from '@ndustrial/nd-button-react';
import { Loader } from '@ndustrial/contxt-common';
import ValidatedInput from '../ValidatedInput';
import { required, email, composeValidators } from '../../utils/formValidators';
import styled from 'styled-components';
import {
  ndColorBorder,
  ndColorCritical,
  ndColorText
} from '@ndustrial/contxt-common';

const propTypes = {
  className: PropTypes.string,
  formState: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: 10px;

  :disabled {
    opacity: 0.5;
  }
`;

function NewUserForm({ className, formState, onSubmit }) {
  return (
    <div className={className}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="firstName"
                label="First Name"
                component={ValidatedInput}
                validate={required}
              />
              <Field
                name="lastName"
                label="Last Name"
                component={ValidatedInput}
                validate={required}
              />
              <Field
                name="email"
                label="Email"
                component={ValidatedInput}
                validate={composeValidators(required, email)}
              />
              <StyledPrimaryButton
                type="submit"
                disabled={invalid || formState.isLoading}
              >
                {formState.isLoading ? <Loader /> : 'Submit'}
              </StyledPrimaryButton>
            </form>
          );
        }}
      />
    </div>
  );
}

NewUserForm.propTypes = propTypes;

export default styled(NewUserForm)`
  .nd-input__container {
    flex: 1;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 15px;
    margin-right: 15px;
  }

  .nd-input-label-error {
    color: ${ndColorCritical};
  }

  .nd-input {
    width: 100%;
    font-size: 0.825rem;
    height: 30px;
    -webkit-box-flex: 1;
    flex: 1;
    margin-bottom: 10px;
    outline: none;
    padding: 8px 10px;
    color: ${ndColorText};
    background: transparent;
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    border-radius: 0;
    -webkit-appearance: none;
    border: 1px solid ${ndColorBorder};
  }
  .nd-input-error {
    border: 1px solid ${ndColorCritical};
  }
`;
