import 'react-hot-loader/patch';
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';
import { LicenseManager } from 'ag-grid-enterprise';

import history from './history';
import { withAuth } from './routes/AuthenticatedRoutes';
import Routes from './routes/Routes';
import { Provider } from 'react-redux';
import createStore from './createStore';
import theme from './utils/theme';
import ActivateUser from './containers/ActivateUser';
import { ToastContainer } from 'react-toastify';
import { initDataDog } from './initDataDog';

import 'react-toastify/dist/ReactToastify.css';
import GlobalStyle from './GlobalStyle';
import { toastDefaultColor } from '@ndustrial/contxt-common';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__progress-bar--default {
    background-color: ${toastDefaultColor};
  }
`;

initDataDog();
LicenseManager.setLicenseKey(window.nd.agGrid.licenseKey);
const RoutesWithAuth = withAuth(Routes);
const store = createStore();

const routes = (
  <Provider store={store}>
    <GlobalStyle />

    <StyledToastContainer position="bottom-right" theme="dark" />
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route path="/activate" component={ActivateUser} />
          <RoutesWithAuth path="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(routes, document.getElementById('main'));
