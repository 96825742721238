import { createSelector } from 'reselect';
import { ApplicationGrouping, ApplicationModule } from '../../../types/sdk';

interface TempStateType {
  applicationGroupings: {
    groupings: ApplicationGrouping[];
  };
}

const getApplicationGroupings = (state: TempStateType) =>
  state.applicationGroupings.groupings;

const getApplicationModules = createSelector(
  getApplicationGroupings,
  (applicationGroupings) => {
    if (!applicationGroupings) return [];

    return applicationGroupings.reduce(
      (modules: ApplicationModule[], grouping: ApplicationGrouping) => {
        return [...modules, ...grouping.applicationModules];
      },
      []
    );
  }
);

export { getApplicationModules };
