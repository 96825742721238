import React from 'react';
import styled from 'styled-components';
import { ndInputBorderColor } from '../constants';
import { Tooltip } from '@ndustrial/nd-tooltip-react';
import { Info as InfoIcon } from '@ndustrial/nd-icons-svg';

export const SectionHeaderHeight = 60;
export const SectionBorderColor = ndInputBorderColor;

const Wrapper = styled.div`
  border: 1px solid ${SectionBorderColor};
  border-radius: 5px;
  background-color: white;
`;

const Header = styled.div`
  display: flex;
  height: ${SectionHeaderHeight}px;
  line-height: 60px;
  font-size: 20px;
  border-bottom: 1px solid #dbdbdb;
  padding-left: 20px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
`;

export interface SectionProps {
  className?: string;
  title?: string;
  tooltip?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

export function Section(props: SectionProps) {
  return (
    <Wrapper className={props.className}>
      {props.title && (
        <Header>
          {props.title}
          {props.tooltip && (
            <StyledTooltip content={props.tooltip}>
              <InfoIcon stroke="#0b588a" />
            </StyledTooltip>
          )}
        </Header>
      )}
      {props.children}
    </Wrapper>
  );
}
