import React from 'react';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';
import { $white, ndColorLink } from '@ndustrial/contxt-common';

const StyledFilterItem = styled.div`
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.primary : ''};

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  font-weight: 400;
  font-size: 0.8rem;
  height: 50px;

  &:hover {
    background-color: $gray-very-light;
    cursor: pointer;
  }
`;

const StyledFilterLabel = styled.div`
  color: ${({ $isActive }) => ($isActive ? $white : ndColorLink)};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledFilterCount = styled.div`
  background-color: ${({ theme }) => theme.primary};
  text-align: center;
  padding: 6px;
  color: ${$white};
  border-radius: 4rem;
  min-width: 3rem;
`;

const propTypes = {
  className: string,
  activeFilter: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    count: PropTypes.number
  }).isRequired,
  filter: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
  }).isRequired,
  onFilter: PropTypes.func.isRequired
};

function Filter({ className, activeFilter, filter, onFilter }) {
  return (
    <StyledFilterItem
      className={`${className} nd-users-access-filter__item`}
      $isActive={
        activeFilter.key === filter.key && activeFilter.value === filter.value
      }
      onClick={() => onFilter(filter)}
    >
      <StyledFilterLabel
        className="nd-users-access-filter__label"
        $isActive={
          activeFilter.key === filter.key && activeFilter.value === filter.value
        }
      >
        {filter.label}
      </StyledFilterLabel>
      <StyledFilterCount
        $isActive={
          activeFilter.key === filter.key && activeFilter.value === filter.value
        }
        className="nd-users-access-filter__count"
      >
        {filter.count}
      </StyledFilterCount>
    </StyledFilterItem>
  );
}

Filter.propTypes = propTypes;

export default Filter;
