import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { loadOrganizations } from '../../actions/organizations';
import { filterOrganizationUsersByValue } from '../../actions/organizationUsers';
import { createOrganization } from '../../actions/provisioning';
import { getFilteredOrganizations } from '../../selectors/organizations';
import {
  getOrganizationUsers,
  getOrganizationUsersValueFilter
} from '../../selectors/users';
import DebounceTextInput from '../DebounceTextInput';
import NewOrganizationModal from '../NewOrganizationModal';
import OrganizationsAccessFilter from './OrganizationsAccessFilter';
import UsersTable from './UsersTable';

const ProvisioningHeader = styled.h1`
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  text-transform: uppercase;
  color: #606060;
`;

const ProvisioningActionsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const ProvisioningContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: 20px 10px;
  width: 100%;
  height: 100%;

  ${ProvisioningHeader}, ${ProvisioningActionsContainer} {
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;

  ${UsersTable} {
    flex: 1;
    height: 100%;
  }
`;

function Provisioning() {
  const dispatch = useDispatch();
  const [selectedOrganizationId, setOrganizationId] = useState(null);

  const handleFilterInput = useCallback(
    (val) => dispatch(filterOrganizationUsersByValue(val)),
    [dispatch]
  );
  const onCreateOrganization = useCallback(
    (organization) => dispatch(createOrganization(organization)),
    [dispatch]
  );
  const handleLoadOrganizations = useCallback(
    () => dispatch(loadOrganizations()),
    [dispatch]
  );

  const currentValueFilter = useSelector(getOrganizationUsersValueFilter);
  const newOrganizationForm = useSelector(
    (state) => state.organizations.newOrganizationForm
  );
  const organizations = useSelector(getFilteredOrganizations);
  const users = useSelector(getOrganizationUsers);
  const organizationUsersError = useSelector(
    (state) => state.organizationUsers.error
  );

  return (
    <ProvisioningContainer>
      <ProvisioningHeader>Provisioning</ProvisioningHeader>
      <ProvisioningActionsContainer>
        <DebounceTextInput
          onChange={handleFilterInput}
          placeholder="Filter Users"
          value={currentValueFilter}
        />
        <NewOrganizationModal
          onCreateOrganization={onCreateOrganization}
          loadOrganizations={handleLoadOrganizations}
          newOrganizationForm={newOrganizationForm}
        />
      </ProvisioningActionsContainer>
      <ContentWrapper>
        <OrganizationsAccessFilter
          selectedOrganizationId={selectedOrganizationId}
          organizations={organizations}
          setOrganizationId={setOrganizationId}
        />
        <UsersTable
          organizationUsersError={organizationUsersError}
          selectedOrganizationId={selectedOrganizationId}
          users={users}
        />
      </ContentWrapper>
    </ProvisioningContainer>
  );
}

export default Provisioning;
