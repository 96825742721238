import React, { MutableRefObject } from 'react';
import { Close, FolderAdd } from '@ndustrial/nd-icons-svg';
import styled from 'styled-components';
import StyledOptionWidget from '../../common/StyledOptionWidget';
import useOuterClick from '../../common/hooks/useOuterClick';
import AddGroupInput from "./AddGroupInput";

export interface IsAddGroupVisible {
  isAddGroupVisible: boolean;
}

const AddGroupIcon = styled.span`
  cursor: pointer;
  position: relative;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  font-size: 1.25rem;
  stroke: gray;

  &:hover {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

const AddGroupWrapper = styled.span`
  position: relative;
  top: -1rem;
  right: -1rem;
`;

const AddGroupMenu = styled(StyledOptionWidget)<IsAddGroupVisible>`
  background-color: white;
  width: 375px;
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 5px;
  cursor: auto;
  display: ${({ isAddGroupVisible }) => (isAddGroupVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.65rem;
`;

interface AddGroupProps {
  onAddGroup: (name: string) => void;
  isOpen: boolean;
  onToggleOpen: (isOpen: boolean) => void;
}

const AddGroupAction = ({
  onAddGroup,
  isOpen,
  onToggleOpen
}: AddGroupProps) => {
  const outerClickRef = useOuterClick(
    () => isOpen && onToggleOpen(false)
  ) as MutableRefObject<HTMLDivElement>;

  return (
    <AddGroupWrapper>
      <AddGroupMenu ref={outerClickRef} isAddGroupVisible={isOpen}>
        <AddGroupInput onAddGroup={({name}) => onAddGroup(name)} isActive={isOpen}/>
      </AddGroupMenu>
      {isOpen ? (
        <AddGroupIcon as={Close} onClick={() => onToggleOpen(false)} />
      ) : (
        <AddGroupIcon as={FolderAdd} onClick={() => onToggleOpen(true)} />
      )}
    </AddGroupWrapper>
  );
};

export default AddGroupAction;
