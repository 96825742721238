import {
  Menu,
  MenuButton as DropdownMenuButton,
  MenuItem,
  MenuList as DropdownMenuList
} from '@ndustrial/nd-menu-button-react';
import styled, { css, keyframes } from 'styled-components';

import '@ndustrial/nd-menu-button-react/lib/index.css';

const slideIn = keyframes`
    0% {
      opacity: 0;
    }
  
    1% {
      max-height: 0px;
      opacity: 0;
      transform: translateY(10px);
    }
  
    100% {
      max-height: 1000px;
      opacity: 1;
      transform: translateY(0);
    }
  `;

const slideOut = keyframes`
    0% {
      opacity: 1;
    }
  
    99% {
      max-height: 1000px;
      opacity: 0;
      transform: translateY(10px);
    }
  
    100% {
      max-height: 0px;
      opacity: 0;
      display: none;
    }
  `;

const slideInAnimation = () => css`
  ${slideIn} 200ms 1 normal cubic-bezier(0.165, 0.840, 0.440, 1) forwards;
`;

const slideOutAnimation = () => css`
  ${slideOut} 200ms 1 normal cubic-bezier(0.165, 0.840, 0.440, 1) forwards;
`;

const MenuButton = styled(DropdownMenuButton)`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  font-size: 1em;
  text-decoration: underline;
  font-weight: 400;
  margin: 0;
`;

const MenuList = styled(DropdownMenuList)`
  animation: ${({ isOpen }) => (isOpen ? slideOutAnimation : slideInAnimation)};
  background-color: #fff;
  outline: none;
  padding: 0 !important;
  width: 200px;

  height: 300px;
  overflow-y: scroll;

  > [data-reach-menu-item] {
    padding: 0;
  }

  > :nth-child(even) {
    background-color: #f4f4f4;
  }

  > [data-reach-menu-item][data-selected] {
    background: #ddd;
    color: ${({ theme }) => theme.primary};
  }
`;

export { Menu, MenuButton, MenuItem, MenuList };
