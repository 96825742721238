import React from 'react';
import PropTypes from 'prop-types';
import ReactTable, { ReactTableDefaults } from 'react-table';

import ActionsCell from './ActionsCell';
import Expander from '../UsersTable/Expander';
import ExpandedRole from '../../containers/ExpandedRole';
import styled from 'styled-components';
import { reactTableCss } from '../../utils/reactTableCss';

const StyledReactTable = styled(ReactTable)`
  flex: 1;
  height: 100%;

  a {
    text-decoration-line: underline;
    font-weight: 400;
  }

  .nd-loader {
    padding: 0;
    justify-content: center;
  }

  .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const propTypes = {
  className: PropTypes.string,
  onRemoveRole: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      applications: PropTypes.array.isRequired,
      projectEnvironments: PropTypes.array.isRequired,
      users: PropTypes.array.isRequired,
      isLoading: PropTypes.bool
    })
  ).isRequired
};

const columns = (onRemoveRole) => [
  {
    expander: true,
    width: 50,
    Expander: Expander
  },
  {
    accessor: 'name',
    className: 'nd-role-table__cell',
    Header: 'Name',
    id: 'name',
    minResizeWidth: 50
  },
  {
    accessor: 'description',
    className: 'nd-role-table__cell',
    Header: 'Description',
    id: 'description',
    minResizeWidth: 50
  },
  {
    accessor: (c) => c.applications.length,
    Cell: function Cell({ value }) {
      return `${value} Apps`;
    },
    className: 'nd-role-table__cell',
    Header: 'Apps',
    id: 'apps',
    maxWidth: 200,
    minResizeWidth: 50
  },
  {
    accessor: (c) => c.projectEnvironments.length,
    Cell: function Cell({ value }) {
      return `${value} Project Environments`;
    },
    className: 'nd-role-table__cell',
    Header: 'Project Environments',
    id: 'projectEnvironments',
    maxWidth: 200,
    minResizeWidth: 50
  },
  {
    accessor: (c) => c.users.length,
    Cell: function Cell({ value }) {
      return `${value} Users`;
    },
    className: 'nd-role-table__cell',
    Header: 'Users',
    id: 'users',
    maxWidth: 200,
    minResizeWidth: 50
  },
  {
    Cell: function Cell(cell) {
      return <ActionsCell cell={cell} onRemoveRole={onRemoveRole} />;
    },
    className: 'nd-role-table__cell',
    Header: 'Actions',
    id: 'actions',
    maxWidth: 200
  }
];

function RolesTable({ className, onRemoveRole, roles }) {
  const tableProps = {
    className: `${className} nd-role-table -striped -highlight`,
    column: {
      ...ReactTableDefaults.column,
      headerClassName: 'nd-role-table__header',
      minWidth: 100
    },
    showPageSizeOptions: false,
    defaultPageSize: 50
  };

  return (
    <StyledReactTable
      data={roles}
      columns={columns(onRemoveRole)}
      collapseOnDataChange={false}
      getTdProps={() => {
        return {
          style: {
            padding: '10px 15px',
            fontSize: '0.9rem',
            fontWeight: '400'
          }
        };
      }}
      getTheadThProps={() => {
        return {
          style: {
            padding: '14px 15px',
            fontSize: '0.8rem',
            fontWeight: '700',
            height: '50px',
            display: 'flex',
            alignItems: 'center'
          }
        };
      }}
      SubComponent={(row) => {
        return <ExpandedRole id={row.original.id} />;
      }}
      {...tableProps}
    />
  );
}

RolesTable.propTypes = propTypes;

export default styled(RolesTable)`
  ${reactTableCss}
`;
