/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * External module configuration in the sdk requires a defined module class.
 * However, if you just want to configure the service purely to fetch an auth
 * token (ie: nionic), then you can just use this placeholder class.
 */
class EmptyService {
  _baseUrl: string;
  constructor(sdk: any, audienceName: any) {
    this._baseUrl = sdk.config.audiences[audienceName._audienceName].host;
  }
}

export default EmptyService;
