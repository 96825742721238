import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader } from '@ndustrial/contxt-common';

import * as organizationUsersActionCreators from '../actions/organizationUsers';
import * as usersPermissionsActionCreators from '../actions/usersPermissions';
import DebounceTextInput from '../components/DebounceTextInput';
import NewUserModal from '../components/NewUserModal';
import UsersTable from '../components/UsersTable';
import UsersAccessFilter from '../components/UsersAccessFilter';
import {
  getOrganizationUsers,
  getOrganizationUsersCategoryFilter,
  getOrganizationUsersValueFilter,
  getUserAccessFilters,
  getUsersLoading
} from '../selectors/users';
import {
  getRoleApplications,
  getRoleEnvironments,
  getRoles
} from '../selectors/roles';
import styled from 'styled-components';
import {
  ndColorBorder,
  ndColorLink,
  ndColorText
} from '@ndustrial/contxt-common';

const StyledTableControls = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
`;

const StyledFilterUsers = styled(DebounceTextInput)`
  height: 30px;
  flex: 1;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid ${ndColorBorder};
  padding: (10 * 0.8) (10);
  color: ${ndColorText};
  background: rgba(0, 0, 0, 0);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 0.85rem;

  &:focus {
    border-color: ${ndColorLink};
  }

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.02);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

class ConnectedUsers extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      usersPermissions: PropTypes.shape({
        loadUserPermissions: PropTypes.func.isRequired
      }),
      organizationUsers: PropTypes.shape({
        addApplication: PropTypes.func.isRequired,
        addRole: PropTypes.func.isRequired,
        addProjectEnvironment: PropTypes.func.isRequired,
        filterOrganizationUsersByCategory: PropTypes.func.isRequired,
        filterOrganizationUsersByValue: PropTypes.func.isRequired,
        inviteNewOrganizationUser: PropTypes.func.isRequired,
        removeApplication: PropTypes.func.isRequired,
        removeOrganizationUser: PropTypes.func.isRequired,
        removeRole: PropTypes.func.isRequired,
        removeProjectEnvironment: PropTypes.func.isRequired
      })
    }),
    applications: PropTypes.array.isRequired,
    currentAccessFilter: PropTypes.object.isRequired,
    currentValueFilter: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    newUserForm: PropTypes.object.isRequired,
    organizationUsers: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    selectedOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired
    }),
    projectEnvironments: PropTypes.array.isRequired,
    userAccessFilters: PropTypes.array.isRequired
  };

  handleInviteNewUser = (newUser) => {
    newUser.redirectUrl = `${window.location.protocol}//${
      window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}/activate`;

    return this.props.actions.organizationUsers.inviteNewOrganizationUser(
      this.props.selectedOrganization.id,
      newUser
    );
  };

  handleRemoveUser = (userId) => {
    return this.props.actions.organizationUsers.removeOrganizationUser(
      this.props.selectedOrganization.id,
      userId
    );
  };

  handleAddRole = (userId, roleId) => {
    return this.props.actions.organizationUsers
      .addRole(userId, roleId)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          userId
        );
      });
  };

  handleRemoveRole = (userId, roleId) => {
    return this.props.actions.organizationUsers
      .removeRole(userId, roleId)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          userId
        );
      });
  };

  render() {
    if (this.props.isLoading) {
      return <Loader label="LOADING USERS" />;
    }

    return (
      <React.Fragment>
        <StyledTableControls className="nd-user-table__controls">
          <StyledFilterUsers
            onChange={
              this.props.actions.organizationUsers
                .filterOrganizationUsersByValue
            }
            placeholder="Filter Users"
            value={this.props.currentValueFilter}
          />
          <NewUserModal
            onInviteNewUser={this.handleInviteNewUser}
            newUserForm={this.props.newUserForm}
          />
        </StyledTableControls>
        <StyledTableWrapper className="nd-user-table__wrapper">
          <UsersAccessFilter
            activeFilter={this.props.currentAccessFilter}
            filterGroups={this.props.userAccessFilters}
            onFilter={
              this.props.actions.organizationUsers
                .filterOrganizationUsersByCategory
            }
          />
          <UsersTable
            onRemoveUser={this.handleRemoveUser}
            users={this.props.organizationUsers}
          />
        </StyledTableWrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    organizationUsers: getOrganizationUsers(state),
    selectedOrganization: state.organizations.selectedOrganization,
    newUserForm: state.organizationUsers.newUserForm,
    userAccessFilters: getUserAccessFilters(state),
    currentAccessFilter: getOrganizationUsersCategoryFilter(state),
    currentValueFilter: getOrganizationUsersValueFilter(state),
    roles: getRoles(state),
    applications: getRoleApplications(state),
    projectEnvironments: getRoleEnvironments(state),
    isLoading: getUsersLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      organizationUsers: bindActionCreators(
        organizationUsersActionCreators,
        dispatch
      ),
      usersPermissions: bindActionCreators(
        usersPermissionsActionCreators,
        dispatch
      )
    }
  };
}

export { ConnectedUsers as Users };
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
