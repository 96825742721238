import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Admin from '../containers/Admin';
import OrgContainer from '../containers/OrgContainer';
import Applications from '../containers/Applications';
import UserSettings from '../containers/UserSettings';
import NotFound from '../components/NotFound';
import history from '../history';
import ProtectedRoute from './ProtectedRoute';
import SessionContainer from '../containers/SessionContainer';
import Provisioning from '../components/Provisioning';
import AssetManagerRouter from '../../ts/containers/AssetManagerRouter';
import DataExplorer from '../../ts/containers/DataExplorer';

export const APPLICATIONS_BASE_ROUTE = '/organizations/:orgSlug/applications';
export const ADMIN_BASE_ROUTE = '/organizations/:orgSlug/admin';
export const PROVISIONING_BASE_ROUTE = '/organizations/:orgSlug/provisioning';
export const ASSET_MANAGER_BASE_ROUTE = '/organizations/:orgSlug/asset-manager';
export const DATA_EXPLORER_BASE_ROUTE = '/organizations/:orgSlug/data-explorer';

export default function Routes() {
  return (
    <SessionContainer history={history}>
      <Switch>
        <Route path="/settings" component={UserSettings} />
        <OrgContainer history={history}>
          <Switch>
            <Route path={APPLICATIONS_BASE_ROUTE} component={Applications} />
            <ProtectedRoute
              allowedRoles={['admin', 'Organization Admin']}
              component={Admin}
              path={ADMIN_BASE_ROUTE}
              redirectPath={APPLICATIONS_BASE_ROUTE}
            />
            <Route path={PROVISIONING_BASE_ROUTE} component={Provisioning} />
            <Route
              path={ASSET_MANAGER_BASE_ROUTE}
              component={AssetManagerRouter}
            />
            <Route path={DATA_EXPLORER_BASE_ROUTE} component={DataExplorer} />
            <Route component={NotFound} />
          </Switch>
        </OrgContainer>
      </Switch>
    </SessionContainer>
  );
}
