/* eslint-disable @typescript-eslint/no-explicit-any */
import { toCamelCase, toSnakeCase } from '@ndustrial/contxt-sdk';

class AdminService {
  _baseUrl: string;
  _request: any;
  _sdk: any;
  constructor(sdk: any, request: any) {
    this._baseUrl = `${sdk.config.audiences.admin.host}/access/v1`;
    this._request = request;
    this._sdk = sdk;
  }

  addExistingUserToOrganization(organizationId: string, userId: string) {
    return this._request
      .post(`${this._baseUrl}/${organizationId}/users/${userId}`)
      .then((organization: any) => toCamelCase(organization));
  }

  createOrganization(organization = {}) {
    return this._request
      .post(`${this._baseUrl}/organizations`, toSnakeCase(organization))
      .then((organization: any) => toCamelCase(organization));
  }

  loadUsers(organizationId: string) {
    return this._request
      .get(`${this._baseUrl}/${organizationId}/users`, {
        params: {
          include_organizations: true
        }
      })
      .then((organizationUsers: any) => toCamelCase(organizationUsers));
  }

  removeUserFromOrganization(organizationId: any, userId: any) {
    return this._request
      .delete(`${this._baseUrl}/${organizationId}/users/${userId}`)
      .then((organization: any) => toCamelCase(organization));
  }

  updateOrganization(organization: any = {}) {
    return this._request
      .put(
        `${this._baseUrl}/organizations/${organization.id}`,
        toSnakeCase(organization)
      )
      .then((organization: any) => toCamelCase(organization));
  }
}

export default AdminService;
