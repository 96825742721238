import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoading: true,
  items: [],
  error: null
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_USERS_PERMISSIONS_START:
      return {
        ...state,
        isLoading: true,
        error: INITIAL_STATE.error
      };
    case actionTypes.LOAD_USERS_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: action.payload
      };
    case actionTypes.LOAD_USERS_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case actionTypes.LOAD_USER_PERMISSIONS_START:
      return {
        ...state,
        error: INITIAL_STATE.error
      };
    case actionTypes.LOAD_USER_PERMISSIONS_SUCCESS: {
      const items = state.items.filter(
        (x) => x.userId !== action.payload.userId
      );

      return {
        ...state,
        items: [...items, action.payload]
      };
    }
    case actionTypes.LOAD_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case actionTypes.USER_PERMISSIONS_APPLICATION_ADD: {
      const item = state.items.filter(
        ({ userId }) => userId === action.payload.userId
      );

      if (!item.length) {
        return state;
      }

      const updatedItem = {
        ...item[0],
        applicationsExplicit: [
          ...item[0].applicationsExplicit,
          { id: action.payload.applicationId }
        ]
      };

      const items = state.items.filter(
        ({ userId }) => userId !== action.payload.userId
      );

      return {
        ...state,
        items: [...items, updatedItem]
      };
    }
    case actionTypes.USER_PERMISSIONS_APPLICATION_REMOVE: {
      const item = state.items.filter(
        ({ userId }) => userId === action.payload.userId
      );

      if (!item.length) {
        return state;
      }

      const updatedItem = {
        ...item[0],
        applicationsExplicit: item[0].applicationsExplicit.filter(
          ({ id }) => id !== action.payload.applicationId
        )
      };

      const items = state.items.filter(
        ({ userId }) => userId !== action.payload.userId
      );

      return {
        ...state,
        items: [...items, updatedItem]
      };
    }
    case actionTypes.USER_PERMISSIONS_PROJECT_ENVIRONMENT_ADD: {
      const item = state.items.filter(
        ({ userId }) => userId === action.payload.userId
      );

      if (!item.length) {
        return state;
      }

      const updatedItem = {
        ...item[0],
        projectEnvironmentsExplicit: [
          ...item[0].projectEnvironmentsExplicit,
          {
            ...action.payload.projectEnvironment,
            accessType: action.payload.accessType
          }
        ]
      };

      const items = state.items.filter(
        ({ userId }) => userId !== action.payload.userId
      );

      return {
        ...state,
        items: [...items, updatedItem]
      };
    }
    case actionTypes.USER_PERMISSIONS_PROJECT_ENVIRONMENT_REMOVE: {
      const item = state.items.filter(
        ({ userId }) => userId === action.payload.userId
      );

      if (!item.length) {
        return state;
      }

      const updatedItem = {
        ...item[0],
        projectEnvironmentsExplicit: item[0].projectEnvironmentsExplicit.filter(
          ({ id }) => id !== action.payload.projectEnvironmentId
        )
      };

      const items = state.items.filter(
        ({ userId }) => userId !== action.payload.userId
      );

      return {
        ...state,
        items: [...items, updatedItem]
      };
    }
    default:
      return state;
  }
}

export default reducer;
