import { find } from 'lodash';

import actionTypes from '../constants/actionTypes';
import { contxtSdk } from '@ndustrial/contxt-common';

function addExistingUserToOrganization(organizationId, userId) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_START
    });

    return contxtSdk.admin
      .addExistingUserToOrganization(organizationId, userId)
      .then((organizationUser) => {
        const { items } = getState().organizations;
        const addedOrganization = find(items, {
          id: organizationUser.organizationId
        });

        dispatch({
          type: actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_SUCCESS,
          payload: {
            organization: addedOrganization,
            userId
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function createOrganization(organization) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_ORGANIZATION_START
    });

    return contxtSdk.admin
      .createOrganization(organization)
      .then(() => {
        dispatch({
          type: actionTypes.CREATE_ORGANIZATION_SUCCESS
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_ORGANIZATION_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function loadUsers(organizationId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOAD_ORGANIZATION_USERS_START
    });

    return contxtSdk.admin
      .loadUsers(organizationId)
      .then((users) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_USERS_SUCCESS,
          payload: users
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOAD_ORGANIZATION_USERS_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

function removeUserFromOrganization(organizationId, userId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_START
    });

    return contxtSdk.admin
      .removeUserFromOrganization(organizationId, userId)
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_SUCCESS,
          payload: {
            organizationId,
            userId
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_FAILURE,
          payload: err
        });

        throw err;
      });
  };
}

export {
  addExistingUserToOrganization,
  createOrganization,
  loadUsers,
  removeUserFromOrganization
};
