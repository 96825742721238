import styled from 'styled-components';
import React from 'react';
import { defaultTheme } from '@ndustrial/nd-theme-react';
import { Tooltip } from '@ndustrial/nd-tooltip-react';
import { Info as InfoIcon } from '@ndustrial/nd-icons-svg';

// TODO this should be global somewhere probably
const FieldMarginTop = 15;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;

  margin-top: ${FieldMarginTop}px;
  margin-bottom: 5px;
  font-weight: 400;
  font-style: normal;
  font-size: 0.83rem;
  color: ${defaultTheme.text};
`;

const Switch = styled.div<{ $height: number }>`
  position: relative;
  width: ${({ $height }) => `${$height * 2}px`};
  height: ${({ $height }) => `${$height}px`};
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: ${({ $height }) => `${$height - 4}px`};
    height: ${({ $height }) => `${$height - 4}px`};
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: white;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input<{ $height: number }>`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${({ theme }) => theme.primary};

    &:before {
      transform: translate(${({ $height }) => `${$height}px`}, -50%);
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
`;

export interface ToggleSwitchProps {
  className?: string;
  checked: boolean;
  label?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  height?: number;
  tooltipContent?: string | null;
}

export const ToggleSwitch = ({
  className,
  checked,
  label,
  onChange,
  height = 24,
  tooltipContent
}: ToggleSwitchProps) => {
  return (
    <Label className={className}>
      {label && (
        <LabelWrapper>
          {label}
          {!!tooltipContent && (
            <StyledTooltip content={tooltipContent}>
              <InfoIcon stroke="#0b588a" />
            </StyledTooltip>
          )}
        </LabelWrapper>
      )}

      <Input
        $height={height}
        checked={checked}
        type="checkbox"
        onChange={onChange}
      />
      <Switch $height={height} />
      <span>{checked ? 'On' : 'Off'}</span>
    </Label>
  );
};
