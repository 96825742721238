import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@ndustrial/contxt-common';
import { PrimaryButton } from '@ndustrial/nd-button-react';
import Form from './Form';
import { useTheme } from 'styled-components';

const propTypes = {
  newRoleForm: PropTypes.object.isRequired,
  onCreateRole: PropTypes.func.isRequired
};

function NewRoleModal(props) {
  const [isModalShown, setIsModalShown] = useState(false);
  const theme = useTheme();

  const openModal = () => {
    setIsModalShown(true);
  };

  const closeModal = () => {
    setIsModalShown(false);
  };

  const handleSubmit = (newRole) => {
    props.onCreateRole(newRole).then(() => {
      closeModal();
    });
  };
  return (
    <React.Fragment>
      <Modal
        title="Add Role"
        isOpen={isModalShown}
        onRequestClose={closeModal}
        customStyles={{
          height: 235,
          width: 800,
          padding: 0,
          borderRadius: 0,
          border: 'none'
        }}
        customHeaderStyles={{
          backgroundColor: theme.primary,
          padding: '10px',
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: 'none'
        }}
      >
        <Form onSubmit={handleSubmit} formState={props.newRoleForm} />
      </Modal>
      <PrimaryButton className="create-new-role-button" onClick={openModal}>
        Add Role
      </PrimaryButton>
    </React.Fragment>
  );
}

NewRoleModal.propTypes = propTypes;

export default NewRoleModal;
