import React from 'react';
import PropTypes from 'prop-types';

import DebounceTextInput from './DebounceTextInput';
import styled from 'styled-components';
import { ndColorBorder } from '@ndustrial/contxt-common';

const StyledDebounceTextInput = styled(DebounceTextInput)`
  margin: 0;
  max-width: 300px;
  position: absolute;
  right: 20px;
  top: 75px;
  font-size: 0.625rem;
  border: 1px solid ${ndColorBorder};
  color: var(--nd-color-text);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0;
  -webkit-appearance: none;
  height: 30px;
  padding: 8px 10px;
  outline: none;
  width: 30em;
  z-index: 10;

  :focus {
    border-color: var(--nd-color-link);
  }
`;

const propTypes = {
  onFilter: PropTypes.func.isRequired,
  value: PropTypes.string
};

function ApplicationsFilter({ onFilter, value }) {
  return (
    <StyledDebounceTextInput
      className="application-filter"
      placeholder="Filter Applications"
      value={value}
      onChange={onFilter}
    />
  );
}

ApplicationsFilter.propTypes = propTypes;

export default ApplicationsFilter;
