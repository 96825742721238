import { FacilityFieldType } from '../types/types';

export function facilityAttributeIsRequired(
  attribute: FacilityFieldType | undefined
) {
  if (attribute?.type.ofType === null) {
    return false;
  } else {
    return true;
  }
}
