import {
  AllGroupsDocument,
  useCreateFacilityGroupMutation
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { useCallback } from 'react';

export type OnAddGroupProps = { name: string; parentId?: string | null };

export default () => {
  const [addGroup] = useCreateFacilityGroupMutation();
  return useCallback(
    ({ name, parentId = null }: OnAddGroupProps) => {
      return addGroup({
        variables: {
          input: {
            facilityGroup: {
              name,
              parentId
            }
          }
        },
        refetchQueries: [AllGroupsDocument]
      });
    },
    [addGroup]
  );
};
