import React from 'react';
import styled from 'styled-components';

import { LabelSpan } from '@ndustrial/contxt-common';
import { FacilityGroupingTree } from './GroupingsContainer';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
`;

const Attribute = styled.span`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AttributeValue = styled.span``;

type GroupAttributesProps = {
  group: FacilityGroupingTree;
};

function GroupAttributes({ group }: GroupAttributesProps) {
  return (
    <Wrapper>
      <Attribute>
        <LabelSpan>Subgroups</LabelSpan>
        <AttributeValue>{group.groupCount}</AttributeValue>
      </Attribute>
      <Attribute>
        <LabelSpan>Facilities</LabelSpan>
        <AttributeValue>{group.facilityCount}</AttributeValue>
      </Attribute>
    </Wrapper>
  );
}

export default GroupAttributes;
