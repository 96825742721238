import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

import { CheckCircle, Close, Pencil } from '@ndustrial/nd-icons-svg';

import { FacilityGroupingTree } from './GroupingsContainer';
import useOuterClick from '../../common/hooks/useOuterClick';
import { GroupingItemProps } from './GroupingItem';

const GroupNameValue = styled.div<{ isRoot: boolean }>`
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${({ isRoot }) => (isRoot ? '1.2rem' : '1rem')};
`;

const GroupValueInput = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const GroupNameValueTextArea = styled.textarea`
  padding: 0.25rem 2rem 0.25rem 0.25rem;
  flex: 1;
  background: transparent;
  outline: none;
  font-size: 1.1rem;
  font-family: 'Roboto';
  border: 1px solid gray;
  margin-right: 0.5rem;
  height: 30px;
  resize: none;

  &:focus {
    border-color: ${({ theme }) => theme.primary};
  }
`;

const GroupNameAction = styled.span`
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
`;

const StyledPencil = styled(Pencil)`
  display: none;
  width: 1.2rem;
  height: 1.2rem;
  stroke: gray;
  margin-left: 10px;

  &:hover {
    stroke: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

const StyledClose = styled(Close)``;
const StyledCheck = styled(CheckCircle)`
  position: absolute;
  stroke: #00c01d;
  width: 1.3rem;
  height: 1.3rem;
  right: 15px;
  top: 5px;
  animation: grow-check 0.3s linear forwards;

  @keyframes grow-check {
    0% {
      transform: scale(1, 1);
      opacity: 0.25;
    }
    50% {
      transform: scale(1.1, 1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const GroupName = styled.span<{ isEditing: boolean; isOver: boolean }>`
  padding: 0.25rem 2rem 0.25rem 0.25rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: transform 0.1s ease-in;
  transform: ${({ isEditing }) =>
    isEditing ? `scale(1.02,1.02)` : `scale(1,1)`};
  transform-origin: left;

  ${StyledClose}, ${StyledPencil} {
    width: 1.1rem;
    height: 1.1rem;
  }

  ${StyledPencil} {
    display: ${({ isOver }) => isOver && 'inline-block'};
  }

  &:hover {
    ${GroupNameValue} {
      text-decoration-color: gray;
      text-decoration-style: dashed;
      text-decoration-thickness: 1px;
      text-decoration-line: underline;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: edit;
  position: relative;
`;

export interface EditableDebounceTextProps {
  group: FacilityGroupingTree;
  onUpdate: (value: string) => Promise<unknown>;
  isOver: boolean;
}

const GroupNameDebouncedInput = ({
  group,
  onUpdate,
  isOver,
  isRoot = false
}: EditableDebounceTextProps & Partial<GroupingItemProps>) => {
  const [isEditing, setIsEditing] = useState(false);
  const [groupName, setGroupName] = useState(group.name);
  const [success, setSuccess] = useState(false);

  const groupNameRef = useRef(group.name);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const outerClickRef = useOuterClick(() => {
    isEditing && setIsEditing(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateGroupName = useCallback(
    debounce(() => {
      onUpdate(groupNameRef.current).then(() => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 2000);
      });
    }, 700),
    []
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = groupName.length;
    }
  }, [isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GroupName isEditing={isEditing} ref={outerClickRef} isOver={isOver}>
      {isEditing ? (
        <FlexRow>
          <GroupValueInput>
            <GroupNameValueTextArea
              ref={inputRef}
              value={groupName}
              onChange={(e) => {
                setGroupName(e.currentTarget.value);
                groupNameRef.current = e.currentTarget.value;
              }}
              onKeyDown={updateGroupName}
            />
            {success && <StyledCheck />}
          </GroupValueInput>
          <GroupNameAction>
            <StyledClose onClick={() => setIsEditing(false)} />
          </GroupNameAction>
        </FlexRow>
      ) : (
        <FlexRow onClick={() => setIsEditing(true)}>
          <GroupNameValue isRoot={isRoot}>{groupName}</GroupNameValue>
          <GroupNameAction>
            <StyledPencil />
          </GroupNameAction>
        </FlexRow>
      )}
    </GroupName>
  );
};

export default GroupNameDebouncedInput;
