import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { nameof } from '@ndustrial/contxt-common';
import { Facility as FacilityType } from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { toast } from 'react-toastify';
import { User } from './types/types';
import FacilityContactCard from './FacilityContactCard';
import Select, { createFilter, SingleValue } from 'react-select';
import { default as MenuList } from './common/CustomReactSelectList';
import { ReactSelectSingleValueStyles } from '@ndustrial/contxt-common/src';

interface Props {
  facilityData: FacilityType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateField: (fieldName: string, newValue: any) => void;
  orgId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contxtSdk: any;
}

const SeeAllUsersButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  padding-left: 0;

  :hover {
    cursor: pointer;
  }
`;

const FacilityEditorContacts = (props: Props) => {
  const [orgUsers, setOrgUsers] = useState<User[]>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>();
  const [seeAllUsers, setSeeAllUsers] = useState<boolean>(false);

  useEffect(() => {
    props.contxtSdk.coordinator.users
      .getByOrganizationId(props.orgId)
      .then((users: User[]) => {
        setOrgUsers(users);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        toast(error);
      });
  }, [props.orgId]);

  useEffect(() => {
    const userIds = props.facilityData?.facilityContacts?.split(',');
    setSelectedUsers(orgUsers?.filter((user) => userIds?.includes(user.id)));
  }, [orgUsers, props.facilityData?.facilityContacts]);

  function onSelectChange(
    selection: SingleValue<{
      value: string;
      label: string;
    }>
  ) {
    if (selection) {
      if (selectedUsers?.map((user) => user.id).includes(selection.value)) {
        return;
      }
      if (
        props.facilityData.facilityContacts &&
        props.facilityData.facilityContacts.length > 0
      ) {
        props.updateField(
          nameof<FacilityType>('facilityContacts'),
          `${props.facilityData.facilityContacts},${selection.value}`
        );
      } else {
        props.updateField(
          nameof<FacilityType>('facilityContacts'),
          `${selection.value}`
        );
      }
      setSeeAllUsers(true);
    }
  }

  function onDeleteUser(userId: string) {
    props.updateField(
      nameof<FacilityType>('facilityContacts'),
      selectedUsers
        ?.filter((user) => user.id !== userId)
        .map((user) => user.id)
        .join()
    );
  }

  const options = useMemo(() => {
    return orgUsers
      ?.sort((a, b) => {
        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      })
      .map((user) => {
        return { value: user.id, label: `${user.firstName} ${user.lastName}` };
      });
  }, [orgUsers]);

  const memoizedStyle = useMemo(() => {
    return ReactSelectSingleValueStyles(40);
  }, []);

  return (
    <>
      <Select<SingleValue<{ value: string; label: string }>>
        options={options ?? []}
        placeholder="Add a contact..."
        isLoading={!orgUsers}
        onChange={onSelectChange}
        value={null}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={memoizedStyle}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore next-line
        components={{ MenuList }} // PLEASE NOTE: this HAS to be named `MenuList`
      />
      {seeAllUsers &&
        selectedUsers?.map((user: User) => {
          return (
            <FacilityContactCard
              user={user}
              onDeleteUser={onDeleteUser}
              key={user.id}
            />
          );
        })}
      {!seeAllUsers && selectedUsers && selectedUsers.length > 0 && (
        <>
          <FacilityContactCard
            user={selectedUsers[0]}
            onDeleteUser={onDeleteUser}
          />
          {selectedUsers.length !== 1 && (
            <SeeAllUsersButton
              onClick={() => {
                setSeeAllUsers(true);
              }}
            >
              See all contacts...
            </SeeAllUsersButton>
          )}
        </>
      )}
    </>
  );
};

export default FacilityEditorContacts;
