export const RECOIL_BASE_KEY = 'contxt-common-';

export const $white = '#fff';
export const $gray = '#d7d7d7';
export const $grayVeryLight = '#fbfbfb';
export const $grayLight = '#e8e8e8';
export const $grayDark = '#bebebe';
export const $grayVeryDark = '#606060';

export const ndColorLink = '#0a588a';
export const ndColorBorder = '#f2f2f2';
export const ndColorSubtle = '#bababa';
// export const ndColorPrimary = '#2ab7cc';
export const ndColorText = '#717171';

// Status
export const ndColorCritical = '#da5f73';
export const ndColorSuccess = '#8ec640';
export const ndColorFailure = '#da5f73';

// Theme
export const ndPageBackground = '#fbfbfb';
export const ndInputBorderColor = '#a9a9a9';
export const ndInputPlaceholderColor = '#c1c1c1';
export const toastDefaultColor = '#8ab4f8';
