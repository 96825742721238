import { gql, useApolloClient } from '@apollo/client';
import React, { ChangeEvent, KeyboardEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { InputTextField } from '@ndustrial/nd-inputs-react';
import { Loader, LoaderDot } from '@ndustrial/nd-loader-react';
import { nameof, ToggleSwitch } from '@ndustrial/contxt-common';
import { PrimaryButton, WarningButton } from '@ndustrial/nd-button-react';
import { ArrowLeft, Trash } from '@ndustrial/nd-icons-svg';
import {
  Facility as FacilityType,
  useGetFacilitySchemaQuery,
  GetFacilitySchemaQuery,
  __TypeKind,
  UpdateFacilityBySlugInput,
  CreateFacilityInput,
  Scalars,
  useDeleteFacilityByNodeIdMutation
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import FacilityEditorHeader from './FacilityEditorHeader';
import FacilityEditorContacts from './FacilityEditorContacts';
import { SingleDatePicker } from '@ndustrial/nd-date-picker-react';
import moment from 'moment';
import { FacilityEditorProps } from '.';
import {
  ConfirmDeleteModal,
  EditorInputTextFieldStyle,
  FacilityFieldsArray,
  FacilityFieldType,
  ndInputBorderColor,
  ndInputPlaceholderColor
} from '@ndustrial/contxt-common/src';

const addressFields: string[] = [
  nameof<FacilityType>('address'),
  nameof<FacilityType>('city'),
  nameof<FacilityType>('state'),
  nameof<FacilityType>('zip'),
  nameof<FacilityType>('timezoneName'),
  nameof<FacilityType>('country')
];
const headerFields: string[] = [
  nameof<FacilityType>('name'),
  nameof<FacilityType>('facilityContacts'),
  ...addressFields
];
const keyFields: string[] = [
  nameof<FacilityType>('id'),
  nameof<FacilityType>('nodeId'),
  nameof<FacilityType>('slug')
];
const readOnlyFields: string[] = [
  ...keyFields,
  nameof<FacilityType>('createdAt'),
  nameof<FacilityType>('updatedAt')
];

const StyledLoader = styled(Loader)`
  ${LoaderDot} {
    background-color: #fff;
  }
`;

const PageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 30px;
`;

const Header = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
`;

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 30px;
  margin-bottom: 0;
`;

const SectionWrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  margin: 0 auto;
  width: 750px;
`;

const EditFacilityDiv = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
  flex: 1;
`;

const StyledInputTextField = styled(InputTextField)`
  ${EditorInputTextFieldStyle}
`;
const StyledHeading = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const StyledArrowBack = styled(ArrowLeft)`
  stroke: #838383;
  height: 45px;
  width: 45px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;

  :hover {
    stroke: #646464;
    cursor: pointer;
    background-color: #e8e8e8;
    border-radius: 25px;
  }
`;

const StyledSaveButton = styled(PrimaryButton)`
  flex: 0 1 auto;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
`;

const StyledDeleteButton = styled(WarningButton)`
  flex: 0 0 40px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 50px;
`;

const StyledErrorDiv = styled.div`
  padding: 10px;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  border-bottom: 1px solid #ebebeb;
  margin-top: 20px;
  padding-bottom: 20px;
`;

const StyledSingleDatePicker = styled(SingleDatePicker)`
  margin-top: 15px;
  width: 100%;

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    width: 100%;
    border-color: ${ndInputBorderColor};

    .DateInput_input {
      &::placeholder {
        color: ${ndInputPlaceholderColor};
      }
    }
  }

  label {
    font-weight: 400;
    font-style: normal !important;
  }

  input {
    margin-bottom: 0;
    padding-left: 10px !important;
    font-size: 1rem !important;
  }
`;

function getFacilityDataQuery(
  facilityId: number | string,
  simpleFieldsList: FacilityFieldsArray
) {
  return gql`
    query getFacilityData {
      facility(id: ${facilityId}) {
        ${simpleFieldsList.map((field) => field.name + '\n').join('')}
      }
    }
  `;
}

function sortFacilitySchemaByTypeThenName(
  a: FacilityFieldType,
  b: FacilityFieldType
) {
  const typeOfA = a.type?.name || a.type.ofType?.name;
  const typeOfB = b.type?.name || b.type.ofType?.name;
  if (!typeOfA || !typeOfB || typeOfA === typeOfB) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
  } else {
    if (typeOfA < typeOfB) {
      return -1;
    }
    if (typeOfA > typeOfB) {
      return 1;
    }
  }
  return 0;
}

const FacilityEditor = (props: FacilityEditorProps) => {
  const apolloClient = useApolloClient();
  const [deleteFacility] = useDeleteFacilityByNodeIdMutation();
  const [simpleFields, setSimpleFields] = useState<FacilityFieldsArray>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [facilityData, setFacilityData] = useState<any>({});
  const [updatedFacilityFields, setUpdatedFacilityFields] = useState<string[]>(
    []
  );
  const [facilityDataFetchFailedMessage, setFacilityDataFetchFailedMessage] =
    useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  function fieldIsNeeded(fieldName: string) {
    if (!props.fieldWhitelist || keyFields.includes(fieldName)) {
      return true;
    }
    // using address as a special field to include all address fields: city, state, zip, timezone
    // as it wouldn't make much sense to show only some of these
    if (
      addressFields.includes(fieldName) &&
      props.fieldWhitelist.includes(nameof<FacilityType>('address'))
    ) {
      return true;
    }
    if (!props.fieldWhitelist.includes(fieldName)) {
      return false;
    } else {
      return true;
    }
  }

  // this function is differentiating between complex/simple fields.
  // Simple fields should be of scalar type and not require additional parameters/fields to query
  function fieldIsSimple(field: FacilityFieldType) {
    if (
      field.type.kind === __TypeKind.SCALAR ||
      field.type?.ofType?.kind === __TypeKind.SCALAR ||
      field.type.kind === __TypeKind.ENUM ||
      field.type?.ofType?.kind === __TypeKind.ENUM
    ) {
      return true;
    } else {
      return false;
    }
  }

  const allFacilityFieldsQuery = useGetFacilitySchemaQuery({
    onCompleted: (data: GetFacilitySchemaQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tempSimpleFields: any[] = [];
      data.__type?.fields?.forEach((field) => {
        if (fieldIsNeeded(field.name) && fieldIsSimple(field)) {
          tempSimpleFields.push(field);
        }
      });
      setSimpleFields(tempSimpleFields.sort(sortFacilitySchemaByTypeThenName));
      if (props.facilityId) {
        if (isNaN(Number(props.facilityId))) {
          setFacilityDataFetchFailedMessage('Facility not found');
        } else {
          apolloClient
            .query({
              query: getFacilityDataQuery(props.facilityId, tempSimpleFields)
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((result: any) => {
              if (result?.data?.facility) {
                setFacilityData(result?.data?.facility);
              } else {
                setFacilityDataFetchFailedMessage('Facility not found');
              }
            })
            .catch((error) => {
              setFacilityDataFetchFailedMessage(error.message);
            });
        }
      }
    }
  });

  const hasAttributeFields = useMemo(() => {
    if (!props.fieldWhitelist) {
      return true;
    }
    let hasField = false;
    simpleFields?.forEach((field) => {
      if (
        !hasField &&
        !headerFields.includes(field.name) &&
        !readOnlyFields.includes(field.name)
      ) {
        hasField = true;
      }
    });
    return hasField;
  }, [simpleFields, props.fieldWhitelist]);

  const hasReadonlyFields = useMemo(() => {
    if (!props.fieldWhitelist) {
      return true;
    }
    let hasField = false;
    simpleFields?.forEach((field) => {
      if (!hasField && readOnlyFields.includes(field.name)) {
        hasField = true;
      }
    });
    return hasField;
  }, [simpleFields, props.fieldWhitelist]);

  if (allFacilityFieldsQuery.error)
    return (
      <StyledErrorDiv className={props.className}>
        {allFacilityFieldsQuery.error.message}
      </StyledErrorDiv>
    );
  if (facilityDataFetchFailedMessage)
    return (
      <StyledErrorDiv className={props.className}>
        {facilityDataFetchFailedMessage}
      </StyledErrorDiv>
    );

  if (
    allFacilityFieldsQuery.loading ||
    (props.facilityId && !facilityData?.nodeId)
  ) {
    return (
      <StyledLoader
        className={props.className}
        label={'LOADING FACILITY DATA...'}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateField(fieldName: string, newValue: any) {
    setFacilityData({
      ...facilityData,
      [fieldName]: newValue
    });
    setUpdatedFacilityFields((oldFields) => {
      if (!oldFields.includes(fieldName)) {
        return [...oldFields, fieldName];
      } else {
        return oldFields;
      }
    });
  }

  function constructMutationQuery() {
    if (!props.facilityId) {
      return gql`
      mutation CreateFacility($input: CreateFacilityInput!) {
        createFacility(input: $input) {
          facility {
            ${updatedFacilityFields.map((field) => field + '\n').join('')}
          }
        }
      }
    `;
    } else {
      return gql`
      mutation ($input: UpdateFacilityBySlugInput!) {
        updateFacilityBySlug(input: $input) {
          facility {
            ${updatedFacilityFields.map((field) => field + '\n').join('')}
          }
        }
      }
    `;
    }
  }

  function constructMutationVariables(): {
    input: UpdateFacilityBySlugInput | CreateFacilityInput;
  } {
    if (!props.facilityId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const facility: any = {};
      updatedFacilityFields.forEach(
        (field) => (facility[field] = facilityData[field])
      );
      return {
        input: {
          facility: facility
        }
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const patch: any = {};
      updatedFacilityFields.forEach(
        (field) => (patch[field] = facilityData[field])
      );
      return {
        input: {
          slug: facilityData.slug,
          patch: patch
        }
      };
    }
  }

  function onDeleteConfirmClick() {
    deleteFacility({
      variables: {
        input: {
          nodeId: (facilityData as FacilityType).nodeId
        }
      },
      update: (cache) => {
        cache.evict({ id: `Facility:${facilityData.id}` });
      }
    })
      .then((result) => {
        if (
          result.data?.deleteFacilityByNodeId?.deletedFacilityNodeId ===
          (facilityData as FacilityType).nodeId
        ) {
          if (props.onDelete) {
            props.onDelete();
          }
        } else {
          if (props.onDelete) {
            props.onDelete('Could not delete facility. Please try again.');
          }
        }
      })
      .catch((error) => {
        props.onSubmit(error.message);
      });
  }

  function onSubmit() {
    if (!props.facilityId && (!facilityData.slug || !facilityData.name)) {
      props.onSubmit('Please provide a slug and name to create a new facility');
    } else {
      if (updatedFacilityFields.length === 0) {
        props.onSubmit(
          undefined,
          'There are no updates to save. Cancelling action.'
        );
      } else {
        apolloClient
          .mutate({
            mutation: constructMutationQuery(),
            variables: constructMutationVariables(),
            update: (cache) => {
              cache.evict({ id: `Facility:${facilityData.id}` });
            }
          })
          .then(() => {
            props.onSubmit();
            setUpdatedFacilityFields([]);
          })
          .catch((error) => props.onSubmit(error.message));
      }
    }
  }

  return (
    <PageWrapper className={props.className}>
      <Header>
        {props.onGoBack && <StyledArrowBack onClick={props.onGoBack} />}
        <EditFacilityDiv>
          {!props.facilityId ? 'Add' : 'Edit'} Facility
        </EditFacilityDiv>
        <StyledSaveButton onClick={onSubmit}>Save</StyledSaveButton>
        {props.facilityId && props.canDelete && (
          <StyledDeleteButton onClick={() => setShowDeleteModal(true)}>
            <Trash />
          </StyledDeleteButton>
        )}
      </Header>
      <FormWrapper>
        <SectionWrapper>
          <StyledHeading>Information</StyledHeading>
          {!props.facilityId && (
            <StyledInputTextField
              id={`facility-editor-slug`}
              key={`facility-editor-slug`}
              label="Slug"
              required
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) =>
                updateField(nameof<FacilityType>('slug'), event.target.value)
              }
              value={facilityData.slug ?? ''}
              tooltipContent="The facility's slug."
            />
          )}
          <FacilityEditorHeader
            facilityData={facilityData}
            updateField={updateField}
            inputStyle={EditorInputTextFieldStyle}
            fieldWhitelist={props.fieldWhitelist}
          />
        </SectionWrapper>
        {(!props.fieldWhitelist ||
          props.fieldWhitelist.includes(
            nameof<FacilityType>('facilityContacts')
          )) && (
          <SectionWrapper>
            <StyledHeading>Contacts</StyledHeading>
            <FacilityEditorContacts
              orgId={props.organization.id}
              facilityData={facilityData}
              updateField={updateField}
              contxtSdk={props.contxtSdk}
            />
          </SectionWrapper>
        )}
        {hasAttributeFields && (
          <SectionWrapper>
            <StyledHeading>Attributes</StyledHeading>
            {simpleFields &&
              facilityData &&
              simpleFields
                .filter(
                  (field) =>
                    !readOnlyFields.includes(field.name) &&
                    !headerFields.includes(field.name)
                )
                .map((field) => {
                  const fieldType = field.type.name || field.type.ofType?.name;
                  switch (fieldType) {
                    case nameof<Scalars>('Boolean'):
                      return (
                        <StyledToggleSwitch
                          key={`facility-editor-${field.name}`}
                          tooltipContent={field.description}
                          label={field.name}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            updateField(field.name, e.target.checked)
                          }
                          checked={facilityData[field.name]}
                        />
                      );
                    case nameof<Scalars>('Datetime'):
                      return (
                        <StyledSingleDatePicker
                          value={
                            facilityData[field.name]
                              ? moment(facilityData[field.name])
                              : null
                          }
                          label={field.name}
                          id={`facility-editor-${field.name}`}
                          key={`facility-editor-${field.name}`}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onDateChange={(date: any) => {
                            updateField(field.name, date.toISOString());
                          }}
                        />
                      );
                    case nameof<Scalars>('Int'):
                      return (
                        <StyledInputTextField
                          id={`facility-editor-${field.name}`}
                          key={`facility-editor-${field.name}`}
                          label={field.name}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(event: any) =>
                            updateField(
                              field.name,
                              parseInt(event.target.value)
                            )
                          }
                          onPaste={(event: React.ClipboardEvent) => {
                            const pasteData =
                              event.clipboardData.getData('Text');
                            const cleansedPaste = pasteData.match('^[0-9]*');
                            updateField(field.name, cleansedPaste);

                            event.preventDefault();
                          }}
                          onKeyDown={(
                            event: KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (event.key === '.') {
                              event.preventDefault();
                            }
                          }}
                          type="number"
                          step={1}
                          value={facilityData[field.name] ?? ''}
                          tooltipContent={field.description}
                        />
                      );
                    case nameof<Scalars>('Float'):
                      return (
                        <StyledInputTextField
                          id={`facility-editor-${field.name}`}
                          key={`facility-editor-${field.name}`}
                          label={field.name}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(event: any) =>
                            updateField(
                              field.name,
                              parseFloat(event.target.value)
                            )
                          }
                          type="number"
                          value={facilityData[field.name] ?? ''}
                          tooltipContent={field.description}
                        />
                      );
                    default:
                      return (
                        <StyledInputTextField
                          id={`facility-editor-${field.name}`}
                          key={`facility-editor-${field.name}`}
                          label={field.name}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(event: any) =>
                            updateField(field.name, event.target.value)
                          }
                          value={facilityData[field.name] ?? ''}
                          tooltipContent={field.description}
                        />
                      );
                  }
                })}
          </SectionWrapper>
        )}
        {hasReadonlyFields && (
          <SectionWrapper>
            <StyledHeading>Internal use</StyledHeading>
            {simpleFields &&
              facilityData &&
              simpleFields
                .filter((field) => readOnlyFields.includes(field.name))
                .map((field) => {
                  const fieldType = field.type.name || field.type.ofType?.name;
                  let value;
                  if (fieldType === nameof<Scalars>('Datetime')) {
                    if (facilityData[field.name]) {
                      value = moment(facilityData[field.name]).toLocaleString();
                    } // else value remains undefined
                  } else {
                    value = facilityData[field.name] ?? '';
                  }

                  return (
                    <StyledInputTextField
                      disabled
                      id={`facility-editor-${field.name}`}
                      key={`facility-editor-${field.name}`}
                      label={field.name}
                      value={value}
                      tooltipContent={field.description}
                    />
                  );
                })}
          </SectionWrapper>
        )}
      </FormWrapper>
      {showDeleteModal && (
        <ConfirmDeleteModal
          objectType="facility"
          objectValue={facilityData.name}
          onDelete={onDeleteConfirmClick}
          onRequestClose={() => setShowDeleteModal(false)}
        />
      )}
    </PageWrapper>
  );
};

export default FacilityEditor;
