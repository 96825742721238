import React from 'react';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@ndustrial/nd-button-react';
import { useHistory } from 'react-router-dom';
import { Search, Close } from '@ndustrial/nd-icons-svg';
import { InputTextField } from '@ndustrial/nd-inputs-react';
import { useRecoilState } from 'recoil';
import { facilitySearchAtom } from './Atom';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderDiv = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`;

const StyledCreateNewButton = styled(PrimaryButton)`
  text-align: center;
  height: auto;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
`;

const sharedIconStyle = css`
  height: 14px;
  width: 14px;
  stroke: ${({ theme }) => theme.colors.primary};
`;

const StyledSearchIcon = styled(Search)`
  ${sharedIconStyle}
`;

const StyledCloseIcon = styled(Close)`
  ${sharedIconStyle}
  :hover {
    cursor: pointer;
  }
`;

const RightSideDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchBox = styled(InputTextField)`
  background-color: white;
  padding: 0;
  width: 300px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  flex: 0 1 auto;
  height: auto;

  input {
    font-size: 16px;
    margin-bottom: 0;
  }

  .nd-inputs-react-inputtext-inputcontainer {
    width: 300px;
  }
`;

export default function FacilityListHeader() {
  const history = useHistory();
  const [searchValue, setSearchValue] = useRecoilState(facilitySearchAtom);
  return (
    <Wrapper>
      <HeaderDiv>Assets</HeaderDiv>
      <RightSideDiv>
        <SearchBox
          id="facility-list-searchbox"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any) => setSearchValue(event.target.value)}
          placeholder="Filter Facilities"
          value={searchValue}
          endIcon={
            searchValue ? (
              <StyledCloseIcon onClick={() => setSearchValue('')} />
            ) : (
              <StyledSearchIcon />
            )
          }
        />
        <StyledCreateNewButton
          onClick={() => {
            history.push(history.location.pathname + '/facility/new');
          }}
        >
          Add Facility
        </StyledCreateNewButton>
      </RightSideDiv>
    </Wrapper>
  );
}
