import { get } from 'lodash';
import { createSelector } from 'reselect';

const getAuth0Profile = (state) => state.user.auth0;
const getRoles = (state) => state.user.userInfo.roles;
const isUserLoading = (state) => state.user.isLoading;
const isOrganizationsLoading = (state) => state.organizations.isLoading;
const isConsentLoading = (state) => state.consent.isLoading;
const isConsentAccepting = (state) => state.consent.isAccepting;
const getCurrentConsentVersion = (state) => state.consent.currentVersion;

const getRoleNames = createSelector([getRoles], (roles) => {
  return roles.map((r) => r.name);
});

const getProfile = createSelector([getAuth0Profile], (auth0Profile) => {
  return {
    profileImage: auth0Profile.picture,
    userName: auth0Profile.name
  };
});

const getSessionLoading = createSelector(
  [isUserLoading, isOrganizationsLoading, isConsentLoading],
  (userLoading, organizationsLoading, consentLoading) => {
    return userLoading || organizationsLoading || consentLoading;
  }
);

const getConsentRequired = createSelector(
  [getCurrentConsentVersion],
  (currentVersion) => {
    return (
      !!currentVersion &&
      get(currentVersion, 'consent.userApproval', []).length === 0
    );
  }
);

const getConsent = createSelector(
  [getCurrentConsentVersion],
  (currentVersion) => {
    return {
      createdAt: currentVersion.createdAt,
      formText: get(currentVersion, 'consent.text'),
      id: currentVersion.consentId,
      subTitle: currentVersion.label,
      title: currentVersion.description
    };
  }
);

export {
  getAuth0Profile,
  getConsent,
  getConsentRequired,
  getRoleNames,
  getProfile,
  getSessionLoading,
  isConsentLoading,
  isConsentAccepting
};
