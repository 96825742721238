import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router';
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  RouteComponentProps
} from 'react-router-dom';
import styled from 'styled-components';
import { ndColorLink } from '@ndustrial/contxt-common';
import { Loader } from './Loader';
import { ndInputBorderColor } from '../constants';

const StyledWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const StyledRouterHeader = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${ndInputBorderColor};
`;

const StyledNavLink = styled(NavLink)`
  padding-bottom: 10px;
  font-size: 1em;
  text-align: left;
  margin-right: 30px;

  color: ${ndColorLink};
  font-weight: 700;
  text-decoration: none;

  &.active {
    border-bottom: 3px solid ${ndColorLink};
  }
`;

const StyledTabContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 30px;
`;

export interface TabRouterPropsData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  label: string;
  slug: string;
}

export interface PassedTabRouterProps {
  className?: string;
  data: TabRouterPropsData[];
  orgSlug?: string;
}

export type TabRouterProps = PassedTabRouterProps & RouteComponentProps;

class TabRouter extends Component<TabRouterProps> {
  renderTabs() {
    const { url } = this.props.match;

    return (
      <StyledRouterHeader className="tab-router__header">
        {this.props.data.map((state) => {
          return (
            <StyledNavLink
              activeClassName="active"
              className="tab-router__tab"
              key={state.slug}
              to={`${url}/${state.slug}`}
            >
              {state.label}
            </StyledNavLink>
          );
        })}
      </StyledRouterHeader>
    );
  }

  renderContent() {
    const { path, url } = this.props.match;

    return (
      <StyledTabContent className="tab-router__content">
        <Switch>
          {this.props.data.map((state) => {
            return (
              <Route
                key={state.slug}
                path={`${path}/${state.slug}`}
                render={() => {
                  return (
                    <Suspense fallback={<Loader />}>
                      <state.component orgSlug={this.props.orgSlug} />
                    </Suspense>
                  );
                }}
              />
            );
          })}
          <Redirect to={`${url}/${this.props.data[0].slug}`} />
        </Switch>
      </StyledTabContent>
    );
  }

  render() {
    return (
      <StyledWrapper className={`${this.props.className} tab-router__wrapper`}>
        {this.renderTabs()}
        {this.renderContent()}
      </StyledWrapper>
    );
  }
}

export { TabRouter };
export default withRouter(TabRouter);
