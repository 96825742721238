import React from 'react';
import { ApolloProviderWrapper } from '@ndustrial/contxt-common';
import AttributeList from './AttributeList';
import AttributeListHeader from './AttributeListHeader';
import { AttributeEditorURLParams } from './AttributeEditorURLParams';
import AttributeEditor from './AttributeEditor';
import AttributeEditorContainer from './AttributeEditorContainer';

export { AttributeListHeader, AttributeEditor, AttributeEditorContainer };

export type { AttributeEditorURLParams };

interface PassedProps {
  orgSlug: string;
}

function AttributeEditorIndex({ orgSlug }: PassedProps) {
  return (
    <ApolloProviderWrapper orgSlug={orgSlug}>
      <AttributeList />
    </ApolloProviderWrapper>
  );
}

export default AttributeEditorIndex;
