import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  GridReadyEvent,
  GridApi,
  CellClickedEvent,
  GetQuickFilterTextParams
} from 'ag-grid-community';

import { nameof } from '@ndustrial/contxt-common/';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import {
  Facility as FacilityType,
  useAllFacilitiesWithAddressQuery
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import { useHistory } from 'react-router-dom';
import { Section, SectionHeaderHeight } from '@ndustrial/contxt-common/src';
import { useRecoilValue } from 'recoil';
import { facilitySearchAtom } from './Atom';
import { deburr } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const GridWrapper = styled.div`
  height: calc(100% - ${SectionHeaderHeight}px);
  width: 100%;

  .ag-root-wrapper {
    border: none;
    border-radius: 5px;
  }

  .ag-row-hover {
    :hover {
      cursor: pointer;
    }
  }
`;

const StyledSection = styled(Section)`
  height: 100%;
`;

const defaultColDef = {
  sortable: true,
  filter: 'agTextColumnFilter',
  resizable: true,
  getQuickFilterText: (params: GetQuickFilterTextParams) => {
    return deburr(params.value?.toString());
  }
};

interface FacilityListProps {
  className?: string;
}

const FacilityList = (props: FacilityListProps) => {
  const { error, data, refetch } = useAllFacilitiesWithAddressQuery();
  const [gridApi, setGridApi] = useState<GridApi>();
  const history = useHistory();
  const searchValue = useRecoilValue(facilitySearchAtom);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    gridApi?.setQuickFilter(deburr(searchValue));
  }, [gridApi, searchValue]);

  if (error) return <div>`Error! ${error.message}`</div>;

  function onGridReady(event: GridReadyEvent) {
    event.api.sizeColumnsToFit();
    setGridApi(event.api);
  }

  return (
    <Wrapper>
      <StyledSection title="Facilities">
        <GridWrapper className="ag-theme-alpine">
          <AgGridReact
            className={props.className}
            rowData={data?.facilities?.nodes}
            animateRows={true}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onCellClicked={(event: CellClickedEvent) =>
              history.push(
                history.location.pathname + `/facility/${event.data.id}`
              )
            }
          >
            <AgGridColumn field={nameof<FacilityType>('name')} sort="asc" />
            <AgGridColumn field={nameof<FacilityType>('address')} />
          </AgGridReact>
        </GridWrapper>
      </StyledSection>
    </Wrapper>
  );
};

export default FacilityList;
