import { gql } from '@apollo/client';

export const GET_FACILITIES = gql`
  query AllFacilties($orderBy: [FacilitiesOrderBy!]) {
    facilities(orderBy: $orderBy) {
      nodes {
        id
        name
      }
    }
  }
`;
