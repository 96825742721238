import {
  compact,
  defaults,
  flatMap,
  groupBy,
  filter,
  set,
  sortBy,
  unionBy
} from 'lodash';
import { createSelector } from 'reselect';

import {
  getRoles,
  getRolesLoading,
  getRoleApplications,
  getRoleEnvironments
} from './roles';

import {
  getUsersPermissions,
  getUsersPermissionsLoading
} from './userPermissions';

const getRawOrganizationUsers = (state) => state.organizationUsers.items;
const getRawOrganizationUsersLoading = (state) =>
  state.organizationUsers.isLoading;
const getLoadingUsers = (state) => state.organizationUsers.loadingItems;
const getOrganizationUsersCategoryFilter = (state) =>
  state.organizationUsers.categoryFilter;
const getOrganizationUsersValueFilter = (state) =>
  state.organizationUsers.valueFilter;

const getAuthType = (user) => {
  switch (true) {
    case user.id.startsWith('auth0'):
      return 'Basic';
    case user.id.startsWith('samlp|lineageokta'):
      return 'Lineage MetricsOne Okta';
    case user.id.startsWith('samlp|ndustrialokta'):
      return 'Ndustrial Okta';
    case user.id.startsWith('samlp|onelineageokta'):
      return 'OneLineage Okta';
    default:
      return 'Other';
  }
};

const getUsersTableRowExpand = (
  orgApplications,
  permissions,
  orgRoles,
  orgProjectEnvironments
) => {
  return {
    applications: getResourceList(
      permissions.applicationsExplicit,
      orgApplications
    ),
    roles: getResourceList(permissions.roles, orgRoles),
    projectEnvironments: getResourceList(
      permissions.projectEnvironmentsExplicit,
      orgProjectEnvironments
    )
  };
};

const getResourceList = (resourceList, availableList) => {
  let resources = [];
  let available = [];
  availableList.forEach((a) => {
    const resource = resourceList.find((r) => r.id === a.id);
    if (resource) {
      resources.push(defaults({}, a, resource));
    } else {
      available.push(defaults({}, a));
    }
  });
  return {
    resources,
    available
  };
};

const normalizePermissions = (permissions, resources) => {
  return compact(
    permissions.map(({ id }) => {
      const resource = resources.find((r) => r.id === id);

      if (!resource) {
        return;
      }

      return {
        id,
        name: resource.name
      };
    })
  );
};

const getDerivedOrganizationUsers = createSelector(
  [
    getRawOrganizationUsers,
    getLoadingUsers,
    getUsersPermissions,
    getRoles,
    getRoleApplications,
    getRoleEnvironments
  ],
  (
    users,
    loadingUsers,
    usersPermissions,
    roles,
    applications,
    projectEnvironments
  ) => {
    return users.map((user) => {
      const isLoading = loadingUsers.indexOf(user.id) > -1;
      const permissions = defaults(
        {},
        usersPermissions.find((p) => p.userId === user.id),
        {
          projectEnvironmentsExplicit: [],
          projectEnvironmentsImplicit: [],
          applicationsExplicit: [],
          applicationsImplicit: [],
          roles: []
        }
      );

      return {
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        authType: getAuthType(user),
        isLoading,
        organizations: user.organizations,
        permissions,
        projectEnvironments: [
          ...normalizePermissions(
            unionBy(
              permissions.projectEnvironmentsExplicit,
              permissions.projectEnvironmentsImplicit,
              'id'
            ),
            projectEnvironments
          )
        ],
        applications: [
          ...normalizePermissions(
            unionBy(
              permissions.applicationsExplicit,
              permissions.applicationsImplicit,
              'id'
            ),
            applications
          )
        ],
        roles: normalizePermissions(permissions.roles, roles),
        expandedRow: getUsersTableRowExpand(
          applications,
          permissions,
          roles,
          projectEnvironments
        )
      };
    });
  }
);

const getFilteredUsers = createSelector(
  [getDerivedOrganizationUsers, getOrganizationUsersValueFilter],
  (users, valueFilter) => {
    if (!valueFilter.trim()) return users;

    return users.filter((user) => {
      return (
        user.fullName.toLowerCase().indexOf(valueFilter.trim().toLowerCase()) >
          -1 ||
        user.email.toLowerCase().indexOf(valueFilter.trim().toLowerCase()) > -1
      );
    });
  }
);

const getOrganizationUsers = createSelector(
  [getFilteredUsers, getOrganizationUsersCategoryFilter],
  (users, categoryFilter) => {
    if (!categoryFilter.key) return users;

    const propertyMatcher = set({}, categoryFilter.key, categoryFilter.value);

    return filter(users, propertyMatcher);
  }
);

const getUserAccessFilters = createSelector(
  [getDerivedOrganizationUsers],
  (users) => {
    const authTypeGroups = groupBy(users, 'authType');
    const authTypeFilters = [];

    Object.entries(authTypeGroups).forEach(([value, group]) => {
      authTypeFilters.push({
        key: 'authType',
        value,
        label: value,
        count: group.length
      });
    });

    const roles = flatMap(users, (user) =>
      user.roles.map((role) => {
        return { id: role.id, label: role.name };
      })
    );
    const rolesGroup = groupBy(roles, 'id');
    const roleFilters = [];

    Object.entries(rolesGroup).forEach(([value, group]) => {
      roleFilters.push({
        key: 'roles[0].id',
        value,
        label: group[0].label,
        count: group.length
      });
    });

    const applications = flatMap(users, (user) =>
      user.applications.map((app) => {
        return { id: app.id, label: app.name };
      })
    );

    const applicationsGroup = groupBy(applications, 'id');
    const applicationFilters = [];

    Object.entries(applicationsGroup).forEach(([value, group]) => {
      applicationFilters.push({
        key: 'applications[0].id',
        value: Number(value),
        label: group[0].label,
        count: group.length
      });
    });

    const projectEnvironments = flatMap(users, (user) =>
      user.projectEnvironments.map((projectEnvironment) => {
        return { id: projectEnvironment.id, label: projectEnvironment.name };
      })
    );

    const projectEnvironmentsGroup = groupBy(projectEnvironments, 'id');
    const projectEnvironmentFilters = [];

    Object.entries(projectEnvironmentsGroup).forEach(([value, group]) => {
      projectEnvironmentFilters.push({
        key: 'projectEnvironments[0].id',
        value: value,
        label: group[0].label,
        count: group.length
      });
    });

    return [
      {
        key: 'all',
        filters: [
          {
            key: '',
            value: '',
            label: 'All Users',
            count: users.length
          }
        ]
      },
      {
        label: 'Auth Access',
        key: 'authTypes',
        filters: sortBy(authTypeFilters, 'label')
      },
      {
        label: 'Role Access',
        key: 'roles',
        filters: sortBy(roleFilters, 'label')
      },
      {
        label: 'Application Access',
        key: 'applications',
        filters: sortBy(applicationFilters, 'label')
      },
      {
        label: 'Project Environment Access',
        key: 'projectEnvironments',
        filters: sortBy(projectEnvironmentFilters, 'label')
      }
    ];
  }
);

const getUsersLoading = createSelector(
  [getRawOrganizationUsersLoading, getUsersPermissionsLoading, getRolesLoading],
  (usersLoading, permissionsLoading, rolesLoading) => {
    return usersLoading || permissionsLoading || rolesLoading;
  }
);

export {
  getDerivedOrganizationUsers,
  getFilteredUsers,
  getOrganizationUsers,
  getOrganizationUsersCategoryFilter,
  getOrganizationUsersValueFilter,
  getUserAccessFilters,
  getUsersLoading
};
