import React from 'react';
import styled from 'styled-components';
import { SectionBorderColor } from './Section';

export const SectionFooterHeight = 60;

const Wrapper = styled.div`
  display: flex;
  height: ${SectionFooterHeight}px;
  background-color: #ededed;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid ${SectionBorderColor};
`;

export interface SectionFooterProps {
  className?: string;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

export function SectionFooter(props: SectionFooterProps) {
  return <Wrapper className={props.className}>{props.children}</Wrapper>;
}
