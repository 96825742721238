import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Chip, ChipGroup, ChipIcon, ChipLabel } from '@ndustrial/nd-chip-react';
import { Close } from '@ndustrial/nd-icons-svg';
import ResourceSelect from './ResourceSelect';

const ResourceListWrapper = styled.div`
  flex: 1;
  padding-right: 10px;
`;

const ResourceListLabel = styled.h4`
  display: flex;
  align-items: center;
  font-size: 0.85em;
`;

const ResourceListMessage = styled.p`
  font-style: italic;
  font-size: 0.625rem;
`;

const StyledChipgroup = styled(ChipGroup)`
  font-size: 0.625rem;
`;

class ResourceList extends Component {
  static propTypes = {
    children: PropTypes.func,
    label: PropTypes.string.isRequired,
    associateById: PropTypes.bool.isRequired,
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    available: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    readOnly: PropTypes.bool.isRequired,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    readOnly: true,
    associateById: false
  };

  render() {
    let content;
    if (!this.props.resources.length) {
      content = (
        <ResourceListMessage>No {this.props.label} Found</ResourceListMessage>
      );
    } else {
      content = (
        <StyledChipgroup>
          {this.props.resources.map((resource) => {
            if (this.props.children) {
              return this.props.children(resource);
            }

            return (
              <Chip key={resource.id}>
                {!this.props.readOnly && (
                  <ChipIcon
                    onClick={() => {
                      this.props.onRemove(resource.id);
                    }}
                  >
                    <Close />
                  </ChipIcon>
                )}
                <ChipLabel>{resource.name}</ChipLabel>
              </Chip>
            );
          })}
        </StyledChipgroup>
      );
    }
    return (
      <ResourceListWrapper>
        <ResourceListLabel>
          {this.props.label}{' '}
          {!this.props.readOnly && (
            <ResourceSelect
              associateById={this.props.associateById}
              items={this.props.available}
              onSelect={this.props.onAdd}
            />
          )}
        </ResourceListLabel>
        {content}
      </ResourceListWrapper>
    );
  }
}

export default ResourceList;
