import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import styled from 'styled-components';

const Input = styled.input`
  margin: 0;
  max-width: 300px;
`;

class DebounceTextInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: '',
    placeholder: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  componentWillUnmount() {
    this.handleDebounceChange.cancel();
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value
    });

    this.handleDebounceChange(event.target.value);
  };

  handleDebounceChange = debounce((value) => {
    this.props.onChange(value);
  }, 250);

  render() {
    const { placeholder, ...rest } = this.props;
    const { value } = this.state;

    return (
      <Input
        {...rest}
        onChange={this.handleChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />
    );
  }
}

export default DebounceTextInput;
