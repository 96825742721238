import {
  ApolloProviderWrapper,
  nameof,
  Organization
} from '@ndustrial/contxt-common/src';
import {
  FacilityEditorContainer,
  FacilityListHeader
} from '@ndustrial/facility-editor/src/';
import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ApplicationModule } from '../../../types/sdk';
import PackageEntryPoint from '../components/PackageEntryPoint';
import { ASSET_MANAGER_BASE_ROUTE } from '../../src/routes/Routes';
import {
  AttributeEditorContainer,
  AttributeEditorURLParams,
  AttributeListHeader
} from '@ndustrial/attribute-editor/src';
import { FacilityEditorURLParams } from '@ndustrial/facility-editor/src/common/FacilityEditorURLParams';
import { FacilityGroupingHeader } from '@ndustrial/hierarchy-editor/src';
import { contxtSdk } from '@ndustrial/contxt-common';

const FACILITY_EDITOR_PATH = 'facility-editor';
const ATTRIBUTE_EDITOR_PATH = 'attribute-editor';
const FACILITY_GROUPING_PATH = 'facility-groups';

const customApplicationModules: Record<string, ApplicationModule> = {
  '@ndustrial/attribute-editor': {
    Component: lazy(() => import('@ndustrial/attribute-editor')),
    path: ATTRIBUTE_EDITOR_PATH,
    label: 'Attributes',
    slug: '@ndustrial/attribute-editor'
  },
  '@ndustrial/facility-editor': {
    Component: lazy(() =>
      import('@ndustrial/facility-editor').then((modules) => {
        return { default: modules.FacilityList };
      })
    ),
    path: FACILITY_EDITOR_PATH,
    label: 'Facilities',
    slug: '@ndustrial/facility-editor'
  },
  '@ndustrial/hierarchy-editor': {
    Component: lazy(() => import('@ndustrial/hierarchy-editor')),
    path: 'facility-groups',
    slug: '@ndustrial/hierarchy-editor',
    label: 'Groupings'
  }
};

export const facilityEditorBasePath = `/organizations/:${nameof<FacilityEditorURLParams>(
  'orgSlug'
)}/asset-manager/${FACILITY_EDITOR_PATH}`;
export const facilityEditPath = `${facilityEditorBasePath}/facility/:${nameof<FacilityEditorURLParams>(
  'facilityId'
)}`;

export const attributeEditorBasePath = `/organizations/:${nameof<AttributeEditorURLParams>(
  'orgSlug'
)}/asset-manager/${ATTRIBUTE_EDITOR_PATH}`;
export const attributeEditPath = `${attributeEditorBasePath}/attribute/:${nameof<AttributeEditorURLParams>(
  'name'
)}`;

const AssetManager = () => {
  const selectedOrganization = useSelector(
    (state: { organizations: { selectedOrganization: Organization } }) =>
      state.organizations.selectedOrganization
  );
  const facilityEditorMatch = useRouteMatch(
    `${ASSET_MANAGER_BASE_ROUTE}/${FACILITY_EDITOR_PATH}`
  );
  const attributeEditorMatch = useRouteMatch(
    `${ASSET_MANAGER_BASE_ROUTE}/${ATTRIBUTE_EDITOR_PATH}`
  );
  const facilityGroupingMatch = useRouteMatch(
    `${ASSET_MANAGER_BASE_ROUTE}/${FACILITY_GROUPING_PATH}`
  );

  const headerComponent = useMemo(() => {
    if (facilityEditorMatch?.isExact) {
      return FacilityListHeader;
    } else if (attributeEditorMatch?.isExact) {
      return AttributeListHeader;
    } else if (facilityGroupingMatch?.isExact) {
      return FacilityGroupingHeader;
    } else {
      return undefined;
    }
  }, [facilityEditorMatch, attributeEditorMatch, facilityGroupingMatch]);

  return (
    <ApolloProviderWrapper orgSlug={selectedOrganization.slug}>
      <Switch>
        <Route exact path={facilityEditPath}>
          <FacilityEditorContainer contxtSdk={contxtSdk} />
        </Route>
        <Route exact path={attributeEditPath}>
          <AttributeEditorContainer />
        </Route>
        <Route>
          <PackageEntryPoint
            label="Assets"
            customApplicationModules={customApplicationModules}
            CustomHeader={headerComponent}
          />
        </Route>
      </Switch>
    </ApolloProviderWrapper>
  );
};

export default AssetManager;
