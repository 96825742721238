import { omit } from 'lodash';
import { combineReducers } from 'redux';

import actionTypes from '../constants/actionTypes';

const INITIAL_STATE = {
  byId: {},
  error: null,
  isLoading: true,
  itemOrder: [],
  loadingItems: [],
  newRoleForm: {
    isLoading: false,
    error: null
  },
  valueFilter: ''
};

function byId(state = INITIAL_STATE.byId, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION_ROLES_SUCCESS: {
      const items = action.payload;
      const byId = {};

      items.forEach((item) => {
        byId[item.id] = item;
      });

      return byId;
    }
    case actionTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    case actionTypes.REMOVE_ROLE_SUCCESS:
      return {
        ...omit(state, [action.payload])
      };
    case actionTypes.ADD_ROLE_APPLICATION_SUCCESS: {
      const role = state[action.payload.roleId];
      return {
        ...state,
        [action.payload.roleId]: {
          ...role,
          applications: [...role.applications, action.payload.application]
        }
      };
    }
    case actionTypes.ADD_ROLE_PROJECT_ENVIRONMENT_SUCCESS: {
      const role = state[action.payload.roleId];
      return {
        ...state,
        [action.payload.roleId]: {
          ...role,
          projectEnvironments: [
            ...role.projectEnvironments,
            action.payload.projectEnvironment
          ]
        }
      };
    }
    case actionTypes.REMOVE_ROLE_APPLICATION_SUCCESS: {
      const role = state[action.payload.roleId];
      role.applications = role.applications.filter(
        (a) => a.id !== action.payload.applicationId
      );
      return {
        ...state,
        [action.payload.roleId]: { ...role }
      };
    }
    case actionTypes.REMOVE_ROLE_PROJECT_ENVIRONMENT_SUCCESS: {
      const role = state[action.payload.roleId];
      role.projectEnvironments = role.projectEnvironments.filter(
        (s) => s.id !== action.payload.projectEnvironmentId
      );
      return {
        ...state,
        [action.payload.roleId]: { ...role }
      };
    }
    default:
      return state;
  }
}

function error(state = INITIAL_STATE.error, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION_ROLES_START:
    case actionTypes.REMOVE_ROLE_LOADING_START:
      return INITIAL_STATE.error;
    case actionTypes.LOAD_ORGANIZATION_ROLES_FAILURE:
    case actionTypes.REMOVE_ROLE_FAILURE:
    case actionTypes.ADD_ROLE_APPLICATION_FAILURE:
    case actionTypes.ADD_ROLE_PROJECT_ENVIRONMENT_FAILURE:
    case actionTypes.REMOVE_ROLE_APPLICATION_FAILURE:
    case actionTypes.REMOVE_ROLE_PROJECT_ENVIRONMENT_FAILURE:
      return action.payload;
    default:
      return state;
  }
}

function isLoading(state = INITIAL_STATE.isLoading, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION_ROLES_START:
      return true;
    case actionTypes.LOAD_ORGANIZATION_ROLES_SUCCESS:
    case actionTypes.LOAD_ORGANIZATION_ROLES_FAILURE:
    case actionTypes.CREATE_ROLE_FAILURE:
      return false;
    default:
      return state;
  }
}

function itemOrder(state = INITIAL_STATE.itemOrder, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION_ROLES_SUCCESS:
      return action.payload.map((r) => r.id);
    case actionTypes.CREATE_ROLE_SUCCESS:
      return [...state, action.payload.id];
    case actionTypes.REMOVE_ROLE_SUCCESS:
      return state.filter((x) => x !== action.payload);
    default:
      return state;
  }
}

function loadingItems(state = INITIAL_STATE.loadingItems, action) {
  switch (action.type) {
    case actionTypes.REMOVE_ROLE_LOADING_START:
      return [...state, action.payload];
    case actionTypes.REMOVE_ROLE_LOADING_STOP:
      return state.filter((x) => x !== action.payload);
    default:
      return state;
  }
}

function newRoleForm(state = INITIAL_STATE.newRoleForm, action) {
  switch (action.type) {
    case actionTypes.CREATE_ROLE_START:
      return {
        isLoading: true,
        error: null
      };
    case actionTypes.CREATE_ROLE_SUCCESS:
      return INITIAL_STATE.newRoleForm;
    case actionTypes.CREATE_ROLE_FAILURE:
      return {
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

function valueFilter(state = INITIAL_STATE.valueFilter, action) {
  switch (action.type) {
    case actionTypes.FILTER_ROLES_BY_VALUE:
      return action.payload;
    default:
      return state;
  }
}

const roles = combineReducers({
  byId,
  error,
  isLoading,
  itemOrder,
  loadingItems,
  newRoleForm,
  valueFilter
});

export default roles;
