import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '@ndustrial/nd-button-react';

import { Modal } from '@ndustrial/contxt-common';

import Form from './Form';
import { useTheme } from 'styled-components';

const propTypes = {
  className: PropTypes.string,
  newUserForm: PropTypes.object.isRequired,
  onInviteNewUser: PropTypes.func.isRequired,
  theme: PropTypes.object
};

function NewUserModal(props) {
  const [isModalShown, setIsModalShown] = useState(false);
  const theme = useTheme();

  const openModal = () => {
    setIsModalShown(true);
  };

  const closeModal = () => {
    setIsModalShown(false);
  };

  const handleSubmit = (newUser) => {
    props.onInviteNewUser(newUser).then(() => {
      closeModal();
    });
  };

  return (
    <div className={props.className}>
      <Modal
        title="Add User"
        isOpen={isModalShown}
        onRequestClose={closeModal}
        customStyles={{
          height: 300,
          width: 800,
          padding: 0,
          borderRadius: 0,
          border: 'none'
        }}
        customHeaderStyles={{
          backgroundColor: theme.primary,
          padding: '10px',
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: 'none'
        }}
      >
        <Form onSubmit={handleSubmit} formState={props.newUserForm} />
      </Modal>
      <PrimaryButton className="invite-new-user-button" onClick={openModal}>
        Add User
      </PrimaryButton>
    </div>
  );
}

NewUserModal.propTypes = propTypes;

export default NewUserModal;
