import React, { lazy } from 'react';

import { ApplicationModule } from '../../../types/sdk';
import PackageEntryPoint from '../components/PackageEntryPoint';

const customApplicationModules: Record<string, ApplicationModule> = {
  '@ndustrial/data-explorer-viewer': {
    Component: lazy(() => import('@ndustrial/data-explorer-viewer')),
    path: 'data-explorer-viewer',
    label: 'Viewer',
    slug: '@ndustrial/data-explorer-viewer'
  },
  '@ndustrial/data-explorer-editor': {
    Component: lazy(() => import('@ndustrial/data-explorer-editor')),
    path: 'fdata-explorer-editor',
    label: 'Editor',
    slug: '@ndustrial/data-explorer-editor'
  }
};

const DataExplorer = () => {
  return (
    <PackageEntryPoint
      label="Data"
      customApplicationModules={customApplicationModules}
    />
  );
};

export default DataExplorer;
