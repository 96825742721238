import styled from 'styled-components';

const Label = styled.label``;

const LabelSpan = styled.span`
  font-size: 0.8rem;
  font-weight: 400;
  color: #757575;
`;

export { Label, LabelSpan };
