import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Loader, Modal } from '@ndustrial/contxt-common';
import styled, { useTheme } from 'styled-components';
import { ndColorCritical } from '@ndustrial/contxt-common';
import { Trash } from '@ndustrial/nd-icons-svg';
import { PrimaryButton, SecondaryButton } from '@ndustrial/nd-button-react';

const StyledTrashIcon = styled(Trash)`
  cursor: pointer;
  stroke: ${ndColorCritical};
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  display: flex;
`;

const ModalButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  width: 150px;
  margin-right: 15px;
`;
const StyledPrimaryButton = styled(PrimaryButton)`
  width: 150px;
  margin-right: 15px;
`;

const ModalContent = styled.h3`
  margin-left: 10px;
`;

const propTypes = {
  cell: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isLoading: PropTypes.bool
    }).isRequired
  }).isRequired,
  onRemoveRole: PropTypes.func.isRequired
};

function ActionsCell(props) {
  const [isModalShown, setIsModalShown] = useState(false);
  const theme = useTheme();

  const openConfirmationDialog = () => {
    setIsModalShown(true);
  };

  const closeConfirmationDialog = () => {
    setIsModalShown(false);
  };

  const handleConfirmation = () => {
    props.onRemoveRole(props.cell.original.id);
    closeConfirmationDialog();
  };

  if (props.cell.original.isLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Modal
        title="Confirm Delete"
        isOpen={isModalShown}
        onRequestClose={closeConfirmationDialog}
        customStyles={{
          height: 165,
          width: 800,
          padding: 0,
          borderRadius: 0,
          border: 'none'
        }}
        customHeaderStyles={{
          backgroundColor: theme.primary,
          padding: '10px',
          color: 'white',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: 'none'
        }}
      >
        <ModalContent>
          Are you sure that you want to delete {props.cell.original.name}?
        </ModalContent>
        <ModalButtonWrapper>
          <StyledSecondaryButton
            type="button"
            onClick={closeConfirmationDialog}
          >
            Cancel
          </StyledSecondaryButton>
          <StyledPrimaryButton type="button" onClick={handleConfirmation}>
            Delete
          </StyledPrimaryButton>
        </ModalButtonWrapper>
      </Modal>
      <StyledTrashIcon onClick={openConfirmationDialog} />
    </Wrapper>
  );
}

ActionsCell.propTypes = propTypes;

export default ActionsCell;
