import React, { ChangeEvent, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@ndustrial/nd-button-react';
import { Search, Close } from '@ndustrial/nd-icons-svg';
import { InputTextField } from '@ndustrial/nd-inputs-react';
import { useRecoilState } from 'recoil';
import { facilityGroupingSearchAtom } from './Atoms';
import useOnAddGroup from './common/hooks/useOnAddGroup';
import AddGroupInputModal from './features/groupings/AddGroupInputModal';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderDiv = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
`;

const StyledAddGroupButton = styled(PrimaryButton)`
  text-align: center;
  height: auto;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
`;

const sharedIconStyle = css`
  height: 14px;
  width: 14px;
  stroke: ${({ theme }) => theme.colors.primary};
`;

const StyledSearchIcon = styled(Search)`
  ${sharedIconStyle}
`;

const StyledCloseIcon = styled(Close)`
  ${sharedIconStyle}
  :hover {
    cursor: pointer;
  }
`;

const RightSideDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchBox = styled(InputTextField)`
  background-color: white;
  padding: 0;
  width: 300px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  flex: 0 1 auto;
  height: auto;

  input {
    font-size: 16px;
    margin-bottom: 0;
  }

  .nd-inputs-react-inputtext-inputcontainer {
    width: 300px;
  }
`;

export default function FacilityGroupingHeader() {
  const [searchValue, setSearchValue] = useRecoilState(
    facilityGroupingSearchAtom
  );
  const [isOpen, setIsOpen] = useState(false);
  const addGroup = useOnAddGroup();
  const onAddGroup = useCallback(
    ({ name }: { name: string }) => {
      addGroup({ name }).then(() => setIsOpen(false));
    },
    [addGroup]
  );
  return (
    <Wrapper>
      <HeaderDiv>Assets</HeaderDiv>
      <RightSideDiv>
        <SearchBox
          id="facility-grouping-searchbox"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearchValue(event.target.value)
          }
          placeholder="Filter Facilities"
          value={searchValue}
          endIcon={
            searchValue ? (
              <StyledCloseIcon onClick={() => setSearchValue('')} />
            ) : (
              <StyledSearchIcon />
            )
          }
        />
        <StyledAddGroupButton
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Add Group
        </StyledAddGroupButton>
      </RightSideDiv>
      {isOpen && (
        <AddGroupInputModal
          onRequestClose={() => setIsOpen(false)}
          onAddGroup={onAddGroup}
          isOpen={isOpen}
        />
      )}
    </Wrapper>
  );
}
