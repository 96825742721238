import React from 'react';
import { useRecoilState } from 'recoil';
import { gql, useQuery } from '@apollo/client';

import { Facility } from '../graphql/graphql.generated';
import { ReactSelect, recoil } from '..';
import { GroupedOption, Option } from '../types/types';

const GET_FACILITIES = gql`
  query AllFacilties($orderBy: [FacilitiesOrderBy!]) {
    facilities(orderBy: $orderBy) {
      nodes {
        id
        name
      }
    }
  }
`;

const formatOption = (facility: Facility) => {
  return {
    value: facility,
    label: facility.name
  };
};

export const FacilitiesSelect = ({
  onChange
}: {
  onChange?: (selectedOptions: readonly Option<Facility>[]) => void;
}) => {
  const [selectedFacilities, setSelectedFacilities] = useRecoilState(
    recoil.selectedFacilitiesAtom
  );
  const defaultOnChange = (selectedOptions: readonly Option<Facility>[]) => {
    setSelectedFacilities(selectedOptions.map(({ value }) => value));
  };

  const { loading, data } = useQuery(GET_FACILITIES, {
    variables: { orderBy: 'SLUG_ASC' }
  });
  const facilityOptions: GroupedOption<Facility>[] =
    data?.facilities?.nodes?.map((facility: Facility) =>
      formatOption(facility)
    );

  const value = selectedFacilities.map((facility) => formatOption(facility));

  return (
    <ReactSelect<Facility>
      placeholder="Facilities"
      name="Facilities Select"
      options={facilityOptions}
      onChange={onChange ? onChange : defaultOnChange}
      isMulti={true}
      isLoading={loading}
      value={value}
    />
  );
};
