import React, { MutableRefObject } from 'react';
import { ArrowLeftUp, Close, Trash } from '@ndustrial/nd-icons-svg';
import styled from 'styled-components';
import StyledOptionWidget from './StyledOptionWidget';
import useOuterClick from './hooks/useOuterClick';

export interface IsTrashVisible {
  isTrashVisible: boolean;
}

const TrashActionIcon = styled.span`
  cursor: pointer;
  position: relative;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  font-size: 1.25rem;
  stroke: gray;

  &:hover {
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;

const TrashActionWrapper = styled.span`
  position: relative;
  top: -1rem;
  right: -1rem;
`;

const TrashActionMenu = styled(StyledOptionWidget)<IsTrashVisible>`
  background-color: white;
  width: 375px;
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 5px;
  cursor: auto;
  display: ${({ isTrashVisible }) => (isTrashVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 0.65rem;
  padding: 1rem 3rem 2rem 1rem;
`;

const TrashActionMenuOption = styled.a`
  font-weight: 400;
  color: #505050;
  transition: all 0.1s ease-in;
  text-decoration-color: transparent;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  &:hover {
    ${({ theme }) => `
      text-decoration-color: ${theme.colors.primary};
      color: black;
    `}
  }
`;

interface TrashActionProps {
  onRemoveFromGroup?: undefined | (() => void);
  onDelete?: () => void;
  removeText?: string;
  deleteText?: string;
  isOpen: boolean;
  onToggleOpen: (isOpen: boolean) => void;
}

const defaultProps: Partial<TrashActionProps> = {
  removeText: 'remove from group (becomes top level group)',
  deleteText: 'permanently delete from groups'
};

const TrashAction = ({
  onDelete,
  onRemoveFromGroup,
  isOpen,
  onToggleOpen,
  removeText,
  deleteText
}: TrashActionProps) => {
  const outerClickRef = useOuterClick(
    () => isOpen && onToggleOpen(false)
  ) as MutableRefObject<HTMLDivElement>;

  return (
    <TrashActionWrapper>
      <TrashActionMenu ref={outerClickRef} isTrashVisible={isOpen}>
        {onDelete && (
          <TrashActionMenuOption onClick={() => onDelete()}>
            <Trash />
            {deleteText ?? defaultProps.deleteText}
          </TrashActionMenuOption>
        )}
        {onRemoveFromGroup && (
          <TrashActionMenuOption onClick={() => onRemoveFromGroup()}>
            <ArrowLeftUp />
            {removeText ?? defaultProps.removeText}
          </TrashActionMenuOption>
        )}
      </TrashActionMenu>
      {isOpen ? (
        <TrashActionIcon as={Close} onClick={() => onToggleOpen(false)} />
      ) : (
        <TrashActionIcon as={Trash} onClick={() => onToggleOpen(true)} />
      )}
    </TrashActionWrapper>
  );
};

export default TrashAction;
