import React from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import FacilitiesContainer from './features/facilities/FacilitiesContainer';
import GroupingsContainer from './features/groupings/GroupingsContainer';

const ContentWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const HierarchyEditorContainer = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ContentWrapper>
        <FacilitiesContainer />
        <GroupingsContainer />
      </ContentWrapper>
    </DndProvider>
  );
};

export default HierarchyEditorContainer;
