import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { Close } from '@ndustrial/nd-icons-svg';
import { ChipIcon } from '@ndustrial/nd-chip-react';
import {
  ProjectEnvironmentChip,
  ProjectEnvironmentChipLabel
} from './ProjectEnvironmentChip';

import * as organizationUsersActionCreators from '../../actions/organizationUsers';
import * as usersPermissionsActionCreators from '../../actions/usersPermissions';
import ResourceList from '../ResourceList';
import ResourceSelect from '../ResourceSelect';
import RolesSubGroup from './RolesSubGroup';
import { ToggleButton, ToggleButtonGroup } from '../ToggleButton';

const ExpandedUserWrapper = styled.div`
  padding: 10px;
`;

const ResourceGroupWrapper = styled.div`
  padding-bottom: 30px;
`;
const ResourceGroup = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid #e6e6e6;
`;
const ResourceGroupLabel = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 100;
`;

class ConnectedExpandedUser extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      usersPermissions: PropTypes.shape({
        loadUserPermissions: PropTypes.func.isRequired
      }),
      organizationUsers: PropTypes.shape({
        addApplication: PropTypes.func.isRequired,
        addProjectEnvironment: PropTypes.func.isRequired,
        addRole: PropTypes.func.isRequired,
        removeApplication: PropTypes.func.isRequired,
        removeRole: PropTypes.func.isRequired,
        removeProjectEnvironment: PropTypes.func.isRequired
      })
    }),
    applications: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    }),
    id: PropTypes.string.isRequired,
    projectEnvironments: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          accessType: PropTypes.oneOf(['reader', 'admin']).isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired
        })
      )
    }),
    roles: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ),
      available: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      )
    }),
    selectedOrganization: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  };

  handleAddApplication = (applicationId) => {
    return this.props.actions.organizationUsers.addApplication(
      this.props.id,
      applicationId
    );
  };

  handleRemoveApplication = (applicationId) => {
    return this.props.actions.organizationUsers.removeApplication(
      this.props.id,
      applicationId
    );
  };

  handleAddProjectEnvironment = (
    projectEnvironmentId,
    accessType = 'reader'
  ) => {
    return this.props.actions.organizationUsers.addProjectEnvironment(
      this.props.id,
      {
        ...this.props.projectEnvironments.available.find(
          (s) => s.id === projectEnvironmentId
        )
      },
      accessType
    );
  };

  handleRemoveProjectEnvironment = (projectEnvironmentId) => {
    return this.props.actions.organizationUsers.removeProjectEnvironment(
      this.props.id,
      projectEnvironmentId
    );
  };

  handleProjectEnvironmentAccessTypeChange = (
    projectEnvironmentId,
    accessType
  ) => {
    return this.handleRemoveProjectEnvironment(projectEnvironmentId).then(
      () => {
        return this.handleAddProjectEnvironment(
          projectEnvironmentId,
          accessType
        );
      }
    );
  };

  handleAddRole = (roleId) => {
    return this.props.actions.organizationUsers
      .addRole(this.props.id, roleId)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          this.props.id
        );
      });
  };

  handleRemoveRole = (roleId) => {
    return this.props.actions.organizationUsers
      .removeRole(this.props.id, roleId)
      .then(() => {
        this.props.actions.usersPermissions.loadUserPermissions(
          this.props.selectedOrganization.id,
          this.props.id
        );
      });
  };

  render() {
    return (
      <ExpandedUserWrapper>
        <ResourceGroupWrapper>
          <ResourceGroupLabel>Direct Access</ResourceGroupLabel>
          <ResourceGroup>
            <ResourceList
              label="Applications"
              resources={this.props.applications.resources}
              available={this.props.applications.available}
              readOnly={false}
              onRemove={this.handleRemoveApplication}
              onAdd={this.handleAddApplication}
            />
            <ResourceList
              label="Project Environments"
              resources={this.props.projectEnvironments.resources}
              available={this.props.projectEnvironments.available}
              associateById={true}
              readOnly={false}
              onRemove={this.handleRemoveProjectEnvironment}
              onAdd={this.handleAddProjectEnvironment}
            >
              {(resource) => (
                <ProjectEnvironmentChip key={resource.id}>
                  <ChipIcon
                    onClick={() => {
                      this.handleRemoveProjectEnvironment(resource.id);
                    }}
                  >
                    <Close />
                  </ChipIcon>
                  <ProjectEnvironmentChipLabel>
                    {resource.name}
                  </ProjectEnvironmentChipLabel>
                  <ToggleButtonGroup
                    value={resource.accessType}
                    onChange={(value) =>
                      this.handleProjectEnvironmentAccessTypeChange(
                        resource.id,
                        value
                      )
                    }
                  >
                    <ToggleButton value="reader">Reader</ToggleButton>
                    <ToggleButton value="admin">Admin</ToggleButton>
                  </ToggleButtonGroup>
                </ProjectEnvironmentChip>
              )}
            </ResourceList>
          </ResourceGroup>
        </ResourceGroupWrapper>
        <ResourceGroupWrapper>
          <ResourceGroupLabel>
            Role Access
            <ResourceSelect
              items={this.props.roles.available}
              onSelect={this.handleAddRole}
            />
          </ResourceGroupLabel>
          {this.props.roles.resources.map((role) => {
            return (
              <RolesSubGroup
                key={role.id}
                applications={this.props.applications}
                onRemove={this.handleRemoveRole}
                role={role}
                projectEnvironments={this.props.projectEnvironments}
              />
            );
          })}
        </ResourceGroupWrapper>
      </ExpandedUserWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOrganization: state.organizations.selectedOrganization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      organizationUsers: bindActionCreators(
        organizationUsersActionCreators,
        dispatch
      ),
      usersPermissions: bindActionCreators(
        usersPermissionsActionCreators,
        dispatch
      )
    }
  };
}

export { ConnectedExpandedUser as ExpandedUser };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedExpandedUser);
