import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';

import * as consentActionCreators from '../actions/consent';
import * as organizationsActionCreators from '../actions/organizations';
import * as userActionCreators from '../actions/user';
import * as applicationGroupingActionCreators from '../actions/applicationGroupings';
import { getConsentRequired, getSessionLoading } from '../selectors/session';
import getTheme from '../utils/theme';
import LoadingPage from '../components/LoadingPage';

const config = window.nd;

class ConnectedSessionContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      consent: PropTypes.shape({
        loadConsent: PropTypes.func.isRequired
      }),
      organizations: PropTypes.shape({
        loadOrganizations: PropTypes.func.isRequired
      }),
      user: PropTypes.shape({
        loadUser: PropTypes.func.isRequired
      }),
      applicationGroupings: PropTypes.shape({
        loadGroupings: PropTypes.func.isRequired
      })
    }),
    history: PropTypes.object.isRequired,
    isConsentRequired: PropTypes.bool.isRequired,
    isSessionLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    selectedOrganization: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      theme: getTheme(this.props.selectedOrganization.slug)
    };
  }

  componentDidMount() {
    this.props.actions.consent.loadConsent();
    this.props.actions.organizations.loadOrganizations();
    this.props.actions.user.loadUser();
  }

  componentDidUpdate(prevProps) {
    const slug = get(this.props, 'selectedOrganization.slug');
    const prevSlug = get(prevProps, 'selectedOrganization.slug');

    if (prevSlug !== slug) {
      const theme = getTheme(slug);
      this.setState({ theme });
      this.props.actions.applicationGroupings.loadGroupings(
        config.applicationId
      );
    }

    if (
      !this.props.history.location.pathname.startsWith('/settings') &&
      this.props.isConsentRequired
    ) {
      this.props.history.replace(`/settings`);
    }
  }

  getContent() {
    if (this.props.isSessionLoading) {
      return <LoadingPage label="LOADING" />;
    } else {
      return this.props.children;
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        <div className="nd-container">
          {this.getContent()}
          <NotificationContainer />
        </div>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    isSessionLoading: getSessionLoading(state),
    selectedOrganization: state.organizations.selectedOrganization,
    isConsentRequired: getConsentRequired(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      consent: bindActionCreators(consentActionCreators, dispatch),
      organizations: bindActionCreators(organizationsActionCreators, dispatch),
      user: bindActionCreators(userActionCreators, dispatch),
      applicationGroupings: bindActionCreators(
        applicationGroupingActionCreators,
        dispatch
      )
    }
  };
}

export { ConnectedSessionContainer as SessionContainer };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSessionContainer);
