import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ApplicationCard from './ApplicationCard';
import applicationListTypes from '../constants/applicationListTypes';
import FavoriteApplication from './FavoriteApplication';

const ApplicationGroup = styled.div`
  padding: 20px 20px;
  border-bottom: 1px solid #e8e8e8;

  &:nth-child(even) {
    background: #fbfbfb;
  }
`;

const ApplicationGroupName = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #606060;
  margin-bottom: 10px;
`;

ApplicationGroupName.displayName = 'ApplicationGroupName';

const ApplicationList = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 20px;

  white-space: nowrap;
  overflow-x: scroll;

  ${({ theme }) => theme.media.tablet`
    overflow: auto;
    white-space: normal;
  `};
`;

ApplicationList.displayName = 'ApplicationList';

const ListItem = styled.div`
  margin-right: 20px;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
`;

const ApplicationListItem = styled(ListItem)`
  width: 40%;

  ${({ theme }) => theme.media.tablet`
    width: 12%;
  `};
`;

const FavoriteListItem = styled(ListItem)`
  width: auto;
`;

const propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    applications: PropTypes.array.isRequired
  }),
  type: PropTypes.string,
  onAddFavorite: PropTypes.func.isRequired,
  onRemoveFavorite: PropTypes.func.isRequired
};

function ApplicationGroupComponent({
  group,
  type,
  onAddFavorite,
  onRemoveFavorite
}) {
  return (
    <ApplicationGroup>
      <ApplicationGroupName>
        {group.name} Applications ({group.applications.length})
      </ApplicationGroupName>
      <ApplicationList>
        {group.applications.map((app) => {
          if (type === applicationListTypes.FAVORITE) {
            return (
              <FavoriteListItem key={app.id}>
                <FavoriteApplication
                  application={app}
                  onRemoveFavorite={onRemoveFavorite}
                />
              </FavoriteListItem>
            );
          }
          return (
            <ApplicationListItem key={app.id}>
              <ApplicationCard
                application={app}
                onAddFavorite={onAddFavorite}
                onRemoveFavorite={onRemoveFavorite}
              />
            </ApplicationListItem>
          );
        })}
      </ApplicationList>
    </ApplicationGroup>
  );
}

ApplicationGroupComponent.propTypes = propTypes;
ApplicationGroupComponent.displayName = 'ApplicationGroup';

export default ApplicationGroupComponent;
