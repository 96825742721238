import { differenceWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  addExistingUserToOrganization,
  removeUserFromOrganization
} from '../../actions/provisioning';
import ResourceList from '../ResourceList';

const ExpandedContainer = styled.div`
  border: 1px solid #e6e6e6;
  padding: 10px;
`;

const propTypes = {
  allOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  setExpanded: PropTypes.func.isRequired,
  usersOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  userId: PropTypes.string.isRequired
};

function ExpandedUser(props) {
  const { allOrganizations, setExpanded, usersOrganizations, userId } = props;
  const dispatch = useDispatch();

  const handleAddUserToOrganization = useCallback(
    (organizationId) =>
      dispatch(addExistingUserToOrganization(organizationId, userId)).then(() =>
        setExpanded({})
      ),
    [dispatch, setExpanded, userId]
  );

  const handleRemoveUserFromOrganization = useCallback(
    (organizationId) =>
      dispatch(removeUserFromOrganization(organizationId, userId)).then(() =>
        setExpanded({})
      ),
    [dispatch, setExpanded, userId]
  );

  const available = differenceWith(
    allOrganizations,
    usersOrganizations,
    (allOrg, userOrg) => {
      return allOrg.id === userOrg.id;
    }
  );

  return (
    <ExpandedContainer>
      <ResourceList
        available={available}
        label="Organizations"
        onAdd={handleAddUserToOrganization}
        onRemove={handleRemoveUserFromOrganization}
        associateById={true}
        readOnly={false}
        resources={usersOrganizations}
      />
    </ExpandedContainer>
  );
}

ExpandedUser.propTypes = propTypes;

export default ExpandedUser;
