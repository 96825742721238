import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';

import { Header as NdHeader } from '@ndustrial/nd-header-react';
import Consent from './Consent';
import YourData from './YourData';
import TabRouter from '@ndustrial/contxt-common/src/components/TabRouter';

const Header = styled(NdHeader)`
  img {
    max-width: 100%;
    max-height: 100%;
    padding: 15px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;

  img {
    padding: 0px;
  }
`;

class UserSettings extends Component {
  static propTypes = {
    theme: PropTypes.object
  };

  render() {
    return (
      <div className="nd-container">
        <Header appName="contxt" logoSrc={this.props.theme.logo}>
          <HeaderContent />
        </Header>
        <div className="nd-content nd-content--fullscreen">
          <TabRouter
            data={[
              {
                slug: 'consent',
                label: 'Consent',
                component: Consent
              },
              {
                slug: 'your-data',
                label: 'Your Data',
                component: YourData
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

export default withTheme(UserSettings);
