import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import VirtualizedFixedSizedList from '../../common/VirtualizedFixedSizedList';

import {
  useAllFacilitiesQuery,
  Facility
} from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import Draggable from '../drag-and-drop/Draggable';
import {
  Loader,
  ndInputBorderColor,
  Section,
  SectionHeaderHeight
} from '@ndustrial/contxt-common/src';
import { useRecoilValue } from 'recoil';
import { facilityGroupingSearchAtom } from '../../Atoms';
import FacilityItemInList from './FacilityItemInList';
import { deburr } from 'lodash';

const filtersDivHeight = 30;

const StyledSection = styled(Section)`
  height: 100%;
  flex: 2;
`;

const FacilitiesList = styled.div`
  overflow: hidden;
  flex: 1;
  height: calc(100% - ${SectionHeaderHeight}px - ${filtersDivHeight}px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-content: flex-end;
  padding-bottom: 0.3rem;
  padding-top: 5px;
  height: ${filtersDivHeight}px;
  border-bottom: 1px solid #e6e6e6;
`;

const Filter = styled.span<{ selected: boolean }>`
  font-size: 0.9rem;
  margin: 0 0.5rem;
  cursor: pointer;
  color: ${({ selected }) => (selected ? '#2764ae' : ndInputBorderColor)};
  line-height: 20px;

  &:hover {
    color: #023879;
  }
`;
export enum FacilityFilters {
  ALL,
  GROUPED,
  UNGROUPED
}

const FacilitiesContainer = () => {
  const [listDimensions, setListDimensions] = useState<DOMRect | null>(null);
  const [facilityFilter, setFacilityFilter] = useState<FacilityFilters>(
    FacilityFilters.ALL
  );
  const [allFacilities, setAllFacilities] = useState<Facility[]>([]);
  const filterSearchValue = useRecoilValue(facilityGroupingSearchAtom);

  const { ALL, GROUPED, UNGROUPED } = FacilityFilters;

  const { loading, error, data } = useAllFacilitiesQuery();

  useEffect(() => {
    if (data?.facilities?.nodes)
      setAllFacilities(data.facilities.nodes as Facility[]);
  }, [data]);

  const visibleFacilities = useMemo(() => {
    return allFacilities.filter((facility) => {
      if (
        filterSearchValue &&
        !deburr(facility.name)
          .toLowerCase()
          .includes(deburr(filterSearchValue).toLowerCase())
      ) {
        return false;
      }
      if (facilityFilter === ALL) return true;

      if (facilityFilter === GROUPED)
        return facility.facilityGroups?.nodes?.length;

      return !facility.facilityGroups?.nodes?.length;
    });
  }, [ALL, GROUPED, allFacilities, facilityFilter, filterSearchValue]);

  const measureFacilities = useCallback((node: HTMLDivElement) => {
    setListDimensions(node?.getBoundingClientRect());
  }, []);

  if (loading) return <Loader label="LOADING FACILITIES" />;
  if (error) return <div>`Error! ${error.message}`</div>;

  return (
    <StyledSection title="Facilities">
      <Filters>
        <Filter
          onClick={() => setFacilityFilter(ALL)}
          selected={facilityFilter === ALL}
        >
          All
        </Filter>
        <Filter
          onClick={() => setFacilityFilter(GROUPED)}
          selected={facilityFilter === GROUPED}
        >
          Grouped
        </Filter>
        <Filter
          onClick={() => setFacilityFilter(UNGROUPED)}
          selected={facilityFilter === UNGROUPED}
        >
          Ungrouped
        </Filter>
      </Filters>
      <FacilitiesList ref={measureFacilities}>
        {listDimensions && data?.facilities?.nodes && (
          <VirtualizedFixedSizedList
            height={listDimensions.height}
            items={visibleFacilities}
            render={(item) => (
              <Draggable
                type={'FACILITY_FROM_LIST'}
                handleLeftOffset={0}
                item={item as Facility}
              >
                <FacilityItemInList facility={item as Facility} />
              </Draggable>
            )}
          />
        )}
      </FacilitiesList>
    </StyledSection>
  );
};

export default FacilitiesContainer;
