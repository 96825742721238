import AnimatedExpandChevron from './AnimatedExpandChevron';
import { Folder } from '@ndustrial/nd-icons-svg';
import GroupAttributes from './GroupAttributes';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { ExpandableSection } from './GroupingItemContainer';
import HierarchyItemConstants from '../../common/constants/HierarchyItemConstants';
import { FacilityGroupingTree } from './GroupingsContainer';
import TrashAction, { IsTrashVisible } from '../../common/TrashAction';
import { UpdateFacilityGroupByIdMutationResult } from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import GroupNameDebouncedInput from './GroupNameDebouncedInput';
import AddGroupAction, { IsAddGroupVisible } from './AddGroupAction';
import useOnAddGroup from '../../common/hooks/useOnAddGroup';

interface IsRootItem {
  isRoot: boolean;
}

const FolderIconWrapper = styled.span`
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  display: flex;
  margin: 0 0.5rem;

  svg {
    height: 17px;
    width: 17px;
  }
`;

const GroupActions = styled.span``;

const GroupAction = styled.span`
  font-size: 1.1rem;
  display: inline-block;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  min-width: 2rem;
`;

const showActionStyle = css`
  opacity: 1;
  pointer-events: all;
`;

const hideActionStyle = css`
  opacity: 0 !important;
  pointer-events: 1 !important;
`;

const GroupTrashAction = styled(GroupAction)``;
const GroupAddAction = styled(GroupAction)``;

const ExpandRow = styled.span`
  padding-right: 0.5rem;
  cursor: pointer;
`;

const GroupingItemWrapper = styled.div<
  IsRootItem & ExpandableSection & IsTrashVisible & IsAddGroupVisible
>`
  display: grid;
  height: ${HierarchyItemConstants.ITEM_HEIGHT}px;
  grid-template-columns: min-content min-content clamp(300px, 400px, 500px) auto 100px;
  align-items: center;
  position: relative;

  &:hover {
    ${GroupTrashAction} {
      ${showActionStyle}
    }

    ${GroupAddAction} {
      ${showActionStyle}
    }
  }

  ${({ isTrashVisible, isAddGroupVisible }) => `
    ${GroupTrashAction} {
      ${isTrashVisible && showActionStyle};
      ${isAddGroupVisible && hideActionStyle};
    }
    
    ${GroupAddAction} {
      ${isAddGroupVisible && showActionStyle};
      ${isTrashVisible && hideActionStyle};
    }
  `}
`;

export interface GroupingItemProps {
  group: FacilityGroupingTree;
  isRoot?: boolean;
  isExpanded: boolean;
  onExpanded: (expanded: boolean) => void;
  isExpandable: boolean;
  onUpdateGroup: (
    group: FacilityGroupingTree,
    update: Partial<FacilityGroupingTree>,
    refresh?: boolean
  ) => Promise<UpdateFacilityGroupByIdMutationResult | null | undefined>;
  onDelete: (group: FacilityGroupingTree) => void;
}

const GroupingItem = (props: GroupingItemProps) => {
  const {
    group,
    isRoot = false,
    onExpanded,
    isExpanded,
    isExpandable,
    onUpdateGroup,
    onDelete
  } = props;

  const [showTrashOptions, setShowTrashOptions] = useState(false);
  const [showAddGroupsOptions, setShowAddGroupsOptions] = useState(false);
  const [isOver, setIsOver] = useState(false);

  const updateGroupName = useCallback(
    async (value: string) => {
      await onUpdateGroup(group, { name: value }, false);
    },
    [group, onUpdateGroup]
  );

  const addGroup = useOnAddGroup();
  const onAddGroup = useCallback(
    ({ name }: { name: string }) => {
      addGroup({ name, parentId: group.id }).then(() =>
        setShowAddGroupsOptions(false)
      );
    },
    [addGroup, group.id]
  );

  return (
    <GroupingItemWrapper
      isRoot={isRoot}
      isExpanded={isExpanded}
      isTrashVisible={showTrashOptions}
      isAddGroupVisible={showAddGroupsOptions}
      onMouseOver={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
    >
      <ExpandRow onClick={() => isExpandable && onExpanded(!isExpanded)}>
        <AnimatedExpandChevron
          isExpanded={isExpanded}
          inactive={!isExpandable}
        />
      </ExpandRow>
      <FolderIconWrapper onClick={() => onExpanded(!isExpanded)}>
        <Folder />
      </FolderIconWrapper>
      <GroupNameDebouncedInput
        {...props}
        onUpdate={updateGroupName}
        isOver={isOver}
      />

      <GroupAttributes group={group} />
      <GroupActions>
        <GroupTrashAction>
          <TrashAction
            onDelete={() => onDelete(group)}
            onRemoveFromGroup={
              !isRoot
                ? () => onUpdateGroup(group, { parentId: null })
                : undefined
            }
            isOpen={showTrashOptions}
            onToggleOpen={setShowTrashOptions}
          />
        </GroupTrashAction>
        <GroupAddAction>
          <AddGroupAction
            onAddGroup={(name) => onAddGroup({ name })}
            isOpen={showAddGroupsOptions}
            onToggleOpen={setShowAddGroupsOptions}
          />
        </GroupAddAction>
      </GroupActions>
    </GroupingItemWrapper>
  );
};

export default GroupingItem;
