import React from 'react';
import { useQuery } from '@apollo/client';
import { OnChangeValue } from 'react-select';

import { Facility } from '../graphql/graphql.generated';
import { ReactSelect } from '..';
import { Option } from '../types/types';
import { GET_FACILITIES } from '../graphql/queries/facilities';

const formatOption = (facility: Facility) => {
  return {
    value: facility,
    label: facility.name
  };
};

export const FacilitySelect = ({
  selectedFacility,
  onChange
}: {
  selectedFacility: Facility | null;
  onChange: (option: OnChangeValue<Option<Facility>, false>) => void;
}) => {
  const { loading, data } = useQuery(GET_FACILITIES, {
    variables: { orderBy: 'SLUG_ASC' }
  });
  const facilityOptions: Option<Facility>[] = data?.facilities?.nodes?.map(
    (facility: Facility) => formatOption(facility)
  );

  const value = selectedFacility ? formatOption(selectedFacility) : null;

  return (
    <ReactSelect<Facility>
      placeholder="Facilities"
      name="Facilities Select"
      options={facilityOptions}
      onChange={onChange}
      isMulti={false}
      isLoading={loading}
      value={value}
    />
  );
};
