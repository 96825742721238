import React from 'react';
import PropTypes from 'prop-types';

import Grouping from './Grouping';
import styled from 'styled-components';
import { $gray } from '@ndustrial/contxt-common';

const StyledFilterContainer = styled.div`
  flex-basis: 300px;
  border-left: 1px solid ${$gray};
  border-top: 1px solid ${$gray};
  border-bottom: 1px solid ${$gray};
  height: 100%;
  overflow-y: scroll;

  > div {
    border-bottom: 1px solid ${$gray};
  }

  > div:last-child {
    border-bottom: 0;
  }
`;

const propTypes = {
  activeFilter: PropTypes.object.isRequired,
  filterGroups: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onFilter: PropTypes.func.isRequired
};

function UsersAccessFilter({ activeFilter, filterGroups, onFilter }) {
  return (
    <StyledFilterContainer className="nd-users-access-filter__container">
      {filterGroups.map((filterGroup) => (
        <Grouping
          key={filterGroup.key}
          activeFilter={activeFilter}
          filterGroup={filterGroup}
          onFilter={onFilter}
        />
      ))}
    </StyledFilterContainer>
  );
}

UsersAccessFilter.propTypes = propTypes;

export default UsersAccessFilter;
