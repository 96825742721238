import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoadingPage from '../components/LoadingPage';
import { contxtSdk } from '@ndustrial/contxt-common';

export function withAuth(WrappedComponent) {
  return class extends React.Component {
    static displayName = `withAuth(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    constructor(props) {
      super(props);
      this.handleAuthentication = this.handleAuthentication.bind(this);
    }

    handleAuthentication(nextState) {
      if (/access_token|id_token|error/.test(nextState.location.hash)) {
        contxtSdk.auth.handleAuthentication();
      }
    }

    render() {
      return (
        <Switch>
          <Route
            path="/callback"
            render={(props) => {
              this.handleAuthentication(props);
              return <LoadingPage label="Signing You In..." />;
            }}
          />
          <Route
            render={(props) => {
              if (contxtSdk.auth.isAuthenticated()) {
                return <WrappedComponent />;
              } else {
                // Capture current location so we can return user to correct page after login.
                localStorage.setItem(
                  'redirect_pathname',
                  props.location.pathname
                );
                contxtSdk.auth.logIn();
                return null;
              }
            }}
          />
        </Switch>
      );
    }
  };
}
