import { createSelector } from 'reselect';
import { intersectionWith } from 'lodash';

import navStructure from '../constants/navStructure';
import { getApplicationModules } from './applications';

const getSelectedOrg = (state) => state.organizations.selectedOrganization;

export const getNavConfig = createSelector(
  [getSelectedOrg, getApplicationModules],
  (organization, applicationModules) => {
    const applicationModuleSlugs = applicationModules.reduce((acc, module) => {
      acc.push(module.slug);
      return acc;
    }, []);

    return navStructure.reduce((acc, navConfigItem) => {
      const intersection = intersectionWith(
        navConfigItem.applicationModuleSlugs,
        applicationModuleSlugs,
        (navAppModuleSlug, appModuleSlug) => {
          return navAppModuleSlug === appModuleSlug;
        }
      );
      if (intersection.length > 0) {
        const url = navConfigItem.url
          .replace('{org}', organization.slug)
          .replace('{orgId}', organization.id);
        return acc.concat({
          ...navConfigItem,
          url
        });
      }

      return acc;
    }, []);
  }
);
