import { combineReducers } from 'redux';

import applications from './applications';
import applicationGroupings from './applicationGroupings';
import consent from './consent';
import favoriteApplications from './favoriteApplications';
import featuredApplications from './featuredApplications';
import organizations from './organizations';
import organizationUsers from './organizationUsers';
import roles from './roles';
import user from './user';
import usersPermissions from './usersPermissions';

export default combineReducers({
  applications,
  applicationGroupings,
  consent,
  favoriteApplications,
  featuredApplications,
  organizations,
  organizationUsers,
  roles,
  user,
  usersPermissions
});
