import React from 'react';
import ReactModal, { Styles as ReactModalStyles } from 'react-modal';

import ModalHeader from './ModalHeader';
import { NdReactModalProps } from '../../types/types';

const defaultStyles: ReactModalStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'hsl(0 0% 0% / 30%)',
    display: 'flex'
  },
  content: {
    inset: '50px',
    padding: '15px 35px 40px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: 'auto'
  }
};

export const Modal = ({
  className,
  title,
  isOpen,
  onRequestClose,
  children,
  customStyles,
  customHeaderStyles,
  hideHeader,
  ...rest
}: NdReactModalProps) => {
  const styles: ReactModalStyles = {
    ...defaultStyles,
    content: {
      ...defaultStyles.content,
      ...customStyles
    }
  };

  return (
    <ReactModal
      {...rest}
      className={className}
      style={styles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {!hideHeader && (
        <ModalHeader
          title={title}
          onCloseClick={() => onRequestClose()}
          customStyles={customHeaderStyles}
        />
      )}
      {children}
    </ReactModal>
  );
};
