import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';

import { PlusBox, CircleSlash } from '@ndustrial/nd-icons-svg';
import { Facility } from '@ndustrial/contxt-common/src/graphql/graphql.generated';
import {
  FacilityGroupingDropTypes,
  FacilityGroupingDropTypesArray
} from './constants/dropTypes';
import { FacilityGroupingTree } from '../groupings/GroupingsContainer';

interface DragZoneProps {
  canDrop: boolean;
  isOver: boolean;
}

const DropZoneWrapper = styled.div<DragZoneProps>`
  position: relative;

  &::before {
    pointer-events: none;
    content: '';
    display: ${({ isOver }) => (isOver ? 'flex' : 'none')};
    position: absolute;
    inset: 0;
    background-color: ${({ canDrop }) =>
      canDrop ? 'rgb(220 235 255 / 52%)' : 'rgb(255 221 221 / 42%)'};
    border: 1px dashed ${({ canDrop }) => (canDrop ? '#4c80e1' : '#da6464')};
    z-index: 1;
  }
`;

const DropIcon = styled.div<DragZoneProps>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  font-size: 2rem;
  z-index: 2;

  svg {
    stroke: ${({ canDrop }) => (canDrop ? '#598ae7' : '#e06e6e')};
  }
`;

export interface DropZoneProps {
  children: ReactNode;
  className?: string;
  onDrop: (
    item: Facility | FacilityGroupingTree,
    type: FacilityGroupingDropTypes
  ) => void;
  onConfirmCanDrop?: (
    item: Facility | FacilityGroupingTree,
    type: FacilityGroupingDropTypes
  ) => boolean;
}

const DropZone = ({
  children,
  onDrop,
  className,
  onConfirmCanDrop
}: DropZoneProps) => {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);
  const contentItem = useRef<HTMLDivElement>(null);

  const [{ isOverCurrent, canDrop }, drop] = useDrop(
    () => ({
      accept: [...FacilityGroupingDropTypesArray],
      drop(item: Facility | FacilityGroupingTree, monitor) {
        const canDrop =
          onConfirmCanDrop &&
          onConfirmCanDrop(
            monitor.getItem(),
            monitor.getItemType() as FacilityGroupingDropTypes
          );
        const isOver = monitor.isOver({ shallow: true });

        if (canDrop && isOver)
          onDrop(item, monitor.getItemType() as FacilityGroupingDropTypes);

        return;
      },
      canDrop(props, monitor) {
        if (onConfirmCanDrop && monitor.isOver({ shallow: true }))
          return onConfirmCanDrop(
            monitor.getItem(),
            monitor.getItemType() as FacilityGroupingDropTypes
          );

        return true;
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop()
      })
    }),
    [dimensions, onDrop]
  );

  useEffect(() => {
    if (contentItem?.current)
      setDimensions(contentItem.current.getBoundingClientRect());
  }, []);

  return (
    <DropZoneWrapper
      ref={drop}
      className={className}
      canDrop={canDrop}
      isOver={isOverCurrent}
    >
      <div ref={contentItem}>{children}</div>
      {isOverCurrent && (
        <DropIcon canDrop={canDrop} isOver={isOverCurrent}>
          {canDrop ? <PlusBox /> : <CircleSlash />}
        </DropIcon>
      )}
    </DropZoneWrapper>
  );
};

export default DropZone;
