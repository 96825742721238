import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as rolesActionCreators from '../actions/roles';
import DebounceTextInput from '../components/DebounceTextInput';
import NewRoleModal from '../components/NewRoleModal';
import RolesTable from '../components/RolesTable';
import {
  getFilteredRoles,
  getRolesLoading,
  getRolesValueFilter
} from '../selectors/roles';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import {
  ndColorBorder,
  ndColorLink,
  ndColorText
} from '@ndustrial/contxt-common';
import { Loader } from '@ndustrial/contxt-common';

const StyledRoleTableControls = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`;

const StyledFilterRoles = styled(DebounceTextInput)`
  height: 30px;
  flex: 1;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid ${ndColorBorder};
  padding: (10 * 0.8) (10);
  color: ${ndColorText};
  background: rgba(0, 0, 0, 0);
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 0.85rem;

  &:focus {
    border-color: ${ndColorLink};
  }

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.02);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const propTypes = {
  actions: PropTypes.shape({
    roles: PropTypes.shape({
      createRole: PropTypes.func.isRequired,
      filterRolesByValue: PropTypes.func.isRequired,
      removeRole: PropTypes.func.isRequired
    })
  }),
  roles: PropTypes.array.isRequired,
  currentValueFilter: PropTypes.string.isRequired,
  newRoleForm: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  isLoading: PropTypes.bool.isRequired
};

function Roles({
  actions,
  roles,
  currentValueFilter,
  newRoleForm,
  selectedOrganization,
  isLoading
}) {
  const handleCreateRole = (newRole) => {
    newRole.organization_id = selectedOrganization.id;
    return actions.roles.createRole(newRole);
  };

  const handleRemoveRole = (roleId) => {
    actions.roles.removeRole(selectedOrganization.id, roleId);
  };

  if (isLoading) {
    return <Loader label="LOADING ROLES" />;
  }

  return (
    <React.Fragment>
      <StyledRoleTableControls className="nd-role-table__controls">
        <StyledFilterRoles
          onChange={actions.roles.filterRolesByValue}
          placeholder="Filter Roles"
          value={currentValueFilter}
        />
        <NewRoleModal
          onCreateRole={handleCreateRole}
          newRoleForm={newRoleForm}
        />
      </StyledRoleTableControls>
      <StyledTableWrapper className="nd-role-table__wrapper">
        <RolesTable roles={roles} onRemoveRole={handleRemoveRole} />
      </StyledTableWrapper>
    </React.Fragment>
  );
}

Roles.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    roles: getFilteredRoles(state),
    currentValueFilter: getRolesValueFilter(state),
    newRoleForm: state.roles.newRoleForm,
    selectedOrganization: state.organizations.selectedOrganization,
    isLoading: getRolesLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      roles: bindActionCreators(rolesActionCreators, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
