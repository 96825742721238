import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActionCreators from '../actions/user';
import ActivateUserForm from '../components/ActivateUserForm';
import SplashScreen from '../components/SplashScreen';

import { contxtSdk } from '@ndustrial/contxt-common';

class ConnectedActivateUser extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      user: PropTypes.shape({
        activateUser: PropTypes.func.isRequired
      }).isRequired
    }).isRequired,
    formState: PropTypes.shape({
      isLoading: PropTypes.bool,
      error: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired
    }).isRequired
  };

  handleSubmit = ({ password }) => {
    const { email, userId, userToken } = qs.parse(this.props.location.search);

    return this.props.actions.user
      .activateUser(userId, {
        email,
        password,
        userToken
      })
      .then(() => {
        localStorage.setItem('redirect_pathname', '/');
        contxtSdk.auth.logIn();
      });
  };

  render() {
    return (
      <SplashScreen>
        <ActivateUserForm
          formState={this.props.formState}
          onSubmit={this.handleSubmit}
        />
      </SplashScreen>
    );
  }
}

function mapStateToProps(state) {
  return {
    formState: {
      isLoading: state.user.isLoading,
      error: state.user.error
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      user: bindActionCreators(userActionCreators, dispatch)
    }
  };
}

export { ConnectedActivateUser as ActivateUser };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedActivateUser);
