import React from 'react';
import PropTypes from 'prop-types';

import SplashScreen from './SplashScreen';
import { Loader } from '@ndustrial/contxt-common';

const propTypes = {
  label: PropTypes.string
};

function LoadingPage({ label }) {
  return (
    <SplashScreen>
      <Loader label={label} />
    </SplashScreen>
  );
}

LoadingPage.propTypes = propTypes;

export default LoadingPage;
