import { Loader } from '@ndustrial/contxt-common';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Close } from '@ndustrial/nd-icons-svg';
import { $gray } from '@ndustrial/contxt-common';

const propTypes = {
  application: PropTypes.shape({
    favorite: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired
    }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    launchUrl: PropTypes.string.isRequired
  }).isRequired,
  onRemoveFavorite: PropTypes.func.isRequired
};

const StyledFavoriteApplication = styled.div`
  position: relative;
  border: 1px solid ${$gray};
  display: flex;
  align-items: center;
  vertical-align: top;
  margin-top: 1em;
  height: 50px;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--nd-color-primary);
  }
`;

const StyledCloseButton = styled(Close)`
  color: blue;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledAhref = styled.a`
  font-size: 0.8675rem;
  font-weight: 700;
  color: var(--nd-color-gray);
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  text-decoration: none;
`;

function FavoriteApplication({ application, onRemoveFavorite }) {
  return (
    <StyledFavoriteApplication className="favorite-application">
      {application.favorite.isLoading ? (
        <Loader />
      ) : (
        <StyledCloseButton
          className="icon-close favorite-application__close"
          onClick={() => {
            onRemoveFavorite(application.id);
          }}
        />
      )}
      <StyledAhref
        className="favorite-application__name"
        href={application.launchUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {application.name}
      </StyledAhref>
    </StyledFavoriteApplication>
  );
}

FavoriteApplication.propTypes = propTypes;

export default FavoriteApplication;
