import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Close } from '@ndustrial/nd-icons-svg';

import ResourceList from '../ResourceList';
import { DisabledToggleButton, ToggleButtonGroup } from '../ToggleButton';
import {
  ProjectEnvironmentChip,
  ProjectEnvironmentChipLabel
} from './ProjectEnvironmentChip';

const ResourceSubGroupWrapper = styled.div`
  padding: 10px;

  border: 1px solid #e6e6e6;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const ResourceSubGroupLabel = styled.h3`
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
`;
const ResourceSubGroupMeta = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 0.7rem;
  padding-left: 8px;
`;
const ResourceSubGroup = styled.div`
  display: flex;
`;

const RemoveResourceButton = styled(Close)`
  stroke: ${({ theme }) => theme.primary};
  cursor: pointer;
  margin: 0 6px;
`;

const propTypes = {
  applications: PropTypes.shape({
    available: PropTypes.array.isRequired
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  role: PropTypes.shape({
    applications: PropTypes.array.isRequired,
    externallyMapped: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    projectEnvironments: PropTypes.array.isRequired
  }).isRequired,
  projectEnvironments: PropTypes.shape({
    available: PropTypes.array.isRequired
  })
};

function RolesSubGroup({ applications, onRemove, role, projectEnvironments }) {
  let removeButton = <RemoveResourceButton onClick={() => onRemove(role.id)} />;

  if (role.externallyMapped) {
    removeButton = (
      <ResourceSubGroupMeta>
        (Cannot be Removed: External Role)
      </ResourceSubGroupMeta>
    );
  } else if (role.name === 'user') {
    removeButton = (
      <ResourceSubGroupMeta>(Cannot be Removed)</ResourceSubGroupMeta>
    );
  }

  return (
    <ResourceSubGroupWrapper>
      <ResourceSubGroupLabel>
        {role.name} {removeButton}
      </ResourceSubGroupLabel>
      <ResourceSubGroup>
        <ResourceList
          label="Applications"
          resources={role.applications}
          available={applications.available}
        />
        <ResourceList
          label="Project Environments"
          resources={role.projectEnvironments}
          available={projectEnvironments.available}
        >
          {(resource) => (
            <ProjectEnvironmentChip key={resource.id}>
              <ProjectEnvironmentChipLabel>
                {resource.name}
              </ProjectEnvironmentChipLabel>
              <ToggleButtonGroup
                value={resource.roleEnvironmentAccess.accessType}
              >
                <DisabledToggleButton value="reader" disabled={true}>
                  Reader
                </DisabledToggleButton>
                <DisabledToggleButton value="admin" disabled={true}>
                  Admin
                </DisabledToggleButton>
              </ToggleButtonGroup>
            </ProjectEnvironmentChip>
          )}
        </ResourceList>
      </ResourceSubGroup>
    </ResourceSubGroupWrapper>
  );
}

RolesSubGroup.propTypes = propTypes;

export default RolesSubGroup;
