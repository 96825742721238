import { Loader } from '@ndustrial/contxt-common/src';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable, { ReactTableDefaults } from 'react-table';
import styled from 'styled-components';
import { reactTableCss } from '../../utils/reactTableCss';

import Expander from '../UsersTable/Expander';
import ExpandedUser from './ExpandedUser';

const StyledReactTable = styled(ReactTable)`
  flex: 1;
  height: 100%;

  a {
    text-decoration-line: underline;
    font-weight: 400;
  }

  .nd-loader {
    padding: 0;
    justify-content: center;
  }

  .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const CellLoader = styled(Loader)`
  padding: 0;
`;

const NoDataText = styled.div`
  display: inline-block;
  font-size: 1.5em;
  position: relative;
`;

const NoData = styled.div`
  background-color: hsla(0, 0%, 100%, 0.8);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 3;

  ${NoDataText} {
    top: 120px;
  }
`;

const MailToLink = styled.a`
  color: ${({ theme }) => theme.button};
  font-weight: 400;
  text-decoration-line: underline;
`;

const columns = () => [
  {
    expander: true,
    Expander,
    width: 50
  },
  {
    accessor: 'fullName',
    Header: 'Name',
    id: 'name'
  },
  {
    accessor: 'email',
    Cell: function Cell(cell) {
      return (
        <MailToLink href={`mailto:${cell.value}`}>{cell.value}</MailToLink>
      );
    },
    Header: 'Email',
    id: 'email'
  },
  {
    accessor: 'organizations',
    Cell: function Cell(cell) {
      const { value } = cell;

      if (!value) {
        return <CellLoader />;
      }

      const organizationCount = value.length;

      return `${organizationCount} Organizations`;
    },
    Header: 'Organizations',
    id: 'organizations',
    maxWidth: 200,
    minResizeWidth: 150,
    minWidth: 150
  }
];

const propTypes = {
  className: PropTypes.string,
  organizationUsersError: PropTypes.object,
  selectedOrganizationId: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      authType: PropTypes.string.isRequired,
      applications: PropTypes.array.isRequired,
      roles: PropTypes.array.isRequired,
      projectEnvironments: PropTypes.array.isRequired,
      isLoading: PropTypes.bool,
      organizations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired
};

function UsersTable(props) {
  const { className, organizationUsersError, selectedOrganizationId, users } =
    props;

  const [expanded, setExpanded] = useState({});
  const isLoading = useSelector((state) => state.organizationUsers.isLoading);
  const allOrganizations = useSelector((state) => state.organizations.items);

  useEffect(() => {
    setExpanded({});
  }, [selectedOrganizationId, setExpanded]);

  const data = organizationUsersError ? [] : users;

  if (isLoading) {
    return <Loader label="LOADING USERS..." />;
  }

  return (
    <StyledReactTable
      {...ReactTableDefaults}
      className={`-striped -highlighted ${className}`}
      column={{
        ...ReactTableDefaults.column,
        minWidth: 100
      }}
      columns={columns()}
      collapseOnDataChange={false}
      data={data}
      defaultPageSize={50}
      expanded={expanded}
      getTheadThProps={() => {
        return {
          style: {
            padding: '14px 15px',
            fontSize: '0.8rem',
            fontWeight: '700',
            height: '50px',
            display: 'flex',
            alignItems: 'center'
          }
        };
      }}
      getTdProps={() => {
        return {
          style: {
            padding: '10px 15px',
            fontSize: '0.9rem',
            fontWeight: '400'
          }
        };
      }}
      NoDataComponent={(props) => {
        return (
          <NoData {...props}>
            <NoDataText>No rows found</NoDataText>
          </NoData>
        );
      }}
      onExpandedChange={(newExpaned) => {
        setExpanded(newExpaned);
      }}
      showPageSizeOptions={false}
      SubComponent={(row) => {
        if (!row.original.organizations) {
          return null;
        }

        const organizations = row.original.organizations;
        const userId = row.original.id;

        return (
          <ExpandedUser
            allOrganizations={allOrganizations}
            setExpanded={setExpanded}
            usersOrganizations={organizations}
            userId={userId}
          />
        );
      }}
    />
  );
}

UsersTable.propTypes = propTypes;

export default styled(UsersTable)`
  ${reactTableCss}
`;
