import actionTypes from '../constants/actionTypes';
import { combineReducers } from 'redux';

const INITIAL_STATE = {
  // TODO: migrate to flattened state
  isLoading: true,
  items: [],
  categoryFilter: {
    key: '',
    value: ''
  },
  newUserForm: {
    isLoading: false,
    error: null
  },
  valueFilter: '',
  loadingItems: [],
  error: null
};

function isLoading(state = INITIAL_STATE.isLoading, action) {
  switch (action.type) {
    case actionTypes.LOAD_ORGANIZATION_USERS_START:
    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_START:
    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_START:
      return true;
    case actionTypes.LOAD_ORGANIZATION_USERS_SUCCESS:
    case actionTypes.LOAD_ORGANIZATION_USERS_FAILURE:
    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_SUCCESS:
    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_FAILURE:
    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_SUCCESS:
    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_FAILURE:
      return false;
    default:
      return state;
  }
}

function items(state = INITIAL_STATE.items, action) {
  switch (action.type) {
    case actionTypes.INVITE_ORGANIZATION_USER_SUCCESS:
      return [...state, action.payload];

    case actionTypes.LOAD_ORGANIZATION_USERS_SUCCESS:
      return action.payload;

    case actionTypes.REMOVE_ORGANIZATION_USER_SUCCESS:
      return state.filter((x) => x.id !== action.payload);

    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.userId) {
          return Object.assign({}, user, {
            organizations: user.organizations.concat(
              action.payload.organization
            )
          });
        }

        return user;
      });

    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.userId) {
          return Object.assign({}, user, {
            organizations: user.organizations.filter(
              (organization) =>
                organization.id !== action.payload.organizationId
            )
          });
        }

        return user;
      });

    default:
      return state;
  }
}

function categoryFilter(state = INITIAL_STATE.categoryFilter, action) {
  switch (action.type) {
    case actionTypes.FILTER_ORGANIZATION_USERS_BY_CATEGORY:
      return action.payload;
    default:
      return state;
  }
}

function newUserForm(state = INITIAL_STATE.newUserForm, action) {
  switch (action.type) {
    case actionTypes.INVITE_ORGANIZATION_USER_START:
      return {
        isLoading: true,
        error: null
      };
    case actionTypes.INVITE_ORGANIZATION_USER_SUCCESS:
      return INITIAL_STATE.newUserForm;
    case actionTypes.INVITE_ORGANIZATION_USER_FAILURE:
      return {
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

function valueFilter(state = INITIAL_STATE.valueFilter, action) {
  switch (action.type) {
    case actionTypes.FILTER_ORGANIZATION_USERS_BY_VALUE:
      return action.payload;
    default:
      return state;
  }
}

function loadingItems(state = INITIAL_STATE.loadingItems, action) {
  switch (action.type) {
    case actionTypes.ORGANIZATION_USER_LOADING_START:
      return [...state, action.payload];
    case actionTypes.ORGANIZATION_USER_LOADING_STOP:
      return state.filter((x) => x !== action.payload);
    default:
      return state;
  }
}

function error(state = INITIAL_STATE.error, action) {
  switch (action.type) {
    case actionTypes.ORGANIZATION_USER_LOADING_START:
    case actionTypes.LOAD_ORGANIZATION_USERS_START:
    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_START:
    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_START:
      return INITIAL_STATE.error;
    case actionTypes.LOAD_ORGANIZATION_USERS_FAILURE:
    case actionTypes.REMOVE_ORGANIZATION_USER_FAILURE:
    case actionTypes.ADD_EXISTING_USER_TO_ORGANIZATION_FAILURE:
    case actionTypes.REMOVE_EXISTING_USER_FROM_ORGANIZATION_FAILURE:
      return action.payload;
    default:
      return state;
  }
}

const organizationUsers = combineReducers({
  isLoading,
  items,
  categoryFilter,
  newUserForm,
  valueFilter,
  loadingItems,
  error
});
export default organizationUsers;
