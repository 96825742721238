import keyMirror from 'keymirror';

export default keyMirror({
  ACTIVATE_USER_START: null,
  ACTIVATE_USER_SUCCESS: null,
  ACTIVATE_USER_FAILURE: null,
  ADD_FAVORITE_APPLICATION_SUCCESS: null,
  ADD_FAVORITE_APPLICATION_FAILURE: null,
  ADD_ROLE_APPLICATION_SUCCESS: null,
  ADD_ROLE_APPLICATION_FAILURE: null,
  ADD_ROLE_PROJECT_ENVIRONMENT_SUCCESS: null,
  ADD_ROLE_PROJECT_ENVIRONMENT_FAILURE: null,
  ADD_EXISTING_USER_TO_ORGANIZATION_START: null,
  ADD_EXISTING_USER_TO_ORGANIZATION_SUCCESS: null,
  ADD_EXISTING_USER_TO_ORGANIZATION_FAILURE: null,
  CONSENT_ACCEPT_START: null,
  CONSENT_ACCEPT_SUCCESS: null,
  CONSENT_ACCEPT_FAILURE: null,
  CONSENT_LOAD_START: null,
  CONSENT_LOAD_SUCCESS: null,
  CONSENT_LOAD_FAILURE: null,
  CREATE_ORGANIZATION_START: null,
  CREATE_ORGANIZATION_SUCCESS: null,
  CREATE_ORGANIZATION_FAILURE: null,
  CREATE_ROLE_START: null,
  CREATE_ROLE_SUCCESS: null,
  CREATE_ROLE_FAILURE: null,
  FAVORITE_APPLICATION_LOADING_START: null,
  FAVORITE_APPLICATION_LOADING_STOP: null,
  FILTER_APPLICATIONS: null,
  FILTER_ORGANIZATION_USERS_BY_CATEGORY: null,
  FILTER_ORGANIZATION_USERS_BY_VALUE: null,
  FILTER_ORGANIZATIONS_BY_VALUE: null,
  FILTER_ROLES_BY_VALUE: null,
  INVITE_ORGANIZATION_USER_START: null,
  INVITE_ORGANIZATION_USER_SUCCESS: null,
  INVITE_ORGANIZATION_USER_FAILURE: null,
  LOAD_APPLICATIONS_START: null,
  LOAD_APPLICATIONS_SUCCESS: null,
  LOAD_APPLICATIONS_FAILURE: null,
  /*  MODULE ROUTING WIP */
  LOAD_APPLICATION_GROUPINGS_START: null,
  LOAD_APPLICATION_GROUPINGS_SUCCESS: null,
  LOAD_APPLICATION_GROUPINGS_FAILURE: null,
  /*  MODULE ROUTING WIP */
  LOAD_FAVORITE_APPLICATIONS_START: null,
  LOAD_FAVORITE_APPLICATIONS_SUCCESS: null,
  LOAD_FAVORITE_APPLICATIONS_FAILURE: null,
  LOAD_FEATURED_APPLICATIONS_START: null,
  LOAD_FEATURED_APPLICATIONS_SUCCESS: null,
  LOAD_FEATURED_APPLICATIONS_FAILURE: null,
  LOAD_USER_START: null,
  LOAD_USER_SUCCESS: null,
  LOAD_USER_FAILURE: null,
  LOAD_USER_PERMISSIONS_START: null,
  LOAD_USER_PERMISSIONS_SUCCESS: null,
  LOAD_USER_PERMISSIONS_FAILURE: null,
  LOAD_USERS_PERMISSIONS_START: null,
  LOAD_USERS_PERMISSIONS_SUCCESS: null,
  LOAD_USERS_PERMISSIONS_FAILURE: null,
  LOAD_ORGANIZATIONS_START: null,
  LOAD_ORGANIZATIONS_SUCCESS: null,
  LOAD_ORGANIZATIONS_FAILURE: null,
  LOAD_ORGANIZATION_ROLES_START: null,
  LOAD_ORGANIZATION_ROLES_SUCCESS: null,
  LOAD_ORGANIZATION_ROLES_FAILURE: null,
  LOAD_ORGANIZATION_USERS_START: null,
  LOAD_ORGANIZATION_USERS_SUCCESS: null,
  LOAD_ORGANIZATION_USERS_FAILURE: null,
  ORGANIZATION_USER_LOADING_START: null,
  ORGANIZATION_USER_LOADING_STOP: null,
  REMOVE_EXISTING_USER_FROM_ORGANIZATION_START: null,
  REMOVE_EXISTING_USER_FROM_ORGANIZATION_SUCCESS: null,
  REMOVE_EXISTING_USER_FROM_ORGANIZATION_FAILURE: null,
  REMOVE_FAVORITE_APPLICATION_SUCCESS: null,
  REMOVE_FAVORITE_APPLICATION_FAILURE: null,
  REMOVE_ORGANIZATION_USER_SUCCESS: null,
  REMOVE_ORGANIZATION_USER_FAILURE: null,
  REMOVE_ORGANIZATION_USER_LOADING_START: null,
  REMOVE_ORGANIZATION_USER_LOADING_STOP: null,
  REMOVE_ROLE_APPLICATION_SUCCESS: null,
  REMOVE_ROLE_APPLICATION_FAILURE: null,
  REMOVE_ROLE_PROJECT_ENVIRONMENT_SUCCESS: null,
  REMOVE_ROLE_PROJECT_ENVIRONMENT_FAILURE: null,
  REMOVE_ROLE_SUCCESS: null,
  REMOVE_ROLE_FAILURE: null,
  REMOVE_ROLE_LOADING_START: null,
  REMOVE_ROLE_LOADING_STOP: null,
  SET_SELECTED_ORGANIZATION: null,
  USER_PERMISSIONS_APPLICATION_ADD: null,
  USER_PERMISSIONS_APPLICATION_REMOVE: null,
  USER_PERMISSIONS_PROJECT_ENVIRONMENT_ADD: null,
  USER_PERMISSIONS_PROJECT_ENVIRONMENT_REMOVE: null
});
